import React from 'react';
import image from 'assets/images/change-password-img.svg';
import ReactPasswordStrength from 'react-password-strength';
import { t } from 'util/I18nMessages';

const template = _this => {
    return (
        <>
            <section id="change-pass">
                <div className="change-pass">
                    <div className="check-pass">
                        <div className="logo-change-pass">
                            <img src={image} alt="" />
                        </div>
                        <div className="info-change-pass">
                            <h3>{t('PAGE.CHANGE_FORGOT_PASSWORD.CHANGE_PASSWORD')}</h3>
                            <p>{t('PAGE.CHANGE_FORGOT_PASSWORD.PASSWORD_MUST_CONTAIN')}</p>
                            <div className="list-check-pass">
                                {_this.state.messages ?
                                    <ul>
                                        {/* <li className={!_this.state.newPasswordErrors.notSameLoginName ? 'active' : ''}>{_this.state.messages.notSameLoginName}</li> */}
                                        <li className={!_this.state.newPasswordErrors.minimumCharacterCount ? 'active' : ''}>{t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_8_CHARACTERS')}</li>
                                        <li className={!_this.state.newPasswordErrors.minimumUpperCaseLetter ? 'active' : ''}>{t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_UPPERCASE')}</li>
                                        <li className={!_this.state.newPasswordErrors.minimumLowerCaseLetter ? 'active' : ''}>{t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_LOWER')}</li>
                                        <li className={!_this.state.newPasswordErrors.minimumDigitCharacterCount ? 'active' : ''}>{t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_DIGIT')}</li>
                                        <li className={!_this.state.newPasswordErrors.minimumSpecialCharacter ? 'active' : ''}>{t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_SPECIAL_OR_PUNCTUATION')}</li>
                                    </ul>                                    
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="form-pass">
                        <p className="title">
                            <span dangerouslySetInnerHTML={{__html: t('PAGE.CHANGE_FORGOT_PASSWORD.INSTRUCTION', { hint: `<span class="hint">${t('PAGE.CHANGE_FORGOT_PASSWORD.INSTRUCTION_STRONG_WORD')}</span>` })}}></span>
                        </p>
                        <div className="form">
                            <div className="form-input">
                                <label>{t('PAGE.CHANGE_FORGOT_PASSWORD.CURRENT_PASSWORD')}</label>
                                <div className="input-group">
                                    <input 
                                        className={`current-password form-control ${_this.state.errors.currentPassword != null ? 'error': ''}`}
                                        id="current-password" 
                                        type={_this.state.currentPasswordType} 
                                        placeholder={t('PAGE.CHANGE_FORGOT_PASSWORD.CURRENT_PASSWORD')}
                                        name="currentPassword" 
                                        value={_this.state.currentPassword}
                                        onChange={(event) =>
                                            _this.handleInputChange('currentPassword', event)
                                        }                                        
                                    />                                
                                    <span className={`icon ${_this.state.currentPasswordType === 'password' ? 'icon-visible-ico' : 'icon-hidden'}`} onClick={(event) => _this.showHideCurrentPassword(event)}></span>                                
                                </div>
                                {_this.state.errors.currentPassword != null && (
                                    <span className="info-err">{t(_this.state.errors.currentPassword)}</span>
                                )}
                            </div>
                            <div className="form-input">
                                <label>{t('PAGE.CHANGE_FORGOT_PASSWORD.NEW_PASSWORD')}</label>                                
                                <div className="input-group">
                                    <ReactPasswordStrength
                                        ref={ref => _this.ReactPasswordStrength = ref}
                                        className={`form-control ${_this.state.isOnFocusNewPassword ? 'react-password-strength-input': ''} ${_this.state.errors.newPassword != null ? 'error': ''}`}
                                        minLength={8}
                                        minScore={2}
                                        tooShortWord={t('PAGE.CHANGE_FORGOT_PASSWORD.WEAK')}
                                        scoreWords={[
                                            t('PAGE.CHANGE_FORGOT_PASSWORD.WEAK'), 
                                            t('PAGE.CHANGE_FORGOT_PASSWORD.WEAK'), 
                                            t('PAGE.CHANGE_FORGOT_PASSWORD.MEDIUM'), 
                                            t('PAGE.CHANGE_FORGOT_PASSWORD.MEDIUM'), 
                                            t('PAGE.CHANGE_FORGOT_PASSWORD.STRONG')]}
                                        changeCallback={(event) => _this.handleNewPassword(event)}
                                        inputProps={{ 
                                            name: "newPassword", 
                                            type: _this.state.newPasswordType,
                                            autoComplete: "off", 
                                            className: "new-password form-control", 
                                            placeholder: t('PAGE.CHANGE_FORGOT_PASSWORD.NEW_PASSWORD'), 
                                            value: _this.state.newPassword,
                                            onFocus: () => _this.handleFocusNewPassword(),
                                            onBlur: () => _this.handleFocusNewPassword()}}                                                                                                                         
                                    />
                                    <span className={`icon ${_this.state.newPasswordType === 'password' ? 'icon-visible-ico' : 'icon-hidden'}`} onClick={(event) => _this.showHideNewPassword(event)}></span>
                                </div>
                                {_this.state.errors.newPassword != null && (
                                    <span className="info-err">{t(_this.state.errors.newPassword)}</span>
                                )}
                            </div>
                            <div className="form-input">
                                <label>{t('PAGE.CHANGE_FORGOT_PASSWORD.CONFIRM_NEW_PASSWORD')}</label>
                                <div className="input-group">
                                    <input 
                                        className={`confirm-new-password form-control ${_this.state.errors.confirmNewPassword != null ? 'error': ''}`}
                                        id="confirm-new-password" 
                                        type={_this.state.confirmNewPasswordType} 
                                        placeholder={t('PAGE.CHANGE_FORGOT_PASSWORD.CONFIRM_NEW_PASSWORD')}
                                        name="confirmNewPassword" 
                                        value={_this.state.confirmNewPassword}
                                        onChange={(event) =>
                                            _this.handleInputChange('confirmNewPassword', event)
                                        }
                                    />  
                                    <span className={`icon ${_this.state.confirmNewPasswordType === 'password' ? 'icon-visible-ico' : 'icon-hidden'}`} onClick={(event) => _this.showHideConfirmNewPassword(event)}></span>                              
                                </div>
                                {_this.state.errors.confirmNewPassword != null && (
                                    <span className="info-err">{t(_this.state.errors.confirmNewPassword)}</span>
                                )}
                            </div>
                        </div>
                        <div className="btn-pass">
                            <button 
                                disabled={!_this.state.newPasswordValid} 
                                className="btn btn-primary" 
                                onClick={(event) => _this.handleSave(event)}>
                                {t('BUTTON.SAVE')}
                            </button>
                            
                            {(!localStorage.isRequiredChangePassword || localStorage.isRequiredChangePassword === "0") && (
                                <button 
                                    disabled={_this.state.currentPassword === ''
                                                && _this.state.newPassword === '' 
                                                && _this.state.confirmNewPassword === ''} 
                                    className="btn btn-dark" 
                                    onClick={() => _this.handleCancel()}>
                                    {t('BUTTON.CANCEL')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default template;
