import React from 'react';
import ThisInterface from './interface';
import * as Constants from 'constants/Constants';
import Loading from 'components/Loading';
import SelectSubTenant from 'components/SelectSubTenant';
import * as Routes from 'pages/RouteLoader';
import { getIframeAPIURL } from 'util/ControlUtils';

const template = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { iframeToken, subTenantId, iframeLoaded } = _this.state;

    return (
        <>
            <div className="package-detail">
                <div className="list-page plan-list-view-page">
                    <div className="list-page-title">
                        <p className="title-content title-content-list">
                            {t('PAGE.PRODUCTS.ADDONS.TITLE')}
                        </p>
                    </div>
                    <div className="box-btn-filter ml-auto d-flex">
                        <span className="plan-hint">
                            {t('PAGE.PRODUCTS.ADDONS.INSTRUCTION')}
                        </span>
                    </div>
                </div>
            </div>
            {!subTenantId && <SelectSubTenant 
                rightName={Routes.AddonListRoute.rightName}
                conmanService={_this.props.conmanService}
                onNext={_this.handleNext}
            />}
            {subTenantId && 
            <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && <iframe src={`${getIframeAPIURL(Constants.IFRAME_ADDON_LIST, subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => _this.resizeIframe(_this.refs.iframe)} className="iframe mt-3" frameBorder="0"></iframe>}
                    </div>
                </div>
            </div>}
        </>
    );
};

export default template;
