import React from 'react';
import Swal from 'sweetalert2';

import { withUserAndUserGroup } from 'hocs';

import observer from 'util/Observer';
import { isGroupNameValid, filterUserAndUserGroup, getSubTenantId } from 'util/ControlUtils';
import { WithTranslation } from 'react-i18next';
import * as DefaultConstants from 'constants/DefaultConstants';

import template from './template';
import ThisInterface from './interface';
import { API_USERS_FILTER } from 'constants/Constants';
import _ from 'lodash';
import * as Route from 'pages/RouteLoader';

const initialState = {
    userFilterObject: {
        and: [
            {
                operation: 'contains',
                queryType: 'text',
                queryKey: 'user.UserName',
                queryValue: '{value}'
            },{
                queryKey: 'roleId',
                operation: 'in',
                queryType: 'guid',
                queryValue: '00000000-0000-0000-0000-000000000000'
            }
        ]
    },
    isLoading: true,
    isEditMode: false,
    groupName: '',
    groupId: '',
    isGroupNameValid: true,
    tenantName: '',
    tenantId: '',
    selectedSubTenantId: '',
    tenantRoleEnabled: false,
    selectedTenantRoles: [],
    selectedSubTenantRoles: [],
    selectedUsers: [],
    isSelectedRolesValid: true,
    disabled: true
};

class AddUserGroupPage extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);

        // Get subTenantId & rightName
        const { isEditMode } = props;
        const rightName = isEditMode ? Route.EditUserGroupRoute.rightName : Route.AddUserGroupRoute.rightName;

        const { match, tenantId } = props;
        this.state = {
            ...initialState,
            groupId: match.params['id'] || '',
            tenantId,
            rightName: rightName
        };
    }

    componentDidMount() {
        observer.subscribe('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', (isClear) => {
            const { tenantId, selectedSubTenantRoles,selectedTenantRoles, tenantRoleEnabled } = this.state;
            const filterObject = filterUserAndUserGroup(tenantId, selectedTenantRoles, selectedSubTenantRoles);
            if (tenantRoleEnabled && selectedTenantRoles.length) {
                this.setState({userFilterObject: {
                    and: [{
                        queryKey: 'tenantId',
                        operation: 'eq',
                        queryType: 'guid',
                        queryValue: tenantId
                    },
                    {
                        operation: 'contains',
                        queryType: 'text',
                        queryKey: 'UserName',
                        queryValue: '{value}'
                    }]
                }
                });
            } else {
                if (filterObject && filterObject.length) {
                    this.setState({userFilterObject: {
                        and: [
                            initialState.userFilterObject.and[0],
                            {or: filterObject}
                        ]
                    }});
                } else {
                    this.setState({userFilterObject: {
                        and: [
                            initialState.userFilterObject.and[0],
                            {
                                queryKey: 'roleId',
                                operation: 'in',
                                queryType: 'guid',
                                queryValue: '00000000-0000-0000-0000-000000000000'
                            }
                        ]
                    }});
                }
            }
            if (isClear) {
                const { rightName } = this.state;
                const subTenantId = getSubTenantId();

                if (filterObject && filterObject.length) {
                    let data = { filter: JSON.stringify({or: filterObject}) };
                    this.props.conmanService
                        .getSuggestions(API_USERS_FILTER, 'post', data, subTenantId, rightName)
                        .then(res => {
                            const resCondition = res && res.data && !_.isEmpty(res.data);
                            if (resCondition) {
                                const selectedUsers = this.state.selectedUsers.filter(e => {
                                    return res.data.find(f => f.id === e.id) ? true : false;
                                });
                                this.setState({selectedUsers: selectedUsers});
                            } else {
                                this.setState({selectedUsers: []});
                            }
                        });
                } else {
                    this.setState({selectedUsers: []});
                }
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('BUILD_FILTER_OBJECT.ADVANCE_SEARCH');
    }


    static getDerivedStateFromProps(
        nextProps: ThisInterface['props'],
        prevState: ThisInterface['state']
    ) {
        const { selectedSubTenantRoles } = nextProps;
        if (nextProps) {
            const selectedSubTenantRoleList =
                selectedSubTenantRoles &&
                selectedSubTenantRoles !== prevState.selectedSubTenantRoles
                    ? selectedSubTenantRoles
                    : prevState.selectedSubTenantRoles;

            return {
                selectedSubTenantRoles: selectedSubTenantRoleList
            };
        }
        return null;
    }

    componentDidUpdate(prevProps: ThisInterface['props']) {
        const { subTenants, selectedSubTenantRoles, isEditMode } = this.props;
        if (
            subTenants !== prevProps.subTenants &&
            selectedSubTenantRoles !== prevProps.selectedSubTenantRoles
        ) {
            isEditMode ? this.initEditMode() : this.initSelectSubTenant(subTenants);
        }
    }

    initSelectSubTenant = subTenants => {
        this.setState({
            selectedSubTenantId: subTenants[0].id,
            isLoading: false
        });
    };

    initEditMode = () => {
        const { groupId, rightName } = this.state;
        const { conmanService, t } = this.props;

        conmanService
            .getUserGroupById(groupId, rightName)
            .then(res => {
                this.mapUserGroupToState(res);
            })
            .catch(err => {
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    type: 'error',
                    html: err.message,
                    confirmButtonText: t('BUTTON.CLOSE'),
                    onClose: () => {
                        this.props.history.push('/system/user-group');
                    }
                });
            });
    };

    mapUserGroupToState = userGroup => {
        const { selectedSubTenantRoles} = this.props;

        let tenantName = '',
            tenantRoleEnabled = false,
            initialTenantRoles = [];

        if (userGroup.roles && userGroup.roles.length > 0) {
            tenantName = userGroup.roles[0].tenantName;
            initialTenantRoles = userGroup.roles[0].tenantRoles;
            tenantRoleEnabled = initialTenantRoles.length > 0;

            let subTenantRoles = userGroup.roles[0].subTenants;
            subTenantRoles.forEach(str => {
                let index = selectedSubTenantRoles.findIndex(
                    istr => istr.subTenantId === str.subTenantId
                );
                if (index > -1)
                    selectedSubTenantRoles[index].roles = str.subTenantRoles;
            });
            this.props.editMode(subTenantRoles);
        }

        this.setState({
            isLoading: false,
            groupId: userGroup.id,
            groupName: userGroup.name,
            tenantId: userGroup.tenantId,
            tenantName: tenantName,
            selectedUsers: userGroup.users,
            selectedSubTenantId: userGroup.subTenantId,
            tenantRoleEnabled: tenantRoleEnabled,
            selectedTenantRoles: initialTenantRoles,
            selectedSubTenantRoles
        });
        observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
    };

    onGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const { value } = event.target;

        this.setState({
            disabled: false
        });
        const valid = isGroupNameValid(value);
        if (valid || value === '') {
            this.setState({
                groupName: value,
                isGroupNameValid: value.trim() === '' ? false : true
            });
        }
    };

    //Tenant functions
    onTenantRoleSwichChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        observer.publish('flagUnsavedChangeEvent', true);
        let { checked } = event.target;
        const { t } = this.props;
        if (checked) {
            this.setState({
                tenantRoleEnabled: checked,
                disabled: false
            });
            observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
        } else {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USER_GROUP.ADD_EDIT.CONFIRM_CHANGE_ASSIGN_USER'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then((result) => {
                if (result.value) {
                    this.setState({
                        tenantRoleEnabled: false,
                        disabled: false,
                        selectedTenantRoles: []
                    });
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        }
    };

    handleAddRemoveSingleSuggestion = (suggestion, key: string) => {
        const {t} = this.props;
        if (this.state[key].length > suggestion.length) { // Is remove
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USER_GROUP.ADD_EDIT.CONFIRM_CHANGE_ASSIGN_USER'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then((result) => {
                if (result.value) {
                    observer.publish('flagUnsavedChangeEvent', true);
                    this.setState(
                        prevState => {
                            return {
                                ...prevState,
                                disabled: false,
                                [key]: suggestion
                            };
                        }
                    );
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        } else {
            observer.publish('flagUnsavedChangeEvent', true);
            this.setState(
                prevState => {
                    return {
                        ...prevState,
                        disabled: false,
                        [key]: suggestion
                    };
                }
            );
            observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
        }
    };

    //Sub-tenant functions
    onSubTenantSelectedChanged = value => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState({
            selectedSubTenantId: value.value,
            selectedUsers: [],
            disabled: false
        });
    };

    onSubTenantRoleSwichChanged = event => {
        const { t } = this.props;
        if (event.target.checked) {
            observer.publish('flagUnsavedChangeEvent', true);
            this.props.onSubTenantRoleSwichChanged(event);
            this.setState({
                disabled: false
            });
        } else {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USER_GROUP.ADD_EDIT.CONFIRM_CHANGE_ASSIGN_USER'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then((result) => {
                if (result.value) {
                    const eventNotChecked = { target: {  checked: false } };
                    observer.publish('flagUnsavedChangeEvent', true);
                    this.props.onSubTenantRoleSwichChanged(eventNotChecked);
                    this.setState({
                        disabled: false
                    });
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        }
    };

    onSubTenantRole = (_subTenantId: string, roles, key: string) => {
        const { t } = this.props;
        switch (key) {
            case 'add':
                observer.publish('flagUnsavedChangeEvent', true);
                this.props.onSubTenantRoleAdded(_subTenantId, roles);
                observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
                break;
            case 'delete':
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    text: t('MSG.SYSTEM.USER_GROUP.ADD_EDIT.CONFIRM_CHANGE_ASSIGN_USER'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('BUTTON.YES'),
                    cancelButtonText: t('BUTTON.NO')
                }).then((result) => {
                    if (result.value) {
                        this.props.onSubTenantRoleDeleted(_subTenantId, roles);
                        observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                    }
                });
                break;
            case 'disabled':
                observer.publish('flagUnsavedChangeEvent', true);
                this.props.onSubTenantRoleDisabled(_subTenantId);
                break;
            default:
                break;
        }
        this.setState({
            disabled: false
        });
    };

    onUserRemoved = userId => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState({
            selectedUsers: this.state.selectedUsers.filter(usr => usr.id !== userId),
            disabled: false
        });
    };

    checkingReadyToSave = () => {
        const {
            groupName,
            tenantRoleEnabled,
            selectedTenantRoles,
            selectedSubTenantRoles
        } = this.state;

        const { subTenantRoleEnabled } = this.props;

        const isGroupNameValid = groupName.trim() !== '';

        const isTenantRolesValid =
            !tenantRoleEnabled ||
            (tenantRoleEnabled && selectedTenantRoles.length > 0);
        const isSubTenantRolesValid =
            !subTenantRoleEnabled ||
            (subTenantRoleEnabled && selectedSubTenantRoles.some(str => str.roles.length > 0));
        const isRolesValid = isTenantRolesValid && isSubTenantRolesValid;

        if (!isGroupNameValid || !isRolesValid) {
            this.setState({
                isGroupNameValid: isGroupNameValid,
                isSelectedRolesValid: isRolesValid
            });
        }

        return isGroupNameValid && isRolesValid;
    };

    mapStateToUserGroup = () => {
        const {
            groupName,
            tenantId,
            selectedSubTenantId,
            selectedTenantRoles,
            selectedSubTenantRoles,
            selectedUsers
        } = this.state;

        const postData = {
            name: groupName.trim(),
            tenantId,
            subTenantId: selectedSubTenantId,
            users: selectedUsers.map(usr => usr.id)
        };

        const tenantRoles = selectedTenantRoles.map(tr => {
            return {
                tenantId,
                subTenantId: null,
                roleId: tr.id
            };
        });

        const subTenantRoles = selectedSubTenantRoles
            .filter(t => t.roles.length > 0)
            .reduce((acc: any, tr) => {
                const stRoles = tr.roles.map(r => {
                    return {
                        tenantId,
                        subTenantId: tr.subTenantId,
                        roleId: r.firstKeyId ? r.firstKeyId : r['role'].id,
                        clubGroupId: r.secondKeyId ? r.secondKeyId : r['clubGroup'].id,
                        name: ''
                    };
                });
                return acc.concat(stRoles);
            }, []);

        postData['roles'] = tenantRoles.concat(subTenantRoles);
        return postData;
    };

    createOrEditUserGroup = userGroup => {
        const { conmanService, isEditMode, t } = this.props;
        const { groupId, rightName } = this.state;
        Promise.all([
            isEditMode
                ? conmanService.updateUserGroup(groupId, userGroup, rightName)
                : conmanService.createUserGroup(userGroup, rightName)
        ]).then(([]) => {
            observer.publish('flagUnsavedChangeEvent', false);

            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                type: 'success',
                html: t('MSG.SYSTEM.USER_GROUP.ADD_EDIT.SAVE_SUCCESS'),
                confirmButtonText: t('BUTTON.CLOSE'),
                onClose: () => {
                    this.props.history.push('/system/user-group');
                }
            });
        });
    };

    handleCancel = () => {
        this.props.history.push('/system/user-group');
    };

    handleSubmit = () => {
        const { t } = this.props;
        const isValid = this.checkingReadyToSave();

        if (isValid) {
            const userGroup = this.mapStateToUserGroup();
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USER_GROUP.ADD_EDIT.CONFIRM_SAVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(res => {
                if (res.value) {
                    Swal.fire({
                        html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                        showConfirmButton: false,
                        allowOutsideClick: false
                    });
                    this.createOrEditUserGroup(userGroup);
                }
            });
        }
    };

    render() {
        return template(this);
    }
}

export default withUserAndUserGroup(
    AddUserGroupPage,
    '/system/user-group/{id}/edit',
    'addUserGroup'
);
