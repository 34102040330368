import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import store from 'reduxs/index';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import template from './template';
import ThisInterface from './interface';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import {
    getFieldConfig,
    filterValuableFieldsOnly
} from 'util/FieldConfiguration';
import * as Route from 'pages/RouteLoader';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';

import { withTranslation, WithTranslation } from 'react-i18next';
import { MsbClubPlan, MsbFee } from 'models/PackagePlan';

class MemberSummary extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);

        this.state = {
            openDropdown: {
                memberSummary: true,
                personalDetails: false,
                healthQuestion: false,
                membershipDetails: false,
                memberPaymentDetails: false,
                memberPayment: false
            },
            minContractEndDate: '',
            fieldConfigs: this.getFieldConfiguration(InitialFieldConfigs.data),
            fees: []
        };
    }

    componentDidMount() {
        this.getFieldConfigs();
        this.getMinContractEndDate();
        this.getProjectedPayments();
    }

    getProjectedPayments = () => {
        const {
            newMembership: {
                membershipSearchFilter,
                selectedPackagePlan,
                selectedSubTenant
            }
        } = this.props;
        if (
            membershipSearchFilter &&
            selectedPackagePlan &&
            selectedSubTenant &&
            selectedPackagePlan.plan
        ) {
            const packagePlan: MsbClubPlan = selectedPackagePlan.plan;
            if (packagePlan &&
                packagePlan.payment &&
                packagePlan.payment.payNow &&
                packagePlan.payment.payNow.fees &&
                packagePlan.payment.payNow.fees.length) {
                const payNowFees: MsbFee[] = packagePlan.payment.payNow.fees;
                const hasNextMonthDue = payNowFees.find(e => e.typeName.indexOf('Next Month') > -1);
                let regularFees: MsbFee[] = [];
                if (packagePlan.payment.regular &&
                    packagePlan.payment.regular.fees &&
                    packagePlan.payment.regular.fees.length) {
                    const sourceRegularFees = JSON.parse(JSON.stringify(packagePlan.payment.regular.fees));
                    const regularDate = sourceRegularFees[0].startDate;
                    for (let i = 1; i < (hasNextMonthDue ? 5 : 6); i++) {
                        sourceRegularFees.forEach(e => {
                            e.startDate = moment(regularDate).add(i-1, 'months').format(GENERAL_DATABASE_DATE_FORMAT + 'T00:00:00Z');
                            regularFees.push({...e});
                        });
                    }
                }

                this.setState({
                    fees: [
                        ...payNowFees,
                        ...regularFees,
                    ]
                });
            }
        }
    };

    getMinContractEndDate = () => {
        const {
            newMembership: { selectedPackagePlan, membershipSearchFilter }
        } = this.props;
        let day = new Date(membershipSearchFilter.startDate);
        if (
            selectedPackagePlan.plan.minimumContractValue
                .toLowerCase()
                .indexOf('day') > -1
        ) {
            day.setDate(
                day.getDate() + selectedPackagePlan.plan.minimumContract.value
            );
        } else {
            day.setMonth(
                day.getMonth() + selectedPackagePlan.plan.minimumContract.value
            );
        }
        this.setState({
            minContractEndDate: day
        });
    };

    getFieldConfigs = () => {
        const {
            membershipService,
            newMembership: { selectedSubTenant },
            isEdit
        } = this.props;
        const rightName = !isEdit ? Route.AddNewMemberRoute.rightName : Route.ChangeMembershipRoute.rightName;
        membershipService
            .getFieldsConfiguration(selectedSubTenant.id, rightName)
            .then(res => {
                if (res.data && res.data.length) {
                    let fieldConfigs = filterValuableFieldsOnly(
                        this.getFieldConfiguration(res.data)
                    );

                    this.setState(prevState => {
                        return {
                            fieldConfigs: {
                                ...prevState.fieldConfigs,
                                ...fieldConfigs
                            }
                        };
                    });
                }
            })
            .catch(() => {
                this.setState({
                    fieldConfigs: this.getFieldConfiguration(InitialFieldConfigs.data)
                });
            });
    };

    handlePrevious() {
        const { membershipConfiguration } = this.props.newMembership;
        membershipConfiguration.stepIndex = 4;
        store.dispatch<any>(
            MembershipActions.setConfiguration(membershipConfiguration)
        );
    }

    handleOpenDropdown = key => {
        const { openDropdown } = this.state;
        openDropdown[key] = !this.state.openDropdown[key];
        this.setState({
            openDropdown
        });
    };

    handleEdit = index => {
        const { membershipConfiguration } = this.props.newMembership;
        membershipConfiguration.stepIndex = index;
        store.dispatch<any>(
            MembershipActions.setConfiguration(membershipConfiguration)
        );
    };

    getFieldConfiguration = (fieldConfiguration: any) => {
        const dateOfBirthConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_3
        );
        const titleConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_TITLE
        );
        const genderConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_GENDER
        );
        const firstNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_FIRST_NAME
        );
        const lastNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_LAST_NAME
        );
        const nationalConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_NATIONAL
        );
        const nationalIdConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_NATIONAL_ID
        );
        const otherNationalIdConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OTHER_NATIONAL_ID
        );
        const parentalConsentConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PARENTAL_CONSENT_PROVIDED
        );
        const preferedLanguageConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES
        );
        const telephoneNumberConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_TELEPHONE_NUMBER
        );
        const mobilePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MOBILE_TELEPHONE
        );
        const homePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_HOME_TELEPHONE
        );
        const workPhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_WORK_TELEPHONE
        );
        const emailConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_EMAIL_ADDRESS
        );
        const overrideEmailConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OVERRIDE_EMAIL
        );
        const numberConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_NUMBER
        );
        const line1Config = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_LINE_1
        );
        const line2Config = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_LINE_2
        );
        const cityConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_CITY
        );
        const stateConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_STATE
        );
        const postcodeConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_POSTCODE
        );
        const countryConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_COUNTRY
        );
        const goneAwayConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_GONE_AWAY
        );
        const applicationRefConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBERSHIP_APPLICATION_REF
        );
        const marketingConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED
        );
        const preferredContactMethodsConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD
        );
        const methodPostConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST
        );
        const methodMobilePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
        );
        const methodHomePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
        );
        const methodWorkPhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
        );
        const methodSMSConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS
        );
        const methodEmailConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
        );
        const thirdPartyConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED_BY_THIRD_PARTY
        );
        const sourceConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MARKETING_SOURCE
        );
        const emergencyNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_NAME
        );
        const emergencyPhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER
        );
        const companyNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_COMPANY_NAME
        );
        const occupationConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OCCUPATION
        );
        const referredByNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_REFERRED_BY_NAME
        );
        const referredByMembershipConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER
        );
        const otherCommissonNoteConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OTHER_COMMISSION_NOTE
        );
        const generalNoteConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_GENERAL_NOTE
        );
        const aiaVitalityConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_AIA_VITALITY_CATEGORY
        );
        const memberIsTaxExemptConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_IS_TAX_EXEMPT
        );
        const recurringCardTypeConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_RECURRING_CARD_TYPE
        );

        return {
            titleConfig,
            genderConfig,
            firstNameConfig,
            lastNameConfig,
            dateOfBirthConfig,
            nationalConfig,
            nationalIdConfig,
            otherNationalIdConfig,
            preferedLanguageConfig,
            parentalConsentConfig,
            telephoneNumberConfig,
            mobilePhoneConfig,
            homePhoneConfig,
            workPhoneConfig,
            emailConfig,
            overrideEmailConfig,
            numberConfig,
            line1Config,
            line2Config,
            cityConfig,
            stateConfig,
            postcodeConfig,
            countryConfig,
            goneAwayConfig,
            applicationRefConfig,
            marketingConfig,
            preferredContactMethodsConfig,
            methodPostConfig,
            methodMobilePhoneConfig,
            methodHomePhoneConfig,
            methodWorkPhoneConfig,
            methodSMSConfig,
            methodEmailConfig,
            thirdPartyConfig,
            sourceConfig,
            emergencyNameConfig,
            emergencyPhoneConfig,
            companyNameConfig,
            occupationConfig,
            referredByNameConfig,
            referredByMembershipConfig,
            otherCommissonNoteConfig,
            generalNoteConfig,
            aiaVitalityConfig,
            memberIsTaxExemptConfig,
            recurringCardTypeConfig
        };
    };

    goToStep(stepIndex) {
        const { membershipConfiguration } = this.props.newMembership;
        membershipConfiguration.stepIndex = stepIndex;
        store.dispatch<any>(
            MembershipActions.setConfiguration(membershipConfiguration)
        );
    }

    render() {
        return template(this);
    }
}

const mapStateToProps: any = ({ newMembership }) => {
    return { newMembership };
};

export default connect(mapStateToProps)(withTranslation()(MemberSummary));
