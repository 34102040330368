export class LookUpValue {
    code = {
        value: '',
        errorMsg: ''
    };
    sortIndex = {
        value: 0,
        errorMsg: ''
    };
    name = {
        value: '',
        errorMsg: ''
    };
    status = true;
    id = '';
    isNew = false;
}

export default class LookUp {
    values = [new LookUpValue()];
    reasonCode = '';
    sortIndex = 0;
    name = '';
    status = true;
    id = '';
    static clone = (obj: LookUpValue) => {
        return {
            code: obj.code,
            sortIndex: obj.sortIndex,
            name: obj.name,
            status: obj.status,
            id: obj.id,
            isNew: obj.isNew
        };
    };
}
