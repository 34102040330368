import React from 'react';
import ThisInterface from './interface';
import Loading from 'components/Loading';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import PersonalDetails from 'components/Membership/PersonalDetails';
import ContactDetails from 'components/Membership/ContactDetails';
import AddressDetails from 'components/Membership/AddressDetails';
import ContactedDetails from 'components/Membership/ContactedDetails';
import EmergencyContact from 'components/Membership/EmergencyContact';
import HealthQuestion from 'components/Membership/HealthQuestion';
import OtherFields from 'components/Membership/OtherFields';
import DuplicateMembers from 'components/Membership/DuplicateMembers';

const template = (_this: ThisInterface) => {
    const {
        membershipSearchFilter,
        membershipConfiguration,
        selectedPackagePlan,
        selectedAddons } = _this.props.newMembership;

    const {
        onInputChange,
        onCheckboxChange,
        onSelectOptionChange,
        onPhoneNumberChange,
        onDuplicateMemberSelected,
        onDuplicateMemberSectionClose
    } = _this;

    const {
        isLoading,
        isServiceError,
        openDropdown,
        errors,
        titles,
        genders,
        languages,
        personalDetails,
        personalDetailsFieldConfigs,
        overrideEmailOptions,
        contactDetails,
        contactDetailsFieldConfigs,
        countries,
        addressDetails,
        addressDetailsFieldConfigs,
        marketingSources,
        contactMethods,
        contactedDetails,
        contactedDetailsFieldConfigs,
        emergencyContact,
        emergencyContactFieldsConfigs,
        questions,
        healthQuestions,
        aiaVitalityCategories,
        recurringCardTypes,
        otherFields,
        otherFieldsFieldsConfigs,
        duplicateMembers
    } = _this.state;

    const { t } = _this.props;

    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    <p>{t!('PAGE.MEMBERSHIPS.CREATE.STEP_3.PERSONAL_DETAILS')}</p>
                </div>
                <div className="member-main">
                    <Breadcrumb
                        isEdit={false}
                        membershipConfiguration={membershipConfiguration}
                        goToStep={stepIndex => _this.goToStep(stepIndex)}
                    />

                    <div className="content select-add-on personal">
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <React.Fragment>
                                {isServiceError && (
                                    <span className="text-danger input-error">
                                        {t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SERVICE_ERROR')}
                                    </span>
                                )}

                                {duplicateMembers && duplicateMembers.length > 0 ? (
                                    <DuplicateMembers
                                        duplicateMembers={duplicateMembers}
                                        onMemberSelected={onDuplicateMemberSelected}
                                        onClose={onDuplicateMemberSectionClose}
                                    />
                                ) : (
                                    <div className="select-add-on-main">
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t!(
                                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.PERSONAL_DETAILS'
                                            )}
                                            content={
                                                <PersonalDetails
                                                    errors={errors}
                                                    titles={titles}
                                                    genders={genders}
                                                    languages={languages}
                                                    personalDetails={personalDetails}
                                                    personalDetailsFieldConfigs={
                                                        personalDetailsFieldConfigs
                                                    }
                                                    onInputChange={onInputChange}
                                                    onSelectOptionChange={onSelectOptionChange}
                                                    onCheckboxChange={onCheckboxChange}
                                                    membershipConfiguration={membershipConfiguration}
                                                />
                                            }
                                            isOpen={openDropdown['personalDetails']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('personalDetails')
                                            }
                                        />
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t(
                                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.CONTACT_DETAILS'
                                            )}
                                            content={
                                                <ContactDetails
                                                    {...{
                                                        errors,
                                                        overrideEmailOptions,
                                                        contactDetails,
                                                        contactDetailsFieldConfigs,
                                                        onInputChange,
                                                        onSelectOptionChange,
                                                        onPhoneNumberChange
                                                    }}
                                                />
                                            }
                                            isOpen={openDropdown['contactDetails']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('contactDetails')
                                            }
                                        />
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t(
                                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.ADDRESS_DETAILS'
                                            )}
                                            content={
                                                <AddressDetails
                                                    {...{
                                                        errors,
                                                        countries,
                                                        addressDetails,
                                                        addressDetailsFieldConfigs,
                                                        onInputChange,
                                                        onSelectOptionChange,
                                                        onCheckboxChange
                                                    }}
                                                />
                                            }
                                            isOpen={openDropdown['addressDetails']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('addressDetails')
                                            }
                                        />
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t(
                                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.CONTACTED_DETAILS'
                                            )}
                                            content={
                                                <ContactedDetails
                                                    {...{
                                                        errors,
                                                        marketingSources,
                                                        contactMethods,
                                                        contactedDetails,
                                                        contactedDetailsFieldConfigs,
                                                        onInputChange,
                                                        onCheckboxChange,
                                                        onSelectOptionChange
                                                    }}
                                                />
                                            }
                                            isOpen={openDropdown['contactedDetails']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('contactedDetails')
                                            }
                                        />
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t(
                                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.EMERGENCY_CONTACT'
                                            )}
                                            content={
                                                <EmergencyContact
                                                    {...{
                                                        errors,
                                                        emergencyContact,
                                                        emergencyContactFieldsConfigs,
                                                        onInputChange,
                                                        onPhoneNumberChange
                                                    }}
                                                />
                                            }
                                            isOpen={openDropdown['emergencyContact']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('emergencyContact')
                                            }
                                        />
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t(
                                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE'
                                            )}
                                            content={
                                                <HealthQuestion
                                                    {...{
                                                        errors,
                                                        questions,
                                                        healthQuestions,
                                                        onCheckboxChange
                                                    }}
                                                />
                                            }
                                            isOpen={openDropdown['healthQuestion']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('healthQuestion')
                                            }
                                        />
                                        <CollapseButton
                                            className="collapse-promotion"
                                            title={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OTHER_FIELDS')}
                                            content={
                                                <OtherFields
                                                    {...{
                                                        errors,
                                                        aiaVitalityCategories,
                                                        recurringCardTypes,
                                                        otherFields,
                                                        otherFieldsFieldsConfigs,
                                                        onInputChange,
                                                        onSelectOptionChange
                                                    }}
                                                />
                                            }
                                            isOpen={openDropdown['otherFields']}
                                            maxHeight="1200px"
                                            openCollapsedContent={() =>
                                                _this.handleOpenDropdown('otherFields')
                                            }
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        <ProductSummary
                            productSummary={selectedPackagePlan}
                            membershipSearchFilter={membershipSearchFilter}
                            membershipConfiguration={membershipConfiguration}
                            addons={selectedAddons}
                            handlePrevious={() => _this.handlePrevious()}
                            handleNext={() => _this.handleNext()}
                            handleCancel={_this.props.onCancel}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
