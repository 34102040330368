import React from 'react';
import ThisInterface  from './interface';
import { ClubGroupsModel, ClubsModel, PlanAvailabilityModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import OptionModel from 'models/Option';
import { chunk, removeDuplicatedObj, DisplayDateFormat } from 'util/ControlUtils';
import observer from 'util/Observer';

import FormItem from 'components/FormV2';
import Loading from 'components/Loading';
import Language from 'models/Language';
import * as Constants from 'constants/Constants';
import i18n from 'i18next';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoadingClubs: boolean;
    clubs: OptionModel[];
}
interface Props {
    onChangeHandle: Function;
    data: PlanAvailabilityModel;
    dataDefault: PlanAvailabilityModel;
    step: number;
    fields: FieldModel[];
    locale?: Language;
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoadingClubs: true,
            clubs: []
        }
    }

    componentDidMount() {
        observer.subscribe('plan.clubGroups.changed', (data) => {
            if (data) {
                if (this.props.data && this.props.data.clubGroups) {
                    this.loadClubsByClubGroups(this.props.data.clubGroups.map(e => e.id));
                }
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('plan.clubGroups.changed');
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    loadClubsByClubGroups(value) {
        const { subTenantId, rightName } = this.props;
        this.setState({ isLoadingClubs: true });
        this.props.planService.getClubs(value, subTenantId, rightName).then((res) => {
            const clubs: ClubsModel[] = res.data;
            const clubsOptions = clubs.map(e => ({ value: e.id, label: e.name }));
            this.setState({
                clubs: removeDuplicatedObj(clubsOptions),
                isLoadingClubs: false
            });
        }, () => {
            this.setState({
                clubs: [],
                isLoadingClubs: false
            });
        });
    }

    render() {
        const { onChangeHandle, data, dataDefault, fields, step, locale, subTenantId, rightName, t } = this.props;
        let clubGroupsName = sessionStorage.getItem(`clubGroupsName-${data.id}`);
        let clubGroupsNameDefault = sessionStorage.getItem(`clubGroupsNameDefault-${data.id}`);
        const clubGroupsValue = data.clubGroups.map(e => ({value: e.id, label: e.name}));

        const buildOptions = (objects: any) => {
            if (objects && objects.data && objects.data.length) {
                let clubGroupsName: string[] = [];
                data.clubGroups.forEach(e => {
                    const option = objects.data.find(o => o.id === e.id);
                    if (option) {
                        clubGroupsName.push(option.name);
                    }
                });
                sessionStorage.setItem(`clubGroupsName-${data.id}`, clubGroupsName.length ? clubGroupsName.join(', ') : '-');

                let clubGroupsNameDefault: string[] = [];
                dataDefault.clubGroups.forEach(e => {
                    const option = objects.data.find(o => o.id === e.id);
                    if (option) {
                        clubGroupsNameDefault.push(option.name);
                    }
                });
                sessionStorage.setItem(`clubGroupsNameDefault-${data.id}`, clubGroupsNameDefault.length ? clubGroupsNameDefault.join(', ') : '-');

                return objects.data.map((e: ClubGroupsModel) => ({value: e.id, label: e.name}));
            }
            return [];
        }

        const buildFilterObject = (keyword: string) => {
            const keywords = {
                operation: 'contains',
                queryType: 'text',
                queryKey: 'name',
                queryValue: keyword
            };
            const value = data.clubGroups;
            if (value && value.length && !keyword) {
                return  JSON.stringify(
                    {
                        or: 
                                [   
                                    keywords, 
                                    {
                                        operation: 'in',
                                        queryType: 'guid',
                                        queryKey: 'id',
                                        queryValue: value.map(e => e.id).join(',')
                                    }
                                ]
                    }
                );
            } else {
                return JSON.stringify(keywords);
            }
        }

        const availableDateColumns = [
            {
                props: {
                    type: 'calendar',
                    htmlFor: 'availability[availableFrom]',
                    fieldName: 'availability[availableFrom]',
                    datePickerData: data.availableFrom ? new Date(data.availableFrom) : undefined,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.AVAILABLE_FROM_EFFECTIVE_FROM_MIDNIGHT'),
                    locale: i18n.language,
                },
                className: 'col-lg-5 col-12'
            },
            {
                props: {
                    type: 'html',
                    html: <span></span>,
                },
                className: 'col-md-1 col-12'
            },
            {
                props: {
                    type: 'calendar',
                    htmlFor: 'availability[availableTo]',
                    fieldName: 'availability[availableTo]',
                    datePickerData: data.availableTo ? new Date(data.availableTo) : undefined,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.AVAILABLE_TO'),
                    locale: i18n.language,
                },
                className: 'col-lg-5 col-12'
            }
        ];

        const optionsColumns1 = [
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[availableSoldClub]',
                    fieldName: 'availability[availableSoldClub]',
                    data: data.isAvailableForClub ? 1 : 0,
                    attribute: {
                        checked: data.isAvailableForClub
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.AVAILABLE_TO_BE_SOLD_IN_CLUB'),
                },
                className: "form-check mt-3"
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[availableSoldTelesale]',
                    fieldName: 'availability[availableSoldTelesale]',
                    data: data.isAvailableForTeleSales ? 1 : 0,
                    attribute: {
                        checked: data.isAvailableForTeleSales
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.AVAILABLE_TO_BE_SOLD_VIA_TELESALES'),
                },
                className: "form-check mt-3"
            }
        ];

        const optionsColumns2 = [
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[availableSoldWeb]',
                    fieldName: 'availability[availableSoldWeb]',
                    data: data.isAvailableForWeb ? 1 : 0,
                    attribute: {
                        checked: data.isAvailableForWeb
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.AVAILABLE_TO_BE_SOLD_VIA_WEB'),
                },
                className: "form-check mt-3"
            }
        ];

        const clubGroupColumns = [
            {
                props: {
                    type: 'multi-select',
                    htmlFor: 'availability[clubGroups]',
                    fieldName: 'availability[clubGroups]',
                    multiSelectData: clubGroupsValue,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_IS_AVAILABLE_FOR_SALE_IN'),
                    advanceOptions: {
                        apiEndpoint: Constants.API_PLAN_GET_CLUBGROUPS,
                        buildOptions: buildOptions,
                        // buildFilterObject: buildFilterObject,
                        // pageSize: Constants.GENERAL_OPTION_PAGE_SIZE,
                        subTenantId: subTenantId,
                        rightName: rightName
                    }
                },
            },
        ];

        const originalAvailabilityRows = [
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.EFFECTIVE_DATE'),
                data: DisplayDateFormat(dataDefault.availableFrom, Constants.GENERAL_DATE_FORMAT)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.EXPIRATION_DATE'),
                data: DisplayDateFormat(dataDefault.availableTo, Constants.GENERAL_DATE_FORMAT)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_AVAILABLE_OFFLINE'),
                data: dataDefault.isAvailableForClub ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_AVAILABLE_ONLINE'),
                data: dataDefault.isAvailableForWeb ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_AVAILABLE_FOR_TELEPHONE_SALES'),
                data: dataDefault.isAvailableForTeleSales ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.CLUB_GROUPS'),
                data: clubGroupsNameDefault
            },
        ];

        const newAvailabilityRows = [
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.EFFECTIVE_DATE'),
                data: DisplayDateFormat(data.availableFrom, Constants.GENERAL_DATE_FORMAT)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.EXPIRATION_DATE'),
                data: DisplayDateFormat(data.availableTo, Constants.GENERAL_DATE_FORMAT)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_AVAILABLE_OFFLINE'),
                data: data.isAvailableForClub ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_AVAILABLE_ONLINE'),
                data: data.isAvailableForWeb ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.PLAN_AVAILABLE_FOR_TELEPHONE_SALES'),
                data: data.isAvailableForTeleSales ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.CLUB_GROUPS'),
                data: clubGroupsName
            },
        ];


        const clubsChunk = chunk(this.state.clubs, 4);

        return (
            step === 1 ? 
                <div className="collapse-promotion collapse-saas">
                    <div className={'content open'} style={{ maxHeight: '100%' }}>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    {availableDateColumns.map((field, key) =>
                                        <div key={key} className={field.className}>
                                            <FormItem
                                                {...field.props}
                                                onChangeHandle={onChangeHandle}
                                                fields={fields}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        {optionsColumns1.map((field, key) =>
                                            <div key={key} className={field.className}>
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        {optionsColumns2.map((field, key) =>
                                            <div key={key} className={field.className}>
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h4 className="title">{t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.CLUB_GROUPS')}</h4>
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        {clubGroupColumns.map((field, key) =>
                                            <div key={key}>
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.state.isLoadingClubs ?
                                    <Loading />
                                    :
                                    this.state.clubs.length > 0 &&
                                <>
                                    <div className="mt-3"><span className="font-weight-bold">{this.state.clubs.length} </span><span>{t('PAGE.PRODUCTS.PLANS.EDIT.CLUBS')} </span></div>
                                    <div className="mt-3">
                                        {clubsChunk.map((row, rowKey) => (
                                            <div key={rowKey} className="row mb-2">
                                                {row.map((value, key) => (
                                                    <div key={key} className="col-md-3 col-6">{value.label}</div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                :
            
                <div className="collapse-promotion collapse-saas">
                    <button className={'collapsible'}>{t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.ORIGINAL_AVAILABILITY')}</button>
                    <div className={'content open'} style={{ maxHeight: '100%' }}>
                        {originalAvailabilityRows.map((field, key) =>
                            <div key={key} className="row col-12 mb-2 ml-2">
                                <div className="col-lg-4 col-md-6 col-7">{field.fieldLabel}</div>
                                <div className="col-lg-8 col-md-6 col-5">{field.data}</div>
                            </div>
                        )}
                    </div>
                    <button className={'collapsible'}>{t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.NEW_AVAILABILITY')}</button>
                    <div className={'content open'} style={{ maxHeight: '100%' }}>
                        {newAvailabilityRows.map((field, key) =>
                            <div key={key} className="row col-12 mb-2 ml-2">
                                <div className="col-lg-4 col-md-6 col-7">{field.fieldLabel}</div>
                                <div className="col-lg-8 col-md-6 col-5">{field.data}</div>
                            </div>
                        )}
                    </div>
                </div>
        );
    }
}
export default Template;