import React from 'react';

import * as Route from 'pages/RouteLoader';
import { getIframeAPIURL } from 'util/ControlUtils';
import { IFRAME_CONMAN_ADD_CLUB, IFRAME_CONMAN_EDIT_CLUB } from 'constants/Constants';
import Loading from 'components/Loading';
import embededWrapper from 'hocs/Embeded';
import ThisInterface from '../interface';

const embeded = (route, title = '', description = '') => {
    class CMAddEditClubsPage extends React.Component<ThisInterface['props']> {
        constructor(props: ThisInterface['props']) {
            super(props);
        }
		
        componentDidMount() {
            window.addEventListener("message", this.messageHandler, false);
        }
	
        componentWillUnmount() {
            window.removeEventListener('message', this.messageHandler);
        }
	
        messageHandler = (event) => {
            const { action, value } = event.data;
            switch (action) {
                case 'returnHeightData': 
                    const obj: any = this.refs.iframe;
                    if (obj) {
                        obj.style.height = value + 'px';
                    }
                    break;
                case 'redirectURL':
                    if(value.includes('/Clubs')) {
                        this.props.history.push(`/${Route.CMClubsPageRoute.url}`);
                        break;
                    }
                    break;
            }
        }
	
        render() {
            const { iframeToken, subTenantId, iframeLoaded, resizeIframe, id } = this.props;
            const editUrl = id ? IFRAME_CONMAN_EDIT_CLUB.replace('{0}', id) : '';
            const isEditMode = !!id;
	
            return <>
				{subTenantId && <div className="member mt-3">
				    <div className="member-main member-main--custom">
				        <div className="content">
				            {!iframeLoaded && <Loading />}
				            {iframeToken && !isEditMode && <iframe src={`${getIframeAPIURL(IFRAME_CONMAN_ADD_CLUB, subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => resizeIframe(this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
				            {iframeToken && isEditMode && <iframe src={`${getIframeAPIURL(editUrl, subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => resizeIframe(this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
				        </div>
				    </div>
				</div>}
			</>
        }
    }

    return embededWrapper(CMAddEditClubsPage, route, title, description);
}

export default embeded;