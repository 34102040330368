import React from 'react';
import { BASE_WEBPAGE_URL } from 'constants/Constants';
import Loading from 'components/Loading';
import ThisInterface from './interface';

const FormInput = ({ value, onChange, placeholder, errorMsg, icon }) => {
    return (
        <form>
            <div className={`input-group ${icon}`}>
                <input
                    className="form-control"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
                <span className="text-danger input-error">{errorMsg}</span>
            </div>
        </form>
    );
};

const ForgotPassword = ({ state, resetPassword, handleChangeText, t }) => {
    const {
        username,
        emailOrPhone,
        usernameError,
        emailOrPhoneError,
        isLoading
    } = state;

    return (
    <>
      <FormInput
          value={username}
          icon="input-acc"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeText(e, 'username')
          }
          placeholder={t('PAGE.FORGOT_PASSWORD.ACCOUNT_NAME')}
          errorMsg={t(usernameError, { key: t('PAGE.FORGOT_PASSWORD.USERNAME') })}
      />
      <FormInput
          value={emailOrPhone}
          icon="input-email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeText(e, 'emailOrPhone')
          }
          placeholder={t('PAGE.FORGOT_PASSWORD.EMAIL_PHONE')}
          errorMsg={t(emailOrPhoneError, {
              key: t('PAGE.FORGOT_PASSWORD.EMAIL_OR_PHONE')
          })}
      />
      {isLoading ? (
          <Loading />
      ) : (
        <>
          <button className="btn btn-white" onClick={resetPassword}>
              {t('PAGE.FORGOT_PASSWORD.RESET_PASSWORD_BTN')}
          </button>
          <div className="link-back">
              <a href={BASE_WEBPAGE_URL}>
                  <span>
                      <i>{t('PAGE.FORGOT_PASSWORD.BACK_TO_SIGNIN')}</i>
                  </span>
              </a>
          </div>
        </>
      )}
    </>
    );
};

const template = (_this: ThisInterface) => {
    const { isResend, isLoading } = _this.state;
    const { t } = _this.props;

    return (
    <>
      <div className="loginForm__desc">
          <h3>
              {t(
                  isResend
                      ? 'PAGE.FORGOT_PASSWORD.EMAIL_A_LINK'
                      : 'PAGE.FORGOT_PASSWORD.TITLE'
              )}
          </h3>
          <p>
              {t(
                  isResend
                      ? 'PAGE.FORGOT_PASSWORD.CHECK_EMAIL'
                      : 'PAGE.FORGOT_PASSWORD.DESCRIPTION'
              )}
          </p>
      </div>
      {isResend ? (
          isLoading ? (
              <Loading />
          ) : (
              <button className="btn btn-white" onClick={_this.resetPassword}>
                  {t('PAGE.FORGOT_PASSWORD.RESEND_BTN')}
              </button>
          )
      ) : (
          <ForgotPassword
              t={_this.props.t}
              state={_this.state}
              resetPassword={_this.resetPassword}
              handleChangeText={_this.handleChangeText}
          />
      )}
    </>
    );
};

export default template;
