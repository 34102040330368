import React from 'react';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import CommonService from 'services/common.service';
import { API_CREATE_FREEZE_MEMBERSHIP, API_MODIFY_AND_DELETE_FREEZE, API_GET_FREEZE } from 'constants/Constants';
import { FreezeDetails, FreezeRequestModel } from 'models/Freeze';
import { withTranslation } from 'react-i18next';
import { submitModal } from 'components/CommonModal';
import { template } from './template';
import { ThisInterface } from './interface';

class RemoveFreeze extends React.Component<ThisInterface['props'], ThisInterface['state']> {
    commonService: CommonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        this.commonService = new CommonService();
        this.state = {
            isLoading: false,
            paymentSummary: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    callApis = (requests: {
        url: string;
        headers?: any;
        method?: 'GET' | 'POST';
        body?: any;
    }[]) => {
        const queue: Promise<any>[] = [];

        requests.forEach(requestOptions => {
            let request;
            if (requestOptions.method === 'POST') {
                request = this.commonService.postData(requestOptions.url, requestOptions.body || {}, requestOptions.headers);
            } else {
                request = this.commonService.fetchData(requestOptions.url, requestOptions.headers);
            }
            queue.push(request);
        });

        return Promise.all(queue);
    };

    fetchData = () => {
        const requests: any[] = [];
        requests.push(this.getFreezeDetailsRequest());
        requests.push(this.getPaymentSummaryRequest());

        this.setState({
            isLoading: true
        });

        this.callApis(requests)
            .then((data) => {
                this.setState({
                    isLoading: false
                });
                this.mapData(data);
            })
            .catch((err) => {
                this.props.onCancel();
                throw err;
            });
    };

    getFreezeDetailsRequest = () => {
        const { id, subTenantId } = this.props.memberData;
        const url = API_CREATE_FREEZE_MEMBERSHIP.replace('{id}', id);
        const headers = {
            subTenantId,
            rightName: 'remove_freeze_membership'
        };
        return {
            url,
            headers
        };
    };

    getPaymentSummaryRequest = () => {
        const { id, subTenantId } = this.props.memberData;
        const url = API_GET_FREEZE.replace('{id}', id);
        const headers = {
            subTenantId,
            rightName: 'remove_freeze_membership'
        };
        const body = {
            startDate: null,
            endDate: null,
            month: 6
        };
        return {
            url,
            headers,
            method: 'POST',
            body
        };
    };

    mapData = (responses: any) => {
        const [freezeDetails, paymentSummary]: [FreezeDetails, FreezeRequestModel[]] = responses;
        this.setState({
            freezeDetails,
            paymentSummary
        });
    };

    callRemoveFreezeRequest = () => {
        if (!this.state.freezeDetails) return Promise.reject('Missing freeze details');
        const { id, subTenantId } = this.props.memberData;
        const url = API_MODIFY_AND_DELETE_FREEZE.replace('{id}', id).replace('{freezeId}', this.state.freezeDetails.id);
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, 'remove_freeze_membership');
        return this.commonService.delete(url, headers);
    };

    removeFreeze = () => {
        const { t } = this.props;
        submitModal(t('PAGE.REMOVE_FREEZE.CONFIRM_MESSAGE'), () => {
            this.callRemoveFreezeRequest()
                .then(() => {
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('PAGE.REMOVE_FREEZE.SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE'),
                    }).then(() => this.props.onCancel());
                });
        });
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(RemoveFreeze);