import React from 'react';
import SubTenantRole from './SubTenantRole';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Fab from '@material-ui/core/Fab';
import { SubTenantRoleModel } from 'models/Role';

interface Props {
    selectedSubTenantRoles: any;
    searchRoleApi: string;
    searchClubGroupApi: string;
    onRoleAdded: (subTenantId: string, roles: SubTenantRoleModel[]) => void;
    onRoleDeleted: (subTenantId: string, roles: SubTenantRoleModel[]) => void;
    onRoleDisabled: (subTenantId: string) => void;
    parent: 'userGroup' | 'user';
}

interface State {
    isSubTenantRoleExpanded: boolean;
}

const filterBySubtenant = {
    and: [
        {
            operation: 'eq',
            queryType: 'guid',
            queryKey: 'subTenantId',
            queryValue: '{subTenantIdValue}'
        },
        {
            operation: 'contains',
            queryType: 'text',
            queryKey: 'role.name',
            queryValue: '{value}'
        }
    ]
};

const filterBySubtenantClubGroup = {
    operation: 'eq',
    queryType: 'guid',
    queryKey: 'subTenantId',
    queryValue: '{subTenantIdValue}'
};

export default class SubTenantRolePanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isSubTenantRoleExpanded: true
        };
    }

    onSubTenantRoleCollapsed = () => {
        this.setState({
            isSubTenantRoleExpanded: !this.state.isSubTenantRoleExpanded
        });
    };

    renderSubTenantRoles = () => {
        const {
            selectedSubTenantRoles,
            searchRoleApi,
            searchClubGroupApi,
            onRoleAdded,
            onRoleDeleted,
            onRoleDisabled,
            parent
        } = this.props;

        return selectedSubTenantRoles.map(str => {
            const filterRole = JSON.stringify(filterBySubtenant).replace(
                '{subTenantIdValue}',
                str.subTenantId
            );
            const filterClubGroup = JSON.stringify(
                filterBySubtenantClubGroup
            ).replace('{subTenantIdValue}', str.subTenantId);

            return (
                <SubTenantRole
                    key={str.subTenantId}
                    subTenant={{ id: str.subTenantId, name: str.subTenantName }}
                    selectedRoles={str.roles}
                    searchRoleApi={searchRoleApi}
                    searchClubGroupApi={searchClubGroupApi}
                    filterRole={filterRole}
                    filterClubGroup={filterClubGroup}
                    onRoleAdded={onRoleAdded}
                    onRoleDeleted={onRoleDeleted}
                    onRoleDisabled={onRoleDisabled}
                    parent={parent}
                />
            );
        });
    };

    render() {
        const { isSubTenantRoleExpanded } = this.state;

        return (
            <div
                className={`form-group subtennant-roles-panel ${
                    !isSubTenantRoleExpanded || this.renderSubTenantRoles().length == 0
                        ? 'collapsed'
                        : ''
                }`}
            >
                <Fab
                    size="small"
                    className="expand-collapse-button"
                    onClick={this.onSubTenantRoleCollapsed}
                >
                    {isSubTenantRoleExpanded ? <ExpandLess /> : <ExpandMore />}
                </Fab>
                <Collapse
                    in={isSubTenantRoleExpanded}
                    className="expand-collapse-panel"
                >
                    {this.renderSubTenantRoles()}
                </Collapse>
            </div>
        );
    }
}
