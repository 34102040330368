import React from 'react';
import ListComponent from 'list-component';
import { handleSessionExpired } from 'util/ControlUtils';
import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';
import { PRESET_ENDPOINT_URL, GENERAL_DATE_FORMAT } from 'constants/Constants';
import { BASE_API_URL_DEVELOPMENT } from 'constants/Constants';
import i18n from 'i18next';

import ThisInterface from './interface';

const template = (_this: ThisInterface) => {

    const { t } = _this.props;

    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT
    };

    const errorHandle = err => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    };

    return (
        <section>
            <p className="title-content">
                {t('PAGE.SYSTEM.USERS.TITLE')}
            </p>
            <div className="add-new-section">
                <div className="box-btn-filter ml-auto d-flex">
                    <span>{t('PAGE.SYSTEM.USERS.INSTRUCTION')}</span>
                    {isHasRight(RouteLoader.AddUserRoute.rightName) && (
                        <button
                            onClick={() => _this.props.history.push('/system/users/create')}
                            className="btn btn-height-sm btn-add-new"
                        >
                            {t('PAGE.SYSTEM.USERS.BUTTON.ADD_USER')}
                        </button>
                    )}
                </div>
            </div>
            {_this.state.version > 0 ? (
                <ListComponent
                    oidcAuth={_this.props.auth.user}
                    version={_this.state.version}
                    rightName="view_user_list"
                    config={{
                        presetUrl: PRESET_ENDPOINT_URL,
                        timeFormat: GENERAL_DATE_FORMAT,
                        langCode: i18n.language,
                        tableHeight: `${_this.state.tableHeight}px`,
                        tableWidth: '100%'
                    }}
                    variable={variableConfig}
                    actionEvent={_this.callback}
                    errorEvent={errorHandle}
                />
            ) : null}
        </section>
    );
};

export default template;
