import React from 'react';
import { LookupModel, OtherFieldsModel, OtherFieldsFieldsModel } from 'models/PersonalDetails';
import { FormSelect, FormInput } from 'components/Form';
import { useTranslation } from 'react-i18next';

interface Props {    
    errors: any;    
    aiaVitalityCategories: LookupModel[];
    recurringCardTypes: LookupModel[];
    otherFields: OtherFieldsModel;
    otherFieldsFieldsConfigs: OtherFieldsFieldsModel;  
    onInputChange: (key: string, value: string) => void;
    onSelectOptionChange: (key: string, item: any) => void;    
}

const arrayValue = (array, event) => {
    return array.find(el => el.value === event.value);
}

const OtherFields = ({
    errors,    
    aiaVitalityCategories,
    recurringCardTypes,
    otherFields,    
    otherFieldsFieldsConfigs,
    onInputChange,         
    onSelectOptionChange,
}: Props) => {
    const {
        companyName, 
        occupation, 
        referredByMemberName, 
        referredByMembershipNumber, 
        otherCommissionNote, 
        generalNote, 
        aiaVitalityCategory,
        recurringCardType
    } = otherFields;

    const {
        companyNameConfig,
        occupationConfig,
        referredByMemberNameConfig,
        referredByMembershipNumberConfig,
        otherCommissionNoteConfig,
        generalNoteConfig,
        aiaVitalityCategoryConfig,
        recurringCardTypeConfig
    } = otherFieldsFieldsConfigs;
    
    const { t } = useTranslation();

    return (     
        <div className="personal-group">
            <div className="row">
                <div className="col-md-6">
                    {companyNameConfig.isVisible &&      
                        <FormInput                            
                            inputClass="form-control"                                
                            value={companyName}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.COMPANY_NAME')}                                                    
                            maxlength={companyNameConfig.maxLength}
                            require = {companyNameConfig.isRequired} 
                            isValid={companyNameConfig.isRequired && companyName.length > 0}                        
                            validMsg={t(errors.companyName)}
                            onChange={(event) => onInputChange("companyName", event!.target.value)} />
                    }                    
                </div>
                <div className="col-md-6">
                    {occupationConfig.isVisible &&      
                        <FormInput                            
                            inputClass="form-control"                                
                            value={occupation}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OCCUPATION')}                                                    
                            maxlength={occupationConfig.maxLength}
                            require = {occupationConfig.isRequired} 
                            isValid={occupationConfig.isRequired && occupation.length > 0}                        
                            validMsg={t(errors.occupation)}
                            onChange={(event) => onInputChange("occupation", event!.target.value)} />
                    }    
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">                    
                    {referredByMemberNameConfig.isVisible &&      
                        <FormInput                            
                            inputClass="form-control"                                
                            value={referredByMemberName}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.REFERRED_BY_NAME')}                                                    
                            maxlength={referredByMemberNameConfig.maxLength}
                            require = {referredByMemberNameConfig.isRequired} 
                            isValid={referredByMemberNameConfig.isRequired && referredByMemberName.length > 0}                        
                            validMsg={t(errors.referredByMemberName)}
                            onChange={(event) => onInputChange("referredByMemberName", event!.target.value)} />
                    }                    
                </div>
                <div className="col-md-6">
                    {referredByMembershipNumberConfig.isVisible &&      
                        <FormInput                            
                            inputClass="form-control"                                
                            value={referredByMembershipNumber}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.REFERRED_BY_MEMBERSHIP_NUMBER')}                                                    
                            maxlength={referredByMembershipNumberConfig.maxLength}
                            require = {referredByMembershipNumberConfig.isRequired} 
                            isValid={referredByMembershipNumberConfig.isRequired && referredByMembershipNumber.length > 0}                        
                            validMsg={t(errors.referredByMembershipNumber)}
                            onChange={(event) => onInputChange("referredByMembershipNumber", event!.target.value)} />
                    }                    
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {otherCommissionNoteConfig.isVisible &&      
                        <FormInput                            
                            inputClass="form-control"                                
                            value={otherCommissionNote}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OTHER_COMMISSION_NOTE')}                                                    
                            maxlength={otherCommissionNoteConfig.maxLength}
                            require = {otherCommissionNoteConfig.isRequired} 
                            isValid={otherCommissionNoteConfig.isRequired && otherCommissionNote.length > 0}                        
                            validMsg={t(errors.otherCommissionNote)}
                            onChange={(event) => onInputChange("otherCommissionNote", event!.target.value)} />
                    }                    
                </div>
                <div className="col-md-6">
                    {generalNoteConfig.isVisible &&      
                        <FormInput                            
                            inputClass="form-control"                                
                            value={generalNote}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.GENERAL_NOTE')}                                                    
                            maxlength={generalNoteConfig.maxLength}
                            require = {generalNoteConfig.isRequired} 
                            isValid={generalNoteConfig.isRequired && generalNote.length > 0}                        
                            validMsg={t(errors.generalNote)}
                            onChange={(event) => onInputChange("generalNote", event!.target.value)} />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {aiaVitalityCategoryConfig.isVisible && 
                        <FormSelect
                            id="aiaVitality-category"
                            name="aiaVitality-category"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.AIA_VITALITY_CATEGORY')}
                            disabled={aiaVitalityCategories.length === 0}
                            require={aiaVitalityCategoryConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("aiaVitalityCategory", arrayValue(aiaVitalityCategories, event))}
                            value={aiaVitalityCategory}
                            options={aiaVitalityCategories}
                            errorMsg={t(errors.aiaVitalityCategory)}
                        />
                    }
                </div>
                <div className="col-md-6">
                    {recurringCardTypeConfig.isVisible && 
                        <FormSelect
                            id="recurring-card-type"
                            name="recurring-card-type"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.RECURRING_CARD_TYPE')}
                            disabled={recurringCardTypes.length === 0}
                            require={aiaVitalityCategoryConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("recurringCardType", arrayValue(recurringCardTypes, event))}
                            value={recurringCardType}
                            options={recurringCardTypes}
                            errorMsg={t(errors.recurringCardType)}
                        />
                    }
                </div>
            </div>
        </div>
        
    )
}

export default  OtherFields;