import AddOn from "./AddOn";

export class ChildModel {
    id = '';
    name = '';
}

export class FutureEvent {
    duration = 0;
    endDateUtc = '';
    feeValue = 0;
    id = '';
    memberId = '';
    name = '';
    reason = '';
    reasonId = '';
    startedDateUtc = '';
    type = '';
    clubName = '';
    planName = '';
}

export class Fee {
    planId = "";
    planName = "";
    feeId = "";
    feeName = "";
    memberId = "";
    priority = 10;
    minimumMembershipValue = 0;
    maximumMembershipValue = 0;
    price = 0;
    effectiveDate = "";
    expirationDate = "";
    isPlanAllowFreeze = false;
    isPlanAllowFreezeToday = false;
    id = "";
    name = null;
}

export class PeriodItem {
    value = 0;
    unitName = '';
    name = '';
    id = '';
}

export default class MemberSummaryModel {
    firstName = '';
    lastName = '';
    nationalId = '';
    otherNationalId = '';
    arrearsValue = 0;
    currentBalance = 0;
    dateOfBirth = '';
    isParentConsentProvided = false;
    joinedDate = '';
    startedDate = '';
    generalNote = '';
    otherCommisisionNote = '';
    referredBymembershipNumber = '';
    referredByName = '';
    occupation = '';
    companyName = '';
    emergencyContactPhoneNumber = '';
    emergencyContactName = '';
    goneAway = false;
    isAllowFreeze = false;
    futureEvents = [new FutureEvent()];
    events: FutureEvent[] = [];
    isAllowCancel = false;
    monthsContract = 0;
    membershipNumber = '';
    lastCharge = 0;
    nextCharge = 0;
    memberStatus = {
        name: '',
        id: '',
        code: ''
    };
    cancelNoticePeriod = '';
    paymentStatus = {
        name: '',
        id: '',
        code: ''
    };
    applicationRef = '';
    postCode = '';
    state = '';
    city = '';
    line2 = '';
    line1 = '';
    addressNumber = '';
    email = '';
    workingPhone = '';
    homePhone = '';
    mobilePhone = '';
    workingPhoneRegionCode = '';
    homePhoneRegionCode = '';
    mobilePhoneRegionCode = '';
    planId = '';
    lengthOfContract = '';
    endDate = '';
    withinMBG = false;
    withinMCP = false;
    remainingCCV = null;
    salesPerson = new ChildModel();
    title = new ChildModel();
    gender = new ChildModel();
    source = new ChildModel();
    recurringCardType = new ChildModel();
    overrideEmail = new ChildModel();
    aiaVitalityCategory = new ChildModel();
    languagePreference = new ChildModel();
    country = new ChildModel();
    preferredContactMethod = [];
    package = {
        packageTier: new ChildModel(),
        packageTimeGroup: new ChildModel(),
        id: '',
        name: ''
    };
    plan = {
        minimumContract: {
            minimumContractValue: 0,
            minimumContractUnitName: '',
            id: '',
            name: ''
        },
        isLumpsum: false,
        id: '',
        name: '',
        period: {
            moneyBackGuarantee: new PeriodItem(),
            futureStartPeriod: new PeriodItem(),
            membershipGrace: new PeriodItem(),
            freezeNotice: new PeriodItem(),
            noFreeze: new PeriodItem(),
            billingChangeNotice: new PeriodItem(),
            cancellationNotice: new PeriodItem(),
            minimumFreeze: new PeriodItem(),
            maximumFreeze: new PeriodItem(),
            minimumMembership: new PeriodItem(),
            maximumMembership: new PeriodItem(),
        },
        freezeAvailable: false,
        immediateFreezeAvailable: false
    };
    club = new ChildModel();
    payment = {
        primary: {
            accountName: '',
            cardNumber: '',
            issueNumber: '',
            nationalId: '',
            otherNationalId: '',
            method: new ChildModel(),
            cardType: null,
            bank: new ChildModel(),
            id: '',
            name: null
        },
        secondary: {
            accountName: null,
            cardNumber: null,
            issueNumber: null,
            nationalId: null,
            otherNationalId: null,
            method: new ChildModel(),
            cardType: null,
            bank: null,
            id: '',
            name: null
        }
    };
    id = '';
    billingRunDay = 1;
    name = null;
    subTenantId = '';
    tenantId = '';
    memberPlanFees = [new Fee()];
    addons: AddOn[] = [];
}
