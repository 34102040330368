import axios from 'axios';
import * as API from 'constants/Constants';
import CommonService from './common.service';

export default class PlanService {
    commonService: CommonService;

    constructor() {
        this.commonService = new CommonService();
    }

    getPlanData(planId, subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_DATA;
        return axios
            .get(url.replace('{0}', planId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPlanAvailabilityData(planId, subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_AVAILABILITY_GET_DATA;
        return axios
            .get(url.replace('{0}', planId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPackagesPeriods(planId, subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_PACKAGES_PERIODS;
        return axios
            .get(url.replace('{0}', planId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPackages(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_PACKAGES;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getClasses(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_CLASSES;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPaymentsMethod(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_PAYMENTS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTerms(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_TERMS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSubsidies(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_SUBSIDIES;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getClubGroups(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_CLUBGROUPS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getClubs(clubGroupId, subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_CLUBS.replace('{0}', clubGroupId);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getUnits(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_GET_UNITS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFields(subTenantId, rightName) {
        const fields = [
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.class",
                "isRequired": true,
                "isVisible": true,
                "regex": "^[-_. 0-9a-zA-Z]+$",
                "maxLength": 100,
                "id": "f5340247-ed9d-4ad5-9f56-80992abe8463",
                "name": "Plan Class"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.package",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 100,
                "id": "6ce4025c-51af-4a42-ba51-b02c218246ec",
                "name": "Plan Package"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.availableTo",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 100,
                "id": "1be46d06-a398-4a66-9fef-b98db274e025",
                "name": "Plan Available To"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.name",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "9a3b9191-febb-45d3-8f17-c185be1db34d",
                "name": "Plan Name"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.paymentMethod",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 100,
                "id": "ac70fcb2-63c5-431c-ac0c-c1e174f56884",
                "name": "Plan Payment Method"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.comment",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 100,
                "id": "ea6aacbe-7cde-49ca-820c-ded849b7414e",
                "name": "Plan Comment"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.monthDayValue",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 100,
                "id": "b78c72c3-15c3-48a4-9bce-f1c78b011550",
                "name": "Plan MonthDayValue"
            },
            {
                "tenantId": rightName,
                "subTenantId": subTenantId,
                "key": "planEdit.availableFrom",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 100,
                "id": "660d2817-c2fe-432b-8d6a-fa0b8b46e6be",
                "name": "Plan Available From"
            }
        ];

        return Promise.resolve({ data: fields });
    }

    putPlanData(planId, data) {
        const subTenantId = data.subTenantId;
        const rightName = data.rightName;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_PUT_DATA;
        return axios
            .put(url.replace('{0}', planId), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    putPlanAvailabilityData(planId, data) {
        const subTenantId = data.subTenantId;
        const rightName = data.rightName;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_AVAILABILITY_PUT_DATA;
        return axios
            .put(url.replace('{0}', planId), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postQuickCloneData(planId, data) {
        const subTenantId = data.subTenantId;
        const rightName = data.rightName;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_QUICK_CLONE_POST_DATA;
        return axios
            .post(url.replace('{0}', planId), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postCloneData(data) {
        const subTenantId = data.subTenantId;
        const rightName = data.rightName;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_CLONE_POST_DATA;
        return axios
            .post(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    putQuickEditData(planId, data) {
        const subTenantId = data.subTenantId;
        const rightName = data.rightName;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_PLAN_QUICK_EDIT_PUT_DATA;
        return axios
            .put(url.replace('{0}', planId), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
}
