import * as FieldKeys from 'util/FieldConfiguration/Keys';

export const validPersonalDetail = [
    {
        configKey: 'title',
        detailKey: 'title.value'
    },
    {
        configKey: 'gender',
        detailKey: 'gender.value'
    },
    {
        configKey: 'firstName',
        detailKey: 'firstName'
    },
    {
        configKey: 'lastName',
        detailKey: 'lastName'
    },
    {
        configKey: 'preferedLanguage',
        detailKey: 'preferedLanguage.value'
    }
];

export const validAddressDetail = [
    {
        configKey: 'addressNumber',
        detailKey: 'addressNumber'
    },
    {
        configKey: 'addressLine1',
        detailKey: 'addressLine1'
    },
    {
        configKey: 'addressLine2',
        detailKey: 'addressLine2'
    },
    {
        configKey: 'city',
        detailKey: 'city'
    },
    {
        configKey: 'country',
        detailKey: 'country.id'
    },
    {
        configKey: 'postCode',
        detailKey: 'postCode'
    },
    {
        configKey: 'goneAway',
        detailKey: 'goneAway'
    }
];

export const validOtherField = [
    {
        configKey: 'companyName',
        detailKey: 'companyName'
    },
    {
        configKey: 'occupation',
        detailKey: 'occupation'
    },
    {
        configKey: 'referredByMemberName',
        detailKey: 'referredByMemberName'
    },
    {
        configKey: 'referredByMembershipNumber',
        detailKey: 'referredByMembershipNumber'
    },
    {
        configKey: 'otherCommissionNote',
        detailKey: 'otherCommissionNote'
    },
    {
        configKey: 'generalNote',
        detailKey: 'generalNote'
    },
    {
        configKey: 'aiaVitalityCategory',
        detailKey: 'aiaVitalityCategory.id'
    },
    {
        configKey: 'recurringCardType',
        detailKey: 'recurringCardType.id'
    }
];

export const validContactedDetail = [
    {
        configKey: 'applicationRef',
        detailKey: 'applicationRef'
    },
    {
        configKey: 'memberHappyToBeContact',
        detailKey: 'memberHappyToBeContact'
    },
    {
        configKey: 'thirdPartyContact',
        detailKey: 'thirdPartyContact'
    },
    {
        configKey: 'marketingSource',
        detailKey: 'marketingSource.id'
    },
    {
        configKey: 'preferredContactMethod',
        detailKey: 'preferredContactMethod'
    }
];

export const contactMethodErrorStrings = [
    {
        key: FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL,
        validKey: 'isEmailValid',
        emptyKey: 'email',
        contactMethod: 'EMAIl',
    },
    {
        key: FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE,
        validKey: 'homePhone.isValid',
        emptyKey: 'homePhone.phoneNumber',
        contactMethod: 'HOME_TELEPHONE',
    },
    {
        key: FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE,
        validKey: 'workPhone.isValid',
        emptyKey: 'workPhone.phoneNumber',
        contactMethod: 'WORK_TELEPHONE',
    },
    {
        key: FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS,
        validKey: 'mobilePhone.isValid',
        emptyKey: 'mobilePhone.phoneNumber',
        contactMethod: 'MOBILE_TELEPHONE',
    },
    {
        key: FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE,
        validKey: 'mobilePhone.isValid',
        emptyKey: 'mobilePhone.phoneNumber',
        contactMethod: 'MOBILE_TELEPHONE',
    },
]
