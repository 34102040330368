import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import MemberSummaryModel from 'models/MemberSummary';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import * as Route from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';

import { withWrapper } from 'hocs';
import template from './template';
import ThisInterface from './interface';

class MemberSummaryPage extends React.Component<
ThisInterface['props'] & RouteComponentProps<{}> & WithTranslation,
ThisInterface['state']
> {
    memberAccountRef;
    memberShipRef;
    personalDetailRef;
    constructor(
        props: ThisInterface['props'] & RouteComponentProps<{}> & WithTranslation
    ) {
        super(props);
        this.state = {
            memberData: new MemberSummaryModel(),
            dateFormat: GENERAL_DATE_FORMAT,
            isLoading: true,
            previousSection: '',
            section: '',
            action: '',
            memberAccountOpen: false,
            memberShipOpen: false,
            personalDetailOpen: false,
            isWidthBelow1200: window.innerWidth <= 1200
        };
        this.memberAccountRef = React.createRef();
        this.memberShipRef = React.createRef();
        this.personalDetailRef = React.createRef();
    }

    componentDidMount() {
        const {
            location: { state }
        } = this.props;
        window.scrollTo(0, 0);
        this.addClassBody();
        this.setState({
            section: state && state.previousSection ? state.previousSection : ''
        });
        this.getInitData();
        document.addEventListener('click', this.handleClickOutSide);
        const mainContent = document.querySelector('.main-content');
        const tableListStyle = document.querySelector('.member-summary-table-list'); 
        if (mainContent && tableListStyle) {
            mainContent.classList.add('member-summary-main-content');
            tableListStyle.classList.remove('table-list');
        }
        window.onresize = this.isWidthBelow1200;
    }
	
    isWidthBelow1200 = () => {
        this.setState({
            isWidthBelow1200: window.innerWidth <= 1200
        });
    }
	
    componentDidUpdate() {
        const { section } = this.state;
        const mainContent = document.querySelector('.main-content');
        const tableListStyle = document.querySelector('.member-summary-table-list');
        if (tableListStyle && mainContent) {
            if (section === '') {
                tableListStyle.classList.remove('table-list');
                mainContent.classList.add('member-summary-main-content');
            } else {
                tableListStyle.classList.add('table-list');
                mainContent.classList.remove('member-summary-main-content');
            }
        }
    }
	
    componentWillUnmount() {
        const mainContent = document.querySelector('.main-content');
        const tableListStyle = document.querySelector('.member-summary-table-list'); 
        if (mainContent && tableListStyle) {
            mainContent.classList.remove('member-summary-main-content');
            tableListStyle.classList.add('table-list');
        }
    }

    containsRef = (key: string, e) => {
        if (
            this[`${key}Ref`] &&
            this[`${key}Ref`].current &&
            this[`${key}Ref`].current.dropdownRef &&
            this[`${key}Ref`].current.dropdownRef.current &&
            !this[`${key}Ref`].current.dropdownRef.current.contains(e.target)
        ) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    [`${key}Open`]: false
                };
            });
        }
    };

    handleClickOutSide = e => {
        this.containsRef('memberAccount', e);
        this.containsRef('memberShip', e);
        this.containsRef('personalDetail', e);
    };

    addClassBody = () => {
        const element = document.body;
        element.classList.toggle('member-summary-page');
    };

    getInitData = () => {
        const { membershipService, userService, match, location } = this.props;

        const memberId = match.params['id'];
        const { state } = location;
        if (state && state.subTenantId) {
            Promise.all([
                membershipService.getMemberById(
                    state.subTenantId,
                    memberId,
                    Route.MemberSummaryRoute.rightName
                ),
                userService.getSystemConfigs(
                    state.subTenantId,
                    Route.MemberSummaryRoute.rightName
                )
            ])
                .then(([res1, res2]) => {
                    if (res1) this.setState({ memberData: res1, isLoading: false });

                    const systemConfigs = res2;
                    if (systemConfigs) {
                        const dateFormat = systemConfigs.find(e => e.key === 'date.format');
                        if (dateFormat) {
                            this.setState({
                                dateFormat: dateFormat.value
                            });
                        }
                    }
                })
                .catch(() => {
                    this.setState({
                        memberData: new MemberSummaryModel(),
                        isLoading: false
                    });
                });
        } else {
            this.props.history.push('/');
        }
    };

    handleOpenLink = (href: string) => {
        const { history } = this.props;
        history.push(href);
    };

    handleChangeSection = (key: string, previousSection = '') => {
        this.setState({
            section: key,
            previousSection: previousSection
        });
        key === '' && this.getInitData();
    };

    handleOpenAction = (key: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                [`${key}Open`]: !prevState[`${key}Open`]
            };
        });
    };

    isAllowedFreeze = () => {
        const { memberData } = this.state;
        const hasClaim = !!isHasRight(Route.ViewRequestFreezeMemberShip.rightName);

        return memberData.isAllowFreeze && hasClaim;
    };

    isAllowedChangeMembership = () => {
        const { memberData } = this.state;
        const hasClaim = !!isHasRight(Route.ChangeMembershipRoute.rightName);

        return memberData.paymentStatus && memberData.paymentStatus.code === 'OK' && hasClaim;
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        auth
    };
};

export default withWrapper(
    withRouter(connect(mapStateToProps)(withTranslation()(MemberSummaryPage))),
    true
);
