import React from 'react';
import moment from 'moment';
import { FormSelect, FormInput, FormDatePicker } from 'components/Form';
import { LookupModel, PersonalDetailsModel, PersonalDetailFieldsModel } from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';

interface Props {
    isEditMode?: boolean;
    canChangeFirstName?: boolean;
    personalDetailsFieldConfigs: PersonalDetailFieldsModel;
    personalDetails: PersonalDetailsModel;
    titles: LookupModel[];
    genders: LookupModel[];
    languages: LookupModel[];
    errors: any;
    onInputChange: (key: string, value: string) => void;
    onSelectOptionChange: (key: string, value: any) => void;
    onCheckboxChange: (key: string, value: boolean) => void;
    membershipConfiguration: any;
}

const arrayValue = (array, event) => {
    return array.find(el => el.value === event.value);
}

const PersonalDetails = ({
    isEditMode,
    canChangeFirstName,
    errors,
    titles,
    genders,
    languages,
    personalDetails,
    personalDetailsFieldConfigs,
    onInputChange,
    onSelectOptionChange,
    onCheckboxChange,
    membershipConfiguration
}: Props) => {
    const {
        title,
        gender,
        firstName,
        lastName,
        nationalIdNumber,
        otherNationalIdNumber,
        dateOfBirth,
        isParentalConsentProvided,
        preferedLanguage
    } = personalDetails;
    const {
        titleConfig,
        genderConfig,
        firstNameConfig,
        lastNameConfig,
        dateOfBirthConfig,
        nationalConfig,
        nationalIdConfig,
        otherNationalIdConfig,
        preferedLanguageConfig,
        parentalConsentConfig,
    } = personalDetailsFieldConfigs;
    const { t } = useTranslation();

    const isParentalConsentRequired = moment(dateOfBirth).isValid() && moment().diff(dateOfBirth, 'years') < 18;

    return (
        <div className="personal-group open">
            <div className="row">
                <div className="col-md-6">
                    {titleConfig.isVisible &&
                        <FormSelect
                            id="title"
                            name="title"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.TITLE')}
                            disabled={titles.length === 0}
                            require={titleConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("title", arrayValue(titles, event))}
                            value={title}
                            options={titles}
                            errorMsg={t(errors.title)}
                        />
                    }
                </div>
                <div className="col-md-6">
                    {genderConfig.isVisible &&
                        <FormSelect
                            id="gender"
                            name="gender"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.GENDER')}
                            disabled={genders.length === 0}
                            require={genderConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("gender", arrayValue(genders, event))}
                            value={gender}
                            options={genders}
                            errorMsg={t(errors.gender)}
                        />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {!firstNameConfig.isVisible ? null :
                        isEditMode && !canChangeFirstName ?
                            <FormInput
                                id="firstName"
                                inputClass="form-control"
                                disabled
                                value={firstName}
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.FIRST_NAME')} />
                            :
                            <FormInput
                                id="firstName"
                                inputClass="form-control"
                                value={firstName}
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.FIRST_NAME')}
                                maxlength={firstNameConfig.maxLength}
                                require={firstNameConfig.isRequired}
                                isValid={firstName.length !== 0}
                                validMsg={t(errors.firstName)}
                                onChange={(event) => onInputChange("firstName", event!.target.value)} />
                    }
                </div>
                <div className="col-md-6">
                    {lastNameConfig.isVisible &&
                        <FormInput
                            id="lastName"
                            inputClass="form-control"
                            value={lastName}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.LAST_NAME')}
                            maxlength={lastNameConfig.maxLength}
                            require={lastNameConfig.isRequired}
                            isValid={lastName.length !== 0}
                            validMsg={t(errors.lastName)}
                            onChange={(event) => onInputChange("lastName", event!.target.value)} />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {nationalConfig.isVisible &&
                        <FormInput
                            id="nationalIdNumber"
                            inputClass="form-control"
                            value={nationalIdNumber}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NATIONAL_ID_NUMBER')}
                            maxlength={nationalIdConfig.maxLength}
                            require={nationalConfig.isRequired}
                            classLongErrorMsg="long-text-error"
                            isValid={nationalConfig.isRequired && (nationalIdNumber.length !== 0 || otherNationalIdNumber.length !== 0)}
                            validMsg={t(errors.nationalIdNumber)}
                            onChange={(event) => onInputChange("nationalIdNumber", event!.target.value)} />
                    }
                </div>
                <div className="col-md-6">
                    {nationalConfig.isVisible &&
                        <FormInput
                            id="otherNationalIdNumber"
                            inputClass="form-control"
                            value={otherNationalIdNumber}
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OLD_OTHER_NATIONAL_ID')}
                            maxlength={otherNationalIdConfig.maxLength}
                            classLongErrorMsg="long-text-error"
                            isValid={nationalConfig.isRequired && (nationalIdNumber.length !== 0 || otherNationalIdNumber.length !== 0)}
                            validMsg={t(errors.nationalIdNumber)}
                            onChange={(event) => onInputChange("otherNationalIdNumber", event!.target.value)} />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {dateOfBirthConfig.isVisible &&
                        <FormDatePicker
                            id="dob"
                            className="datepicker-wrapper"
                            name="dateOfBirth"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.DATE_OF_BIRTH')}
                            required={dateOfBirthConfig.isRequired}
                            dateFormat={membershipConfiguration.newMemberDOBFormat}
                            maxDate={new Date()}
                            selectedDate={dateOfBirth ? new Date(dateOfBirth) : null}
                            onChange={value => onInputChange('dateOfBirth', value)}
                            errorMsg={t(errors.dateOfBirth)} />
                    }
                </div>
                <div className="col-md-6 align-self-end">
                    {parentalConsentConfig.isVisible && isParentalConsentRequired &&
                        <div className="form-group">
                            <div className="form-check">
                                <input id="pcp" type="checkbox" checked={isParentalConsentProvided} onChange={(event) => onCheckboxChange("isParentalConsentProvided", event.target.checked)} />
                                <label className="icon-checked-ico" htmlFor="pcp"> <span>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PARENTAL_CONSENT_PROVIDED')}</span></label>
                            </div>
                            {errors.isParentalConsentProvided && <span className="text-danger input-error">{t(errors.isParentalConsentProvided)}</span>}
                        </div>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {preferedLanguageConfig.isVisible &&
                        <FormSelect
                            id="language"
                            name="language"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBER_LANGUAGE_PREFERENCE')}
                            disabled={languages.length === 0}
                            require={preferedLanguageConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("preferedLanguage", arrayValue(languages, event))}
                            value={preferedLanguage}
                            options={languages}
                            errorMsg={t(errors.preferedLanguage)}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails;