import calendar from 'assets/images/calendar.svg';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import _ from 'lodash';
import * as RouteLoader from 'pages/RouteLoader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Utils from 'util/ControlUtils';
import ThisInterface from '../interface';


const renderEventContent = (item, diffDays: number, dateFormat: string, t) => {
    switch (item.type) {
        case 'FZ':
            return diffDays > 0
                ? t('PAGE.MEMBERSHIPS.VIEW.FREEZE_EVENT_DESCRIPTION', {
                    startDate: Utils.DisplayDateFormat(item.startedDateUtc, dateFormat),
                    remainingDate: diffDays,
                    duration: item.duration,
                    reason: item.reason
                })
                : t('PAGE.MEMBERSHIPS.VIEW.FREEZE_STARTED', {
                    startDate: Utils.DisplayDateFormat(item.startedDateUtc, dateFormat),
                    endDate: Utils.DisplayDateFormat(item.endDateUtc, dateFormat),
                    reason: item.reason
                });
        case 'CH':
            return diffDays > 0 ? (
                t('PAGE.MEMBERSHIPS.VIEW.CHANGE_MEMBERSHIP_EVENT_DESCRIPTION', {
                    startDate: Utils.DisplayDateFormat(item.startedDateUtc, dateFormat),
                    clubName: item.clubName,
                    planName: item.planName
                })
            ) : '';
        case 'CA':
            const date = new Date(item.startedDateUtc);
			
            return diffDays >= 0 ? (
                t('PAGE.CANCEL_MEMBERSHIP.EVENT_DESCRIPTION', {
                    requestCancelDate: Utils.DisplayDateFormat(item.requestDate, dateFormat),
                    cancelDate: Utils.DisplayDateFormat(date.setDate(date.getDate() + 1), dateFormat),
                    reason: item.reason
                })
            ) : ''; 
        default:
            return '';
    }
};

const FutureAction = ({ _this, item, diffDays }) => {
    const { t } = useTranslation();
    switch (item.type) {
        case 'FZ': {
            const hasModifyRight = Utils.isHasRight(
                RouteLoader.ViewModifyFreezeMemberShip.rightName
            );
            const hasRemoveRight = Utils.isHasRight(
                RouteLoader.ViewRemoveFreezeMemberShip.rightName
            );
            return (
                <React.Fragment>
                    {diffDays > 0 && hasModifyRight && (
                        <li
                            className="list-action__items"
                            onClick={() => _this.handleChangeSection('freeze-membership')}
                        >
                            <span>{t('PAGE.MEMBERSHIPS.VIEW.BUTTON.MODIFY')}</span>
                        </li>
                    )}
                    {diffDays > 0 && hasRemoveRight && (
                        <li
                            className="list-action__items"
                            onClick={() => _this.handleChangeSection('remove-freeze')}
                        >
                            <span>{t('PAGE.MEMBERSHIPS.VIEW.BUTTON.REMOVE')}</span>
                        </li>
                    )}
                </React.Fragment>
            );
        }
        case 'CH': {
            const hasRemoveRight = Utils.isHasRight(
                RouteLoader.RemoveChangeMembershipRoute.rightName
            );
            return (
                <React.Fragment>
                    {hasRemoveRight && (
                        <li
                            className="list-action__items"
                            onClick={() => _this.handleChangeSection('remove-change-membership')}
                        >
                            <span>{t('PAGE.MEMBERSHIPS.VIEW.BUTTON.REMOVE')}</span>
                        </li>
                    )}
                </React.Fragment>
            )
        }
        case 'CA': {
            const hasRemoveRight = Utils.isHasRight(
                RouteLoader.RemoveCancelMembershipRoute.rightName
            );
            
            return (
                <React.Fragment>
                    {hasRemoveRight && (
                        <li
                            className="list-action__items"
                            onClick={() => _this.handleChangeSection('cancel-membership')}
                        >
                            <span>{t('PAGE.MEMBERSHIPS.VIEW.BUTTON.REMOVE')}</span>
                        </li>
                    )}
                </React.Fragment>
            )
        }
        default:
            return <></>;
    }
};

const CurrentEventAction = ({item, _this}) => {
    const {t} = _this.props;

    const hasUnfreezeRight = Utils.isHasRight(
        RouteLoader.UnfreezeMembershipRoute.rightName
    );

    switch (item.type) {
        case 'FZ':
            return (
                <React.Fragment>
                    {hasUnfreezeRight && (
                        <li
                            className="list-action__items"
                            onClick={() => _this.handleChangeSection('unfreeze')}
                        >
                            <span>{t('PAGE.MEMBERSHIPS.VIEW.BUTTON.UNFREEZE')}</span>
                        </li>
                    )}
                </React.Fragment>
            );
        default:
            return <></>;
    }
};

const Event = ({item, diffDays = 0, type, _this}) => {
    const {
        props: {
            t
        },
        state: {
            dateFormat
        }
    } = _this;

    return (
        <div className="freeze-event-wrapper">
            <div className="feature-event">
                <div className="calendar-img">
                    <img src={calendar} alt="calendar" />
                </div>
                <div className="text-holder">
                    <p className="title">
                        {t(`PAGE.MEMBERSHIPS.VIEW.${type === 'FUTURE' ? 'FUTURE_EVENT' : 'EVENT'}`)}
                    </p>
                    <div className="freeze-description">
                        {renderEventContent(item, diffDays, dateFormat, t)}
                    </div>
                </div>
            </div>
            <div className="summary-header no-mg">
                <ul className="list-action">
                    {type === 'FUTURE'
                        ? <FutureAction item={item} _this={_this} diffDays={diffDays} />
                        : <CurrentEventAction item={item} _this={_this} />
                    }
                </ul>
            </div>
        </div>
    );
};

const renderFutureEvents = (_this) => {
    const { memberData } = _this.state;
    const { futureEvents } = memberData;
    if (futureEvents && !_.isEmpty(futureEvents)) {
        const oneDay = 24 * 60 * 60 * 1000;
        /*eslint-disable @typescript-eslint/no-explicit-any */
        const currentDate: any = new Date(
            `${Utils.DisplayDateFormat(
                new Date(),
                GENERAL_DATABASE_DATE_FORMAT
            )}T00:00:00Z`
        );

        return (
            <div className="row">
                <div className="col-md-12 col-lg-12 future-event">
                    {futureEvents.map((item, index) => {
                        const startDate: any = new Date(item.startedDateUtc);
                        const diffDays = Math.round(
                            Math.abs((startDate - currentDate) / oneDay)
                        );

                        return <Event key={index} item={item} diffDays={diffDays} type="FUTURE" _this={_this} />;
                    })}
                </div>
            </div>
        );
    }
    return <></>;
};

const renderCurrentEvents = (_this: ThisInterface) => {
    const { 
        memberData: {
            events
        }
    } = _this.state;

    if (events && !_.isEmpty(events)) {
        return (
            <div className="row">
                <div className="col-md-12 col-lg-12 future-event">
                    {events.map((item, index) => <Event key={index} item={item} type="CURRENT" _this={_this} />)}
                </div>
            </div>
        );
    }
    return <></>;
};

const renderEvents = (_this: ThisInterface) => {
    return (
        <React.Fragment>
            {renderCurrentEvents(_this)}
            {renderFutureEvents(_this)}
        </React.Fragment>
    );
};

export default renderEvents;