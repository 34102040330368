import axios from 'axios';
import * as API from 'constants/Constants';
import CommonService from './common.service';
import store from 'reduxs/index';
import { getTenantId, getSubTenantId, getIframeEndpoint } from 'util/ControlUtils';

export default class ConmanService {
    commonService: CommonService;

    constructor() {
        this.commonService = new CommonService();
    }

    getIframeSubTenant(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(false, rightName);
        const url = API.API_GET_IFRAME_SUBTENANTS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getIframeToken(subTenantId) {
        const endpoints = getIframeEndpoint();
        let OperatorId = 0;
        const endpoint = endpoints.find(item => item.SubTenantId === subTenantId);
        if (endpoint) {
            OperatorId = parseInt(endpoint.OperatorId);
        }
        const params = {
            operatorId: OperatorId
        };
        const headers = this.commonService.getHeaderWithSubtenant(subTenantId);
        return axios
            .post(API.API_GET_IFRAME_TOKEN, params, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getOperator(userId: string) {
        const headers = this.commonService.getHeader(false);
        return axios
            .get(API.API_GET_OPERATOR.replace('{1}', userId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getLanguageData(tenantId: string, subTenantId: string) {
        const defaultHeaders = this.commonService.getHeader();
        const headers = {
            ...defaultHeaders,
            'x-tenant-id': tenantId,
            'x-subtenant-id': subTenantId
        };
        return axios
            .get(API.API_GET_LANGUAGE_JSON, { headers: headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
    getDataFromUri(uri: string) {
        var config = {
            headers: null
        };
        return axios
            .get(uri, config)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                this.commonService.handleErrorHttp(err);
            });
    }

    getTenant(tenantId: string) {
        const headers = this.commonService.getHeader();
        return axios
            .get(API.API_CONFIGS_GET_TENANT.replace('{0}', tenantId), {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSubTenant(subTenantId: string, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        return axios
            .get(API.API_CONFIGS_GET_SUBTENANT.replace('{0}', subTenantId), {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    putAddLogo(base64String, rightName, subTenantId) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const data = {
            base64Image: base64String
        };
        return axios
            .put(
                `${API.API_CONFIGS_GET_SUBTENANT.replace('{0}', subTenantId)}/logo`,
                data,
                {
                    headers
                }
            )
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    putUpdateLogo(rightName, subTenantId) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const data = {
            base64Image: null
        };
        return axios
            .put(
                `${API.API_CONFIGS_GET_SUBTENANT.replace('{0}', subTenantId)}/logo`,
                data,
                {
                    headers
                }
            )
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getOperators(keywords, pageIndex) {
        const headers = this.commonService.getHeader();
        const data = new FormData();
        data.append('keywords', keywords);
        data.append('pageIndex', pageIndex);
        return axios
            .post(API.API_OPERATORS_SEARCH, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    resetPasswordOperator(username) {
        const headers = this.commonService.getHeaderTextData();
        const data = username;
        return axios
            .post(API.API_OPERATORS_RESET_PASSWORD, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    importOperator(file: File) {
        const url = API.API_BULK_IMPORT_OPERATORS;
        const headers = this.commonService.getHeader();
        const data = new FormData();
        data.append('inputFile', file);
        return axios
            .post(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getImportOperatorPagination(pageIndex) {
        const headers = this.commonService.getHeader();
        const data = { pageIndex: pageIndex };
        return axios
            .put(API.API_BULK_IMPORT_OPERATORS_PAGINATION, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenantRecommendationThemes(total, subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const filter = {
            operation: 'eq',
            queryKey: 'isCombo',
            queryValue: 'true',
            queryType: 'boolean'
        };
        const sort = 'Created=desc';
        return axios
            .get(
                `${API.API_CONFIGS_GET_TENANT_THEME}?filter=${JSON.stringify(
                    filter
                )}&sort=${sort}&pageSize=${total}`,
                { headers }
            )
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenantDefaultTheme(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        var filter = {
            operation: 'eq',
            queryKey: 'IsDefaultTheme',
            queryValue: 'true',
            queryType: 'boolean'
        };
        return axios
            .get(
                `${API.API_CONFIGS_GET_TENANT_THEME}?filter=${JSON.stringify(
                    filter
                )}&pageSize=1`,
                { headers }
            )
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenantActiveTheme(subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        var filter = {
            operation: 'eq',
            queryKey: 'IsActive',
            queryValue: 'true',
            queryType: 'boolean'
        };
        return axios
            .get(
                `${API.API_CONFIGS_GET_TENANT_THEME}?filter=${JSON.stringify(
                    filter
                )}&pageSize=1`,
                { headers }
            )
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenantHistoryThemes(total = 0, subTenantId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const filter = {
            and: [
                {
                    operation: 'eq',
                    queryKey: 'IsActive',
                    queryValue: 'false',
                    queryType: 'boolean'
                },
                {
                    operation: 'eq',
                    queryKey: 'IsDefaultTheme',
                    queryValue: 'false',
                    queryType: 'boolean'
                },
                {
                    operation: 'eq',
                    queryKey: 'isCombo',
                    queryValue: 'false',
                    queryType: 'boolean'
                }
            ]
        };
        const sort = 'Created=desc';
        return axios
            .get(
                `${API.API_CONFIGS_GET_TENANT_THEME}?filter=${JSON.stringify(
                    filter
                )}&sort=${sort}&pageSize=${total}`,
                { headers }
            )
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    saveTenantTheme(theme, rightName, subTenantId) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        return axios
            .post(API.API_CONFIGS_GET_TENANT_THEME, theme, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    changeNewPassword(userid, data) {
        const headers = this.commonService.getHeader();
        return axios
            .put(API.API_CONFIGS_MEMBER_PASSWORD.replace('{0}', userid), data, {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    forgotPassword(username, emailOrPhoneNumber) {
        const headers = this.commonService.getHeader();
        const data = {
            Username: username,
            EmailOrPhone: emailOrPhoneNumber
        };

        return axios
            .post(API.API_FORGOT_PASSWORD, data, { headers })
            .then(res => res)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    changeForgotPassword = (newPassword, confirmPassword, userId, token) => {
        const headers = this.commonService.getHeader();
        const data = {
            newPassword,
            confirmNewPassword: confirmPassword,
            userId,
            token
        };
        return axios
            .post(API.API_CHANGE_FORGOT_PASSWORD, data, { headers })
            .then(res => res)
            .catch(err => this.commonService.handleErrorHttp(err));
    };

    getSuggestions(
        api,
        method: 'get' | 'post' = 'get',
        data = {},
        subtenantId: string = '',
        rightName: string = ''
    ) {
        let headers = this.commonService.getHeader();
        if (rightName) {
            if (subtenantId !== '') {
                headers = this.commonService.getHeaderWithSubtenantAndRightName(
                    subtenantId,
                    rightName,
                    false
                );
            } else {
                headers = this.commonService.getHeaderWithoutSubTenant(
                    false,
                    rightName
                );
            }
        }

        const tenantId = getTenantId();
        const subTenantId = getSubTenantId();

        let fullApi = api
            .replace('{tenant-id}', tenantId)
            .replace('{subtenant-id}', subTenantId);

        if (method === 'get') {
            return axios
                .get(fullApi, { headers })
                .then(res => res.data)
                .catch(err => console.log(err));
        } else {
            return axios
                .post(fullApi, data, { headers })
                .then(res => res.data)
                .catch(err => console.log(err));
        }
    }

    getSubTenants(rightName: string = '') {
        const headers = this.commonService.getHeaderWithoutSubTenant(
            false,
            rightName
        );
        return axios
            .get(API.API_GET_SUBTENANTS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getAuthorizationMethods() {
        const headers = this.commonService.getHeader();
        const url = API.API_GET_AUTHORIZATION_METHOD;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRoleTypes() {
        const headers = this.commonService.getHeader();
        const url = API.API_RESOURCES;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getResourceByType(id) {
        const headers = this.commonService.getHeader();
        const url = API.API_RESOURCE_BY_ID;
        return axios
            .get(url.replace('{0}', id), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getClubGroupByTenant() {
        const headers = this.commonService.getHeader();
        const url = API.API_SEARCH_CLUBGROUP;
        const tenantId = getTenantId();
        return axios
            .get(url.replace('{0}', tenantId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createRole(data) {
        const headers = this.commonService.getHeader();
        const url = API.API_ROLES;
        return axios
            .post(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createUser(data) {
        const headers = this.commonService.getHeader();
        const url = API.API_USERS;
        return axios
            .post(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createRandomPassword() {
        const headers = this.commonService.getHeader();
        return axios
            .post(API.API_CREATE_PASSWORD, {}, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createUserGroup(usergroup, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = API.API_CREATE_USER_GROUP;
        const data = JSON.stringify(usergroup);

        return axios
            .post(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRoleById(id, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = API.API_ROLE_BY_ID;
        return axios
            .get(url.replace('{0}', id), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateRole(id, data) {
        const headers = this.commonService.getHeader();
        const url = API.API_ROLE_BY_ID;
        return axios
            .put(url.replace('{0}', id), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getUserById(id, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = API.API_USER_BY_ID;
        return axios
            .get(url.replace('{0}', id), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
    getUserGroupById(groupId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = `${API.API_CREATE_USER_GROUP}/${groupId}`;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateUser(id, data) {
        const headers = this.commonService.getHeader();
        const url = API.API_USER_BY_ID;
        return axios
            .put(url.replace('{0}', id), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
    updateUserGroup(groupId, usergroup, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = `${API.API_CREATE_USER_GROUP}/${groupId}`;
        const data = JSON.stringify(usergroup);

        return axios
            .put(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPackageDetail(pkgId: string, subTenantId: string, rightName: string) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(
            subTenantId,
            rightName,
            false
        );
        const url = `${API.API_GET_PACKAGE_DETAIL}`.replace('{1}', pkgId);

        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSubTenantConfig(rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        return axios
            .get(API.API_GET_SUBTENANT_CONMAN, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFeatures(subtenant: string) {
        const headers = this.commonService.getHeaderWithSubtenant(subtenant);

        return axios
            .get(API.API_GET_FEATURES, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFeatureDetail(featureId: string, subtenant: string) {
        const headers = this.commonService.getHeaderWithSubtenant(subtenant);
        return axios
            .get(API.API_GET_FEATURE_DETAIL.replace('{1}', featureId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFields(featureId: string, subtenant: string) {
        const headers = this.commonService.getHeaderWithSubtenant(subtenant);
        return axios
            .get(API.API_GET_PUT_FIELDS.replace('{1}', featureId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateConfig(featureId: string, subtenant: string, data) {
        const headers = this.commonService.getHeaderWithSubtenant(subtenant);

        return axios
            .put(API.API_GET_PUT_FIELDS.replace('{1}', featureId), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSubTenantLookUp = (rightName: string, subTenantId) => {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(
            subTenantId,
            rightName,
            false
        );
        return axios
            .get(API.API_GET_SUBTENANTS_LOOK_UP, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    };

    createLookUp(data, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        return axios
            .post(API.API_CREATE_LOOK_UP, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updatelookUp(data, id, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        return axios
            .put(API.API_GET_UPDATE_LOOK_UP_DETAIL.replace('{1}', id), data, {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getLookUpDetail(id: string, subTenantId: string, rightName) {
        const headers =
      subTenantId && subTenantId !== ''
          ? this.commonService.getHeaderWithSubtenantAndRightName(
              subTenantId,
              rightName,
              false
          )
          : this.commonService.getHeader();
        return axios
            .get(API.API_GET_UPDATE_LOOK_UP_DETAIL.replace('{1}', id), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
    getTenderClassesSubTenants(rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = API.API_GET_TENDER_CLASS_SUBTENANTS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createTenderClass(data, rightName) {
        const subTenantId = data.subTenantId;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        return axios
            .post(API.API_CREATE_TENDER_CLASS, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateTenderClass(data, id, rightName) {
        const subTenantId = data.subTenantId;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        return axios
            .put(API.API_GET_UPDATE_TENDER_CLASS_DETAIL.replace('{1}', id), data, {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenderClassDetail(id: string, rightName, subTenantId) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(
            subTenantId,
            rightName,
            false
        );
        return axios
            .get(API.API_GET_UPDATE_TENDER_CLASS_DETAIL.replace('{1}', id), {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenderClasses(subTenantId: string, rightName: string) {
        if (subTenantId) {
            const headers = this.commonService.getHeader(false, rightName);
            const url = API.API_GET_TENDER_TYPE_TENDER_CLASSES;
            return axios
                .get(
                    url +
            `?filter={"operation":"eq","queryType":"guid","queryKey":"subtenant.id","queryValue":"${subTenantId}"}`,
                    { headers }
                )
                .then(res => res.data)
                .catch(err => this.commonService.handleErrorHttp(err));
        } else {
            return Promise.resolve({ data: [] });
        }
    }

    getTenderClassFields(rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = API.API_TENDER_CLASS_GET_FIELDS;

        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenderTypeSubTenants(rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        const url = API.API_GET_TENDER_TYPE_SUBTENANTS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createTenderType(data, rightName) {
        const subTenantId = data.subTenantId;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        return axios
            .post(API.API_CREATE_TENDER_TYPE, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateTenderType(data, id, rightName) {
        const subTenantId = data.subTenantId;
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        return axios
            .put(API.API_GET_UPDATE_TENDER_TYPE_DETAIL.replace('{1}', id), data, {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenderTypeDetail(id: string, rightName, subTenantId) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(
            subTenantId,
            rightName,
            false
        );
        return axios
            .get(API.API_GET_UPDATE_TENDER_TYPE_DETAIL.replace('{1}', id), {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenderTypeFields() {
        const headers = this.commonService.getHeader();
        const url = API.API_TENDER_TYPE_GET_FIELDS;

        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSystemConfigs(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers['x-tenant-id'] = getTenantId();
        headers['x-subtenant-id'] = subTenantId;
        return axios
            .get(API.API_GET_SYSTEM_CONFIGS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
}
