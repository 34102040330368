import React from 'react';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AdvanceSearchPanel from 'components/AdvanceSearchPanel';
import { SubTenantRoleModel } from 'models/Role';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import observer from 'util/Observer';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';


interface Props extends RouteComponentProps {
    subTenant: { id: string; name: string };
    searchRoleApi: string;
    searchClubGroupApi: string;
    selectedRoles: any;
    filterRole: string;
    filterClubGroup: string;
    onRoleAdded: (subTenantId: string, roles: SubTenantRoleModel[]) => void;
    onRoleDeleted: (subTenantId: string, roles: SubTenantRoleModel[]) => void;
    onRoleDisabled: (subTenantId: string) => void;
    parent: 'userGroup' | 'user';
    t?: any;
}

interface State {
    isRoleSwitchOn: boolean;
    isEditMode: boolean;
}

const getRoleIdClubGroupId = selectedRoles => {
    if (selectedRoles && !_.isEmpty(selectedRoles)) {
        return selectedRoles.map(item => {
            return {
                name: item.name
                    ? item.name
                    : `${item['role'].name} - ${item['clubGroup'].name}`,
                id: item.id ? item.id : `${item['role'].id}-${item['clubGroup'].id}`,
                firstKeyId: item.firstKeyId ? item.firstKeyId : item['role'].id,
                secondKeyId: item.secondKeyId ? item.secondKeyId : item['clubGroup'].id
            };
        });
    }
    return [];
};

const getRightName = (isEditMode: boolean, pageType: string) => {
    switch (pageType) {
        case 'user':
            return isEditMode
                ? Route.EditUserRoute.rightName
                : Route.AddUserRoute.rightName;
        case 'userGroup':
            return isEditMode
                ? Route.EditUserGroupRoute.rightName
                : Route.AddUserGroupRoute.rightName;
        default:
            return '';
    }
};

class SubTenantRole extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        const { match } = props;
        this.state = {
            isRoleSwitchOn: props.selectedRoles.length > 0,
            isEditMode: match.params['id'] && match.params['id'] !== ''
        };
    }

    switchChanged = event => {
        const { parent, t } = this.props;
        if (event.target.checked) {
            this.setState({
                isRoleSwitchOn: event.target.checked
            });
            if (!event.target.checked) {
                let { subTenant, onRoleDisabled } = this.props;
                onRoleDisabled(subTenant.id);
            }
        } else {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t(
                    parent === 'userGroup'
                        ? 'MSG.SYSTEM.USER_GROUP.ADD_EDIT.CONFIRM_CHANGE_ASSIGN_USER'
                        : 'MSG.SYSTEM.USERS.ADD_EDIT.CONFIRM_REMOVE_ROLE_CLUB_GROUP'
                ),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(result => {
                if (result.value) {
                    this.setState({
                        isRoleSwitchOn: false
                    });
                    let { subTenant, onRoleDisabled } = this.props;
                    onRoleDisabled(subTenant.id);
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        }
    };

    onRoleAdded = value => {
        const { subTenant, onRoleAdded } = this.props;
        onRoleAdded(subTenant.id, value);
    };

    onRoleDeleted = value => {
        const { subTenant, onRoleDeleted } = this.props;
        onRoleDeleted(subTenant.id, value);
    };

    render() {
        const {
            subTenant,
            searchRoleApi,
            searchClubGroupApi,
            filterRole,
            filterClubGroup,
            selectedRoles,
            parent,
            t
        } = this.props;
        const { isRoleSwitchOn, isEditMode } = this.state;
		

        return (
            <React.Fragment>
                <div className="form-group switch-container">
                    <label className="switch">
                        <input
                            type="checkbox"
                            name={`subTenant-${subTenant.id}`}
                            checked={isRoleSwitchOn}
                            onChange={e => this.switchChanged(e)}
                        />
                        <span className="slider round" />
                    </label>
                    <span className="label-select-switch">{subTenant.name}</span>
                </div>
                {isRoleSwitchOn && (
                    <AdvanceSearchPanel
                        tooltipFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ROLE_INSTRUCTION')}
                        tooltipSecondSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.CLUB_GROUP_INSTRUCTION')}
                        titleFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ROLES')}
                        titleSecondSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.CLUB_GROUP')}
                        emptyTagMessage={`(${t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.NO_ROLE_HAS_BEEN_CHOSEN_YET')})`}
                        placeholderFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ADD_ROLE')}
                        placeholderSecondSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ADD_CLUB_GROUP')}
                        firstSelectApi={searchRoleApi}
                        secondSelectApi={searchClubGroupApi}
                        filterFirstSelect={filterRole}
                        filterSecondSelect={filterClubGroup}
                        onSuggestionAdded={this.onRoleAdded}
                        onSuggestionRemoved={this.onRoleDeleted}
                        showSecondSelect
                        subTenant={subTenant}
                        rightName={getRightName(isEditMode, parent)}
                        selectedSuggestions={getRoleIdClubGroupId(selectedRoles)}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(SubTenantRole));
