import React from 'react';
import {
    PRESET_ENDPOINT_URL,
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ListComponent from 'list-component';
import ThisInterface from './interface';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

const template = (_this: ThisInterface) => {
    const {
        auth: { user }
    } = _this.props;
    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT
    };

    const errorHandle = (err) => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    }

    return (
        <ListComponent
            oidcAuth={user}
            version={_this.state.version}
            rightName="view_system_version"     
            config={{
                presetUrl: PRESET_ENDPOINT_URL,
                timeFormat: GENERAL_DATE_FORMAT,
                langCode: i18n.language,
                tableHeight: `${_this.state.tableHeight}px`,
                tableWidth: '100%'
            }
            }
            variable={variableConfig}
            actionEvent={null}
            errorEvent={errorHandle}
        />
    );
};

export default template;
