import React from 'react';
import ThisInterface  from './interface';
import PlanModel from 'models/Plan';

import { getNameOrUndefined } from 'components/FormV2';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
}
interface Props {
    data: PlanModel;
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true
        }
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { data, t } = this.props;

        const col1Rows = [
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.PAYMENT_METHOD_CONFIGURATION'),
                data: getNameOrUndefined(data.paymentMethod)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.MEMBER_ARE_ALLOWED_TO_FREEZE'),
                data: data.isAllowFreezeMembership ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.MEMBERS_CAN_BE_TRANSFERRED_BETWEEN_CLUBS'),
                data: data.isAllowChangeHomeClub ? t('BUTTON.YES') : t('BUTTON.NO')
            },
        ];

        const col2Rows = [
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.MEMBERS_CAN_FREEZE_IMMEDIATELY'),
                data: data.isIgnoreNoFreeze ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.AUTOMATICALLY_COLLECT_ARREARS_THROUGH_BILLING'),
                data: data.sweepArrearOntoBilling ? t('BUTTON.YES') : t('BUTTON.NO')
            },
        ];


        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.OPTION')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    <div className="row">
                        <div className="col-md-6">
                            {col1Rows.map((field, key) =>
                                field && <div key={key} className="row mb-4">
                                    <div className="col-6">{field.fieldLabel}</div>
                                    <div className="col-6 text-primary">{field.data}</div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            {col2Rows.map((field, key) =>
                                field && <div key={key} className="row mb-4">
                                    <div className="col-6">{field.fieldLabel}</div>
                                    <div className="col-6 text-primary">{field.data}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;