import React from 'react';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import ThisInteface, { OptionsModel } from './interface';

class AddFieldsConfig extends React.Component<
ThisInteface['props'] & WithTranslation & RouteComponentProps,
ThisInteface['state']
> {
    constructor(props: ThisInteface['props'] & WithTranslation & RouteComponentProps) {
        super(props);
        const innerHeight = window.innerHeight - 510;

        this.state = {
            isLoading: true,
            isLoadingFields: false,
            disabledButton: true,
            countries: [],
            features: [],
            fields: [],
            selectedCountries: null,
            selectedFeature: null,
            selectedField: null,
            editFields: [],
            originEditFields: [],
            originField: [],
            tableHeight: innerHeight < 250 ? 250 : innerHeight,
            isChanging: false
        };
    }

    componentDidMount() {
        this.initData();
        window.onresize = this.onresize;
    }

    onresize = () => {
        const innerHeight = window.innerHeight - 510;
        this.setState({
            tableHeight: innerHeight < 250 ? 250 : innerHeight
        });
    };

    initData = () => {
        const { conmanService } = this.props;
        const rightName = Route.AddFieldsConfigRoute.rightName;
        conmanService
            .getSubTenantConfig(rightName)
            .then(res => {
                this.setState({
                    isLoading: false
                });
                if (res && res.data && !_.isEmpty(res.data)) {
                    this.setState({
                        countries: this.transformOptions(res.data)
                    });
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    transformOptions = (data: { id: string; name: string }[]) => {
        return data.map(item => {
            return {
                value: item.id,
                label: item.name,
                ...item
            };
        });
    };

    setFields = (editFields, originField) => {
        let originFieldTemp = editFields.concat(originField);
        if (editFields.length === originField.length) {
            originFieldTemp = [];
        } else {
            originFieldTemp = _.uniqBy(originFieldTemp, 'id');
        }
        this.setState({
            fields: originFieldTemp.filter(item => item.value)
        });
    };

    initState = () => {
        this.setState({
            fields: [],
            originField: [],
            editFields: [],
            originEditFields: []
        });
    };

    loadDataEachSelect = (value: string, key: string) => {
        const { conmanService } = this.props;
        const { selectedCountries } = this.state;
        switch (key) {
            case 'selectedCountries':
                conmanService
                    .getFeatures(selectedCountries!.value)
                    .then(res => {
                        if (res && res.data && !_.isEmpty(res.data)) {
                            this.initState();
                            this.setState({
                                selectedFeature: null,
                                selectedField: null,
                                features: this.transformOptions(res.data)
                            });
                        }
                    })
                    .catch(() => {
                        this.initState();
                        this.setState({
                            selectedFeature: null,
                            selectedField: null,
                            features: []
                        });
                    });
                break;
            case 'selectedFeature':
                this.setState({
                    isLoadingFields: true
                });
                Promise.all([
                    conmanService.getFeatureDetail(value, selectedCountries!.value),
                    conmanService.getFields(value, selectedCountries!.value)
                ])
                    .then(([res, res1]) => {
                        if (res && !_.isEmpty(res)) {
                            this.setState(
                                {
                                    fields: this.transformOptions(res),
                                    originField: this.transformOptions(res),
                                    isLoadingFields: false
                                },
                                () => {
                                    if (res1 && !_.isEmpty(res1)) {
                                        // using clone in modal class next CR
                                        const originField = _.cloneDeep(res);
                                        res.forEach((item, index) => {
                                            if (res1[index]) {
                                                const itemIndex = originField.findIndex(
                                                    el => el.id === res1[index].id
                                                );
                                                originField.splice(itemIndex, 1);
                                            }
                                        });

                                        this.setState({
                                            editFields: res1,
                                            originEditFields: res1,
                                            fields: this.transformOptions(originField)
                                        });
                                    } else {
                                        this.setState({
                                            editFields: [],
                                            originEditFields: []
                                        });
                                    }
                                }
                            );
                        } else {
                            this.initState();
                            this.setState({
                                isLoadingFields: false
                            });
                        }
                    })
                    .catch(() => {
                        this.initState();
                        this.setState({
                            isLoadingFields: false
                        });
                    });
                break;
            default:
                break;
        }
    };

    handleChangeValue = (value: OptionsModel, key: string) => {
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    [key]: value
                };
            },
            () => {
                value && this.loadDataEachSelect(value.value, key);
            }
        );
    };

    handleChangeFieldValue = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: string,
        key: string,
        childId?: string
    ) => {
        const { value } = e.target;
        // using clone in modal class next CR
        const editFields = _.cloneDeep(this.state.editFields);
        const currentField = editFields.find(item => item.id === id);
        const currentIndex = editFields.findIndex(item => item.id === id);
        if (currentField) {
            if (
                childId &&
                currentField.children &&
                !_.isEmpty(currentField.children)
            ) {
                const children = currentField.children;
                const currentChildField = children.find(item => item.id === childId);
                const currentChildIndex = children.findIndex(
                    item => item.id === childId
                );
                if (currentChildField) {
                    currentChildField[key] = value;
                    children.splice(currentChildIndex, 1, currentChildField);
                    currentField.children = children;
                    editFields.splice(currentIndex, 1, currentField);
                    this.setState({
                        editFields,
                        disabledButton: false,
                        isChanging: true
                    });
                }
            } else {
                const isBoolean = typeof currentField[key] === 'boolean';
                currentField[key] = isBoolean ? !currentField[key] : value;
                !currentField.isVisible && (currentField.isRequired = false);
                editFields.splice(currentIndex, 1, currentField);
                this.setState({
                    editFields,
                    disabledButton: false,
                    isChanging: true
                });
            }
        }
    };

    handleAddField = () => {
        const { editFields } = this.state;
        const selectedField: any = this.state.selectedField;
        if (selectedField) {
            if (!editFields.some(item => item.id === selectedField.value)) {
                this.setState(prevState => {
                    return {
                        editFields: editFields.concat([selectedField]),
                        fields: prevState.fields.filter(
                            item => item.value !== selectedField.value
                        ),
                        selectedField: null,
                        disabledButton: false,
                        isChanging: true
                    };
                });
            }
        }
    };

    handleRemoveField = (fieldId: string) => {
        // using clone in modal class next CR
        const editFields = _.cloneDeep(this.state.editFields);
        this.setState({
            disabledButton: false,
            isChanging: true
        });
        if (editFields.length > 0) {
            this.setState(
                {
                    editFields: editFields.filter(item => item.id !== fieldId)
                },
                () => {
                    this.setFields(this.state.editFields, this.state.originField);
                }
            );
        } else {
            this.setState(prevState => {
                return {
                    editFields: editFields.filter(item => item.id !== fieldId),
                    fields: prevState.originField
                };
            });
        }
    };

    handleCancel = () => {
        const { t } = this.props; 
        if (this.state.isChanging) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('PAGE.SYSTEM.FIELDS_CONFIG.CONFIRM_CANCEL'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(res => {
                if (res.value) {
                    this.setState(
                        prevState => {
                            return {
                                editFields: prevState.originEditFields,
                                disabledButton: true,
                                isChanging: false
                            };
                        },
                        () => {
                            if (this.state.originEditFields.length > 0) {
                                this.setFields(
                                    this.state.originEditFields,
                                    this.state.originField
                                );
                            } else {
                                this.setState(prevState => {
                                    return {
                                        fields: prevState.originField
                                    };
                                });
                            }
                        }
                    );
                }
            });
        } else {
            this.props.history.push('/');
        }
    };

    handleSaveConfig = () => {
        const { conmanService,t  } = this.props;
        const { selectedCountries, selectedFeature, editFields } = this.state;
        if (selectedCountries && selectedFeature) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('PAGE.SYSTEM.FIELDS_CONFIG.CONFIRM_SAVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.SAVE'),
                cancelButtonText: t('BUTTON.CANCEL')
            }).then(res => {
                if (res.value) {
                    Swal.fire({
                        html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                        showConfirmButton: false
                    });
                    conmanService
                        .updateConfig(
                            selectedFeature.value,
                            selectedCountries.value,
                            !_.isEmpty(editFields) ? JSON.stringify(editFields) : []
                        )
                        .then(res => {
                            if (res.isSuccess) {
                                this.setState({
                                    originEditFields: editFields,
                                    disabledButton: true,
                                    isChanging: false
                                });
                                Swal.fire({
                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                    type: 'success',
                                    html: t('PAGE.SYSTEM.FIELDS_CONFIG.SAVE_SUCCESSFUL'),
                                    confirmButtonText: t('BUTTON.CLOSE')
                                });
                            }
                        })
                        .catch(() => {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'error',
                                html: t('PAGE.SYSTEM.FIELDS_CONFIG.SAVE_ERROR'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            });
                        });
                }
            });
        }
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(AddFieldsConfig);
