import React from 'react';

import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight, getIframeAPIURL } from 'util/ControlUtils';
import { IFRAME_CONMAN_CLUBS } from 'constants/Constants';
import Loading from 'components/Loading';
import embededWrapper from 'hocs/Embeded';

import ThisInterface from './interface';

class CMClubsPage  extends React.Component<ThisInterface['props'], {}> {
    constructor(props: ThisInterface['props']) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener("message", this.messageHandler, false);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data;
        
        switch (action) {
            case 'returnHeightData': 
                const obj: any = this.refs.iframe;
                if (obj) {
                    obj.style.height = value + 'px';
                }
                break;
            case 'redirectURL':
                if(value.includes('/Club/Edit')){
                    const slugs = value.split('/');
                    const clubId = slugs[slugs.length - 1];
                    this.props.history.push('/' + RouteLoader.CMEditClubPageRoute.url.replace(':id', clubId));
                }
                break;
        }
    }

    permission = () => {
        this.setState({iframeLoaded: true});
        
        const obj: any = this.refs.iframe;
        obj.contentWindow.postMessage({
            action: 'getHeightData'
        }, '*');

        const hasConmanEditClub = isHasRight(RouteLoader.CMEditClubPageRoute.rightName);
        if(hasConmanEditClub) {
            obj.contentWindow.postMessage({
                action: 'hasConmanEditClub'
            }, '*');
        }
    }

    render() {
        const { iframeToken, subTenantId, iframeLoaded, resizeIframe } = this.props;

        return (
            <>
                {subTenantId && <div className="member mt-3">
                    <div className="member-main member-main--custom">
                        <div className="content">
                            {!iframeLoaded && <Loading />}
                            {iframeToken && <iframe src={`${getIframeAPIURL(IFRAME_CONMAN_CLUBS, subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => resizeIframe(this.refs.iframe, this.permission)} className="iframe" frameBorder="0"></iframe>}
                        </div>
                    </div>
                </div>}
            </>
        );
    }
}

export default embededWrapper(CMClubsPage, 'CMClubsPageRoute', 'PAGE.CONMAN.CLUBS.TITLE');