import React from 'react';
import CropImage from 'components/CropImage';
import observer from 'util/Observer';
import defaultAvatar from 'assets/images/user_avatar.png';

interface UserAvatarProps {
    avatarUri: string;
    handleChangeAvatar: (imageSrc: string) => void;
}

const UserAvatar: React.FunctionComponent<UserAvatarProps> = React.memo(
    ({ avatarUri, handleChangeAvatar }) => {
        function handleCancel() {
            observer.publish('closeDialogEvent', true);
        }

        function resize() {
            observer.publish(
                'windowResize',
                window.innerWidth > 768
                    ? { canvasW: 276, canvasH: 230 }
                    : { canvasW: 186, canvasH: 186 }
            );
        }

        function openSelectImage() {
            observer.publish('openDialogEvent', {
                content: (
                    <CropImage
                        onSave={handleChangeAvatar}
                        onCancel={handleCancel}
                        height={window.innerWidth > 768 ? 230 : 186}
                        width={window.innerWidth > 768 ? 276 : 186}
                        isAvatar
                    />
                ),
                classHolder: 'user-profile-picture'
            });
            window.onresize = resize;
        }

        return (
            <div className="info-acc">
                <div className="card-acc">
                    <div className="box-avatar">
                        <div className="edit-avartar" onClick={openSelectImage}>
                            <img
                                className="img-acc"
                                src={avatarUri ? avatarUri : defaultAvatar}
                                alt="user-avatar"
                            />
                            <span className="icon-edit-ico" />
                        </div>
                    </div>
                    <div className="detail-acc">
                        <div className="title-acc">
                            <h3 className="acc-name text-center">Profile Picture</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

UserAvatar.displayName = 'UserAvatar';

export default UserAvatar;
