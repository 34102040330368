import { isLettersOnly, checkValidCardNumber, checkValidBankAccount, checkCreditValidYear } from "util/ControlUtils";
import ChangePaymentDetailModel from "models/ChangePaymentDetail";
import FieldModel from 'models/FieldConfig';
import ThisInterface, { PaymentMethods } from "./interface";
import { t } from 'util/I18nMessages';

const fieldModel = (keyName: string, options?: Partial<FieldModel>) => {
    return {
        ...new FieldModel(),
        key: keyName,
        name: keyName,
        isValidType: 'required' as 'required' | 'regex' | 'none' | undefined,
        isRequired: true,
        ...options
    };
};

export const getInitialValidationState = () => ({
    accountName: fieldModel('accountName', {
        maxLength: 100
    }),
    cardNumber: fieldModel('cardNumber'),
    expiryDate: fieldModel('expiryDate'),
    'bank.id': fieldModel('bank.id'),
    nationalId: fieldModel('nationalId'),
    otherNationalId: fieldModel('otherNationalId')
});


const isValidCard = (
    paymentMethod: ThisInterface['state']['paymentDetails']['primary']['paymentMethod'],
    key: string,
    value: string
) => {
    let validCardNumber = true;
    switch (paymentMethod.name) {
        case PaymentMethods.Credit:
            validCardNumber = key === 'cardNumber' && checkValidCardNumber(value);
            break;
        case PaymentMethods.Debit:
            validCardNumber = key === 'cardNumber' && checkValidBankAccount(value);
            break;
        default:
            validCardNumber = false;
            break;
    }
    return validCardNumber;
};

export const validateAccountName = (value: string) => {
    if (!value || value === '') return {
        isDirty: true,
        customMsg: ''
    };
    if (!isLettersOnly(value)) return {
        isDirty: true,
        customMsg: 'PAGE.MEMBERSHIPS.CREATE.ACCOUNT_NAME_INVALID'
    };
    return {
        isDirty: false,
        customMsg: ''
    };
};

export const validateCardNumber = (
    value: string,
    paymentMethod: ChangePaymentDetailModel['primary']['paymentMethod']
) => {
    const isDirty = !isValidCard(paymentMethod, 'cardNumber', value);
    let customMsg = '';
	
    if (isDirty && value !== '') {
        if (paymentMethod.name === PaymentMethods.Credit) {
            customMsg = 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.CARD_NUMBER_ERR';
        } else {
            customMsg = 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ACCOUNT_NUMBER_ERR';	
        }
    }
    return {
        isDirty,
        customMsg
    };
};

export const validateExpiryDate = (value: string, paymentMethod: ChangePaymentDetailModel['primary']['paymentMethod']) => {
    if (paymentMethod.name !== PaymentMethods.Credit) return {
        isDirty: false,
        customMsg: ''
    };
    const isDirty = !!!(value && checkCreditValidYear(value));
    let customMsg = '';
    if (isDirty) {
        if (value) {
            customMsg = 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.VALID_EXPIRED_DATE'
        } else {
            customMsg = 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.EXPIRY_DATE_REQUIRED'
        }
    }

    return {
        isDirty,
        customMsg
    };
};

export const validateNationalIdDetails = (
    paymentDetails: ChangePaymentDetailModel['primary'],
    basePrimary: ThisInterface['state']['basePaymentDetails']['primary'],
    fields: FieldModel[]
) => {
    let isDirty = false;
    let customMsg = '';
    if (
        (!paymentDetails.nationalId && !paymentDetails.otherNationalId)
        && (!basePrimary.nationalId && !basePrimary.otherNationalId)
    ) {
        const nationalIdField = fields.find(field => field.key === 'addMember.nationalId.NationalId') || { name: 'National ID' };
        const otherNationalIdField = fields.find(field => field.key === 'addMember.nationalId.Alternativeid') || { name: 'Other National ID' };
        isDirty = true;		
        customMsg = { value: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.MISSING_NATIONAL_ID_MESSAGE', key: { nationalId: nationalIdField.name, otherNationalId: otherNationalIdField.name } } as any;
    }
    return {
        isDirty,
        customMsg
    };
};

export const validatePaymentDetails = (
    data: ChangePaymentDetailModel['primary'],
    validationState: ThisInterface['state']['validations']
) => {
    const initialValidationState = getInitialValidationState();
    const validations = Object.assign({}, validationState);
    validations.accountName = {
        ...validations.accountName || initialValidationState.accountName,
        ...validateAccountName(data.accountName)
    };

    validations.cardNumber = {
        ...validations.cardNumber || initialValidationState.cardNumber,
        ...validateCardNumber(data.cardNumber, data.paymentMethod)
    };

    validations.expiryDate = {
        ...validations.expiryDate || initialValidationState.expiryDate,
        ...validateExpiryDate(data.expiryDate, data.paymentMethod)
    };

    return validations;
};

export const isAllFieldsValid = (validations: ThisInterface['state']['validations']) => {
    const invalidFields = Object.keys(validations).filter(key => {
        let fieldValidation = validations[key];
        return fieldValidation.isDirty;
    });
    return invalidFields.length === 0;
};

export const validateField = (
    fieldName: string,
    validationState: ThisInterface['state']['validations'],
    paymentDetails: ChangePaymentDetailModel['primary']
) => {
    const validations = Object.assign({}, validationState);
    const initialValidationState = getInitialValidationState();
    switch (fieldName) {
        case 'accountName':
            validations.accountName = {
                ...validations.accountName || initialValidationState.accountName,
                ...validateAccountName(paymentDetails.accountName)
            };
            break;
        case 'cardNumber':
            validations.cardNumber = {
                ...validations.cardNumber || initialValidationState.cardNumber,
                ...validateCardNumber(paymentDetails.cardNumber, paymentDetails.paymentMethod)
            };
            break;
        case 'expiryDate':
            validations.expiryDate = {
                ...validations.expiryDate || initialValidationState.expiryDate,
                ...validateExpiryDate(paymentDetails.expiryDate, paymentDetails.paymentMethod)
            };
            break;
        default:
            break;
    }

    return validations;
};