import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ConmanService from 'services/conman.service';
import template from './template';
import ThisInterface from './interface';

class PackagePage extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    conmanService: ConmanService;
    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {
            tableHeight: window.innerHeight - 310,
            version: 0,
            subTenantId: ''
        };
        this.conmanService = new ConmanService();
    }

    componentDidMount() {
        window.onresize = this.resize;
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 310
        });
    };
	
    handleNext = (subTenant) => {
        this.setState({
            subTenantId: subTenant.id
        });
    }

    handleActionEvent = (actionMap: string, value: string) => {
        const { history } = this.props;
        const { subTenantId } = this.state;
        switch (actionMap) {
            case 'view':
                history.push(`/products/packages/${value}/view-detail`, {
                    subTenantId
                });
            default:
                break;
        }
        this.setState(state => ({ version: state.version + 1 }));
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(PackagePage)));
