import React from 'react';
import * as Utils from 'util/ControlUtils';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';
import { BASE_WEBPAGE_URL } from 'constants/Constants';

import ThisInterface from './interface';
import template from './template';

interface SwalInterface {
    type: 'error' | 'warning' | 'success' | 'info' | 'question' | undefined;
}

class ChangePassword extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    userId: string | undefined;
    token: string | undefined;

    constructor(props: ThisInterface['props']) {
        super(props);
        const {
            location: { search }
        } = props.history;
        const { t } = this.props;
        this.state = {
            newPassword: '',
            confirmNewPassword: '',
            newPasswordShow: false,
            confirmNewPasswordShow: false,
            message: {
                minimumCharacterCount: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_8_CHARACTERS'),
                minimumUpperCaseLetter: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_UPPERCASE'),
                minimumLowerCaseLetter: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_LOWER'),
                minimumDigitCharacterCount: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_DIGIT'),
                minimumSpecialCharacter: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_SPECIAL_OR_PUNCTUATION')
            },
            newPasswordErrors: {
                minimumCharacterCount: true,
                minimumUpperCaseLetter: true,
                minimumLowerCaseLetter: true,
                minimumDigitCharacterCount: true,
                minimumSpecialCharacter: true
            },
            confirmNewPasswordError: false,
            newPasswordError: false,
            isLoading: false
        };
        if (search.split('&identity=')[1] && search.split('&identity=')[0]) {
            this.userId = search.split('&identity=')[1];
            this.token = search.split('&identity=')[0].split('?token=')[1];
        } else {
            window.location.assign(BASE_WEBPAGE_URL);
        }
    }
    
    showPassword = (key: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                [`${key}Show`]: !prevState[`${key}Show`]
            };
        });
    };

    validateNewPassword = (password: string) => {
        this.setState({
            newPasswordErrors: {
                minimumCharacterCount: !Utils.isMinimumCharacterCount(password),
                minimumUpperCaseLetter: !Utils.IsMinimumUpperCaseLetter(password),
                minimumLowerCaseLetter: !Utils.IsMinimumLowerCaseLetter(password),
                minimumDigitCharacterCount: !Utils.IsMinimumDigitCharacterCount(
                    password
                ),
                minimumSpecialCharacter: !Utils.IsMinimumSpecialCharacter(password)
            }
        });
    };

    handleChangeNewPassword = e => {
        const { password } = e;
        this.validateNewPassword(password);
        this.setState({
            newPassword: password
        });
    };

    handleChangeConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        this.setState(
            {
                confirmNewPassword: value
            },
            () => {
                const { confirmNewPassword, newPassword } = this.state;
                if (newPassword !== confirmNewPassword) {
                    this.setState({
                        confirmNewPasswordError: true
                    });
                } else {
                    this.setState({
                        confirmNewPasswordError: false
                    });
                }
            }
        );
    };

    showPopUp = (
        msgId: string = '_blank',
        callback: () => void = () => {},
        type: SwalInterface['type'] = 'warning'
    ) => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t(msgId),
            type,
            allowOutsideClick: false,
            confirmButtonText: t('BUTTON.OK')
        }).then(res => {
            if (res.value && callback) {
                callback();
            }
        });
    };

    unsetLoading = (callback?: () => void) => {
        this.setState(
            {
                isLoading: false
            },
            callback
        );
    };

    submitNewPassword = () => {
        const {
            newPasswordErrors: {
                minimumCharacterCount,
                minimumUpperCaseLetter,
                minimumLowerCaseLetter,
                minimumDigitCharacterCount,
                minimumSpecialCharacter
            },
            confirmNewPasswordError,
            newPassword,
            confirmNewPassword
        } = this.state;
        const { conmanService } = this.props;

        if (
            minimumCharacterCount ||
            minimumUpperCaseLetter ||
            minimumLowerCaseLetter ||
            minimumDigitCharacterCount ||
            minimumSpecialCharacter
        ) {
            this.showPopUp('OPERATOR_CHANGE_PASSWORD.NEW_PASSWORD_VALIDATION_FAILED');
        } else if (!confirmNewPassword) {
            this.showPopUp('OPERATOR_CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_REQUIRED');
        } else if (confirmNewPasswordError) {
            this.showPopUp('OPERATOR_CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_NOT_MATCH_NEW_PASSWORD');
        } else {
            this.setState({
                isLoading: true
            });
            conmanService
                .changeForgotPassword(
                    newPassword,
                    confirmNewPassword,
                    this.userId,
                    this.token
                )
                .then(res => {
                    if (res) {
                        this.unsetLoading(() => {
                            if (res.data.statusCode === 200) {
                                this.showPopUp(
                                    res.data.message,
                                    () => {
                                        window.location.assign(BASE_WEBPAGE_URL);
                                    },
                                    'success'
                                );
                            } else {
                                this.showPopUp(res.data.message);
                            }
                        });
                    }
                })
                .catch(err => {
                    this.unsetLoading(() => {
                        this.showPopUp(err);
                    });
                });
        }
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(ChangePassword);
