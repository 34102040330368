import React from 'react';
import { IFRAME_ADD_FEE, IFRAME_EDIT_FEE } from 'constants/Constants';
import { getIframeAPIURL, getParamFromUrl } from 'util/ControlUtils';

import * as Route from 'pages/RouteLoader';
import Loading from 'components/Loading';
import embededWrapper from 'hocs/Embeded';
import ThisInterface from '../interface';

const embeded = (route, title = '', description = '') => {
    class MFAddEditFeePage extends React.Component<ThisInterface['props'], { isEditMode: boolean }> {
        constructor(props: ThisInterface['props']) {
            super(props);
            this.state = {
                isEditMode: !!this.props.id
            }
        }
        componentDidMount() {
            window.addEventListener("message", this.messageHandler, false);
        }
	
        componentWillUnmount() {
            window.removeEventListener('message', this.messageHandler);
        }
		
        messageHandler = (event) => {
            const { action, value } = event.data;
			
            switch (action) {
                case 'returnHeightData': 
                    const obj: any = this.refs.iframe;
                    if (obj) {
                        obj.style.height = value + 'px';
                    }
                    break;
                case 'redirectURL':
                    if(value.includes('MEMBER_UPDATE_CANCELLED_FIELD')) {
                        this.props.history.push(`/${Route.ItemRoute.url}`);
                    } else if(value.includes('/Products/Items/Item.aspx')) {
                        const item = getParamFromUrl(value, 'ItemId');
                        item && this.props.history.push(`/${Route.ViewFeeRoute.url.replace(':id', item.value)}`);
                    } else if(value.includes('/EmbeddedAction.aspx')){
                        const redirectURL = getParamFromUrl(value, 'REDIRECT_URL');
                        const item = getParamFromUrl(value, 'ItemId');
                        if(redirectURL && item && redirectURL.value.includes('Products\\Items\\Item.aspx')) {
                            this.props.history.push(`/${Route.ViewFeeRoute.url.replace(':id', item.value)}`);
                        }
                    }
                    break;
            }
        }
	
        render() {
            const { iframeLoaded, iframeToken, subTenantId } = this.props;
            const { isEditMode } = this.state;
            const { id } = this.props;
            const editUrl = id ? IFRAME_EDIT_FEE.replace('{0}', id) : '';
            return <div>
                {subTenantId && <div className="member mt-3">
                    <div className="member-main member-main--custom">
                        <div className="content">
                            {!iframeLoaded && <Loading />}
                            {iframeToken && !isEditMode && <iframe src={`${getIframeAPIURL(IFRAME_ADD_FEE, subTenantId, '/MembershipManagement')}&token=${iframeToken}`} ref='iframe' onLoad={() => this.props.resizeIframe(this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
                            {iframeToken && isEditMode && <iframe src={`${getIframeAPIURL(editUrl, subTenantId, '/MembershipManagement')}&token=${iframeToken}`} ref='iframe' onLoad={() => this.props.resizeIframe(this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
                        </div>
                    </div>
                </div>}
            </div>
        }
    }

    return embededWrapper(MFAddEditFeePage, route, title, description);
}

export default embeded;
