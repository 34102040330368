import React from 'react';
import ThisInterface from './interface';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import Loading from 'components/Loading';

const Promotions = (_this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <>
            <form className="option-group select-group open" action="#">
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
            </form>
        </>
    )
}

const SpecialPromotions = (_this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <>
            <form className="option-group" action="#">
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio" />
                </label></div>
                <div className="form-check"><label className="custom-radio">
                    <div className="card-gr">
                        <h4>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.FEE_ADMIN_FEE')}</h4>
                        <ul>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION_DESCRIPTION')}</li>
                            <li>{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.EXPIRES')}: N/A</li>
                        </ul>
                    </div><input type="radio" name="radio" /><span className="checkmark-radio"> </span>
                </label></div>
            </form>
        </>
    )
}

const AddOns = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { isLoading, addOns, selectedAddOns } = _this.state;
    return ( 
        <>
            {isLoading.addOns && <Loading />}
            {!isLoading.addOns && <form className="option-group check-gr">
                {addOns.map((e, key) => {
                    const price = e.totalPrice.toString();
                    const prices = price.indexOf('.') > 0 ? price.split('.') : [price, '00'];
                    return <div key={key} className="form-check">
                        <input 
                            onChange={(event) => _this.handleChangeAddOn(e, event)} 
                            checked={selectedAddOns.filter(f => f.id === e.id).length > 0}
                            id={`addOn${key}`} 
                            type="checkbox" />
                        <label className="icon-checked-ico" htmlFor={`addOn${key}`}>
                            <div className="card-gr">
                                <h4 className="name-add-on">{e.name}</h4>
                            </div>
                        </label>
                        <div className="info-add-on">
                            <p className="monthly">{t(`PAGE.MEMBERSHIPS.CREATE.STEP_2.MC`)}<span className="add-on-number">{prices[0]}.<sup>{prices[1].padEnd(2, '0')}</sup></span></p>
                        </div>
                    </div>
                })}
            </form>}
        </>
    )
}

const template = (_this: ThisInterface) => {
    const { 
        membershipSearchFilter,
        membershipConfiguration, 
        selectedPackagePlan,
        selectedAddons } = _this.props.newMembership;
        
    const {
        openDropdown } = _this.state;

    const { isEdit , t } = _this.props;

    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    {!isEdit && <p>{t('PAGE.MEMBERSHIPS.CREATE.ADD_NEW_MEMBER')}</p>}
                    {isEdit && <p>{t('PAGE.MEMBERSHIPS.EDIT.CHANGE_MEMBERSHIP')}</p>}
                </div>
                <div className="member-main">        
                    <Breadcrumb 
                        isEdit={isEdit}
                        membershipConfiguration={membershipConfiguration} 
                        goToStep={(stepIndex) => _this.goToStep(stepIndex)} 
                    />           
                    <div className="content select-add-on product-extra">
                        <div className="select-add-on-main">
                            <p className="select-add-on-title text-uppercase select-add-on-title--custom">{t('PAGE.MEMBERSHIPS.CREATE.STEP_2.SELECT_PRODUCT_EXTRAS')}</p>
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_2.PROMOTION')}
                                content={Promotions(_this)}
                                isOpen={openDropdown['promotion']}
                                maxHeight="1200px"
                                openCollapsedContent={() => _this.handleOpenDropdown('promotion')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_2.SPECIAL_PROMOTION')}
                                content={SpecialPromotions(_this)}
                                isOpen={openDropdown['specialPromotion']}
                                maxHeight="1200px"
                                openCollapsedContent={() => _this.handleOpenDropdown('specialPromotion')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_2.ADDONS')}
                                content={AddOns(_this)}
                                isOpen={openDropdown['addOns']}
                                maxHeight="1200px"
                                openCollapsedContent={() => _this.handleOpenDropdown('addOns')}
                            />            
                        </div>
                        <ProductSummary 
                            productSummary={selectedPackagePlan}
                            membershipSearchFilter={membershipSearchFilter}
                            membershipConfiguration={membershipConfiguration}
                            addons={selectedAddons}
                            handlePrevious={() => _this.handlePrevious()} 
                            handleNext={() => _this.handleNext()} 
                            handleCancel={_this.props.onCancel}  
                        />
                    </div>
                </div>
            </div>            
        </section>
    );
}

export default template;

