import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as Interface from 'interfaces';
import template from './template';
import ThisInterface from './interface';

class HomePage extends React.Component<
ThisInterface['props'] & RouteComponentProps<{}>,
ThisInterface['state']
> {
    operator = null;
    constructor(props: ThisInterface['props'] & RouteComponentProps<{}>) {
        super(props);
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ oidc, conman }: Interface.StateToPropInterface) => {
    return { oidc, conman };
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);
