import React from 'react';
import ThisInterface  from './interface';
import PlanModel from 'models/Plan';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
}
interface Props {
    data: PlanModel;
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true
        }
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { data, t } = this.props;

        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.RELATED_FEE')} <span className="item-count">({t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.COUNT_ITEMS', {count: data.payments.length})})</span></button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    <div className="table-list">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.ITEM_CODE')}</th>
                                        <th>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.DESCRIPTION')}</th>
                                        <th>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.GROUP')}</th>
                                        <th className="text-right">{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.ACTION')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.payments.map((e, key) => 
                                        <tr key={key}>
                                            <td>{e.code}</td>
                                            <td>{e.name}</td>
                                            <td>{e.name}</td>
                                            <td className="text-right">
                                                <div className="td-action">
                                                    <button className="btn-transparent" title="Action"><i className="icon icon-dots-horizontally" /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {data.payments.length === 0 && <tr><td className="text-center" colSpan={4}>{t('COMMON.NO_DATA')}</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;