import React from 'react';
import membershipService from 'services/membership.service'
import { withRouter } from 'react-router';
import MemberSummary from './pages/MemberSummary';
import MemberSummary2 from './pages/MemberSummaryVer2';
import MemberSummaryFutureEvent from './pages/MemberSummary-FutureEvent';
import MemberSummaryNoDebt from './pages/MemberSummaryVer2-NoDebt';
import MemberSummaryNoDebtFutureEvent from './pages/MemberSummary-NoDebt-FutureEvent';
import ChangeSummary from './pages/ChangeMembershipPage/index';
import ListComponentDemo from './pages/ListComponentDemo';
import DemoButtonPage from './pages/DemoButtonPage';

class HTMLPage extends React.Component<any> {
    membershipService: membershipService
    constructor(props) {
        super(props)
        this.membershipService = new membershipService();
    }

    componentDidMount() {
        const siteAppEl = document.querySelector('#app-site');
        siteAppEl && siteAppEl.setAttribute('class', 'sidebar-demo');
    }

    renderContent = () => {
        const { match: { params } } = this.props;
        switch (params.name) {
            case 'membersummary':
                return <div className="table-list">
                    <div className="membersummary-page">
                        <MemberSummary />
                    </div>
                </div>

            case 'changesummary':
                return <div className="table-list">
                    <div className="membersummary-page">
                        <ChangeSummary />
                    </div>
                </div>
            case 'membersummary2':
                return <div className="table-list">
                    <div className="membersummary-page">
                        <MemberSummary2 />
                    </div>
                </div>
            case 'membersummary2-nodebt':
                return <div className="table-list">
                    <div className="membersummary-page">
                        <MemberSummaryNoDebt />
                    </div>
                </div>
            case 'membersummary2-future-event':
                return <div className="table-list">
                    <div className="membersummary-page">
                        <MemberSummaryFutureEvent />
                    </div>
                </div>
            case 'membersummary2-nodebt-future-event':
                return <div className="table-list">
                    <div className="membersummary-page">
                        <MemberSummaryNoDebtFutureEvent />
                    </div>
                </div>
                
            case 'listcomponent':
                return <ListComponentDemo />
            case 'buttons':
                return <DemoButtonPage />
            default:
                return <></>
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderContent()}
            </React.Fragment>    
        );
    }
}

export default withRouter(HTMLPage);
