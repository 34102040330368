import { submitModal } from 'components/CommonModal';
import { API_GET_UNFREEZE_PREVIEW, API_UNFREEZE } from 'constants/Constants';
import { SWAL_COMMON_STYLE } from 'constants/DefaultConstants';
import { UnfreezeMembershipRoute } from 'pages/RouteLoader';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CommonService from 'services/common.service';
import Swal from 'sweetalert2';
import { EmailTypes } from '../../EmailSummary/interface';
import { ThisInterface } from './interface';
import { template } from './template';

class UnfreezeMembership extends React.Component<ThisInterface['props'], ThisInterface['state']> {
    commonService: CommonService;

    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {
            isLoading: false,
            communicationEmail: null,
            communicationOption: EmailTypes.existEmail,
            isValidForm: true,
            unfreezeDetails: {
                id: '',
                memberId: '',
                startedDateUtc: '',
                endDateUtc: '',
                newEndDateUtc: '',
                proRataFee: 0,
                fees: []
            }
        };
        this.commonService = new CommonService();
    }

    componentDidMount() {
        this.getUnfreezeDetails();
    }

    getUnfreezeDetails = () => {
        const { events } = this.props.memberData;
        const freezeEvent = events.find(e => e.type === 'FZ');
        if(!freezeEvent) return Promise.reject();

        const URL = API_GET_UNFREEZE_PREVIEW
            .replace('{id}', this.props.memberData.id)
            .replace('{freezeId}', freezeEvent.id);

        this.setState({
            isLoading: true
        });
        
        this.commonService.postData(URL, {
            month: 6
        }, {
            subTenantId: this.props.memberData.subTenantId,
            rightName: UnfreezeMembershipRoute.rightName
        }).then(data => {
            if(data) {
                this.setState({
                    unfreezeDetails: {
                        ...data,
                        fees: data.fees ? data.fees.filter(fee => fee && fee.amount > 0) : []
                    },
                    isLoading: false
                });
            }
        }).catch(this.props.onCancel);
    }

    handleCommunicationEmailChanged = (value) => {
        if(!value) return;
        this.setState({
            communicationEmail: value.email,
            communicationOption: value.emailType,
            isValidForm: !value.error
        });
    }

    unfreeze = () => {
        const { t } = this.props;
        const { communicationEmail, communicationOption } = this.state;
        let freezeNoticeEmail: string | null = null;
        if(communicationOption === EmailTypes.existEmail) {
            freezeNoticeEmail = this.props.memberData.email;
        } else if(communicationOption === EmailTypes.newEmail) {
            freezeNoticeEmail = communicationEmail;
        }
        const payload = { freezeNoticeEmail };
        const URL = API_UNFREEZE
            .replace('{id}', this.props.memberData.id)
            .replace('{freezeId}', this.state.unfreezeDetails.id);
            
        this.commonService.postData(URL, payload, {
            subTenantId: this.props.memberData.subTenantId,
            rightName: UnfreezeMembershipRoute.rightName
        }).then(() => {
            Swal.fire({
                ...SWAL_COMMON_STYLE,
                type: 'success',
                html: t('PAGE.UNFREEZE_MEMBERSHIP.SUCCESS'),
                confirmButtonText: t('BUTTON.CLOSE'),
            }).then(() => this.props.onCancel());
        });
    }

    finish = () => {
        const { t } = this.props;
        submitModal(t('PAGE.UNFREEZE_MEMBERSHIP.CONFIRM_MESSAGE'), () => {
            this.unfreeze();
        });
    }

    render() {
        return template(this);
    }
}

export default withTranslation()(UnfreezeMembership);