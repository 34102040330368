import React from "react";
import CommonService from 'services/common.service';
import ConmanService from 'services/conman.service';
import PlanService from 'services/plan.service';
import FeeService from 'services/fee.service';
import MembershipService from 'services/membership.service';
import UserService from 'services/user.service';

export const services = {
    commonService: new CommonService(),
    conmanService: new ConmanService(),
    membershipService: new MembershipService(),
    planService: new PlanService(),
    feeService: new FeeService(),
    userService: new UserService()
};

const ApiContext = React.createContext(services);

export default ApiContext;