import React from 'react';
import Loading from 'components/Loading';
import CardInfo from './CardInfo';

import ThisInterface, { Steps } from './interface';
import ChangePaymentDetailSummary from './ChangePaymentDetailSummary';

const ButtonItem = ({ onClick, type = 'primary', disabled = false, title }) => {
    return (
        <li>
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const renderButtonAction = (_this: ThisInterface) => {
    const { step, disabledNext, disabledFinish } = _this.state;
    switch (step) {
        case Steps.Form:
            return disabledNext ? () => { } : _this.review();
        case Steps.Review:
            return disabledFinish ? () => { } : _this.finish();
        default:
            return () => { };
    }
};

export const Template: React.FC<ThisInterface> = _this => {
    const {
        step,
        paymentDetails,
        isLoading,
        banks,
        fields,
        disabledNext,
        planPaymentMethods,
        basePaymentDetails,
        disabledFinish
    } = _this.state;
    const { primary, secondary } = paymentDetails,
        { primary: basePrimary } = basePaymentDetails;

    const { t } = _this.props;   
    
    const renderReview = () => (
        <ChangePaymentDetailSummary
            memberData={_this.props.memberData}
            paymentDetails={paymentDetails}
        />
    );

    const renderForm = () => {
        return (
            <div className="change-payment-detail">
                <div className="payment-method">
                    <p className="section-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.PRIMARY_PAYMENT_METHOD')}
                    </p>
                    {primary && (
                        <div className="section-content">
                            <CardInfo
                                planPaymentMethods={planPaymentMethods}
                                primary={primary}
                                banks={banks}
                                fields={fields}
                                basePrimary={basePrimary}
                                handleFieldChanged={_this.handleFieldChanged}
                                handlePaymentMethodChanged={_this.handlePaymentMethodChanged}
                                validations={_this.state.validations}
                                handleChangeToogle={_this.handleChangeToogle}
                                isShowCreditCardModify={_this.state.isShowCreditCardModify}
                            />
                        </div>
                    )}
                </div>
                <div className="payment-method">
                    <p className="section-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.SECONDARY_PAYMENT_METHOD')}
                    </p>
                    <div className="section-content">
                        <div className="row">
                            <div className="col-3">
                                {t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.SECONDARY_PAYMENT_METHOD')}
                            </div>
                            <div className="col-4">
                                {secondary && secondary.paymentMethod
                                    ? secondary.paymentMethod.name
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        switch (_this.state.step) {
            case Steps.Form:
                return renderForm();
            case 1:
                return renderReview();
            default:
                return renderForm();
        }
    };

    return isLoading ? (
        <Loading />
    ) : (
        <div className="change-payment-detail">
            <div className="row">
                <div className="col-12 col-xs-12">
                    <div className="section-down">
                        <div className="change-payment-detail">
                            <h3 className="text-title">
                                {t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.CHANGE_PAYMENT_DETAIL')}
                            </h3>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-4">
                    <ul className="list-btn-action">
                        {step === Steps.Review && (
                            <ButtonItem
                                onClick={_this.viewForm}
                                title={t('BUTTON.PREVIOUS')}
                            />
                        )}
                        <ButtonItem
                            onClick={() => renderButtonAction(_this)}
                            title={
                                step === Steps.Form ? t('BUTTON.NEXT') : t('BUTTON.FINISH')
                            }
                            disabled={step === Steps.Form ? disabledNext : disabledFinish}
                        />
                        <ButtonItem
                            onClick={_this.props.onCancel}
                            title={t('BUTTON.CANCEL')}
                            type="dark"
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
};
