import React from 'react';
import ThisInterface from './interface';
import PlanModel, {
    ClubGroupsModel,
    ClubsModel
} from 'models/Plan';
import { FieldModel } from 'models/Field';
import OptionModel from 'models/Option';
import { chunk, removeDuplicatedObj } from 'util/ControlUtils';
import observer from 'util/Observer';

import FormItem from 'components/FormV2';
import Loading from 'components/Loading';
import Language from 'models/Language';
import * as Constants from 'constants/Constants';
import i18n from 'i18next';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoadingClubs: boolean;
    clubGroups: OptionModel[];
    clubs: OptionModel[];
}
interface Props {
    onChangeHandle: Function;
    data: PlanModel;
    fields: FieldModel[];
    locale?: Language;
    t?: any;
}

class Template extends React.PureComponent<
Props & ThisInterface['props'],
State
> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoadingClubs: true,
            clubGroups: [],
            clubs: []
        };
    }

    componentDidMount() {
        observer.subscribe('plan.clubGroups.changed', data => {
            if (data) {
                if (this.props.data && this.props.data.clubGroups) {
                    this.loadClubsByClubGroups(this.props.data.clubGroups.map(e => e.id));
                }
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('plan.clubGroups.changed');
    }

    toogleExpand() {
        this.setState(
            state => ({ isExpand: !state.isExpand }),
            () => {
                setInterval(
                    () => this.setState({ isExpanded: this.state.isExpand }),
                    500
                );
            }
        );
    }

    loadClubsByClubGroups(value) {
        const { subTenantId, rightName } = this.props;
        this.setState({ isLoadingClubs: true });
        this.props.planService.getClubs(value, subTenantId, rightName).then(
            res => {
                const clubs: ClubsModel[] = res.data;
                const clubsOptions = clubs.map(e => ({ value: e.id, label: e.name }));
                this.setState({
                    clubs: removeDuplicatedObj(clubsOptions),
                    isLoadingClubs: false
                });
            },
            () => {
                this.setState({
                    clubs: [],
                    isLoadingClubs: false
                });
            }
        );
    }

    render() {
        const { onChangeHandle, data, fields, locale, subTenantId, rightName, t } = this.props;
        const clubGroupsValue = data.clubGroups.map(e => ({value: e.id, label: e.name}));
        
        const buildOptions = (objects: any) => {
            if (objects && objects.data && objects.data.length) {
                return objects.data.map((e: ClubGroupsModel) => ({value: e.id, label: e.name}));
            }
            return [];
        }

        const buildFilterObject = (keyword: string) => {
            const keywords = {
                operation: 'contains',
                queryType: 'text',
                queryKey: 'name',
                queryValue: keyword
            };
            const value = data.clubGroups;
            if (value && value.length && !keyword) {
                return  JSON.stringify(
                    {
                        or: 
                                [   
                                    keywords, 
                                    {
                                        operation: 'in',
                                        queryType: 'guid',
                                        queryKey: 'id',
                                        queryValue: value.map(e => e.id).join(',')
                                    }
                                ]
                    }
                );
            } else {
                return JSON.stringify(keywords);
            }
        }

        const availableDateColumns = [
            {
                props: {
                    type: 'calendar',
                    htmlFor: 'availability[effectiveDate]',
                    fieldName: 'availability[effectiveDate]',
                    datePickerData: data.effectiveDate
                        ? new Date(data.effectiveDate)
                        : undefined,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.AVAILABLE_FROM'),
                    locale: i18n.language
                },
                className: 'col-5 available-from'
            },
            {
                props: {
                    type: 'html',
                    html: <span>-</span>
                },
                className: 'col-1 line-date'
            },
            {
                props: {
                    type: 'calendar',
                    htmlFor: 'availability[expirationDate]',
                    fieldName: 'availability[expirationDate]',
                    datePickerData: data.expirationDate
                        ? new Date(data.expirationDate)
                        : undefined,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.AVAILABLE_TO'),
                    locale: i18n.language
                },
                className: 'col-5 available-to'
            }
        ];

        const optionsColumns = [
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[requirePermissionToSell]',
                    fieldName: 'availability[requirePermissionToSell]',
                    data: data.isRequiresPermissionSellRestrictedItem ? 1 : 0,
                    attribute: {
                        checked: data.isRequiresPermissionSellRestrictedItem
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.REQUIRES_PERMISSION_TO_SELL_RESTRICTED_PLANS')
                },
                className: 'form-check mt-3'
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[availableSoldTelesale]',
                    fieldName: 'availability[availableSoldTelesale]',
                    data: data.isAvailableForTeleSales ? 1 : 0,
                    attribute: {
                        checked: data.isAvailableForTeleSales
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.AVAILABLE_TO_BE_SOLD_VIA_TELESALES')
                },
                className: 'form-check mt-3'
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[availableSoldWeb]',
                    fieldName: 'availability[availableSoldWeb]',
                    data: data.isAvailableForWeb ? 1 : 0,
                    attribute: {
                        checked: data.isAvailableForWeb
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.AVAILABLE_TO_BE_SOLD_VIA_WEB')
                },
                className: 'form-check mt-3'
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'availability[availableSoldClub]',
                    fieldName: 'availability[availableSoldClub]',
                    data: data.isAvailableForClub ? 1 : 0,
                    attribute: {
                        checked: data.isAvailableForClub
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.AVAILABLE_TO_BE_SOLD_IN_CLUB')
                },
                className: 'form-check mt-3'
            }
        ];

        const clubGroupColumns = [
            {
                props: {
                    type: 'multi-select',
                    htmlFor: 'availability[clubGroups]',
                    fieldName: 'availability[clubGroups]',
                    multiSelectData: clubGroupsValue,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.PLAN_IS_AVAILABLE_FOR_SALE_IN'),
                    advanceOptions: {
                        apiEndpoint: Constants.API_PLAN_GET_CLUBGROUPS,
                        buildOptions: buildOptions,
                        // buildFilterObject: buildFilterObject,
                        // pageSize: Constants.GENERAL_OPTION_PAGE_SIZE,
                        subTenantId: subTenantId,
                        rightName: rightName
                    }
                }
            }
        ];

        const clubsChunk = chunk(this.state.clubs, 4);

        return (
            <div className="collapse-promotion collapse-saas">
                <button
                    className={this.state.isExpand ? 'collapsible active' : 'collapsible'}
                    onClick={() => this.toogleExpand()}
                >
                    {t('PAGE.PRODUCTS.PLANS.CLONE.AVAILABILITY')}
                </button>
                <div
                    className={this.state.isExpanded ? 'content open' : 'content'}
                    style={{ maxHeight: this.state.isExpand ? '10000px' : '0' }}
                >
                    <div className="row">
                        <div className="col-lg-5 mb-3">
                            <h4 className="title">
                                {t('PAGE.PRODUCTS.PLANS.CLONE.OPTIONS')}
                            </h4>
                            <div className="row align-items-center">
                                {availableDateColumns.map((field, key) => (
                                    <div key={key} className={field.className}>
                                        <FormItem
                                            {...field.props}
                                            onChangeHandle={onChangeHandle}
                                            fields={fields}
                                        />
                                    </div>
                                ))}
                            </div>
                            {optionsColumns.map((field, key) => (
                                <div key={key} className={field.className}>
                                    <FormItem
                                        {...field.props}
                                        onChangeHandle={onChangeHandle}
                                        fields={fields}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-7">
                            <h4 className="title">
                                {t('PAGE.PRODUCTS.PLANS.CLONE.CLUB_GROUPS')}
                            </h4>
                            <div className="row">
                                <div className="col-10">
                                    {clubGroupColumns.map((field, key) => (
                                        <div key={key}>
                                            <FormItem
                                                {...field.props}
                                                onChangeHandle={onChangeHandle}
                                                fields={fields}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {this.state.isLoadingClubs ? (
                                <Loading />
                            ) : (
                                this.state.clubs.length > 0 && (
                                <>
                                    <div className="mt-3">
                                        <span className="font-weight-bold">
                                            {this.state.clubs.length}{' '}
                                        </span>
                                        <span>{t('PAGE.PRODUCTS.PLANS.EDIT.CLUBS')} </span>
                                    </div>
                                    <div className="mt-3">
                                        {clubsChunk.map((row, rowKey) => (
                                            <div key={rowKey} className="row mb-2">
                                                {row.map((value, key) => (
                                                    <div key={key} className="col-3">
                                                        {value.label}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;
