import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import ThisInterface from './interface';
import { withTranslation, WithTranslation } from 'react-i18next';

class AddEditAddonPage extends React.Component<
ThisInterface['props'] & WithTranslation  & RouteComponentProps<{}>,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props']  & WithTranslation & RouteComponentProps<{}>) {
        super(props);
        const pathname = props.location.pathname;
        const id = props.match.params['id'];
        const iframeSubtenantId = localStorage.iframeSubtenantId ? localStorage.iframeSubtenantId : '';

        this.state = {
            id: id,
            isEditMode: id && pathname.indexOf('edit') > -1 ? true : false,
            isEditSimpleMode: id && pathname.indexOf('edit-simple') > -1 ? true : false,
            
            iframeLoaded: false,
            iframeToken: '',
            subTenantId: iframeSubtenantId
        }
        if (iframeSubtenantId) {
            this.getIframeToken(iframeSubtenantId);
        }
    }

    getIframeToken(subTenantId) {
        this.props.conmanService.getIframeToken(subTenantId).then(res => {
            this.setState({
                iframeToken: res.message
            })
        });
    }

    handleNext = (subTenant) => {
        this.setState({subTenantId: subTenant.id});
        localStorage.iframeSubtenantId = subTenant.id;
        this.getIframeToken(subTenant.id);
    }

    componentDidMount = () => {
        window.addEventListener("message", this.messageHandler, false);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data
        if (action == 'returnHeightData') {
            const obj: any = this.refs.iframe;
            if (obj) {
                obj.style.height = value + 'px';
            }
        }
    }

    resizeIframe = (obj) => {
        this.setState({iframeLoaded: true});
        obj.contentWindow.postMessage({
            action: 'getHeightData'
        }, '*');
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(AddEditAddonPage)));
