import React, { useState, useEffect } from 'react';
import { FieldModel } from 'models/Field';
import {
    FormItemInterface,
    isValidOrNot,
    renderErrorHtml,
    AdvanceOptions
} from './index';
import OptionModel from 'models/Option';
import CommonService from 'services/common.service';
import axios from 'axios';

import AsyncSelect from 'react-select/async';

interface Props extends FormItemInterface {
    multiSelectData?: OptionModel[] | undefined;
    selectAllLabel: string | undefined;
    options: OptionModel[] | undefined;
    advanceOptions: AdvanceOptions | undefined;
}

const MultiSelectBoxAsyncGroup = React.memo((props: Props) => {
    const commonService = new CommonService();
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        selectAllLabel,
        className = 'select-custom',
        multiSelectData,
        attribute,
        options = [],
        advanceOptions,
        onChangeHandle,
        htmlLabel
    } = props;

    const [value, setValue] = useState<OptionModel[] | undefined>([]);
    useEffect(() => {
        const { multiSelectData } = props;
        if (
            value &&
      multiSelectData &&
      value.length &&
      multiSelectData.length &&
      advanceOptions
        ) {
            buildValue(multiSelectData, value);
        } else {
            setValue(multiSelectData);
        }
    }, [multiSelectData]);

    const onChange = event => {
        let value = [];
        if (event) {
            value = event.map(e => e.value);
        }
        onChangeHandle(fieldName, value);
    };

    const buildValue = (value: OptionModel[], options: OptionModel[]) => {
        if (value) {
            let hasChanged = false;
            const newValue = value.map(e => {
                const existOption = options.find(o => o.value === e.value);
                if (existOption && !e.label) {
                    e.label = existOption.label;
                    hasChanged = true;
                }
                return e;
            });
            hasChanged && setValue(newValue);
        }
    };

    const filterKeywords = (inputValue: string) => {
        return options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = inputValue => {
        const { advanceOptions } = props;
        if (advanceOptions) {
            const {
                apiEndpoint,
                buildOptions,
                buildFilterObject,
                pageSize,
                subTenantId,
                rightName
            } = advanceOptions;
            const headers = commonService.getHeaderWithSubtenantAndRightName(
                subTenantId,
                rightName,
                false
            );
			
            const filter = buildFilterObject && pageSize ? `?filter=${buildFilterObject(
                inputValue
            )}&pageSize=${pageSize}&pageIndex=0` : ''

            return axios
                .get(`${apiEndpoint}${filter}`,
                    { headers }
                )
                .then(res => {
                    const options = buildOptions(res.data);
                    value && buildValue(value, options);
                    return options;
                })
                .catch(err => commonService.handleErrorHttp(err));
        } else {
            return new Promise(resolve => {
                resolve(filterKeywords(inputValue));
            });
        }
    };

    const disabled = attribute && attribute.disabled;

    let isClearable = attribute && attribute.isClearable;
    if (attribute && attribute.hasOwnProperty('isClearable')) {
        delete attribute.isClearable;
    } else {
        isClearable = true;
    }

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    const filterValue = value ? value.filter(e => e.label) : [];
    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={className + ' ' + errorClass}>
                <AsyncSelect
                    key={`${JSON.stringify(multiSelectData)}${value}`}
                    {...attribute}
                    isMulti
                    value={filterValue}
                    onChange={onChange}
                    className="select-async"
                    name={fieldName}
                    id={htmlFor}
                    cacheOptions
                    defaultOptions={advanceOptions ? true : options}
                    loadOptions={promiseOptions}
                    classNamePrefix="select"
                    isDisabled={disabled}
                    isClearable={isClearable}
                    placeholder={selectAllLabel}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
MultiSelectBoxAsyncGroup.displayName = 'MultiSelectBoxAsyncGroup';

export default MultiSelectBoxAsyncGroup;
