import React from 'react';
import { WithTranslation } from 'react-i18next';
import FormItem, { getIdOrUndefined } from 'components/FormV2';
import OptionModel from 'models/Option';
import PlanModel from 'models/Plan';
import { FieldModel } from 'models/Field';
import { DisplayDateFormat } from 'util/ControlUtils';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import ThisInterface from './interface';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
}
interface Props extends WithTranslation {
    onChangeHandle: Function;
    data: PlanModel;
    fields: FieldModel[];
    hideComments: boolean;
}

class Template extends React.PureComponent<
Props & ThisInterface['props'],
State
> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true
        };
    }

    toogleExpand() {
        this.setState(
            state => ({ isExpand: !state.isExpand }),
            () => {
                setInterval(
                    () => this.setState({ isExpanded: this.state.isExpand }),
                    500
                );
            }
        );
    }

    render() {
        const { onChangeHandle, data, fields, hideComments, t } = this.props;

        let paymentColumns: any[] = [];
        let sellingPriceColumns: any[][] = [];
        data.payments.forEach((row, key) => {
            const collectionMethodOption: OptionModel[] = row.collectionMethod
                ? [{ value: row.collectionMethod.id, label: row.collectionMethod.name }]
                : [];
            paymentColumns[key] = [
                {
                    props: {
                        type: 'select',
                        htmlFor: 'payment[' + key + '][collectionMethod]',
                        fieldName: 'payment[' + key + '][collectionMethod]',
                        attribute: {
                            disabled: true
                        },
                        data: getIdOrUndefined(row.collectionMethod),
                        options: collectionMethodOption
                    }
                }
            ];
            sellingPriceColumns[key] = [];

            if (row.prices) {
                row.prices.forEach((price, priceKey) => {
                    const priorityOption: OptionModel[] =
            			price.priority || price.priority === 0
            			    ? [
            			        {
            			            value: String(price.priority),
            			            label: String(price.priority)
            			        }
            			    ]
            			    : [];
                    const clubGroupOption: OptionModel[] = price.clubGroup
                        ? [{ value: price.clubGroup.id, label: price.clubGroup.name }]
                        : [];
                    sellingPriceColumns[key].push([
                        {
                            props: {
                                type: 'html',
                                html: (
                                    <span className="price-is mt-1">
                                        {t('PAGE.PRODUCTS.PLANS.CLONE.PRICE_IS')}
                                    </span>
                                )
                            },
                            className: 'mrc-5'
                        },
                        {
                            props: {
                                type: 'text',
                                htmlFor: 'payment[' + key + '][' + priceKey + '][priceIs]',
                                fieldName: 'payment[' + key + '][' + priceKey + '][priceIs]',
                                attribute: {
                                    disabled: true
                                },
                                data: price.value,
                                className: 'input-group'
                            },
                            className: 'price-is'
                        },
                        {
                            props: {
                                type: 'html',
                                html: <span className="span-1" />
                            },
                            className: 'span-1'
                        },
                        {
                            props: {
                                type: 'html',
                                html: (
                                    <span className="priority mt-1">
                                        {t('PAGE.PRODUCTS.PLANS.CLONE.PRIORITY')}
                                    </span>
                                )
                            },
                            className: 'mrc-5'
                        },
                        {
                            props: {
                                type: 'select',
                                htmlFor: 'payment[' + key + '][' + priceKey + '][priority]',
                                fieldName: 'payment[' + key + '][' + priceKey + '][priority]',
                                attribute: {
                                    disabled: true
                                },
                                data: price.priority.toString(),
                                options: priorityOption
                            },
                            className: 'priority'
                        },
                        {
                            props: {
                                type: 'html',
                                html: <span className="span-2" />
                            },
                            className: 'span-2'
                        },
                        {
                            props: {
                                type: 'html',
                                html: (
                                    <span className="is-club mt-1">
                                        {t('PAGE.PRODUCTS.PLANS.CLONE.IS_CLUBS_IN_GROUP')}
                                    </span>
                                )
                            },
                            className: 'mrc-5'
                        },
                        {
                            props: {
                                type: 'select',
                                htmlFor: 'payment[' + key + '][' + priceKey + '][inClubGroup]',
                                fieldName:
                  					'payment[' + key + '][' + priceKey + '][inClubGroup]',
                                attribute: {
                                    disabled: true
                                },
                                data: price.clubGroup ? price.clubGroup.id : null,
                                options: clubGroupOption
                            },
                            className: 'is-club'
                        },
                        {
                            props: {
                                type: 'html',
                                html: <span className="span-3" />
                            },
                            className: 'span-3'
                        },
                        {
                            props: {
                                type: 'html',
                                html: <span className="span-null" />
                            },
                            className: 'span-null'
                        },
                        {
                            props: {
                                type: 'html',
                                html: (
                                    <span className="from-to mt-1">
                                        {t('PAGE.PRODUCTS.PLANS.CLONE.FROM')}
                                    </span>
                                )
                            },
                            className: 'mrc-5'
                        },
                        {
                            props: {
                                type: 'calendar',
                                htmlFor: 'payment[' + key + '][' + priceKey + '][from]',
                                fieldName: 'payment[' + key + '][' + priceKey + '][from]',
                                attribute: {
                                    disabled: true
                                },
                                datePickerData: price.effectiveDate
                                    ? new Date(price.effectiveDate)
                                    : undefined,
                                className: 'input-group'
                            },
                            className: 'from-to'
                        },
                        {
                            props: {
                                type: 'html',
                                html: <span className="span-4" />
                            },
                            className: 'span-4'
                        },
                        {
                            props: {
                                type: 'html',
                                html: (
                                    <span className="from-to mt-1">
                                        {t('PAGE.PRODUCTS.PLANS.CLONE.TO')}
                                    </span>
                                )
                            },
                            className: 'mrc-5'
                        },
                        {
                            props: {
                                type: 'calendar',
                                htmlFor: 'payment[' + key + '][' + priceKey + '][to]',
                                fieldName: 'payment[' + key + '][' + priceKey + '][to]',
                                attribute: {
                                    disabled: true
                                },
                                datePickerData: price.expirationDate
                                    ? new Date(price.expirationDate)
                                    : undefined,
                                className: 'input-group'
                            },
                            className: 'from-to'
                        }
                    ]);
                });
            }
        });

        const commentColumns = [
            {
                props: {
                    type: 'textarea',
                    htmlFor: 'payment[comment]',
                    fieldName: 'payment[comment]',
                    data: data.comment,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.COMMENT')
                },
                className: 'comment'
            }
        ];

        return (
            <div className="collapse-promotion collapse-saas">
                <button
                    className={this.state.isExpand ? 'collapsible active' : 'collapsible'}
                    onClick={() => this.toogleExpand()}
                >
                    {t('PAGE.PRODUCTS.PLANS.CLONE.PAYMENTS')}
                </button>
                <div
                    className={
                        this.state.isExpanded ? 'content open payments' : 'content payments'
                    }
                    style={{ maxHeight: this.state.isExpand ? '10000px' : '0' }}
                >
                    <div className="payments-main-holder">
                        {data.payments
                            ? data.payments.map((row, rowKey) => (
                                <div key={rowKey}>
                                    <div className="d-flex">
                                        <h4 className="mt-1 title uppercase">{row.name}</h4>
                                    </div>
                                    <h4 className="mt-3 font-weight-normal">
                                        {t('PAGE.PRODUCTS.PLANS.CLONE.ITEM')}
                                    </h4>
                                    <div className="row">
                                        <div className="col-lg-4 col-xl-3">
                                            <div className="row mt-3">
                                                <div className="col-6 title-medium">
                                                    {t('PAGE.PRODUCTS.PLANS.CLONE.ITEMID')}
                                                </div>
                                                <div className="col-6">{row['itemId']}</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-5">
                                            <div className="row my-3">
                                                <div className="col-5 title-medium mt-1">
                                                    {t('PAGE.PRODUCTS.PLANS.CLONE.COLLECTION_METHOD')}
                                                </div>
                                                <div className="col-7">
                                                    {paymentColumns[rowKey].map((field, key) => (
                                                        <FormItem
                                                            key={key}
                                                            {...field.props}
                                                            onChangeHandle={onChangeHandle}
                                                            fields={fields}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="selling-price">
                                        <h4 className="font-weight-normal">
                                            {t('PAGE.PRODUCTS.PLANS.CLONE.SELLING_PRICES')}
                                        </h4>
                                        {sellingPriceColumns[rowKey].length === 0 &&
                        t('COMMON.NO_DATA')}
                                        {sellingPriceColumns[rowKey].map(
                                            (sellingRow, sellingKey) => (
                                                <div key={sellingKey} className="mt-3">
                                                    {sellingRow.map((field, key) => (
                                                        <span key={key} className={field.className}>
                                                            <FormItem
                                                                {...field.props}
                                                                onChangeHandle={onChangeHandle}
                                                                fields={fields}
                                                            />
                                                        </span>
                                                    ))}
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="hr" />
                                </div>
                            ))
                            : null}
                    </div>
                </div>
                {!hideComments &&
          data.comments &&
          data.comments.map((row, key) => (
              <div key={key} className="mb-2">
                  {row.comment &&
                t('PAGE.PRODUCTS.PLANS.CLONE.COMMENT_DATA', {
                    date: DisplayDateFormat(row.dateTime, GENERAL_DATE_FORMAT),
                    comment: row.comment,
                    name:
                    row.fullName && row.fullName !== ' ' && row.fullName !== ''
                        ? row.fullName
                        : t('PAGE.PRODUCTS.PLANS.CLONE.UNKNOWN')
                })}
              </div>
          ))}
                <div className="mt-3 mb-2" />

                {commentColumns.map((field, key) => (
                    <div key={key} className={field.className}>
                        <FormItem
                            {...field.props}
                            onChangeHandle={onChangeHandle}
                            fields={fields}
                        />
                    </div>
                ))}
            </div>
        );
    }
}
export default Template;
