import React from 'react';

import ThisInterface from '../interface';
import { Actions } from '../common';

const Contact = (_this: ThisInterface) => {
    const { t } = _this.props;

    const actions = [
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.ADD_NOTE'
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.ADD_MESSAGE'
        }
    ]
    return (
        <div className="contact-detail-summary">
            <div className="summary-header">
                <p onClick={() => _this.handleChangeSection('contact-detail')}>
                    {t('PAGE.MEMBERSHIPS.VIEW.CONTACT')}
                </p>
                <Actions actions={actions} t={t} />
            </div>
            <div className="contact-detail">
                <div className="content row">
                    <div className="col-6 detail-data">
                        <div className="number">
                            7
                        </div>
                        <div className="text-content">
                            <div className="title">
                                {t('PAGE.MEMBERSHIPS.VIEW.NOTES')}
                            </div>
                            <div className="value">
								Last added 17/03/2016 by Norfork
                            </div>
                        </div>
                    </div>
                    <div className="col-6 detail-data">
                        <div className="number">
                            0
                        </div>
                        <div className="text-content">
                            <div className="title">
                                {t('PAGE.MEMBERSHIPS.VIEW.MESSAGES')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content row">
                    <div className="col-6 detail-data">
                        <div className="number">
                            0
                        </div>
                        <div className="text-content">
                            <div className="title">
                                {t('PAGE.MEMBERSHIPS.VIEW.EMAILS')}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 detail-data">
                        <div className="number">
                            0
                        </div>
                        <div className="text-content">
                            <div className="title">
                                {t('PAGE.MEMBERSHIPS.VIEW.LETTERS')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content row">
                    <div className="col-6 detail-data">
                        <div className="number">
                            12
                        </div>
                        <div className="text-content">
                            <div className="title">
                                {t('PAGE.MEMBERSHIPS.VIEW.DOCUMENTS')}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 detail-data">
                        <div className="number">
                            0
                        </div>
                        <div className="text-content">
                            <div className="title">
                                {t('PAGE.MEMBERSHIPS.VIEW.INTERACTIONS')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
