import React from 'react';
import ChangeLogo from './ChangeLogo';
import ChangeTheme from './ChangeTheme';
import ApiContext from 'util/ApiContext';
import { FormSelect } from 'components/Form';
import Loading from 'components/Loading';
import { t } from 'util/I18nMessages';

const template = _this => {
    const {
        countries,
        isLoading,
        selectedCountries,
        subTenantId
    } = _this.state;

    return (
        <section className="appearance">
            <p className="title-content">
                {t('PAGE.CONFIG.APPEARANCE_SETTING.TITLE')}
            </p>
            <div className="box-btn-filter ml-auto d-flex mb-3">
                <span>
                    {t('PAGE.CONFIG.APPEARANCE_SETTING.INSTRUCTION_SUB_TENANT')}
                </span>
                {!isLoading && countries.length > 1 && <FormSelect
                    className="select-form"
                    options={countries}
                    value={selectedCountries}
                    onChange={e => _this.handleChangeValue(e, 'selectedCountries')}
                />}
                {!isLoading && countries.length === 1 && <span className="subtenant-name">&nbsp;{selectedCountries.label}</span>}
            </div>
            <div className="tabs">
                <ul className="nav nav-tabs border-bottom-0" role="tablist">
                    <li className="nav-item"><a className="defaultOpen nav-link active text-uppercase" onClick={(event) => _this.openTabs(event, 'change-color')}>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.TITLE')}</a></li>
                    <li className="nav-item"><a className="nav-link text-uppercase" onClick={(event) => _this.openTabs(event, 'change-logo')}>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.TITLE')}</a></li>
                </ul>
                <div className="tab-content">
                    <div className="change-color tab-pane active" id="change-color">
                        {isLoading && <Loading />}
                        {!isLoading && subTenantId && <ApiContext.Consumer>
                            {svrs => <ChangeTheme key={subTenantId} {...svrs} subTenantId={subTenantId} />}
                        </ApiContext.Consumer>}
                    </div>
                    <div className="change-logo tab-pane change-logo--rectangle" id="change-logo">
                        {isLoading && <Loading />}
                        {!isLoading && subTenantId && <ApiContext.Consumer>
                            {svrs => <ChangeLogo key={subTenantId} {...svrs} subTenantId={subTenantId} />}
                        </ApiContext.Consumer>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
