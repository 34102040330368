/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import store from 'reduxs/index';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import template from './template';
import ThisInterface from './interface';
import * as Utils from 'util/ControlUtils';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from 'util/I18nMessages';
import {
    getFieldConfig,
    isFieldRequired,
    filterValuableFieldsOnly
} from 'util/FieldConfiguration';
import {
    PersonalDetailsModel,
    PersonalDetailFieldsModel,
    ContactDetailsModel,
    ContactDetailsFieldsModel,
    AddressDetailsModel,
    AddressDetailsFieldsModel,
    ContactedDetailsModel,
    ContactedDetailsFieldsModel,
    EmergencyContactModel,
    EmergencyContactFieldsModel,
    HealthQuestionModel,
    OtherFieldsModel,
    OtherFieldsFieldsModel,
    LookupModel
} from 'models/PersonalDetails';
import observer from 'util/Observer';
import * as Route from 'pages/RouteLoader';

const isResponseValid = res => {
    return res.data && res.data.length > 0;
};

class SelectPersonalDetails extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);

        this.state = {
            isLoading: true,
            isServiceError: false,
            errors: {},
            openDropdown: {
                personalDetails: true,
                contactDetails: false,
                addressDetails: false,
                contactedDetails: false,
                emergencyContact: false,
                healthQuestion: false,
                otherFields: false
            },
            titles: [],
            genders: [],
            languages: [],
            personalDetails: new PersonalDetailsModel(),
            overrideEmailOptions: [],
            contactDetails: new ContactDetailsModel(),
            countries: [],
            addressDetails: new AddressDetailsModel(),
            marketingSources: [],
            contactMethods: [],
            contactedDetails: new ContactedDetailsModel(),
            emergencyContact: new EmergencyContactModel(),
            questions: [],
            healthQuestions: [],
            aiaVitalityCategories: [],
            recurringCardTypes: [],
            otherFields: new OtherFieldsModel(),
            personalDetailsFieldConfigs: new PersonalDetailFieldsModel(),
            contactDetailsFieldConfigs: new ContactDetailsFieldsModel(),
            addressDetailsFieldConfigs: new AddressDetailsFieldsModel(),
            contactedDetailsFieldConfigs: new ContactedDetailsFieldsModel(),
            emergencyContactFieldsConfigs: new EmergencyContactFieldsModel(),
            otherFieldsFieldsConfigs: new OtherFieldsFieldsModel(),
            duplicateMembers: [],
            duplicateMemberProcessed: false,
            hasChanged: false
        };
    }

    componentDidMount() {
        const {
            membershipService,
            newMembership: { selectedSubTenant },
            t,
            isEdit
        } = this.props;
        const personalDetails = this.populatePersonalDetailsFromReduxStore();
        const contactDetails = this.populateContactDetailsFromReduxStore();
        const addressDetails = this.populateAddressDetailsFromReduxStore();
        const contactedDetails = this.populateContactedDetailsFromReduxStore();
        const emergencyContact = this.populateEmergencyContactFromReduxStore();
        const healthQuestions = this.populateHealthQuestionsFromReduxStore();
        const otherFields = this.populateOtherFieldsFromReduxStore();
        const questions = this.getHealthQuestions();
        //Get local field configs
        const personalDetailsFieldLocalConfigs = this.getPersonalFieldConfiguration(
            InitialFieldConfigs.data
        );
        const contactDetailsFieldLocalConfigs = this.getContactFieldConfiguration(
            InitialFieldConfigs.data
        );
        const addressDetailsFieldLocalConfigs = this.getAddressFieldConfiguration(
            InitialFieldConfigs.data
        );
        const contactedDetailsFieldLocalConfigs = this.getContactedFieldConfiguration(
            InitialFieldConfigs.data
        );
        const emergencyContactFieldsLocalConfigs = this.getEmergencyContactFieldConfiguration(
            InitialFieldConfigs.data
        );
        const otherFieldsFieldsLocalConfigs = this.getOtherFieldsFieldConfiguration(
            InitialFieldConfigs.data
        );
        const rightName = !isEdit
            ? Route.AddNewMemberRoute.rightName
            : Route.ChangeMembershipRoute.rightName;

        Promise.all([
            membershipService.getFieldsConfiguration(selectedSubTenant.id, rightName),
            membershipService.getMemberTitles(selectedSubTenant.id, rightName),
            membershipService.getMemberGenders(selectedSubTenant.id, rightName),
            membershipService.getMemberLanguagePreferences(
                selectedSubTenant.id,
                rightName
            ),
            membershipService.getOverrideEmailOptions(
                selectedSubTenant.id,
                rightName
            ),
            membershipService.getCountries(selectedSubTenant.id, rightName),
            membershipService.getContactMethods(selectedSubTenant.id, rightName),
            membershipService.getMarketingSources(selectedSubTenant.id, rightName),
            membershipService.getAIAVitalityCategories(
                selectedSubTenant.id,
                rightName
            ),
            membershipService.getRecurringCardTypes(selectedSubTenant.id, rightName)
        ])
            .then(
                ([
                    fieldResponse,
                    titleResponse,
                    genderResponse,
                    languageResponse,
                    emailOptionsResponse,
                    countryResponse,
                    contactMethodResponse,
                    marketingSourceResponse,
                    aiaVitalityCategoryResponse,
                    recurringCardTypeResponse
                ]) => {
                    //get field configs from service to override local config if any
                    const personalDetailsFieldConfigs = filterValuableFieldsOnly(
                        this.getPersonalFieldConfiguration(fieldResponse.data)
                    );
                    const contactDetailsFieldConfigs = filterValuableFieldsOnly(
                        this.getContactFieldConfiguration(fieldResponse.data)
                    );
                    const addressDetailsFieldConfigs = filterValuableFieldsOnly(
                        this.getAddressFieldConfiguration(fieldResponse.data)
                    );
                    const contactedDetailsFieldConfigs = filterValuableFieldsOnly(
                        this.getContactedFieldConfiguration(fieldResponse.data)
                    );
                    const emergencyContactFieldsConfigs = filterValuableFieldsOnly(
                        this.getEmergencyContactFieldConfiguration(fieldResponse.data)
                    );
                    const otherFieldsFieldsConfigs = filterValuableFieldsOnly(
                        this.getOtherFieldsFieldConfiguration(fieldResponse.data)
                    );

                    const defaultOption: LookupModel = {
                        value: '',
                        label: 'COMMON.PLEASE_SELECT',
                        key: '',
                        code: '',
                        name: '',
                        id: ''
                    };

                    const titles = this.getOptionList(titleResponse);
                    if (titles.length > 0) {
                        titles.unshift(defaultOption);
                        if (!personalDetails.title.value) {
                            personalDetails.title = titles[0] || new LookupModel();
                        }
                    }

                    const genders = this.getOptionList(genderResponse);
                    if (genders.length > 0) {
                        genders.unshift(defaultOption);
                        if (!personalDetails.gender.value) {
                            personalDetails.gender = genders[0] || new LookupModel();
                        }
                    }

                    const languages = this.getOptionList(languageResponse);
                    if (languages.length > 0 && !personalDetails.preferedLanguage.value) {
                        personalDetails.preferedLanguage =
             				 languages[0] || new LookupModel();
                    }

                    const overrideEmailOptions = this.getOptionList(emailOptionsResponse);
                    if (
                        overrideEmailOptions.length > 0 &&
            			!contactDetails.overrideEmailOption.value
                    ) {
                        contactDetails.overrideEmailOption =
							overrideEmailOptions.find(
							    overrideEmailOption => overrideEmailOption.code === 'EM'
							) ||
							overrideEmailOptions[0] || new LookupModel();
                    }

                    const countries = this.getOptionList(countryResponse);
                    if (countries.length > 0 && !addressDetails.country.value) {
                        addressDetails.country =
							countries.find(
							    country => country.value === selectedSubTenant.countryId
							) || new LookupModel();
                    }

                    const marketingSources = this.getOptionList(marketingSourceResponse);
                    if (marketingSources.length > 0) {
                        marketingSources.unshift(defaultOption);
                        if (!contactedDetails.marketingSource.value) {
                            contactedDetails.marketingSource =
                				marketingSources[0] || new LookupModel();
                        }
                    }

                    const aiaVitalityCategories = this.getOptionList(
                        aiaVitalityCategoryResponse
                    );
                    if (
                        aiaVitalityCategories.length > 0 &&
						!otherFields.aiaVitalityCategory.value
                    ) {
                        otherFields.aiaVitalityCategory =
              				aiaVitalityCategories[0] || new LookupModel();
                    }

                    const recurringCardTypes = this.getOptionList(
                        recurringCardTypeResponse
                    );
                    if (
                        recurringCardTypes.length > 0 &&
            			!otherFields.recurringCardType.value
                    ) {
                        otherFields.recurringCardType =
              				recurringCardTypes[0] || new LookupModel();
                    }

                    this.setState(() => {
                        return {
                            isLoading: false,
                            isServiceError: false,
                            personalDetails,
                            personalDetailsFieldConfigs: {
                                ...personalDetailsFieldLocalConfigs,
                                ...personalDetailsFieldConfigs
                            },
                            contactDetails,
                            contactDetailsFieldConfigs: {
                                ...contactDetailsFieldLocalConfigs,
                                ...contactDetailsFieldConfigs
                            },
                            addressDetails,
                            addressDetailsFieldConfigs: {
                                ...addressDetailsFieldLocalConfigs,
                                ...addressDetailsFieldConfigs
                            },
                            contactedDetails,
                            contactedDetailsFieldConfigs: {
                                ...contactedDetailsFieldLocalConfigs,
                                ...contactedDetailsFieldConfigs
                            },
                            emergencyContact,
                            emergencyContactFieldsConfigs: {
                                ...emergencyContactFieldsLocalConfigs,
                                ...emergencyContactFieldsConfigs
                            },
                            healthQuestions,
                            otherFields,
                            otherFieldsFieldsConfigs: {
                                ...otherFieldsFieldsLocalConfigs,
                                ...otherFieldsFieldsConfigs
                            },
                            titles,
                            genders,
                            languages,
                            overrideEmailOptions,
                            countries,
                            contactMethods: contactMethodResponse.data,
                            marketingSources,
                            questions,
                            aiaVitalityCategories,
                            recurringCardTypes
                        };
                    });
                }
            )
            .catch(error => {
                this.setState({
                    isLoading: false,
                    isServiceError: true,
                    personalDetails,
                    personalDetailsFieldConfigs: personalDetailsFieldLocalConfigs,
                    contactDetails,
                    contactDetailsFieldConfigs: contactDetailsFieldLocalConfigs,
                    addressDetails,
                    addressDetailsFieldConfigs: addressDetailsFieldLocalConfigs,
                    contactedDetails,
                    contactedDetailsFieldConfigs: contactedDetailsFieldLocalConfigs,
                    emergencyContact,
                    emergencyContactFieldsConfigs: emergencyContactFieldsLocalConfigs,
                    healthQuestions,
                    otherFields,
                    otherFieldsFieldsConfigs: otherFieldsFieldsLocalConfigs
                });
            });
    }

    getOptionList = list => {
        return isResponseValid(list)
            ? list.data.map(e => ({
                id: e.id,
                name: e.name, // t(e.key || "DEFAULT_FALLBACK", {defaultMessage: e.name}),
                value: e.id,
                label: e.name, // t(e.key || "DEFAULT_FALLBACK", {defaultMessage: e.name}),
                key: e.key,
                code: e.code
            }))
            : [];
    };

    //#region Handle events
    handlePrevious() {
        const {
            membershipConfiguration,
            membershipConfiguration: { stepLatest }
        } = this.props.newMembership;
        if (stepLatest > 3 && !this.isReadyToNextStep()) return;
        this.storeDataToRedux();
        membershipConfiguration.stepIndex = 2;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
    }

    handleNext() {
        if (this.state.isServiceError) return;
        let isReady = this.isReadyToNextStep();
        if (!isReady) return;

        if (this.state.duplicateMemberProcessed) {
            this.goToNextStep();
        } else {
            const { lastName, dateOfBirth } = this.state.personalDetails;
            const { postCode } = this.state.addressDetails;
            if (!lastName && !dateOfBirth && !postCode) {
                this.goToNextStep();
            } else {
                this.findDuplicateMembers(lastName, dateOfBirth, postCode);
            }
        }
    }

    goToNextStep = () => {
        const { personalDetails } = this.state;

        this.setState({
            duplicateMembers: []
        });

        const {
            membershipConfiguration,
            membershipSearchFilter
        } = this.props.newMembership;
        if (membershipConfiguration.stepLatest < 4) {
            membershipConfiguration.stepLatest = 4;
        }
        membershipConfiguration.stepIndex = 4;
        membershipSearchFilter.dateOfBirth = personalDetails.dateOfBirth;

        store.dispatch(
            MembershipActions.setPackagePlanSearchFilter(membershipSearchFilter)
        );
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        this.storeDataToRedux();
    };

    storeDataToRedux = () => {
        const {
            personalDetails,
            contactDetails,
            addressDetails,
            contactedDetails,
            emergencyContact,
            healthQuestions,
            otherFields
        } = this.state;

        store.dispatch(
            MembershipActions.setPersonalDetails({
                ...personalDetails,
                contactDetails,
                addressDetails,
                contactedDetails,
                emergencyContact,
                healthQuestions,
                otherFields
            })
        );
    };

    handleOpenDropdown = key => {
        const { openDropdown } = this.state;
        openDropdown[key] = !this.state.openDropdown[key];
        this.setState({
            openDropdown
        });
    };

    onInputChange = (key: string, value: string) => {
        switch (key) {
            case 'firstName':
            case 'lastName':
            case 'nationalIdNumber':
            case 'otherNationalIdNumber':
            case 'dateOfBirth':
                this.populatePersonalDetailsChange(key, value);
                break;
            case 'email':
                this.populateContactDetailsChange(key, value);
                break;
            case 'addressNumber':
            case 'addressLine1':
            case 'addressLine2':
            case 'city':
            case 'state':
            case 'postCode':
                this.populateAddressDetailsChange(key, value);
                break;
            case 'applicationRef':
                this.populateContactedDetailsChange(key, value);
                break;
            case 'contactName':
                this.populateEmergencyContactChange(key, value);
                break;
            case 'companyName':
            case 'occupation':
            case 'referredByMemberName':
            case 'referredByMembershipNumber':
            case 'otherCommissionNote':
            case 'generalNote':
                this.populateOtherFieldsChange(key, value);
                break;
            default:
                break;
        }
        this.hasChangeControlValue();
    };

    onSelectOptionChange = (key: string, value) => {
        switch (key) {
            case 'title':
            case 'gender':
            case 'preferedLanguage':
                this.populatePersonalDetailsChange(key, value);
                break;
            case 'overrideEmailOption':
                this.populateContactDetailsChange(key, value);
                break;
            case 'country':
                this.populateAddressDetailsChange(key, value);
                break;
            case 'marketingSource':
                this.populateContactedDetailsChange(key, value);
                break;
            case 'aiaVitalityCategory':
            case 'recurringCardType':
                this.populateOtherFieldsChange(key, value);
                break;
            default:
                break;
        }
        this.hasChangeControlValue();
    };

    onCheckboxChange = (key: string, checked: boolean, value?) => {
        switch (key) {
            case 'isParentalConsentProvided':
                this.populatePersonalDetailsChange(key, checked);
                break;
            case 'goneAway':
                this.populateAddressDetailsChange(key, checked);
                break;
            case 'memberHappyToBeContact':
            case 'thirdPartyContact':
                this.populateContactedDetailsChange(key, checked);
                break;
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS:
                this.populatePreferredContactMethods(key, checked, value || '');
                break;
            case 'answeredQuestion':
            case 'lastQuestion':
                this.populateHealthQuestionsChange(key, checked, value || '');
                break;
            default:
                break;
        }
        this.hasChangeControlValue();
    };

    onPhoneNumberChange = (
        key: string,
        isValid: boolean,
        phoneNumber: string,
        countryCode: string
    ) => {
        switch (key) {
            case 'emergencyPhone':
                this.populateEmergencyPhoneNumberChange(
                    key,
                    isValid,
                    phoneNumber,
                    countryCode
                );
                break;
            case 'mobilePhone':
            case 'homePhone':
            case 'workPhone':
                this.populateContactPhoneNumberChange(
                    key,
                    isValid,
                    phoneNumber,
                    countryCode
                );
                break;
            default:
                break;
        }
        this.hasChangeControlValue();
    };

    onDuplicateMemberSectionClose = () => {
        this.setState({
            duplicateMembers: [],
            duplicateMemberProcessed: false
        });
    };

    onDuplicateMemberSelected = (memberNo: string) => {
    //this.goToNextStep();
    };

    findDuplicateMembers = (
        lastName: string,
        birthDate: string,
        postCode: string
    ) => {
        this.setState({
            isLoading: true
        });

        const dateOfBirth = moment(birthDate);
        const {
            membershipService,
            newMembership: { selectedSubTenant },
            isEdit
        } = this.props;
        const rightName = !isEdit
            ? Route.AddNewMemberRoute.rightName
            : Route.ChangeMembershipRoute.rightName;
        membershipService
            .getDuplicateMembers(
                selectedSubTenant.id,
                lastName,
                dateOfBirth.isValid()
                    ? dateOfBirth.format(GENERAL_DATABASE_DATE_FORMAT)
                    : '',
                postCode,
                rightName
            )
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.setState({
                        isLoading: false,
                        duplicateMembers: res.data,
                        duplicateMemberProcessed: true
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.goToNextStep();
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
                this.goToNextStep();
            });
    };

    //#endregion Handle events

    //#region Personal details
    populatePersonalDetailsFromReduxStore = (): PersonalDetailsModel => {
        const {
            personalDetails,
            membershipSearchFilter
        } = this.props.newMembership;
        let {
            title = new LookupModel(),
            gender = new LookupModel(),
            firstName = '',
            lastName = '',
            nationalIdNumber = '',
            otherNationalIdNumber = '',
            dateOfBirth = '',
            preferedLanguage = new LookupModel(),
            isParentalConsentProvided = false
        } = personalDetails || {};

        if (!dateOfBirth) dateOfBirth = membershipSearchFilter.dateOfBirth || '';

        return {
            title,
            gender,
            firstName,
            lastName,
            nationalIdNumber,
            otherNationalIdNumber,
            dateOfBirth,
            preferedLanguage,
            isParentalConsentProvided
        };
    };

    getPersonalFieldConfiguration = (
        fieldConfiguration
    ): PersonalDetailFieldsModel => {
        const dateOfBirthConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_3
        );
        const titleConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_TITLE
        );
        const genderConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_GENDER
        );
        const firstNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_FIRST_NAME
        );
        const lastNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_LAST_NAME
        );
        const nationalConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_NATIONAL
        );
        const nationalIdConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_NATIONAL_ID
        );
        const otherNationalIdConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OTHER_NATIONAL_ID
        );
        const parentalConsentConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PARENTAL_CONSENT_PROVIDED
        );
        const preferedLanguageConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES
        );

        return {
            titleConfig,
            genderConfig,
            firstNameConfig,
            lastNameConfig,
            dateOfBirthConfig,
            nationalConfig,
            nationalIdConfig,
            otherNationalIdConfig,
            preferedLanguageConfig,
            parentalConsentConfig
        };
    };

    populatePersonalDetailsChange(key: string, value) {
        let {
            firstNameConfig,
            lastNameConfig,
            nationalIdConfig,
            otherNationalIdConfig
        } = this.state.personalDetailsFieldConfigs;
        let isMatchRegex = true;
        let errorMessage = '';

        const { t } = this.props;

        switch (key) {
            case 'firstName':
                isMatchRegex = Utils.isInputMatchRegex(firstNameConfig.regex, value);
                if (isFieldRequired(firstNameConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'lastName':
                isMatchRegex = Utils.isInputMatchRegex(lastNameConfig.regex, value);
                if (isFieldRequired(lastNameConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'nationalIdNumber':
                isMatchRegex = Utils.isInputMatchRegex(nationalIdConfig.regex, value);
                if (isFieldRequired(nationalIdConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'otherNationalIdNumber':
                isMatchRegex = Utils.isInputMatchRegex(
                    otherNationalIdConfig.regex,
                    value
                );
                if (isFieldRequired(otherNationalIdConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'dateOfBirth':
                value = value
                    ? new Date(
                        Utils.DisplayDateFormat(value, GENERAL_DATABASE_DATE_FORMAT)
                    )
                    : null;
                break;

            default:
                break;
        }

        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                personalDetails: {
                    ...prevState.personalDetails,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    }

    isPersonalDetailsValid() {
        const { membershipConfiguration } = this.props.newMembership;
        const {
            titleConfig,
            genderConfig,
            firstNameConfig,
            lastNameConfig,
            nationalConfig,
            dateOfBirthConfig,
            preferedLanguageConfig
        } = this.state.personalDetailsFieldConfigs;

        const { t } = this.props;

        let errors: any = {};
        const { personalDetails } = this.state;
        const isParentalConsentRequired =
			moment(personalDetails.dateOfBirth).isValid() &&
			moment().diff(personalDetails.dateOfBirth, 'years') < 18;

        if (isFieldRequired(titleConfig) && !personalDetails.title.value) {
            errors = { ...errors, title: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(genderConfig) && !personalDetails.gender.value) {
            errors = { ...errors, gender: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(firstNameConfig) && !personalDetails.firstName) {
            errors = { ...errors, firstName: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(lastNameConfig) && !personalDetails.lastName) {
            errors = { ...errors, lastName: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(preferedLanguageConfig) &&
      		!personalDetails.preferedLanguage.value
        ) {
            errors = { ...errors, preferedLanguage: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(nationalConfig) &&
			!personalDetails.nationalIdNumber &&
			!personalDetails.otherNationalIdNumber
        ) {
            errors = {
                ...errors,
                nationalIdNumber:
          'PAGE.MEMBERSHIPS.CREATE.STEP_3.NATIONAL_ID_NUMBER_REQUIRED'
            };
        }

        if (isFieldRequired(dateOfBirthConfig) && !personalDetails.dateOfBirth) {
            errors = { ...errors, dateOfBirth: 'COMMON.FIELD_REQUIRED' };
        } else if (
            personalDetails.dateOfBirth &&
			!Utils.IsValidDate(
			    personalDetails.dateOfBirth,
			    membershipConfiguration.newMemberDOBFormat
			)
        ) {
            errors = {
                ...errors,
                dateOfBirth: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.DATE_OF_BIRTH_INVALID')
            };
        }

        if (
            isParentalConsentRequired &&
      		!personalDetails.isParentalConsentProvided
        ) {
            errors = {
                ...errors,
                isParentalConsentProvided:
          			'PAGE.MEMBERSHIPS.CREATE.STEP_3.PARENTAL_REQUIRE_MEMBER_UNDER_18_YEAR_OLD'
            };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    }
    //#endregion

    //#region Contact Details
    getContactFieldConfiguration = (
        fieldConfiguration
    ): ContactDetailsFieldsModel => {
        const telephoneNumberConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_TELEPHONE_NUMBER
        );
        const mobilePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MOBILE_TELEPHONE
        );
        const homePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_HOME_TELEPHONE
        );
        const workPhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_WORK_TELEPHONE
        );
        const emailConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_EMAIL_ADDRESS
        );
        const overrideEmailConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OVERRIDE_EMAIL
        );

        return {
            telephoneNumberConfig,
            mobilePhoneConfig,
            homePhoneConfig,
            workPhoneConfig,
            emailConfig,
            overrideEmailConfig
        };
    };

    populateContactDetailsFromReduxStore = (): ContactDetailsModel => {
        let {
            personalDetails: { contactDetails }
        } = this.props.newMembership;

        return contactDetails || new ContactDetailsModel();
    };

    populateContactDetailsChange(key: string, value) {
        const { emailConfig } = this.state.contactDetailsFieldConfigs;
        const { preferredContactMethod } = this.state.contactedDetails;
        let newContacts = preferredContactMethod;
        switch (key) {
            case 'email':
                let errorMessage = '';
                let isEmailValid = true;
                if (isFieldRequired(emailConfig) && !value)
                    errorMessage = 'COMMON.FIELD_REQUIRED';
                else {
                    isEmailValid = Utils.checkInvalidEmailField(value);
                    if (!isEmailValid)
                        errorMessage =
              				'PAGE.MEMBERSHIPS.CREATE.STEP_3.INVALID_EMAIL_ADDRESS';
                }
                if (!value)
                    newContacts = preferredContactMethod.filter(
                        cm => cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
                    );
                this.setState(prevState => {
                    return {
                        contactDetails: {
                            ...prevState.contactDetails,
                            email: value,
                            isEmailValid
                        },
                        contactedDetails: {
                            ...prevState.contactedDetails,
                            preferredContactMethod: newContacts
                        },
                        errors: {
                            ...prevState.errors,
                            email: errorMessage
                        }
                    };
                });

                break;
            case 'overrideEmailOption':
                let { email } = this.state.contactDetails;
                let { email: emailError } = this.state.errors;
                if (value.code !== 'EM') {
                    email = '';
                    emailError = '';
                    newContacts = preferredContactMethod.filter(
                        cm => cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
                    );
                }
                this.setState(prevState => {
                    return {
                        contactDetails: {
                            ...prevState.contactDetails,
                            overrideEmailOption: value,
                            email
                        },
                        contactedDetails: {
                            ...prevState.contactedDetails,
                            preferredContactMethod: newContacts
                        },
                        errors: {
                            ...prevState.errors,
                            email: emailError
                        }
                    };
                });
                break;
            default:
                break;
        }
    }

    populateContactPhoneNumberChange = (
        key: string,
        isValid: boolean,
        phoneNumber: string,
        countryCode: string
    ) => {
        const { preferredContactMethod } = this.state.contactedDetails;
        let error = {
            mobilePhone: '',
            homePhone: '',
            workPhone: ''
        };

        let newContacts = preferredContactMethod;
        switch (key) {
            case 'mobilePhone':
                if (!isValid && phoneNumber) {
                    error.mobilePhone =
						'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID';
                } else if (!phoneNumber) {
                    newContacts = preferredContactMethod.filter(
                        cm =>
                            cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS &&
              cm.key !==
                FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
                    );
                }
                break;
            case 'homePhone':
                if (!isValid && phoneNumber) {
                    error.homePhone =
            			'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID';
                } else if (!phoneNumber) {
                    newContacts = preferredContactMethod.filter(
                        cm =>
                            cm.key !==
              				FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
                    );
                }
                break;
            case 'workPhone':
                if (!isValid && phoneNumber) {
                    error.workPhone =
            			'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID';
                } else if (!phoneNumber) {
                    newContacts = preferredContactMethod.filter(
                        cm =>
                            cm.key !==
              				FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
                    );
                }
                break;
            default:
                break;
        }

        this.setState(prevState => {
            return {
                contactDetails: {
                    ...prevState.contactDetails,
                    [key]: {
                        isValid,
                        phoneNumber,
                        countryCode
                    }
                },
                contactedDetails: {
                    ...prevState.contactedDetails,
                    preferredContactMethod: newContacts
                },
                errors: {
                    ...prevState.errors,
                    ...error
                }
            };
        });
    };

    isContactDetailsValid = () => {
        const {
            email,
            mobilePhone,
            homePhone,
            workPhone,
            overrideEmailOption
        } = this.state.contactDetails;

        const {
            telephoneNumberConfig,
            emailConfig
        } = this.state.contactDetailsFieldConfigs;

        let errors: any = {};

        if (
            isFieldRequired(emailConfig) &&
			overrideEmailOption.code === 'EM' &&
			!email
        ) {
            errors = { ...errors, email: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(telephoneNumberConfig) &&
			!mobilePhone.phoneNumber &&
			!homePhone.phoneNumber &&
			!workPhone.phoneNumber
        ) {
            errors = {
                ...errors,
                mobilePhone: 'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED',
                homePhone: 'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED',
                workPhone: 'PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED'
            };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };
    //#endregion

    //#region Address details
    populateAddressDetailsFromReduxStore = (): AddressDetailsModel => {
        let {
            personalDetails: { addressDetails }
        } = this.props.newMembership;
        return addressDetails || new AddressDetailsModel();
    };

    populateAddressDetailsChange(key: string, value) {
        let {
            addressNumberConfig,
            addressLine1Config,
            addressLine2Config,
            cityConfig,
            stateConfig,
            postCodeConfig
        } = this.state.addressDetailsFieldConfigs;
        let isMatchRegex = true;
        let errorMessage = '';

        const { t } = this.props;

        switch (key) {
            case 'addressNumber':
                isMatchRegex = Utils.isInputMatchRegex(
                    addressNumberConfig.regex,
                    value
                );
                if (isFieldRequired(addressNumberConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'addressLine1':
                isMatchRegex = Utils.isInputMatchRegex(addressLine1Config.regex, value);
                if (isFieldRequired(addressLine1Config) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'addressLine2':
                isMatchRegex = Utils.isInputMatchRegex(addressLine2Config.regex, value);
                if (isFieldRequired(addressLine2Config) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'city':
                isMatchRegex = Utils.isInputMatchRegex(cityConfig.regex, value);
                if (isFieldRequired(cityConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'state':
                isMatchRegex = Utils.isInputMatchRegex(stateConfig.regex, value);
                if (isFieldRequired(stateConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'postCode':
                isMatchRegex = Utils.isInputMatchRegex(postCodeConfig.regex, value);
                if (isFieldRequired(postCodeConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            default:
                break;
        }

        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                addressDetails: {
                    ...prevState.addressDetails,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    }

    getAddressFieldConfiguration = (
        fieldConfiguration
    ): AddressDetailsFieldsModel => {
        const addressNumberConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_NUMBER
        );
        const addressLine1Config = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_LINE_1
        );
        const addressLine2Config = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_LINE_2
        );
        const cityConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_CITY
        );
        const stateConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_STATE
        );
        const countryConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_COUNTRY
        );
        const postCodeConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_ADDRESS_POSTCODE
        );
        const goneAwayConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_GONE_AWAY
        );
        return {
            addressNumberConfig,
            addressLine1Config,
            addressLine2Config,
            cityConfig,
            stateConfig,
            countryConfig,
            postCodeConfig,
            goneAwayConfig
        };
    };

    isAddressDetailsValid = () => {
        const { addressDetails } = this.state;
        const {
            addressNumberConfig,
            addressLine1Config,
            addressLine2Config,
            cityConfig,
            stateConfig,
            countryConfig,
            postCodeConfig,
            goneAwayConfig
        } = this.state.addressDetailsFieldConfigs;

        let errors: any = {};
        if (isFieldRequired(addressNumberConfig) && !addressDetails.addressNumber) {
            errors = { ...errors, addressNumber: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(addressLine1Config) && !addressDetails.addressLine1) {
            errors = { ...errors, addressLine1: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(addressLine2Config) && !addressDetails.addressLine2) {
            errors = { ...errors, addressLine2: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(cityConfig) && !addressDetails.city) {
            errors = { ...errors, city: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(stateConfig) && !addressDetails.state) {
            errors = { ...errors, state: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(countryConfig) && !addressDetails.country.value) {
            errors = { ...errors, country: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(postCodeConfig) && !addressDetails.postCode) {
            errors = { ...errors, postCode: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(goneAwayConfig) && !addressDetails.goneAway) {
            errors = { ...errors, goneAway: 'COMMON.FIELD_REQUIRED' };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };
    //#endregion

    //#region Contacted details
    getContactedFieldConfiguration = (
        fieldConfiguration
    ): ContactedDetailsFieldsModel => {
        const applicationRefConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBERSHIP_APPLICATION_REF
        );
        const contactMethodConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED
        );
        const thirdPartyContactConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED_BY_THIRD_PARTY
        );
        const preferredContactMethodConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD
        );
        const postConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST
        );
        const homePhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
        );
        const mobileConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
        );
        const workPhoneConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
        );
        const emailConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
        );
        const smsConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS
        );
        const marketingSourceConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_MARKETING_SOURCE
        );

        return {
            applicationRefConfig,
            memberHappyToBeContactConfig: contactMethodConfig,
            thirdPartyContactConfig,
            preferredContactMethodConfig,
            postConfig,
            homePhoneConfig,
            mobileConfig,
            workPhoneConfig,
            emailConfig,
            smsConfig,
            marketingSourceConfig
        };
    };

    populateContactedDetailsFromReduxStore = (): ContactedDetailsModel => {
        const {
            personalDetails: { contactedDetails }
        } = this.props.newMembership;

        return contactedDetails || new ContactedDetailsModel();
    };

    populateContactedDetailsChange = (key: string, value) => {
        const { t } = this.props;
        let { applicationRefConfig } = this.state.contactedDetailsFieldConfigs;
        let isMatchRegex = true;
        let errorMessage = '';
        switch (key) {
            case 'applicationRef':
                isMatchRegex = Utils.isInputMatchRegex(
                    applicationRefConfig.regex,
                    value
                );
                if (isFieldRequired(applicationRefConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;

            default:
                break;
        }

        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                contactedDetails: {
                    ...prevState.contactedDetails,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    };

    populatePreferredContactMethods = (key: string, checked: boolean, value) => {
        let {
            contactDetails,
            contactedDetails,
            contactedDetailsFieldConfigs: { preferredContactMethodConfig }
        } = this.state;

        let isContactMethodProvided = false;
        let errorMessage = '';

        const { t } = this.props;

        switch (key) {
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:
                isContactMethodProvided = true;
                break;
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL:
                isContactMethodProvided =
          			contactDetails.isEmailValid && contactDetails.email !== '';
                if (!isContactMethodProvided)
                    errorMessage = t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
                        { contactMethod: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL') }
                    );
                break;
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE:
                isContactMethodProvided =
					contactDetails.homePhone.isValid &&
					contactDetails.homePhone.phoneNumber !== '';
                if (!isContactMethodProvided)
                    errorMessage = t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
                        {
                            contactMethod: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE')
                        }
                    );
                break;
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE:
                isContactMethodProvided =
					contactDetails.workPhone.isValid &&
					contactDetails.workPhone.phoneNumber !== '';
                if (!isContactMethodProvided)
                    errorMessage = t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
                        {
                            contactMethod: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE')
                        }
                    );
                break;
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE:
                isContactMethodProvided =
					contactDetails.mobilePhone.isValid &&
					contactDetails.mobilePhone.phoneNumber !== '';
                if (!isContactMethodProvided)
                    errorMessage = t(
                        'PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED',
                        {
                            contactMethod: t(
                                'PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE'
                            )
                        }
                    );
                break;
            default:
                break;
        }

        if (isContactMethodProvided) {
            let prevContacts = contactedDetails.preferredContactMethod;
            let newContacts = checked
                ? prevContacts.concat(value)
                : prevContacts.filter(cm => cm.id !== value.id);
            errorMessage =
				isFieldRequired(preferredContactMethodConfig) &&
				newContacts.length === 0
				    ? 'COMMON.FIELD_REQUIRED'
				    : '';
            this.setState(prevState => {
                return {
                    contactedDetails: {
                        ...prevState.contactedDetails,
                        preferredContactMethod: newContacts
                    },
                    errors: {
                        ...prevState.errors,
                        preferredContactMethod: errorMessage
                    }
                };
            });
        } else {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        preferredContactMethod: errorMessage
                    }
                };
            });
        }
    };

    isContactedDetailsValid = () => {
        let {
            applicationRef,
            memberHappyToBeContact,
            thirdPartyContact,
            preferredContactMethod,
            marketingSource
        } = this.state.contactedDetails;

        let {
            applicationRefConfig,
            memberHappyToBeContactConfig,
            thirdPartyContactConfig,
            preferredContactMethodConfig,
            marketingSourceConfig
        } = this.state.contactedDetailsFieldConfigs;

        let errors: any = {};
        if (isFieldRequired(applicationRefConfig) && !applicationRef) {
            errors = { ...errors, applicationRef: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(memberHappyToBeContactConfig) &&
      		!memberHappyToBeContact
        ) {
            errors = { ...errors, memberHappyToBeContact: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(thirdPartyContactConfig) && !thirdPartyContact) {
            errors = { ...errors, thirdPartyContact: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(marketingSourceConfig) && !marketingSource.value) {
            errors = { ...errors, marketingSource: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(preferredContactMethodConfig) &&
      		preferredContactMethod.length === 0
        ) {
            errors = { ...errors, preferredContactMethod: 'COMMON.FIELD_REQUIRED' };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };
    //#endregion

    //#region Emergency contact
    getEmergencyContactFieldConfiguration = (
        fieldConfiguration
    ): EmergencyContactFieldsModel => {
        const contactNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_NAME
        );
        const contactNumberConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER
        );

        return {
            contactNameConfig,
            contactNumberConfig
        };
    };

    populateEmergencyContactFromReduxStore = (): EmergencyContactModel => {
        const {
            personalDetails: { emergencyContact }
        } = this.props.newMembership;

        return emergencyContact || new EmergencyContactModel();
    };

    populateEmergencyContactChange = (key: string, value) => {
        let { contactNameConfig } = this.state.emergencyContactFieldsConfigs;
        let isMatchRegex = true;
        let errorMessage = '';
        const { t } = this.props;

        switch (key) {
            case 'contactName':
                isMatchRegex = Utils.isInputMatchRegex(contactNameConfig.regex, value);
                if (isFieldRequired(contactNameConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;

            default:
                break;
        }
        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                emergencyContact: {
                    ...prevState.emergencyContact,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    };

    populateEmergencyPhoneNumberChange = (
        key: string,
        isValid: boolean,
        phoneNumber: string,
        countryCode: string
    ) => {
        const { t } = this.props;
        let { contactNumberConfig } = this.state.emergencyContactFieldsConfigs;
        let errorMessage = '';
        if (isFieldRequired(contactNumberConfig) && !phoneNumber) {
            errorMessage = t('COMMON.FIELD_REQUIRED');
        } else if (!isValid) {
            errorMessage = t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID');
        }

        this.setState(prevState => {
            return {
                emergencyContact: {
                    ...prevState.emergencyContact,
                    contactNumber: {
                        isValid,
                        countryCode,
                        phoneNumber
                    }
                },
                errors: {
                    ...prevState.errors,
                    emergencyPhone: errorMessage
                }
            };
        });
    };

    isEmergencyContactValid = () => {
        let { contactName, contactNumber } = this.state.emergencyContact;

        const { t } = this.props;

        let {
            contactNameConfig,
            contactNumberConfig
        } = this.state.emergencyContactFieldsConfigs;

        let errors: any = {};
        if (isFieldRequired(contactNameConfig) && !contactName) {
            errors = { ...errors, contactName: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(contactNumberConfig) && !contactNumber.phoneNumber) {
            errors = { ...errors, emergencyPhone: 'COMMON.FIELD_REQUIRED' };
        } else if (!contactNumber.isValid && contactNumber.phoneNumber) {
            errors = {
                ...errors,
                emergencyPhone: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID')
            };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };
    //#endregion

    //#region Health question
    getHealthQuestions = () => {
    //todo: get data from Question module - TBD
        let questions = [
            {
                "id": "1",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_1')
            },
            {
                "id": "2",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_2')
            },
            {
                "id": "3",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_3')
            },
            {
                "id": "4",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_4')
            },
            {
                "id": "5",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_5')
            },
            {
                "id": "6",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_6')
            },
            {
                "id": "7",
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_7')
            },
            {   "id": "8", 
                "name": t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HEALTH_QUESTIONNAIRE_8')
            }
        ];

        return questions;
    };

    populateHealthQuestionsFromReduxStore = (): HealthQuestionModel[] => {
        const {
            personalDetails: { healthQuestions }
        } = this.props.newMembership;

        return healthQuestions || [];
    };

    populateHealthQuestionsChange = (
        key: string,
        checked: boolean,
        question?
    ) => {
        let { healthQuestions } = this.state;
        let isLastQuestion = false;
        let errors: any = {};
        const { t } = this.props;

        switch (key) {
            case 'answeredQuestion':
                isLastQuestion = false;
                break;
            case 'lastQuestion':
                isLastQuestion = true;
                errors = { lastQuestion: checked ? '' : t('COMMON.FIELD_REQUIRED') };
                break;
            default:
                break;
        }

        let healthQuestion: HealthQuestionModel = {
            ...question,
            isLastQuestion
        };

        let newAnsweredQuestions = checked
            ? healthQuestions.concat(healthQuestion)
            : healthQuestions.filter(aq => aq.id !== healthQuestion.id);
        this.setState(prevState => {
            return {
                healthQuestions: newAnsweredQuestions,
                errors: {
                    ...prevState.errors,
                    ...errors
                }
            };
        });
    };

    isHealthQuestionsValid = () => {
        let { healthQuestions } = this.state;
        let isValid = healthQuestions.some(q => q.isLastQuestion == true);

        let errors: any = {};
        if (!isValid) {
            errors = { ...errors, lastQuestion: 'COMMON.FIELD_REQUIRED' };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };
    //#endregion

    //#region Other fields
    getOtherFieldsFieldConfiguration = (
        fieldConfiguration
    ): OtherFieldsFieldsModel => {
        const companyNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_COMPANY_NAME
        );
        const occupationConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OCCUPATION
        );
        const referredByMemberNameConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_REFERRED_BY_NAME
        );
        const referredByMembershipNumberConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER
        );
        const otherCommissionNoteConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_OTHER_COMMISSION_NOTE
        );
        const generalNoteConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_GENERAL_NOTE
        );
        const aiaVitalityCategoryConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_AIA_VITALITY_CATEGORY
        );
        const recurringCardTypeConfig = getFieldConfig(
            fieldConfiguration,
            FieldKeys.ADDMEMBER_RECURRING_CARD_TYPE
        );

        return {
            companyNameConfig,
            occupationConfig,
            referredByMemberNameConfig,
            referredByMembershipNumberConfig,
            otherCommissionNoteConfig,
            generalNoteConfig,
            aiaVitalityCategoryConfig,
            recurringCardTypeConfig
        };
    };

    populateOtherFieldsFromReduxStore = (): OtherFieldsModel => {
        const {
            personalDetails: { otherFields }
        } = this.props.newMembership;

        return otherFields || new OtherFieldsModel();
    };

    populateOtherFieldsChange = (key: string, value: any) => {
        const {
            companyNameConfig,
            occupationConfig,
            referredByMemberNameConfig,
            referredByMembershipNumberConfig,
            otherCommissionNoteConfig,
            generalNoteConfig
        } = this.state.otherFieldsFieldsConfigs;

        const { t } = this.props;

        let isMatchRegex = true;
        let errorMessage = '';

        switch (key) {
            case 'companyName':
                isMatchRegex = Utils.isInputMatchRegex(companyNameConfig.regex, value);
                if (isFieldRequired(companyNameConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'occupation':
                isMatchRegex = Utils.isInputMatchRegex(occupationConfig.regex, value);
                if (isFieldRequired(occupationConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'referredByMemberName':
                isMatchRegex = Utils.isInputMatchRegex(
                    referredByMemberNameConfig.regex,
                    value
                );
                if (isFieldRequired(referredByMemberNameConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'referredByMembershipNumber':
                isMatchRegex = Utils.isInputMatchRegex(
                    referredByMembershipNumberConfig.regex,
                    value
                );
                if (isFieldRequired(referredByMembershipNumberConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'otherCommissionNote':
                isMatchRegex = Utils.isInputMatchRegex(
                    otherCommissionNoteConfig.regex,
                    value
                );
                if (isFieldRequired(otherCommissionNoteConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;
            case 'generalNote':
                isMatchRegex = Utils.isInputMatchRegex(generalNoteConfig.regex, value);
                if (isFieldRequired(generalNoteConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;

            default:
                break;
        }

        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                otherFields: {
                    ...prevState.otherFields,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    };

    isOtherFieldsValid = () => {
        const {
            companyName,
            occupation,
            referredByMemberName,
            referredByMembershipNumber,
            otherCommissionNote,
            generalNote,
            aiaVitalityCategory,
            recurringCardType
        } = this.state.otherFields;

        const {
            companyNameConfig,
            occupationConfig,
            referredByMemberNameConfig,
            referredByMembershipNumberConfig,
            otherCommissionNoteConfig,
            generalNoteConfig,
            aiaVitalityCategoryConfig,
            recurringCardTypeConfig
        } = this.state.otherFieldsFieldsConfigs;

        let errors: any = {};
        if (isFieldRequired(companyNameConfig) && !companyName) {
            errors = { ...errors, companyName: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(occupationConfig) && !occupation) {
            errors = { ...errors, occupation: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(referredByMemberNameConfig) && !referredByMemberName) {
            errors = { ...errors, referredByMemberName: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(referredByMembershipNumberConfig) &&
      		!referredByMembershipNumber
        ) {
            errors = {
                ...errors,
                referredByMembershipNumber: 'COMMON.FIELD_REQUIRED'
            };
        }

        if (isFieldRequired(otherCommissionNoteConfig) && !otherCommissionNote) {
            errors = { ...errors, otherCommissionNote: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(generalNoteConfig) && !generalNote) {
            errors = { ...errors, generalNote: 'COMMON.FIELD_REQUIRED' };
        }

        if (
            isFieldRequired(aiaVitalityCategoryConfig) &&
      		!aiaVitalityCategory.value
        ) {
            errors = { ...errors, aiaVitalityCategory: 'COMMON.FIELD_REQUIRED' };
        }

        if (isFieldRequired(recurringCardTypeConfig) && !recurringCardType.value) {
            errors = { ...errors, recurringCardType: 'COMMON.FIELD_REQUIRED' };
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };
    //#endregion

    isReadyToNextStep = () => {
        let isPersonalDetailsValid = this.isPersonalDetailsValid();
        let isContactDetailsValid = this.isContactDetailsValid();
        let isAddressDetailsValid = this.isAddressDetailsValid();
        let isContactedDetailsValid = this.isContactedDetailsValid();
        let isEmergencyContactValid = this.isEmergencyContactValid();
        let isHealthQuestionsValid = this.isHealthQuestionsValid();
        let isOtherFieldsValid = this.isOtherFieldsValid();

        this.setState({
            openDropdown: {
                personalDetails: !isPersonalDetailsValid,
                contactDetails: !isContactDetailsValid,
                addressDetails: !isAddressDetailsValid,
                contactedDetails: !isContactedDetailsValid,
                emergencyContact: !isEmergencyContactValid,
                healthQuestion: !isHealthQuestionsValid,
                otherFields: !isOtherFieldsValid
            }
        });

        return (
            isPersonalDetailsValid &&
			isContactDetailsValid &&
			isAddressDetailsValid &&
			isContactedDetailsValid &&
			isEmergencyContactValid &&
			isHealthQuestionsValid &&
			isOtherFieldsValid
        );
    };

    hasChangeControlValue() {
        const {
            membershipConfiguration: { stepLatest }
        } = this.props.newMembership;
        if (stepLatest < 4) return;
        this.setState({
            hasChanged: true
        });
    }

    handleSaveAndLeave(stepIndex, isSave = false) {
        const { membershipConfiguration } = this.props.newMembership;
        if (isSave) {
            this.storeDataToRedux();
        }
        membershipConfiguration.stepIndex = stepIndex;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        observer.publish('closeDialogEvent', true);
    }

    goToStep(stepIndex) {
        const { hasChanged } = this.state;
        const {
            membershipConfiguration,
            membershipConfiguration: { stepLatest }
        } = this.props.newMembership;
        const { t } = this.props;

        if (hasChanged && stepLatest > 3) {
            if (!this.isReadyToNextStep()) return;
            const content = (
                <React.Fragment>
                    <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page" />
                    <p className="content-center add-member-page">
                        {t('MSG.UNSAVED_CHANGES')}
                    </p>
                    <div className="modalSaas__row--btn">
                        <button
                            className="btn btn-primary text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex, true)}
                        >
                            {t('BUTTON.SAVE_AND_LEAVE')}
                        </button>
                        <button
                            className="btn btn-dark text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex)}
                        >
                            {t('BUTTON.LEAVE_AND_NOT_SAVE')}
                        </button>
                    </div>
                </React.Fragment>
            );
            observer.publish('openDialogEvent', {
                content: content
            });
        } else {
            this.storeDataToRedux();
            membershipConfiguration.stepIndex = stepIndex;
            store.dispatch(
                MembershipActions.setConfiguration(membershipConfiguration)
            );
        }
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ newMembership }) => {
    return { newMembership };
};

export default connect(mapStateToProps)(
    withTranslation()(SelectPersonalDetails)
);
