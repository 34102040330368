import React from 'react';
import ListComponent from 'list-component';
import {
    PRESET_ENDPOINT_URL,
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ThisInterface from './interface';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

import { t } from 'util/I18nMessages';

const template = (_this: ThisInterface) => {
    const { auth: { user } } = _this.props;
    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT
    };

    const errorHandle = (err) => {
        if (err && err.status === 401) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleSessionExpired();
        }
    }

    return (
        <>
            <div className="list-page plan-list-view-page">
                <div className="list-page-title">
                    <p className="title-content title-content-list">
                        {t('PRODUCT_ITEM.LIST')}
                    </p>
                </div>
                <div className="box-btn-filter ml-auto d-flex">
                    <span className="plan-hint">
                        {t('PRODUCT_ITEM.ITEMS_HINT')}
                    </span>
                </div>
            </div>
            <ListComponent
                oidcAuth={user}
                version={_this.state.version}
                rightName="view_product"     
                config={{
                    presetUrl: PRESET_ENDPOINT_URL,
                    timeFormat: GENERAL_DATE_FORMAT,
                    langCode: i18n.language,
                    tableHeight: `${_this.state.tableHeight}px`,
                    tableWidth: '100%'
                }
                }
                variable={variableConfig}
                actionEvent={_this.handleActionEvent}
                errorEvent={errorHandle}
            />
        </>
    );
};

export default template;
