import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { submitModal, errorModal } from 'components/CommonModal';
import CommonService from 'services/common.service';
import {
    API_GET_CANCELLATION_REASONS,
    API_GET_CANCELLATION_DATE,
    GENERAL_DATABASE_DATE_FORMAT,
    API_GET_CANCELLATION_PAYMENT_SUMMARY,
    API_POST_CANCELLATION,
    API_REMOVE_CANCEL_MEMBERSHIP,
    API_SAVE_REMOVE_CANCEL_MEMBERSHIP,
    API_GET_CANCEL_CODE,
} from 'constants/Constants';
import { CancelMembershipRoute, RemoveCancelMembershipRoute } from 'pages/RouteLoader';
import { DisplayDateFormat } from 'util/ControlUtils'
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';
import { ThisProps, ThisState, Steps } from './interface';
import { template } from './template';

const DEFAULT_MONTHS_OF_PAYMENT = 6;

class CancelMembership extends React.Component<ThisProps, ThisState> {
    commonService: CommonService
    
    constructor(props: ThisProps) {
        super(props);
        const removeEvent = !_.isEmpty(props.memberData.futureEvents) ? props.memberData.futureEvents.find(item => item.type === 'CA') : null
        this.state = {
            isLoading: true,
            disableNext: true,
            step: removeEvent ? Steps.Summary : Steps.Form,
            fields: {
                requestDate: new Date(),
                cancellationDate: '',
                reasonId: '',
                memberRequested: true,
                overrideMoneyBackPeriod: false,
                overrideMinimumContractPeriod: false,
                overrideEndBillingPeriod: false,
                overrideCancellationNoticePeriod: false,
                saveCancelCodeId: ''
            },
            monthsOfPaymentsProjected: DEFAULT_MONTHS_OF_PAYMENT,
            allowedCancellationDates: [],
            payments: [],
            communicationEmail: {
                email: props.memberData.email,
                error: false,
                disabledFinish: false
            },
            cancellationReasons: [],
            isRemoveCancel: !!removeEvent,
            saveCancelCodes: []
        };
        this.commonService = new CommonService();
    }

    componentDidMount() {
        this.getData();
        observer.subscribe('communicationEmailChanged', data => {
            if (data) {
                this.setState({
                    communicationEmail: {
                        email: data.email,
                        error: data.error,
                        disabledFinish: data.email !== '' && data.error
                    }
                });
            }
        });
    }
	
    componentWillUnmount() {
        observer.unsubscribe('communicationEmailChanged');
    }

    getData = () => {
        const { 
            memberData: { futureEvents },
            match: { params }
        } = this.props;
        const {
            fields: {
                requestDate,
                overrideMoneyBackPeriod,
                overrideMinimumContractPeriod,
                overrideEndBillingPeriod,
                overrideCancellationNoticePeriod
            },
            isRemoveCancel
        } = this.state;

        if (params['id']) {
            const url = API_GET_CANCELLATION_DATE
                .replace('{id}', params['id'])
                .replace('{requestDate}', DisplayDateFormat(requestDate, GENERAL_DATABASE_DATE_FORMAT))
                .replace('{overrideCNB}', overrideCancellationNoticePeriod.toString())
                .replace('{overrideMBP}', overrideMoneyBackPeriod.toString())
                .replace('{overrideEBP}', overrideEndBillingPeriod.toString())
                .replace('{overrideMCP}', overrideMinimumContractPeriod.toString());

            Promise.all([
                this.commonService.fetchData(API_GET_CANCELLATION_REASONS, {
                    subTenantId: this.props.memberData.subTenantId,
                    rightName: CancelMembershipRoute.rightName
                }),
                this.commonService.fetchData(url, {
                    subTenantId: this.props.memberData.subTenantId,
                    rightName: CancelMembershipRoute.rightName
                })
            ]).then(([res, res1]) => {
                if (res && !_.isEmpty(res.data) && res1 && !_.isEmpty(res1.data)) {
                    this.setState(prevState => {
                        const removeEvent = prevState.isRemoveCancel && !_.isEmpty(futureEvents) ? futureEvents.find(item => item.type === 'CA') : null;
                        return {
                            cancellationReasons: res.data,
                            fields: {
                                ...prevState.fields,
                                reasonId: removeEvent ? removeEvent.reasonId : res.data[0].id,
                                cancellationDate: removeEvent ? removeEvent.startedDateUtc : res1.data[0].cancellationDate,
                            },
                            allowedCancellationDates: res1.data,
                            disableNext: false,
                            isLoading: false
                        }
                    }, () => {
                        isRemoveCancel ? this.getPaymentSummaryForRemoveCancel() : this.getPaymentSummaryForCancel();
                    });

                }
            }).catch(() => {
                this.setState(prevState => {
                    return {
                        cancellationReasons: [],
                        fields: {
                            ...prevState.fields,
                            reasonId: '',
                            cancellationDate: ''
                        },
                        allowedCancellationDates: [],
                        disableNext: true,
                        isLoading: false
                    }
                });
            });
        }
        
    }

    getPaymentSummaryForCancel = () => {
        const url = API_GET_CANCELLATION_PAYMENT_SUMMARY
            .replace('{id}', this.props.memberData.id)
            .replace('{cancellationDate}', this.state.fields.cancellationDate);
        this.commonService.fetchData(url, {
            rightName: CancelMembershipRoute.rightName,
            subTenantId: this.props.memberData.subTenantId
        })
            .then(res => {
                if (res && !_.isEmpty(res)) {
                    this.setState({
                        payments: res.filter(item => {
                            const type = item.type.toUpperCase();
                            return type.includes('RECURRING');
                        })
                    }, () => {
                        const { payments } = this.state;
                        this.setState({
                            monthsOfPaymentsProjected: Math.min(payments.length, DEFAULT_MONTHS_OF_PAYMENT)
                        });
                        
                    });
                } else {
                    this.setState({
                        payments: [],
                        monthsOfPaymentsProjected: DEFAULT_MONTHS_OF_PAYMENT
                    });
                }
            }).catch(() => {
                this.setState({
                    payments: [],
                    monthsOfPaymentsProjected: DEFAULT_MONTHS_OF_PAYMENT
                });
            })
    }

    getPaymentSummaryForRemoveCancel = () => {
        const month = 6;
        const {
            memberData: {
                id,
                subTenantId,
            }
        } = this.props;

        const url = API_REMOVE_CANCEL_MEMBERSHIP
            .replace('{id}', id)
            .replace('{month}', `${month}`);
            
        Promise.all([
            this.commonService.fetchData(API_GET_CANCEL_CODE, {
                subTenantId: this.props.memberData.subTenantId,
                rightName: RemoveCancelMembershipRoute.rightName
            }),
            this.commonService.fetchData(url, {
                rightName: RemoveCancelMembershipRoute.rightName,
                subTenantId: subTenantId,
            })
        ]).then(([res, res1]) => {
            if (res && !_.isEmpty(res.data)) {
                this.setState(prevState => {
                    const { fields } = prevState;
                    return {
                        ...prevState,
                        saveCancelCodes: res.data,
                        fields: {
                            ...fields,
                            saveCancelCodeId: res.data[0].id
                        },
                    }
                });
            }

            if (res1 && !_.isEmpty(res1)) {
                this.setState({
                    payments: res1,
                });
            }
        }).catch(() => {
            this.setState({
                saveCancelCodes: [],
                payments: [],
            });
        })
        
    }

    handleCommunicationEmailChanged = (value) => {
        this.setState({
            communicationEmail: {
                ...value
            }
        });
    }

    validateForm = () => {
        const isFormValid = !!(this.state.fields.reasonId && this.state.fields.cancellationDate);
        this.setState({
            disableNext: !isFormValid
        });
    }

    handleFieldChanged = (key, value) => {
        this.setState({
            fields: {
                ...this.state.fields,
                [key]: value
            }
        }, () => {
            this.validateForm()
            switch (key) {
                case 'reasonId':
                case 'cancellationDate':
                case 'saveCancelCodeId':
                    return;
                default:
                    this.getData();
                    break;
            };
        });		
    }

    moveToStep = (step: Steps) => {
        this.setState({
            step
        });
    }

    handleFinish = () => {
        const { match: { params }, t } = this.props;
        const { 
            communicationEmail: { email, error }, 
            isRemoveCancel,
            fields: {
                saveCancelCodeId,
            }
        } = this.state;
        if (params['id']) {	
            if (email === '' && error) {
                errorModal('MSG.WRONG_FORMAT_EMAIL_OR_EMPTY');
                return;
            }
            if(isRemoveCancel) {
                const data = {
                    email,
                    saveCancelCodeId,
                };
                
                submitModal('PAGE.REMOVE_CANCEL_MEMBERSHIP.CONFIRM_SAVE_MSG', () => {
                    this.commonService.putData(API_SAVE_REMOVE_CANCEL_MEMBERSHIP.replace('{id}', params['id']), data, {
                        subTenantId: this.props.memberData.subTenantId,
                        rightName: RemoveCancelMembershipRoute.rightName
                    }).then(res => {
                        if (res) {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('PAGE.REMOVE_CANCEL_MEMBERSHIP.REMOVE_CANCEL_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => this.props.onCancel());
                        }
                    }).catch(() => {
                        Swal.fire({
                            ...DefaultConstants.SWAL_COMMON_STYLE,
                            type: 'error',
                            html: t('PAGE.REMOVE_CANCEL_MEMBERSHIP.REMOVE_CANCEL_ERROR'),
                            confirmButtonText: t('COMMON.OK')
                        });
                    });
                });
            } else {
                const data = {
                    ...this.state.fields,
                    noticeEmail: email,
                    memberId: params['id']
                };

                submitModal('PAGE.CANCEL_MEMBERSHIP.CONFIRM_SAVE_MSG', () => {
                    this.commonService.postData(API_POST_CANCELLATION.replace('{id}', params['id']), data, {
                        subTenantId: this.props.memberData.subTenantId,
                        rightName: CancelMembershipRoute.rightName
                    }).then(res => {
                        if (res) {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('PAGE.CANCEL_MEMBERSHIP.CANCEL_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => this.props.onCancel());
                        }
                    });
                });
            }
        }
    }
	
    handleCancel = () => {
        const { t, onCancel } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            type: 'warning',
            html: t('MSG.UNSAVED_CHANGES'),
            showCancelButton: true,
            confirmButtonText: t('BUTTON.LEAVE_THIS_PAGE'),
            cancelButtonText: t('BUTTON.STAY_ON_THIS_PAGE')
        }).then((res) => {
            if (res.value) {
                onCancel();
            }
        });
    }

    render() {
        return template(this);
    }
}

export default withRouter(withTranslation()(CancelMembership));