import React from 'react';
import _ from 'lodash';

import CustomTable from 'components/Table';
import Loading from 'components/Loading';
import EmailSummary from 'pages/MembershipManagement/MemberSummaryPage/EmailSummary';
import {
    getFutureDateYearWithMonth,
    CommaFormatted,
    DisplayDateFormat
} from 'util/ControlUtils';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';

import ThisInterface from './interface';
import moment from 'moment';

const paymentSummaryHeader = [
    {
        key: 'startDate',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_DATE'
    },
    {
        key: 'typeName',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_TYPE',
        className: 'type-name'
    },
    {
        key: 'method',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_METHOD'
    },
    {
        key: 'status',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_STATUS',
        className: 'status-text'
    },
    {
        key: 'amount',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_AMOUNT',
        className: 'amount-number'
    }
];

const SummaryItem = ({ titleId, dataText, t, notToShow = false }) => {
    return notToShow ? null : (
        <div className="row my-3">
            <div className="col-md-3">{t(titleId)}</div>
            <div className="col-md-4">{t(dataText)}</div>
        </div>
    )
};

const fieldsSummary = requestFreeze => {
    const { startDate, duration, freezePrice, reason } = requestFreeze;
    const validDate = startDate === '' ? new Date() : new Date(startDate);
    return [
        {
            titleId: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_START_DATE',
            dataText: DisplayDateFormat(startDate, GENERAL_DATE_FORMAT)
        },
        {
            titleId: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_END_DATE',
            dataText: getFutureDateYearWithMonth(validDate, duration)
        },
        {
            titleId: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_REASON',
            dataText: reason ? reason.name : '',
            notToShow: reason ? false : true
        },
        {
            titleId: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_FEE',
            dataText: CommaFormatted(freezePrice)
        }
    ];
};

const renderTableContent = (_this: ThisInterface) => {
    const { freezeRequestSummary } = _this.state;
    const { t } = _this.props;

    return !_.isEmpty(freezeRequestSummary) ? (
        freezeRequestSummary.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{DisplayDateFormat(item.startDate, GENERAL_DATE_FORMAT)}</td>
                    <td>{item.typeName}</td>
                    <td>{item.method}</td>
                    <td>{item.status}</td>
                    <td>{CommaFormatted(item.amount)}</td>
                </tr>
            );
        })
    ) : (
        <tr className="no-data text-center"><td colSpan={5}>{t('COMMON.NO_DATA')}</td></tr>
    );
};

const renderPaymentSummary = (_this: ThisInterface) => {
    const {
        requestFreeze: { startDate },
        t
    } = _this.props;
    const tillDate = moment(
        startDate
    ).add(5, 'months').startOf('month').format(GENERAL_DATE_FORMAT);

    return (
        <React.Fragment>
            <h3 className="text-title mt-3 mb-3">
                {t('PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.MEMBER_PAYMENT_SUMMARY')}
            </h3>
            <p>
                {t(
                    'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.MEMBER_PAYMENT_SUMMARY_DESCRIPTION',
                    { month: 6, date: tillDate }
                )}
            </p>
            <CustomTable
                renderingCondition
                headers={paymentSummaryHeader}
                tableContent={renderTableContent(_this)}
                maxHeigth="auto"
                className="freeze-payment-summary-table"
            />
        </React.Fragment>
    );
};

const template = (_this: ThisInterface) => {
    const { memberData, requestFreeze, t } = _this.props;
    const { isLoading } = _this.state;
    return (
        <React.Fragment>
            <p>{t('PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.ENTER_INFORMATION')}</p>
            {fieldsSummary(requestFreeze).map(item => (
                <SummaryItem key={item.titleId} {...item} t={t} notToShow={item.notToShow} />
            ))}
            <EmailSummary memberData={memberData} />
            {isLoading ? <Loading /> : renderPaymentSummary(_this)}
        </React.Fragment>
    );
};

export default template;
