import React from 'react';
import { Input } from 'reactstrap';
import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';

const CheckBoxGroup = React.memo((props: FormItemInterface) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        attribute,
        data,
        className = '',
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = () => {
        onChangeHandle(fieldName, data);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';
    return !isVisible ? null : (
        <div className={`${errorClass} ${className} radio-check`}>
            <label className="custom-radio" htmlFor={htmlFor}>
                {fieldLabel} {isRequired && <span className="required">*</span>}
                <Input
                    {...attribute}
                    value={data}
                    onChange={onChange}
                    id={htmlFor}
                    name={fieldName}
                    type="radio"
                />
                <span className="checkmark-radio" />
            </label>
            {renderErrorHtml(
                isRequired,
                isValidType,
                isDirty,
                fieldLabel,
                customMsg,
                htmlLabel
            )}
        </div>
    );
});
CheckBoxGroup.displayName = 'CheckBoxGroup';

export default CheckBoxGroup;
