export default class MemberPersonalDetail {
    id = '';
    subTenantId = '';
    email = '';
    overrideEmail: any;
    nationalId = '';
    otherNationalId = '';
    dateOfBirth = '';
    languagePreference: any;
    addressNumber = '';
    line1 = '';
    line2 = '';
    city = '';
    state = '';
    postCode = '';
    country: any;
    mobilePhone = '';
    homePhone = '';
    workingPhone = '';
    companyName = '';
    occupation = '';
    referredByName = '';
    referredBymembershipNumber = '';
    otherCommisisionNote = '';
    generalNote = '';
    aiaVitalityCategory: any;
    recurringCardType = '';
    preferredContactMethod: [] = [];
    emergencyContactName = '';
    emergencyContactPhoneNumber = '';
    marketingConsent = '';
    thirdpartymarketingconsent = '';
    source: any;
    isHappyToBeContacted = false;
    isAllowContactByThirdParty = false;
}
