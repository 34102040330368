import React from 'react';
import { ListContentProps } from './interface';

const createField = (key, fieldName, data) => {
    return {
        [fieldName]: data.find(item => item.key === key)
    };
};

const fields = [
    {
        fieldName: 'referredByName',
        keyData: 'referredByName'
    },
    {
        fieldName: 'aiaVitalityCategory',
        keyData: 'aiaVitalityCategory'
    },
    {
        fieldName: 'country',
        keyData: 'address.Country'
    },
    {
        fieldName: 'companyName',
        keyData: 'companyName'
    },
    {
        fieldName: 'numberName',
        keyData: 'number/Name'
    },
    {
        fieldName: 'State',
        keyData: 'address.State'
    },
    {
        fieldName: 'otherCommissionNote',
        keyData: 'otherCommissionNote'
    },
    {
        fieldName: 'applicationRef',
        keyData: 'applicationRef'
    },
    {
        fieldName: 'generalNote',
        keyData: 'generalNote'
    },
    {
        fieldName: 'emergencyContactPhoneNumber',
        keyData: 'emergencyContactPhoneNumber'
    },
    {
        fieldName: 'nationalId',
        keyData: 'nationalId'
    },
    {
        fieldName: 'postcode',
        keyData: 'address.Postcode'
    },
    {
        fieldName: 'alternativeid',
        keyData: 'nationalId.Alternativeid'
    },
    {
        fieldName: 'memberHappyHappyToBeContacted',
        keyData: 'memberHappyToBeContact'
    },
    {
        fieldName: 'preferredContactMethodMobileTelephone',
        keyData: 'preferredContactMethod.MobileTelephone'
    },
    {
        fieldName: 'referredByMembershipNumber',
        keyData: 'referredByMembershipNumber'
    },
    {
        fieldName: 'preferredContactMethodWorkTelephone',
        keyData: 'preferredContactMethod.WorkTelephone'
    },
    {
        fieldName: 'addressLine1',
        keyData: 'address.Line1'
    },
    {
        fieldName: 'addressLine2',
        keyData: 'address.Line2'
    },
    {
        fieldName: 'emergencyContactName',
        keyData: 'emergencyContactName'
    },
    {
        fieldName: 'homeTelephone',
        keyData: 'telephoneNumber.HomeTelephone'
    },
    {
        fieldName: 'source',
        keyData: 'source'
    },
    {
        fieldName: 'memberIsHappyToBeContactedByAThirdParty',
        keyData: 'memberIsHappyToBeContactedByAThirdParty'
    },
    {
        fieldName: 'telephoneNumberMobileTelephone',
        keyData: 'telephoneNumber.MobileTelephone'
    },
    {
        fieldName: 'preferredContactMethod',
        keyData: 'preferredContactMethod'
    },
    {
        fieldName: 'occupation',
        keyData: 'occupation'
    },
    {
        fieldName: 'dateOfBirth',
        keyData: 'dateOfBirth.step3'
    },
    {
        fieldName: 'nationalIdNationalId',
        keyData: 'nationalId.NationalId'
    },
    {
        fieldName: 'workTelephone',
        keyData: 'telephoneNumber.WorkTelephone'
    },
    {
        fieldName: 'emailAddress',
        keyData: 'emailAddress'
    },
    {
        fieldName: 'memberLanguagePreferences',
        keyData: 'memberLanguagePreferences'
    },
    {
        fieldName: 'addressCity',
        keyData: 'address.City'
    },
    {
        fieldName: 'telephoneNumber',
        keyData: 'addMember.telephoneNumber'
    }

];

export const createFields = (rootKey, data) => {
    return fields.map(item => {
        return createField(`${rootKey}.${item.keyData}`, item.fieldName, data);
    });
};

export const ListContent: React.FC<ListContentProps> = (props) => {
    return (
        <>
            {props.fields.map((field, key) => {
                if (!field || field.isVisible === false) return null;
                return (
                    <dl key={key}>
                        <dt className="col-md-6">{field.fieldLabel}</dt>
                        <dd className="col-md-6">{field.data}</dd>
                    </dl>
                );
            })}
        </>
    );
};