import FormItem from 'components/FormV2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { transformOptions } from 'util/ControlUtils';
import { FeesTable } from '../common';
import { PayOptions } from '../interface';
import Props from './interface';

const PayOptionsGroup: React.FC<Props> = (props) => {
    const { payOption, onChangeOption } = props;
    const { t } = useTranslation();

    const payOptions = [
        {
            fieldLabel: t('PAGE.MAKE_PAYMENT.PAY_OPTIONS.FULL'),
            fieldName: 'payOption',
            fields: [],
            attribute: {
                checked: payOption === PayOptions.Full
            },
            data: PayOptions.Full
        },
        {
            fieldLabel: t('PAGE.MAKE_PAYMENT.PAY_OPTIONS.OTHER_AMOUNT'),
            fieldName: 'payOption',
            fields: [],
            attribute: {
                checked: payOption === PayOptions.OtherAmount
            },
            data: PayOptions.OtherAmount
        },
        {
            fieldLabel: t('PAGE.MAKE_PAYMENT.PAY_OPTIONS.CUSTOM_AMOUNT'),
            fieldName: 'payOption',
            fields: [],
            attribute: {
                checked: payOption === PayOptions.CustomAmount
            },
            data: PayOptions.CustomAmount
        }
    ];


    return (
        <div className="form-check ml-4">
            {payOptions.map((option, index) => (
                <FormItem
                    key={index}
                    type="radio"
                    className="my-3"
                    onChangeHandle={() => {
                        onChangeOption(option.data);
                    }}
                    {...option}
                />
            ))}
        </div>
    );
};

const PaymentDetails: React.FC<Props> = (props) => {
    const { 
        paymentAmount,
        onChangeField,
        payOption,
        allocate,
        selectedTenderType,
        tenderTypes,
        validations
    } = props;
    const { t } = useTranslation();
    return (
        <div className="payment-details">
            <div className="row">
                {payOption !== PayOptions.Full && (
                    <div className="col-4">
                        <label htmlFor="paymentAmount">
                            {t('PAGE.MAKE_PAYMENT.ENTER_AMOUNT_TO_PAY')}
                        </label>
                        <FormItem
                            data={paymentAmount}
                            fieldName="paymentAmount"
                            htmlFor="paymentAmount"
                            type="text"
                            fields={[validations['paymentAmount']]}
                            onChangeHandle={onChangeField}
                        />
                    </div>
                )}
                <div className="col-6">
                    <label htmlFor="tenderType">
                        {t('PAGE.MAKE_PAYMENT.SELECT_TENDER_TYPE')}
                    </label>
                    <FormItem
                        data={selectedTenderType}
                        fieldName="tenderType"
                        htmlFor="tenderType"
                        type="select"
                        fields={[]}
                        onChangeHandle={onChangeField}
                        options={transformOptions(tenderTypes)}
                        attribute={{
                            isClearable: false
                        }}
                    />
                </div>
            </div>
            {
                payOption !== PayOptions.Full && (
                    <div className="row col" onClick={() => allocate()}>
                        <button className={`btn btn-primary`}>
                            {t('PAGE.MAKE_PAYMENT.BUTTON.ALLOCATE')}
                        </button>
                    </div>
                )
            }
        </div>
    );
};

export const PaymentInfo = (props: Props) => {
    return (
        <React.Fragment>
            <div className="row">
                <FeesTable {...props} />
            </div>
            <div className="row">
                <PayOptionsGroup {...props} />
            </div>
            <div className="row">
                <PaymentDetails {...props} />
            </div>
        </React.Fragment>
    );
};