import React from 'react';
import Slider from 'react-rangeslider';
import {WithTranslation, withTranslation} from 'react-i18next'
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import observer from 'util/Observer';

interface Props extends WithTranslation{
    onSave: (image: string) => void;
    onCancel: () => void;
    isAvatar?: boolean;
    width?: number;
    height?: number;
    maxSize?: number;
    message?: string;
}

interface State {
    zoom: number;
    invisibleHolder: boolean;
    isDiscard: boolean;
}

declare var AvatarCrop;
declare var img;

class CropImage extends React.Component<Props, State> {
    avatar;
    constructor(props: Props) {
        super(props);
        AvatarCrop.DEFAULT_MODE = 'fill';
        this.state = {
            zoom: 1,
            invisibleHolder: true,
            isDiscard: false,
        };
    }

    static defaultProps = {
        width: 276,
        height: 99,
        maxSize: DefaultConstants.MAX_FILESIZE_LOGO_UPLOAD * 1024 * 1024,
        message: '',
    }

    componentDidMount() {
        const { width, height, maxSize, message } = this.props;

        this.avatar = new AvatarCrop(document.querySelector('.holder'), {
            width,
            height,
            maxSize,
            message
        });

        img = document.getElementById('image-store');

        this.avatar.addEventListener('change', () => this.changingImage());
        this.avatar.addEventListener('clear', () => this.changingImage());
        this.avatar.addEventListener('load', () => this.selectedImage());
        this.avatar.addEventListener('errorMaxSize', () => this.showErrorMaxSize());

        observer.subscribe('windowResize', data => {
            const { canvasW, canvasH } = data;
            if (canvasW && canvasH) {
                this.avatar.config({
                    width: canvasW,
                    height: canvasH,
                });
                this.avatar.render();
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('windowResize');
    }

    selectedImage = () => {
        this.avatar.fit().render();
        this.setState({
            invisibleHolder: false,
            isDiscard: true
        });
    };

    changingImage = () => {
        let data = this.avatar.save();
        if (data) {
            img.src = data;
        } else {
            img.removeAttribute('src');
        }
    };

    showErrorMaxSize = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t(
                'PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.FILE_SIZE_INSTRUCTION',
                { filesize: DefaultConstants.MAX_FILESIZE_LOGO_UPLOAD }
            ),
            type: 'error',
            confirmButtonText: 'OK'
        });
    };

    changeZoom = (value: number) => {
        this.setState({ zoom: value });
        if (this.avatar && this.avatar.data) {
            let image = this.avatar.data,
                imageW = image.width,
                imageH = image.height,
                ratio = 1;
            if (imageH < imageW) {
                ratio = imageH / imageW;
            } else {
                ratio = imageW / imageH;
            }
            this.avatar.zoom(ratio + value / 10).render();
        }
    };

    zoomOut = () => {
        const { zoom } = this.state;
        if (zoom > 1) {
            this.changeZoom(zoom - 1);
        }
    };

    zoomIn = () => {
        const { zoom } = this.state;
        if (zoom < 100) {
            this.changeZoom(zoom + 1);
        }
    };

    discardChange = () => {
        img.src = ''
        this.avatar.clear();
        this.setState({
            zoom: 1,
            invisibleHolder: true,
            isDiscard: false
        });
    }

    handleSave = (imageSrc: string) => {
        this.props.onSave(imageSrc);
        this.props.isAvatar ? this.discardChange() : '';
    }

    handleCancel = () => {
        this.props.onCancel();
        this.discardChange();
    }

    render() {
        const { isAvatar, t } = this.props;
        const { zoom, invisibleHolder, isDiscard } = this.state;

        return (
            <div className="appearance">
                <div className="tabs">
                    <div className="tab-content" style={{ border: 'none' }}>
                        <div className="change-logo tab-pane active">
                            <div className="up-logo">
                                <div className="box-img">
                                    <a id="img-store">
                                        <img id="image-store" src='' />
                                    </a>
                                    <div
                                        id="holder"
                                        className={`holder ${
                                            invisibleHolder ? 'invisible-holder' : ''
                                        }`}
                                    />
                                    <span className="upload-title icon-image-ico">
                                        {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.UPLOAD_IMAGE_HERE')}
                                    </span>
                                    <div className="change-img">
                                        <button
                                            onClick={this.zoomOut}
                                            className="btn btn-zoom-in icon-zoom-in-ico"
                                        />
                                        <button
                                            onClick={this.zoomIn}
                                            className="btn btn-zoom-out icon-zoom-out-ico"
                                        />
                                        <div className="uou-rangeslider">
                                            <Slider
                                                min={1}
                                                max={100}
                                                step={1}
                                                value={zoom}
                                                tooltip={false}
                                                onChange={this.changeZoom}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isAvatar && (
                                <>
                                    <div className="description">
                                        <p>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.AVATAR_DESCRIPTION_1', {size: 2})}</p>
                                        <p>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.AVATAR_DESCRIPTION_2')}</p>
                                    </div>
                                </>
                            )}
                            {!isAvatar && (
                                <p>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.UPLOAD_LOGO_INSTRUCTION', {filesize: DefaultConstants.MAX_FILESIZE_LOGO_UPLOAD})}</p>
                            )}
                            <div className="saveColor">
                                <button className="btn btn-dark text-uppercase" onClick={this.handleCancel}>
                                    {t(`${isAvatar ? 'BUTTON.CANCEL' : 'BUTTON.RESTORE_DEFAULTS'}`)}
                                </button>
                                {!isAvatar && isDiscard
                                    && <button
                                        className="btn btn-dark hover text-uppercase"
                                        onClick={this.discardChange}
                                    >
                                        {t('BUTTON.DISCARD_CHANGE')}
                                    </button>}
                                <button
                                    className="btn btn-primary text-uppercase"
                                    disabled={!isDiscard}
                                    onClick={() => this.handleSave(img.src)}
                                >
                                    {t('BUTTON.SAVE')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(CropImage);
