import React from 'react';
import * as Interface from 'interfaces';
import * as DefaultConstants from 'constants/DefaultConstants';
import Loading from 'components/Loading';
import Swal from 'sweetalert2';
import store from 'reduxs/index';
import { setSelectedSubTenant } from 'reduxs/actions/NewMembership';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props {
    membershipService: Interface.PagePropsInterface['membershipService'];
    onCancel: () => void;
    isEdit: boolean;
}

interface State {
    isLoading: boolean;
    subTenants: any;
    selectedSubTenantId: string;
}

class SelectSubTenant extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            isLoading: true,
            subTenants: [],
            selectedSubTenantId: ""
        }
    }

    componentDidMount(){
        const { membershipService, isEdit }  = this.props;    
        const rightName = !isEdit ? Route.AddNewMemberRoute.rightName : Route.ChangeMembershipRoute.rightName;
            
        membershipService
            .getSubTenants(rightName)
            .then(res => this.initSubTenantData(res.data));          
    }

    initSubTenantData = (operatorSubTenants: any) => {

        if (operatorSubTenants.length == 1) {
            store.dispatch(setSelectedSubTenant(operatorSubTenants[0]));
        }
        else {
            operatorSubTenants.sort((t1, t2) => {
                var name1 = t1.name.toUpperCase();
                var name2 = t2.name.toUpperCase();
                if (name1 < name2) {
                    return -1;
                }
                if (name1 > name2) {
                    return 1;
                }
                return 0;
            });

            this.setState({
                isLoading: false,
                subTenants: operatorSubTenants
            });
        }
    }

    onSubTenantSelected = (subtenantId: string) => {
        this.setState({
            selectedSubTenantId: subtenantId
        })
    }

    handleNext = () => {
        const { t } = this.props;
        if (this.state.selectedSubTenantId === "") {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                type: 'error',
                html: t('PAGE.MEMBERSHIPS.CREATE.STEP_0.SELECT_SUBTENANT_REQUIRED'),
                confirmButtonText: t('BUTTON.CLOSE')
            });
        }
        else {
            const { subTenants, selectedSubTenantId } = this.state;
            let subtenant = subTenants.find(t => t.id == selectedSubTenantId);
            store.dispatch(setSelectedSubTenant(subtenant));
        }
    }

    handleCancel = () => {
        this.props.onCancel();
    }

    render() {
        const { isLoading, subTenants, selectedSubTenantId } = this.state;
        const { t } = this.props;
        const tenantList = subTenants.map((t, index) => (
            <div key={index} className="col-6 col-md-4">
                <button className={`btn btn-default ${selectedSubTenantId == t.id ? "active" : ""}`}
                    onClick={() => this.onSubTenantSelected(t.id)}>
                    {t.name}
                </button>
            </div>
        ))

        return (
            tenantList.length < 2 ?
                <div></div>
                :
                <div className="main-center d-flex justify-content-center select-subtenant">
                    <section id="sub-tenant">
                        <div className="sub-tenant">
                            <div className="title-sub-tenant">
                                <h3>{t('PAGE.MEMBERSHIPS.CREATE.STEP_0.TITLE')}</h3>
                            </div>
                            <div className="main-sub-tenant">
                                <div className="intro-sub-tenant">
                                    <p className="title">{t('PAGE.MEMBERSHIPS.CREATE.STEP_0.INSTRUCTION')}</p>
                                    <p className="note">{t('PAGE.MEMBERSHIPS.CREATE.STEP_0.NOTICE')}</p>
                                </div>
                                <div className="box-btn-select">
                                    <div className="row">
                                        {isLoading ? <Loading /> : tenantList}
                                    </div>
                                </div>
                                <div className="box-btn-confirm">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <button className="btn btn-primary" onClick={this.handleNext}>
                                                {t('BUTTON.NEXT')}
                                            </button>
                                        </div>
                                        <div className="col-6 col-md-4">
                                            <button className="btn btn-dark" onClick={this.handleCancel}>
                                                {t('BUTTON.CANCEL')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        )
    }
}

export default withTranslation()(SelectSubTenant);