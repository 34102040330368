import React from 'react';
import ThisInterface, { PropsInterface } from './interface';
import template from './template';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
class RoleListPage extends React.Component<
PropsInterface & WithTranslation,
ThisInterface['state']
> {
    constructor(props: PropsInterface & WithTranslation) {
        super(props);
        this.state = {
            version: 0,
            tableHeight: window.innerHeight - 320
        };
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.setState({
            version: 1
        });
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 320
        });
    };

    callback = (action: string, id: string, item: any) => {
        const { history } = this.props;
        switch (action) {
            case 'edit':
                history.push(`/system/roles/${id}/edit`);
                break;
            case 'delete':
                console.log(`Delete role ${id}`);
                break;
            default:
        }
        this.setState(state => ({ version: state.version + 1 }));
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(RoleListPage)));
