import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import { FormInput, FormPhoneInput, FormSelect } from 'components/Form';
import SubTenantRolePanel from 'components/SubTenantRolePanel/SubTenantRolePanel';
import AdvanceSearchPanel from 'components/AdvanceSearchPanel';
import Loading from 'components/Loading';

import { checkInvalidEmailField, isValidPassword, getTenantId } from 'util/ControlUtils';
import * as API from 'constants/Constants';

import UserAvatar from './UserAvatar';
import ThisInterface from './interface';

const filterRoleByTenantAndName = {
    and: [
        {
            operation: 'contains',
            queryType: 'text',
            queryKey: 'role.name',
            queryValue: '{value}'
        }
    ]
};  

const template = (_this: ThisInterface) => {
    const {
        avatarUri,
        userName,
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        tenantName,
        subTenantId,
        isSelectedRolesValid,
        tenantRoleEnabled,
        selectedTenantRoles,
        selectedSubTenantRoles,
        userGroup,
        userGroupFilterObject,
        isDirtyUserName,
        isDirtyFirstName,
        isDirtyLastName,
        isDirtyPassword,
        isCopy,
        isLoading,
        disabled
    } = _this.state;
    const { userProfile, isEditMode, subTenants, subTenantRoleEnabled, t } = _this.props;

    return (
        <section className="edit-profile system-page">
            <p className="title-content">
                {isEditMode ? t('PAGE.SYSTEM.USERS.ADD_EDIT.TITLE_EDIT') : t('PAGE.SYSTEM.USERS.ADD_EDIT.TITLE_ADD')}
            </p>
            {isLoading ? (
                <Loading />
            ) : _this.mounted && (
                <div className="profile-content">
                    <UserAvatar avatarUri={avatarUri} handleChangeAvatar={_this.handleChangeAvatar} />
                    <div className="edit-acc tabs">
                        <div className="tab-content">
                            <div>
                                <div className="row form-add-user">
                                    <div className="col-md-6">
                                        <div className="form-edit">
                                            <div className="form-input">
                                                <FormInput
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.USERNAME')}
                                                    id="user-name"
                                                    placeholder={t('PAGE.SYSTEM.USERS.ADD_EDIT.USERNAME')}
                                                    value={userName}
                                                    maxlength={100}
                                                    isDirty={isDirtyUserName}
                                                    onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                                        _this.handleChangeText(e!, 'userName')
                                                    }
                                                    errorMsg={t('COMMON.NOT_EMPTY', {key: 'User name'})}
                                                    require
                                                />
                                                <Tooltip title={`${t('PAGE.SYSTEM.USERS.ADD_EDIT.USER_NAME_INSTRUCTION')}`} placement="top-start">
                                                    <IconButton aria-label="info" className="btn-tooltip">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="form-input">
                                                <FormInput
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.FIRST_NAME')}
                                                    id="first-name"
                                                    placeholder={t('PAGE.SYSTEM.USERS.ADD_EDIT.FIRST_NAME')}
                                                    value={firstName}
                                                    maxlength={50}
                                                    isDirty={isDirtyFirstName}
                                                    onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                                        _this.handleChangeText(e!, 'firstName')
                                                    }
                                                    errorMsg={t('COMMON.NOT_EMPTY', {key: 'First name'})}
                                                    require
                                                />
                                                <Tooltip title={`${t('PAGE.SYSTEM.USERS.ADD_EDIT.NAME_INSTRUCTION')}`} placement="top-start">
                                                    <IconButton aria-label="info" className="btn-tooltip">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="form-input">
                                                <FormInput
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.LAST_NAME')}
                                                    id="last-name"
                                                    placeholder={t('PAGE.SYSTEM.USERS.ADD_EDIT.LAST_NAME')}
                                                    value={lastName}
                                                    maxlength={50}
                                                    isDirty={isDirtyLastName}
                                                    onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                                        _this.handleChangeText(e!, 'lastName')
                                                    }
                                                    errorMsg={t('COMMON.NOT_EMPTY', {key: 'Last name'})}
                                                    require
                                                />
                                                <Tooltip title={`${t('PAGE.SYSTEM.USERS.ADD_EDIT.NAME_INSTRUCTION')}`} placement="top-start">
                                                    <IconButton aria-label="info" className="btn-tooltip">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-edit">
                                            <div className="form-input">
                                                <FormInput
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.EMAIL')}
                                                    id="email"
                                                    maxlength={254}
                                                    placeholder={t('PAGE.SYSTEM.USERS.ADD_EDIT.EMAIL')}
                                                    value={email ? email : ''}
                                                    onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                                        _this.handleChangeText(e!, 'email')
                                                    }
                                                    isValid={checkInvalidEmailField(email)}
                                                    validMsg={t('COMMON.ERR_NOT_VAILD',{value: 'Email'})}
                                                />
                                                <Tooltip title={`${t('PAGE.SYSTEM.USERS.ADD_EDIT.EMAIL_INSTRUCTION')}`} placement="top-start" className="btn-tooltip">
                                                    <IconButton aria-label="info">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="form-input">
                                                <FormPhoneInput
                                                    id="phone-number"
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.PHONE_NUMBER')}
                                                    value={phoneNumber.nationalNumber}
                                                    countryCode={phoneNumber.regionCode}
                                                    onPhoneNumberChange={(phoneNum, dialCode, isValid) =>
                                                        _this.handleChangePhoneNumber(
                                                            phoneNum,
                                                            dialCode,
                                                            isValid,
                                                            'phoneNumber'
                                                        )
                                                    }
                                                    errorMsg={t('COMMON.ERR_NOT_VAILD',{value: 'Phone number'})}
                                                />
                                                <Tooltip title={`${t('PAGE.SYSTEM.USERS.ADD_EDIT.PHONE_NUMBER_INSTRUCTION')}`} placement="top-start" className="btn-tooltip">
                                                    <IconButton aria-label="info">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div className="generate-pw">
                                                <div className="form-input">
                                                    <FormInput
                                                        className="password"
                                                        label={t('PAGE.SYSTEM.USERS.ADD_EDIT.PASSWORD')}
                                                        id="password"
                                                        maxlength={20}
                                                        minlength={8}
                                                        placeholder={t('PAGE.SYSTEM.USERS.ADD_EDIT.PASSWORD')}
                                                        value={password}
                                                        isDirty={isDirtyPassword}
                                                        isValid={!password || password === '****' || password.length >= 8 && password.length <= 20 && isValidPassword(password)}
                                                        onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                                            _this.handleChangeText(e!, 'password')
                                                        }
                                                        errorMsg={t('COMMON.NOT_EMPTY', {key: 'Password'})}
                                                        require={!isEditMode}
                                                        validMsg={t('COMMON.PASSWORD_WRONG_FORMAT')}
                                                    />
                                                    <Tooltip title={t('PAGE.SYSTEM.USERS.ADD_EDIT.PASSWORD_INSTRUCTION')} placement="top-start" className="btn-tooltip">
                                                        <IconButton aria-label="info">
                                                            <InfoIcon className="infoIcon" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                <div className="btn-holder">
                                                    <button className="btn btn-primary" onClick={_this.handleGeneratePassword}>
                                                        {t('BUTTON.GENERATE')}
                                                    </button>
                                                    <CopyToClipboard text={password} onCopy={_this.handleCopyPassword} >
                                                        <button className="btn btn-primary">{t('BUTTON.COPY')}</button>
                                                    </CopyToClipboard>
                                                    {isCopy && <span>{t('COMMON.COPIED')}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-edit">
                                            <div className="form-input hidden-tooltip">
                                                <FormInput
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.TENANT')}
                                                    id="tenant"
                                                    placeholder={t('PAGE.SYSTEM.USERS.ADD_EDIT.TENANT')}
                                                    value={tenantName}
                                                    onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                                        _this.handleChangeText(e!, 'tenantName')
                                                    }
                                                    disabled
                                                />
                                                <Tooltip title="" placement="top-start" className="btn-tooltip">
                                                    <IconButton aria-label="info">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-edit">
                                            <div className="form-input hidden-tooltip">
                                                <FormSelect
                                                    label={t('PAGE.SYSTEM.USERS.ADD_EDIT.SUB-TENANT')}
                                                    options={subTenants}
                                                    value={subTenantId}
                                                    onChange={_this.handleSubTenant}
                                                />
                                                <Tooltip title="" placement="top-start" className="btn-tooltip">
                                                    <IconButton aria-label="info">
                                                        <InfoIcon className="infoIcon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="add-usergroup add-usergroup-container">
                                            <p>{t('PAGE.SYSTEM.USERS.ADD_EDIT.ROLE_OF_USER')}<span className="text-primary bold">&nbsp;*</span></p>
                                            {!isSelectedRolesValid && (
                                                <>
                                                    <span className="text-danger input-error">
                                                        {t('MSG.SYSTEM.USERS.ADD_USER.ROLE_REQUIRED')}
                                                    </span>
                                                    <br />
                                                </>
                                            )}
                                            {userProfile.tenantRoles.length > 0 && (
                                                <div className="tenant-roles">
                                                    <div className="form-group switch-container">
                                                        <label className="switch">
                                                            <input type="checkbox" checked={tenantRoleEnabled}
                                                                onChange={(e) => _this.onTenantRoleSwichChanged(e)} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <span className="label-select-switch">
                                                            {t('PAGE.SYSTEM.USERS.ADD_EDIT.TENANT_LEVEL')}
                                                        </span>
                                                    </div>
                                                    {tenantRoleEnabled && (
                                                        <AdvanceSearchPanel
                                                            titleFirstSelect={t('PAGE.SYSTEM.USERS.ADD_EDIT.ROLES')}
                                                            placeholderFirstSelect={t('PAGE.SYSTEM.USERS.ADD_EDIT.ADD_ROLE')}
                                                            tooltipFirstSelect={t('PAGE.SYSTEM.USERS.ADD_EDIT.ROLE_INSTRUCTION')}
                                                            emptyTagMessage={t('PAGE.SYSTEM.USERS.ADD_EDIT.NO_ROLE_HAS_BEEN_CHOSEN_YET')}
                                                            firstSelectApi={API.API_SEARCH_ROLES}
                                                            filterFirstSelect={JSON.stringify(filterRoleByTenantAndName)}
                                                            selectedSuggestions={selectedTenantRoles}
                                                            onSuggestionAdded={(suggestion) => _this.handleAddRemoveSingleSuggestion(suggestion, 'selectedTenantRoles')}
                                                            onSuggestionRemoved={(suggestion) => _this.handleAddRemoveSingleSuggestion(suggestion, 'selectedTenantRoles')}
                                                            rightName={_this.state.rightName}
                                                        />
                                                    )}
                                                </div>
                                            )}

                                            <div className="sub-teanant-roles">
                                                <div className="form-group switch-container">
                                                    <label className="switch">
                                                        <input type="checkbox"
                                                            checked={subTenantRoleEnabled}
                                                            onChange={(e) => _this.onSubTenantRoleSwichChanged(e)} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <span className="label-select-switch">
                                                        {t('PAGE.SYSTEM.USERS.ADD_EDIT.SUB-TENANT_LEVEL')}
                                                    </span>
                                                </div>

                                                {subTenantRoleEnabled &&
                                                    <SubTenantRolePanel
                                                        parent={'user'}
                                                        searchClubGroupApi={API.API_SEARCH_CLUBGROUP.replace('{1}', getTenantId())}
                                                        searchRoleApi={API.API_SEARCH_ROLES}
                                                        selectedSubTenantRoles={selectedSubTenantRoles}
                                                        onRoleAdded={(subId, roles) => _this.onSubTenantRole(subId, roles, 'add')}
                                                        onRoleDeleted={(subId, roles) => _this.onSubTenantRole(subId, roles, 'delete')}
                                                        onRoleDisabled={subId => _this.onSubTenantRole(subId, [], 'disabled')} />
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-md-12">
                                        <AdvanceSearchPanel
                                            useSubTenantId={false}
                                            firstSelectApi={tenantRoleEnabled && userProfile.tenantRoles.length > 0 ? API.API_SEARCH_USER_GROUPS : API.API_USERS_GROUP_FILTER}
                                            filterFirstSelect={JSON.stringify(userGroupFilterObject)}
                                            placeholderFirstSelect={t('PAGE.SYSTEM.USERS.ADD_EDIT.ADD_USER_GROUP')}
                                            titleFirstSelect={t('PAGE.SYSTEM.USERS.ADD_EDIT.USER_GROUP')}
                                            tooltipFirstSelect={t('PAGE.SYSTEM.USERS.ADD_EDIT.USER_GROUP_INSTRUCTION')}
                                            emptyTagMessage={t('PAGE.SYSTEM.USERS.ADD_EDIT.NO_USER_GROUP_HAS_BEEN_ADDED_YET')}
                                            onSuggestionAdded={(suggestion) => _this.handleAddRemoveSingleSuggestion(suggestion, 'userGroup')}
                                            selectedSuggestions={userGroup}
                                            onSuggestionRemoved={(suggestion) => _this.handleAddRemoveSingleSuggestion(suggestion, 'userGroup')}
                                            method={'post'}
                                            rightName={_this.state.rightName}
                                        />
                                    </div>

                                    <div className="row-btn col-md-12">
                                        <button className="btn btn-primary" onClick={_this.handleSaveUser} disabled={disabled}>
                                            {t('BUTTON.SAVE')}
                                        </button>
                                        <button className="btn btn-dark" onClick={_this.handleCancel}>
                                            {t('BUTTON.CANCEL')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default template;
