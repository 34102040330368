import React from 'react';
import Loading from 'components/Loading';
import ThisInterface, { ContentField } from './interface';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';
import { NavLink } from 'react-router-dom';
import { ListContent } from './common';

export const Template: React.FC<ThisInterface> = _this => {
    const { data, isLoading, fields } = _this.state;
    const { t } = _this.props;
    const renderForm = () => {
        if (data) {
            let addressNumber = data.addressNumber ? `${data.addressNumber}` : '';
            let line = data.line1
                ? `${data.line1},`
                : data.line2
                    ? `${data.line2},`
                    : '';
            let city = data.city ? `${data.city},` : '';
            let state = data.state ? `${data.state},` : '';
            let country = data.country && data.country.name ? data.country.name : '';
            let address = line
                ? `${addressNumber} ${line} ${city} ${state} ${country}`.trim()
                : t('COMMON.NA');

            let email = data.email
                ? data.email
                : data.overrideEmail && data.overrideEmail.name.toLowerCase() != 'email'
                    ? data.overrideEmail.name
                    : t('COMMON.NA');
            let contacts = data.preferredContactMethod
                ? data.preferredContactMethod
                    .map(({ name }) => {
                        return name;
                    })
                    .join(', ')
                : t('COMMON.NA');

            const col1Rows: ContentField[] = [
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.HOME_ADDRESS'),
                    data: address,
                    isVisible: true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.HOME_PHONE'),
                    data: data.homePhone,
                    isVisible: fields.telephoneNumber ? fields.telephoneNumber.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.WORK_PHONE'),
                    data: data.workingPhone,
                    isVisible: fields.telephoneNumber ? fields.telephoneNumber.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.MOBILE_PHONE'),
                    data: data.mobilePhone,
                    isVisible: fields.telephoneNumber ? fields.telephoneNumber.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.EMAL_ADDRESS'),
                    data: email,
                    isVisible: fields.emailAddress ? fields.emailAddress.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.DATE_OF_BIRTH'),
                    data: DisplayDateFormat(data.dateOfBirth, GENERAL_DATE_FORMAT),
                    isVisible: fields.dateOfBirth ? fields.dateOfBirth.isVisible : true
                },
                {
                    fieldLabel: fields.nationalId ? fields.nationalId.name : t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.NATIONAL_ID'),
                    data: data.nationalId,
                    isVisible: fields.nationalId ? fields.nationalId.isVisible : true
                },
                {
                    fieldLabel: fields.alternativeid ? fields.alternativeid.name : t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.OTHER_NATIONAL_ID'),
                    data: data.otherNationalId,
                    isVisible: fields.nationalId ? fields.nationalId.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.MEMBER_LANGUAGE_PREFER'),
                    data: data.languagePreference
                        ? data.languagePreference.name
                        : t('COMMON.NA'),
                    isVisible: fields.memberLanguagePreferences
                        ? fields.memberLanguagePreferences.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.MARKETING_AND_OTHER_QUESTION'),
                    data: '',
                    isVisible: true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.COMPANY_NAME'),
                    data: data.companyName,
                    isVisible: fields.companyName ? fields.companyName.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.OCCUPATION'),
                    data: data.occupation,
                    isVisible: fields.occupation ? fields.occupation.isVisible : true
                }
            ];

            const col2Rows: ContentField[] = [
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.REFERRED_BY_NAME'),
                    data: data.referredByName,
                    isVisible: fields.referredByName
                        ? fields.referredByName.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.REFERRED_BY_MEMBERSHIP_NUMBER'),
                    data: data.referredBymembershipNumber,
                    isVisible: fields.referredByMembershipNumber
                        ? fields.referredByMembershipNumber.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.OTHER_COMMISSION_NOTE'),
                    data: data.otherCommisisionNote,
                    isVisible: fields.otherCommissionNote
                        ? fields.otherCommissionNote.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.GENERAL_NOTE'),
                    data: data.generalNote,
                    isVisible: fields.generalNote ? fields.generalNote.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.AIA_VITALITY_CATEGORY'),
                    data: data.aiaVitalityCategory
                        ? data.aiaVitalityCategory.name
                        : t('COMMON.NA'),
                    isVisible: fields.aiaVitalityCategory
                        ? fields.aiaVitalityCategory.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.SOURCE'),
                    data: data.source
                        ? data.source.name
                        : t('COMMON.NA'),
                    isVisible: fields.source ? fields.source.isVisible : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.EMERGENCY_CONTACT_NAME'),
                    data: data.emergencyContactName,
                    isVisible: fields.emergencyContactName
                        ? fields.emergencyContactName.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.EMERGENCY_CONTACT_PHONE_NUMBER'),
                    data: data.emergencyContactPhoneNumber,
                    isVisible: fields.emergencyContactPhoneNumber
                        ? fields.emergencyContactPhoneNumber.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.MARKETING_CONSENT'),
                    data: data.isHappyToBeContacted
                        ? t('BUTTON.YES')
                        : t('BUTTON.NO'),
                    isVisible: fields.memberHappyHappyToBeContacted
                        ? fields.memberHappyHappyToBeContacted.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.THIRD_PARTY_MARKETING'),
                    data: data.isAllowContactByThirdParty
                        ? t('BUTTON.YES')
                        : t('BUTTON.NO'),
                    isVisible: fields.memberIsHappyToBeContactedByAThirdParty
                        ? fields.memberIsHappyToBeContactedByAThirdParty.isVisible
                        : true
                },
                {
                    fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.PREFER_CONTACT_PREFERENCES'),
                    data: contacts,
                    isVisible: fields.preferredContactMethod
                        ? fields.preferredContactMethod.isVisible
                        : true
                }
            ];

            return (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-6 list-content">
                            <ListContent fields={col1Rows} />
                        </div>
                        <div className="col-md-6 list-content">
                            <ListContent fields={col2Rows} />
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return <></>;
    };

    const renderContent = () => {
        return renderForm();
    };

    return isLoading ? (
        <Loading />
    ) : (
        <React.Fragment>
            <div className="view-personal-detail">
                <div className="view-personal-detail_header">
                    <h3 className="text-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.PERSONAL_DETAILS')}
                    </h3>
                    <ul className="list-action">
                        <li>
                            <NavLink
                                to={{
                                    pathname: `/memberships/members/${
                                        data.id
                                    }/change-personal-details`,
                                    state: { subTenantId: data.subTenantId, previousSection: 'personal-detail' }
                                }}
                            >
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.CHANGE_PERSONAL_DETAILS')}
                            </NavLink>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.CHANGE_EXTRA_CLUB')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.CHANGE_HOME_CLUB')}
                            </a>
                        </li>
                        <li>
                            <a onClick={_this.props.onGoToPaymentDetail}>
                                {' '}
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.CHANGE_PAYMENT_DETAILS')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.BAN_MEMBER')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.MAKE_PAYMENT')}
                            </a>
                        </li>
                    </ul>
                </div>

                {renderContent()}
                <div className="view-personal-detail-health">
                    <h3 className="text-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.HEALTH_QUESTIONNAIRE')}
                    </h3>
                    <ul className="list-action">
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.ADD_NOTE')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.ADD_MESSAGE')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.VIEW_AUDIT')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.VET_MEMBER')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.UPDATE_TAX_EXAMPTION')}
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                {t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.MARK_DECEASED')}
                            </a>
                        </li>
                    </ul>
                </div>
                <p>{t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.HEALTH_QUESTIONNAIRE_MEDICAL_QUESTIONNAIRE_COMPLETED')}</p>
                <div className="row-btn">
                    <div className="col-md-12">
                        <ul className="list-btn-action">
                            <li>
                                <button
                                    onClick={_this.cancel}
                                    className="btn btn-dark btn-w100"
                                >
                                    {t('BUTTON.BACK')}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
