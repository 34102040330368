export default class MembershipConfiguration {
    stepIndex = 1;
    stepLatest = 1;

    newMemberTimeFormat = "";
    newMemberDOBFormat = "";
    summaryTimeFormat = "";
    
    nationalIdSystemValidation = "";
    nationalIdSystemValidationDescription = "";
    homePhoneSystemValidation = "";
    homePhoneSystemValidationDescription = "";
    mobilePhoneSystemValidation = "";
    mobilePhoneSystemValidationDescription = "";

    locale = "";
}