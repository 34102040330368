import React from 'react';
import FormItem, { getIdOrUndefined, getCodeOrUndefined } from 'components/FormV2';
import Loading from 'components/Loading';
import { AddEditTenderTypeInterface } from 'pages/SystemPage/Tender/TenderType/interface';

const template = (_this: AddEditTenderTypeInterface) => {
    const {
        tenderTypeDetail,
        fields,
        isEditMode,
        isViewMode,
        subTenants,
        authorizationMethods,
        tenderClasses,
        currency,
        isLoading } = _this.state;
	
    const { t } = _this.props;

    const generalInfoColumns = [
        {
            props: {
                type: 'text',
                htmlFor: 'tenderTypeDetail[code]',
                fieldName: 'tenderTypeDetail[code]',
                data: tenderTypeDetail.code,
                attribute: {
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.TENDER_TYPE')
            }
        },
        {
            props: {
                type: 'text',
                htmlFor: 'tenderTypeDetail[name]',
                fieldName: 'tenderTypeDetail[name]',
                data: tenderTypeDetail.name,
                attribute: {
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.NAME')
            }
        },
        {
            props: {
                type: 'select',
                htmlFor: 'tenderTypeDetail[authorizationMethod]',
                fieldName: 'tenderTypeDetail[authorizationMethod]',
                data: getIdOrUndefined(tenderTypeDetail.authorizationMethod),
                attribute: {
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.AUTHORIZATION_METHOD'),
                options: authorizationMethods
            }
        },
        {
            props: {
                type: 'select',
                htmlFor: 'tenderTypeDetail[subTenant]',
                fieldName: 'tenderTypeDetail[subTenant]',
                data: getIdOrUndefined(tenderTypeDetail.subTenant),
                attribute: {
                    disabled: isEditMode || isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.SUB-TENANT'),
                options: subTenants
            }
        },
        {
            props: {
                type: 'select',
                htmlFor: 'tenderTypeDetail[tenderClass]',
                fieldName: 'tenderTypeDetail[tenderClass]',
                data: getCodeOrUndefined(tenderTypeDetail.tenderClass),
                attribute: {
                    disabled: isViewMode || !getIdOrUndefined(tenderTypeDetail.subTenant)
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.TENDER_CLASS'),
                options: tenderClasses
            }
        },
        {
            props: {
                type: 'toogle',
                htmlFor: 'tenderTypeDetail[isActive]',
                fieldName: 'tenderTypeDetail[isActive]',
                data: tenderTypeDetail.isActive ? 1 : 0,
                attribute: {
                    checked: tenderTypeDetail.isActive,
                    disabled: isViewMode,
                    toogleLabel: t('COMMON.ACTIVE')
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.STATUS'),
            },
            className: "form-check"
        },
        {
            props: {
                type: 'checkbox',
                htmlFor: 'tenderTypeDetail[isUnitCountRequired]',
                fieldName: 'tenderTypeDetail[isUnitCountRequired]',
                data: tenderTypeDetail.isUnitCountRequired ? 1 : 0,
                attribute: {
                    checked: tenderTypeDetail.isUnitCountRequired,
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.UNIT_COUNT_REQUIRED'),
            },
            className: "form-check"
        }
    ];

    const tenderAmountColumns = [
        {
            props: {
                type: 'currency',
                htmlFor: 'tenderTypeDetail[changeThresholdAmount]',
                fieldName: 'tenderTypeDetail[changeThresholdAmount]',
                data: tenderTypeDetail.changeThresholdAmount,
                attribute: {
                    disabled: isViewMode,
                    min: 0
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.CHANGE_THRESHOLD_AMOUNT')
            }
        },
        {
            props: {
                type: 'currency',
                htmlFor: 'tenderTypeDetail[refundThresholdAmount]',
                fieldName: 'tenderTypeDetail[refundThresholdAmount]',
                data: tenderTypeDetail.refundThresholdAmount,
                attribute: {
                    disabled: isViewMode,
                    min: 0
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.REFUND_THRESHOLD_AMOUNT')
            }
        },
        {
            props: {
                type: 'currency',
                htmlFor: 'tenderTypeDetail[managerRefundThresholdAmount]',
                fieldName: 'tenderTypeDetail[managerRefundThresholdAmount]',
                data: tenderTypeDetail.managerRefundThresholdAmount,
                attribute: {
                    disabled: isViewMode,
                    min: 0
                },
                fieldLabel: <span className="overflow-text" title={t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.MANAGER_REFUND_THRESHOLD_AMOUNT')}>{t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.MANAGER_REFUND_THRESHOLD_AMOUNT')}</span>
            }
        },
        {
            props: {
                type: 'currency',
                htmlFor: 'tenderTypeDetail[minimumAmount]',
                fieldName: 'tenderTypeDetail[minimumAmount]',
                data: tenderTypeDetail.minimumAmount,
                attribute: {
                    disabled: isViewMode,
                    min: 0
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.MINIMUM_AMOUNT')
            }
        },
        {
            props: {
                type: 'currency',
                htmlFor: 'tenderTypeDetail[maximumAmount]',
                fieldName: 'tenderTypeDetail[maximumAmount]',
                data: tenderTypeDetail.maximumAmount,
                attribute: {
                    disabled: isViewMode,
                    min: 0
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.MAXIMUM_AMOUNT')
            }
        },
        {
            props: {
                type: 'currency',
                htmlFor: 'tenderTypeDetail[maximumSmartCardAmount]',
                fieldName: 'tenderTypeDetail[maximumSmartCardAmount]',
                data: tenderTypeDetail.maximumSmartCardAmount,
                attribute: {
                    disabled: isViewMode,
                    min: 0
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.MAXIMUM_SMART_CARD_AMOUNT')
            }
        },
        {
            props: {
                type: 'checkbox',
                htmlFor: 'tenderTypeDetail[isOpenCashDrawerRequired]',
                fieldName: 'tenderTypeDetail[isOpenCashDrawerRequired]',
                data: tenderTypeDetail.isOpenCashDrawerRequired ? 1 : 0,
                attribute: {
                    checked: tenderTypeDetail.isOpenCashDrawerRequired,
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.OPEN_CASH_DRAWER_REQUIRED'),
            },
            className: "form-check"
        }
    ]
    return (
        <>
                <section>
                    <div className="add-edit-tender-type">
                        <div className="title-content mb-3">
                            {!isViewMode && !isEditMode ? t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.TITLE_ADD') : null}
                            {isEditMode ? t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.TITLE_EDIT') : null}
                            {isViewMode ? t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.TITLE_VIEW') : null}
                        </div>
                        <div className="member">
                            <div className="member-main member-main--custom">
                                {isLoading ? <Loading /> :
                                    <div className="content">
                                        <div className="collapse-promotion collapse-saas">
                                            <button className={'collapsible'}>{t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.GENERAL_INFO')}</button>
                                            <div className={'content open'} style={{ maxHeight: '100%' }}>
                                                <div className="row">
                                                    {generalInfoColumns.map((field, key) =>
                                                        <div key={key} className="col-lg-4 col-md-6 mt-3">
                                                            <FormItem
                                                                {...field.props}
                                                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                                                fields={fields}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <button className={'collapsible mt-3'}>{t('PAGE.SYSTEM.TENDER_TYPE.ADD_EDIT_VIEW.TENDER_AMOUNT_DETAILS')} {currency && <span className="item-count">({currency})</span>}</button>
                                            <div className={'content open mb-3'} style={{ maxHeight: '100%' }}>
                                                <div className="row">
                                                    {tenderAmountColumns.map((field, key) =>
                                                        <div key={key} className="col-lg-4 col-md-6 mt-3">
                                                            <FormItem
                                                                {...field.props}
                                                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                                                fields={fields}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group-btn">
                                            {!isViewMode && <button disabled={_this.state.disabled} className="btn btn-primary" onClick={() => _this.onSubmit()}>{t('BUTTON.SAVE')} </button>}
                                            <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.CANCEL')} </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
};

export default template;
