import React from 'react';
import Loading from 'components/Loading';

import { DisplayDateFormat } from 'util/ControlUtils';

import ThisInterface from './interface';
import RequestFreezeMembership from './RequestFreezeMembership';
import FreezeMembershipSummary from './FreezeMembershipSummary';

const ButtonItem = ({
    type = 'primary',
    disabled = false,
    title,
    onClick = () => {}
}) => {
    return (
        <li>
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const renderButtonNextFinishAction = (_this: ThisInterface) => {
    const { disabledFinish, section } = _this.state;
    if (disabledFinish) {
        return () => {};
    }
    return section === 'freeze'
        ? _this.handleMoveSection('summary')
        : _this.handleSubmitFreeze();
};

const renderContent = (_this: ThisInterface) => {
    const { section, freezeData, requestFreeze, freezeMember, isModify } = _this.state;
    const { memberData, t, dateFormat } = _this.props;
    switch (section) {
        case 'freeze':
            return (
                <RequestFreezeMembership
                    isModify={isModify}
                    memberData={memberData}
                    freezeData={freezeData}
                    freezeMember={freezeMember}
                    dateFormat={dateFormat}
                    t={t!}
                />
            );
        case 'summary':
            return (
                <FreezeMembershipSummary
                    memberData={memberData}
                    requestFreeze={requestFreeze!}
                    t={t!}
                />
            );
        default:
            return <></>;
    }
};

const template = (_this: ThisInterface) => {
    const { isLoading, section, disabledFinish, isModify, freezeData } = _this.state;
    const { t, dateFormat } = _this.props;

    return isLoading ? (
        <Loading />
    ) : (
        <div className="freeze-membership">
            <div className="row">
                <div className=" col-12">
                    <div className="section-down">
                        <h3 className="text-title mb-3">
                            {t!(`PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.${isModify ? 'MODIFY_FREEZE' : 'NEW_FREEZE'}`)}
                        </h3>
                        {freezeData && freezeData.freezeNoticeEndDate && 
							(
							    <p>
							        {t('PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_NOTICE',
							            { endDate: DisplayDateFormat(freezeData.freezeNoticeEndDate, dateFormat) }
							        )}
							    </p>
							)}
                        {renderContent(_this)}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-4">
                    <ul className="list-btn-action">
                        {section === 'summary' && (
                            <ButtonItem
                                onClick={() => _this.handleMoveSection('freeze')}
                                title={t!('BUTTON.PREVIOUS')}
                            />
                        )}
                        <ButtonItem
                            onClick={() => renderButtonNextFinishAction(_this)}
                            title={t!(section === 'freeze' ? 'BUTTON.NEXT' : 'BUTTON.FINISH')}
                            disabled={section === 'summary' && disabledFinish}
                        />
                        <ButtonItem
                            onClick={_this.props.onCancel}
                            title={t!('BUTTON.CANCEL')}
                            type="dark"
                        />
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default template;
