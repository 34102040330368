import React, { useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { t } from 'util/I18nMessages';

interface PagePropsInterface {
    fullName: string;
    newPassword: string;
};

const NewPasswordContent = React.memo<PagePropsInterface>((props) => {
    const [copiedClipboard, setCopiedClipboard] = useState(false);
    return <>
        <p className="title">{t('PAGE.RESET_PASSWORD.NEW_PASSWORD_OF', { fullname: props.fullName })}</p>
        <div className="new-pass text-center" id="new-pass">
            <CopyToClipboard text={props.newPassword}
                onCopy={() => setCopiedClipboard(true)}>
                <span className="code-pass">{props.newPassword}<i className="icon icon-duplicated"></i></span>
            </CopyToClipboard>
            {copiedClipboard ? <p className="confirm text-primary">{t('PAGE.RESET_PASSWORD.COPIED_CLIPBOARD')}</p> : null}
        </div>
        <p className="info-new-pass">{t('PAGE.RESET_PASSWORD.NEW_PASSWORD_DESCRIPTION')}</p>
    </>
});
NewPasswordContent.displayName = 'NewPasswordContent';

export default NewPasswordContent;