import React from 'react';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { withRouter, RouteComponentProps } from 'react-router';

import * as RouteLoader from 'pages/RouteLoader';
import { submitModal, errorModal, confirmModal } from 'components/CommonModal';
import commonSevice from 'services/common.service';
import FieldModel from 'models/FieldConfig';
import ChangePaymentDetailModel from 'models/ChangePaymentDetail';
import * as DefaultConstants from 'constants/DefaultConstants';
import * as API from 'constants/Constants';
import observer from 'util/Observer';
import { withTranslation, WithTranslation } from 'react-i18next';
import ThisInterface, { Steps, PaymentMethods } from './interface';
import { Template } from './template';
import {
    getInitialValidationState,
    validatePaymentDetails,
    isAllFieldsValid,
    validateField,
    validateNationalIdDetails
} from './common';

class ChangePaymentDetail extends React.Component<
ThisInterface['props'] & WithTranslation & RouteComponentProps,
ThisInterface['state']
> {
    commonService: commonSevice;
    constructor(props: ThisInterface['props'] & WithTranslation & RouteComponentProps) {
        super(props);
        const {
            memberData: { email }
        } = props;
        this.state = {
            paymentDetails: new ChangePaymentDetailModel(),
            basePaymentDetails: new ChangePaymentDetailModel(),
            planPaymentMethods: [],
            step: Steps.Form,
            banks: [],
            fields: [],
            isLoading: true,
            communicationEmail: {
                email,
                emailError: false
            },
            disabledNext: false,
            disabledFinish: false,
            validations: getInitialValidationState(),
            isShowCreditCardModify: false
        };
        this.commonService = new commonSevice();
    }

    componentDidMount() {
        this.getField();
        observer.subscribe('communicationEmailChanged', data => {
            if (data) {
                this.setState({
                    communicationEmail: {
                        email: data.email,
                        emailError: data.error
                    },
                    disabledFinish: data.email !== '' && data.error
                });
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('communicationEmailChanged');
    }

    getField = () => {
        const { subTenantId } = this.props.memberData;

        Promise.all([
            this.commonService.fetchData(API.API_MBS_GET_BANK, {
                rightName: RouteLoader.ViewChangePaymentDetail.rightName,
                subTenantId
            }),
            this.commonService.fetchData(
                API.API_MBS_GET_FIELDS_ADD_MEMBER.replace('{0}', '1000'),
                {
                    rightName: RouteLoader.ViewChangePaymentDetail.rightName,
                    subTenantId
                }
            )
        ])
            .then(([resBanks, resFields]) => {
                if (resBanks && resFields) {
                    const fields: FieldModel[] = [];
                    if (!_.isEmpty(resFields.data)) {
                        const nationalId: FieldModel = resFields.data.find(
                                item => item.key === 'addMember.nationalId.NationalId'
                            ),
                            otherNationalId: FieldModel = resFields.data.find(
                                item => item.key === 'addMember.nationalId.Alternativeid'
                            );
                        fields.push(nationalId, otherNationalId);
                    }

                    this.setState(
                        {
                            banks: resBanks.data,
                            fields
                        },
                        () => {
                            this.getPaymentDetail();
                        }
                    );
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    getPaymentDetail = () => {
        const {
            match: { params },
            memberData: { planId }
        } = this.props;
        if (params['id'] && planId) {
            const apiGetPaymentMethod = API.API_MBS_GET_PAYMENT_METHOD.replace(
                    '{id}',
                    planId
                ),
                apiGetPaymentDetail = API.API_MBS_GET_PAYMENT_DETAIL.replace(
                    '{id}',
                    params['id']
                );
            this.callApiWithCallback(apiGetPaymentMethod, resMethod => {
                if (resMethod && !_.isEmpty(resMethod.data)) {
                    this.callApiWithCallback(apiGetPaymentDetail, resDetail => {
                        if (resDetail) {
                            const paymentDetails = {
                                ...resDetail,
                                primary: {
                                    ...resDetail.primary,
                                    paymentMethod:
                                        resMethod.data.length > 1
                                            ? resDetail.primary.paymentMethod
                                            : resMethod.data[0]
                                }
                            };
                            this.setState({
                                paymentDetails,
                                basePaymentDetails: paymentDetails,
                                planPaymentMethods: resMethod.data || [],
                                isLoading: false
                            });
                        }
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        } else {
            this.setState({
                isLoading: false
            });
        }
    };

    callApiWithCallback = (api: string, callback: Function) => {
        this.commonService
            .fetchData(api, {
                rightName: RouteLoader.ViewChangePaymentDetail.rightName
            })
            .then(res => {
                callback(res);
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    paymentDetails: new ChangePaymentDetailModel()
                });
            });
    };

    handlePaymentMethodChanged = (methodId: string) => {
        const { paymentDetails: { primary }, basePaymentDetails: { primary: basePrimary } } = this.state;
        const selectedMethod = this.state.planPaymentMethods.find(method => method.id === methodId);
        if (!selectedMethod) return;
        if (selectedMethod.id !== primary.paymentMethod.id) {
            const updatedData = selectedMethod.id === basePrimary.paymentMethod.id ? basePrimary : {
                ...new ChangePaymentDetailModel()['primary'],
                paymentMethod: selectedMethod,
                bank: this.state.banks[0],
                cardType: basePrimary.cardType,
                nationalId: basePrimary.nationalId,
                otherNationalId: basePrimary.otherNationalId,
                id: basePrimary.id
            };
            this.setState({
                paymentDetails: {
                    ...this.state.paymentDetails,
                    primary: updatedData
                },
                validations: getInitialValidationState(),
                isShowCreditCardModify: false,
                disabledNext: !isAllFieldsValid(getInitialValidationState())
            });
        }
    };

    handleFieldChanged = (key: string, value: any) => {
        const { primary } = this.state.paymentDetails;
        const updatedData = {
            ...primary,
            [key]: value
        };
        const validations = validateField(key, this.state.validations, updatedData);
        this.setState({
            paymentDetails: {
                ...this.state.paymentDetails,
                primary: updatedData
            },
            validations,
            disabledNext: !isAllFieldsValid(validations)
        });
    };

    handleChangeToogle = () => {
        this.setState({
            isShowCreditCardModify: !this.state.isShowCreditCardModify
        }, () => {
            if (this.state.isShowCreditCardModify) {
                const validations = validatePaymentDetails(
                    this.state.paymentDetails.primary,
                    this.state.validations
                );
                this.setState({
                    validations,
                    disabledNext: !isAllFieldsValid(validations)
                });
            }
        });
    };

    review = () => {
        const { t } = this.props;
        const {
            paymentDetails,
            basePaymentDetails: { primary: basePrimary }
        } = this.state;
        const validations = validatePaymentDetails(
            this.state.paymentDetails.primary,
            this.state.validations
        );
        const isFormValid = isAllFieldsValid(validations);
        if (!isFormValid) {
            return this.setState({
                validations,
                disabledNext: true,
                isShowCreditCardModify: paymentDetails.primary.paymentMethod.name === PaymentMethods.Credit ? true : this.state.isShowCreditCardModify
            });
        }
        const nationalIdDetailsValidation = validateNationalIdDetails(paymentDetails.primary, basePrimary, this.state.fields) as any;
        if (nationalIdDetailsValidation.isDirty) {
            const customMessage = nationalIdDetailsValidation.customMsg.key ? t(nationalIdDetailsValidation.customMsg.value, {...nationalIdDetailsValidation.customMsg.key}) : nationalIdDetailsValidation
            errorModal(customMessage);
            return this.setState({
                disabledNext: true
            });
        }
        if (!paymentDetails.primary.nationalId && !paymentDetails.primary.otherNationalId) {
            confirmModal('MSG.MEMBERSHIPS.CHANGE_PAYMENT_DETAIL.CONFIRM_NATIONAL_ID', () => {
                const {
                    paymentDetails: { primary },
                    basePaymentDetails: { primary: basePrimary }
                } = this.state;
                if (basePrimary.nationalId || basePrimary.otherNationalId) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            paymentDetails: {
                                ...prevState.paymentDetails,
                                primary: {
                                    ...primary,
                                    nationalId: primary.nationalId || basePrimary.nationalId,
                                    otherNationalId:
                                        primary.otherNationalId || basePrimary.otherNationalId
                                }
                            },
                            step: Steps.Review
                        };
                    });
                }
            });
        } else {
            this.setState({
                step: Steps.Review
            });
        }
    };

    viewForm = () => {
        this.setState({
            step: Steps.Form
        });
    };

    finish = () => {
        const {
            match: { params },
            memberData: { subTenantId },
            onCancel,
            t
        } = this.props;
        const {
            communicationEmail: { email, emailError },
            paymentDetails: { primary }
        } = this.state;
        if (params['id'] && primary) {
            if (email === '' && emailError) {
                errorModal('MSG.MEMBERSHIPS.CHANGE_PAYMENT_DETAIL.WRONG_FORMAT_EMAIL_OR_EMPTY');
                return;
            } else {
                const data = {
                    id: primary.id,
                    methodId: primary.paymentMethod.id,
                    cardTypeId: (primary.cardType || {}).id || null,
                    bankId: (primary.bank || {}).id || null,
                    accountName: primary.accountName,
                    cardNumber: primary.cardNumber,
                    expiryDate: primary.expiryDate,
                    issueNumber: primary.issueNumber,
                    nationalId: primary.nationalId,
                    otherNationalId: primary.otherNationalId,
                    email
                };
                submitModal('MSG.MEMBERSHIPS.CHANGE_PAYMENT_DETAIL.CONFIRM_SAVE_INFORMATION', () => {
                    this.commonService
                        .putData(
                            API.API_MBS_GET_PAYMENT_DETAIL.replace('{id}', params['id']),
                            data,
                            {
                                rightName: RouteLoader.ViewChangePaymentDetail.rightName,
                                subTenantId
                            }
                        )
                        .then(res => {
                            if (res) {
                                Swal.fire({
                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                    type: 'success',
                                    html: t('MSG.SYSTEM.USERS.ADD_USER.SAVE_SUCCESS'),
                                    confirmButtonText: t('BUTTON.CLOSE')
                                }).then(() => onCancel());
                            }
                        });
                });
            }
        }
    };

    render = () => <Template {...this} />;
}

export default withRouter(withTranslation()(ChangePaymentDetail));
