import React from 'react';
import moment from 'moment';
import * as RouteLoader from 'pages/RouteLoader';
import * as Utils from 'util/ControlUtils';
import { t } from 'util/I18nMessages';

import { Actions, DropdownContent } from '../common';
import ThisInterface from '../interface';

const getMinusWidth = () => {
    const width = window.innerWidth;
    if (width > 1440) {
        return 46;
    }
    return 41;
};

const getStaticProgress = (progress) => {
    if (window.innerWidth <= 1440) {
        return progress <= 10 ? 10 : progress;
    }
    return progress <= 6 ? 6 : progress;
}

const ProgressContent = ({
    wrapperClass,
    className = '',
    title,
    value,
    progress,
    dateFormat
}) => {
    return (
        <span
            className={`${wrapperClass} ${className}`}
            style={{ left: progress }}
        >
            <span className={`${wrapperClass}-title`}>
                {t(title)}
            </span>
            <span className={`${wrapperClass}-value`}>
                {Utils.DisplayDateFormat(value, dateFormat)}
            </span>
        </span>
    )
}

const ChargeContent = ({ title, value }) => {
    return (
        <div className="content-wrapper">
            <div className="charge-title">{title}:</div>
            <div
                className="charge-value"
                dangerouslySetInnerHTML={{ __html: value }}
            />
        </div>
    );
};

const isAllowedChangeMembership = memberData => {
    const hasClaim = !!Utils.isHasRight(
        RouteLoader.ChangeMembershipRoute.rightName
    );

    return (
        memberData.paymentStatus &&
		memberData.paymentStatus.code === 'OK' &&
		hasClaim
    );
};

const getProgress = (startDate: string, monthsContract: number) => {
    const timeBeginContract = new Date(startDate);
    const timeEndContract = moment(timeBeginContract)
        .add(monthsContract, 'months')
        .toDate()
        .getTime();
    const lastVisitTime = new Date(moment(startDate).add(20, 'days').toDate()).getTime();
    const debtTime = new Date(moment(startDate).add(23, 'days').toDate()).getTime();

    return {
        currentVisitProgress:
			(lastVisitTime - timeBeginContract.getTime()) /
			(timeEndContract - timeBeginContract.getTime()),
        debtDateProgress:
			(debtTime - timeBeginContract.getTime()) /
			(timeEndContract - timeBeginContract.getTime())
    };
};

const MemberAccount = (_this: ThisInterface, previousSection: string = '') => {
    const {
        t,
        match: { params }
    } = _this.props;
    const { memberData, dateFormat, memberAccountOpen } = _this.state;
    const {
        futureEvents,
        events,
        subTenantId,
        monthsContract,
        currentBalance,
        lastCharge,
        nextCharge,
        startedDate
    } = memberData;
    const { currentVisitProgress, debtDateProgress } = getProgress(
        startedDate,
        monthsContract
    );
    const progressVisit = parseFloat((currentVisitProgress * 100).toFixed(2));
    const debtProgress = parseFloat((debtDateProgress * 100).toFixed(2));
    const isOk = memberData.paymentStatus.code === 'OK';

    const actions = [
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_MEMBERSHIP',
            isVisible:
				isAllowedChangeMembership(memberData) &&
				Utils.isNotHasFutureEvent(futureEvents, events),
            to: {
                pathname: `/memberships/members/${params['id']}/edit`,
                state: { subTenantId: subTenantId, previousSection: '' }
            }
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_PAYMENT_DETAILS',
            isVisible: !!Utils.isHasRight(
                RouteLoader.ViewChangePaymentDetail.rightName
            ),
            onClick: () =>
                _this.handleChangeSection('payment-detail', previousSection)
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT',
            onClick: () =>
                _this.handleChangeSection('account-detail', previousSection)
        },
        {
            hasAction: true,
            className: 'action',
            onClick: () => _this.handleOpenAction('memberAccount'),
            element: (
                <DropdownContent
                    t={t}
                    isVisible={memberAccountOpen}
                    actions={[
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.ADD_MESSAGE'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.ADD_NOTE'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.PRINT_MEMBER_DETAILS'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.RETURN_DEPOSIT'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.VIEW_BILLING_HISTORY'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.REMOVE_PRICE_CHANGE'
                        }
                    ]}
                />
            )
        }
    ];

    const currentDate = Utils.DisplayDateFormat(new Date(), dateFormat);
    const dateAfterMonth = moment(new Date())
        .add(1, 'months')
        .format(dateFormat);

    return (
        <div className="member-account-summary">
            {memberData.paymentStatus.code === 'OK' && (
                <div className="current-balance row">
                    <p className="col-12">{t('PAGE.MEMBERSHIPS.VIEW.CURRENT_BALANCE')}</p>
                    <div className="current-value col-12">
                        <Utils.NumberMoneyFormat amount={`$${currentBalance}.00`} />
                    </div>
                </div>
            )}
            <div className={`charge-content ${isOk ? 'has-current-balance' : ''}`}>
                <ChargeContent
                    title={t('PAGE.MEMBERSHIPS.VIEW.LAST_CHARGE')}
                    value={`$${lastCharge}.00 <span>${t('COMMON.ON')}</span> ${currentDate}`}
                />
                <ChargeContent
                    title={t('PAGE.MEMBERSHIPS.VIEW.NEXT_CHARGE')}
                    value={`$${nextCharge}.00 <span>${t('COMMON.ON')}</span> ${dateAfterMonth}`}
                />
            </div>
            <div className="overview-content">
                <div className="summary-header">
                    <Actions actions={actions} t={t} ref={_this.memberAccountRef} />
                </div>
                <div className="contact-right">
                    <div className="start-date">
                        <div className="title">
                            {t('PAGE.MEMBERSHIPS.VIEW.CONTRACT_START_DATE')}
                        </div>
                        <div className="value">
                            {Utils.DisplayDateFormat(startedDate, dateFormat)}
                        </div>
                    </div>
                    <div className="progress-wrapper">
                        <ProgressContent
                            wrapperClass="last-visit"
                            title="PAGE.MEMBERSHIPS.VIEW.LAST_VISIT"
                            value={moment(startedDate).add(20, 'days')}
                            progress={`calc(${getStaticProgress(progressVisit)}% - ${getMinusWidth()}px)`}
                            dateFormat={dateFormat}
                        />
                        <ProgressContent
                            wrapperClass="debt-start-date"
                            title="PAGE.MEMBERSHIPS.VIEW.DEBT_START_DATE"
                            value={moment(startedDate).add(23, 'days')}
                            progress={`calc(${getStaticProgress(debtProgress)}% - 42px)`}
                            dateFormat={dateFormat}
                        />
                        <div className="progress">
                            <div
                                className="progress-bar"
                                style={{ width: `${progressVisit}%` }}
                            />
                        </div>
                    </div>
                    <div className="date-mcp">
                        <div className="contract-month">
                            <div className="no">{monthsContract >= 10 ? monthsContract : `0${monthsContract}`}</div>
                            <div className="contract-text">
                                {t('PAGE.MEMBERSHIPS.VIEW.MONTHS_CONTRACT')}
                            </div>
                        </div>
                        <div className="date-mcp-content">
                            <div className="text">
                                {t('PAGE.MEMBERSHIPS.VIEW.DATE_WITH_MCP')}
                            </div>
                            <div className="date">
                                {moment(startedDate)
                                    .add(monthsContract, 'months')
                                    .format(dateFormat)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberAccount;
