import * as Route from 'pages/RouteLoader';

interface SideNavItemInterface {
    key: string;
    icon: string;
    idText: string;
    rightName?: string;
    sub: {
        url?: string;
        icon: string;
        idText: string;
        rightName?: string;
        key?: string;
        sub?: {
            url: string;
            icon: string;
            idText: string;
            rightName?: string;
            key?: string;
            sub?: {
                url: string;
                icon: string;
                idText: string;
                rightName?: string;
                key?: string;
            }[];
        }[];
    }[];
}

export const SideNavItem: SideNavItemInterface[] = [
    {
        key: 'memberships',
        icon: 'icon icon-membership-ico',
        idText: 'NAV.MEMBERSHIP_MANAGEMENT',
        sub: [
            {
                url: '/' + Route.MemberListRoute.url,
                icon: 'icon icon-membership-ico',
                idText: 'NAV.MEMBERSHIP_MANAGEMENT.VIEW_SEARCH',
                rightName: Route.MemberListRoute.rightName
            },
            {
                url: '/' + Route.AddNewMemberRoute.url,
                icon: 'icon icon-add-member-icon',
                idText: 'NAV.MEMBERSHIP_MANAGEMENT.ADD_MEMBERS',
                rightName: Route.AddNewMemberRoute.rightName
            },
        ]
    },
    {
        key: 'clubs',
        icon: 'icon icon-clubs',
        idText: 'NAV.CLUBS',
        sub: [
            {
                key: 'clubs-view',
                url: '/' + Route.ClubListPageRoute.url,
                icon: 'icon icon-view-n-search',
                idText: 'NAV.CLUBS.VIEW_SEARCH',
                rightName: Route.ClubListPageRoute.rightName
            },
        ]
    },
    {
        key: 'products',
        icon: 'icon icon-debt',
        idText: 'NAV.PRODUCTS',
        sub: [
            {
                key: 'items',
                icon: 'icon icon-items',
                idText: 'NAV.PRODUCTS.ITEMS',
                sub: [
                    {
                        url: '/' + Route.ItemRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.ITEMS.VIEW_SEARCH',
                        rightName: Route.ItemRoute.rightName
                    },
                    {
                        url: '/' + Route.AddFeeRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.ITEMS.ADD_FEE',
                        rightName: Route.AddFeeRoute.rightName
                    },
                ]
            },
            {
                key: 'plans',
                icon: 'icon icon-plan',
                idText: 'NAV.PRODUCTS.PLAN',
                sub: [
                    {
                        url: '/' + Route.PlanRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.PLAN.VIEW_SEARCH',
                        rightName: Route.PlanRoute.rightName
                    },
                ]
            },
            {
                key: 'packages',
                icon: 'icon icon-packages',
                idText: 'NAV.PRODUCTS.PACKAGES',
                sub: [
                    {
                        url: '/' + Route.PackageRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.PACKAGES.VIEW_SEARCH',
                        rightName: Route.PackageRoute.rightName
                    },
                ]
            },
            {
                key: 'addons',
                icon: 'icon icon-promotion',
                idText: 'NAV.PRODUCTS.ADDONS',
                sub: [
                    {
                        url: '/' + Route.AddonListRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.ADDONS.VIEW_SEARCH',
                        rightName: Route.AddonListRoute.rightName
                    },
                    {
                        url: '/' + Route.AddAddonRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.ADDONS.ADD_ADDON',
                        rightName: Route.AddAddonRoute.rightName
                    },
                ]
            },
            {
                key: 'promotion',
                icon: 'icon icon-promotion',
                idText: 'NAV.PRODUCTS.PROMOTION',
                sub: [
                    {
                        url: '/' + Route.PromotionRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.PROMOTION.VIEW_SEARCH',
                        rightName: Route.PromotionRoute.rightName
                    },
                    {
                        url: '/' + Route.AddPromotionRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.PRODUCTS.PROMOTION.ADD_PROMOTION',
                        rightName: Route.AddPromotionRoute.rightName
                    },
                ]
            },
        ]
    },
    {
        key: 'system',
        icon: 'icon icon-system',
        idText: 'NAV.SYSTEM',
        sub: [
            {
                key: 'operators',
                icon: 'icon icon-operators',
                idText: 'NAV.SYSTEM.USERS',
                sub: [
                    {
                        url: '/' + Route.UserListRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.USERS.VIEW_SEARCH',
                        rightName: Route.UserListRoute.rightName
                    },
                    {
                        url: '/' + Route.AddUserRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.USERS.ADD_USER',
                        rightName: Route.AddUserRoute.rightName
                    },
                ]
            },
            {
                key: 'role',
                icon: 'icon icon-role',
                idText: 'NAV.SYSTEM.ROLES',
                sub: [
                    {
                        url: '/' + Route.RoleListRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.ROLES.VIEW_SEARCH',
                        rightName: Route.RoleListRoute.rightName
                    },
                    {
                        url: '/' + Route.AddRoleRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.ROLES.ADD_ROLE',
                        rightName: Route.AddRoleRoute.rightName
                    }
                ]
            },
            {
                key: 'user-group',
                icon: 'icon icon-user-group',
                idText: 'NAV.SYSTEM.USER_GROUP',
                sub: [
                    {
                        url: '/' + Route.UserGroupListRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.USER_GROUP.VIEW_SEARCH',
                        rightName: Route.UserGroupListRoute.rightName
                    },
                    {
                        url: '/' + Route.AddUserGroupRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.USER_GROUP.ADD_USER_GROUP',
                        rightName: Route.AddUserGroupRoute.rightName
                    }
                ]
            },
            {
                key: 'tender-class',
                icon: 'icon icon-invoice-list',
                idText: 'NAV.SYSTEM.TENDER_CLASSES',
                sub: [
                    {
                        url: '/' + Route.TenderClassRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.TENDER_CLASSES.VIEW_SEARCH',
                        rightName: Route.TenderClassRoute.rightName
                    },
                    {
                        url: '/' + Route.AddTenderClassRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.TENDER_CLASSES.ADD_TENDER_CLASS',
                        rightName: Route.AddTenderClassRoute.rightName
                    },
                ]
            },
            {
                key: 'tender-type',
                icon: 'icon icon-user-group',
                idText: 'NAV.SYSTEM.TENDER_TYPES',
                sub: [
                    {
                        url: '/' + Route.TenderTypesRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.TENDER_TYPES.VIEW_SEARCH',
                        rightName: Route.TenderTypesRoute.rightName
                    },
                    {
                        url: '/' + Route.AddTenderTypeRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.TENDER_TYPES.ADD_TENDER_TYPE',
                        rightName: Route.AddTenderTypeRoute.rightName
                    },
                ]
            },
            {
                key: 'lookup',
                icon: 'icon icon-authorisan',
                idText: 'NAV.SYSTEM.LOOKUPS',
                sub: [
                    {
                        url: '/' + Route.LookUpsRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.LOOKUPS.VIEW_SEARCH',
                        rightName: Route.LookUpsRoute.rightName
                    },
                    {
                        url: '/' + Route.AddLookUpRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.SYSTEM.LOOKUPS.ADD_LOOKUPS',
                        rightName: Route.AddLookUpRoute.rightName
                    }
                ]
            },
            {
                key: 'fields-config',
                url: '/' + Route.AddFieldsConfigRoute.url,
                icon: 'icon icon-operators',
                idText: 'NAV.SYSTEM.ADD_MEMBER_CONFIGURATION',
                rightName: Route.AddFieldsConfigRoute.rightName
            },
            {
                key: 'build-version',
                url: '/' + Route.ServiceVersionRoute.url,
                icon: 'icon icon-authorisan',
                idText: 'NAV.SYSTEM.SERVICE_VERSION',
                rightName: Route.ServiceVersionRoute.rightName
            }
        ]
    },
    {
        key: 'config',
        icon: 'icon icon-config-ico',
        idText: 'NAV.CONFIGURATION_MANAGEMENT',
        sub: [
            {
                key: 'system-config',
                icon: 'icon icon-system-config',
                idText: 'NAV.CONFIGURATION_MANAGEMENT.SYSTEM_CONFIG',
                sub: [
                    {
                        url: '/' + Route.AppearanceSettingRoute.url,
                        icon: 'icon icon icon-layout-ico',
                        idText: 'NAV.CONFIGURATION_MANAGEMENT.SYSTEM_CONFIG.APPEARANCE_SETTING',
                        rightName: Route.AppearanceSettingRoute.rightName
                    }
                ]
            },
            {
                key: 'support-tool',
                icon: 'icon icon-support-tool',
                idText: 'NAV.CONFIGURATION_MANAGEMENT.SUPPORT_TOOLS',
                sub: [
                    {
                        url: '/' + Route.ImportMemberRoute.url,
                        icon: 'icon icon-global-ico',
                        idText: 'NAV.CONFIGURATION_MANAGEMENT.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT',
                        rightName: Route.ImportMemberRoute.rightName
                    },
                ]
            },
            {
                key: 'club',
                icon: 'icon icon-clubs',
                idText: 'NAV.CONFIGURATION_MANAGEMENT.CLUBS',
                sub: [
                    {
                        url: '/' + Route.CMClubsPageRoute.url,
                        icon: 'icon icon-view-n-search',
                        idText: 'NAV.CONFIGURATION_MANAGEMENT.CLUBS.VIEW_SEARCH',
                        rightName: Route.CMClubsPageRoute.rightName
                    },
                    {
                        url: '/' + Route.CMAddClubPageRoute.url,
                        key: 'club-details',
                        icon: 'icon icon-detail',
                        idText: 'NAV.CONFIGURATION_MANAGEMENT.CLUBS.ADD_CLUB',
                        rightName: Route.CMAddClubPageRoute.rightName
                    },
                    
                ]
            },
        ]
    }
]
