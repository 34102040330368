// System Configs
export const ADDMEMBER_ADD_NEW_DATE_FORMAT = 'date.format';
export const ADDMEMBER_ADD_NEW_DOB_FORMAT = 'mem.addnew.dob';

export const ADDMEMBER_BIRTH_DATE_STEP_1 = 'addMember.dateOfBirth.step1';

export const ADDMEMBER_TITLE = 'addMember.title';
export const ADDMEMBER_GENDER = 'addMember.gender';
export const ADDMEMBER_FIRST_NAME = 'addMember.firstName';
export const ADDMEMBER_LAST_NAME = 'addMember.lastName';
export const ADDMEMBER_BIRTH_DATE_STEP_3 = 'addMember.dateOfBirth.step3';
export const ADDMEMBER_PARENTAL_CONSENT_PROVIDED = 'addMember.parentalConsentProvided';
export const ADDMEMBER_NATIONAL = 'addMember.nationalId';
export const ADDMEMBER_NATIONAL_ID = 'addMember.nationalId.NationalId';
export const ADDMEMBER_OTHER_NATIONAL_ID = 'addMember.nationalId.Alternativeid';
export const ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES = 'addMember.memberLanguagePreferences';
export const ADDMEMBER_TELEPHONE_NUMBER = 'addMember.telephoneNumber';
export const ADDMEMBER_MOBILE_TELEPHONE = 'addMember.telephoneNumber.mobileTelephone';
export const ADDMEMBER_HOME_TELEPHONE = 'addMember.telephoneNumber.homeTelephone';
export const ADDMEMBER_WORK_TELEPHONE = 'addMember.telephoneNumber.workTelephone';
export const ADDMEMBER_EMAIL_ADDRESS = 'addMember.emailAddress';
export const ADDMEMBER_OVERRIDE_EMAIL = 'addMember.overrideEmail';
export const ADDMEMBER_NUMBER = 'addMember.number/Name';
export const ADDMEMBER_ADDRESS_LINE_1 = 'addMember.address.Line1';
export const ADDMEMBER_ADDRESS_LINE_2 = 'addMember.address.Line2';
export const ADDMEMBER_ADDRESS_CITY = 'addMember.address.City';
export const ADDMEMBER_ADDRESS_STATE = 'addMember.address.State';
export const ADDMEMBER_ADDRESS_POSTCODE = 'addMember.address.Postcode';
export const ADDMEMBER_ADDRESS_COUNTRY = 'addMember.address.Country';
export const ADDMEMBER_GONE_AWAY = 'addMember.goneAway';
export const ADDMEMBER_MEMBERSHIP_APPLICATION_REF = 'addMember.applicationRef';
export const ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED = 'addMember.memberHappyToBeContact';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD = 'addMember.preferredContactMethod';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_POST = 'addMember.preferredContactMethod.Post';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE = 'addMember.preferredContactMethod.HomeTelephone';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE = 'addMember.preferredContactMethod.WorkTelephone';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE = 'addMember.preferredContactMethod.MobileTelephone';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS = 'addMember.PreferredContactMethod.Sms';
export const ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL = 'addMember.preferredContactMethod.Email';
export const ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED_BY_THIRD_PARTY = 'addMember.memberIsHappyToBeContactedByAThirdParty';
export const ADDMEMBER_MARKETING_SOURCE = 'addMember.source';
export const ADDMEMBER_EMERGENCY_CONTACT_NAME = 'addMember.emergencyContactName';
export const ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER = 'addMember.emergencyContactPhoneNumber';
export const ADDMEMBER_COMPANY_NAME = 'addMember.companyName';
export const ADDMEMBER_OCCUPATION = 'addMember.occupation';
export const ADDMEMBER_REFERRED_BY_NAME = 'addMember.referredByName';
export const ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER = 'addMember.referredByMembershipNumber';
export const ADDMEMBER_OTHER_COMMISSION_NOTE = 'addMember.otherCommissionNote';
export const ADDMEMBER_GENERAL_NOTE = 'addMember.generalNote';
export const ADDMEMBER_AIA_VITALITY_CATEGORY = 'addMember.aiaVitalityCategory';
export const ADDMEMBER_MEMBER_IS_TAX_EXEMPT = 'addMember.memberIsTaxExempt';
export const ADDMEMBER_RECURRING_CARD_TYPE = 'addMember.recurringCardType';