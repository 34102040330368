import React from 'react';
import _ from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import Chip from '@material-ui/core/Chip';
import { SubTenantRoleModel, OptionModel } from 'models/Role';

export const ToolTipItem = ({ tooltipText }: { tooltipText: string }) => {
    return (
        <Tooltip title={tooltipText} className="tooltipText infoIcon">
            <InfoIcon />
        </Tooltip>
    );
};

export const TagList = ({
    selectedSuggestions,
    emptyTagMessage,
    onTagDeleted
}) => {
    return _.isEmpty(selectedSuggestions) ? (
        <div className={`tag-container centerText`}>
            <span>{emptyTagMessage}</span>
        </div>
    ) : (
        <div className="tag-container">
            {selectedSuggestions.map(tag => {
                return (
                    <Chip
                        key={tag.id}
                        label={tag.name}
                        onDelete={() => onTagDeleted(tag.id)}
                        className="tag-item"
                    />
                );
            })}
        </div>
    );
};

const getSuggestions = (
    selectedSuggestions: SubTenantRoleModel[],
    name: string,
    firstKeyId: string,
    secondKeyId: string
) => {
    const array = selectedSuggestions;
    const rootId =
    secondKeyId !== '' ? `${firstKeyId}-${secondKeyId}` : firstKeyId;
    if (selectedSuggestions.some(item => item.id === rootId)) {
        return {
            suggestionList: array,
            isDuplicate: true
        };
    }
    array.push({
        name,
        id: rootId,
        firstKeyId,
        secondKeyId
    });
    return {
        suggestionList: array,
        isDuplicate: false
    };
};

export const updateSelectedSuggestions = (
    suggestionList: SubTenantRoleModel[],
    showSecondSelect: boolean,
    selectedFirstSelect: OptionModel,
    selectedSecondSelect: OptionModel
) => {
    const selectedSuggestions = suggestionList.map(item => {
        return SubTenantRoleModel.clone(item);
    });
    if (!showSecondSelect) {
        return selectedFirstSelect
            ? getSuggestions(
                selectedSuggestions,
                selectedFirstSelect.label,
                selectedFirstSelect.value,
                ''
            )
            : {
                suggestionList: [],
                isDuplicate: false
            };
    }
    return selectedFirstSelect && selectedSecondSelect
        ? getSuggestions(
            selectedSuggestions,
            `${selectedFirstSelect.label} - ${selectedSecondSelect.label}`,
            selectedFirstSelect.value,
            selectedSecondSelect.value
        )
        : {
            suggestionList: [],
            isDuplicate: false
        };
};

export const getSortingString = (filterObject: string) => {
    let sortingString = '';

    try {
        const filterObjParse = JSON.parse(filterObject);
        if (filterObjParse && filterObjParse.and) {
            filterObjParse.and.forEach(item => {
                if (item.queryValue.includes('{value}')) {
                    sortingString = `&sort=${item.queryKey}=asc`;
                }
            });
        }
    } catch {
        sortingString = '';
    }

    return sortingString;
};
