import React from 'react';
import EmailSummary from 'pages/MembershipManagement/MemberSummaryPage/EmailSummary';
import { withTranslation, WithTranslation } from 'react-i18next';
import ThisInterface from './interface';
import { renderFields } from './common';

class ChangePaymentDetailSummary extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);
        this.state = {
            showFullAccountNumber: false
        };
    }

    showFullAccountNumberChanged = () => {
        this.setState(prevState => {
            return {
                showFullAccountNumber: !prevState.showFullAccountNumber
            };
        });
    };

    render() {
        const { memberData } = this.props;
        return (
            <React.Fragment>
                <div className="payment-method">
                    <div className="section-content">
                        {renderFields(this)}
                        <EmailSummary memberData={memberData} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ChangePaymentDetailSummary)