import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import ThisInterface from './interface';

class ChangeSummary extends React.Component<ThisInterface["props"] & WithTranslation, ThisInterface["state"]> {
    constructor(props: ThisInterface["props"] & WithTranslation) {
        super(props)

        this.state = {
            openDropdown: {
                changeMemberSummaryInformation: true,
                newMemberSummaryInformation: true,
                oldMemberSummaryInformation: true,
                paymentMethodSummaryInformation: true,
                memberPaymentSummaryInformation: true,
            }
        }
    }
    handleOpenDropdown = (key) => {
        const { openDropdown } = this.state;
        openDropdown[key] = !this.state.openDropdown[key];
        this.setState({
            openDropdown
        })
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(ChangeSummary);