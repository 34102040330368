import React from 'react';
import * as RouteLoader from 'pages/RouteLoader';
import { getIframeAPIURL, isHasRight, getParamFromUrl } from 'util/ControlUtils';
import { IFRAME_ITEM_LIST } from 'constants/Constants';
import Loading from 'components/Loading';
import embededWrapper from 'hocs/Embeded';
import ThisInterface from './interface';

class MFItemsPage extends React.Component<ThisInterface['props'], {}> {
    componentDidMount = () => {
        window.addEventListener("message", this.messageHandler, false);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data;
        
        switch (action) {
            case 'returnHeightData': 
                const obj: any = this.refs.iframe;
                if (obj) {
                    obj.style.height = value + 'px';
                }
                break;
            case 'redirectURL':
                if(value.includes('/Products/Items/Item.aspx')) {
                    const item = getParamFromUrl(value, 'ItemId');
                    item && this.props.history.push(`/${RouteLoader.ViewFeeRoute.url.replace(':id', item.value)}`);
                }
                break;
        }
    }

    permission = () => {
        const obj: any = this.refs.iframe;

        const hasEditFeeRight = isHasRight(RouteLoader.EditFeeRoute.rightName);
        if(hasEditFeeRight) {
            obj.contentWindow.postMessage({
                action: 'hasEditFeeRight'
            }, '*');
        }
    }

    render() {
        const { iframeLoaded, iframeToken, subTenantId } = this.props;

        return <div>
            { subTenantId && <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && <iframe src={`${getIframeAPIURL(IFRAME_ITEM_LIST, subTenantId, '/MembershipManagement')}?token=${iframeToken}`}
                            ref='iframe'
                            onLoad={() => this.props.resizeIframe(this.refs.iframe, this.permission)}
                            className="iframe"
                            frameBorder="0"/>}
                    </div>
                </div>
            </div>
            }
        </div>
    }
}

export default embededWrapper(MFItemsPage, 'ItemRoute', 'PAGE.PRODUCTS.ITEMS.TITLE', 'PAGE.PRODUCTS.ITEMS.INSTRUCTION');
