import axios from 'axios';
import * as API from 'constants/Constants';
import CommonService from './common.service';
import { getTenantId } from 'util/ControlUtils';

export default class UserService {
    commonService: CommonService;
    constructor() {
        this.commonService = new CommonService();
    }


    updateUserProfile(userid: string, userInfo) {
        const headers = this.commonService.getHeader(false);
        const url = API.API_UPDATE_OPERATOR_INFO.replace('{0}', userid);
        const formData = {
            ...userInfo,
            email: userInfo.email ? userInfo.email : null
        };
        const data = JSON.stringify(formData);
        return axios
            .put(url, data, { headers })
            .then(() => formData)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateUserAvatar(userid: string, avt: string) {
        const headers = this.commonService.getHeader();
        const url = API.API_UPDATE_OPERATOR_AVATAR.replace('{0}', userid);
        const data = { base64Image: avt };
        return axios
            .put(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRoleTypes() {
        const headers = this.commonService.getHeader();
        const url = API.API_RESOURCES;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getResourceByType(id) {
        const headers = this.commonService.getHeader();
        const url = API.API_RESOURCE_BY_ID;
        return axios
            .get(url.replace('{0}', id), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getClubGroupByTenant(filter) {
        const headers = this.commonService.getHeader();
        const url = API.API_SEARCH_CLUBGROUP;
        const tenantId = getTenantId();
        return axios
            .get(url.replace('{0}', tenantId).replace('{1}', filter), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    createRole(data) {
        const headers = this.commonService.getHeader();
        const url = API.API_ROLES;
        return axios
            .post(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSystemConfigs(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-tenant-id"] = getTenantId();
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_GET_SYSTEM_CONFIGS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
}
