import React from 'react';
import moment from 'moment';
import {
    PersonalDetailsModel,
    ContactDetailsModel,
    AddressDetailsModel,
    ContactedDetailsModel,
    EmergencyContactModel,
    HealthQuestionModel,
    OtherFieldsModel,
    LookupModel
} from 'models/PersonalDetails';
import * as Route from 'pages/RouteLoader';
import observer from 'util/Observer';
import * as Utils from 'util/ControlUtils';
import { isFieldRequired } from 'util/FieldConfiguration';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';

import template from './template';
import ThisInterface from './interface';
import { fieldErrorMessage, getErrorIfAny, getContactMethodErrorIfAny } from './common';
import {
    validPersonalDetail,
    validAddressDetail,
    validOtherField,
    validContactedDetail,
    contactMethodErrorStrings
} from './fieldCommon';
import { withTranslation, WithTranslation } from 'react-i18next';

const isResponseValid = res => {
    return res.data && res.data.length > 0;
};

class PersonalDetails extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation ) {
        super(props);

        this.state = {
            isLoading: true,
            isServiceError: false,
            errors: {},
            openDropdown: {
                personalDetails: true,
                contactDetails: false,
                addressDetails: false,
                contactedDetails: false,
                emergencyContact: false,
                healthQuestion: false,
                otherFields: false
            },
            personalDetails: new PersonalDetailsModel(),
            contactDetails: new ContactDetailsModel(),
            addressDetails: new AddressDetailsModel(),
            contactedDetails: new ContactedDetailsModel(),
            emergencyContact: new EmergencyContactModel(),
            healthQuestions: [],
            otherFields: new OtherFieldsModel(),
            titles: [],
            genders: [],
            languages: [],
            overrideEmailOptions: [],
            countries: [],
            marketingSources: [],
            contactMethods: [],
            questions: [],
            aiaVitalityCategories: [],
            recurringCardTypes: []
        };
    }

    componentDidMount() {
        const {
            membershipService,
            dataSource: {
                subTenantId,
                personalDetails,
                contactDetails,
                addressDetails,
                contactedDetails,
                emergencyContact,
                healthQuestions,
                otherFields
            },
            t
        } = this.props;

        const questions = this.getHealthQuestions();

        const rightName = Route.ChangePersonalDetailsRoute.rightName;

        Promise.all([
            membershipService.getMemberTitles(subTenantId, rightName),
            membershipService.getMemberGenders(subTenantId, rightName),
            membershipService.getMemberLanguagePreferences(subTenantId, rightName),
            membershipService.getOverrideEmailOptions(subTenantId, rightName),
            membershipService.getCountries(subTenantId, rightName),
            membershipService.getContactMethods(subTenantId, rightName),
            membershipService.getMarketingSources(subTenantId, rightName),
            membershipService.getAIAVitalityCategories(subTenantId, rightName),
            membershipService.getRecurringCardTypes(subTenantId, rightName)
        ])
            .then(
                ([
                    titleResponse,
                    genderResponse,
                    languageResponse,
                    emailOptionsResponse,
                    countryResponse,
                    contactMethodResponse,
                    marketingSourceResponse,
                    aiaVitalityCategoryResponse,
                    recurringCardTypeResponse
                ]) => {
                    //set default selected item for dropdown
                    const defaultOption: LookupModel = {
                        value: '',
                        label: 'COMMON.PLEASE_SELECT',
                        id: '',
                        name: '',
                        key: '',
                        code: ''
                    };

                    const titles = this.getOptionList(titleResponse);
                    const genders = this.getOptionList(genderResponse);
                    const languages = this.getOptionList(languageResponse);
                    const countries = this.getOptionList(countryResponse);
                    const overrideEmailOptions = this.getOptionList(emailOptionsResponse);
                    const marketingSources = this.getOptionList(marketingSourceResponse);
                    const aiaVitalityCategories = this.getOptionList(
                        aiaVitalityCategoryResponse
                    );
                    const recurringCardTypes = this.getOptionList(
                        recurringCardTypeResponse
                    );

                    let emailOption: LookupModel;
                    const lang =
						languages.length === 0
						    ? new LookupModel()
						    : languages.find(
						        l => l.id === personalDetails.preferedLanguage.id
						    );
                    const cat =
						aiaVitalityCategories.length === 0
						    ? new LookupModel()
						    : aiaVitalityCategories.find(
						        cat => cat.id === otherFields.aiaVitalityCategory.id
						    );
                    const country =
						countries.length === 0
						    ? new LookupModel()
						    : countries.find(l => l.id === addressDetails.country.id);
                    const cardType =
						recurringCardTypes.length === 0
						    ? new LookupModel()
						    : recurringCardTypes.find(
						        card => card.id === otherFields.recurringCardType.id
						    );

                    if (titles.length > 0) {
                        titles.unshift(defaultOption);
                        const title = titles.find(t => t.id === personalDetails.title.id);
                        personalDetails.title = title || titles[0];
                    }

                    if (genders.length > 0) {
                        genders.unshift(defaultOption);
                        const gender = genders.find(
                            g => g.id === personalDetails.gender.id
                        );
                        personalDetails.gender = gender || genders[0];
                    }

                    personalDetails.preferedLanguage = lang || languages[0];

                    if (overrideEmailOptions.length === 0) {
                        emailOption = new LookupModel();
                    } else {
                        const found =
							overrideEmailOptions.find(
							    op => op.id === contactDetails.overrideEmailOption.id
							) || overrideEmailOptions.find(op => op.code === 'EM');
                        emailOption = found || overrideEmailOptions[0];
                    }

                    contactDetails.overrideEmailOption = emailOption;
                    addressDetails.country = country || countries[0];

                    if (marketingSources.length > 0) {
                        marketingSources.unshift(defaultOption);
                        const source = marketingSources.find(
                            l => l.id === contactedDetails.marketingSource.id
                        );
                        contactedDetails.marketingSource = source || marketingSources[0];
                    }

                    otherFields.aiaVitalityCategory = cat || aiaVitalityCategories[0];
                    otherFields.recurringCardType = cardType || recurringCardTypes[0];

                    this.setState({
                        isLoading: false,
                        isServiceError: false,
                        personalDetails,
                        contactDetails,
                        addressDetails,
                        contactedDetails,
                        emergencyContact,
                        healthQuestions,
                        otherFields,
                        titles,
                        genders,
                        languages,
                        overrideEmailOptions,
                        countries,
                        contactMethods: contactMethodResponse.data,
                        marketingSources,
                        questions,
                        aiaVitalityCategories,
                        recurringCardTypes
                    });
                }
            )
            .catch(() => {
                this.setState({
                    isLoading: false,
                    isServiceError: true,
                    personalDetails,
                    contactDetails,
                    addressDetails,
                    contactedDetails,
                    emergencyContact,
                    healthQuestions,
                    otherFields
                });
            });
    }

    getOptionList = list => {
        const { t } = this.props;
        return isResponseValid(list) ?
			    list.data.map(e => ({
			        value: e.id,
			        label: e.name,//t({ id: e.key || 'DEFAULT_FALLBACK', defaultMessage: e.name }),
			        id: e.id,
			        name: e.name, //t({id: e.key || 'DEFAULT_FALLBACK',defaultMessage: e.name }),
			        key: e.key,
			        code: e.code
			    })) : [];
    };

    setErrorsIfAny = errors => {
        if (Object.keys(errors).length > 0) {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        ...errors
                    }
                };
            });

            return false;
        }
        return true;
    };

    isValidFields = (validArray, fieldConfig, dataDetail) => {
        let errors = {};

        validArray.forEach(item => {
            errors = getErrorIfAny(
                fieldConfig,
                dataDetail,
                item.configKey,
                item.detailKey
            );
        });

        return this.setErrorsIfAny(errors);
    };

    //#region Handle events
    handleOpenDropdown = (key: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                openDropdown: {
                    ...prevState.openDropdown[key],
                    [key]: !prevState.openDropdown[key]
                }
            };
        });
    };

    handleNext() {
        if (this.state.isServiceError) return;
        let isReady = this.isReadyToNextStep();
        if (!isReady) return;

        const {
            personalDetails,
            contactDetails,
            addressDetails,
            contactedDetails,
            emergencyContact,
            healthQuestions,
            otherFields
        } = this.state;

        this.props.handleNext({
            ...this.props.dataSource,
            personalDetails,
            contactDetails,
            addressDetails,
            contactedDetails,
            emergencyContact,
            healthQuestions,
            otherFields
        });
    }

    onInputChange = (key: string, value: string) => {
        observer.publish('flagUnsavedChangeEvent', true);
        switch (key) {
            case 'firstName':
            case 'lastName':
            case 'nationalIdNumber':
            case 'otherNationalIdNumber':
            case 'dateOfBirth':
                this.populatePersonalDetailsChange(key, value);
                break;
            case 'email':
                this.populateContactDetailsChange(key, value);
                break;
            case 'addressNumber':
            case 'addressLine1':
            case 'addressLine2':
            case 'city':
            case 'state':
            case 'postCode':
                this.populateAddressDetailsChange(key, value);
                break;
            case 'applicationRef':
                this.populateContactedDetailsChange(key, value);
                break;
            case 'contactName':
                this.populateEmergencyContactChange(key, value);
                break;
            case 'companyName':
            case 'occupation':
            case 'referredByMemberName':
            case 'referredByMembershipNumber':
            case 'otherCommissionNote':
            case 'generalNote':
                this.populateOtherFieldsChange(key, value);
                break;
            default:
                break;
        }
    };

    onSelectOptionChange = (key: string, value) => {
        observer.publish('flagUnsavedChangeEvent', true);
        switch (key) {
            case 'title':
            case 'gender':
            case 'preferedLanguage':
                this.populatePersonalDetailsChange(key, value);
                break;
            case 'overrideEmailOption':
                this.populateContactDetailsChange(key, value);
                break;
            case 'country':
                this.populateAddressDetailsChange(key, value);
                break;
            case 'marketingSource':
                this.populateContactedDetailsChange(key, value);
                break;
            case 'aiaVitalityCategory':
            case 'recurringCardType':
                this.populateOtherFieldsChange(key, value);
                break;
            default:
                break;
        }
    };

    onCheckboxChange = (key: string, checked: boolean, value) => {
        observer.publish('flagUnsavedChangeEvent', true);
        switch (key) {
            case 'isParentalConsentProvided':
                this.populatePersonalDetailsChange(key, checked);
                break;
            case 'mobilePhoneNoLongerUse':
            case 'homePhoneNoLongerUse':
            case 'workPhoneNoLongerUse':
            case 'emailNoLongerUse':
                this.populateContactDetailsChange(key, checked);
                break;
            case 'goneAway':
                this.populateAddressDetailsChange(key, checked);
                break;
            case 'memberHappyToBeContact':
            case 'thirdPartyContact':
                this.populateContactedDetailsChange(key, checked);
                break;
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL:
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS:
                this.populatePreferredContactMethods(key, checked, value || '');
                break;
            case 'answeredQuestion':
            case 'lastQuestion':
                this.populateHealthQuestionsChange(key, checked, value || '');
                break;
            default:
                break;
        }
    };

    onPhoneNumberChange = (
        key: string,
        isValid: boolean,
        phoneNumber: string,
        countryCode: string
    ) => {
        observer.publish('flagUnsavedChangeEvent', true);
        switch (key) {
            case 'emergencyPhone':
                this.populateEmergencyPhoneNumberChange(
                    key,
                    isValid,
                    phoneNumber,
                    countryCode
                );
                break;
            case 'mobilePhone':
            case 'homePhone':
            case 'workPhone':
                this.populateContactPhoneNumberChange(
                    key,
                    isValid,
                    phoneNumber,
                    countryCode
                );
                break;
            default:
                break;
        }
    };

    //#endregion Handle events

    //#region Personal details
    populatePersonalDetailsChange(key: string, value) {
        let { personalDetailsFieldConfigs } = this.props.fieldConfigs;
        const errorMessage = fieldErrorMessage(
            personalDetailsFieldConfigs,
            key,
            value
        );

        if (errorMessage !== false) {
            this.setState(prevState => {
                return {
                    personalDetails: {
                        ...prevState.personalDetails,
                        [key]: key === 'dateOfBirth'
                            ? value
                                ? new Date(
                                    Utils.DisplayDateFormat(
                                        value,
                                        GENERAL_DATABASE_DATE_FORMAT
                                    )
                                )
                                : null
                            : value
                    },
                    errors: {
                        ...prevState.errors,
                        [key]: errorMessage
                    }
                };
            });
        }
    }

    isPersonalDetailsValid() {
        const { t } = this.props;
        let errors = {};
        const {
            dateFormat,
            fieldConfigs: {
                personalDetailsFieldConfigs: { nationalConfig, dateOfBirthConfig }
            }
        } = this.props;
        const { personalDetails } = this.state;
        const isParentalConsentRequired =
			moment(personalDetails.dateOfBirth).isValid() &&
			moment().diff(personalDetails.dateOfBirth, 'years') < 18;

        validPersonalDetail.forEach(item => {
            errors = getErrorIfAny(
                this.props.fieldConfigs.personalDetailsFieldConfigs,
                personalDetails,
                item.configKey,
                item.detailKey,
                errors
            );
        });

        if (
            isFieldRequired(nationalConfig) &&
			!personalDetails.nationalIdNumber &&
			!personalDetails.otherNationalIdNumber
        ) {
            errors = {
                ...errors,
                nationalIdNumber: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NATIONAL_ID_NUMBER_REQUIRED')
            };
        }

        if (isFieldRequired(dateOfBirthConfig) && !personalDetails.dateOfBirth) {
            errors = {
                ...errors,
                dateOfBirth: t('COMMON.FIELD_REQUIRED')
            };
        } else if (
            personalDetails.dateOfBirth &&
      		!Utils.IsValidDate(personalDetails.dateOfBirth, dateFormat)
        ) {
            errors = {
                ...errors,
                dateOfBirth: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.DATE_OF_BIRTH_INVALID')
            };
        }

        if (
            isParentalConsentRequired &&
      		!personalDetails.isParentalConsentProvided
        ) {
            errors = {
                ...errors,
                isParentalConsentProvided:
          'Parental Consent is required for member under 18 year old'
            };
        }

        return this.setErrorsIfAny(errors);
    }
    //#endregion

    //#region Contact Details
    populateContactDetailsChange(key: string, value) {
        const { t } = this.props;
        const { preferredContactMethod } = this.state.contactedDetails;
        let newContacts = preferredContactMethod;

        switch (key) {
            case 'email':
                const {
                    emailConfig
                } = this.props.fieldConfigs.contactDetailsFieldConfigs;
                let errorMessage = '';
                let isEmailValid = true;
                if (isFieldRequired(emailConfig) && !value)
                    errorMessage = errorMessage = t('COMMON.FIELD_REQUIRED');
                else {
                    isEmailValid = Utils.checkInvalidEmailField(value);
                    if (!isEmailValid)
                        errorMessage = errorMessage = t('PAGE.MEMBERSHIPS.CREATE.STEP_3.INVALID_EMAIL_ADDRESS');
                }
                if (!value)
                    newContacts = preferredContactMethod.filter(
                        cm => cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
                    );
                this.setState(prevState => {
                    return {
                        contactDetails: {
                            ...prevState.contactDetails,
                            email: value,
                            isEmailValid
                        },
                        contactedDetails: {
                            ...prevState.contactedDetails,
                            preferredContactMethod: newContacts
                        },
                        errors: {
                            ...prevState.errors,
                            email: errorMessage
                        }
                    };
                });

                break;
            case 'overrideEmailOption':
                let { email } = this.state.contactDetails;
                let { email: emailError } = this.state.errors;
                if (value.code !== 'EM') {
                    email = '';
                    emailError = '';
                    newContacts = preferredContactMethod.filter(
                        cm => cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL
                    );
                }
                this.setState(prevState => {
                    return {
                        contactDetails: {
                            ...prevState.contactDetails,
                            overrideEmailOption: value,
                            email
                        },
                        contactedDetails: {
                            ...prevState.contactedDetails,
                            preferredContactMethod: newContacts
                        },
                        errors: {
                            ...prevState.errors,
                            email: emailError
                        }
                    };
                });
                break;
            case 'mobilePhoneNoLongerUse':
            case 'homePhoneNoLongerUse':
            case 'workPhoneNoLongerUse':
            case 'emailNoLongerUse':
                this.setState(prevState => {
                    return {
                        contactDetails: {
                            ...prevState.contactDetails,
                            [key]: value
                        }
                    };
                });
                break;
            default:
                break;
        }
    }

    populateContactPhoneNumberChange = (
        key: string,
        isValid: boolean,
        phoneNumber: string,
        countryCode: string
    ) => {
        const { preferredContactMethod } = this.state.contactedDetails;
        const { t } = this.props;
        let error = {
            mobilePhone: '',
            homePhone: '',
            workPhone: ''
        };

        let newContacts = preferredContactMethod;
        switch (key) {
            case 'mobilePhone':
                if (!isValid && phoneNumber) {
                    error.mobilePhone = t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID');
                } else if (!phoneNumber) {
                    newContacts = preferredContactMethod.filter(
                        cm =>
                            cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS &&
							cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE
                    );
                }
                break;
            case 'homePhone':
                if (!isValid && phoneNumber) {
                    error.homePhone = t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID');
                } else if (!phoneNumber) {
                    newContacts = preferredContactMethod.filter(
                        cm =>
                            cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE
                    );
                }
                break;
            case 'workPhone':
                if (!isValid && phoneNumber) {
                    error.workPhone = t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID');
                } else if (!phoneNumber) {
                    newContacts = preferredContactMethod.filter(
                        cm =>
                            cm.key !== FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE
                    );
                }
                break;
            default:
                break;
        }

        this.setState(prevState => {
            return {
                contactDetails: {
                    ...prevState.contactDetails,
                    [key]: {
                        isValid,
                        phoneNumber,
                        countryCode
                    }
                },
                contactedDetails: {
                    ...prevState.contactedDetails,
                    preferredContactMethod: newContacts
                },
                errors: {
                    ...prevState.errors,
                    ...error
                }
            };
        });
    };

    isContactDetailsValid = () => {
        const { t } = this.props;
        const {
            email,
            mobilePhone,
            homePhone,
            workPhone,
            overrideEmailOption
        } = this.state.contactDetails;

        const {
            telephoneNumberConfig,
            emailConfig
        } = this.props.fieldConfigs.contactDetailsFieldConfigs;

        let errors = {};

        if (
            isFieldRequired(emailConfig) &&
			overrideEmailOption.code === 'EM' &&
			!email
        ) {
            errors = {
                ...errors,
                email: t('COMMON.FIELD_REQUIRED')
            };
        }

        if (
            isFieldRequired(telephoneNumberConfig) &&
			!mobilePhone.phoneNumber &&
			!homePhone.phoneNumber &&
			!workPhone.phoneNumber
        ) {
            errors = {
                ...errors,
                mobilePhone: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED'),
                homePhone: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED'),
                workPhone: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER.REQUIRED')
            };
        }

        return this.setErrorsIfAny(errors);
    };
    //#endregion

    //#region Address details
    populateAddressDetailsChange(key: string, value) {
        const { addressDetailsFieldConfigs } = this.props.fieldConfigs;

        const errorMessage = fieldErrorMessage(
            addressDetailsFieldConfigs,
            key,
            value
        );

        if (errorMessage !== false) {
            this.setState(prevState => {
                return {
                    addressDetails: {
                        ...prevState.addressDetails,
                        [key]: value
                    },
                    errors: {
                        ...prevState.errors,
                        [key]: errorMessage
                    }
                };
            });
        }
    }
    //#endregion

    //#region Contacted details
    populateContactedDetailsChange = (key: string, value) => {
        const { t } = this.props;
        let {
            applicationRefConfig
        } = this.props.fieldConfigs.contactedDetailsFieldConfigs;
        let isMatchRegex = true;
        let errorMessage = '';
        switch (key) {
            case 'applicationRef':
                isMatchRegex = Utils.isInputMatchRegex(
                    applicationRefConfig.regex,
                    value
                );
                if (isFieldRequired(applicationRefConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;

            default:
                break;
        }

        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                contactedDetails: {
                    ...prevState.contactedDetails,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    };

    populatePreferredContactMethods = (key: string, checked, value) => {
        const { contactDetails, contactedDetails } = this.state;
        const { t } = this.props;
        const {
            contactedDetailsFieldConfigs: { preferredContactMethodConfig }
        } = this.props.fieldConfigs;
        let isProvided = false;
        let errorMsg = '';

        switch (key) {
            case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:
                isProvided = true;
                break;
            default:
                contactMethodErrorStrings.forEach(item => {
                    if (item.key === key) {
                        const { errorMessage, isContactMethodProvided } =
							getContactMethodErrorIfAny(
							    contactDetails,
							    item.validKey,
							    item.emptyKey,
							    item.contactMethod
							);
                        isProvided = isContactMethodProvided;
                        errorMsg = errorMessage;
                    }
                });
                break;
        }

        if (isProvided) {
            let prevContacts = contactedDetails.preferredContactMethod;
            let newContacts = checked
                ? prevContacts.concat(value)
                : prevContacts.filter(cm => cm.id !== value.id);
            errorMsg =
				isFieldRequired(preferredContactMethodConfig) &&
				newContacts.length === 0
				    ? t('COMMON.FIELD_REQUIRED')
				    : '';
            this.setState(prevState => {
                return {
                    contactedDetails: {
                        ...prevState.contactedDetails,
                        preferredContactMethod: newContacts
                    },
                    errors: {
                        ...prevState.errors,
                        preferredContactMethod: errorMsg
                    }
                };
            });
        } else {
            this.setState(prevState => {
                return {
                    errors: {
                        ...prevState.errors,
                        preferredContactMethod: errorMsg
                    }
                };
            });
        }
    };
    //#endregion

    //#region Emergency contact
    populateEmergencyContactChange = (key: string, value) => {
        const { t } = this.props;
        let {
            contactNameConfig
        } = this.props.fieldConfigs.emergencyContactFieldsConfigs;
        let isMatchRegex = true;
        let errorMessage = '';

        switch (key) {
            case 'contactName':
                isMatchRegex = Utils.isInputMatchRegex(contactNameConfig.regex, value);
                if (isFieldRequired(contactNameConfig) && !value)
                    errorMessage = t('COMMON.FIELD_REQUIRED');
                break;

            default:
                break;
        }
        if (!isMatchRegex) return;
        this.setState(prevState => {
            return {
                emergencyContact: {
                    ...prevState.emergencyContact,
                    [key]: value
                },
                errors: {
                    ...prevState.errors,
                    [key]: errorMessage
                }
            };
        });
    };

    populateEmergencyPhoneNumberChange = (
        key: string,
        isValid: boolean,
        phoneNumber: string,
        countryCode: string
    ) => {
        const { t } = this.props;
        let {
            contactNumberConfig
        } = this.props.fieldConfigs.emergencyContactFieldsConfigs;
        let errorMessage = '';
        if (isFieldRequired(contactNumberConfig) && !phoneNumber) {
            errorMessage = t('COMMON.FIELD_REQUIRED');
        } else if (!isValid) {
            errorMessage = t('COMMON.FIELD_REQUIRED');
        }

        this.setState(prevState => {
            return {
                emergencyContact: {
                    ...prevState.emergencyContact,
                    contactNumber: {
                        isValid,
                        countryCode,
                        phoneNumber
                    }
                },
                errors: {
                    ...prevState.errors,
                    emergencyPhone: errorMessage
                }
            };
        });
    };

    isEmergencyContactValid = () => {
        let { contactName, contactNumber } = this.state.emergencyContact;
        const { t } = this.props;
        let {
            contactNameConfig,
            contactNumberConfig
        } = this.props.fieldConfigs.emergencyContactFieldsConfigs;

        let errors = {};
        if (isFieldRequired(contactNameConfig) && !contactName) {
            errors = {
                ...errors,
                contactName: t('COMMON.FIELD_REQUIRED')
            };
        }

        if (isFieldRequired(contactNumberConfig) && !contactNumber.phoneNumber) {
            errors = {
                ...errors,
                emergencyPhone: t('COMMON.FIELD_REQUIRED')
            };
        } else if (!contactNumber.isValid && contactNumber.phoneNumber) {
            errors = {
                ...errors,
                emergencyPhone: t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PHONE_NUMBER_INVALID')
            };
        }

        return this.setErrorsIfAny(errors);
    };
    //#endregion

    //#region Health question
    getHealthQuestions = () => {
    //todo: get data from Question module - TBD
        let questions = [
            {
                id: '1',
                name:
          'Has your doctor ever said that you have a heart condition and that you should only do physical activity recommended by a doctor?'
            },
            {
                id: '2',
                name: 'Do you feel pain in your chest when you do physical activity?'
            },
            {
                id: '3',
                name:
          'In the past month, have you had chest pain when you were not doing physical activity?'
            },
            {
                id: '4',
                name:
          'Do you lose your balance because of dizzinesss or do you ever lose consciousness?'
            },
            {
                id: '5',
                name:
          'Do you have a bone or joint problem that could be made worse by a change in your physical activity?'
            },
            {
                id: '6',
                name:
          'Is your doctor currently prescribing drugs for a heart condition or ones that affect your blood pressure?'
            },
            {
                id: '7',
                name:
          'Do you know o other reason why you should not increase your physical activity?'
            },
            {
                id: '8',
                name: 'Are all questions in the medical questionnaire complete?'
            }
        ];

        return questions;
    };

    populateHealthQuestionsChange = (
        key: string,
        checked: boolean,
        question?
    ) => {
        const { t } = this.props;
        let { healthQuestions } = this.state;
        let isLastQuestion = false;
        let errors = {};
        switch (key) {
            case 'answeredQuestion':
                isLastQuestion = false;
                break;
            case 'lastQuestion':
                isLastQuestion = true;
                errors = {
                    lastQuestion: checked
                        ? ''
                        : t('COMMON.FIELD_REQUIRED')
                };
                break;
            default:
                break;
        }

        let healthQuestion: HealthQuestionModel = {
            ...question,
            isLastQuestion
        };

        let newAnsweredQuestions = checked
            ? healthQuestions.concat(healthQuestion)
            : healthQuestions.filter(aq => aq.id !== healthQuestion.id);
        this.setState(prevState => {
            return {
                healthQuestions: newAnsweredQuestions,
                errors: {
                    ...prevState.errors,
                    ...errors
                }
            };
        });
    };

    isHealthQuestionsValid = () => {
        let { healthQuestions } = this.state;
        const { t } = this.props;
        let isValid = healthQuestions.some(q => q.isLastQuestion == true);

        let errors = {};
        if (!isValid) {
            errors = {
                ...errors,
                lastQuestion: t('COMMON.FIELD_REQUIRED')
            };
        }

        return this.setErrorsIfAny(errors);
    };
    //#endregion

    //#region Other fields
    populateOtherFieldsChange = (key: string, value) => {
        const { otherFieldsFieldsConfigs } = this.props.fieldConfigs;
		
        const errorMessage = fieldErrorMessage(
            otherFieldsFieldsConfigs,
            key,
            value
        );

        if (errorMessage !== false) {
            this.setState(prevState => {
                return {
                    otherFields: {
                        ...prevState.otherFields,
                        [key]: value
                    },
                    errors: {
                        ...prevState.errors,
                        [key]: errorMessage
                    }
                };
            });
        }
    };

    //#endregion

    isReadyToNextStep = () => {
        const {
            addressDetailsFieldConfigs,
            contactedDetailsFieldConfigs,
            otherFieldsFieldsConfigs
        } = this.props.fieldConfigs;
        const { addressDetails, contactedDetails, otherFields } = this.state;

        const isPersonalDetailsValid = this.isPersonalDetailsValid();
        const isContactDetailsValid = this.isContactDetailsValid();
        const isAddressDetailsValid = this.isValidFields(
            validAddressDetail,
            addressDetailsFieldConfigs,
            addressDetails
        );
        const isContactedDetailsValid = this.isValidFields(
            validContactedDetail,
            contactedDetailsFieldConfigs,
            contactedDetails
        );
        const isEmergencyContactValid = this.isEmergencyContactValid();
        const isHealthQuestionsValid = this.isHealthQuestionsValid();
        const isOtherFieldsValid = this.isValidFields(
            validOtherField,
            otherFieldsFieldsConfigs,
            otherFields
        );

        this.setState({
            openDropdown: {
                personalDetails: !isPersonalDetailsValid,
                contactDetails: !isContactDetailsValid,
                addressDetails: !isAddressDetailsValid,
                contactedDetails: !isContactedDetailsValid,
                emergencyContact: !isEmergencyContactValid,
                healthQuestion: !isHealthQuestionsValid,
                otherFields: !isOtherFieldsValid
            }
        });

        return (
            isPersonalDetailsValid &&
			isContactDetailsValid &&
			isAddressDetailsValid &&
			isContactedDetailsValid &&
			isEmergencyContactValid &&
			isHealthQuestionsValid &&
			isOtherFieldsValid
        );
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(PersonalDetails);
