import React from 'react';
import * as RouteLoader from 'pages/RouteLoader';
import * as Utils from 'util/ControlUtils';
import { Descriptions, DropdownContent, Actions } from '../common';
import ThisInterface from '../interface';

const MemberShipDetail = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { memberShipOpen, memberData } = _this.state;
    const {
        futureEvents,
        events,
        isAllowCancel,
        salesPerson,
        club,
        plan,
        withinMBG,
        addons
    } = memberData;

    const isAllowCancelMembership = (isAllowCancel: boolean) => {
        return (
            isAllowCancel &&
      		!!Utils.isHasRight(RouteLoader.CancelMembershipRoute.rightName)
        );
    };

    const actions = [
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.FREEZE_MEMBERSHIP',
            isVisible:
				_this.isAllowedFreeze() &&
				Utils.isNotHasFutureEvent(futureEvents, events),
            onClick: () => _this.handleChangeSection('freeze-membership')
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.REQUEST_CANCELLATION',
            isVisible:
				isAllowCancelMembership(isAllowCancel) &&
				Utils.isNotHasFutureEvent(futureEvents, events),
            onClick: () => _this.handleChangeSection('cancel-membership')
        },
        {
            hasAction: true,
            className: 'action',
            onClick: () => _this.handleOpenAction('memberShip'),
            element: (
                <DropdownContent
                    t={t}
                    isVisible={memberShipOpen}
                    actions={[
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.RENEW_MEMBERSHIP'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.RENEW_CORPORATE_MEMBERSHIP'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_PRICE_CHANGE_STATUS'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.ADD_MEMBER_ADD-ON'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_PROMOTION'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.MEMBER_DOCUMENTS'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.MEMBER_TO_NON_MEMBER_TRANSFER'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.MOVE_TO_CORPORATE_MEMBERSHIP'
                        }
                    ]}
                />
            )
        }
    ];

    let descriptionList = [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.SALE_PERSON', className: 'col-5' },
            value: {
                text: t('PAGE.MEMBERSHIPS.VIEW.SOLE_BY_SALE_PERSON_IN_CLUB', {salePerson: (salesPerson || {}).name || '', club: (club || {}).name || ''}),
                className: 'col-7'
            },
            className: 'row'
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.PACKAGE', className: 'col-5' },
            value: { text: memberData.package.name, className: 'col-7' },
            className: 'row'
        },
        {
            name: {
                text: 'PAGE.MEMBERSHIPS.CREATE.SUMMARY.PLAN',
                className: 'col-5'
            },
            value: { text: plan.name, className: 'col-7' },
            className: 'row'
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.WITHIN_MBG', className: 'col-5' },
            value: { text: withinMBG ? 'Yes' : 'No', className: 'col-7' },
            className: 'row'
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.REMAINING_CCV', className: 'col-5' },
            value: { text: '$123,456.00', className: 'col-7' },
            className: 'row'
        }
    ];

    if (addons.length) {
        descriptionList = [...descriptionList, {
            name: { text: 'PAGE.MEMBERSHIPS.CREATE.SUMMARY.ADDONS', className: 'col-5' },
            value: { text: addons.reduce((acc, cur) => [...acc, cur.name], [] as string[]).join(', '), className: 'col-7' },
            className: 'row'
        }];
    }
	
    const notOk = memberData.paymentStatus.code !== 'OK';

    return (
        <div className="membership-detail-summary">
            <div className="summary-header">
                <p onClick={() => _this.handleChangeSection('membership-detail')}>
                    {t('PAGE.MEMBERSHIPS.VIEW.MEMBERSHIP')}
                </p>
                <Actions ref={_this.memberShipRef} actions={actions} t={t} />
            </div>
            <div className="summary-detail row">
                <div className={`access-right col-xl-${notOk ? '4' : '3'} col-lg-12 row`}>
                    <div className="right-wrapper col-xl-12 col-lg-5 col-md-5">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.TIER')}</p>
                        <div className="right-name">
                            {memberData.package.packageTier.name}
                        </div>
                    </div>
                    <div className="right-wrapper col-xl-12 col-lg-5 col-md-5">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.ACCESS')}</p>
                        <div className="right-name">
                            {memberData.package.packageTimeGroup.name}
                        </div>
                    </div>
                </div>
                <div className="detail col-xl-8 col-lg-12">
                    <Descriptions descriptionList={descriptionList} t={t} />
                </div>
            </div>
        </div>
    );
};

export default MemberShipDetail;
