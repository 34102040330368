import React from 'react';
import * as Interface from 'interfaces';
import Select from './Select';
import { useTranslation } from 'react-i18next';

interface FormSelectProps extends Interface.InputInterface {
    options: {
        label: string;
        value: React.ReactNode;
    }[];
    defaultValue?: string | number;
    isClearable?: boolean;
    searchIcon?: boolean;
    isDirty?: boolean;
    isLoading?: boolean;
    onInputChange?: (value: string) => void;
}

const FormSelect: React.FunctionComponent<FormSelectProps> = ({
    label,
    id,
    name,
    options,
    value = null,
    onChange,
    disabled = false,
    defaultValue,
    require = false,
    className = '',
    labelClass = '',
    inputClass = '',
    isClearable = false,
    searchIcon = false,
    isDirty,
    errorMsg = '',
    onInputChange = null,
    isLoading = false,
}) => {
    const { t } = useTranslation();
    return (
        <form className={`form-group ${className} form-select`}>
            {label && (
                <label className={labelClass}>
                    {label}
                    {require && <span className="text-primary bold">&nbsp;*</span>}
                </label>
            )}
            <Select
                className={inputClass}
                options={options ? options.map(item => ({...item, label: t(item.label)})) : options}
                name={name}
                id={id}
                value={value ? { ...value, label: t(value.label) } : value}
                onChange={onChange}
                defaultValue={defaultValue}
                isDisabled={disabled}
                placeholder={isLoading ? t('COMMON.LOADING') : t('COMMON.SELECT') }
                isClearable={isClearable}
                searchIcon={searchIcon}
                errorMsg={errorMsg}
                isDirty={isDirty}
                onInputChange={onInputChange!}
                isLoading={isLoading}
            />
        </form>
    );
};

FormSelect.displayName = 'FormSelect';

export default FormSelect;
