import { GENERAL_DATE_FORMAT } from 'constants/Constants';

export const InitialFieldConfigs = {    
    "data": [
        {
            "key": "addMember.title",
            "name": "Title",
            "isRequired": true,
            "isVisible": true,
            "regex": "",
            "maxLength": 0
        },
        {
            "key": "addMember.gender",
            "name": "Gender",
            "isRequired": true,
            "isVisible": true,
            "regex": "",
            "maxLength": 50
        },
        {
            "key": "addMember.firstName",
            "name": "First Name",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[A-Za-z\\s-]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.lastName",
            "name": "Last Name",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[A-Za-z\\s-]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.dateOfBirth.step3",
            "name": "Date of Birth",
            "isRequired": true,
            "isVisible": true,
            "regex": GENERAL_DATE_FORMAT,
            "maxLength": 0
        },
        {
            "key": "addMember.parentalConsentProvided",
            "name": "Parental consent provided",
            "isRequired": true,
            "isVisible": true,
            "regex": "",
            "maxLength": 0
        },
        {
            "key": "addMember.nationalId",
            "name": "National Number",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9]+$",
            "maxLength": 12
        },
        {
            "key": "addMember.nationalId.NationalId",
            "name": "National Id",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9]+$",
            "maxLength": 12
        },
        {
            "key": "addMember.nationalId.Alternativeid",
            "name": "Other National Id",
            "isRequired": false,
            "isVisible": true,
            "regex": "^\\d+$",
            "maxLength": 15
        },
        {
            "key": "addMember.memberLanguagePreferences",
            "name": "Member Language Preferences",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.telephoneNumber",
            "name": "Telephone Number",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.telephoneNumber.mobileTelephone",
            "name": "Mobile Telephone",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.telephoneNumber.homeTelephone",
            "name": "Home Telephone",
            "isRequired": false,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.telephoneNumber.workTelephone",
            "name": "Work Telephone",
            "isRequired": false,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.emailAddress",
            "name": "Email Address",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": 254
        },
        {
            "key": "addMember.overrideEmail",
            "name": "Override Email",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.number/Name",
            "name": "Number/Name",
            "isRequired": false,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\\s-.]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.address.Line1",
            "name": "Line 1",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\\s-.,/]+$",
            "maxLength": 100          
        },
        {
            "key": "addMember.address.Line2",
            "name": "Line 2",
            "isRequired": false,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\\s-.,/]+$",
            "maxLength": 100
        },
        {
            "key": "addMember.address.City",
            "name": "City/Town",
            "isRequired": false,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\\s-.,/]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.address.State",
            "name": "State/County",
            "isRequired": false,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\\s-.,/]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.address.Postcode",
            "name": "Postcode",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9]+$",
            "maxLength": 12
        },
        {
            "key": "addMember.address.Country",
            "name": "Country",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.goneAway",
            "name": "Gone Away",
            "isRequired": false,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.applicationRef",
            "name": "Membership Application Ref",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\\s-.,/]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.memberHappyToBeContact",
            "name": "Member happy to be contacted",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.preferredContactMethod",
            "name": "Preferred contact method",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.preferredContactMethod.Post",
            "name": "Post",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.preferredContactMethod.HomeTelephone",
            "name": "Home Telephone",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.preferredContactMethod.WorkTelephone",
            "name": "Work Telephone",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.preferredContactMethod.MobileTelephone",
            "name": "Mobile Telephone",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.PreferredContactMethod.Sms",
            "name": "SMS",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.preferredContactMethod.Email",
            "name": "Email",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.memberIsHappyToBeContactedByAThirdParty",
            "name": "Member is happy to be contacted by a Third Party",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.source",
            "name": "Source",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.emergencyContactName",
            "name": "Emergency Contact Name",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[a-zA-Z\\s-.]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.emergencyContactPhoneNumber",
            "name": "Emergency Contact Phone Number",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.companyName",
            "name": "Company Name",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\-\,\.\\s]+$",
            "maxLength": 100
        },
        {
            "key": "addMember.occupation",
            "name": "Occupation",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9a-zA-Z\-\,\.\\s]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.referredByName",
            "name": "Referred by name",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[a-zA-Z\-\.\\s]+$",
            "maxLength": 50
        },
        {
            "key": "addMember.referredByMembershipNumber",
            "name": "Referred by Membership number",
            "isRequired": true,
            "isVisible": true,
            "regex": "^[0-9]+$",
            "maxLength": 20
        },
        {
            "key": "addMember.otherCommissionNote",
            "name": "Other Commission Note",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": 200
        },
        {
            "key": "addMember.generalNote",
            "name": "General Note",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": 200
        },
        {
            "key": "addMember.aiaVitalityCategory",
            "name": "AIA Vitality Category",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.recurringCardType",
            "name": "Recurring Card Type",
            "isRequired": true,
            "isVisible": true,
            "regex": null,
            "maxLength": null
        },
        {
            "key": "addMember.memberIsTaxExempt",
            "name": "Member is Tax Exempt",
            "isRequired": true,
            "isVisible": true,
            "regex": "^A-z$",
            "maxLength": 50
        }
    ]
}