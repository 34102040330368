import { WithTranslation } from "react-i18next";
import { RouteComponentProps } from 'react-router-dom';
import MemberSummaryModel from "models/MemberSummary";
import { EmailTypes } from "../EmailSummary/interface";

export enum Steps {
    Form = 0,
    Summary = 1
}

export interface ThisProps extends WithTranslation, RouteComponentProps {
    memberData: MemberSummaryModel;
    dateFormat: string;
    onCancel: () => void;
}

export interface ThisState {
    isLoading: boolean;
    disableNext: boolean;
    step: Steps;
    isRemoveCancel: boolean;
    fields: {
        [key: string]: any;
        requestDate: Date | undefined;
        cancellationDate: string;
        reasonId: string;
        overrideCancellationNoticePeriod: boolean;
        overrideMinimumContractPeriod: boolean;
        overrideMoneyBackPeriod: boolean;
        overrideEndBillingPeriod: boolean;
        memberRequested: boolean;
        saveCancelCodeId: string;
    };
    monthsOfPaymentsProjected: number;
    allowedCancellationDates: {cancellationDate: string}[];
    communicationEmail: {
        email: string;
        error: boolean;
        disabledFinish: boolean;
    };
    payments: {
        date: string;
        type: string;
        method: string;
        status: string;
        amount: number;  
    }[];
    cancellationReasons: {
        id: string;
        name: string;
    }[];
    saveCancelCodes: {
        id: string;
        name: string;
    }[];
}

export interface ThisInterface {
    props: ThisProps;
    state: ThisState;
    handleFieldChanged: (key, value) => void;
    moveToStep: (step: Steps) => void;
    handleFinish: () => void;
    handleCommunicationEmailChanged: (value: { email: string; error?: boolean; emailType: EmailTypes } | false) => void;
    handleCancel: () => void;
}