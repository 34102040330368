import React from 'react';
import ThisInterface  from './interface';
import PlanModel from 'models/Plan';

import { getNameOrUndefined } from 'components/FormV2';
import { DisplayDateFormat } from 'util/ControlUtils';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
}
interface Props {
    data: PlanModel;
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true
        }
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { data, t } = this.props;

        const col1Rows = [
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.NAME'),
                data: data.name
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.DESCRIPTION'),
                data: data.description
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.IS_CORPORATE_PLAN'),
                data: data.isCorporatePlan ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.EFFECTIVE_DATE'),
                data: DisplayDateFormat(data.effectiveDate, GENERAL_DATE_FORMAT)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.EXPIRES_DATE'),
                data: DisplayDateFormat(data.expirationDate, GENERAL_DATE_FORMAT)
            },
        ];

        const col2Rows = [
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.PACKAGE'),
                data: getNameOrUndefined(data.package)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.PLAN_CLASS'),
                data: getNameOrUndefined(data.class)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.SUBSIDY_TYPE'),
                data: getNameOrUndefined(data.corporateSubsidy)
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.IS_AVAILABLE_ONLINE'),
                data: data.isAvailableForWeb ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.IS_AVAILABLE_OFFLINE'),
                data: data.isAvailableForClub ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.IS_AVAILABLE_FOR_TELEPHONE_SALES'),
                data: data.isAvailableForTeleSales ? t('BUTTON.YES') : t('BUTTON.NO')
            },
        ];


        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.BASIC')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    <div className="row">
                        <div className="col-md-6">
                            {col1Rows.map((field, key) =>
                                field && <div key={key} className="row mb-4">
                                    <div className="col-6">{field.fieldLabel}</div>
                                    <div className="col-6 text-primary">{field.data}</div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            {col2Rows.map((field, key) =>
                                field && <div key={key} className="row mb-4">
                                    <div className="col-6">{field.fieldLabel}</div>
                                    <div className="col-6 text-primary">{field.data}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;