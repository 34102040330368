import React from 'react';
import ThisInterface from './interface';
import Breadcrumb from 'components/Membership/Breadcrumb';
import FormInfo from 'components/Membership/FormInfo';
import FormFilter from 'components/Membership/FormFilter';
import FormButton from 'components/Membership/FormButton';
import PackagePlanList from 'components/Membership/PackagePlanList';

const template = (_this: ThisInterface) => {
    const {
        dateOfBirthConfig,
        membershipConfiguration,
        membershipSearchFilter,
        errors,
        clubs,
        applicationTypes,
        salePeople,
        reciprocalAccesses,
        minimumContracts,
        accesses,
        paymentMethods,
        packagePlans,
        isShowFilter,
        isLoading } = _this.state;

    const { isEdit } = _this.props;
    const { t } = _this.props;

    const {
        toggleFilter,
        filterRef,
        filterButtonRef,
        handleChange,
        handleFilterChange,
        handleInputChange,
        handleDateChange,
        onPackagePlanSelected,
        handleFilterPackagePlan,
        handleClearFilter } = _this;

    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    {!isEdit && <p>{t('PAGE.MEMBERSHIPS.CREATE.ADD_NEW_MEMBER')}</p>}
                    {isEdit && <p>{t('PAGE.MEMBERSHIPS.EDIT.CHANGE_MEMBERSHIP')}</p>}
                </div>
                <div className="member-main">
                    <Breadcrumb
                        isEdit={isEdit}
                        membershipConfiguration={membershipConfiguration}
                        goToStep={(stepIndex) => _this.goToStep(stepIndex)} />
                    <FormInfo
                        dateOfBirthConfig={dateOfBirthConfig}
                        membershipSearchFilter={membershipSearchFilter}
                        membershipConfiguration={membershipConfiguration}
                        errors={errors}
                        clubs={clubs}
                        applicationTypes={applicationTypes}
                        salePeople={salePeople}
                        toggleFilter={toggleFilter}
                        filterButtonRef={filterButtonRef}
                        handleChange={handleChange}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange} />
                    <PackagePlanList
                        isLoading={isLoading}
                        packagePlans={packagePlans}
                        selectedPackagePlanId={membershipSearchFilter.selectedPackagePlanId}
                        membershipConfiguration={membershipConfiguration}
                        onPackagePlanSelected={onPackagePlanSelected} />
                    <FormFilter
                        isShowFilter={isShowFilter}
                        membershipSearchFilter={membershipSearchFilter}
                        reciprocalAccesses={reciprocalAccesses}
                        minimumContracts={minimumContracts}
                        accesses={accesses}
                        paymentMethods={paymentMethods}
                        toggleFilter={toggleFilter}
                        filterRef={filterRef}
                        handleFilterChange={handleFilterChange}
                        handleFilterPackagePlan={handleFilterPackagePlan}
                        handleClearFilter={handleClearFilter}
                    />
                    <FormButton handleCancel={_this.props.onCancel} />
                </div>
            </div>
        </section>
    );
}

export default template;

