import React from 'react';
import { t } from 'util/I18nMessages';
import TableWithPaginationSortFilter, { TableColumn } from 'components/TableV2';
import ThisInterface from './interface';

// TODO: simulate API
const onlineAccessData = [
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.STATUS',
        valueKey: 'BUTTON.YES',
    },
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.LAST_LOGIN_DATE',
        valueKey: 'COMMON.VALUE_MONTH',
        value: {count: 15}
    },
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.PREVIOUS_LOGIN_DATE',
        valueKey: 'COMMON.VALUE_DAY',
        value: {count: 1}
    },
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.INVALID_LOGIN_ATTEMPTS',
        valueKey: 'COMMON.VALUE_DAY',
        value: {count: 0}
    },
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.FIRST_CLUB_IDENTIFIER',
        valueKey: 'COMMON.VALUE_MONTH',
        value: {count: 1}
    },
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.SECURITY_QUESTION_CODE',
        valueKey: 'COMMON.VALUE_MONTH',
        value: {count: 1}
    },
    {
        key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.SECURITY_QUESTION_ANSWER',
        valueKey: 'COMMON.VALUE_DAY',
        value: {count: 30}
    }
];

// TODO: simulate API
const datas = [
    ['30/02/2019 01:52:37 PTG', 'FFP Empire Supang', 'MYEMPDF003', '01:52:36 PTG', 'Denied - No Reciprocal Access for Member Tier'],
    ['30/02/2019 01:52:37 PTG', 'FFP Empire Supang', 'MYEMPDF003', '01:52:36 PTG', 'OK - Home'],
    ['30/02/2019 01:52:37 PTG', 'FFP Empire Supang', 'MYEMPDF003', '01:52:36 PTG', 'OK - Home'],
    ['30/02/2019 01:52:37 PTG', 'FFP Empire Supang', 'MYEMPDF003', '01:52:36 PTG', 'OK - Home'],
    ['30/02/2019 01:52:37 PTG', 'FFP Empire Supang', 'MYEMPDF003', '01:52:36 PTG', 'OK - Home'],
    ['30/02/2019 01:52:37 PTG', 'FFP Empire Supang', 'MYEMPDF003', '01:52:36 PTG', 'OK - Home'],
];

// TODO: simulate API
const pagination = {
    totalItemsCount: 20,
    totalPages: 2,
    pageIndex: 0,
    pageSize: 10
};

const memberNotesTable = {
    columns: [
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.LIST.DATE', sortKey: 'date', sortable: true },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.LIST.CLUB', sortKey: 'club', sortable: false },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.LIST.WORKSTATION', sortKey: 'workstation', sortable: false },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.LIST.WORKSTATION_TIME', sortKey: 'workstationTime', sortable: false },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.LIST.RESULT', sortKey: 'result', sortable: false },
    ] as TableColumn[],
    datas: datas,
    pagination: pagination,
    filtersField: [],
    filtersForm: []
    
}

const template = (_this: ThisInterface) => {

    return (
        <div className="member-ship-detail member-activity-detail">
            <div className="section-summary mb-1">
                <div className="summary-header ">
                    <p>{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY')}</p>
                </div>
                <div className="row">
                    <div className="col-md-9 col-lg-9">
                        <span>{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.HINT')}</span>
                    </div>
                    <div className="col-md-3 col-lg-3 text-right">
                        <button className="btn btn-height-sm btn-add-new">
                            {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.BUTTON.CREATE_BOOKING')}
                        </button>
                    </div>
                </div>
            </div>
            <div className="content-wrapper ">
                <TableWithPaginationSortFilter
                    columns={memberNotesTable.columns} 
                    datas={memberNotesTable.datas}
                    pagination={memberNotesTable.pagination}
                    filtersForm={memberNotesTable.filtersForm}
                    filtersField={memberNotesTable.filtersField}
                    isLoading={false}
                    onPaginationChanged={(pageIndex, pageSize) => console.log(pageIndex, pageSize)}
                    onPageSortChanged={(sorts) => console.log(sorts)}
                    onFilterChanged={(queryString) => console.log(queryString)}
                />
            </div>
            
            <div className="section-summary mb-1">
                <div className="summary-header">
                    <p>{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.ONLINE_ACCESS_DETAILS')}</p>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="content-header">
                    <p className="head-title mb-1">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.SUMMARY')}</p>
                </div>
                <div className="row">
                    <div className="col-md-4 desc-list">
                        {onlineAccessData.map((item, key) => {
                            return (
                                <div className="row" key={key}>
                                    <div className="col-md-8 left-content">{t(item.key)}</div>
                                    <div className="col-md-4 text-right">{t(item.valueKey, item.value)}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="content-wrapper ">
                <div className="content-header">
                    <p className="head-title mb-2">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.ACCESS_HISTORY')}</p>
                </div>
                <div className="row promotion-addon-wrapper">
                    <div className="col-md-10 promotion-addon">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.NO_ACCESS_HISTORY')}</div>
                </div>
            </div>

            <div className="content-wrapper ">
                <div className="content-header">
                    <p className="head-title mb-2">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.EVENT_HISTORY')}</p>
                </div>
                <div className="row promotion-addon-wrapper">
                    <div className="col-md-10 promotion-addon">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.NO_EVENT_HISTORY')}</div>
                </div>
            </div>

            <div className="content-wrapper ">
                <div className="content-header">
                    <p className="head-title mb-2">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.AUTHENTICATION_KEYS')}</p>
                </div>
                <div className="row promotion-addon-wrapper">
                    <div className="col-md-10 promotion-addon">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACTIVITY.NO_AUTHENTICATION_KEYS')}</div>
                </div>
            </div>

            <ul className="list-btn-action">
                <li>
                    <button className="btn btn-secondary">{t('BUTTON.ADD_NOTE')}</button>
                </li>
                <li>
                    <button className="btn btn-primary">{t('BUTTON.ADD_MESSAGE')}</button>
                </li>
                <li>
                    <button className="btn btn-dark" onClick={_this.props.onCancel}>
                        {t('BUTTON.BACK')}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default template;
