import { getIdOrNull, getCodeOrNull, forceFloat, forceDecimal } from "components/FormV2";

export class Currency {
    code = '';
    name = '';
}

export class SubTenant {
    id = '';
    name = '';
    currency = new Currency();

    value = '';
    label = '';
    static init = (obj: SubTenant) => {
        return {
            id: obj.id,
            name: obj.name,
            currency: {...obj.currency},
            value: obj.id,
            label: obj.name,
        }
    }
}

export class AuthorizationMethod {
    id = '';
    name = '';
}

export class TenderClassModel {
    id = '';
    name = '';
    code = '';
    isAllowInMakePaymentWizard = false;
    isAllowInRefundWizard = false;
    subTenant = new SubTenant();
    static buildData = (obj: TenderClassModel) => {
        return {
            name: obj.name,
            code: obj.code,
            isAllowInMakePaymentWizard: obj.isAllowInMakePaymentWizard,
            isAllowInRefundWizard: obj.isAllowInRefundWizard,
            subTenantId: getIdOrNull(obj.subTenant),
        }
    }
}

export class TenderTypeModel {
    name = '';
    code = '';
    tenderClass = new TenderClassModel();
    authorizationMethod = new AuthorizationMethod();
    isActive = false;
    isUnitCountRequired = false;
    changeThresholdAmount = '0.00';
    refundThresholdAmount = '0.00';
    managerRefundThresholdAmount = '0.00';
    minimumAmount = '0.00';
    maximumAmount = '0.00';
    maximumSmartCardAmount = '0.00';
    isOpenCashDrawerRequired = false;
    subTenant = new SubTenant();
    static init = (obj: TenderTypeModel) => {
        return {
            ...obj,
            changeThresholdAmount: forceDecimal(obj.changeThresholdAmount),
            refundThresholdAmount: forceDecimal(obj.refundThresholdAmount),
            managerRefundThresholdAmount: forceDecimal(obj.managerRefundThresholdAmount),
            minimumAmount: forceDecimal(obj.minimumAmount),
            maximumAmount: forceDecimal(obj.maximumAmount),
            maximumSmartCardAmount: forceDecimal(obj.maximumSmartCardAmount)
        }
    }

    static buildData = (obj: TenderTypeModel) => {
        return {
            name: obj.name,
            code: obj.code,
            tenderClassCode: getCodeOrNull(obj.tenderClass),
            authorizationMethodId: getIdOrNull(obj.authorizationMethod),
            isActive: obj.isActive,
            isUnitCountRequired: obj.isUnitCountRequired,
            subTenantId: getIdOrNull(obj.subTenant),
            changeThresholdAmount: forceFloat(obj.changeThresholdAmount),
            refundThresholdAmount: forceFloat(obj.refundThresholdAmount),
            managerRefundThresholdAmount: forceFloat(obj.managerRefundThresholdAmount),
            minimumAmount: forceFloat(obj.minimumAmount),
            maximumAmount: forceFloat(obj.maximumAmount),
            maximumSmartCardAmount: forceFloat(obj.maximumSmartCardAmount),
            isOpenCashDrawerRequired: obj.isOpenCashDrawerRequired
        }
    }
}