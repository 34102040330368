import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import ThisInterface from './interface';
import template from './template';

class ForgotPasswordPage extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    languageRef: React.RefObject<HTMLSpanElement>;

    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.languageRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutSide);
    }

    handleClickOutSide = (e: Event) => {
        if (e.target !== this.languageRef.current) {
            this.setState({
                isOpen: false
            });
        }
    };

    handleOpenChangeLanguage = () => {
        this.setState(prevState => {
            return {
                isOpen: !prevState.isOpen
            };
        });
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(ForgotPasswordPage);
