import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import SelectSubTenant from 'components/SelectSubTenant';
import ConmanService from 'services/conman.service';
import * as Interface from 'interfaces';
import * as Route from 'constants/Constants';
import {
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ListComponent from 'list-component';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

interface StateInterface {
    version: number;
    tableHeight: number;
    subTenantId: string;
}

interface PropsInterface extends RouteComponentProps, WithTranslation {
    auth: Interface.StateToPropInterface['oidc'];
}

class PlanPage extends React.Component<PropsInterface, StateInterface> {
    conmanService: ConmanService
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            tableHeight: 0,
            version: 0,
            subTenantId: ''
        };
        this.conmanService = new ConmanService();
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.setState({
            tableHeight: window.innerHeight - 310,
            version: 1
        });
    }
    actionCallback = (action, id) => {
        const { subTenantId } = this.state;
        this.setState(state => ({ version: state.version + 1 }));
        if (action === 'view') {
            this.props.history.push(Route.ROUTE_PLAN_VIEW.replace('{0}', id), {
                subTenantId
            });
        }
        if (action === 'edit') {
            this.props.history.push(Route.ROUTE_PLAN_EDIT.replace('{0}', id), {
                subTenantId
            });
        }
        if (action === 'quickEdit') {
            this.props.history.push(Route.ROUTE_PLAN_QUICK_EDIT.replace('{0}', id), {
                subTenantId
            });
        }
        if (action === 'editAvailability') {
            this.props.history.push(
                Route.ROUTE_PLAN_EDIT_AVAILABILITY.replace('{0}', id),
                { subTenantId }
            );
        }
        if (action === 'clone') {
            this.props.history.push(Route.ROUTE_PLAN_CLONE.replace('{0}', id), {
                subTenantId
            });
        }
        if (action === 'quickClone') {
            this.props.history.push(Route.ROUTE_PLAN_QUICK_CLONE.replace('{0}', id), {
                subTenantId
            });
        }
    };
	
    handleNext = (subTenant) => {
        this.setState({
            subTenantId: subTenant.id
        });
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 310
        });
    };

    errorHandle = err => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    };

    render() {
        const { auth, t } = this.props;
        const { subTenantId } = this.state;
        const variableConfig = {
            api: BASE_API_URL_DEVELOPMENT
        };

        return this.state.version > 0 ? (
			<>
				<div className="list-page plan-list-view-page">
				    <div className="list-page-title">
				        <p className="title-content title-content-list">
				            {t('PAGE.PRODUCTS.PLANS.TITLE')}
				        </p>
				    </div>
				    <div className="box-btn-filter ml-auto d-flex">
				        <span className="plan-hint">
				            {t('PAGE.PRODUCTS.PLANS.INSTRUCTION')}
				        </span>
				    </div>
				</div>
					{!subTenantId ? (
					    <SelectSubTenant rightName="view_plan_list"
					        conmanService={this.conmanService}
					        onNext={this.handleNext} />
					) : (
					    <ListComponent
					        oidcAuth={auth.user}
					        version={this.state.version}
					        subTenantId={subTenantId}
					        rightName="view_plan_list"
					        config={{
					            presetUrl: Route.PRESET_ENDPOINT_URL,
					            timeFormat: GENERAL_DATE_FORMAT,
					            langCode: i18n.language,
					            tableHeight: `${this.state.tableHeight}px`,
					            tableWidth: '100%'
					        }}
					        variable={variableConfig}
					        actionEvent={this.actionCallback}
					        errorEvent={this.errorHandle}
					    />
					)}
			</>
        ) : null;
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(PlanPage)));
