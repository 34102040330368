import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CommonService from 'services/common.service';
import template from './template';
import ThisInterface from './interface';
class MemberContactDetail extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    commonService: CommonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {};
        this.commonService = new CommonService();
    }

    render() {
        return template(this);
    }
}

export default withRouter(withTranslation()(MemberContactDetail));
