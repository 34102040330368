import React from 'react';
import embededWrapper from 'hocs/Embeded';
import Loading from 'components/Loading';

import ThisInterface from './interface';
import { IFRAME_CLUB_LIST } from 'constants/Constants';
import { getIframeAPIURL, isHasRight, getParamFromUrl } from 'util/ControlUtils';
import * as RouteLoader from 'pages/RouteLoader';

class ClubList extends React.Component<ThisInterface['props'], {}> {
    componentDidMount = () => {
        window.addEventListener('message', this.messageHandler);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data;
        
        switch (action) {
            case 'returnHeightData': 
                const obj: any = this.refs.iframe;
                if (obj) {
                    obj.style.height = value + 'px';
                }
                break;
            case 'redirectURL':
                if(value.includes('/Clubs/Club.aspx')) {
                    const clubI = getParamFromUrl(value, 'ClubId');
                    clubI && this.props.history.push(`/${RouteLoader.ClubDetailPageRoute.url.replace(':id', clubI.value)}`);
                }
                break;
        }
    }

    permission = () => {
        const hasViewClubRight = isHasRight(RouteLoader.ClubDetailPageRoute.rightName);
        const obj: any = this.refs.iframe;
        if(hasViewClubRight) {
            obj.contentWindow.postMessage({
                action: 'hasViewClubRight'
            }, '*');
        }
    }
	
    render() {
        const { iframeLoaded, iframeToken, subTenantId } = this.props;

        return (
            <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && (
                            <iframe
                                src={`${getIframeAPIURL(IFRAME_CLUB_LIST, subTenantId, '/MembershipManagement')}?token=${iframeToken}`}
                                ref='iframe'
                                onLoad={() => this.props.resizeIframe(this.refs.iframe, this.permission)}
                                className="iframe"
                                frameBorder="0"
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default embededWrapper(ClubList, 'ClubListPageRoute', 'PAGE.MF.CLUBS.TITLE', 'PAGE.MF.CLUBS.DESCRIPTION');