import React from 'react';
import * as Interface from 'interfaces';

interface FormInputProps extends Interface.InputInterface {
    checked?: boolean;
    defaultChecked?: boolean;
    alignEnd?: boolean;
}

const FormCheckbox: React.FunctionComponent<FormInputProps> = ({
    label = '',
    id = '',
    value,
    checked = false,
    onChange,
    disabled = false,
    className = '',
    labelClass = '',
    inputClass = ''
}) => {
    return (
        <div className={`form-group ${className} form-checkbox`}>
            <label className={`checkbox-container ${labelClass}`}>
                {label}
                <input
                    id={id}
                    className={`feature-checkbox ${inputClass}`}
                    type="checkbox"
                    name={label}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                />
                <span className={`checkmark ${disabled ? 'disabled' : ''}`} />
            </label>
        </div>
    );
};

FormCheckbox.displayName = 'FormCheckbox';

export default FormCheckbox;
