import Loading from 'components/Loading';
import React from 'react';
import ThisInterface, { Steps } from './interface';
import { PaymentInfo } from './PaymentInfo';
import { PaymentSummary } from './PaymentSummary';

const ButtonItem = ({ onClick, type = 'primary', disabled = false, title }) => {
    return (
        <li>
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const renderActionButtons = (_this: ThisInterface) => {
    const {
        state: {
            step,
            disabledNext
        },
        props: {
            t
        }
    } = _this;

    const onClickNextOrFinish = (() => {
        switch (step) {
            case Steps.Info:
                return () => _this.moveToStep(Steps.Summary);
            case Steps.Summary:
                return () => _this.submit();
            default:
                return () => {};
        }
    })();

    return (
        <div className="row">
            <div className="col-md-12 mt-4">
                <ul className="list-btn-action">
                    {step === Steps.Summary && (
                        <ButtonItem
                            onClick={() => _this.moveToStep(Steps.Info)}
                            title={t('BUTTON.PREVIOUS')}
                        />
                    )}
                    <ButtonItem
                        onClick={onClickNextOrFinish}
                        title={t(step === Steps.Info ? 'BUTTON.NEXT' : 'BUTTON.FINISH')}
                        disabled={step === Steps.Info ? disabledNext : false}
                    />
                    <ButtonItem
                        onClick={_this.props.onCancel}
                        title={t('BUTTON.CANCEL')}
                        type="dark"
                    />
                </ul>
            </div>
        </div>
    );
};

const renderContent = (_this: ThisInterface) => {
    const {
        state: {
            step,
            fees,
            remaining,
            payOption,
            totalAmountPaid,
            totalAmountCharged,
            totalAmountOutstanding,
            totalPaymentAmount,
            fields: {
                paymentAmount,
                tenderType
            },
            tenderTypes,
            validations
        },
        handleChangeOption,
        handleChangeField,
        allocate
    } = _this;
    switch (step) {
        case Steps.Info:
            return (
                <PaymentInfo
                    step={step}
                    remaining={remaining}
                    fees={fees}
                    payOption={payOption}
                    paymentAmount={paymentAmount}
                    onChangeOption={handleChangeOption}
                    onChangeField={handleChangeField}
                    totalAmountCharged={totalAmountCharged}
                    totalAmountOutstanding={totalAmountOutstanding}
                    totalAmountPaid={totalAmountPaid}
                    allocate={allocate}
                    totalPaymentAmount={totalPaymentAmount}
                    tenderTypes={tenderTypes}
                    selectedTenderType={tenderType}
                    validations={validations}
                />
            );
        case Steps.Summary:
            return (
                <PaymentSummary
                    step={step}
                    remaining={remaining}
                    fees={fees}
                    totalAmountCharged={totalAmountCharged}
                    totalAmountOutstanding={totalAmountOutstanding}
                    totalAmountPaid={totalAmountPaid}
                    totalPaymentAmount={totalPaymentAmount}
                />
            );
        default:
            return null;
    }
};

const template = (_this: ThisInterface) => {
    const { 
        props: {
            t
        },
        state: {
            isLoading
        }
    } = _this;

    return isLoading ? <Loading /> :
        (
            <div className="make-payment">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h3 className="text-title mb-3">{t('PAGE.MAKE_PAYMENT.TITLE')}</h3>
                        </div>
                    </div>
                </div>
                {renderContent(_this)}
                {renderActionButtons(_this)}
            </div>
        );
};

export default template;
