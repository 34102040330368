import CustomTable from 'components/Table';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDateFormat } from 'util/ControlUtils';
import { FeeView } from "./interface";
import { Steps } from './interface';
import { t } from 'util/I18nMessages';

const feeHeaders = [
    {
        key: 'chargedDate',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.DATE'
    },
    {
        key: 'description',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.FEE_DESCRIPTION'
    },
    {
        key: 'amountCharged',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.AMOUNT_CHARGED',
        className: 'text-right'
    },
    {
        key: 'amountPaid',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.AMOUNT_PAID',
        className: 'text-right'
    },
    {
        key: 'amountOutstanding',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.AMOUNT_OUTSTANDING',
        className: 'text-right'
    },
    {
        key: 'paymentAmount',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.PAYMENT_AMOUNT',
        className: 'text-right'
    }
];

const summaryFeeHeaders = [
    {
        key: 'chargedDate',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.DATE'
    },
    {
        key: 'description',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.FEE_DESCRIPTION'
    },
    {
        key: 'amountCharged',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.AMOUNT_CHARGED',
        className: 'text-right'
    },
    {
        key: 'amountOutstanding',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.AMOUNT_PREVIOUS_OUTSTANDING',
        className: 'text-right'
    },
    {
        key: 'paymentAmount',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.AMOUNT_PAID',
        className: 'text-right'
    },
    {
        key: 'newAmountOutstanding',
        title: 'PAGE.MAKE_PAYMENT.PAYMENT_SUMMARY.HEADERS.NEW_AMOUNT_OUTSTANDING',
        className: 'text-right'
    }
];

interface FeesTableProps {
    step: Steps;
    remaining: number;
    fees;
    totalAmountCharged;
    totalAmountOutstanding;
    totalAmountPaid;
    totalPaymentAmount;
    additionalFooter?: React.ReactNode;
}

export const calculateTotalAmount = (
    fees: FeeView[],
    key: 'amountCharged' | 'amountPaid' | 'amountOutstanding' | 'paymentAmount'
) => {
    const total = fees.reduce<number>((prev, curr) => {
        return prev + (curr[key] || 0);
    }, 0);
    return total;
};

export const allocatePaymentAmount = (feeState: FeeView[], paymentAmount: number) => {
    const fees = Array.from(feeState);
    fees.forEach(fee => {
        if(paymentAmount <= 0) return fee.paymentAmount = 0;
        fee.paymentAmount = paymentAmount > fee.amountOutstanding ? fee.amountOutstanding : paymentAmount;
        paymentAmount -= fee.paymentAmount;
    });
    return fees;
};

export const calculateTotalInArears = (totalAmountOutstanding: number, remaining: number) => {
    return (totalAmountOutstanding - remaining).toFixed(2);
};

const renderTableContent = (props: FeesTableProps) => {
    const { fees, step } = props;
    return fees.length ? fees.map((fee, index) => {
        switch (step) {
            case Steps.Info:
                return (
                    <tr key={index}>
                        <td>{DisplayDateFormat(fee.chargedDate, GENERAL_DATE_FORMAT)}</td>
                        <td>{fee.plan.fee.name}</td>
                        <td className="text-right">{fee.amountCharged.toFixed(2)}</td>
                        <td className="text-right">{fee.amountPaid.toFixed(2)}</td>
                        <td className="text-right">{fee.amountOutstanding.toFixed(2)}</td>
                        <td className="text-right">{fee.paymentAmount.toFixed(2)}</td>
                    </tr>
                );
            case Steps.Summary:
                return (
                    <tr key={index}>
                        <td>{DisplayDateFormat(fee.chargedDate, GENERAL_DATE_FORMAT)}</td>
                        <td>{fee.plan.fee.name}</td>
                        <td className="text-right">{fee.amountCharged.toFixed(2)}</td>
                        <td className="text-right">{fee.amountOutstanding.toFixed(2)}</td>
                        <td className="text-right">{fee.paymentAmount.toFixed(2)}</td>
                        <td className="text-right">{(fee.amountOutstanding - fee.paymentAmount).toFixed(2)}</td>
                    </tr>
                );        
            default:
                break;
        }
    }) : <tr><td>NO DATA</td></tr>;
};

const renderTableFoot = (props: FeesTableProps) => {
    const {
        step,
        fees,
        totalAmountCharged,
        totalAmountOutstanding,
        totalAmountPaid,
        remaining,
        additionalFooter,
        totalPaymentAmount
    } = props;
    const { t } = useTranslation();
    if (!fees.length) return null;
    switch (step) {
        case Steps.Info:
            return (
                <React.Fragment>
                    <tr>
                        <td></td>
                        <td><h4 className="text-total">{t('PAGE.MAKE_PAYMENT.TOTAL')}</h4></td>
                        <td className="text-right">{totalAmountCharged.toFixed(2)}</td>
                        <td className="text-right">{totalAmountPaid.toFixed(2)}</td>
                        <td className="text-right">{totalAmountOutstanding.toFixed(2)}</td>
                        <td className="text-right">{calculateTotalAmount(fees, 'paymentAmount').toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-total-in-arrears">{t('PAGE.MAKE_PAYMENT.TOTAL_IN_ARREARS')}</td>
                        <td className="text-right">{calculateTotalInArears(totalAmountOutstanding, remaining)}</td>
                        <td></td><td></td><td></td>
                    </tr>
                    {additionalFooter}
                </React.Fragment>
            );
        case Steps.Summary:
            return (
                <React.Fragment>
                    <tr>
                        <td></td>
                        <td><h4 className="text-total">{t('PAGE.MAKE_PAYMENT.TOTAL')}</h4></td>
                        <td className="text-right">{totalAmountCharged.toFixed(2)}</td>
                        <td className="text-right">{totalAmountOutstanding.toFixed(2)}</td>
                        <td className="text-right">{calculateTotalAmount(fees, 'paymentAmount').toFixed(2)}</td>
                        <td className="text-right">{(totalAmountOutstanding - calculateTotalAmount(fees, 'paymentAmount')).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className="text-total-in-arrears">{t('PAGE.MAKE_PAYMENT.TOTAL_IN_ARREARS')}</td>
                        <td className="text-right">{calculateTotalInArears(totalAmountOutstanding, totalPaymentAmount)}</td>
                        <td></td><td></td><td></td>
                    </tr>
                    {additionalFooter}
                </React.Fragment>
            );
        default:
            return null;
    }
};

export const FeesTable: React.FC<FeesTableProps> = (props) => {
    const headers = props.step === Steps.Info ? feeHeaders : summaryFeeHeaders;

    return (
        <CustomTable
            renderingCondition
            maxHeigth="auto"
            className="fees-table"
            headers={headers}
            tableContent={renderTableContent(props)}
            tfoot={renderTableFoot(props)}
        />
    );
};