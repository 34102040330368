export default class Theme {
    themeId = 0;

    //tenantId = "";

    primaryColor = '';

    secondaryColor = '';

    isDefault = false;

    isActive = false;

    isRecommendation = false;
}
