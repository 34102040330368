import React from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import isBase64 from 'is-base64';

import { withUserAndUserGroup } from 'hocs';

import { WithTranslation } from 'react-i18next';
import * as Route from 'pages/RouteLoader';
import {
    checkInvalidEmailField,
    capitalize,
    isValidPassword,
    filterUserAndUserGroup,
    getSubTenantId
} from 'util/ControlUtils';
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';

import ThisInterface from './interface';
import template from './template';
import { API_USERS_GROUP_FILTER } from 'constants/Constants';

const initState = {
    subTenantId: null,
    selectedSubTenantRoles: [],
    selectTenant: false,
    selectSubTenant: false,
    avatarString: '',
    avatarUri: '',
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: {
        nationalNumber: '',
        regionCode: ''
    },
    tenantName: 'EWS',
    tenantId: '',
    userGroup: [],
    tenantRoleEnabled: false,
    selectedTenantRoles: [],
    userGroupFilterObject: {
        and: [
            {
                queryKey: 'usergroup.name',
                operation: 'contains',
                queryType: 'text',
                queryValue: '{value}'
            },
            {
                queryKey: 'roleId',
                operation: 'in',
                queryType: 'guid',
                queryValue: '00000000-0000-0000-0000-000000000000'
            }
        ]
    },
    isValidPhoneNumber: true,
    isSelectedRolesValid: true,
    isDirtyUserName: false,
    isDirtyFirstName: false,
    isDirtyLastName: false,
    isDirtyPassword: false,
    isCopy: false,
    isLoading: true,
    disabled: true
};

class AddUserPage extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    mounted: boolean;
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);

        // Get subTenantId & rightName
        const { isEditMode, t } = props;
        const rightName = isEditMode
            ? Route.EditUserRoute.rightName
            : Route.AddUserRoute.rightName;

        const { match, tenantId } = props;
        this.state = {
            ...initState,
            password: isEditMode ? '****' : '',
            tenantId,
            userId: match.params['id'] || '',
            rightName: rightName
        };
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
        observer.subscribe('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', isClear => {
            const {
                tenantId,
                selectedSubTenantRoles,
                selectedTenantRoles,
                tenantRoleEnabled
            } = this.state;
            const filterObject = filterUserAndUserGroup(
                tenantId,
                selectedTenantRoles,
                selectedSubTenantRoles
            );
            if (tenantRoleEnabled && selectedTenantRoles.length) {
                this.setState({
                    userGroupFilterObject: {
                        and: [
                            {
                                queryKey: 'tenantId',
                                operation: 'eq',
                                queryType: 'guid',
                                queryValue: tenantId
                            },
                            {
                                queryKey: 'name',
                                operation: 'contains',
                                queryType: 'text',
                                queryValue: '{value}'
                            }
                        ]
                    }
                });
            } else {
                if (filterObject && filterObject.length) {
                    this.setState({
                        userGroupFilterObject: {
                            and: [
                                initState.userGroupFilterObject.and[0],
                                { or: filterObject }
                            ]
                        }
                    });
                } else {
                    this.setState({
                        userGroupFilterObject: {
                            and: [
                                initState.userGroupFilterObject.and[0],
                                {
                                    queryKey: 'roleId',
                                    operation: 'in',
                                    queryType: 'guid',
                                    queryValue: '00000000-0000-0000-0000-000000000000'
                                }
                            ]
                        }
                    });
                }
            }
            if (isClear) {
                const { rightName } = this.state;
                const subTenantId = getSubTenantId();

                if (filterObject && filterObject.length) {
                    let data = { filter: JSON.stringify({ or: filterObject }) };
                    this.props.conmanService
                        .getSuggestions(
                            API_USERS_GROUP_FILTER,
                            'post',
                            data,
                            subTenantId,
                            rightName
                        )
                        .then(res => {
                            const resCondition = res && res.data && !_.isEmpty(res.data);
                            if (resCondition) {
                                const userGroup = this.state.userGroup.filter(e => {
                                    return res.data.find(f => f.id === e.id) ? true : false;
                                });
                                this.setState({ userGroup: userGroup });
                            } else {
                                this.setState({ userGroup: [] });
                            }
                        });
                } else {
                    this.setState({ userGroup: [] });
                }
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
        observer.unsubscribe('BUILD_FILTER_OBJECT.ADVANCE_SEARCH');
    }

    static getDerivedStateFromProps(
        nextProps: ThisInterface['props'],
        prevState: ThisInterface['state']
    ) {
        const { selectedSubTenantRoles } = nextProps;
        if (nextProps) {
            const selectedSubTenantRoleList =
                selectedSubTenantRoles &&
                selectedSubTenantRoles !== prevState.selectedSubTenantRoles
                    ? selectedSubTenantRoles
                    : prevState.selectedSubTenantRoles;

            return {
                selectedSubTenantRoles: selectedSubTenantRoleList
            };
        }
        return null;
    }

    componentDidUpdate(prevProps: ThisInterface['props']) {
        const { subTenants, selectedSubTenantRoles, isEditMode } = this.props;
        if (
            subTenants !== prevProps.subTenants &&
            selectedSubTenantRoles !== prevProps.selectedSubTenantRoles
        ) {
            isEditMode ? this.initEditMode() : this.initSelectSubTenant(subTenants);
        }
    }
    initSelectSubTenant = subTenants => {
        this.setState({
            subTenantId: {
                value: subTenants[0].value,
                label: subTenants[0].label
            },
            isLoading: false
        });
    };

    initEditMode = () => {
        const { userId, rightName } = this.state;
        const { conmanService, t } = this.props;
        conmanService
            .getUserById(userId, rightName)
            .then(res => {
                this.mapDataToState(res);
            })
            .catch(err => {
                if (err) {
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'error',
                        html: err.message,
                        confirmButtonText: t('BUTTON.CLOSE'),
                        onClose: () => {
                            this.props.history.push('/system/users');
                        }
                    });
                }
            });
    };

    mapDataToState = user => {
        const { subTenants, selectedSubTenantRoles } = this.props;
        const tenantRoles = user.hasOwnProperty('roles')
            ? user.roles.tenantRoles
            : [];
        const subTenantRoles = user.hasOwnProperty('roles')
            ? user.roles.subTenants
            : [];
        subTenantRoles.forEach(str => {
            const index = selectedSubTenantRoles.findIndex(
                istr => istr.subTenantId === str.subTenantId
            );
            index >= 0 && (selectedSubTenantRoles[index].roles = str.subTenantRoles);
        });
        const subTenant = subTenants.find(item => item.value === user.subTenantId);

        this.props.editMode(subTenantRoles);

        this.setState({
            isLoading: false,
            userId: user.id,
            avatarUri: user.avatarUri ? user.avatarUri : '',
            avatarString: user.avatarUri ? user.avatarUri : '',
            userName: user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.hasOwnProperty('email') ? user.email : '',
            phoneNumber: {
                nationalNumber: user.hasOwnProperty('mobilePhoneNumber')
                    ? user.mobilePhoneNumber
                    : '',
                regionCode: user.hasOwnProperty('mobilePhoneRegionCode')
                    ? user.mobilePhoneRegionCode
                    : ''
            },
            subTenantId: subTenant
                ? {
                    value: subTenant.value,
                    label: subTenant.label
                }
                : null,
            tenantRoleEnabled: tenantRoles.length > 0,
            selectedTenantRoles: tenantRoles,
            selectedSubTenantRoles,
            userGroup: user.groups
        });
        observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
    };

    handleCopyPassword = () => {
        this.setState(
            {
                isCopy: true
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        isCopy: false
                    });
                }, 3000);
            }
        );
    };

    handleGeneratePassword = () => {
        const { conmanService } = this.props;
        conmanService
            .createRandomPassword()
            .then(res => {
                if (res && res.password) {
                    this.setState({
                        password: res.password,
                        disabled: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    password: '****'
                });
            });
    };

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const { value } = e.target;
        let formatValue = value;
        if (key === 'userName') {
            formatValue = value.replace(/[^a-zA-Z0-9-@.]/g, '');
            this.setState({
                isDirtyUserName: formatValue ? false : true
            });
        }
        if (
            key === 'firstName' ||
            key === 'lastName' ||
            key === 'tenantName' ||
            key === 'password'
        ) {
            formatValue = key !== 'password'
                ? value.replace(/[^a-zA-Z- ]/g, '')
                : value;
            this.setState(prevState => {
                return {
                    ...prevState,
                    [`isDirty${capitalize(key)}`]: formatValue ? false : true
                };
            });
        }
        this.setState(prevState => {
            return {
                ...prevState,
                disabled: false,
                [key]: formatValue
            };
        });
    };

    handleChangePhoneNumber = (
        nationalNumber: string,
        regionCode: string,
        isValid: boolean,
        key: string
    ) => {
        observer.publish('flagUnsavedChangeEvent', true);

        this.setState(prevState => {
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    nationalNumber,
                    regionCode
                },
                isValidPhoneNumber: nationalNumber ? isValid : true,
                disabled: false
            };
        });
    };

    handleSubTenant = value => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState({
            subTenantId: value,
            disabled: false
        });
    };

    handleAddRemoveSingleSuggestion = (suggestion, key: string) => {
        const { t } = this.props;
        if (this.state[key].length > suggestion.length) {
            // Is remove
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USERS.ADD_EDIT.CONFIRM_REMOVE_ROLE_CLUB_GROUP'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(result => {
                if (result.value) {
                    observer.publish('flagUnsavedChangeEvent', true);
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            disabled: false,
                            [key]: suggestion
                        };
                    });
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        } else {
            observer.publish('flagUnsavedChangeEvent', true);
            this.setState(prevState => {
                return {
                    ...prevState,
                    disabled: false,
                    [key]: suggestion
                };
            });
            observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
        }
    };

    onTenantRoleSwichChanged = event => {
        const { t } = this.props;
        observer.publish('flagUnsavedChangeEvent', true);
        let { checked } = event.target;
        if (checked) {
            this.setState({
                tenantRoleEnabled: checked,
                disabled: false
            });
            observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
        } else {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USERS.ADD_EDIT.CONFIRM_REMOVE_ROLE_CLUB_GROUP'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(result => {
                if (result.value) {
                    this.setState({
                        tenantRoleEnabled: false,
                        disabled: false,
                        selectedTenantRoles: []
                    });
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        }
    };

    onSubTenantRoleSwichChanged = event => {
        const { t } = this.props;
        if (event.target.checked) {
            observer.publish('flagUnsavedChangeEvent', true);
            this.props.onSubTenantRoleSwichChanged(event);
            this.setState({
                disabled: false
            });
        } else {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USERS.ADD_EDIT.CONFIRM_REMOVE_ROLE_CLUB_GROUP'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(result => {
                if (result.value) {
                    const eventNotChecked = { target: { checked: false } };
                    observer.publish('flagUnsavedChangeEvent', true);
                    this.props.onSubTenantRoleSwichChanged(eventNotChecked);
                    this.setState({
                        disabled: false
                    });
                    observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                }
            });
        }
    };

    onSubTenantRole = (_subTenantId: string, roles, key: string) => {
        const { t } = this.props;
        switch (key) {
            case 'add':
                observer.publish('flagUnsavedChangeEvent', true);
                this.props.onSubTenantRoleAdded(_subTenantId, roles);
                observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', false);
                break;
            case 'delete':
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    text: t('MSG.SYSTEM.USERS.ADD_EDIT.CONFIRM_REMOVE_ROLE_CLUB_GROUP'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('BUTTON.YES'),
                    cancelButtonText: t('BUTTON.NO')
                }).then(result => {
                    if (result.value) {
                        observer.publish('flagUnsavedChangeEvent', true);
                        this.props.onSubTenantRoleDeleted(_subTenantId, roles);
                        observer.publish('BUILD_FILTER_OBJECT.ADVANCE_SEARCH', true);
                    }
                });
                break;
            case 'disabled':
                observer.publish('flagUnsavedChangeEvent', true);
                this.props.onSubTenantRoleDisabled(_subTenantId);
                break;
            default:
                break;
        }
        this.setState({
            disabled: false
        });
    };

    handleChangeAvatar = (imageSrc: string) => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(
            {
                avatarUri: imageSrc,
                disabled: false
            },
            () => observer.publish('closeDialogEvent', true)
        );
    };

    checkingReadyToSave = () => {
        const {
            avatarString,
            avatarUri,
            userName,
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
            isValidPhoneNumber,
            tenantId,
            subTenantId,
            userGroup,
            selectedTenantRoles,
            selectedSubTenantRoles
        } = this.state;

        const isRolesValid =
            selectedTenantRoles.length > 0 ||
            selectedSubTenantRoles.some(str => str.roles.length > 0);

        this.setState({
            isSelectedRolesValid: isRolesValid,
            isDirtyUserName: !userName,
            isDirtyFirstName: !firstName,
            isDirtyLastName: !lastName,
            isDirtyPassword: !password
        });

        if (
            userName &&
            firstName &&
            lastName &&
            isValidPhoneNumber &&
            checkInvalidEmailField(email) &&
            isRolesValid &&
            (
                password === '****' ||
                (
                    password.length >= 8 &&
                    password.length <= 20 &&
                    isValidPassword(password)
                )
            )
        ) {
            return {
                avatarString,
                avatarUri,
                userName,
                firstName,
                lastName,
                email,
                phoneNumber,
                userGroup,
                tenantId,
                subTenantId,
                password,
                selectedTenantRoles,
                selectedSubTenantRoles
            };
        }
        return false;
    };

    saveSuccessDialog = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            type: 'success',
            html: t('MSG.SYSTEM.USERS.ADD_USER.SAVE_SUCCESS'),
            confirmButtonText: t('BUTTON.CLOSE')
        }).then(() => {
            this.props.history.push('/system/users');
        });
    };

    saveAvatarFailMsg = (mes: string) => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: mes,
            type: 'error',
            confirmButtonText: t('BUTTON.CLOSE')
        });
    };

    createOrEditUser = data => {
        const { conmanService, isEditMode, userService, t } = this.props;
        const { userId, avatarUri } = this.state;
        Promise.all([
            isEditMode
                ? conmanService.updateUser(userId, { ...data, id: userId })
                : conmanService.createUser(data)
        ])
            .then(([res]) => {
                observer.publish('flagUnsavedChangeEvent', false);
                const uid = res.id && !isEditMode ? res.id : userId;
                const isBase64String = isBase64(avatarUri, { allowMime: true });
                if (avatarUri !== '' && isBase64String) {
                    userService
                        .updateUserAvatar(uid, avatarUri)
                        .then(() => {
                            this.saveSuccessDialog();
                        })
                        .catch(() => {
                            this.saveAvatarFailMsg(
                                t('MSG.SYSTEM.USERS.ADD_USER.SAVE_AVATAR_FAIL')
                            );
                        });
                } else {
                    this.saveSuccessDialog();
                }
            })
            .catch(err => {
                this.saveAvatarFailMsg(
                    t(err && err.message ? err.message : 'MSG.SYSTEM.USERS.ADD_USER.SAVE_FAIL')
                );
            });
    };

    handleSaveUser = () => {
        const { t } = this.props;
        const submitData = this.checkingReadyToSave();
        if (submitData) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.SYSTEM.USERS.ADD_USER.CONFIRM_SAVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(res => {
                if (res.value) {
                    Swal.fire({
                        html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                        showConfirmButton: false
                    });

                    const {
                        avatarString,
                        avatarUri,
                        userName,
                        firstName,
                        lastName,
                        email,
                        phoneNumber,
                        password,
                        tenantId,
                        subTenantId,
                        userGroup,
                        selectedTenantRoles,
                        selectedSubTenantRoles
                    } = submitData;

                    let tenantRoles = selectedTenantRoles.map(obj => ({
                        ...obj,
                        tenantId
                    }));
                    tenantRoles = tenantRoles.map(({ id: roleId, tenantId }) => ({
                        tenantId,
                        roleId
                    }));
                    const subTenantRoles = selectedSubTenantRoles
                        .filter(t => t.roles.length > 0)
                        .reduce((acc: any, tr) => {
                            let subTenantRole = tr.roles.map(r => {
                                return {
                                    subTenantId: tr.subTenantId,
                                    roleId: r.firstKeyId ? r.firstKeyId : r['role'].id,
                                    clubGroupId: r.secondKeyId
                                        ? r.secondKeyId
                                        : r['clubGroup'].id,
                                    name: ''
                                };
                            });

                            return acc.concat(subTenantRole);
                        }, []);
                    const groups = userGroup.map(u => u.id);
                    const isBase64String = isBase64(avatarUri, { allowMime: true });
                    const data = {
                        tenantId,
                        subTenantId: subTenantId!.value,
                        userName,
                        firstName,
                        lastName,
                        email: email ? email : null,
                        password: password === '****' ? null : password,
                        avatarUri: isBase64String ? avatarString : avatarUri,
                        mobilePhoneRegionCode:
                            phoneNumber.regionCode && phoneNumber.nationalNumber
                                ? phoneNumber.regionCode
                                : null,
                        mobilePhoneNumber: phoneNumber.nationalNumber
                            ? phoneNumber.nationalNumber
                            : null,
                        tenantRoles,
                        subTenantRoles,
                        groups
                    };
                    this.createOrEditUser(data);
                }
            });
        }
    };

    handleCancel = () => {
        const { history } = this.props;
        history.push('/system/users');
    };

    render() {
        return template(this);
    }
}

export default withUserAndUserGroup(
    AddUserPage,
    '/system/users/{id}/edit',
    'addUser'
);
