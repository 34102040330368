import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Interface from 'interfaces';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import OperatorPagination from 'models/OperatorPagination';

import template from './template';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import NewPasswordContent from './popup';


interface StateInterface {
    page: number;
    keyword: string;
    keywordWillBeSubmited: string;
    data: OperatorPagination;
    isLoading: boolean;
    isLoadingPagination: boolean;
}

class ResetPasswordPage extends Component<Interface.PagePropsInterface & WithTranslation, StateInterface> {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            keyword: '',
            keywordWillBeSubmited: '',
            data: new OperatorPagination(),
            isLoading: true,
            isLoadingPagination: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.props.conmanService.getOperators(this.state.keywordWillBeSubmited, this.state.page).then((res) => {
            res.data.pagination = res.pagination;
            this.setState({ isLoading: false, isLoadingPagination: false, data: res.data });
        }, () => {
            this.setState({ isLoading: false, isLoadingPagination: false });
        });
    }

    handleChangePage = (page: number) => {
        this.setState({
            page: page,
            isLoadingPagination: true
        }, () => this.getData());
    };

    searchKeywordChange(e) {
        this.setState({ keyword: e.target.value });
    }

    submitSearchByKey(e) {
        e.preventDefault();
        this.setState((state) => { return { isLoading: true, page: 1, keywordWillBeSubmited: state.keyword } }, () => this.getData());
    }

    searchButtonPress() {
        this.setState((state) => { return { isLoading: true, page: 1, keywordWillBeSubmited: state.keyword } }, () => this.getData());
    }

    resetPassword(operator) {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('MSG.CONFIRM_RESET_PASSWORD', { fullname: operator.fullName }),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.YES'),
            cancelButtonText: t('BUTTON.NO'),
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                this.props.conmanService.resetPasswordOperator(operator.username).then((res) => {
                    Swal.close();
                    if (res.message !== 'error') {
                        observer.publish('openDialogEvent', {
                            content: <NewPasswordContent key={Math.random()} fullName={operator.fullName} newPassword={res.data} />,
                            classHolder: 'modal-new-pass'
                        });
                    } else {
                        Swal.fire({
                            ...DefaultConstants.SWAL_COMMON_STYLE,
                            title: '',
                            text: t(res.data ? res.data : 'MSG.NETWORK_ERROR'),
                            type: 'error',
                            confirmButtonText: t('BUTTON.CLOSE')
                        });
                    }
                });
            }
        });
    }


    render() {
        return template(this);
    }
}

const mapStateToProps = ({ conman, oidc }: Interface.StateToPropInterface) => {
    return { conman, oidc };
};

export default withTranslation()(connect(mapStateToProps)(ResetPasswordPage));
