/* eslint-disable */
import MemberSummaryModel from 'models/MemberSummary';
import { FieldModel } from 'models/Field';

export enum EmailTypes {
    existEmail = 'existEmail',
    newEmail = 'newEmail',
    noEmail = 'noEmail'
}

export default interface ThisInterface {
    props: {
        memberData: MemberSummaryModel;
        handleChange?: (value: { email: any, error?: any, emailType: EmailTypes } | false) => void;
        t?: any;
    };
    state: {
        email: string;
        checkedEmail: string;
        disbledEmail: boolean;
        emailValidation: FieldModel;
    };

    handleChangeValue: (fieldName: string, value) => void;
    handleEmailChanged: (fieldName: string, value: string) => void;
}
