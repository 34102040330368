import React, { Component, Suspense } from 'react';
import _ from 'lodash';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import * as signalR from '@aspnet/signalr';

import Callback from 'components/CallBack';
import Silent from 'components/Silent';
import Error404 from 'components/Error404';

import store from 'reduxs/index';
import { setProfileAuth } from 'reduxs/actions/Auth';
import * as actions from 'reduxs/actions/Conman';
import { userSignOut } from 'reduxs/actions/Auth';

import defaultTheme from 'util/Themes/defaultTheme';
import { setRequiredChangePassword } from 'util/ControlUtils';
import userManager from 'util/IdentityOidc';
import observer from 'util/Observer';
import * as Utils from 'util/ControlUtils';
import { IDENTITY_SERVER_ENDPOINT_URL } from 'constants/Constants';

import * as Interface from 'interfaces';

import Operator from 'models/Operator';
import Theme from 'models/Theme';
import Tenant from 'models/Tenant';
import 'assets/vendors/style';

import AppRoute from './Route';
import * as RouteLoader from 'pages/RouteLoader';

declare var document: any;

interface Props extends Interface.PagePropsInterface {
    isLoadingUser?: boolean;
}

interface State {
    isConnected: boolean;
    isFullConnected: boolean;
    forceLogout: boolean;
}

interface RestrictProps {
    isFullConnected: boolean;
    component: React.ComponentType<
    any
    > /* eslint @typescript-eslint/no-explicit-any: 0 */;
    path: string;
    oidc: Interface.StateToPropInterface['oidc'];
}

const RestrictedRoute: React.SFC<RestrictProps> = ({
    component: Component,
    isFullConnected,
    oidc,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return oidc.user && !oidc.user.expired ? (
                    <Component {...props}
                        isFullConnected={isFullConnected} />
                ) : (
                    window.location.pathname !== '/callback' &&
                    userManager.signinRedirect({
                        data: { path: window.location.pathname }
                    })
                );
            }}
        />
    );
};

class Layout extends Component<
Props & Interface.StateToPropInterface & RouteComponentProps,
State
> {
    constructor(
        props: Props & Interface.StateToPropInterface & RouteComponentProps
    ) {
        super(props);
        this.state = {
            isConnected: false,
            isFullConnected: false,
            forceLogout: false
        };
    }

    componentDidMount() {
        document.fonts.ready.then(function () { 
            console.log('Font Loaded.');
        });
        window.addEventListener(
            'resize',
            function() {
                // Get screen size (inner/outerWidth, inner/outerHeight)
                observer.publish('windowResizeEvent', window.innerWidth);
            },
            false
        );

        observer.subscribe('oidcEvent', data => {
            if (data) {
                this.cacheAllAPIIfPossible();
            }
        });

        observer.subscribe('forceLogoutEvent', () => {
            this.setState({forceLogout: true});
        });

        // check if user is signed in
        userManager.getUser().then(user => {
            if (user) {
                const { expired, profile } = user;

                // Force user to change password at first time login
                setRequiredChangePassword(profile);

                if (profile.rememberLogin === 'True') {
                    this.reGenerateNewToken(user);
                } else {
                    if (expired) {
                        observer.publish('forceLogoutEvent');
                    } else {
                        this.reGenerateNewToken(user);
                    }
                }
            } else if (window.location.pathname !== '/callback') {
                userManager.signinRedirect({
                    data: { path: window.location.pathname }
                });
            }
        });

        try {
            const logoData = JSON.parse(localStorage.logoData);
            store.dispatch(actions.setTenantLogo(logoData.logoUri));
        } catch (e) {
            localStorage.logoData = '';
        }

        observer.subscribe('loadLogoEvent', data => {
            if (data) {
                let tenant: Tenant = data;
                store.dispatch(actions.setTenantLogo(tenant.logoUri));
                localStorage.logoData = JSON.stringify({
                    logoUri: tenant.logoUri
                });
            }
        });

        observer.subscribe('loaduserProfile', (data: Operator) => {
            if (data) {
                store.dispatch(actions.setOperator(data));
            }
        });

        // Theme default
        observer.subscribe('loadThemeEvent', data => {
            let primaryColor = '';
            let secondaryColor = '';
            let accessibleColor = '';
            if (data) {
                const rightName = RouteLoader.AppearanceSettingRoute.rightName;
                this.props.conmanService.getTenantActiveTheme(Utils.getSubTenantId(), rightName).then(res => {
                    const activeTheme: Theme = res.data && res.data[0];
                    primaryColor = activeTheme.primaryColor;
                    secondaryColor = activeTheme.secondaryColor;
                    accessibleColor = this.convertColorHexToRGB(activeTheme.primaryColor);
                    this.loadActiveTheme(primaryColor, secondaryColor, accessibleColor);
                });
            } else {
                try {
                    if (localStorage.primaryColor && localStorage.primaryColor !== '') {
                        primaryColor = localStorage.primaryColor;
                    }
                    if (
                        localStorage.secondaryColor &&
                        localStorage.secondaryColor !== ''
                    ) {
                        secondaryColor = localStorage.secondaryColor;
                    }
                    if (
                        localStorage.accessibleColor &&
                        localStorage.accessibleColor !== ''
                    ) {
                        accessibleColor = localStorage.accessibleColor;
                    }
                    this.loadActiveTheme(primaryColor, secondaryColor, accessibleColor);
                } catch (e) {
                    this.loadActiveTheme(primaryColor, secondaryColor, accessibleColor);
                }
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('oidcEvent');
        observer.unsubscribe('loadLogoEvent');
        observer.unsubscribe('loadThemeEvent');
        observer.unsubscribe('loaduserProfile');
        observer.unsubscribe('forceLogoutEvent');
        
    }

    openConnectSignalR = (connection, successMsg, failMsg) => {
        connection
            .start()
            .then(() => {
                console.log(successMsg);
            })
            .catch(() => {
                console.log(failMsg);
            });
    };

    connectSignalR = (user: Interface.StateToPropInterface['oidc']['user']) => {
        const { profile } = user;
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${IDENTITY_SERVER_ENDPOINT_URL}/roleChanged`)
            .build();
        this.openConnectSignalR(connection, 'connected', 'connect failed');
        connection.onclose(err => {
            if (err) {
                this.openConnectSignalR(connection, 'reconnected', 'reconnect failed');
            }
        });
        connection.on('RoleChanged', data => {
            if (data && !_.isEmpty(data) && profile) {
                const listUser = typeof data === 'string' ? JSON.parse(data) : data;
                listUser.forEach(item => {
                    if (item.toLowerCase() === profile.uid.toLowerCase()) {
                        Utils.handleSessionExpired();
                    }
                });
            }
        });
    };

    convertColorHexToRGB(hex) {
        hex = '0x' + hex.substr(1, hex.length);
        var rgbaColor = {
            r: (hex >> 16) & 0xff,
            g: (hex >> 8) & 0xff,
            b: hex & 0xff,
            a: '1'
        };
        return `calc(${Utils.enforcingHighContrastColors(
            rgbaColor.r,
            rgbaColor.g,
            rgbaColor.b
        )})`;
    }

    loadActiveTheme(pColor, sColor, aColor) {
        document.body.style.setProperty('--primary-color', pColor);
        document.body.style.setProperty('--primary-color-darken', sColor);
        document.body.style.setProperty('--accessible-color', aColor);
        localStorage.primaryColor = pColor;
        localStorage.secondaryColor = sColor;
        localStorage.accessibleColor = aColor;
    }

    reGenerateNewToken(user: Interface.StateToPropInterface['oidc']['user']) {
        this.setState({ isConnected: false });
        store.dispatch(setProfileAuth(user));
        observer.publish('oidcEvent', true);
    }

    getQuickOperator(profile) {
        let operator = new Operator();
        operator.id = profile.uid;
        operator.userName = profile.username;
        operator.firstName = profile.firstName;
        operator.lastName = profile.lastName;
        operator.fullName = profile.firstName + ' ' + profile.lastName;
        operator.tenantId = profile.tenantId;
        operator.subTenantId = profile.subTenantId;
        operator.right.tenant = [];
        Object.keys(profile).forEach(e => {
            if (e.indexOf('access.right') > -1) {
                const rightSubtenant = e.split('/');
                const rightNames = profile[e].split(',');
                rightNames.forEach(rightName => {
                    operator.right.tenant = {...operator.right.tenant, 
                        [rightName]: rightSubtenant[1]
                    };
                });
            }
        });
        return operator;
    }

    setAllClaimsToLocalStorage(rights) {
        try{
            localStorage.allClaims = JSON.stringify(rights);
        } catch(e) {
            localStorage.allClaims = '';
        }
    }

    cacheAllAPIIfPossible() {
        const { history } = this.props;
        const redirectCondition =
            history.location.pathname.indexOf('/change-password') < 0 &&
            localStorage.isRequiredChangePassword === '1';
        if (redirectCondition) {
            history.push('/config/change-password');
        }

        userManager.getUser().then(user => {
            if (user && !user.expired) {
                const { profile } = user;
                
                store.dispatch(setProfileAuth(user));
                
                const operator = this.getQuickOperator(profile);
                this.setAllClaimsToLocalStorage(operator.right);
                store.dispatch(actions.setOperator(operator));
                this.setState({ isConnected: true });

                this.props.conmanService
                    .getOperator(profile.uid)
                    .then(res => {
                        let operator: Operator = res;
                        this.setAllClaimsToLocalStorage(operator.right);

                        if (operator.subTenantId && operator.subTenantId !== '') {
                            store.dispatch(
                                actions.setUserRoles({
                                    tenantRoles: operator.roles
                                        ? operator.roles['tenantRoles']
                                        : [],
                                    userRoles: operator.userRoles
                                })
                            );
                        }
                        this.setState({isFullConnected: true});
                        observer.publish('loaduserProfile', operator);
                        observer.publish('loadThemeEvent', true);
                        observer.publish('updateRightEvent');
                        this.connectSignalR(user);
                    });
                this.props.conmanService
                    .getSubTenant(profile.subTenantId, RouteLoader.AppearanceSettingRoute.rightName)
                    .then(data => {
                        let tenant: Tenant = data;
                        observer.publish('loadLogoEvent', tenant);
                    });
            }
        });
    }

    extend(obj, src) {
        Object.keys(src).forEach(key => {
            obj[key] = src[key];
        });
        return obj;
    }

    render() {
        const { forceLogout } = this.state;
        if (forceLogout) {
            store.dispatch<any>(userSignOut());
        }
        const { match, location, isLoadingUser, oidc } = this.props;

        // wait for user to be loaded, and location is known
        if (isLoadingUser || !location) {
            return null;
        }

        const applyTheme = createMuiTheme(defaultTheme);

        return (
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Suspense fallback={null}>
                        <Switch>
                            {this.state.isConnected ? (
                                <RestrictedRoute
                                    path={`${match.url}`}
                                    oidc={oidc!}
                                    component={AppRoute}
                                    isFullConnected={this.state.isFullConnected}
                                />
                            ) : null}
                            <Route path="/callback" component={Callback} />
                            <Route path="/silent" component={Silent} />
                            {this.state.isConnected && this.state.isFullConnected ? <Route component={Error404} /> : null}
                        </Switch>
                    </Suspense>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = ({
    oidc,
    conman
}: {
    oidc: Interface.StateToPropInterface['oidc'];
    conman: Props['conman'];
}) => {
    return {
        oidc,
        conman
    };
};

export default connect(
    mapStateToProps,
    {}
)(Layout);
