import FormItem from 'components/FormV2';
import TextBoxGroup from 'components/FormV2/TextBoxGroup';
import { FieldModel } from 'models/Field';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IsValidEmail } from 'util/ControlUtils';
import observer from 'util/Observer';
import ThisInterface, { EmailTypes } from './interface';

class EmailSummary extends React.Component<
ThisInterface['props'] & WithTranslation ,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);
        const {
            memberData: { email }
        } = props;
        this.state = {
            email: '',
            emailValidation: new FieldModel(),
            checkedEmail:
                email && email !== '' ? EmailTypes.existEmail : EmailTypes.noEmail,
            disbledEmail: true
        };
    }

    validateEmail = (emailType: string, email: string) => {
        switch (emailType) {
            case EmailTypes.noEmail:
                return { email: null, error: false, emailType };
            case EmailTypes.existEmail:
                return { email, error: false, emailType };
            case EmailTypes.newEmail:
                return email && email !== '' && IsValidEmail(email)
                    ? { email, emailType }
                    : { email, error: true, emailType };
            default:
                return false;
        }
    };

    handleChangeValue = (fieldName: string, name: string) => {
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    [fieldName]: name,
                    disbledEmail: name !== EmailTypes.newEmail
                };
            },
            () => {
                let emailDetail = {
                    email: '',
                    emailType: ''
                };
                const {
                    memberData: { email }
                } = this.props;
                if (name !== EmailTypes.newEmail) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            email: '',
                            emailValidation: {
                                ...prevState.emailValidation,
                                isDirty: false,
                                customMsg: ''
                            }
                        };
                    });
                    const isExistMail = name === EmailTypes.existEmail;
                    emailDetail = {
                        email: isExistMail ? email : '',
                        emailType: isExistMail
                            ? EmailTypes.existEmail
                            : EmailTypes.noEmail
                    };
                } else {
                    emailDetail = {
                        email: '',
                        emailType: EmailTypes.newEmail
                    };
                }
                let validatedChange = this.validateEmail(emailDetail.emailType, emailDetail.email);
                observer.publish(
                    'communicationEmailChanged',
                    validatedChange
                );
                if(this.props.handleChange) this.props.handleChange(validatedChange);
            }
        );
    };

    handleEmailChanged = (fieldName: string, email: string) => {
        const isDirty = !IsValidEmail(email) && email !== '';
        const { t } = this.props;
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    email,
                    [fieldName]: {
                        ...prevState[fieldName],
                        isDirty,
                        customMsg: isDirty
                            ? t('PAGE.PAYMENT_DETAIL.EMAIL_WRONG_FORMAT')
                            : ''
                    }
                };
            },
            () => {
                let validatedChange = this.validateEmail(EmailTypes.newEmail, email);
                observer.publish(
                    'communicationEmailChanged',
                    validatedChange
                );
                if(this.props.handleChange) this.props.handleChange(validatedChange);
            }
        );
    };

   

    render() {
        const { memberData, t } = this.props;
        const { checkedEmail, disbledEmail, email, emailValidation } = this.state;
        const fieldExistEmail = new FieldModel();

        const radioGroups = [
            {
                props: {
                    fieldLabel: t('PAGE.MEMBERSHIPS.EDIT.STEP_4.NO_COMMUNICATION'),
                    fieldName: 'checkedEmail',
                    data: EmailTypes.noEmail,
                    fields: [],
                    attribute: {
                        checked: checkedEmail === EmailTypes.noEmail
                    }
                }
            },
            {
                props: {
                    fieldLabel: `${t('PAGE.MEMBERSHIPS.EDIT.STEP_4.EXISTING_EMAIL')}: ${memberData.email}`,
                    fieldName: 'checkedEmail',
                    data: EmailTypes.existEmail,
                    fields: [
                        {
                            ...fieldExistEmail,
                            key: 'checkedEmail',
                            isVisible: memberData.email ? true : false
                        }
                    ],
                    attribute: {
                        checked: checkedEmail === EmailTypes.existEmail
                    }
                }
            },
            {
                props: {
                    fieldLabel: t('PAGE.MEMBERSHIPS.EDIT.STEP_4.NEW_EMAIL'),
                    fieldName: 'checkedEmail',
                    data: EmailTypes.newEmail,
                    fields: [],
                    attribute: {
                        checked: checkedEmail === EmailTypes.newEmail
                    }
                }
            }
        ];

        return (
            <React.Fragment>
                <div className="row col">
                    <p>
                        {t('PAGE.MEMBERSHIPS.EDIT.STEP_4.SELECT_COMMUNICATION_OPTIONS')}
                    </p>
                </div>
                <div className="row">
                    <div className="form-check ml-4">
                        {radioGroups.map((item, index) => {
                            return (
                                <FormItem
                                    key={index}
                                    {...item.props}
                                    type="radio"
                                    onChangeHandle={this.handleChangeValue}
                                    className="my-3"
                                />
                            );
                        })}
                    </div>
                    <div className="col-12">
                        <div className="payment-email">
                            <label htmlFor="email-address">
                                {t('PAGE.MEMBERSHIPS.EDIT.STEP_4.EMAIL_ADDRESS')}
                            </label>
                            <div className="col-5 ml-5 mb-3">
                                <TextBoxGroup
                                    className="mb-3"
                                    onChangeHandle={this.handleEmailChanged}
                                    data={email}
                                    fieldName="emailValidation"
                                    htmlFor="emailValidation"
                                    attribute={{
                                        disabled: disbledEmail
                                    }}
                                    placeholder={t('PAGE.MEMBERSHIPS.VIEW.VIEW_PERSONAL_DETAIL.EMAL_ADDRESS')}
                                    fieldValidation={emailValidation}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(EmailSummary);
