import React from 'react';
import CustomTable from 'components/Table';
import CollapseButton from 'components/CollapseButton';
import Pagination from 'components/Pagination';
import Loading from 'components/Loading';
import ThisInterface from './interface';
import { useTranslation } from 'react-i18next';


const renderErrorItem = (errorList: ThisInterface['state']['operatorList']) => {
    const { t } = useTranslation();
	
    return errorList.map(item => {
        return (
            <tr key={item.number}>
                <td>{item.number}</td>
                <td>{item.username ? item.username : t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.ERR_INVALID_FIELD_FORMAT')}</td>
                <td className="rounded-top-left text-danger">{t(item.message)}</td>
            </tr>
        );
    });
};

const headers = [
    {
        key: 1,
        title: 'BULK_IMPORT.ROW_ORDER'
    },
    {
        key: 2,
        title: 'BULK_IMPORT.ACCOUNT_NAME'
    },
    {
        key: 3,
        title: 'BULK_IMPORT.ERRORS'
    }
];

const showImportInfo = (
    operatorPagination: ThisInterface['state']['operatorPagination']
) => {
    const { t } = useTranslation();
    return (
        <div className="show-info">
            <div className="total-acc-err">
                <div className="acc-validated">
                    <span className="text-primary">
                        <i className="icon icon-checked-circle" />
                    </span>
                    <span>
                        {t(
                            'PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.ACCOUNT_VALIDATED' ,
                            { number: operatorPagination.metadata.TotalItemSuccess }
                        )}
                    </span>
                </div>
                <span className="line-vertical" />
                <div className="acc-invalid">
                    <span className="text-danger">
                        <i className="icon icon-detail" />
                    </span>
                    <span>
                        {t(
                            'PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.ACCOUNT_INVALID' ,
                            { number: operatorPagination.pagination.totalItemsCount }
                        )}
                    </span>
                </div>
            </div>
            <p className="text">
                {t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.IMPORTED_NOTE')}
            </p>
        </div>
    );
};

const renderErrorList = (_this: ThisInterface) => {
    const { operatorList, isLoading, currentPage } = _this.state;

    if (operatorList) {
        return (
            <>
                {isLoading ? (
                    <Loading />
                ) : operatorList.length > 0 && (
                    <div className="list-account">
                        <div className="list-page">
                            {showImportInfo(_this.state.operatorPagination)}
                            <Pagination
                                onPageChange={_this.handleChangePage}
                                itemsCountPerPage={_this.state.operatorPagination.pagination.pageSize}
                                totalItemsCount={_this.state.operatorPagination.pagination.totalItemsCount}
                                activePage={currentPage}
                            />
                        </div>
                        <div className="table-account table-responsive">
                            <CustomTable
                                headers={headers}
                                tableContent={renderErrorItem(operatorList)}
                                renderingCondition={true}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
    return <></>;
};

const renderBulkUpload = (_this: ThisInterface) => {
    const { fileName, errorMessage, isLoading } = _this.state;

    const { t } = _this.props;

    return (
        <div className="bulk-upload">
            <p>
                <i>{t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.BULK_DESCRIPTION')}</i>
                <span className="text-warning">
                    <i>{t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.WARNING')}</i>
                </span>
            </p>
            <div className="input-upload">
                <div
                    className="input-file"
                    onClick={() => (!isLoading ? _this.handleOpenFile() : {})}
                >
                    <input
                        id="input-file"
                        value={fileName}
                        onChange={() => {}}
                        className="f-input form-control"
                        placeholder={t('COMMON.BROWSE')}
                    />
                </div>
                <div
                    className="fileUpload btn btn-primary"
                    onClick={_this.handleUploadFile}
                >
                    <span>{t('BUTTON.UPLOAD')}</span>
                </div>
            </div>
            {errorMessage && (
                <p className="text-err text-danger">
                    <span>{t(errorMessage)}</span>
                </p>
            )}
            <input
                type="file"
                accept=".csv"
                ref={node => (_this.inputFile = node)}
                onChange={_this.handleSelectFile}
                style={{ display: 'none' }}
            />
        </div>
    );
};

const renderCollapseContent = (_this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <>
            <a className="btn-down" href="#">
                <span className="icon-download-ico">
                    <i>{t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.DOWNLOAD_USER_GUIDE')}</i>
                </span>
            </a>
            <a
                className="btn-down"
                href="https://ewssaas2.blob.core.windows.net/templates/BulkImport_Operators.csv"
            >
                <span className="icon-download-ico">
                    <i>{t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.DOWNLOAD_TEMPLATE')}</i>
                </span>
            </a>
        </>
    );
};

const template = (_this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <section className="bulk-import">
            <div className="title-content">
                <p>{t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.BULK_IMPORT_OPERATOR')}</p>
            </div>
            <CollapseButton
                title={t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.AZURE_AD')}
                description={t('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.AZURE_DESCRIPTION')}
                content={renderCollapseContent(_this)}
                isOpen={_this.state.isCollapse}
                openCollapsedContent={_this.handleOpenCollapseBtn}
            />
            {renderBulkUpload(_this)}
            {renderErrorList(_this)}
        </section>
    );
};

export default template;
