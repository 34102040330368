import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ThisInterface, { PropsInterface, CpdSteps } from './interface';
import template from './template';
import moment from 'moment';
import Swal from 'sweetalert2';
import { GENERAL_DATE_FORMAT } from 'constants/Constants'
import * as DefaultConstants from 'constants/DefaultConstants';
import observer from 'util/Observer';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import { getFieldConfig, filterValuableFieldsOnly } from 'util/FieldConfiguration';
import {
    ChangePersonalDetailsModel,
    ChangePersonalDetailsFieldConfig
} from 'models/ChangePersonalDetailsModel';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    PersonalDetailFieldsModel,
    ContactDetailsFieldsModel,
    AddressDetailsFieldsModel,
    ContactedDetailsFieldsModel,
    EmergencyContactFieldsModel,
    OtherFieldsFieldsModel,
    LookupModel,
    ContactDetailsModel,
    AddressDetailsModel,
    ContactedDetailsModel,
    EmergencyContactModel,
    OtherFieldsModel,
    PersonalDetailsModel
} from 'models/PersonalDetails';
import * as Route from 'pages/RouteLoader';
import { getAllRights } from 'util/ControlUtils';

class ChangePersonalDetails extends React.Component<ThisInterface['props'] & PropsInterface & WithTranslation, ThisInterface['state']> {
    constructor(props: ThisInterface["props"] & PropsInterface & WithTranslation) {
        super(props);

        this.state = {
            activeStep: CpdSteps.PERSONAL_DETAILS,
            dateFormat: GENERAL_DATE_FORMAT,
            somethingChanged: false,
            originalPersonalDetails: new ChangePersonalDetailsModel(),
            newPersonalDetails: new ChangePersonalDetailsModel(),
            fieldConfigs: new ChangePersonalDetailsFieldConfig(),
            previousSection: props.location.state.previousSection
        }
    }

    componentDidMount() {
        observer.subscribe('flagUnsavedChangeEvent', data => {
            this.setState({ somethingChanged: data });
        });

        const {
            membershipService,
            conmanService,
            auth: { user: { profile } },
            match,
            location
        } = this.props;

        const { state } = location;
        const memberId = match.params.id;

        //Get local field configs
        const personalDetailsFieldLocalConfigs = this.getPersonalFieldConfiguration(InitialFieldConfigs.data);
        const contactDetailsFieldLocalConfigs = this.getContactFieldConfiguration(InitialFieldConfigs.data);
        const addressDetailsFieldLocalConfigs = this.getAddressFieldConfiguration(InitialFieldConfigs.data);
        const contactedDetailsFieldLocalConfigs = this.getContactedFieldConfiguration(InitialFieldConfigs.data);
        const emergencyContactFieldsLocalConfigs = this.getEmergencyContactFieldConfiguration(InitialFieldConfigs.data);
        const otherFieldsFieldsLocalConfigs = this.getOtherFieldsFieldConfiguration(InitialFieldConfigs.data);
        const rightName = Route.ChangePersonalDetailsRoute.rightName;

        if (state && state.subTenantId) {
            Promise.all([
                membershipService.getMemberById(state.subTenantId, memberId, Route.ChangePersonalDetailsRoute.rightName),
                membershipService.getFieldsConfiguration(state.subTenantId, Route.ChangePersonalDetailsRoute.rightName),
                conmanService.getSystemConfigs(state.subTenantId, rightName)
            ]).then(([res1, res2, res3]) => {
                if (res1) {
                    const personalDetails = this.populateStateFromResonse(res1);
                    //get field configs from service to override local config if any
                    const personalDetailsFieldConfigs = filterValuableFieldsOnly(this.getPersonalFieldConfiguration(res2.data));
                    const contactDetailsFieldConfigs = filterValuableFieldsOnly(this.getContactFieldConfiguration(res2.data));
                    const addressDetailsFieldConfigs = filterValuableFieldsOnly(this.getAddressFieldConfiguration(res2.data));
                    const contactedDetailsFieldConfigs = filterValuableFieldsOnly(this.getContactedFieldConfiguration(res2.data));
                    const emergencyContactFieldsConfigs = filterValuableFieldsOnly(this.getEmergencyContactFieldConfiguration(res2.data));
                    const otherFieldsFieldsConfigs = filterValuableFieldsOnly(this.getOtherFieldsFieldConfiguration(res2.data));
                    //system config
                    const config = res3.find(e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DOB_FORMAT);
                    const dateFormat = config ? config.value : GENERAL_DATE_FORMAT;

                    this.setState({
                        dateFormat: dateFormat,
                        originalPersonalDetails: personalDetails,
                        newPersonalDetails: personalDetails,
                        fieldConfigs: {
                            personalDetailsFieldConfigs: {
                                ...personalDetailsFieldLocalConfigs,
                                ...personalDetailsFieldConfigs

                            },
                            contactDetailsFieldConfigs: {
                                ...contactDetailsFieldLocalConfigs,
                                ...contactDetailsFieldConfigs
                            },
                            addressDetailsFieldConfigs: {
                                ...addressDetailsFieldLocalConfigs,
                                ...addressDetailsFieldConfigs
                            },
                            contactedDetailsFieldConfigs: {
                                ...contactedDetailsFieldLocalConfigs,
                                ...contactedDetailsFieldConfigs
                            },
                            emergencyContactFieldsConfigs: {
                                ...emergencyContactFieldsLocalConfigs,
                                ...emergencyContactFieldsConfigs
                            },
                            otherFieldsFieldsConfigs: {
                                ...otherFieldsFieldsLocalConfigs,
                                ...otherFieldsFieldsConfigs
                            },
                        }
                    });
                }
            }).catch(() => {
                this.goBack();
            });
        } else {
            this.props.history.push('/');
        }

    }

    componentWillUnmount() {
        observer.unsubscribe('flagUnsavedChangeEvent');
    }

    //#region Get Field Configuration 

    getPersonalFieldConfiguration = (fieldConfiguration: any): PersonalDetailFieldsModel => {
        const dateOfBirthConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_3);
        const titleConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_TITLE);
        const genderConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_GENDER);
        const firstNameConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_FIRST_NAME);
        const lastNameConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_LAST_NAME);
        const nationalConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_NATIONAL);
        const nationalIdConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_NATIONAL_ID);
        const otherNationalIdConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_OTHER_NATIONAL_ID);
        const parentalConsentConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PARENTAL_CONSENT_PROVIDED);
        const preferedLanguageConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_MEMBER_LANGUAGE_PREFERENCES);

        return {
            titleConfig,
            genderConfig,
            firstNameConfig,
            lastNameConfig,
            dateOfBirthConfig,
            nationalConfig,
            nationalIdConfig,
            otherNationalIdConfig,
            preferedLanguageConfig,
            parentalConsentConfig
        }
    }

    getContactFieldConfiguration = (fieldConfiguration: any): ContactDetailsFieldsModel => {
        const telephoneNumberConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_TELEPHONE_NUMBER);
        const mobilePhoneConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_MOBILE_TELEPHONE);
        const homePhoneConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_HOME_TELEPHONE);
        const workPhoneConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_WORK_TELEPHONE);
        const emailConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_EMAIL_ADDRESS);
        const overrideEmailConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_OVERRIDE_EMAIL);

        return {
            telephoneNumberConfig,
            mobilePhoneConfig,
            homePhoneConfig,
            workPhoneConfig,
            emailConfig,
            overrideEmailConfig
        }
    }

    getAddressFieldConfiguration = (fieldConfiguration: any): AddressDetailsFieldsModel => {
        const addressNumberConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_NUMBER);
        const addressLine1Config = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_ADDRESS_LINE_1);
        const addressLine2Config = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_ADDRESS_LINE_2);
        const cityConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_ADDRESS_CITY);
        const stateConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_ADDRESS_STATE);
        const countryConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_ADDRESS_COUNTRY);
        const postCodeConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_ADDRESS_POSTCODE);
        const goneAwayConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_GONE_AWAY);
        return {
            addressNumberConfig,
            addressLine1Config,
            addressLine2Config,
            cityConfig,
            stateConfig,
            countryConfig,
            postCodeConfig,
            goneAwayConfig
        };
    }

    getContactedFieldConfiguration = (fieldConfiguration: any): ContactedDetailsFieldsModel => {
        const applicationRefConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_MEMBERSHIP_APPLICATION_REF);
        const contactMethodConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED);
        const thirdPartyContactConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_MEMBER_HAPPY_TO_BE_CONTACTED_BY_THIRD_PARTY);
        const preferredContactMethodConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD);
        const postConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST);
        const homePhoneConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE);
        const mobileConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE);
        const workPhoneConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE);
        const emailConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL);
        const smsConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS);
        const marketingSourceConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_MARKETING_SOURCE);

        return {
            applicationRefConfig,
            memberHappyToBeContactConfig: contactMethodConfig,
            thirdPartyContactConfig,
            preferredContactMethodConfig,
            postConfig,
            homePhoneConfig,
            mobileConfig,
            workPhoneConfig,
            emailConfig,
            smsConfig,
            marketingSourceConfig
        };
    }

    getEmergencyContactFieldConfiguration = (fieldConfiguration: any): EmergencyContactFieldsModel => {
        const contactNameConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_NAME);
        const contactNumberConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_EMERGENCY_CONTACT_PHONE_NUMBER);

        return {
            contactNameConfig,
            contactNumberConfig
        };
    }

    getOtherFieldsFieldConfiguration = (fieldConfiguration: any): OtherFieldsFieldsModel => {
        const companyNameConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_COMPANY_NAME);
        const occupationConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_OCCUPATION);
        const referredByMemberNameConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_REFERRED_BY_NAME);
        const referredByMembershipNumberConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_REFERRED_BY_MEMBERSHIP_NUMBER);
        const otherCommissionNoteConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_OTHER_COMMISSION_NOTE);
        const generalNoteConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_GENERAL_NOTE);
        const aiaVitalityCategoryConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_AIA_VITALITY_CATEGORY);
        const recurringCardTypeConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_RECURRING_CARD_TYPE);

        return {
            companyNameConfig,
            occupationConfig,
            referredByMemberNameConfig,
            referredByMembershipNumberConfig,
            otherCommissionNoteConfig,
            generalNoteConfig,
            aiaVitalityCategoryConfig,
            recurringCardTypeConfig
        };
    }
    //#endregion

    populateStateFromResonse = (response: any): ChangePersonalDetailsModel => {
        const memberId = response.id;
        const subTenantId = response.subTenantId || '';

        const personalDetails: PersonalDetailsModel = {
            title: response.title || new LookupModel(),
            gender: response.gender || new LookupModel(),
            firstName: response.firstName || '',
            lastName: response.lastName || '',
            nationalIdNumber: response.nationalId || '',
            otherNationalIdNumber: response.otherNationalId || '',
            dateOfBirth: response.dateOfBirth || null,
            preferedLanguage: response.languagePreference || new LookupModel(),
            isParentalConsentProvided: response.isParentConsentProvided || false
        }

        const contactDetails: ContactDetailsModel = {
            mobilePhone: {
                isValid: response.mobilePhone ? true : false,
                phoneNumber: response.mobilePhone || '',
                countryCode: response.mobilePhoneRegionCode || '',
            },
            mobilePhoneNoLongerUse: response.isMobilePhoneNoLongerInUse || false,
            homePhone: {
                isValid: response.homePhone ? true : false,
                phoneNumber: response.homePhone || '',
                countryCode: response.homePhoneRegionCode || '',
            },
            homePhoneNoLongerUse: response.isHomePhoneNoLongerInUse || false,
            workPhone: {
                isValid: response.workingPhone ? true : false,
                phoneNumber: response.workingPhone || '',
                countryCode: response.workingPhoneRegionCode || '',
            },
            workPhoneNoLongerUse: response.isWorkingPhoneNoLongerInUse || false,
            email: response.email || '',
            emailNoLongerUse: response.isEmailNoLongerInUse || false,
            isEmailValid: response.email || false,
            overrideEmailOption: response.overrideEmail || new LookupModel()
        }

        const addressDetails: AddressDetailsModel = {
            addressNumber: response.addressNumber || '',
            addressLine1: response.line1 || '',
            addressLine2: response.line2 || '',
            city: response.city || '',
            state: response.state || '',
            postCode: response.postCode || '',
            country: response.country || new LookupModel(),
            goneAway: response.isGoneAway || false
        }

        const contactedDetails: ContactedDetailsModel = {
            applicationRef: response.applicationRef || '',
            memberHappyToBeContact: response.isHappyToBeContacted || false,
            thirdPartyContact: response.isAllowContactByThirdParty || false,
            preferredContactMethod: response.preferredContactMethod || [],
            marketingSource: response.source || new LookupModel()
        }

        const emergencyContact: EmergencyContactModel = {
            contactName: response.emergencyContactName || '',
            contactNumber: {
                isValid: response.emergencyContactPhoneNumber ? true : false,
                phoneNumber: response.emergencyContactPhoneNumber || '',
                countryCode: response.emergencyContactPhoneNumberRegionCode || ''
            }
        }

        const healthQuestions = [];

        const otherFields: OtherFieldsModel = {
            companyName: response.companyName || '',
            occupation: response.occupation || '',
            referredByMemberName: response.referredByName || '',
            referredByMembershipNumber: response.referredBymembershipNumber || '',
            otherCommissionNote: response.otherCommisisionNote || '',
            generalNote: response.generalNote || '',
            aiaVitalityCategory: response.aiaVitalityCategory || new LookupModel(),
            recurringCardType: response.recurringCardType || new LookupModel()
        }

        return {
            memberId,
            subTenantId,
            personalDetails,
            contactDetails,
            addressDetails,
            contactedDetails,
            emergencyContact,
            healthQuestions,
            otherFields
        };
    }

    getRequestData = () => {
        const {
            personalDetails,
            contactDetails,
            addressDetails,
            contactedDetails,
            emergencyContact,
            otherFields
        } = this.state.newPersonalDetails;

        const preferredContactMethod = contactedDetails.preferredContactMethod.map(pc => pc.id);
        const birthDate = personalDetails.dateOfBirth
            && moment(personalDetails.dateOfBirth).isValid() ? moment(personalDetails.dateOfBirth).toISOString() : null;
        const data = {
            //Personal details
            titleId: personalDetails.title.id ? personalDetails.title.id : null,
            genderId: personalDetails.gender.id ? personalDetails.gender.id : null,
            firstName: personalDetails.firstName ? personalDetails.firstName : null,
            lastName: personalDetails.lastName ? personalDetails.lastName : null,
            nationalId: personalDetails.nationalIdNumber ? personalDetails.nationalIdNumber : null,
            otherNationalId: personalDetails.otherNationalIdNumber ? personalDetails.otherNationalIdNumber : null,
            dateOfBirth: birthDate,
            isParentalConsentProvided: personalDetails.isParentalConsentProvided,
            preferredLanguageId: personalDetails.preferedLanguage.id ? personalDetails.preferedLanguage.id : null,

            //Contact details
            mobilePhone: contactDetails.mobilePhone.phoneNumber ? contactDetails.mobilePhone.phoneNumber : null,
            mobilePhoneRegionCode: contactDetails.mobilePhone.countryCode ? contactDetails.mobilePhone.countryCode : null,
            isMobilePhoneNoLongerInUse: contactDetails.mobilePhoneNoLongerUse,
            homePhone: contactDetails.homePhone.phoneNumber ? contactDetails.homePhone.phoneNumber : null,
            homePhoneRegionCode: contactDetails.homePhone.countryCode ? contactDetails.homePhone.countryCode : null,
            isHomePhoneNoLongerInUse: contactDetails.homePhoneNoLongerUse,
            workingPhone: contactDetails.workPhone.phoneNumber ? contactDetails.workPhone.phoneNumber : null,
            workingPhoneRegionCode: contactDetails.workPhone.countryCode ? contactDetails.workPhone.countryCode : null,
            isWorkingPhoneNoLongerInUse: contactDetails.workPhoneNoLongerUse,
            email: contactDetails.email ? contactDetails.email : null,
            isEmailNoLongerInUse: contactDetails.emailNoLongerUse,
            overrideEmailId: contactDetails.overrideEmailOption.id ? contactDetails.overrideEmailOption.id : null,

            //Address details
            addressNumber: addressDetails.addressNumber ? addressDetails.addressNumber : null,
            line1: addressDetails.addressLine1 ? addressDetails.addressLine1 : null,
            line2: addressDetails.addressLine2 ? addressDetails.addressLine2 : null,
            city: addressDetails.city ? addressDetails.city : null,
            state: addressDetails.state ? addressDetails.state : null,
            postCode: addressDetails.postCode ? addressDetails.postCode : null,
            countryId: addressDetails.country.id ? addressDetails.country.id : null,
            goneAway: addressDetails.goneAway,

            //Contacted details
            applicationRef: contactedDetails.applicationRef ? contactedDetails.applicationRef : null,
            isHappyToBeContacted: contactedDetails.memberHappyToBeContact,
            isAllowContactByThirdParty: contactedDetails.thirdPartyContact,
            preferredContactMethod: preferredContactMethod || null,
            sourceId: contactedDetails.marketingSource.id ? contactedDetails.marketingSource.id : null,

            //emergency Contact
            emergencyContactName: emergencyContact.contactName ? emergencyContact.contactName : null,
            emergencyContactPhoneNumber: emergencyContact.contactNumber.phoneNumber ? emergencyContact.contactNumber.phoneNumber : null,
            emergencyContactPhoneNumberRegionCode: emergencyContact.contactNumber.countryCode ? emergencyContact.contactNumber.countryCode : null,

            //other fields
            companyname: otherFields.companyName ? otherFields.companyName : null,
            occupation: otherFields.occupation ? otherFields.occupation : null,
            referredByName: otherFields.referredByMemberName ? otherFields.referredByMemberName : null,
            referredByMembershipNumber: otherFields.referredByMembershipNumber ? otherFields.referredByMembershipNumber : null,
            otherCommisisionNote: otherFields.otherCommissionNote ? otherFields.otherCommissionNote : null,
            generalNote: otherFields.generalNote ? otherFields.generalNote : null,
            aIAVitalityCategoryId: otherFields.aiaVitalityCategory.id ? otherFields.aiaVitalityCategory.id : null,
            recurringCardTypeId: otherFields.recurringCardType.id ? otherFields.recurringCardType.id : null
        }

        return data;
    }

    checkClaim = (rightName: string) => {
        const claims = getAllRights();

        if (!rightName || rightName === '') return true;

        let hasRight = false;
        const existRight = claims.find(c => c === rightName);
        if (existRight && existRight.length > 0) {
            hasRight = true;
        }
        return hasRight;
    }

    goBack = () => {
        const { history, match, location } = this.props;
        const { state } = location;
        history.push(`/memberships/members/${match.params.id}/view`, {
            subTenantId: state.subTenantId,
            previousSection: this.state.previousSection
        });
    }

    handleCancel = () => {
        const { t } = this.props;
        if (this.state.somethingChanged) {
            this.goBack();
        }
        else {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('COMMON.CANCEL_NOTIFICATION'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(res => {
                if (res.value) {
                    this.goBack();
                }
            });
        }
    };

    handlePrevious = () => {
        this.setState({
            activeStep: CpdSteps.PERSONAL_DETAILS
        })
    };

    handleNext = (newPersonalDetails: ChangePersonalDetailsModel) => {
        this.setState({
            newPersonalDetails,
            activeStep: CpdSteps.MEMBER_SUMMARY
        })
    };

    handleFinish = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.CONFIRM_FINISH'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.YES'),
            cancelButtonText: t('BUTTON.NO')
        }).then(res => {
            if (!res.value) return;

            const { membershipService, history, location } = this.props;
            const { memberId, subTenantId } = this.state.originalPersonalDetails;
            const data = this.getRequestData();

            Swal.fire({
                html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                showConfirmButton: false,
                allowOutsideClick: false
            });

            const rightName = Route.ChangePersonalDetailsRoute.rightName;

            membershipService.changePersonalDetails(subTenantId, memberId, data, rightName)
                .then(res => {
                    observer.publish('flagUnsavedChangeEvent', false);
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('MSG.MEMBERSHIPS.CREATE.SAVE_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    }).then(() => {
                        const { state } = location;
                        history.push(`/memberships/members/${res.id}/view`, {
                            subTenantId: state.subTenantId,
                            previousSection: this.state.previousSection
                        });
                    });
                })
                .catch(error => {
                    Swal.close();
                    observer.publish('flagUnsavedChangeEvent', false);
                    history.push(`/memberships/members`);
                });
        });
    };

    render() {
        return template(this);
    }
}


const mapStateToProps = ({ auth }) => {
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(ChangePersonalDetails)));