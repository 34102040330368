import React, { useState, useEffect } from 'react';
import { t } from 'util/I18nMessages';
import { FieldModel } from 'models/Field';
import {
    FormItemInterface,
    isValidOrNot,
    renderErrorHtml,
    AdvanceOptions
} from './index';
import OptionModel from 'models/Option';
import CommonService from 'services/common.service';
import axios from 'axios';
import i18n from 'i18next';

import AsyncSelect from 'react-select/async';

interface Props extends FormItemInterface {
    selectAllLabel: string | undefined;
    options: OptionModel[] | undefined;
    advanceOptions: AdvanceOptions | undefined;
}

const SelectBoxAsyncGroup = React.memo((props: Props) => {
    const commonService = new CommonService();
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        selectAllLabel,
        className = 'select-custom',
        data,
        attribute,
        options = [],
        advanceOptions,
        onChangeHandle,
        htmlLabel
    } = props;

    const [value, setValue] = useState<OptionModel | undefined>(
        options.find(e => e.value === data)
    );
    useEffect(() => {
        const { data } = props;
        setValue(options.find(e => e.value === data));
    }, [data, i18n.language]);

    const onChange = event => {
        if (event) {
            setValue(event);
            onChangeHandle(fieldName, event.value);
        } else {
            onChangeHandle(fieldName, '');
        }
    };

    const filterKeywords = (inputValue: string) => {
        return options.filter(i =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const promiseOptions = inputValue => {
        const { advanceOptions, data } = props;
        if (advanceOptions) {
            const {
                apiEndpoint,
                buildOptions,
                buildFilterObject,
                pageSize,
                subTenantId,
                rightName
            } = advanceOptions;
            const headers = commonService.getHeaderWithSubtenantAndRightName(
                subTenantId,
                rightName,
                false
            );
			
            const filter = buildFilterObject && pageSize ? `?filter=${buildFilterObject(
                inputValue
            )}&pageSize=${pageSize}&pageIndex=0` : '';

            return axios
                .get(`${apiEndpoint}${filter}`,
                    { headers }
                )
                .then(res => {
                    const options = buildOptions(res.data);
                    const existValue = options.find(e => e.value === data);
                    existValue && setValue(existValue);
                    return options;
                })
                .catch(err => commonService.handleErrorHttp(err));
        } else {
            return new Promise(resolve => {
                resolve(filterKeywords(inputValue));
            });
        }
    };

    const disabled = attribute && attribute.disabled;

    let isClearable = attribute && attribute.isClearable;
    if (attribute && attribute.hasOwnProperty('isClearable')) {
        delete attribute.isClearable;
    } else {
        isClearable = true;
    }

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';
    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={className + ' ' + errorClass}>
                <AsyncSelect
                    key={`${JSON.stringify(data)}${JSON.stringify(value)}`}
                    {...attribute}
                    value={value ? value : options.find(e => e.value === data)}
                    onChange={onChange}
                    className="select-async"
                    name={fieldName}
                    id={htmlFor}
                    cacheOptions
                    defaultOptions={advanceOptions ? true : options}
                    loadOptions={promiseOptions}
                    classNamePrefix="select"
                    isDisabled={disabled}
                    isClearable={isClearable}
                    placeholder={selectAllLabel}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
SelectBoxAsyncGroup.displayName = 'SelectBoxAsyncGroup';

export default SelectBoxAsyncGroup;
