import * as types from 'constants/ActionTypes';

  
export function setOperator(operator) {
    return { 
        type: types.SET_OPERATOR, 
        operator
    };
}

export function setTenantLogo(logoUri) {
    return { 
        type: types.SET_TENANT_LOGO, 
        logoUri
    };
}

export function setUserRoles(userRoles) {
    return {
        type: types.SET_USER_ROLES,
        userRoles
    };
}
