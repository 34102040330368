import React from 'react';
import Select from 'react-select';
import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';
import OptionModel from 'models/Option';

interface Props extends FormItemInterface {
    multiSelectData?: OptionModel[] | undefined;
    selectAllLabel: string | undefined;
    options: OptionModel[] | undefined;
}

const MultiSelectBoxGroup = React.memo((props: Props) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        className = 'input-group mb-3',
        multiSelectData,
        selectAllLabel,
        attribute,
        options = [],
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        let value = [];
        if (event) {
            value = event.map(e => e.value);
        }
        onChangeHandle(fieldName, value);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    return !isVisible ? null : (
        <React.Fragment>
            <label htmlFor={htmlFor}>
                {fieldLabel} {isRequired && <span className="required">*</span>}
            </label>
            <div className={className + ' ' + errorClass}>
                <Select
                    {...attribute}
                    value={multiSelectData}
                    isMulti
                    id={htmlFor}
                    className="select-fluid"
                    classNamePrefix="select"
                    placeholder={selectAllLabel}
                    isClearable={true}
                    isSearchable={true}
                    name={fieldName}
                    options={options}
                    onChange={onChange}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
MultiSelectBoxGroup.displayName = 'MultiSelectBoxGroup';

export default MultiSelectBoxGroup;
