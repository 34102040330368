import {
    PersonalDetailsModel,    
    PersonalDetailFieldsModel,
    ContactDetailsModel,    
    ContactDetailsFieldsModel,
    AddressDetailsModel,   
    AddressDetailsFieldsModel, 
    ContactedDetailsModel,    
    ContactedDetailsFieldsModel,
    EmergencyContactModel,    
    EmergencyContactFieldsModel,
    HealthQuestionModel,   
    OtherFieldsModel,
    OtherFieldsFieldsModel } from './PersonalDetails';


export class ChangePersonalDetailsModel {
    memberId = '';
    subTenantId = '';
    personalDetails   = new PersonalDetailsModel();
    contactDetails    = new ContactDetailsModel();   
    addressDetails    = new AddressDetailsModel();    
    contactedDetails  = new ContactedDetailsModel();
    emergencyContact  = new EmergencyContactModel();    
    healthQuestions: HealthQuestionModel[]    = [];    
    otherFields       = new OtherFieldsModel()
}
export class ChangePersonalDetailsFieldConfig {
    personalDetailsFieldConfigs = new PersonalDetailFieldsModel(); 
    contactDetailsFieldConfigs = new ContactDetailsFieldsModel();
    addressDetailsFieldConfigs = new AddressDetailsFieldsModel();
    contactedDetailsFieldConfigs = new ContactedDetailsFieldsModel();
    emergencyContactFieldsConfigs = new EmergencyContactFieldsModel();
    otherFieldsFieldsConfigs = new OtherFieldsFieldsModel();
}
