import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import { MatchParams, AddEditTenderTypeInterface } from 'pages/SystemPage/Tender/TenderType/interface';
import { TenderTypeModel, SubTenant } from 'models/Tender';
import { FieldModel } from 'models/Field';
import { transformOptions, getSubTenantId } from 'util/ControlUtils';
import { matchField, getRawValue, isValidOrNot, updateFieldValid, matchFieldNested, forceAbs, getIdOrNull } from 'components/FormV2';
import observer from 'util/Observer';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import * as Constants from 'constants/Constants';
import * as Route from 'pages/RouteLoader';

class AddTenderTypePage extends React.Component<
    AddEditTenderTypeInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation,
    AddEditTenderTypeInterface['state']
    > {
    constructor(props: AddEditTenderTypeInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation) {
        super(props);
        const id = props.match.params['id'];
        const pathname = props.location.pathname;
        this.state = {
            id: id,
            isLoading: true,
            disabled: true,
            isEditMode: id && pathname.indexOf('edit') > -1 ? true : false,
            isViewMode: id && pathname.indexOf('view') > -1 ? true : false,
            tenderTypeDetail: new TenderTypeModel(),
            subTenants: [],
            tenderClasses: [],
            currency: '',
            authorizationMethods: [],
            fields: []
        }
    }

    componentDidMount() {
        const { isEditMode, isViewMode, id } = this.state;
        const rightName = isEditMode ? Route.EditTenderTypeRoute.rightName :
            (isViewMode ? Route.ViewTenderTypeRoute.rightName : Route.AddTenderTypeRoute.rightName);

        // Get subTenantId & rightName
        const { location: { state } } = this.props;
        let subTenantId = getSubTenantId();
        if (state && state.subTenantId) {
            subTenantId = state.subTenantId;
        }

        const getTenderTypeData = isEditMode || isViewMode ? this.props.conmanService.getTenderTypeDetail(id, rightName, subTenantId).then((res) => {
            let tenderTypeDetail: TenderTypeModel = TenderTypeModel.init(res);
            this.setState({
                tenderTypeDetail: tenderTypeDetail,
                currency: tenderTypeDetail.subTenant && tenderTypeDetail.subTenant.currency ? tenderTypeDetail.subTenant.currency.name : ''
            });
            return this.props.conmanService.getTenderClasses(getIdOrNull(tenderTypeDetail.subTenant), rightName);
        }, () => {
            this.props.history.push(Constants.ROUTE_TENDER_TYPE_LIST);
        }).then((res) => {
            if (res && res.data) {
                this.setState({
                    tenderClasses: res.data.map(e => ({ label: e.name, value: e.code }))
                });
            }
        }) : {};

        let fields = [
            {
                "key": "tenderType.name",
                "isRequired": true,
                "isVisible": true,
                "regex": "^[a-zA-Z0-9-._ ]+$",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Name"
            },
            {
                "key": "tenderType.code",
                "isRequired": true,
                "isVisible": true,
                "regex": "^[a-zA-Z0-9]+$",
                "maxLength": 2,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Code"
            },
            {
                "key": "tenderType.subTenant",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Sub-tenant"
            },
            {
                "key": "tenderType.tenderClass",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Tender Class"
            },
            {
                "key": "tenderType.authorizationMethod",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Authorization Method"
            },
            {
                "key": "tenderType.isUnitCountRequired",
                "isRequired": false,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Unit Count Required"
            },
            {
                "key": "tenderType.isActive",
                "isRequired": false,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Status"
            },
            {
                "key": "tenderType.changeThresholdAmount",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Change Threshold Amount"
            },
            {
                "key": "tenderType.refundThresholdAmount",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Refund Threshold Amount"
            },
            {
                "key": "tenderType.managerRefundThresholdAmount",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Manager Refund Threshold Amount"
            },
            {
                "key": "tenderType.minimumAmount",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Minimum Amount"
            },
            {
                "key": "tenderType.maximumAmount",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Maximum Amount"
            },
            {
                "key": "tenderType.maximumSmartCardAmount",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Maximum Smart Card Amount"
            },
            {
                "key": "tenderType.isOpenCashDrawerRequired",
                "isRequired": false,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Open Cash Drawer Required"
            }
        ]

        fields = fields.map(e => ({
            ...new FieldModel(), ...e,
            isValid: isEditMode || e.name.indexOf('Amount') > -1 ? true : false,
            isValidType: isEditMode || e.name.indexOf('Amount') > -1 ? 'none' : 'required'
        }));
        this.setState({
            fields: fields
        });

        const getSubTenants = this.props.conmanService.getTenderTypeSubTenants(rightName).then((res) => {
            if (res && res.data && res.data.length) {
                this.setState({
                    subTenants: res.data.map(e => SubTenant.init(e))
                });
            }
        });

        const getAuthorizationMethods = this.props.conmanService.getAuthorizationMethods().then((res) => {
            if (res && res.data && res.data.length) {
                this.setState({
                    authorizationMethods: transformOptions(res.data)
                });
            }
        });

        Promise.all([
            getTenderTypeData,
            getSubTenants,
            getAuthorizationMethods,
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    onSubmit = () => {
        const isValid = this.checkValidations();
        const { isEditMode } = this.state;
        const { t } = this.props;

        if (isValid) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.CONFIRM_SAVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO'),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false
                    })
                    const { tenderTypeDetail, id } = this.state;
                    Promise.all([
                        isEditMode
                            ? this.props.conmanService.updateTenderType(TenderTypeModel.buildData(tenderTypeDetail), id, Route.EditTenderTypeRoute.rightName)
                            : this.props.conmanService.createTenderType(TenderTypeModel.buildData(tenderTypeDetail), Route.AddTenderTypeRoute.rightName)
                    ]).then(() => {
                        observer.publish('flagUnsavedChangeEvent', false);
                        this.setState({
                            disabled: true
                        }, () => {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('MSG.SAVE_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => {
                                this.props.history.push(Constants.ROUTE_TENDER_TYPE_LIST);
                            })
                        })
                    });
                }
            });
        }
    }

    onCancel = () => {
        this.props.history.push(Constants.ROUTE_TENDER_TYPE_LIST);
    }

    checkValidations() {
        let isValid = true;
        const fields = this.state.fields.map(e => {
            e.isDirty = true;
            if (e.nested) {
                e.nested = e.nested.map(fieldNested => {
                    fieldNested.isDirty = true;
                    if (isValidOrNot(fieldNested.isValid, fieldNested.isRequired, fieldNested.regex, fieldNested.customMsg)) {
                        isValid = false;
                    }
                    return fieldNested;
                })
            }
            if (isValidOrNot(e.isValid, e.isRequired, e.regex, e.customMsg)) {
                isValid = false;
            }
            return e;
        });
        this.setState({
            fields: fields
        }, () => {
            if (!isValid) {
                const element: HTMLElement | null = document.querySelector('.error-holder input, .error-holder textarea, .error-holder select, .error-holder .icon-checked-ico:before, .error-holder .select__control');
                if (element) {
                    element.focus();
                }
            }
        });
        return isValid;
    }

    checkValidation(nameHtml, valueHtml, nestedNameData) {
        const fields = this.state.fields.map(e => {
            const extractNameValidation = e.key.split('.');
            const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
            if (matchField(nameHtml, shortNameValidation)) {
                if (e.nested) {
                    e.nested = e.nested.map(fieldNested => {
                        if (matchFieldNested(nameHtml, fieldNested.key)) {
                            const rawValue = getRawValue(valueHtml, fieldNested.key, nestedNameData);
                            fieldNested = updateFieldValid(fieldNested, rawValue);
                        }
                        return fieldNested;
                    })
                } else {
                    const rawValue = getRawValue(valueHtml, null, null);
                    e = updateFieldValid(e, rawValue);
                }
            }
            return e;
        });
        this.setState({
            fields: fields
        })
    }

    setTenderTypeStateByProperty(nameData, nameHtml, valueHtml, nestedNameData = '') {
        this.setState(prevState => ({
            tenderTypeDetail: {
                ...prevState.tenderTypeDetail, [nameData]: valueHtml
            }
        }), () => this.checkValidation(nameHtml, valueHtml, nestedNameData));
    }

    onChangeHandle(nameHtml, valueHtml) {
        let { tenderTypeDetail, subTenants, isEditMode, isViewMode } = this.state;
        const { t } = this.props;
        let fields: FieldModel[] = [];
        switch (nameHtml) {
            case 'tenderTypeDetail[code]':
                valueHtml = valueHtml.toUpperCase();
                this.setTenderTypeStateByProperty('code', nameHtml, valueHtml);
                /* Custom validation of tender code */
                fields = this.state.fields.map(e => {
                    const extractNameValidation = e.key.split('.');
                    const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
                    if (matchField(nameHtml, shortNameValidation)) {
                        if (valueHtml.length !== 2) {
                            e.customMsg = { value: 'PAGE.SYSTEM.TENDER_TYPE.ERR_TENDER_CODE_MIN_LENGTH', key: { min: 2 } } as any;
                        } else {
                            e.customMsg = '';
                        }
                    }
                    return e;
                });
                this.setState({
                    fields: fields
                })
                break;
            case 'tenderTypeDetail[name]':
                this.setTenderTypeStateByProperty('name', nameHtml, valueHtml);
                break;
            case 'tenderTypeDetail[authorizationMethod]':
                this.setTenderTypeStateByProperty('authorizationMethod', nameHtml, { id: valueHtml, name: '' });
                break;
            case 'tenderTypeDetail[tenderClass]':
                this.setTenderTypeStateByProperty('tenderClass', nameHtml, { code: valueHtml, name: '' });
                break;
            case 'tenderTypeDetail[subTenant]':
                this.setTenderTypeStateByProperty('subTenant', nameHtml, { id: valueHtml, name: '' });
                const currentSubTenant = subTenants.find(e => e.value === valueHtml);
                if (currentSubTenant && currentSubTenant.currency) {
                    this.setState({ currency: currentSubTenant.currency.name });
                } else {
                    this.setState({ currency: '' });
                }
                const rightName = isEditMode ? Route.EditTenderTypeRoute.rightName :
                    (isViewMode ? Route.ViewTenderTypeRoute.rightName : Route.AddTenderTypeRoute.rightName);
                this.props.conmanService.getTenderClasses(valueHtml, rightName).then((res) => {
                    if (res && res.data) {
                        this.setState({
                            tenderClasses: res.data.map(e => ({ label: e.name, value: e.code }))
                        }, () => this.setTenderTypeStateByProperty('tenderClass', 'tenderTypeDetail[tenderClass]', { code: '', name: '' }))
                    }
                }).catch(() => {
                    this.setState({
                        tenderClasses: []
                    });
                });
                break;
            case 'tenderTypeDetail[isActive]':
                this.setTenderTypeStateByProperty('isActive', nameHtml, !tenderTypeDetail.isActive);
                break;
            case 'tenderTypeDetail[isUnitCountRequired]':
                this.setTenderTypeStateByProperty('isUnitCountRequired', nameHtml, !tenderTypeDetail.isUnitCountRequired);
                break;
            case 'tenderTypeDetail[changeThresholdAmount]':
                this.setTenderTypeStateByProperty('changeThresholdAmount', nameHtml, forceAbs(valueHtml));
                break;
            case 'tenderTypeDetail[refundThresholdAmount]':
                this.setTenderTypeStateByProperty('refundThresholdAmount', nameHtml, forceAbs(valueHtml));
                break;
            case 'tenderTypeDetail[managerRefundThresholdAmount]':
                this.setTenderTypeStateByProperty('managerRefundThresholdAmount', nameHtml, forceAbs(valueHtml));
                break;
            case 'tenderTypeDetail[minimumAmount]':
                this.setTenderTypeStateByProperty('minimumAmount', nameHtml, forceAbs(valueHtml));
                break;
            case 'tenderTypeDetail[maximumAmount]':
                this.setTenderTypeStateByProperty('maximumAmount', nameHtml, forceAbs(valueHtml));
                break;
            case 'tenderTypeDetail[maximumSmartCardAmount]':
                this.setTenderTypeStateByProperty('maximumSmartCardAmount', nameHtml, forceAbs(valueHtml));
                break;
            case 'tenderTypeDetail[isOpenCashDrawerRequired]':
                this.setTenderTypeStateByProperty('isOpenCashDrawerRequired', nameHtml, !tenderTypeDetail.isOpenCashDrawerRequired);
                break;
        }
        this.setState({
            disabled: false
        });
        observer.publish('flagUnsavedChangeEvent', true);
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {

    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(AddTenderTypePage)));
