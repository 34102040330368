import React from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';
import * as Utils from 'util/ControlUtils';
import MemberSummaryModel, { PeriodItem } from 'models/MemberSummary';

export const Content = ({
    title = '',
    actions = [],
    t,
    element = '',
    className = ''
}: {
    title?: string;
    actions?: { to?: string; text: string; onClick?: () => void }[];
    t: WithTranslation['t'];
    element?;
    className?: string;
}) => {
    return (
        <div className={`content-wrapper ${className}`}>
            <div className="content-header">
                <p className="head-title">{t(title)}</p>
                <ul className="list-action">
                    {!_.isEmpty(actions) &&
            actions.map((item, index) => {
                return (
                    <span key={index}>
                        <li onClick={item.onClick} className="list-action__items">
                            {item.to ? (
                                <NavLink to={item.to}>
                                    {t(item.text ? item.text : '')}
                                </NavLink>
                            ) : (
                                <span>{t(item.text ? item.text : '')}</span>
                            )}
                        </li>
                    </span>
                );
            })}
                </ul>
            </div>
            {element}
        </div>
    );
};

export const PackageItem = ({ t, label, text }) => {
    return (
        <div className="package-detail">
            <div className="data-label">{t(label)}</div>
            <div className="data-text">{text}</div>
        </div>
    );
};

export const descriptionList1 = (memberData, dateFormat) => {
    const { startedDate, lengthOfContract } = memberData;
    return [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.TIER' },
            value: { text: memberData.package.packageTier.name }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.ACCESS' },
            value: { text: memberData.package.packageTimeGroup.name }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.START_DATE' },
            value: {
                text: Utils.DisplayDateFormat(startedDate, dateFormat)
            }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.LENGTH_OF' },
            value: { text: lengthOfContract }
        }
    ];
};

export const descriptionList2 = (memberData, t) => {
    const { salesPerson, club, plan } = memberData;
    return [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.SALE_PERSON' },
            value: {
                text: t('PAGE.MEMBERSHIPS.VIEW.SOLE_BY_SALE_PERSON_IN_CLUB', {salePerson: (salesPerson || {}).name || '', club: (club || {}).name || ''})
            }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.PACKAGE' },
            value: { text: memberData.package.name }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.CREATE.SUMMARY.PLAN' },
            value: { text: plan.name }
        }
    ];
};

export const descriptionList3 = (memberData, dateFormat, t) => {
    const { endDate, withinMBG, withinMCP } = memberData;

    return [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.END_DATE' },
            value: { text: Utils.DisplayDateFormat(endDate, dateFormat) }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.WITHIN_MBG' },
            value: { text: t(withinMBG ? 'BUTTON.YES' : 'BUTTON.NO') }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.WITHIN_MCP' },
            value: { text: t(withinMCP ? 'BUTTON.YES' : 'BUTTON.NO') }
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.REMAINING_CCV' },
            value: { text: 'N/A' }
        }
    ];
};

const renderTextData = (data: PeriodItem) => {
    if (data) {
        return `${data.value} ${data.unitName}`;
    }
    return 'N/A';
};

export const packagePeriod1 = (
    plan: MemberSummaryModel['plan'],
    t: WithTranslation['t']
) => {
    const { freezeAvailable, period } = plan;
    const {
        moneyBackGuarantee,
        futureStartPeriod,
        billingChangeNotice,
        cancellationNotice,
        minimumMembership,
        maximumMembership
    } = period;
    return [
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.FREEZE_AVAILABLE',
            text: freezeAvailable ? t('BUTTON.YES') : t('BUTTON.NO')
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MIN_MEMBERSHIP_PERIOD',
            text: renderTextData(minimumMembership)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MAX_MONEYBACK_GUARANTEE_PERIOD',
            text: renderTextData(moneyBackGuarantee)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MIN_REQUIRED_BILLING_CHANGE',
            text: renderTextData(billingChangeNotice)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.CANCEL_NOTICE',
            text: renderTextData(cancellationNotice)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.FUTURE_CHANGE_START',
            text: renderTextData(futureStartPeriod)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MAX_PERIOD_IN_FUTURE',
            text: renderTextData(maximumMembership)
        }
    ];
};

export const packagePeriod2 = (
    plan: MemberSummaryModel['plan'],
    t: WithTranslation['t']
) => {
    const { immediateFreezeAvailable, period } = plan;
    const {
        membershipGrace,
        freezeNotice,
        noFreeze,
        minimumFreeze,
        maximumFreeze
    } = period;
    return [
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.IMMEDIATE_FREEZE_AVAILABLE',
            text: immediateFreezeAvailable ? t('BUTTON.YES') : t('BUTTON.NO')
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MEMBERSHIP_GRACE_PERIOD',
            text: renderTextData(membershipGrace)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MIN_FREEZE_PERIOD',
            text: renderTextData(minimumFreeze)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.FREEZE_NOTICE_PERIOD',
            text: renderTextData(freezeNotice)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.INIT_NO_FREEZE_PERIOD',
            text: renderTextData(noFreeze)
        },
        {
            label: 'PAGE.MEMBERSHIP_DETAIL.PACKAGE_PERIOD.MAX_FREEZE_PERIOD',
            text: renderTextData(maximumFreeze)
        }
    ];
};
