import React from 'react';
import _ from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {SWAL_COMMON_STYLE} from 'constants/DefaultConstants';

interface Props {    
    duplicateMembers: any;
    onMemberSelected: (memberNo: string) => void;
    onClose: () => void;
}

const customePopupStyles = _.merge(SWAL_COMMON_STYLE, {
    customClass: {
        popup: 'duplicate-member-popup',
        closeButton: 'close-button'
    }
})

class DuplicateMembers extends React.PureComponent<Props & WithTranslation>{   

    onMemberSelected = (memberNo: string, memberName: string) => {      
        const { t } = this.props;
        Swal.fire({            
            ...customePopupStyles,
            padding: '2em',
            html: `<p><span class="memberName">${memberName}</span> 
                      ${t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.ALERT_MESSAGE')}
                   </p>
                   <p>${t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.CONFIRM_MESSAGE')}</p>`,            
            showCancelButton: true,
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonText: t('BUTTON.NEXT'),
            cancelButtonText: t('BUTTON.CANCEL')
        }).then(res => {
            if (res.value) {
                this.props.onMemberSelected(memberNo);
            }
        })
    }
    
    render(){
        let {duplicateMembers, t} = this.props;
        let members = duplicateMembers.map((mem, index) => {
            let {
                club: {name: clubName = ''} = {},
                memberNo = '',
                firstName = '', 
                lastName = '', 
                postCode = '',
                addressNumber = '',
                line1 = '',
                line2 = '',
                city = '',
                state = '',
                memberStatus = '',
                paymentStatus: {name: paymentStatus = ''} = {}
            } = mem;

            addressNumber = addressNumber ? `${addressNumber},` : '';
            line1 = line1 ? `${line1},` : '';
            line2 = line2 ? `${line2},` : '';
            city = city ? `${city},` : '';            
            let address = `${addressNumber} ${line1} ${line2} ${city} ${state || ''}`.trim();
            if(!address) address = t('COMMON.NA');

            return (
                <tr key={`dup_${index}`} onClick={() => this.onMemberSelected(memberNo, `${firstName} ${lastName}`)}>
                    <td>{clubName}</td>
                    <td className="text-center">{memberNo}</td>
                    <td className="text-center">{`${firstName} ${lastName}`}</td>
                    <td className="text-center">{address}</td>
                    <td className="text-center">{postCode}</td>
                    <td className={`text-center status-${memberStatus.toLowerCase()}`}>{memberStatus}</td>
                    <td className={`text-center status-${paymentStatus.toLowerCase()}`}>{paymentStatus}</td>
                </tr>
            )
        })

        return(
            <div className="select-add-on-main">
                <div className="main-table mt-2">
                    <div className="d-flex justify-content-between align-items-center title-dupplicate">
                        <span className="text-uppercase">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.TITLE')}</span>                 
                        <i className="icon icon-close-ico" onClick={() => this.props.onClose()}></i>
                    </div>

                    <p>{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.SUB_TITLE')}</p>
                
                    <p className="title-search">
                        <span dangerouslySetInnerHTML={{__html: t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.SEARCH_FOUND', { numOfMember: `<span class="total-member">${duplicateMembers.length}</span>` })}}></span>
                    </p>
                
                    <div className="table-responsive-lg table-responsive-auto">
                        <table className="table table-base duplicate-members-list">
                            <thead>
                                <tr>
                                    <th className="rounded-top-left">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.CLUB')}</th>
                                    <th className="text-center">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.MEMBER_NO')}</th>
                                    <th className="text-center">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.NAME')}</th>
                                    <th className="text-center">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.ADDRESS')}</th>
                                    <th className="text-center">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.POSTCODE')}</th>
                                    <th className="text-center">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.STATUS')}</th>
                                    <th className="rounded-top-right text-center">{t('PAGE.MEMBERSHIPS.CREATE.DUPLICATE_MEMBER.PAYMENT_STATUS')}</th>
                                </tr>
                            </thead>
                            <tbody>                         
                                {members}
                            </tbody>
                        </table>
                    </div>            
                </div>
            </div>
        )
    }
}

export default withTranslation()(DuplicateMembers);