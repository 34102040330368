import React from 'react';
import ThisInterface  from './interface';
import PlanModel from 'models/Plan';
import { FieldModel } from 'models/Field';

import FormItem from 'components/FormV2';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
}
interface Props {
    onChangeHandle: Function;
    data: PlanModel;
    fields: FieldModel[];
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true
        }
    }

    componentDidMount() {
    }

    toogleExpand() {
        this.setState(state => ({isExpand: !state.isExpand}), () => {
            setInterval(() => this.setState({isExpanded: this.state.isExpand}), 500);
        });
    }


    render() {
        const { onChangeHandle, data, fields, t } = this.props;
        const restrictionColumns = {
            col1: [
                {
                    props: {
                        type: 'checkbox',
                        htmlFor: 'planRestriction[newMembership]',
                        fieldName: 'planRestriction[newMembership]',
                        data: data.restriction && data.restriction.isNewMembership ? 1 : 0,
                        attribute: {
                            checked: data.restriction && data.restriction.isNewMembership
                        },
                        fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.NEW_MEMBERSHIP'),
                    },
                    className: 'col-md-4 mt-3'
                },
                {
                    props: {
                        type: 'checkbox',
                        htmlFor: 'planRestriction[changeMembership]',
                        fieldName: 'planRestriction[changeMembership]',
                        data: data.restriction && data.restriction.isChangeMembership ? 1 : 0,
                        attribute: {
                            checked: data.restriction && data.restriction.isChangeMembership
                        },
                        fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.CHANGE_MEMBERSHIP'),
                    },
                    className: 'col-md-4 mt-3'
                }
            ],
            col2: [
                {
                    props: {
                        type: 'checkbox',
                        htmlFor: 'planRestriction[renewMembership]',
                        fieldName: 'planRestriction[renewMembership]',
                        data: data.restriction && data.restriction.isRenewMembership ? 1 : 0,
                        attribute: {
                            checked: data.restriction && data.restriction.isRenewMembership
                        },
                        fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.RENEW_MEMBERSHIP'),
                    },
                    className: 'col-md-4 mt-3'
                },
                {
                    props: {
                        type: 'checkbox',
                        htmlFor: 'planRestriction[rejoinMembership]',
                        fieldName: 'planRestriction[rejoinMembership]',
                        data: data.restriction && data.restriction.isRejoinMembership ? 1 : 0,
                        attribute: {
                            checked: data.restriction && data.restriction.isRejoinMembership
                        },
                        fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.REJOIN_MEMBERSHIP'),
                    },
                    className: 'col-md-4 mt-3'
                }
            ]
        };
        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.CLONE.SCENARIO_PLAN_RESTRICTIONS')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    <h4 className="title">{t('PAGE.PRODUCTS.PLANS.CLONE.RESTRICTIONS')}</h4>
                    <div className="row restrictions-item">
                        {restrictionColumns['col1'].map((field, key) =>
                            <div key={key} className={field.className}>
                                <FormItem
                                    {...field.props}
                                    onChangeHandle={onChangeHandle}
                                    fields={fields}
                                />
                            </div>
                        )}
                    </div>
                    <div className="row restrictions-item">
                        {restrictionColumns['col2'].map((field, key) =>
                            <div key={key} className={field.className}>
                                <FormItem
                                    {...field.props}
                                    onChangeHandle={onChangeHandle}
                                    fields={fields}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;