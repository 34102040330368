import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import template from './template';
import ThisInterface from './interface';

class ViewMemberShipDetail extends React.Component<ThisInterface['props'], {}> { 
    render() {
        return template(this);
    }
}

export default withTranslation()(withRouter(ViewMemberShipDetail))