import { connect } from 'react-redux';
import ThisInterface from './interface';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import store from 'reduxs/index';
import template from './template';
import React from 'react';
import OptionModel from 'models/Option';
import PaymentDetails from 'models/PaymentDetails';
import * as Utils from 'util/ControlUtils';
import observer from 'util/Observer';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { InitialFieldConfigs } from 'util/FieldConfiguration/LocalConfigs';
import { getFieldConfig, filterValuableFieldsOnly } from 'util/FieldConfiguration/';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';

class SelectPaymentDetails extends React.Component<ThisInterface["props"] & WithTranslation, ThisInterface["state"]> {
    constructor(props: ThisInterface["props"] & WithTranslation) {
        super(props);

        this.state = {
            openDropdown: {
                primary: true,
                secondary: false
            },
            paymentDetails: new PaymentDetails(),
            primaryPaymentMethods: [],
            cardTypes: [],
            issuingBanks: [],
            errors: [],
            hasChanged: false,
            fieldConfigs: this.getFieldConfiguration(InitialFieldConfigs.data)
        }
    }

    componentDidMount() {
        let paymentDetails = new PaymentDetails();
        const { membershipService, t, isEdit } = this.props;
        const { selectedPackagePlan: { plan: { paymentMethod } }, membershipSearchFilter } = this.props.newMembership;
        const rightName = !isEdit ? Route.AddNewMemberRoute.rightName : Route.ChangeMembershipRoute.rightName;

        let primaryPaymentMethods: OptionModel[] = [];
        let optionModel: OptionModel = new OptionModel();

        // TODO: Payment Method is currently only has "Credit Card" or "Debit Card", and will get Payment Method list from BE later
        if (this.props.newMembership.paymentDetails && this.props.newMembership.paymentDetails.primaryPaymentMethodId) {
            const {
                newMembership: {
                    paymentDetails: {
                        primaryPaymentMethod,
                        nationalIdNumber,
                        otherNationalIdNumber,
                        secondaryPaymentMethod } } } = this.props;
            if (paymentMethod.id === primaryPaymentMethod.id) { // Check package plan has updated from step 1
                paymentDetails = this.props.newMembership.paymentDetails;
                optionModel.value = primaryPaymentMethod.id;
                optionModel.label = primaryPaymentMethod.name;
            }
            else {
                optionModel.value = paymentMethod.id;
                optionModel.label = paymentMethod.name;
                paymentDetails.primaryPaymentMethod = paymentMethod;
                paymentDetails.primaryPaymentMethodId = paymentMethod.id;
                paymentDetails.nationalIdNumber = nationalIdNumber;
                paymentDetails.otherNationalIdNumber = otherNationalIdNumber;
                paymentDetails.secondaryPaymentMethod = secondaryPaymentMethod;
            }
        } else {
            if (paymentMethod) {
                optionModel.value = paymentMethod.id;
                optionModel.label = paymentMethod.name;
                paymentDetails.primaryPaymentMethod = paymentMethod;
                paymentDetails.primaryPaymentMethodId = paymentMethod.id;
            }
            paymentDetails.secondaryPaymentMethod = 'cash'; // TODO: Only one checkbox for "Cash" and the user cannot deselect it.            
        }

        primaryPaymentMethods.push(optionModel);

        this.setState({
            paymentDetails,
            primaryPaymentMethods
        });

        Promise.all([
            membershipService.getBanks(membershipSearchFilter.subTenantId, rightName),
            membershipService.getCardTypes(membershipSearchFilter.subTenantId, rightName),
            membershipService.getFieldsConfiguration(membershipSearchFilter.subTenantId, rightName)
        ]).then(([res1, res2, res3]) => {
            const issuingBank = new OptionModel();
            issuingBank.label = t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PLEASE_SELECT_AN_ISSUING_BANK');
            let issuingBanks: OptionModel[] = [issuingBank, ...res1.data.map(e => ({ value: e.id, label: e.name }))];

            const cardType = new OptionModel();
            cardType.label = t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PLEASE_SELECT_A_CARD_TYPE');
            let cardTypes: OptionModel[] = [cardType, ...res2.data.map(e => ({ value: e.id, label: e.name }))];

            if (res3.data && res3.data.length) {
                let fieldConfigs = filterValuableFieldsOnly(this.getFieldConfiguration(res3.data));

                this.setState(prevState => {
                    return {
                        fieldConfigs: {
                            ...prevState.fieldConfigs,
                            ...fieldConfigs
                        }
                    }
                })
            }

            this.setState({
                issuingBanks,
                cardTypes,
                paymentDetails
            })
        }).catch(() => {
            this.setState({
                issuingBanks: [],
                cardTypes: [],
                fieldConfigs: this.getFieldConfiguration(InitialFieldConfigs.data)
            });
        });
    }

    getFieldConfiguration = (fieldConfiguration: any) => {
        const nationalConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_NATIONAL);
        const nationalIdConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_NATIONAL_ID);
        const otherNationalIdConfig = getFieldConfig(fieldConfiguration, FieldKeys.ADDMEMBER_OTHER_NATIONAL_ID);

        return {
            nationalConfig,
            nationalIdConfig,
            otherNationalIdConfig
        }
    }

    handleChange = (key, event) => {
        const {
            errors,
            paymentDetails } = this.state;
        const { t } = this.props;
        const { value } = event;

        switch (key) {
            case "primaryPaymentMethodId":
                errors[key] = value ? this.getRequiredErrorMessage(
                    value,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD_REQUIRED')
                ) : '';
                break;
            case "cardTypeId":
                errors[key] = value ? this.getRequiredErrorMessage(
                    value,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_TYPE_REQUIRED')
                ) : '';
                break;
            case "issuingBankId":
                errors[key] = value ? this.getRequiredErrorMessage(
                    value,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK_REQUIRED')
                ) : '';
                break;

            default:
                break;
        }

        paymentDetails[key] = event.value;
        this.setState({
            paymentDetails,
            errors
        });
        this.hasChangeControlValue();
    }

    getRequiredErrorMessage = (value, requiredMessage) => {
        return value ? '' : requiredMessage;
    }

    getInvalidErrorMessage = (key, value, invalidMessage, requiredMessage = '') => {
        if (key && value) {
            return Utils[`IsValid${key}`](value) ? '' : invalidMessage
        }
        return this.getRequiredErrorMessage(value, requiredMessage);
    }

    handleInputChange = (key, value) => {
        const {
            errors,
            paymentDetails } = this.state;
        const { t } = this.props;
        let formatValue = value;

        switch (key) {
            case "accountName":
                formatValue = value.replace(/[^a-zA-Z ]/g, '');
                errors[key] = this.getInvalidErrorMessage(
                    'AccountName',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_INVALID'),
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_REQUIRED')
                );
                break;
            case "creditCardNumber":
                formatValue = value.replace(/[^\d ]/g, '');
                errors[key] = this.getInvalidErrorMessage(
                    'CardNumber',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CREDIT_CARD_NUMBER_INVALID'),
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CREDIT_CARD_NUMBER_REQUIRED')
                );
                break;
            case "expiryDate":
                formatValue = value.replace(/[^\d/]/g, '');
                errors[key] = this.getInvalidErrorMessage(
                    'CreditCardExpiryDate',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.EXPIRY_DATE_INVALID'),
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.EXPIRY_DATE_REQUIRED')
                );
                if (formatValue) { // Auto format expiry date, eg. 13 -> 01/3, 2 -> 02/, xxx -> xx/x
                    const expiryMonth = parseInt(formatValue);
                    if (formatValue.length === 1 && expiryMonth > 1) {
                        formatValue = '0' + expiryMonth + '/';
                    }
                    else if (formatValue.length === 2 && expiryMonth > 12) {
                        const expiryNumbers = formatValue.split('');
                        formatValue = '0' + expiryNumbers[0] + '/' + expiryNumbers[1];
                    }
                    else if (formatValue.length === 3 && !formatValue.includes('/')) {
                        const expiryNumbers = formatValue.split('');
                        formatValue = expiryNumbers[0] + expiryNumbers[1] + '/' + expiryNumbers[2];
                    }

                }
                break;
            case "issueNumber":
                formatValue = value.replace(/[^\d]/g, '');
                errors[key] = value && this.getInvalidErrorMessage(
                    'IssueNumber',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUE_NUMBER_INVALID')
                );
                break;
            case "cardNumber":
                formatValue = value.replace(/[^\d ]/g, '');
                errors[key] = this.getInvalidErrorMessage(
                    'CardNumber',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_NUMBER_INVALID'),
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_NUMBER_REQUIRED')
                );
                break;
            case "nationalIdNumber":
                formatValue = value.replace(/[^\d]/g, '');
                errors[key] = this.getInvalidErrorMessage(
                    'NationalIdNumber',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_INVALID')
                );
                break;
            case "otherNationalIdNumber":
                formatValue = value.replace(/[^\d]/g, '');
                errors[key] = value && this.getInvalidErrorMessage(
                    'OtherNationalIdNumber',
                    formatValue,
                    t('PAGE.MEMBERSHIPS.CREATE.STEP_4.OTHER_NATIONAL_ID_INVALID')
                );
                break;

            default:
                break;
        }

        paymentDetails[key] = formatValue;
        this.setState({
            paymentDetails,
            errors
        });
        this.hasChangeControlValue();
    }

    handlePrevious() {
        const { isEdit } = this.props;
        const CURRENT_STEP = isEdit ? 3 : 4;

        const { membershipConfiguration, membershipConfiguration: { stepLatest } } = this.props.newMembership;
        if (stepLatest > CURRENT_STEP && !this.isValidationSuccess()) {
            return;
        }
        this.storeDataToRedux();
        membershipConfiguration.stepIndex = CURRENT_STEP - 1;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
    }

    isValidationSuccess() {
        const {
            paymentDetails: {
                primaryPaymentMethod: { id, name },
                cardTypeId,
                issuingBankId,
                accountName,
                creditCardNumber,
                expiryDate,
                issueNumber,
                cardNumber,
                nationalIdNumber,
                otherNationalIdNumber } } = this.state;

        const { t } = this.props;

        let errors: any = [];

        if (!id) {
            errors = { ...errors, primaryPaymentMethodId: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD_REQUIRED' };
        }
        if (!issuingBankId) {
            errors = { ...errors, issuingBankId: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK_REQUIRED' };
        }

        if (!accountName) {
            errors = { ...errors, accountName: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_REQUIRED' };
        }
        else if (!Utils.IsValidAccountName(accountName)) {
            errors = { ...errors, accountName: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME_INVALID' };
        }

        if (name.toLowerCase().includes('credit')) {
            if (!cardTypeId) {
                errors = { ...errors, cardTypeId: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_TYPE_REQUIRED' };
            }

            if (!expiryDate) {
                errors = { ...errors, expiryDate: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.EXPIRY_DATE_REQUIRED' };
            }
            else if (!Utils.IsValidCreditCardExpiryDate(expiryDate)) {
                errors = { ...errors, expiryDate: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.EXPIRY_DATE_INVALID' };
            }

            if (!creditCardNumber) {
                errors = { ...errors, creditCardNumber: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.CREDIT_CARD_NUMBER_REQUIRED' };
            }
            else if (!Utils.IsValidCardNumber(creditCardNumber)) {
                errors = { ...errors, creditCardNumber: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.CREDIT_CARD_NUMBER_INVALID' };
            }

            if (issueNumber && !Utils.IsValidIssueNumber(issueNumber)) {
                errors = { ...errors, issueNumber: t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUE_NUMBER_INVALID') };
            }
        }
        else if (name.toLowerCase().includes('debit')) {
            if (!cardNumber) {
                errors = { ...errors, cardNumber: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_NUMBER_REQUIRED' };
            }
            else if (!Utils.IsValidCardNumber(cardNumber)) {
                errors = { ...errors, cardNumber: 'PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_NUMBER_INVALID' };
            }
        }

        if (nationalIdNumber && !Utils.IsValidNationalIdNumber(nationalIdNumber)) {
            errors = { ...errors, nationalIdNumber: t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_INVALID') };
        }

        if (otherNationalIdNumber && !Utils.IsValidOtherNationalIdNumber(otherNationalIdNumber)) {
            errors = { ...errors, otherNationalIdNumber: t('PAGE.MEMBERSHIPS.CREATE.STEP_4.OTHER_NATIONAL_ID_INVALID') };
        }

        this.setState({
            errors
        })

        return Object.keys(errors).length === 0;
    }

    handleNext() {
        const {
            paymentDetails,
            cardTypes,
            issuingBanks,
            paymentDetails: { cardTypeId,
                issuingBankId } } = this.state;

        const { isEdit } = this.props;
        const CURRENT_STEP = isEdit ? 4 : 5;

        if (this.isValidationSuccess()) {
            paymentDetails.cardType = cardTypeId && cardTypes.find(e => e.value === cardTypeId);
            paymentDetails.issuingBank = issuingBankId && issuingBanks.find(e => e.value === issuingBankId);
            const { membershipConfiguration } = this.props.newMembership;
            if (membershipConfiguration.stepLatest < CURRENT_STEP) {
                membershipConfiguration.stepLatest = CURRENT_STEP;
            }
            membershipConfiguration.stepIndex = CURRENT_STEP;
            store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
            this.storeDataToRedux();
        }

        this.setState({
            paymentDetails
        })
    }

    handleOpenDropdown = (key) => {
        const { openDropdown } = this.state;
        openDropdown[key] = !this.state.openDropdown[key];
        this.setState({
            openDropdown
        })
    };

    storeDataToRedux = () => {
        const { paymentDetails } = this.state;
        store.dispatch(MembershipActions.setPaymentDetails(paymentDetails));
    }

    hasChangeControlValue() {
        const { membershipConfiguration: { stepLatest } } = this.props.newMembership;
        const { isEdit } = this.props;
        const CURRENT_STEP = isEdit ? 4 : 5;
        if (stepLatest < CURRENT_STEP) return;
        this.setState({
            hasChanged: true
        })
    }

    handleSaveAndLeave(stepIndex, isSave = false) {
        const { membershipConfiguration } = this.props.newMembership;
        if (isSave) {
            this.storeDataToRedux();
        }
        membershipConfiguration.stepIndex = stepIndex;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        observer.publish('closeDialogEvent', true)
    }

    goToStep(stepIndex) {
        const { hasChanged } = this.state;
        const { membershipConfiguration, membershipConfiguration: { stepLatest } } = this.props.newMembership;
        const { isEdit, t } = this.props;
        const CURRENT_STEP = isEdit ? 3 : 4;
        if (hasChanged && stepLatest > CURRENT_STEP) {
            if (!this.isValidationSuccess()) return;
            const content = (
                <React.Fragment>
                    <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page"></div>
                    <p className="content-center add-member-page">{t('MSG.UNSAVED_CHANGES')}</p>
                    <div className="modalSaas__row--btn">
                        <button
                            className="btn btn-primary text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex, true)}
                        >
                            {t('BUTTON.SAVE_AND_LEAVE')}
                        </button>
                        <button
                            className="btn btn-dark text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex)}
                        >
                            {t('BUTTON.LEAVE_AND_NOT_SAVE')}
                        </button>
                    </div>
                </React.Fragment>
            );
            observer.publish('openDialogEvent', {
                content: content
            });
        }
        else {
            this.storeDataToRedux();
            membershipConfiguration.stepIndex = stepIndex;
            store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        }
    }

    render() {
        return template(this);
    }
}

const mapStateToProps: any = ({ newMembership }) => {
    return { newMembership };
};

export default connect(
    mapStateToProps
)(withTranslation()(SelectPaymentDetails));
