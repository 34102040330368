import React from 'react';
import { useTranslation } from 'react-i18next';
import { FeesTable } from '../common';
import { PaymentSummaryProps } from './interface';

const renderAdditionalFooter = (props: PaymentSummaryProps) => {
    const {
        totalPaymentAmount,
        totalAmountOutstanding
    } = props;

    const { t } = useTranslation();

    const calculateTotalOutstanding = () => {
        return totalAmountOutstanding > totalPaymentAmount ? (totalAmountOutstanding - totalPaymentAmount) : 0;
    };

    return (
        <React.Fragment>
            <tr className="payment-amount">
                <td></td>
                <td className="text-total"><h4>{t('PAGE.MAKE_PAYMENT.AMOUNT_BEING_PAID_TODAY')}</h4></td>
                <td className="total-payment-amount">{totalPaymentAmount.toFixed(2)}</td>
                <td></td><td></td><td></td>
            </tr>
            <tr>
                <td></td>
                <td className="text-total">{t('PAGE.MAKE_PAYMENT.TOTAL_OUTSTANDING')}</td>
                <td className="text-right">{calculateTotalOutstanding().toFixed(2)}</td>
                <td></td><td></td><td></td> {/** [TODO] Change to colspan and fix layout */}
            </tr>
        </React.Fragment>
    );
};

export const PaymentSummary = (props: PaymentSummaryProps) => {
    return (
        <React.Fragment>
            <div className="row">
                <FeesTable {...props} additionalFooter={renderAdditionalFooter(props)} />
            </div>
        </React.Fragment>
    );
};