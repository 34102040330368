import React from 'react';

import { DropdownContent, Actions } from '../common';
import ThisInterface from '../interface';

const PersonalDetail = (_this: ThisInterface) => {
    const { t, match } = _this.props;
    const { memberData, personalDetailOpen } = _this.state;

    let addressNumber = memberData.addressNumber
        ? `${memberData.addressNumber}`
        : '';
    let line = memberData.line1
        ? `${memberData.line1},`
        : memberData.line2
            ? `${memberData.line2},`
            : '';
    let city = memberData.city ? `${memberData.city},` : '';
    let state = memberData.state ? `${memberData.state},` : '';
    let country =
    memberData.country && memberData.country.name
        ? memberData.country.name
        : '';
    let address = line
        ? `${addressNumber} ${line} ${city} ${state} ${country}`.trim()
        : t('COMMON.NA');

    let email = memberData.email
        ? memberData.email
        : memberData.overrideEmail &&
      memberData.overrideEmail.name.toLowerCase() != 'email'
            ? memberData.overrideEmail.name
            : t('COMMON.NA');

    const actions = [
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_PERSON_DETAILS',
            to: {
                pathname: `/memberships/members/${
                    match.params['id']
                }/change-personal-details`,
                state: { subTenantId: memberData.subTenantId, previousSection: '' }
            }
        },
        {
            hasAction: true,
            onClick: () => _this.handleOpenAction('personalDetail'),
            className: 'action',
            element: (
                <DropdownContent
                    t={t}
                    isVisible={personalDetailOpen}
                    actions={[
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_HOME_CLUB'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_EXTRA_CLUB'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.BAN_MEMBER'
                        }
                    ]}
                />
            )
        }
    ];

    return (
        <div className="personal-detail-summary">
            <div className="summary-header">
                <p onClick={() => _this.handleChangeSection('personal-detail')}>
                    {t('PAGE.MEMBERSHIPS.VIEW.PERSONAL_DETAILS')}
                </p>
                <Actions ref={_this.personalDetailRef} actions={actions} t={t} />
            </div>
            <div className="personal-detail">
                <div className="content row">
                    <div className="col-7 detail-data address">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.ADDRESS')}</p>
                        <div className="text">{address}</div>
                    </div>
                    <div className="col-5 detail-data phone">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.MOBILE_NUMBER')}</p>
                        <div className="text">{memberData.mobilePhone}</div>
                    </div>
                </div>
                <div className="content row">
                    <div className="col-7 detail-data address">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.EMAIL')}</p>
                        <div className="text">{email}</div>
                    </div>
                    <div className="col-5 detail-data phone">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.HOME_NUMBER')}</p>
                        <div className="text">{memberData.homePhone}</div>
                    </div>
                </div>
                <div className="content row">
                    <div className="col-7 detail-data address">
                        <p>{t('PAGE.MEMBERSHIPS.VIEW.HEALTH_QUESTIONNAIRE')}</p>
                        <div className="text">OK</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalDetail;
