import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as Interface from 'interfaces';

import * as Route from 'pages/RouteLoader';
import { SubTenantRoleModel } from 'models/Role';

import { transformOptions } from 'util/ControlUtils';
import {withTranslation, WithTranslation} from 'react-i18next'

export interface SelectedSubTenantRoles {
    subTenantId: string;
    subTenantName: string;
    roles: SubTenantRoleModel[];
}

export interface UserProfile {
    userRoles: {
        id: string;
        name: string;
        subTenantId: string;
        subTenantName: string;
        tenantId: string;
        tenantName: string;
    }[];
    tenantRoles: {
        id: string;
        name: string;
    }[];
}

interface SubTenant {
    id: string;
    name: string;
}

interface Props {
    conmanService: Interface.PagePropsInterface['conmanService'];
    history: RouteComponentProps['history'];
    location: RouteComponentProps['location'];
    match: RouteComponentProps['match'];
    userRoles: UserProfile;
}

interface State {
    tenantId: string;
    isEditMode: boolean;
    subTenantRoleEnabled: boolean;
    selectedSubTenantRoles: SelectedSubTenantRoles[];
    subTenants: {
        id: string;
        name: string;
    }[];
}

export default function withUserAndUserGroup(Component, path, pageType) {
    const getRightName = (isEditMode: boolean) => {
        switch (pageType) {
            case 'addUser':
                return isEditMode
                    ? Route.EditUserRoute.rightName
                    : Route.AddUserRoute.rightName;
            case 'addUserGroup':
                return isEditMode
                    ? Route.EditUserGroupRoute.rightName
                    : Route.AddUserGroupRoute.rightName;
            default:
                return '';
        }
    };

    class AddUserAndUSerGroup extends React.Component<Props & WithTranslation, State> {
        constructor(props: Props & WithTranslation) {
            super(props);
            const { location, match } = props,
                { tenantId } = localStorage,
                isEditMode = match.params && match.params['id'];
            this.state = {
                tenantId,
                isEditMode,
                subTenantRoleEnabled: false,
                selectedSubTenantRoles: [],
                subTenants: []
            };
        }

        componentDidMount() {
            if (this.props.userRoles) {
                this.getSubTenant();
            }
        }

        componentDidUpdate(prevProps: Props) {
            const { userRoles } = this.props;
            if (userRoles && userRoles !== prevProps.userRoles) {
                this.getSubTenant();
            }
        }

        getSubTenant = () => {
            const { conmanService } = this.props;
            const { isEditMode } = this.state;

            conmanService
                .getSubTenants(getRightName(isEditMode))
                .then(res => {
                    if (res) {
                        const selectedSubTenantRoles = res.data
                            ? res.data.map(st => {
                                return {
                                    subTenantId: st.id,
                                    subTenantName: st.name,
                                    roles: []
                                };
                            })
                            : [];
                        this.initSubTenant(res.data, selectedSubTenantRoles);
                    }
                })
                .catch(() => {
                    this.props.history.push('/');
                });
        };

        initSubTenant = (
            subTenants: SubTenant[],
            selectedSubTenantRoles: SelectedSubTenantRoles[]
        ) => {
            this.setState({
                subTenants: transformOptions(subTenants),
                selectedSubTenantRoles
            });
        };

        editMode = subTenantRoles => {
            this.setState({
                subTenantRoleEnabled: subTenantRoles.length > 0
            });
        };

        handleAddRemoveSingleSuggestion = (suggestion, key: string) => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    [key]: suggestion
                };
            });
        };

        onSubTenantRoleSwichChanged = event => {
            const { checked } = event.target;

            this.setState({
                subTenantRoleEnabled: checked
            });

            if (!checked) {
                const { selectedSubTenantRoles } = this.state;

                this.setState({
                    selectedSubTenantRoles: selectedSubTenantRoles.map(tr => {
                        return {
                            ...tr,
                            roles: []
                        };
                    })
                });
            }
        };

        onSubTenantRoleAdded = (_subTenantId: string, roles) => {
            const { selectedSubTenantRoles } = this.state;
            const index = selectedSubTenantRoles.findIndex(
                str => str.subTenantId === _subTenantId
            );

            selectedSubTenantRoles[index].roles = roles;

            this.setState({
                selectedSubTenantRoles: selectedSubTenantRoles
            });
        };

        onSubTenantRoleDeleted = (_subTenantId: string, roles) => {
            const { selectedSubTenantRoles } = this.state;
            const index = selectedSubTenantRoles.findIndex(
                str => str.subTenantId === _subTenantId
            );
            selectedSubTenantRoles[index].roles = roles;

            this.setState({
                selectedSubTenantRoles: selectedSubTenantRoles
            });
        };

        onSubTenantRoleDisabled = (_subTenantId: string) => {
            let { selectedSubTenantRoles } = this.state;
            let index = selectedSubTenantRoles.findIndex(
                str => str.subTenantId === _subTenantId
            );
            selectedSubTenantRoles[index].roles = [];

            this.setState({
                selectedSubTenantRoles
            });
        };

        render() {
            return (
                <Component
                    {...this.state}
                    {...this.props}
                    userProfile={this.props.userRoles}
                    onSubTenantRoleSwichChanged={this.onSubTenantRoleSwichChanged}
                    onSubTenantRoleAdded={this.onSubTenantRoleAdded}
                    onSubTenantRoleDeleted={this.onSubTenantRoleDeleted}
                    onSubTenantRoleDisabled={this.onSubTenantRoleDisabled}
                    editMode={this.editMode}
                />
            );
        }
    }

    const mapStatesToProps = ({ conman }: any) => {
        return {
            userRoles: conman ? conman.userRoles : {}
        };
    };

    return connect(
        mapStatesToProps,
        {}
    )(withTranslation()(AddUserAndUSerGroup));
}
