import React from 'react';

import { Actions } from '../common';
import ThisInterface from '../interface';

const Activity = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { memberData } = _this.state;


    const actions = [
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CREATE_BOOKING'
        }
    ]

    return (
        <div className="activity-summary">
            <div className="summary-header">
                <p onClick={() => _this.handleChangeSection('activity-detail')}>
                    {t('PAGE.MEMBERSHIPS.VIEW.ACTIVITY')}
                </p>
                <Actions actions={actions} t={t} />
            </div>
            <div className="activity-detail">
                <div className="member-ship-card">
                    <p>{t('PAGE.MEMBERSHIPS.VIEW.MEMBERSHIP_NUMBER')}</p>
                    <div className="card-number">{memberData.membershipNumber}</div>
                </div>
                <div className="activist">
                    <div className="row visit">
                        <div className="col-6 text-content">
                            <div className="no">
								12
                            </div>
                            <div className="text">
                                <div className="main-text">
                                    {t('PAGE.MEMBERSHIPS.VIEW.VISITS')}
                                </div>
                                <p className="sub-text">(in last 90 days)</p>
                            </div>
                        </div>
                        <div className="col-6 description">
                            Last visited CF Wangsa Maju on 2/08/2019 (212 days ago)
                        </div>
                    </div>
                    <div className="row access">
                        <div className="col-6 text-content">
                            <div className="no no-assgigned">
								02
                            </div>
                            <div className="text">
                                <div className="main-text member-card">
                                    {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CARD_HAS_BEEN_ASSIGNED')}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 description">
                            <div className="access-online">
                                {t('PAGE.MEMBERSHIPS.VIEW.ONLINE_ACCESS')}
                            </div>
                            <div className="status">
								Not Register
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activity
