import { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Theme from 'models/Theme';
import * as Interface from 'interfaces';

import * as DefaultConstants from 'constants/DefaultConstants';
import * as Utils from 'util/ControlUtils';
import observer from 'util/Observer';
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import ThisInterface from './interface';
import * as Route from 'pages/RouteLoader';

interface Props {
    subTenantId: string;
}

class ChangeThemePage extends Component<
Interface.PagePropsInterface & Props & WithTranslation,
ThisInterface['state']
> {
    constructor(props: Interface.PagePropsInterface & Props & WithTranslation) {
        super(props);

        this.state = {
            defaultTheme: null,
            activeTheme: null,
            previousTheme: null,
            recommendationThemes: null,
            historyThemes: null,
            displayColorPicker: '',
            primaryColor: {
                r: 255,
                g: 255,
                b: 255,
                a: '1'
            },
            secondaryColor: {
                r: 255,
                g: 255,
                b: 255,
                a: '1'
            },
            accessibleColor: null,
            hasThemeChange: false,
        };
    }

    componentDidMount() {    
        this.reloadAllThemes(false);
    }

    componentWillUnmount() {
        this.applyActiveTheme(
            localStorage.primaryColor,
            localStorage.secondaryColor,
            localStorage.accessibleColor
        );
    }

    convertColorHexToRGB = hex => {
        hex = `0x${hex.substr(1, hex.length)}`;
        return {
            r: (hex >> 16) & 0xff,
            g: (hex >> 8) & 0xff,
            b: hex & 0xff,
            a: '1'
        };
    };

    // Allow user to select expected colour to apply as new color on application theme.
    handleOpenColorPicker = (picker: string) => {
        this.setState(prevState => {
            return {
                displayColorPicker: prevState.displayColorPicker ? '' : picker
            };
        });
    };

    handlePrimaryColorChange = (color: ThisInterface['state']['color']) => {
        if (color && color.rgb && color.hex) {
            this.setState({ primaryColor: color.rgb, hasThemeChange: true });
            this.applyActiveTheme(
                color.hex,
                '',
                `calc(${Utils.enforcingHighContrastColors(
                    color.rgb.r,
                    color.rgb.g,
                    color.rgb.b
                )})`
            );
            observer.publish('flagUnsavedChangeEvent', true);
        }
    };

    handleSecondaryColorChange = (color: ThisInterface['state']['color']) => {
        if (color && color.rgb && color.hex) {
            this.setState({ secondaryColor: color.rgb, hasThemeChange: true });
            this.applyActiveTheme('', color.hex, '');
            observer.publish('flagUnsavedChangeEvent', true);
        }
    };

    handleChangeTheme = (primaryHexColor: string, secondaryHexColor: string) => {
        if (primaryHexColor && secondaryHexColor) {
            const primaryColor: ThisInterface['state']['primaryColor'] = this.convertColorHexToRGB(
                primaryHexColor
            );
            const secondaryColor: ThisInterface['state']['secondaryColor'] = this.convertColorHexToRGB(
                secondaryHexColor
            );
            this.setState({ primaryColor, secondaryColor, hasThemeChange: true });
            this.applyActiveTheme(
                primaryHexColor,
                secondaryHexColor,
                `calc(${Utils.enforcingHighContrastColors(
                    primaryColor.r,
                    primaryColor.g,
                    primaryColor.b
                )})`
            );
            observer.publish('flagUnsavedChangeEvent', true);
        }
    };

    applyActiveTheme = (
        primaryColor: string,
        secondaryColor: string,
        accessibleColor: string
    ) => {
        if (primaryColor) {
            document.body.style.setProperty('--primary-color', primaryColor);
        }
        if (secondaryColor) {
            document.body.style.setProperty('--primary-color-darken', secondaryColor);
        }
        if (accessibleColor) {
            document.body.style.setProperty('--accessible-color', accessibleColor);
        }
    };

    // When user clicks on "SAVE" to apply change or click on "RESTORE DEFAULTS", they are asked to confirm action.
    resetTheme = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.CONFIRM_SAVE_THEME'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.ACCEPT'),
            cancelButtonText: t('BUTTON.DECLINE')
        }).then(result => {
            if (result.value) {
                Swal.fire({
                    html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                const activeTheme = new Theme();
                activeTheme.primaryColor = this.state.defaultTheme!.primaryColor;
                activeTheme.secondaryColor = this.state.defaultTheme!.secondaryColor;
                const rightName = Route.AppearanceSettingRoute.rightName;
                const { subTenantId } = this.props;
                this.props.conmanService!.saveTenantTheme(activeTheme, rightName, subTenantId).then(() => {
                    this.reloadAllThemes(false);
                    this.updateThemeState(activeTheme);
                    observer.publish('flagUnsavedChangeEvent', false);
                    this.setState({ hasThemeChange: false });
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.APPLY_THEME_SUCCESSFULLY'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    }).then(() => {
                        localStorage.primaryColor = activeTheme.primaryColor;
                        localStorage.secondaryColor = activeTheme.secondaryColor;
                        localStorage.accessibleColor = this.state.accessibleColor;
                    });
                });
            } else {
                observer.publish('flagUnsavedChangeEvent', true);
            }
        });
    };

    // When user clicks on "SAVE" to apply change or click on "RESTORE DEFAULTS", they are asked to confirm action.
    saveTheme = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.CONFIRM_SAVE_THEME'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.ACCEPT'),
            cancelButtonText: t('BUTTON.DECLINE')
        }).then(result => {
            if (result.value) {
                Swal.fire({
                    html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                if (!this.state.previousTheme) {
                    const previousTheme = new Theme();
                    previousTheme.primaryColor = this.state.defaultTheme!.primaryColor;
                    previousTheme.secondaryColor = this.state.defaultTheme!.secondaryColor;
                }
                const activeTheme = new Theme();
                activeTheme.primaryColor = Utils.rgbToHex(
                    this.state.primaryColor.r,
                    this.state.primaryColor.g,
                    this.state.primaryColor.b
                );
                activeTheme.secondaryColor = Utils.rgbToHex(
                    this.state.secondaryColor.r,
                    this.state.secondaryColor.g,
                    this.state.secondaryColor.b
                );
                const rightName = Route.AppearanceSettingRoute.rightName;
                const { subTenantId } = this.props;
                this.props.conmanService!.saveTenantTheme(activeTheme, rightName, subTenantId).then(data => {
                    if (data.id) {
                        this.reloadAllThemes(false);                        
                        observer.publish('flagUnsavedChangeEvent', false);
                        this.setState({ hasThemeChange: false });
                        Swal.fire({
                            ...DefaultConstants.SWAL_COMMON_STYLE,
                            type: 'success',
                            html: t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.APPLY_THEME_SUCCESSFULLY'),
                            confirmButtonText: t('BUTTON.CLOSE')
                        }).then(() => {
                            if (Utils.getSubTenantId() && subTenantId === Utils.getSubTenantId()) {
                                localStorage.primaryColor = activeTheme.primaryColor;
                                localStorage.secondaryColor = activeTheme.secondaryColor;
                                localStorage.accessibleColor = this.state.accessibleColor;
                            }
                        });
                    }
                });
            } else {
                observer.publish('flagUnsavedChangeEvent', true);
            }
        });
    };

    reloadAllThemes = (hasCache: boolean = true) => {
        const { conmanService, subTenantId } = this.props;
        const rightName = Route.AppearanceSettingRoute.rightName;

        Promise.all([
            conmanService.getTenantDefaultTheme(subTenantId, rightName),
            conmanService.getTenantActiveTheme(subTenantId, rightName),
            conmanService.getTenantRecommendationThemes(1000, subTenantId, rightName),
            conmanService.getTenantHistoryThemes(4, subTenantId, rightName)
        ]).then(([res1, res2, res3, res4]) => {
            const defaultTheme: Theme = res1.data[0];
            const activeTheme: Theme = res2.data[0];
            const recommendationThemes: Theme[] = res3.data;
            let historyThemes: Theme[] = res4.data;
            if (historyThemes && historyThemes.length > 0) {
                const previousTheme = historyThemes[0];
                this.setState({ previousTheme });
                historyThemes = historyThemes.slice(1);
            }
            this.setState({
                activeTheme,
                defaultTheme,
                recommendationThemes,
                historyThemes
            });
            this.updateThemeState(activeTheme);
        });       
    };

    updateThemeState = (activeTheme: ThisInterface['state']['activeTheme']) => {
        if (activeTheme) {
            const primaryColor: ThisInterface['state']['primaryColor'] = this.convertColorHexToRGB(
                activeTheme.primaryColor
            );
            const secondaryColor: ThisInterface['state']['secondaryColor'] = this.convertColorHexToRGB(
                activeTheme.secondaryColor
            );
            const accessibleColor = `calc(${Utils.enforcingHighContrastColors(
                primaryColor.r,
                primaryColor.g,
                primaryColor.b
            )})`;
            this.setState({
                primaryColor,
                secondaryColor,
                accessibleColor
            });
            this.applyActiveTheme(
                activeTheme.primaryColor,
                activeTheme.secondaryColor,
                accessibleColor
            );
        }
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ conman, oidc }: Interface.StateToPropInterface) => {
    return { conman, oidc };
};

export default connect(mapStateToProps)(withTranslation()(ChangeThemePage));
