import React from 'react';
import _ from 'lodash';
import { FormInput, FormPhoneInput, FormSelect } from 'components/Form';
import { checkInvalidEmailField } from 'util/ControlUtils';
import ThisInterface from './interface';
import Role from '../Roles';

const renderUserRolesGroups = (
    conman: ThisInterface['props']['conman'],
    key: string
) => {
    if (conman && conman.operator) {
        return conman.operator[key] && !_.isEmpty(conman.operator[key])
            ? conman.operator[key]
                .map(item => ({ name: item.name, id: item.id }))
                .filter(item => item)
            : [];
    }
    return [];
};

const template = (_this: ThisInterface) => {
    const { conman, t } = _this.props;
    const { subTenants } = _this.state;

    const {
        email,
        mobilePhoneNumber,
        mobilePhoneRegionCode,
        firstName,
        lastName,
        selectedSubtentant,
        disabled,
        firstNameDirty,
        lastNameDirty
    } = _this.state;

    return (
        <div className="tab-pane">
            <div className="row">
                <div className="col-md-6">
                    <div className="edit-title text-uppercase">
                        {t('PAGE.UPDATE_PROFILE.ACCOUNT_DATA')}
                    </div>
                    <div className="form-edit">
                        <FormInput
                            label={t('PAGE.UPDATE_PROFILE.USERNAME')}
                            id="username"
                            inputClass="form-control"
                            placeholder="Account Name"
                            disabled
                            value={conman!.operator.userName ? conman!.operator.userName : ''}
                            onChange={() => {}}
                        />
                        <FormInput
                            label="Email"
                            id="email"
                            inputClass="form-control"
                            placeholder="Email"
                            value={email ? email : ''}
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                _this.handleChangeText(e!, 'email')
                            }
                            isValid={checkInvalidEmailField(email)}
                            validMsg={t('COMMON.ERR_NOT_VAILD', { key: 'Email' })}
                        />
                        <FormPhoneInput
                            id="mobile-number"
                            label={t('PAGE.UPDATE_PROFILE.MOBILE_PHONE')}
                            value={mobilePhoneNumber}
                            countryCode={mobilePhoneRegionCode}
                            onPhoneNumberChange={(phoneNum, dialCode, isValid) =>
                                _this.handleChangePhoneNumber(
                                    phoneNum,
                                    dialCode,
                                    isValid,
                                    'mobilePhoneNumber'
                                )
                            }
                            errorMsg={t('COMMON.ERR_NOT_VAILD', {
                                key: t('PAGE.UPDATE_PROFILE.MOBILE_PHONE')
                            })}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="edit-title text-uppercase">
                        {t('PAGE.UPDATE_PROFILE.PERSONAL_DATA')}
                    </div>
                    <div className="form-edit">
                        <FormInput
                            label={t('PAGE.UPDATE_PROFILE.FIRST_NAME')}
                            id="first-name"
                            inputClass="form-control"
                            placeholder={t('PAGE.UPDATE_PROFILE.FIRST_NAME')}
                            value={firstName ? firstName : ''}
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                _this.handleChangeText(e!, 'firstName')
                            }
                            isDirty={firstNameDirty}
                            errorMsg={t('COMMON.ERR_IS_REQUIRED', {
                                key: t('PAGE.UPDATE_PROFILE.FIRST_NAME')
                            })}
                            require
                        />
                        <FormInput
                            label={t('PAGE.UPDATE_PROFILE.LAST_NAME')}
                            id="last-name"
                            inputClass="form-control"
                            placeholder={t('PAGE.UPDATE_PROFILE.LAST_NAME')}
                            value={lastName ? lastName : ''}
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                _this.handleChangeText(e!, 'lastName')
                            }
                            isDirty={lastNameDirty}
                            errorMsg={t('COMMON.ERR_IS_REQUIRED', {
                                key: t('PAGE.UPDATE_PROFILE.LAST_NAME')
                            })}
                            require
                        />
                        <FormSelect
                            require
                            label={t('PAGE.UPDATE_PROFILE.NATIONALITY')}
                            options={subTenants}
                            value={selectedSubtentant}
                            onChange={_this.handleChangeNationality}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="row-btn">
                <button className="btn btn-dark" onClick={_this.handleCancel}>
                    {t('BUTTON.CANCEL')}
                </button>
                <button
                    className="btn btn-primary"
                    disabled={disabled}
                    onClick={() => !disabled && _this.handleUpdateProfile()}
                >
                    {t('BUTTON.SAVE')}
                </button>
            </div>
            <Role
                title={t('PAGE.UPDATE_PROFILE.ROLES')}
                listData={renderUserRolesGroups(conman, 'userRoles')}
            />
            <Role
                title={t('PAGE.UPDATE_PROFILE.USER_GROUPS')}
                listData={renderUserRolesGroups(conman, 'groups')}
            />
        </div>
    );
};

export default template;
