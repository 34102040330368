import React from 'react';
import ListComponent from 'list-component';
import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';
import {
    PRESET_ENDPOINT_URL,
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ThisInterface from './interface';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

const template = (_this: ThisInterface) => {
    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT
    };

    const errorHandle = err => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    };

    const { t } = _this.props;
    return (
        <section>
            <p className="title-content">
                {t('PAGE.SYSTEM.ROLES.TITLE')}
            </p>
            <div className="add-new-section">
                <div className="box-btn-filter ml-auto d-flex">
                    <span>{t('PAGE.SYSTEM.ROLES.INSTRUCTION')}</span>
                    {isHasRight(RouteLoader.AddRoleRoute.rightName) && (
                        <button
                            onClick={() => _this.props.history.push('/system/roles/create')}
                            className="btn btn-height-sm btn-add-new"
                        >
                            {t('PAGE.SYSTEM.ROLES.BUTTON.ADD_ROLE')}
                        </button>
                    )}
                </div>
               
            </div>
            {_this.state.version > 0 ? (
                <ListComponent
                    oidcAuth={_this.props.auth.user}
                    version={_this.state.version}
                    rightName="view_role_list"
                    config={{
                        presetUrl: PRESET_ENDPOINT_URL,
                        timeFormat: GENERAL_DATE_FORMAT,
                        langCode: i18n.language,
                        tableHeight: `${_this.state.tableHeight}px`,
                        tableWidth: '100%'
                    }}
                    variable={variableConfig}
                    actionEvent={_this.callback}
                    errorEvent={errorHandle}
                />
            ) : null}
        </section>
    );
};

export default template;
