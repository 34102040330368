import React from 'react';
import { useTranslation } from 'react-i18next';

interface FormFilterProps {
    isShowFilter: boolean;
    membershipSearchFilter;
    reciprocalAccesses;
    minimumContracts;
    accesses;
    paymentMethods;
    toggleFilter;
    filterRef;
    handleFilterChange;
    handleFilterPackagePlan;
    handleClearFilter;
}

const FormFilter: React.SFC<FormFilterProps> = ({
    isShowFilter,
    membershipSearchFilter,
    reciprocalAccesses,
    minimumContracts,
    accesses,
    paymentMethods,
    toggleFilter,
    filterRef,
    handleFilterChange,
    handleFilterPackagePlan,
    handleClearFilter
}) => {
    const { t } = useTranslation();
    return (
        <div ref={filterRef} className={`filter-sidebar ${isShowFilter ? 'collapsed-filter' : ''}`}>
            <div className="filter-header">
                <h3>{t('PAGE.MEMBERSHIPS.CREATE.FILTER')}</h3><i className="icon icon-close-ico" onClick={(event) => toggleFilter(event)}></i>
            </div>
            <div className="filter-content">
                <div className="form-group">
                    <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.RECIPROCAL_ACCESS')}</label>
                    <select
                        className="form-control"
                        style={{ width: '100%' }}
                        value={membershipSearchFilter.reciprocalAccessId}
                        onChange={(event) => handleFilterChange('reciprocalAccessId', event)}
                    >
                        <option value=''>{t('COMMON.ANY')}</option>
                        {reciprocalAccesses.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.name}>{`${item.name} (${item.count})`}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.MINIMUM_CONTRACT')}</label>
                    <select
                        className="form-control"
                        style={{ width: '100%' }}
                        value={membershipSearchFilter.minimumContractId}
                        onChange={(event) => handleFilterChange('minimumContractId', event)}
                    >
                        <option value=''>{t('COMMON.ANY')}</option>
                        {minimumContracts.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.name}>{`${item.name} (${item.count})`}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.ACCESS')}</label>
                    <select
                        className="form-control"
                        style={{ width: '100%' }}
                        value={membershipSearchFilter.accessId}
                        onChange={(event) => handleFilterChange('accessId', event)}
                    >
                        <option value=''>{t('COMMON.ANY')}</option>
                        {accesses.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.name}>{`${item.name} (${item.count})`}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>{t('PAGE.MEMBERSHIPS.CREATE.FILTER.PAYMENT_METHOD')}</label>
                    <select
                        className="form-control"
                        style={{ width: '100%' }}
                        value={membershipSearchFilter.paymentMethodId}
                        onChange={(event) => handleFilterChange('paymentMethodId', event)}
                    >
                        <option value=''>{t('COMMON.ANY')}</option>
                        {paymentMethods.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.name}>{`${item.name} (${item.count})`}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className="filter-footer">
                <div className="btn-box">
                    <button className="btn btn-primary" onClick={handleFilterPackagePlan}>{t('BUTTON.FILTER')}</button>
                    <button className="btn btn-dark" onClick={handleClearFilter}>{t('BUTTON.CLEAR')}</button>
                </div>
            </div>
            {/* <button className="btn btn-primary btn-show-filter" onClick={(event) => toggleFilter(event)}><i className="icon icon-filter"></i></button> */}
        </div>
    );
};

export default FormFilter;