import Loading from 'components/Loading';
import React from 'react';
import { UnfreezeSummary } from './common';
import { ThisInterface } from './interface';

const ButtonItem = ({
    type = 'primary',
    disabled = false,
    title,
    onClick = () => { }
}) => {
    return (
        <li>
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const renderActionButtons = (_this: ThisInterface) => {
    const {
        props: {
            t,
            onCancel
        },
        finish,
        state: {
            isValidForm
        }
    } = _this;
    return (
        <div className="col-12 mt-4">
            <ul className="list-btn-action">
                <ButtonItem
                    onClick={finish}
                    title={t('BUTTON.FINISH')}
                    disabled={!isValidForm}
                />
                <ButtonItem
                    onClick={onCancel}
                    title={t('BUTTON.CANCEL')}
                    type="dark"
                />
            </ul>
        </div>
    );
};

export const template = (_this: ThisInterface) => {
    const {
        state: {
            isLoading
        }
    } = _this;

    return isLoading
        ? <Loading />
        : (
            <div className="unfreeze-membership">
                <UnfreezeSummary {..._this} />
                {renderActionButtons(_this)}
            </div>
        );
};