import React from 'react';
import logoLogin from 'assets/images/logo-login.png';
import ForgotPassword from 'pages/ForgotPasswordPage/ForgotPassword';
import ChangePassword from 'pages/ForgotPasswordPage/ChangePassword';

import ThisInterface from './interface';
import i18n from 'i18next';

const renderPage = (match, conmanService, history, t) => {
    console.log(t);

    switch (match.path) {
        case '/forgot-password':
            return <ForgotPassword conmanService={conmanService} t={t!} />;
        case '/change-forgot-password':
            return <ChangePassword conmanService={conmanService} history={history} />;
        default:
            return <></>;
    }
};

const template = (_this: ThisInterface) => {
    const { switchLanguage, conmanService, match, history, t } = _this.props;
    const { isOpen } = _this.state;

    const languages = [
        {
            locale: 'en-US',
            name: t('TOPMENU.LANGUAGE.EN')
        },
        {
            locale: 'th',
            name: t('TOPMENU.LANGUAGE.TH')
        },
        {
            locale: 'zh-CN',
            name: t('TOPMENU.LANGUAGE.ZH')
        }
    ];

    return (
        <section className="login" id="forgot-password">
            <div className="container">
                <div className="multi-box">
                    <span
                        className={`icon icon-global-ico ${isOpen ? 'icon-active' : ''}`}
                        onClick={_this.handleOpenChangeLanguage}
                        ref={_this.languageRef}
                    >
                        {languages.map(item => {
                            return i18n.language === item.locale && item.name;
                        })}
                    </span>
                    <ul className={`dropdown-content drop-box ${isOpen ? 'show' : ''}`}>
                        {languages.map(item => {
                            return (
                                i18n.language !== item.locale && (
                                    <li
                                        key={item.locale}
                                        onClick={() => switchLanguage(item as any)}
                                    >
                                        <a>{item.name}</a>
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </div>
                <div className="row">
                    <div className="col-lg-5 col-md-6 loginForm">
                        <div className="loginForm__logo">
                            <img src={logoLogin} alt="logo" />
                        </div>
                        {renderPage(match, conmanService, history, t)}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
