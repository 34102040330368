import React from 'react';
import ThisInterface from './interface';
import PlanModel, { PackageModel, ClassModel, PaymentMethodModel, FixTermTypeModel, CorporateSubsidyModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import OptionModel from 'models/Option';

import FormItem, { getIdOrUndefined, getCodeOrNull, getCodeOrUndefined } from 'components/FormV2';
import Loading from 'components/Loading';
import * as Constants from 'constants/Constants';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
    classes: OptionModel[];
    paymentsMethod: OptionModel[];
    terms: OptionModel[];
    subsidies: OptionModel[];
}
interface Props {
    onChangeHandle: Function;
    data: PlanModel;
    fields: FieldModel[];
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true,
            classes: [],
            paymentsMethod: [],
            terms: [],
            subsidies: []
        }
    }

    componentDidMount() {
        const { subTenantId, rightName } = this.props;
        const getClasses = this.props.planService.getClasses(subTenantId, rightName).then((res) => {
            const classes: ClassModel[] = res.data;
            this.setState({
                classes: classes.map(e => ({ value: e.code, label: e.name }))
            });
        });
        const getPaymentsMethod = this.props.planService.getPaymentsMethod(subTenantId, rightName).then((res) => {
            const paymentsMethod: PaymentMethodModel[] = res.data;
            this.setState({
                paymentsMethod: paymentsMethod.map(e => ({ value: e.id, label: e.name }))
            });
        });
        const getTerms = this.props.planService.getTerms(subTenantId, rightName).then((res) => {
            const terms: FixTermTypeModel[] = res.data;
            this.setState({
                terms: terms.map(e => ({ value: e.id, label: e.name }))
            });
        });
        const getSubsidies = this.props.planService.getSubsidies(subTenantId, rightName).then((res) => {
            const subsidies: CorporateSubsidyModel[] = res.data;
            this.setState({
                subsidies: subsidies.map(e => ({ value: e.code, label: e.name }))
            });
        });
        Promise.all([
            getClasses,
            getPaymentsMethod,
            getTerms,
            getSubsidies
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { onChangeHandle, data, fields, rightName, subTenantId, t } = this.props;
        
        const buildOptions = (objects: any) => {
            if (objects && objects.data && objects.data.length) {
                return objects.data.map((e: PackageModel) => ({value: e.id, label: e.name}));
            }
            return [];
        }
        const buildFilterObject = (keyword: string) => {
            const keywords = {
                operation: 'contains',
                queryType: 'text',
                queryKey: 'name',
                queryValue: keyword
            };
            const value = getIdOrUndefined(data.package);
            if (value && !keyword) {
                return  JSON.stringify(
                    {
                        or: 
                                [   
                                    keywords, 
                                    {
                                        operation: 'eq',
                                        queryType: 'guid',
                                        queryKey: 'id',
                                        queryValue: value
                                    }
                                ]
                    }
                );
            } else {
                return JSON.stringify(keywords);
            }
        }
        
        const extraClubs = [
            { value: 'deny', label: t('PAGE.PRODUCTS.PLANS.CLONE.DEFAULT_NO_EXTRA_CLUBS') },
            { value: 'allow', label: t('PAGE.PRODUCTS.PLANS.CLONE.1_EXTRA_CLUB_ALLOWED') },
        ]
        const basicColumns = [
            {
                props: {
                    type: 'text',
                    htmlFor: 'basicDetail[name]',
                    fieldName: 'basicDetail[name]',
                    data: data.name,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.NAME')
                },
            },
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[package]',
                    fieldName: 'basicDetail[package]',
                    data: getIdOrUndefined(data.package),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.PACKAGE'),
                    advanceOptions: {
                        apiEndpoint: Constants.API_PLAN_GET_PACKAGES,
                        buildOptions: buildOptions,
                        // buildFilterObject: buildFilterObject,
                        pageSize: Constants.GENERAL_OPTION_PAGE_SIZE,
                        subTenantId: subTenantId,
                        rightName: rightName
                    }
                },
            },
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[class]',
                    fieldName: 'basicDetail[class]',
                    data: getIdOrUndefined(data.class),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.PLAN_CLASS'),
                    options: this.state.classes
                },
            },
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[paymentMethod]',
                    fieldName: 'basicDetail[paymentMethod]',
                    data: getIdOrUndefined(data.paymentMethod),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.PAYMENT_METHOD_CONFIGURATION'),
                    options: this.state.paymentsMethod
                },
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[sweepArrears]',
                    fieldName: 'basicDetail[sweepArrears]',
                    data: data.sweepArrearOntoBilling ? 1 : 0,
                    attribute: {
                        checked: data.sweepArrearOntoBilling
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.SWEEP_ARREARS_ONTO_BILLING')
                },
            },
            {
                props: {
                    type: 'number',
                    htmlFor: 'basicDetail[rejectThreshold]',
                    fieldName: 'basicDetail[rejectThreshold]',
                    data: data.rejectionCountThresholdOverride,
                    attribute: {
                        min: 0
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.REJECTION_COUNT_THRESHOLD_OVERRIDE')
                },
            },
        ];

        const optionsColumns = [
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[allFrozen]',
                    fieldName: 'basicDetail[allFrozen]',
                    data: data.isAllowFreezeMembership ? 1 : 0,
                    attribute: {
                        checked: data.isAllowFreezeMembership
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.ALLOW_MEMBERSHIP_TO_BE_FROZEN'),
                },
                className: "form-check mt-4"
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[ignoreNoFreeze]',
                    fieldName: 'basicDetail[ignoreNoFreeze]',
                    data: data.isIgnoreNoFreeze ? 1 : 0,
                    attribute: {
                        checked: data.isIgnoreNoFreeze
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.IGNORE_THE_NO_FREEZE_PERIOD_MEMBERSHIPS_CAN_BE_FROZEN_IMMEDIATELY'),
                },
                className: "form-check"
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[allowChangeHomeClub]',
                    fieldName: 'basicDetail[allowChangeHomeClub]',
                    data: data.isAllowChangeHomeClub ? 1 : 0,
                    attribute: {
                        checked: data.isAllowChangeHomeClub
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.ALLOW_CHANGE_HOME_CLUB'),
                },
                className: "form-check"
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[allowTransNonMember]',
                    fieldName: 'basicDetail[allowTransNonMember]',
                    data: data.isAllMemberToNonMemberTransfer ? 1 : 0,
                    attribute: {
                        checked: data.isAllMemberToNonMemberTransfer
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.ALLOW_MEMBER_TO_NON_MEMBER_TRANSFER'),
                },
                className: "form-check"
            },
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[useLastPeriodDues]',
                    fieldName: 'basicDetail[useLastPeriodDues]',
                    data: data.isUseLastPeriodDues ? 1 : 0,
                    attribute: {
                        checked: data.isUseLastPeriodDues
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.USE_LAST_PERIOD_DUES'),
                },
                className: "form-check"
            },
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[extraClubs]',
                    fieldName: 'basicDetail[extraClubs]',
                    data: data.isAllowExtraClub ? 'allow' : 'deny',
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.EXTRA_CLUBS'),
                    selectAllLabel: '',
                    options: extraClubs,
                },
                className: "mt-3"
            },
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[fixedTermType]',
                    fieldName: 'basicDetail[fixedTermType]',
                    data: getIdOrUndefined(data.fixTermType),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.FIXED_TERM_TYPE'),
                    options: this.state.terms
                },
                className: "mt-3"
            },
            {
                props: {
                    type: 'number',
                    htmlFor: 'basicDetail[fixedTermLimit]',
                    fieldName: 'basicDetail[fixedTermLimit]',
                    data: data.fixTermTypeLimit,
                    attribute: {
                        min: 0
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.FIXED_TERM_LIMIT'),
                },
                className: "mt-3"
            },
        ];

        const planColumns = [
            {
                props: {
                    type: 'text',
                    htmlFor: 'basicDetail[planDescriptor]',
                    fieldName: 'basicDetail[planDescriptor]',
                    data: data.description,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.DESCRIPTION')
                }
            },
        ];

        const corporateColumns = [
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[corporatePlan]',
                    fieldName: 'basicDetail[corporatePlan]',
                    data: data.isCorporatePlan ? 1 : 0,
                    attribute: {
                        checked: data.isCorporatePlan
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.CORPORATE_PLAN'),
                },
                className: "form-check"
            },
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[subsidyType]',
                    fieldName: 'basicDetail[subsidyType]',
                    data: getCodeOrUndefined(data.corporateSubsidy),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.SUBSIDY_TYPE'),
                    options: this.state.subsidies
                },
                className: "mt-3"
            },
        ];
		
        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.CLONE.BASIC_DETAILS')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    {this.state.isLoading ?
                        <Loading />
                        :
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-6">
                                        <h4 className="title">{t('PAGE.PRODUCTS.PLANS.CLONE.BASIC')}</h4>
                                        {basicColumns.map((field, key) =>
                                            <div key={key} className="mt-3">
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-6">
                                        <h4 className="title">{t('PAGE.PRODUCTS.PLANS.CLONE.OPTIONS')}</h4>
                                        {optionsColumns.map((field, key) =>
                                            <div key={key} className={field.className}>
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="basic-3">
                                    <div>
                                        <h4 className="title">{t('PAGE.PRODUCTS.PLANS.CLONE.PLAN_DESCRIPTOR')}</h4>
                                        {planColumns.map((field, key) =>
                                            <div key={key}>
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <h4 className="title">{t('PAGE.PRODUCTS.PLANS.CLONE.CORPORATE')}</h4>
                                        {corporateColumns.map((field, key) =>
                                            <div key={key} className={field.className}>
                                                <FormItem
                                                    {...field.props}
                                                    onChangeHandle={onChangeHandle}
                                                    fields={fields}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default Template;