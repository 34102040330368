import React from 'react';
import _ from 'lodash';
import { checkNullData, transformOptions } from 'util/ControlUtils';
import FormItem from 'components/FormV2';
import ThisInterface from './interface';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    renderPaymentInfo,
    Field,
    renderCreditInfo,
    getNationalInfo,
    getDebitCardInfo,
    getCreditCardInfo
} from './common';
import { PaymentMethods } from '../interface';

const renderViewByCardType = (_this: ThisInterface) => {
    const { primary, t } = _this.props;
    const { banks } = _this.props;
    if (primary) {
        switch (primary.paymentMethod.name) {
            case PaymentMethods.Debit:
                return renderPaymentInfo(
                    getDebitCardInfo(primary, banks, _this.props.validations),
                    _this
                );
            case PaymentMethods.Credit:
                return renderCreditInfo(getCreditCardInfo(primary, t), _this);
            default:
                return <></>;
        }
    }
    return <></>;
};


class CardInfo extends React.Component<ThisInterface['props'] & WithTranslation> {
    render() {
        const { fields, planPaymentMethods, primary, t } = this.props;

        const cardType = primary
            ? checkNullData(primary.paymentMethod, _.get(primary.paymentMethod, 'name'))
            : '';

        const primaryPaymentMethodFieldElement =
            planPaymentMethods.length > 1 ? (
                <FormItem
                    fieldName="paymentMethod"
                    attribute={{
                        isClearable: false
                    }}
                    type="select"
                    fields={fields}
                    onChangeHandle={(name, value) => {
                        this.props.handlePaymentMethodChanged(value);
                    }}
                    data={primary && primary.paymentMethod ? primary.paymentMethod.id : ''}
                    options={transformOptions(planPaymentMethods)}
                />
            ) : (
                cardType
            );

        return (
            <div className="direct-debit-form">
                <Field
                    key=""
                    element={primaryPaymentMethodFieldElement}
                    label={t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.PRIMARY_PAYMENT_METHOD')}
                />
                {renderViewByCardType(this)}
                <div className="national-id-details">
                    <div className="national-id-details-label">
                        <b>{t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.NATIONAL_ID_DETAILS')}</b>
                        <p>
                            {t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.IF_NATIONAL_ID_DETAILS_ARE_NOT_ENTERED_THE_MEMBERS_NATIONAL_ID_DETAILS_WILL_BE')}
                        </p>
                    </div>
                    <div className="national-id-details-content">
                        {renderPaymentInfo(
                            getNationalInfo(primary, fields, this.props.validations),
                            this
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(CardInfo);