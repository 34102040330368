import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'util/I18nMessages';

const Error404 = () => (
    <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
        <div className="page-error-content">
            <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
                {t('MSG.MESSAGE_404_NOT_FOUND')}
            </h2>
            <p className="text-center zoomIn animation-delay-20 animated">
                <Link className="btn btn-primary" to="/">
                    {t('COMMON.GO_TO_HOME')}
                </Link>
            </p>
        </div>
    </div>
);

export default Error404;
