export default class PackageModel {
    description = '';
    effectiveDate = '';
    expirationDate = '';
    id = '';
    isEligibilityQuestionRequired = false;
    isRegionRestricted = false;
    isTemplate = true;
    name = '';
    timeRestricted = false;
    packageClass = { code: '', name: '' };
    tier = { id: '', name: '', code: '', level: 0, statusCode: '' };
    isAllowFreeze = true;
    isTimeRestricted = false;
    timeGroup = { id: 0, description: '' };
}
