import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { withTranslation } from 'react-i18next';

import * as RouteLoader from 'pages/RouteLoader';
import { submitModal, errorModal } from 'components/CommonModal';
import OptionModel from 'models/Option';
import commonService from 'services/common.service';
import observer from 'util/Observer';
import {
    getFutureDateYearWithMonth,
    DisplayDateFormat
} from 'util/ControlUtils';
import {
    GENERAL_DATABASE_DATE_FORMAT,
    API_GET_FREEZE_REASON
} from 'constants/Constants';
import * as DefaultConstants from 'constants/DefaultConstants';
import {
    API_GET_FREEZE,
    API_CREATE_FREEZE_MEMBERSHIP,
    API_MODIFY_AND_DELETE_FREEZE
} from 'constants/Constants';

import template from './template';
import ThisInterface from './interface';

class FreezeMembership extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    commonService: commonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        const {
            memberData: { futureEvents }
        } = props;
        const isModify =
			!_.isEmpty(futureEvents) && futureEvents.some(item => item.type === 'FZ');

        const {
            memberData: { email }
        } = props;
        this.state = {
            isLoading: true,
            section: 'freeze',
            freezeData: null,
            requestFreeze: null,
            email,
            emailError: false,
            disabledFinish: false,
            isModify,
            freezeMember: null
        };
        this.commonService = new commonService();
    }

    componentDidMount() {
        this.getFreezeData();
        observer.subscribe(
            'requestFreeze',
            (data: ThisInterface['state']['requestFreeze']) => {
                if (data) {
                    this.setState({
                        requestFreeze: data
                    });
                }
            }
        );
        observer.subscribe('communicationEmailChanged', data => {
            if (data) {
                this.setState({
                    email: data.email,
                    emailError: data.error,
                    disabledFinish: data.email !== '' && data.error
                });
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('requestFreeze');
        observer.unsubscribe('communicationEmailChanged');
    }

    getFreezeData = () => {
        const {
            memberData,
            match: { params },
            dateFormat
        } = this.props;
        const { isModify } = this.state;
        
        Promise.all([
            this.commonService.fetchData(
                API_GET_FREEZE.replace('{id}', params['id']),
                {
                    rightName: RouteLoader.ViewRequestFreezeMemberShip.rightName,
                    subTenantId: memberData.subTenantId
                }
            ),
            this.commonService.fetchData(API_GET_FREEZE_REASON, {
                rightName: RouteLoader.ViewRequestFreezeMemberShip.rightName,
                subTenantId: memberData.subTenantId
            })
        ])
            .then(([resFreeze, resReason]) => {
                if (resFreeze && resReason) {
                    let startDateRange: OptionModel[] = [],
                        freezeDurationRange: OptionModel[] = [],
                        freezePrice = 0,
                        isPlanAllowFreezeToday = false,
                        reasons: OptionModel[] = [];
                    startDateRange = resFreeze.startDateRange.map(item => ({
                        value: item,
                        label: DisplayDateFormat(item, dateFormat)
                    }));
                    freezeDurationRange = resFreeze.freezeDurationRange.map(item => ({
                        value: item,
                        label: `${item}`
                    }));
                    freezePrice = resFreeze.freezePrice;
                    isPlanAllowFreezeToday = resFreeze.isPlanAllowFreezeToday;
                    if (!_.isEmpty(resReason.data)) {
                        reasons = resReason.data.map(item => ({
                            ...item,
                            value: item.code,
                            label: item.name
                        }));
                    }
                    this.setState({
                        freezeData: {
                            ...resFreeze,
                            startDateRange,
                            freezeDurationRange,
                            freezePrice,
                            isPlanAllowFreezeToday,
                            reasons,
                            freezeNoticeEndDate: resFreeze.freezeNoticeEndDate
                        }
                    }, () => {
                        if (isModify) {
                            this.commonService
                                .fetchData(
                                    API_CREATE_FREEZE_MEMBERSHIP.replace('{id}', params['id']),
                                    {
                                        rightName: RouteLoader.ViewModifyFreezeMemberShip.rightName,
                                        subTenantId: memberData.subTenantId
                                    }
                                )
                                .then(res => {
                                    if (res) {
                                        this.setState({
                                            freezeMember: {
                                                ...res,
                                                reason: res.freezeReason.code,
                                                freezePrice: res.freezeValue
                                            },
                                            isLoading: false
                                        });
                                    }
                                });
                        } else {
                            this.setState({
                                freezeMember: {
                                    startDate: startDateRange[0] ? startDateRange[0].value : '',
                                    duration: freezeDurationRange[0] ? parseInt(freezeDurationRange[0].value, 10) : 0,
                                    reason: reasons[0] ? reasons[0].value : '',
                                    isOverrideFreezeNoticePeriod: false,
                                    isAllowFreezeBackDate: false,
                                    isAllowFutureFreezeStartDate: false,
                                    isPlanAllowFreezeToday,
                                    freezePrice,
                                },
                                isLoading: false
                            });
                        }
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    handleSubmitFreeze = () => {
        const { requestFreeze, email, emailError, freezeMember, isModify } = this.state;
        const {
            match: { params },
            memberData: { subTenantId },
            onCancel,
            t
        } = this.props;

        if (params['id'] && requestFreeze) {
            if (email === '' && emailError) {
                errorModal('MSG.MEMBERSHIPS.CHANGE_PAYMENT_DETAIL.WRONG_FORMAT_EMAIL_OR_EMPTY');
                return;
            } else {
                const { freezePrice, reason, startDate, duration } = requestFreeze;
                const data = {
                    ...requestFreeze,
                    startedDateUtc: `${DisplayDateFormat(
                        startDate,
                        GENERAL_DATABASE_DATE_FORMAT
                    )}T00:00:00Z`,
                    endDateUtc: getFutureDateYearWithMonth(
                        new Date(startDate),
                        duration,
                        true
                    ),
                    freezeReasonCode: reason ? reason.code : '',
                    freezeValue: freezePrice,
                    freezeNoticeEmail: email,
                    subTenantId
                };
                submitModal('PAGE.MEMBERSHIPS.FREEZE_MEMBERSHIP.CONFIRM_FREEZE_MESSAGE', () => {
                    if (isModify) {
                        this.commonService
                            .putData(
                                API_MODIFY_AND_DELETE_FREEZE.replace('{id}', params['id']).replace('{freezeId}', freezeMember!['id']),
                                data,
                                {
                                    rightName: RouteLoader.ViewModifyFreezeMemberShip.rightName,
                                    subTenantId
                                }
                            )
                            .then(res => {
                                if (res) {
                                    Swal.fire({
                                        ...DefaultConstants.SWAL_COMMON_STYLE,
                                        type: 'success',
                                        html: t('PAGE.MEMBERSHIPS.FREEZE_MEMBERSHIP.CONFIRM_FREEZE_MESSAGE_SUCCESS'),
                                        confirmButtonText: t('BUTTON.CLOSE')
                                    }).then(() => onCancel());
                                }
                            });
                    } else {
                        this.commonService
                            .postData(
                                API_CREATE_FREEZE_MEMBERSHIP.replace('{id}', params['id']),
                                data,
                                {
                                    rightName: RouteLoader.ViewRequestFreezeMemberShip.rightName,
                                    subTenantId
                                }
                            )
                            .then(res => {
                                if (res) {
                                    Swal.fire({
                                        ...DefaultConstants.SWAL_COMMON_STYLE,
                                        type: 'success',
                                        html: t('PAGE.MEMBERSHIPS.FREEZE_MEMBERSHIP.CONFIRM_FREEZE_MESSAGE_SUCCESS'),
                                        confirmButtonText: t('BUTTON.CLOSE')
                                    }).then(() => onCancel());
                                }
                            });
                    }
                });
            }
        }
    };

    handleMoveSection = (key: string) => {
        const { requestFreeze } = this.state;
        this.setState({
            section: key
        }, () => {
            if (requestFreeze) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        freezeMember: {
                            ...prevState.freezeMember,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            ...requestFreeze as any,
                            reason: requestFreeze.reason ? requestFreeze.reason.code : null
                        },
                        freezeData: prevState.freezeData ? {
                            ...prevState.freezeData,
                            startDateRange: requestFreeze.startDateRange
                        } : null
                    }
                })
            }
        });
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(withRouter(FreezeMembership));
