import React from 'react';
import embededWrapper from 'hocs/Embeded';
import Loading from 'components/Loading';
import { getIframeAPIURL, parseParamFromUrl } from 'util/ControlUtils';
import { IFRAME_PROMOTION_LIST } from 'constants/Constants';
import { isHasRight } from 'util/ControlUtils';
import * as RouteLoader from 'pages/RouteLoader';

import ThisInterface from './interface';

class MFPromotionPage extends React.Component<ThisInterface['props'], {}> {
    
    componentDidMount = () => {
        window.addEventListener('message', this.messageHandler);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data;
        
    
        switch (action) {
            case 'returnHeightData': 
                const obj: any = this.refs.iframe;
                if (obj) {
                    obj.style.height = value + 'px';
                }
                break;
            
            case 'redirectURL':
                if(value.includes('/Products/Promotions/Wizards/AddPromotion.aspx?PromotionId')) {
                    const paramUrl = decodeURIComponent(value).split('?');
                    const redirectUrl = paramUrl.find(item => item.indexOf('PromotionId=') > -1);
                    const params = redirectUrl && parseParamFromUrl(redirectUrl);
                    const promotionIdObj = params && params.find(item => item.key === 'PromotionId');
                
                    if(promotionIdObj){
                        const url = (RouteLoader.EditPromotionRoute.url)
                            .replace(':id', promotionIdObj.value);

                        this.props.history.push(`/${url}`);
                    }
                    break;
                }

                if(value.includes('/Products/Promotions/Wizards/EditPromotionAvailability.aspx?PromotionId')) {
                    const paramUrl = decodeURIComponent(value).split('?');
                    const redirectUrl = paramUrl.find(item => item.indexOf('PromotionId=') > -1);
                    const params = redirectUrl && parseParamFromUrl(redirectUrl);
                    const promotionIdObj = params && params.find(item => item.key === 'PromotionId');
                
                    if(promotionIdObj){
                        const url = (RouteLoader.EditPromotionAvailabilityRoute.url)
                            .replace(':id', promotionIdObj.value);

                        this.props.history.push(`/${url}`);
                    }
                    break;
                }
        }
    }

    permission = () => {
        const obj: any = this.refs.iframe;

        const hasEditPromotionRight = isHasRight(RouteLoader.EditPromotionRoute.rightName);
        if(hasEditPromotionRight) {
            obj.contentWindow.postMessage({
                action: 'hasEditPromotionRight'
            }, '*');
        }

        const hasAvaibilityPromotionRight = isHasRight(RouteLoader.EditPromotionAvailabilityRoute.rightName);
        if(hasAvaibilityPromotionRight) {
            obj.contentWindow.postMessage({
                action: 'hasAvaibilityPromotionRight'
            }, '*');
        }
    }
	
    render() {
        const { iframeLoaded, iframeToken, subTenantId } = this.props;

        return (
            <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && (
                            <iframe
                                src={`${getIframeAPIURL(IFRAME_PROMOTION_LIST, subTenantId, '/MembershipManagement')}?token=${iframeToken}`}
                                ref='iframe'
                                onLoad={() => this.props.resizeIframe(this.refs.iframe, this.permission)}
                                className="iframe"
                                frameBorder="0"
                            />
                        )}
                    </div>
                </div>
            </div>  
        )
    }
}

export default embededWrapper(MFPromotionPage, 'PromotionRoute', 'PAGE.PRODUCTS.PROMOTIONS.TITLE', 'PAGE.PRODUCTS.PROMOTIONS.INSTRUCTION');