import React from 'react';
import editProfile from 'assets/images/edit-profile.png';
import calendar from 'assets/images/calendar.svg';

export default class MemberSummary extends React.Component<any> {
    render() {
        return (
            <React.Fragment>
                <div className="select-add-on-main">
                    <div className="general-info">
                        <div className="row">
                            <div className="info-acc col-md-8">
                                <div className="box-avatar">
                                    <div className="edit-avartar">
                                        <img
                                            className="img-acc"
                                            src={editProfile}
                                            alt="user-avatar"
                                        />
                                    </div>
                                </div>
                                <div className="info-detail">
                                    <p>
                                        <b>MS_IRIS_TEST_SUMMARY_MEMBER</b>
                                    </p>
                                    <p><b>DOB:</b> 06-Feb</p>
                                    <p>
                                        <b>Member since:</b> 11/01/2019 (6 Days)
                                    </p>
                                    <p>
                                        <b>ID Number:</b> *****6789
                                    </p>
                                </div>
                            </div>
                            <div className="general-content col-md-4">
                                <p>
                                    <b className="large-size">CF Lot 10</b>
                                </p>
                                <p className="membership-stt positive">
                  Membership Status: <span className="positive">OK</span>
                                </p>
                                <p className="payment-stt negative">
                  Payment Status: <span className="negative">Arrears</span>
                                </p>
                                <p className="last-swipe-date-stt sad">
								Last Swipe Date: <span className="sad">08/3/2019</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="membersummary-list">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="section-summary section-summary--debt-summary">
                                    <div className="summary-header">
                                        <p>DEBT SUMMARY</p>
                                        <ul className="list-action">
                                            <span>
                                                <li className="list-no-action list-action__items">
                                                    <span>View Debt List</span>
                                                </li>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <dl>
                                                <dt className="color-red">Debt Stage:</dt>
                                                <dd className="color-red">P2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Debt Start Date:</dt>
                                                <dd>20/07/2019</dd>
                                            </dl>
                                            <dl>
                                                <dt>Contract Start Date:</dt>
                                                <dd>30/07/2019</dd>
                                            </dl>
                                            <dl>
                                                <dt>Date of MCP:</dt>
                                                <dd>31/07/2020</dd>
                                            </dl>
                                            <dl>
                                                <dt>Within MCP?:</dt>
                                                <dd>Yes</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <dl>
                                                <dt className="color-red">Remaining CCV:</dt>
                                                <dd className="color-red">4.266,000.00</dd>
                                            </dl>
                                            <dl>
                                                <dt>Member Dues:</dt>
                                                <dd>474,000.00</dd>
                                            </dl>
                                            <dl>
                                                <dt>Contract Notice Period:</dt>
                                                <dd>1 Month</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Attended:</dt>
                                                <dd>-</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <dl>
                                                <dt className="color-red">Arrear Value:</dt>
                                                <dd className="color-red">-275</dd>
                                            </dl>
                                            <dl>
                                                <dt>Original Reject Reason:</dt>
                                                <dd>N/A</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Reject Date:</dt>
                                                <dd>10/01/2019</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Bank Reject Type MF:</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Bank Reject Type:</dt>
                                                <dd>Invalid</dd>
                                            </dl>
                                            <dl>
                                                <dt>Description:</dt>
                                                <dd>payment method data</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 section-line-between">
                                <div className="section-summary section-summary--member-account">
                                    <div className="summary-header member-account-header">
                                        <div className="d-flex justify-content-between">
                                            <p>MEMBER ACCOUNT</p>
                                            <ul className="list-action">
                                                <span>
                                                    <li className="action">
                                                        <span>Action</span>
                                                    </li>
                                                </span>
                                            </ul>
                                        </div>
                                        <ul className="list-action">
                                            <span>
                                                <li className="list-action__items">
                                                    <span>Change Payment Details</span>
                                                </li>
                                            </span>
                                            <span>
                                                <li className="list-action__items">
                                                    <span>Make Payment</span>
                                                </li>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <dl>
                                                <dt>Last charge:</dt>
                                                <dd>474,000.00 on 01/10/2019</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-12 col-lg-12">
                                            <dl>
                                                <dt>Next charge:</dt>
                                                <dd>474,000.00 on 01/11/2019</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-12 col-lg-12">
                                            <dl>
                                                <dt className="color-red">Current balance:</dt>
                                                <dd className="color-red">-275</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="section-summary">
                                    <div className="summary-header membership-header">
                                        <div className="d-flex justify-content-between">
                                            <p>MEMBERSHIP</p>
                                            <ul className="list-action">
                                                <span>
                                                    <li className="action">
                                                        <span>Action</span>
                                                    </li>
                                                </span>
                                            </ul>
                                        </div>
                                        <ul className="list-action">
                                            <span>
                                                <li className="list-action__items">
                                                    <span>Frezze membership</span>
                                                </li>
                                            </span>
                                            <span>
                                                <li className="list-action__items">
                                                    <span>Request Cancellation</span>
                                                </li>
                                            </span>
                                            <span>
                                                <li className="list-action__items">
                                                    <span>Change Membership</span>
                                                </li>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            <dl>
                                                <dt>Tier:</dt>
                                                <dd>Home</dd>
                                            </dl>
                                            <dl>
                                                <dt>Access:</dt>
                                                <dd>Full Access</dd>
                                            </dl>
                                            <dl>
                                                <dt>Start Date:</dt>
                                                <dd>14/02/2020</dd>
                                            </dl>
                                            <dl>
                                                <dt>Length Of:</dt>
                                                <dd>3 Months (Until 13/05/2020)</dd>
                                            </dl>
                                            <dl>
                                                <dt>Sale Person:</dt>
                                                <dd>Sold by NashTechaa Global in CF Lot 10</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <dl>
                                                <dt>Package:</dt>
                                                <dd>Home</dd>
                                            </dl>
                                            <dl>
                                                <dt>Plan:</dt>
                                                <dd>Home Corp Full-Sub Recurring</dd>
                                            </dl>
                                            <dl>
                                                <dt>End Date:</dt>
                                                <dd>13/05/2020</dd>
                                            </dl>
                                            <dl>
                                                <dt>Within MBG?:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Within MCP?:</dt>
                                                <dd>Yes</dd>
                                            </dl>
                                            <dl>
                                                <dt>Remaining CCV:</dt>
                                                <dd />
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 section-line-between">
                                <div className="section-summary">
                                    <div className="summary-header">
                                        <p>PERSONAL DETAILS</p>
                                        <ul className="list-action">
                                            <span>
                                                <li className="list-action__items">
                                                    <a href="#">Change Personal Detail</a>
                                                </li>
                                            </span>
                                            <span>
                                                <li className="action">
                                                    <span>Action</span>
                                                </li>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <dl>
                                                <dt>Address:</dt>
                                                <dd>N/A</dd>
                                            </dl>
                                            <dl>
                                                <dt>Email:</dt>
                                                <dd>daaa@ddd.ccc</dd>
                                            </dl>
                                            <dl>
                                                <dt>Mobile Number:</dt>
                                                <dd>54333333</dd>
                                            </dl>
                                            <dl>
                                                <dt>Home Number:</dt>
                                                <dd>N/A</dd>
                                            </dl>
                                            <dl>
                                                <dt>Work Number:</dt>
                                                <dd>N/A</dd>
                                            </dl>
                                            <dl>
                                                <dt>Health Questionnaire:</dt>
                                                <dd>OK</dd>
                                            </dl>
                                            <dl>
                                                <dt>Preferred Language:</dt>
                                                <dd>English</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="freeze-event-wrapper">
                                    <div className="feature-event">
                                        <div className="calendar-img">
                                            <img
                                                src={calendar}
                                                alt="calendar"
                                            />
                                        </div>
                                        <div className="text-holder">
                                            <p className="title">Future event</p>
                                            <div className="freeze-description">
                        Cancel created on 13/02/2020 to be actioned from
                        01/04/2020 because of Medical
                                            </div>
                                        </div>
                                    </div>
                                    <div className="summary-header no-mg">
                                        <ul className="list-action">
                                            <li className="list-action__items">
                                                <span>Remove</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="section-summary">
                                    <div className="summary-header">
                                        <p>ACTIVITY</p>
                                        <ul className="list-action">
                                            <span>
                                                <li className="list-no-action list-action__items">
                                                    <span>Create Booking</span>
                                                </li>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">
                                            <dl>
                                                <dt>Member Card Has Been Assigned:</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Visits:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Online Access:</dt>
                                                <dd>Not Registered</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9 section-line-between">
                                <div className="section-summary">
                                    <div className="summary-header">
                                        <p>CONTACT</p>
                                        <ul className="list-action">
                                            <span>
                                                <li className="list-action__items">
                                                    <span>Add Note</span>
                                                </li>
                                            </span>
                                            <span>
                                                <li className="list-no-action list-action__items">
                                                    <span>Add Message</span>
                                                </li>
                                            </span>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4">
                                            <dl>
                                                <dt>Notes:</dt>
                                                <dd>Test</dd>
                                            </dl>
                                            <dl>
                                                <dt>Messages:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <dl>
                                                <dt>Emails:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Letters:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <dl>
                                                <dt>Documents:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Interactions:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-btn">
                    <ul className="list-btn-action">
                        <li>
                            <a className="btn btn-primary btn-long-text">Member Documents</a>
                        </li>
                        <li>
                            <a className="btn btn-primary btn-long-text">
                Member to Non Member Transfer
                            </a>
                        </li>
                        <li>
                            <a className="btn btn-primary btn-long-text">
                Move to Corporate membership
                            </a>
                        </li>
                        <li>
                            <a className="btn btn-dark">Back to Members</a>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}
