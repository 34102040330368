import React from 'react';
import _ from 'lodash';
import FormItem from 'components/FormV2';
import {
    transformOptions,
    formatCreditDebitCard,
    DisplayCardNumber,
    formatExpiryDate,
    checkNullData
} from 'util/ControlUtils';
import { t } from 'util/I18nMessages';
import ThisInterface from './interface';
interface FieldInterface {
    label: string;
    element: React.ReactChild;
}

export const getDebitCardInfo = (
    primary: ThisInterface['props']['primary'],
    banks: ThisInterface['props']['banks'],
    validations: ThisInterface['props']['validations']
) => {
    const { accountName, bank, cardNumber } = primary;
    return [
        {
            label: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ACCOUNT_NAME'),
            props: {
                type: 'text',
                htmlFor: 'accountName',
                fieldName: 'accountName',
                htmlLabel: 'Account Name',
                data: accountName.toUpperCase(),
                fields: [validations['accountName']]
            }
        },
        {
            label: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ISSUING_BANK'),
            props: {
                type: 'select',
                className: 'select-bank select-custom',
                options: transformOptions(banks),
                htmlFor: 'bank.id',
                fieldName: 'bank.id',
                data: bank ? bank.id : undefined,
                fields: [validations['bank.id']],
                attribute: {
                    isClearable: false
                }
            }
        },
        {
            label: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ACCOUNT_NUMBER'),
            props: {
                type: 'text',
                htmlFor: 'cardNumber',
                fieldName: 'cardNumber',
                htmlLabel: 'Account Number',
                data: cardNumber ? cardNumber.replace(/[^0-9]/g, '') : '',
                fields: [
                    {
                        ...validations['cardNumber'],
                        maxLength: 10
                    }
                ]
            }
        }
    ];
};

export const getCreditCardInfo = (
    primary: ThisInterface['props']['primary'],
    t
) => {
    const {
        cardType,
        accountName,
        cardNumber,
        expiryDate,
        bank,
        issueNumber
    } = primary;

    return [
        {
            label: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_TYPE'),
            element: checkNullData(cardType, _.get(cardType, 'name'))
        },
        {
            label: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_HOLDER_NAME'),
            element: checkNullData(accountName, accountName.toUpperCase())
        },
        {
            label: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_NUMBER'),
            element: checkNullData(cardNumber, DisplayCardNumber(cardNumber))
        },
        {
            label: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.EXPIRED_DATE'),
            element: checkNullData(expiryDate)
        },
        {
            label: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_ISSUING_BANK'),
            element: checkNullData(bank, _.get(bank, 'name'))
        },
        {
            label: t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.ISSUING_COUNTRY'),
            element: checkNullData(issueNumber)
        }
    ];
};

const commonNationalProps = (
    name: string,
    primary: ThisInterface['props']['primary'],
    validations: ThisInterface['props']['validations'],
    key: string
) => {
    return {
        label: name,
        props: {
            type: 'text',
            htmlFor: key,
            fieldName: key,
            data: primary && primary[key] ? primary[key] : '',
            fields: [
                {
                    ...validations[key],
                    maxLength: key === 'nationalId' ? 12 : 15
                }
            ]
        }
    };
};

export const getNationalInfo = (
    primary: ThisInterface['props']['primary'],
    fields: ThisInterface['props']['fields'],
    validations: ThisInterface['props']['validations']
) => {
    return fields && fields.length > 0
        ? fields.map(item => {
            switch (item.key) {
                case 'addMember.nationalId.NationalId':
                    return commonNationalProps(item.name, primary, validations, 'nationalId');
                case 'addMember.nationalId.Alternativeid':
                    return commonNationalProps(
                        item.name,
                        primary,
                        validations,
                        'otherNationalId'
                    );
                default:
                    return {};
            }
        })
        : [];
};

export const CardHolder = ({ handleChange, primary, validations, t  }) => {
    const props = {
        type: 'text',
        onChangeHandle: (name, value) => handleChange(name, value)
    };

    return (
        <div className="row">
            <div className="col-8 col-xs-12 card-holder">
                <FormItem
                    {...props}
                    data={primary ? primary.accountName.toUpperCase() : ''}
                    fieldLabel={t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_HOLDER_NAME')}
                    fieldName="accountName"
                    htmlFor="accountName"
                    fields={[validations['accountName']]}
                />
                <FormItem
                    {...props}
                    data={
                        primary && primary.cardNumber
                            ? formatCreditDebitCard(primary.cardNumber)
                            : ''
                    }
                    fieldLabel={t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.CARD_NUMBER')}
                    fieldName="cardNumber"
                    htmlFor="cardNumber"
                    fields={[
                        {
                            ...validations['cardNumber'],
                            maxLength: 19
                        }
                    ]}
                />
                <FormItem
                    className="expiry-date"
                    {...props}
                    fields={[
                        {
                            ...validations['expiryDate'],
                            maxLength: 5
                        }
                    ]}
                    data={
                        primary && primary.expiryDate
                            ? formatExpiryDate(primary.expiryDate)
                            : ''
                    }
                    fieldLabel={t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.EXPIRED_DATE')}
                    fieldName="expiryDate"
                    htmlFor="expiryDate"
                />
            </div>
        </div>
    );
};

export const Field: React.FC<FieldInterface> = ({ label, element }) => {
    return (
        <div className="row">
            <div className="col-3 col-xs-12">
                <label>{label}</label>
            </div>
            <div className="col-4 col-xs-12">{element}</div>
        </div>
    );
};

export const renderPaymentInfo = (info, _this: ThisInterface) => {
    return info && info.length > 0
        ? info.map((item, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col-3 col-xs-12">
                        <label>{item.label}</label>
                    </div>
                    <div className="col-4 col-xs-12">
                        <FormItem
                            {...item.props}
                            onChangeHandle={(name, value) =>
                                _this.props.handleFieldChanged(name, value)
                            }
                        />
                    </div>
                </div>
            );
        })
        : null;
};

export const renderCreditInfo = (info, _this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <React.Fragment>
            <Field
                label= {t('PAGE.MEMBERSHIPS.EDIT.CHANGE_PAYMENT_DETAIL.MODIFY_PAYMENT_DETAIL')}
                element={
                    <FormItem
                        type="toogle"
                        htmlFor="modifyPaymentDetail"
                        fieldName="modifyPaymentDetail"
                        data={_this.props.isShowCreditCardModify ? 1 : 0}
                        onChangeHandle={_this.props.handleChangeToogle}
                        fields={[]}
                    />
                }
            />
            {_this.props.isShowCreditCardModify ? (
                <CardHolder
                    handleChange={_this.props.handleFieldChanged}
                    primary={_this.props.primary ? _this.props.primary : {}}
                    validations={_this.props.validations}
                    t={t}
                />
            ) : info && info.length > 0 ? (
                info.map((item, index) => {
                    return (
                        <Field key={index} label={item.label} element={item.element} />
                    );
                })
            ) : null}
        </React.Fragment>
    );
};
