import * as Interface from 'interfaces';
import { ChangePersonalDetailsModel, ChangePersonalDetailsFieldConfig } from 'models/ChangePersonalDetailsModel';
import { RouteComponentProps } from 'react-router-dom';

export enum CpdSteps {
    PERSONAL_DETAILS,
    MEMBER_SUMMARY
}

export interface PropsInterface extends RouteComponentProps<{}> {
    history: any;
    auth: any;
}


export default interface ThisInterface {
    props: {
        membershipService: Interface.PagePropsInterface['membershipService'];
        conmanService: Interface.PagePropsInterface['conmanService'];
        match: any;
        history: any;
        auth: any;
    };

    state: {
        activeStep: number;
        originalPersonalDetails: ChangePersonalDetailsModel;
        newPersonalDetails: ChangePersonalDetailsModel;
        fieldConfigs: ChangePersonalDetailsFieldConfig;
        dateFormat: string;
        somethingChanged: boolean;
        previousSection: string;
    };

    handleCancel: () => void;

    handleNext: (newPersonalDetails: ChangePersonalDetailsModel) => void;

    handlePrevious: () => void;

    handleFinish: () => void;

    checkClaim: (rightName: string) => boolean;
}