import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import CropImage from 'components/CropImage';
import Operator from 'models/Operator';
import * as Interface from 'interfaces';
import observer from 'util/Observer';
import { getDialCode } from 'util/ControlUtils';
import * as DefaultConstants from 'constants/DefaultConstants';

interface UserProps {
    operator: Operator;
    userService: Interface.PagePropsInterface['userService'];
    user: Interface.StateToPropInterface['oidc']['user'];
}

const UserProfile: React.FunctionComponent<UserProps> = React.memo(
    ({ operator, userService, user }) => {
        const { t } = useTranslation();
        const [image, setImage] = useState(operator.avatarUri);
        function handleCancel() {
            observer.publish('closeDialogEvent', true);
        }

        function handleSaveAvatar(imageSrc: string) {
            handleCancel();
            Swal.fire({
                html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                showConfirmButton: false
            });
            userService.updateUserAvatar(operator.id, imageSrc).then(() => {
                setImage(imageSrc);
                observer.publish('loaduserProfile', {
                    ...operator,
                    avatarUri: imageSrc
                });
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    type: 'success',
                    html: t('PAGE.UPDATE_PROFILE.UPDATE_AVATAR_SUCCESS'),
                    confirmButtonText: t('BUTTON.CLOSE')
                });
            });
        }

        function resize() {
            observer.publish(
                'windowResize',
                window.innerWidth > 768
                    ? { canvasW: 276, canvasH: 230 }
                    : { canvasW: 186, canvasH: 186 }
            );
        }

        function openModal() {
            observer.publish('openDialogEvent', {
                content: (
                    <CropImage
                        onSave={handleSaveAvatar}
                        onCancel={handleCancel}
                        height={window.innerWidth > 768 ? 230 : 186}
                        width={window.innerWidth > 768 ? 276 : 186}
                        isAvatar
                    />
                ),
                classHolder: 'user-profile-picture'
            });
            window.onresize = resize;
        }

        function openSelectImage() {
            openModal();
        }

        return (
            <React.Fragment>
                <div className="info-acc">
                    <div className="card-acc">
                        <div className="box-avatar" onClick={openSelectImage}>
                            <div className="edit-avartar">
                                <img className="img-acc" src={image} alt="" />
                                <span className="icon-edit-ico" />
                            </div>
                        </div>
                        <div className="detail-acc">
                            <div className="title-acc">
                                <h3 className="acc-name text-center">
                                    {operator.firstName || operator.lastName
                                        ? `${operator.firstName} ${operator.lastName}`
                                        : ''}
                                </h3>

                                <p className="acc-position text-center text-uppercase">
                                    {operator.title}
                                </p>
                            </div>
                            <ul>
                                {user.profile && user.profile.amr[0] !== 'external' && (
                                    <li className="border-tag">
                                        <NavLink to="/config/change-password">
                                            <span className="icon-lock-icon">
                                                {t('PAGE.UPDATE_PROFILE.CHANGE_PASSWORD')}
                                            </span>
                                        </NavLink>
                                    </li>
                                )}
                                {operator.mobilePhoneNumber && (
                                    <li>
                                        <span className="icon-phone-ico">
                                            {`+${getDialCode(
                                                operator.mobilePhoneNumber,
                                                operator.mobilePhoneRegionCode
                                            )} ${operator.mobilePhoneNumber}`}
                                        </span>
                                    </li>
                                )}
                                {operator.email && (
                                    <li
                                        className="tool-tip"
                                        tool-tip={operator.email}
                                        data-flow="up"
                                    >
                                        <span className="icon-email-ico">{operator.email}</span>
                                    </li>
                                )}
                            </ul>
                            {user.profile && user.profile.amr[0] === 'external' && (
                                <p
                                    className="tool-tip acc-azure"
                                    tool-tip={operator.userName}
                                    data-flow="up"
                                >
                                    <span className="mail-user">{operator.userName}</span>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
);

UserProfile.displayName = 'UserProfile';

export default UserProfile;
