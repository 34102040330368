import React from 'react';
import _ from 'lodash';

import FormItem from 'components/FormV2';
import {
    checkNullData,
    DisplayDateFormat,
    DisplayCardNumber,
    displayBankNumber
} from 'util/ControlUtils';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';

import ThisInterface from './interface';
import { PaymentMethods } from '../interface';

interface DisplayItem {
    title: string;
    template: React.ReactChild;
}

const commonField = (
    paymentDetails: ThisInterface['props']['paymentDetails'],t 
) => {
    const { primary, secondary } = paymentDetails;

    const {
        billingReference,
        nationalId,
        otherNationalId,
        lastUpdatedDate
    } = primary;
    return [
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.BILLING'),
            template: checkNullData(billingReference)
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.PAYERS_NATIONAL_ID'),
            template: checkNullData(nationalId)
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.PAYERS_OLD_OTHER_NATIONAL_ID'),
            template: checkNullData(otherNationalId)
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.LAST_UPDATED'),
            template: checkNullData(
                lastUpdatedDate,
                DisplayDateFormat(lastUpdatedDate, GENERAL_DATE_FORMAT)
            )
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.SECONDARY_PAYMENT'),
            template: checkNullData(secondary, _.get(secondary, 'paymentMethod.name'))
        }
    ];
};

const debitDisplayItems = (
    paymentDetails: ThisInterface['props']['paymentDetails'],t,
    _this: ThisInterface
) => {
    const { primary } = paymentDetails;
    const { showFullAccountNumber } = _this.state;

    const {
        paymentMethod,
        accountName,
        bank,
        cardNumber,
        excludeFromBilling
    } = primary;

    return [
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.PRIMARY_PAYMENT_METHOD'),
            template: checkNullData(paymentMethod, _.get(paymentMethod, 'name'))
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ACCOUNT_NAME'),
            template: checkNullData(accountName, accountName.toUpperCase())
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ISSUING_BANK'),
            template: checkNullData(bank, _.get(bank, 'name'))
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ACCOUNT_NUMBER'),
            template: checkNullData(
                cardNumber,
                !showFullAccountNumber ? displayBankNumber(cardNumber) : cardNumber
            )
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.SHOW_FULL_ACCOUNT_NUMBER'),
            template: (
                <FormItem
                    type="toogle"
                    htmlFor="showFullAccountNumber"
                    fieldName="showFullAccountNumber"
                    data={showFullAccountNumber ? 1 : 0}
                    onChangeHandle={_this.showFullAccountNumberChanged}
                    fields={[]}
                />
            )
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.EXCLUDE_FROM_BILLING'),
            template: excludeFromBilling
                ? t('BUTTON.YES')
                : t('BUTTON.NO')
        }
    ];
};

const creditDisplayItems = (
    paymentDetails: ThisInterface['props']['paymentDetails'], t
) => {
    const { primary } = paymentDetails;
    const {
        paymentMethod,
        accountName,
        bank,
        cardNumber,
        expiryDate,
        cardType,
        cardSubType,
        issuingCountry
    } = primary;

    return [
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.PRIMARY_PAYMENT_METHOD'),
            template: checkNullData(paymentMethod, _.get(paymentMethod, 'name'))
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.CARD_TYPE'),
            template: checkNullData(cardType, _.get(cardType, 'name'))
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.CARD_SUB_TYPE'),
            template: checkNullData(cardSubType)
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.CARDHOLDER_TYPE'),
            template: checkNullData(accountName, accountName.toUpperCase())
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.CARD_NUMBER'),
            template: checkNullData(cardNumber, DisplayCardNumber(cardNumber))
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.EXPIRE_DATE'),
            template: checkNullData(expiryDate)
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ISSUING_BANK'),
            template: checkNullData(bank, _.get(bank, 'name'))
        },
        {
            title: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.ISSUING_COUNTRY'),
            template: checkNullData(issuingCountry)
        }
    ];
};

const Item: React.FC<DisplayItem> = ({ title, template }) => {
    return (
        <div className="row">
            <div className="col-3">
                <p>{title}</p>
            </div>
            <div className="col-4">{template}</div>
        </div>
    );
};

export const renderFields = (_this: ThisInterface) => {
    const { paymentDetails, t  } = _this.props;
    const { primary } = paymentDetails;

    let items: DisplayItem[] = [];
    switch (primary.paymentMethod.name) {
        case PaymentMethods.Debit:
            items = debitDisplayItems(paymentDetails,t, _this).concat(
                commonField(_this.props.paymentDetails, t)
            );
            break;
        case PaymentMethods.Credit:
            items = creditDisplayItems(paymentDetails, t).concat(
                commonField(_this.props.paymentDetails, t)
            );
            break;
        default:
            break;
    }
    return items.map(item => <Item key={item.title} {...item} />);
};
