import Operator from 'models/Operator';
import Pagination from 'models/Pagination';

class OperatorMetadata {
    TotalItemSuccess = 0;
}

export default class OperatorPagination {
    data: Operator[] = [];
    metadata = new OperatorMetadata();
    message = '';
    statusCode = 200;
    pagination = new Pagination();
}
