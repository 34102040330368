import React from 'react';
import ThisInterface from './interface';
import PlanModel, { PackagePeriodsModel, UnitModel, PlanPeriodsModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import OptionModel from 'models/Option';
import observer from 'util/Observer';

import FormItem, { getCodeOrUndefined } from 'components/FormV2';
import Loading from 'components/Loading';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
    packagesPeriods: PackagePeriodsModel[];
    plansPeriods: PlanPeriodsModel[];
    units: OptionModel[];
}
interface Props {
    onChangeHandle: Function;
    packageId: string;
    data: PlanModel;
    fields: FieldModel[];
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true,
            packagesPeriods: [],
            plansPeriods: [],
            units: []
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.packageId !== this.props.packageId) {
            this.getPackagesPeriods(true);
        }
    }

    componentDidMount() {
        const { subTenantId, rightName } = this.props;
        const getUnits = this.props.planService.getUnits(subTenantId, rightName).then((res) => {
            const units: UnitModel[] = res.data;
            this.setState({
                units: units.map(e => ({ value: e.code, label: e.name }))
            });
        });
        const packagesPeriods = this.getPackagesPeriods();
        Promise.all([
            getUnits,
            packagesPeriods
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    getPackagesPeriods(isUpdated = false) {
        const { packageId, subTenantId, rightName, t } = this.props;
        if (packageId !== '') {
            if (isUpdated) {
                this.setState({ isLoading: true });
            }
            return this.props.planService.getPackagesPeriods(packageId, subTenantId, rightName).then((res) => {
                const packagesPeriods: PackagePeriodsModel[] = res.data;

                this.setState({
                    packagesPeriods: packagesPeriods
                });
                observer.publish('plan.packagesPeriods.setData', packagesPeriods);
                if (isUpdated) {
                    this.setState({ isLoading: false });
                }
            }, () => {
                this.setState({
                    packagesPeriods: []
                });
                observer.publish('plan.packagesPeriods.setData', []);
                if (isUpdated) {
                    this.setState({ isLoading: false });
                }
            });
        } else {
            this.setState({
                packagesPeriods: []
            });
            observer.publish('plan.packagesPeriods.setData', []);
            return Promise.resolve();
        }
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { onChangeHandle, data, fields, t } = this.props;

        let planPeriodColumns: {}[] = [];
        let packagePeriodColumns: {}[] = [];

        this.state.packagesPeriods.forEach(row => {
            let overridePlanRow = PackagePeriodsModel.clone(row);
            let overridePackageRow = PackagePeriodsModel.clone(row);

            const planRow = data.planPeriods[row.id];
            if (planRow && planRow.checked) {
                overridePlanRow.value = planRow.value;
                overridePlanRow.unit = UnitModel.clone(planRow.unit);
                overridePlanRow.checked = true;
                overridePackageRow.checked = false;
            } else {
                overridePlanRow.checked = false;
                overridePackageRow.checked = true;
            }
            planPeriodColumns.push({
                col1: [
                    {
                        props: {
                            type: 'checkbox',
                            htmlFor: 'planPeriod[' + row.id + '][planPeriods]',
                            fieldName: 'planPeriod[' + row.id + '][planPeriods]',
                            data: overridePlanRow.id,
                            attribute: {
                                checked: overridePlanRow.checked
                            },
                            fieldLabel: overridePlanRow.name,
                        },
                        className: 'form-check mt-1'
                    }
                ],
                col2: [
                    {
                        props: {
                            type: 'number',
                            data: overridePlanRow.value,
                            attribute: {
                                disabled: !overridePlanRow.checked,
                                min: 0
                            },
                            htmlFor: 'planPeriod[' + row.id + '][monthDayValue]',
                            fieldName: 'planPeriod[' + row.id + '][monthDayValue]'
                        },
                    },
                    {
                        props: {
                            type: 'select',
                            htmlFor: 'planPeriod[' + row.id + '][monthDayType]',
                            fieldName: 'planPeriod[' + row.id + '][monthDayType]',
                            attribute: {
                                disabled: !overridePlanRow.checked,
                                isClearable: false
                            },
                            data: getCodeOrUndefined(overridePlanRow.unit),
                            selectAllLabel: '',
                            options: this.state.units
                        },
                    },
                    {
                        props: {
                            type: 'checkbox',
                            htmlFor: 'planPeriod[' + row.id + '][planUseOffsets]',
                            fieldName: 'planPeriod[' + row.id + '][planUseOffsets]',
                            fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.USE_OFFSETS'),
                            attribute: {
                                disabled: true
                            }
                        },
                        className: 'form-check mt-1'
                    }
                ]
            });

            packagePeriodColumns.push({
                col1: [
                    {
                        props: {
                            type: 'checkbox',
                            htmlFor: 'packagePeriod[' + row.id + '][packagePeriods]',
                            fieldName: 'packagePeriod[' + row.id + '][packagePeriods]',
                            data: overridePackageRow.id,
                            attribute: {
                                checked: overridePackageRow.checked,
                                disabled: true
                            },
                            fieldLabel: overridePackageRow.name,
                        },
                        className: 'form-check mt-1'
                    }
                ],
                col2: [
                    {
                        props: {
                            type: 'number',
                            data: overridePackageRow.value,
                            attribute: {
                                disabled: true
                            },
                            htmlFor: 'packagePeriod[' + row.id + '][packageMonthDayValue]',
                            fieldName: 'packagePeriod[' + row.id + '][packageMonthDayValue]'
                        },
                    },
                    {
                        props: {
                            type: 'select',
                            data: getCodeOrUndefined(overridePackageRow.unit),
                            htmlFor: 'packagePeriod[' + row.id + '][packageMonthDayType]',
                            fieldName: 'packagePeriod[' + row.id + '][packageMonthDayType]',
                            attribute: {
                                disabled: true,
                                isClearable: false
                            },
                            selectAllLabel: '',
                            options: this.state.units
                        },
                    },
                    {
                        props: {
                            type: 'checkbox',
                            htmlFor: 'packagePeriod[' + row.id + '][packageUseOffsets]',
                            fieldName: 'packagePeriod[' + row.id + '][packageUseOffsets]',
                            fieldLabel: t('PAGE.PRODUCTS.PLANS.CLONE.USE_OFFSETS'),
                            attribute: {
                                disabled: true
                            }
                        },
                        className: 'form-check mt-1'
                    }
                ]
            });
        });

        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.CLONE.PERIODS')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    {this.state.isLoading ?
                        <Loading />
                        :
                        <div className="row periods">
                            <div className="plan">
                                <h4 className="title">{t('PAGE.PRODUCTS.PLANS.CLONE.PLAN_PERIODS')}</h4>
                                {planPeriodColumns.length === 0 && t('COMMON.NO_DATA')}
                                {planPeriodColumns.map((row, rowKey) =>
                                    <div key={rowKey} className="period-item">
                                        <div className="period-item-1 d-flex mt-2">
                                            {row['col1'].map((field, key) =>
                                                <div key={key} className={field.className}>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={onChangeHandle}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex mt-2">
                                            {row['col2'].map((field, key) =>
                                                <div key={key} className={field.className}>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={onChangeHandle}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>)}

                            </div>
                            <div className="package">
                                <h4 className="title">{t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.PACKAGE_PERIODS')}</h4>
                                {packagePeriodColumns.length === 0 && t('COMMON.NO_DATA')}
                                {packagePeriodColumns.map((row, rowKey) =>
                                    <div key={rowKey} className="period-item">
                                        <div className="period-item-1 d-flex mt-2">
                                            {row['col1'].map((field, key) =>
                                                <div key={key} className={field.className}>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={onChangeHandle}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex mt-2">
                                            {row['col2'].map((field, key) =>
                                                <div key={key} className={field.className}>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={onChangeHandle}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default Template;