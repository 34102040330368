import Loading from 'components/Loading';
import React from 'react';
import ApiContext from 'util/ApiContext';
import PaymentDetail from './ChangePaymentDetail';
import FreezeMembership from './FreezeMembership';
import RemoveFreeze from './FreezeMembership/RemoveFreeze';
import Unfreeze from './FreezeMembership/Unfreeze';
import ThisInterface from './interface';
import MakePayment from './MakePayment';
import {
    activity,
    contact,
    debtSummary,
    generalInfo,
    memberAccount,
    membership,
    personalDetail
} from './MemberSummarySection';
import ViewPersonalDetail from './ViewPersonalDetail';
import CancelMembership from './CancelMembership';
import ViewMembershipDetail from './ViewMemberShipDetail';
import RemoveChangeMembership from './RemoveChangeMembership';
import MemberAccountDetail from './ViewMemberAccountDetail';
import renderEvents from './MemberSummarySection/RenderEvent';
import MemberContactDetail from './MemberContactDetail';
import MemberActivityDetail from './MemberActivityDetail';

const buttonList = [
    {
        text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.BACK_TO_MEMBERS',
        link: '/memberships/members',
        className: 'btn-dark'
    }
];

const memberSummary = (_this: ThisInterface) => {
    const { t, history } = _this.props;
    const { memberData, isWidthBelow1200 } = _this.state;

    const notOk = memberData.paymentStatus.code !== 'OK';

    return (
        <React.Fragment>
            <div className="new-member-summary row">
                {isWidthBelow1200 && (
                    <div className="summary-wrapper" style={{ width: '100%', marginBottom: notOk ? 'unset' : '5px'}}>
                        <div className="row col-12 member-account-wrapper summary-content">
                            {memberAccount(_this)}
                        </div>
                    </div>
                )}
                <div
                    className="debt-summary-wrapper col-xl-3 col-lg-12"
                    style={{ display: notOk ? 'block' : 'none' }}
                >
                    {debtSummary(_this)}
                </div>
                <div
                    className={`row summary-wrapper col-xl-${
                        notOk ? '9' : '12'
                    } col-lg-12`}
                >
                    {!isWidthBelow1200 && (
                        <div className="row col-12 member-account-wrapper summary-content">
                            {memberAccount(_this)}
                        </div>
                    )}
                    <div className="row col-12 summary-content">
                        <div className="col-xl-7 col-lg-6 membership-detail-wrapper">
                            {membership(_this)}
                        </div>
                        <div className="col-xl-5 col-lg-6 activity-wrapper">{activity(_this)}</div>
                        <div className="col-12">{renderEvents(_this)}</div>
                    </div>
                    <div className="row col-12 summary-content">
                        <div className="col-xl-7 col-lg-6 personal-detail-wrapper">
                            {personalDetail(_this)}
                        </div>
                        <div className="col-xl-5 col-lg-6 activity-wrapper">{contact(_this)}</div>
                    </div>
                </div>
            </div>
            <div className="row-btn">
                <ul className="list-btn-action">
                    {buttonList.map((item, index) => {
                        return (
                            <li key={index}>
                                <a
                                    onClick={() => history.push(item.link)}
                                    className={`btn ${
                                        item.className
                                            ? item.className
                                            : 'btn-primary btn-long-text'
                                    }`}
                                >
                                    {t(item.text)}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </React.Fragment>
    );
};

const section = (_this: ThisInterface) => {
    const { section, memberData, previousSection, dateFormat } = _this.state;
    switch (section) {
        case 'payment-detail':
            return (
                <ApiContext.Consumer>
                    {srvs => (
                        <PaymentDetail
                            memberData={memberData}
                            onCancel={() => _this.handleChangeSection(previousSection)}
                            {...srvs}
                        />
                    )}
                </ApiContext.Consumer>
            );
        case 'personal-detail':
            return (
                <ApiContext.Consumer>
                    {srvs => (
                        <ViewPersonalDetail
                            memberData={memberData}
                            onCancel={() => _this.handleChangeSection('')}
                            onGoToPaymentDetail={() =>
                                _this.handleChangeSection('payment-detail', 'personal-detail')
                            }
                            {...srvs}
                        />
                    )}
                </ApiContext.Consumer>
            );
        case 'membership-detail':
            return (
                <ViewMembershipDetail
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection('')}
                    dateFormat={dateFormat}
                    handleChangeSection={_this.handleChangeSection}
                />
            );
        case 'freeze-membership':
            return (
                <FreezeMembership
                    dateFormat={dateFormat}
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'make-payment':
            return (
                <MakePayment
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'remove-freeze':
            return (
                <RemoveFreeze
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'unfreeze':
            return (
                <Unfreeze
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'cancel-membership':
            return (
                <CancelMembership
                    memberData={memberData}
                    dateFormat={dateFormat}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'remove-change-membership':
            return (
                <RemoveChangeMembership
                    memberData={memberData}
                    dateFormat={dateFormat}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'account-detail':
            return (
                <MemberAccountDetail
                    memberData={memberData}
                    dateFormat={dateFormat}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                    handleChangeSection={_this.handleChangeSection}
                    handleOpenAction={_this.handleOpenAction}
                />
            );
        case 'contact-detail':
            return (
                <MemberContactDetail
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        case 'activity-detail':
            return (
                <MemberActivityDetail
                    memberData={memberData}
                    onCancel={() => _this.handleChangeSection(previousSection)}
                />
            );
        default:
            return memberSummary(_this);
    }
};

const template = (_this: ThisInterface) => {
    const { isLoading } = _this.state;

    return (
        <React.Fragment>
            {isLoading ? (
                <Loading />
            ) : (
                <section className="membersummary-page">
                    <div className="select-add-on-main">
                        <div className="general-info">{generalInfo(_this)}</div>
                        <div className="membersummary-list">{section(_this)}</div>
                    </div>
                </section>
            )}
        </React.Fragment>
    );
};
export default template;
