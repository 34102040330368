import React from 'react';
import ThisInterface from './interface';
import * as Constants from 'constants/Constants';
import Loading from 'components/Loading';
import SelectSubTenant from 'components/SelectSubTenant';
import * as Routes from 'pages/RouteLoader';
import { getIframeAPIURL } from 'util/ControlUtils';

const template = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { isEditMode, isEditSimpleMode, iframeToken, id, subTenantId, iframeLoaded } = _this.state;

    const rightName = !isEditMode ? Routes.AddAddonRoute.rightName : 
        (!isEditSimpleMode ? Routes.EditAddonRoute.rightName : Routes.EditSimpleAddonRoute.rightName)
    return (
        <>
            <div className="package-detail">
                <div className="list-page plan-list-view-page">
                    <div className="list-page-title">
                        <p className="title-content title-content-list">
                            {!isEditMode ? t('PAGE.PRODUCTS.ADDONS.ADD.TITLE') : null}
                            {isEditMode && !isEditSimpleMode ? t('PAGE.PRODUCTS.ADDONS.EDIT.TITLE') : null}
                            {isEditMode && isEditSimpleMode ? t('PAGE.PRODUCTS.ADDONS.QUICK.EDIT.TITLE') : null}
                        </p>
                    </div>
                    <div className="box-btn-filter ml-auto d-flex">
                        <span className="plan-hint">
                            {!isEditMode ? t('PAGE.PRODUCTS.ADDONS.ADD.INSTRUCTION') : null}
                            {isEditMode ? t('PAGE.PRODUCTS.ADDONS.EDIT.INSTRUCTION') : null}
                        </span>
                    </div>
                </div>
            </div>
            {!subTenantId && <SelectSubTenant 
                rightName={rightName}
                conmanService={_this.props.conmanService}
                onNext={_this.handleNext}
            />}
            {subTenantId && <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && !isEditMode && <iframe src={`${getIframeAPIURL(Constants.IFRAME_ADDON_ADD, subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => _this.resizeIframe(_this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
                        {iframeToken && isEditMode && !isEditSimpleMode && <iframe src={`${getIframeAPIURL(Constants.IFRAME_ADDON_EDIT.replace('{0}', id ? id : ''), subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => _this.resizeIframe(_this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
                        {iframeToken && isEditMode && isEditSimpleMode && <iframe src={`${getIframeAPIURL(Constants.IFRAME_ADDON_EDIT_SIMPLE.replace('{0}', id ? id : ''), subTenantId, '/Configuration')}?token=${iframeToken}`} ref='iframe' onLoad={() => _this.resizeIframe(_this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
                    </div>
                </div>
            </div>}
        </>
    );
};

export default template;
