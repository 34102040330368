import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { submitModal } from 'components/CommonModal';
import MemberDetailSummary from 'models/MemberSummary';
import CommonService from 'services/common.service';
import * as Route from 'pages/RouteLoader';
import {
    API_REMOVE_CHANGE_MBS,
    GENERAL_DATABASE_DATE_FORMAT
} from 'constants/Constants';
import * as DefaultConstants from 'constants/DefaultConstants';
import { DisplayDateFormat } from 'util/ControlUtils';

import template from './template';
import ThisInterface from './interface';
import Swal from 'sweetalert2';

class RemoveChangeMembership extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    commonService: CommonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {
            openRemoveInfo: true,
            openPaymentInfo: true,
            paymentRemoveChangeMbs: []
        };
        this.commonService = new CommonService();
    }

    componentDidMount() {
        this.getPaymentRemoveChangeMBS();
    }

    getPaymentRemoveChangeMBS = () => {
        const {
            memberData: { futureEvents, memberPlanFees }
        } = this.props;
        const removeChangeMbs = futureEvents.find(item => item.type === 'CH');
        if (removeChangeMbs) {
            const paymentFeeFor6Months: MemberDetailSummary['memberPlanFees'] = [];
            const memberFees = memberPlanFees.filter(item =>
                item.feeName.includes('Recurring')
            );
            if (!_.isEmpty(memberFees)) {
                for (let i = 1; i < 6; i++) {
                    const effectiveDate = new Date(memberFees[0].effectiveDate);
                    effectiveDate.setMonth(effectiveDate.getMonth() + i);
                    paymentFeeFor6Months.push({
                        ...memberFees[0],
                        effectiveDate: DisplayDateFormat(
                            effectiveDate,
                            GENERAL_DATABASE_DATE_FORMAT
                        )
                    });
                }
            }
            this.setState({
                paymentRemoveChangeMbs: memberFees
                    .concat(paymentFeeFor6Months)
                    .sort((a, b) => a.effectiveDate.localeCompare(b.effectiveDate))
            });
        }
    };

    handleOpenCollapseBtn = (key: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                [`open${key}`]: !prevState[`open${key}`]
            };
        });
    };

    handleRemoveChangeMbs = () => {
        const {
            memberData: { subTenantId, futureEvents },
            onCancel,
            t
        } = this.props;
        const removeChangeMbs = futureEvents.find(item => item.type === 'CH');
        if (removeChangeMbs) {
            const url = API_REMOVE_CHANGE_MBS.replace(
                '{memberId}',
                removeChangeMbs.id
            );
            submitModal('PAGE.REMOVE_CHANGE_MBS.MSG.CONFIM_REMOVE', () => {
                this.commonService
                    .deleteData(url, {
                        subTenantId,
                        rightName: Route.RemoveChangeMembershipRoute.rightName
                    })
                    .then(res => {
                        if (res) {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('PAGE.REMOVE_CHANGE_MBS.MSG.REMOVE_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => onCancel());
                        }
                    });
            });
        }
    };

    render() {
        return template(this);
    }
}

export default withRouter(withTranslation()(RemoveChangeMembership));
