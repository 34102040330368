import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import IntlTelInput from 'react-intl-tel-input';
import * as Interface from 'interfaces';
import { isValidNumber, IsValidPhoneNumberWithCountryCode } from 'util/ControlUtils';
import 'react-intl-tel-input/dist/main.css';

interface Props extends Interface.InputInterface {
    countryCode: string;
    onPhoneNumberChange: (phoneNumber: string, dialCode: string, isValid: boolean) => void;
    phoneType?: string;
    requireMsg?: string;
}

interface PhoneNumberDetail {
    areaCodes: React.ReactNode;
    dialCode: string;
    iso2: string;
    name: string;
    priority: number;
}

const FormPhoneInput: React.FunctionComponent<Props> = ({
    label,
    labelClass = '',
    id,
    require,
    inputClass = '',
    className = '',
    phoneType = 'MOBILE',
    value = '',
    countryCode = '',
    onPhoneNumberChange,
    errorMsg,
    requireMsg
}) => {    
    const [phoneNumber, setPhoneNumber] = useState(value);
    const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode.toLowerCase());
    const [phoneValid, setPhoneValid] = useState(true);
    const [placeholder, setPlaceholder] = useState('');   

    function changePhoneNumber(
        isValidate: boolean,
        phoneNum: string,
        selectedCountry: PhoneNumberDetail
    ) {
        if(!IsValidPhoneNumberWithCountryCode(phoneNum)) return;
        
        const { iso2 } = selectedCountry;
        const isPhoneNumberValid = phoneNum ? isValidNumber(phoneNum, iso2) : true;
        setPhoneNumber(phoneNum);
        setSelectedCountryCode(iso2);
        setPhoneValid(isPhoneNumberValid);
        if (value !== phoneNum) {
            onPhoneNumberChange(phoneNum, iso2.toUpperCase(), isPhoneNumberValid);
        }   
    }
    
    function customPlaceholder(selectedCountryPlaceholder: string) {
        setPlaceholder(`e.g: ${selectedCountryPlaceholder}`)
    }

    return (
        <FormGroup className={`form-group ${className} phone-input`}>
            {label && label !== '' ? (
                <Label className={labelClass} for={id}>
                    {label}
                    {require && <span className="text-primary bold">&nbsp;*</span>}
                </Label>
            ) : null}
            <IntlTelInput
                inputClassName={`form-control ${inputClass}`}
                separateDialCode
                numberType={phoneType}
                value={phoneNumber}                
                onPhoneNumberChange={changePhoneNumber}                              
                preferredCountries={['hk', 'th', 'my', 'sg', 'id', 'ph']}
                defaultCountry={selectedCountryCode}
                customPlaceholder={customPlaceholder}  
                placeholder={placeholder}                
            />
            <div className="text-danger input-error">
                {require && !phoneNumber && requireMsg ? requireMsg : !phoneValid && errorMsg ? errorMsg : ''}
            </div>
        </FormGroup>
    );
};

FormPhoneInput.displayName = 'FormPhoneInput';

export default FormPhoneInput;
