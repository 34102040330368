import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';

import { history, configureStore } from 'reduxs/index';
import i18n from 'i18next';

import initAxios from 'util/Interceptor';
import userManager from 'util/IdentityOidc';
import ApiContext, { services } from 'util/ApiContext';

import Layout from 'pages/Layout';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import * as Constants from 'constants/Constants';
import CurrentI18n from 'util/I18nMessages';

const store = configureStore();
initAxios();

declare var document: any;

class App extends Component<{}, {}> {
    intervalHandle;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const iframe = document.getElementById('iframe-id');
        if (iframe) {
            iframe.setAttribute(
                'src',
                `${Constants.IDENTITY_SERVER_ENDPOINT_URL}/Account/Login`
            );
            this.intervalHandle = setInterval(() => {
                iframe.contentWindow.postMessage(
                    {
                        action: 'get',
                        key: 'currentLanguage'
                    },
                    '*'
                );
            }, 100);
        }
        window.addEventListener('message', this.messageHandler, false);
    }

    messageHandler = event => {
        const { action, value } = event.data;
        if (action == 'returnData') {
            clearInterval(this.intervalHandle);
            i18n.changeLanguage(
                value === 'en' ? 'en-US' : value === 'cn' ? 'zh-CN' : value
            );
        }
    }

    handleChangeLanguage = language => {
        const iframe = document.getElementById('iframe-id');
        if (iframe) {
            let langCode = 'en';
            if (language.locale && language.locale !== '') {
                langCode = language.locale.split('-')[0];
            }
            if (langCode === 'zh') {
                langCode = 'cn';
            }
            iframe.contentWindow.postMessage(
                {
                    action: 'save',
                    key: 'currentLanguage',
                    value: langCode
                },
                '*'
            );
        }
        i18n.changeLanguage(language.locale);
    };

    render = () => {
        const {
            location: { pathname }
        } = history;

        return pathname === '/forgot-password' ||
      		pathname === '/change-forgot-password' ? (
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <ApiContext.Provider value={services}>
                            <React.Suspense fallback={null}>
                                <CurrentI18n />
                                <Switch>
                                    <Route
                                        path="/forgot-password"
                                        render={props => {
                                            return (
                                                <ForgotPasswordPage
                                                    {...props}
                                                    {...services}
                                                    switchLanguage={this.handleChangeLanguage}
                                                />
                                            );
                                        }}
                                    />
                                    <Route
                                        path="/change-forgot-password"
                                        render={props => {
                                            return (
                                                <ForgotPasswordPage
                                                    {...props}
                                                    {...services}
                                                    switchLanguage={this.handleChangeLanguage}
                                                />
                                            );
                                        }}
                                    />
                                </Switch>
                            </React.Suspense>
                        </ApiContext.Provider>
                    </ConnectedRouter>
                </Provider>
            ) : (
                <Provider store={store}>
                    <OidcProvider userManager={userManager} store={store}>
                        <ConnectedRouter history={history}>
                            <ApiContext.Provider value={services}>
                                <React.Suspense fallback={null}>
                                    <CurrentI18n />
                                    <Switch>
                                        <Route
                                            path="/"
                                            render={props => {
                                                return <Layout {...props} {...services} />;
                                            }}
                                        />
                                    </Switch>
                                </React.Suspense>
                            </ApiContext.Provider>
                        </ConnectedRouter>
                    </OidcProvider>
                </Provider>
            );
    };
}

export default App;
