import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import ThisInterface from './interface';
import { withTranslation, WithTranslation } from 'react-i18next';

class LookupListPage extends React.Component<
ThisInterface['props'] & WithTranslation & RouteComponentProps,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation & RouteComponentProps) {
        super(props);
        this.state = {
            tableHeight: window.innerHeight - 320,
            version: 0
        };
    }

    componentDidMount() {
        window.onresize = this.resize;
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 320
        });
    };

    handleActionEvent = (actionMap: string, value: string, rowData) => {
        switch (actionMap) {
            case 'edit':
                this.props.history.push(`/system/lookups/${value}/edit`, {
                    subTenantId: rowData && rowData.subTenant ? rowData.subTenant.id : ''
                });
                break;
            default:
                break;
        }
        this.setState(state => ({ version: state.version + 1 }));
    };

    handleMoveToAddLookup = () => {
        const { history } = this.props;
        history.push('/system/lookups/create');
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(LookupListPage)));
