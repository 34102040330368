import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import { MatchParams, QuickClonePlanInterface } from 'pages/Products/Plan/Template/interface';
import PlanModel, { PlanPeriodsModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import * as Route from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import { DisplayDateFormat, interpret, getSubTenantId } from 'util/ControlUtils';
import observer from 'util/Observer';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import { matchField, matchFieldNested, getRawValue, isValidOrNot, updateFieldValid, forceAbs } from 'components/FormV2';

class PlanQuickClonePage extends React.Component<
QuickClonePlanInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation,
QuickClonePlanInterface['state']
> {
    constructor(props: QuickClonePlanInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation) {
        super(props);
        
        // Get subTenantId & rightName
        const { location: { state } } = props;
        let subTenantId = getSubTenantId();
        if (state && state.subTenantId) {
            subTenantId = state.subTenantId;
        }
        const rightName = RouteLoader.PlanQuickCloneRoute.rightName;

        this.state = {
            packageId: '',
            planDetail: new PlanModel(),
            packagesPeriods: [],
            fields: [],
            isLoading: true,
            disabled: true,
            packagePeriodDefaultId: '6fc35c56-29eb-e911-b5e9-000d3aa097ea',
            subTenantId: subTenantId,
            rightName: rightName
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.packagesPeriods !== this.state.packagesPeriods) {
            let existPlanPeriod = false;
            this.state.packagesPeriods.forEach(e => {
                if (this.state.planDetail && this.state.planDetail.planPeriods[e.id]) {
                    existPlanPeriod = true;
                }
            })
            if (this.state.planDetail && !existPlanPeriod) {
                let planPeriods: PlanPeriodsModel[] = [];
                this.state.packagesPeriods.forEach(e => {
                    if (e.id === this.state.packagePeriodDefaultId) {
                        planPeriods[e.id] = PlanPeriodsModel.clone(e);
                        planPeriods[e.id].checked = true;
                    }
                })
                let { planDetail } = this.state;
                planDetail.planPeriods = planPeriods;
                this.setState({ planDetail: planDetail });
            }
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const { subTenantId, rightName } = this.state;
        const getPlanData = this.props.planService.getPlanData(id, subTenantId, rightName).then((res) => {
            let planDetail: PlanModel = res;
            let planPeriods: PlanPeriodsModel[] = [];
            planDetail.planPeriods.forEach(e => {
                if (e.id === this.state.packagePeriodDefaultId) {
                    planPeriods[e.id] = PlanPeriodsModel.clone(e);
                    planPeriods[e.id].checked = true;
                }
            });
            planDetail.planPeriods = planPeriods;
            this.setState({
                planDetail: planDetail,
                packageId: planDetail.package ? planDetail.package.id : ''
            });
            observer.publish('plan.clubGroups.changed', true);
        }, () => {
            this.props.history.push(Route.ROUTE_PLAN_LIST);
        });

        const getFields = this.props.planService.getFields(subTenantId, rightName).then((res) => {
            let fields: FieldModel[] = res.data;
            fields = fields.map(e => ({
                ...new FieldModel(), ...e,
                isValid: e.key.indexOf('comment') > -1 ? false : true,
                isValidType: e.key.indexOf('comment') > -1 ? 'required' : 'none'
            }));
            this.setState({
                fields: fields
            });
        });


        Promise.all([
            getPlanData,
            getFields
        ]).then(() => {
            this.setState({ isLoading: false });
        });


        observer.subscribe('plan.packagesPeriods.setData', (data) => {
            if (data) {
                this.setState({ packagesPeriods: data });

                const fields = this.state.fields.map(field => {
                    if (field.key.indexOf('monthDayValue') > -1) {
                        field.nested = data.map(e => {
                            let nestedField = FieldModel.clone(field);
                            nestedField.key = e.id;
                            return nestedField;
                        });
                    }
                    return field;
                });
                this.setState({
                    fields: fields
                });
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('plan.packagesPeriods.setData');
    }

    onCancel() {
        this.props.history.push(Route.ROUTE_PLAN_LIST);
    }

    onSubmit() {
        const { subTenantId, rightName } = this.state;
        const { t } = this.props;
        const isValid = this.checkValidations();
        if (isValid) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.PRODUCTS.PLAN.CONFIRM_SAVE_PLAN'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO'),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false
                    })
                    const { planDetail, packagesPeriods } = this.state;
                    let planPeriods: PlanPeriodsModel[] = [];
                    packagesPeriods.forEach(e => {
                        if (planDetail.planPeriods && planDetail.planPeriods[e.id] && planDetail.planPeriods[e.id].checked) {
                            planPeriods.push(planDetail.planPeriods[e.id]);
                        }
                    });
                    this.props.planService.postQuickCloneData(planDetail.id, PlanModel.buildQuickClonePostData(planDetail, planPeriods, subTenantId, rightName)).then(() => {
                        observer.publish('flagUnsavedChangeEvent', false);
                        this.setState({
                            disabled: true
                        }, () => {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('MSG.SAVE_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => {
                                this.props.history.push(Route.ROUTE_PLAN_LIST);
                            })
                        });
                    });
                }
            });
        }
    }

    checkValidations() {
        let isValid = true;
        const fields = this.state.fields.map(e => {
            e.isDirty = true;
            if (e.nested) {
                e.nested = e.nested.map(fieldNested => {
                    fieldNested.isDirty = true;
                    if (isValidOrNot(fieldNested.isValid, fieldNested.isRequired, fieldNested.regex, fieldNested.customMsg)) {
                        isValid = false;
                    }
                    return fieldNested;
                })
            }
            if (isValidOrNot(e.isValid, e.isRequired, e.regex, e.customMsg)) {
                isValid = false;
            }
            return e;
        });
        this.setState({
            fields: fields
        }, () => {
            if (!isValid) {
                const element: HTMLElement | null = document.querySelector('.error-holder input, .error-holder textarea, .error-holder select, .error-holder .icon-checked-ico:before, .error-holder .select__control');
                if (element) {
                    element.focus();
                }
            }
        });
        return isValid;
    }

    checkValidation(nameHtml, valueHtml, nestedNameData) {
        const fields = this.state.fields.map(e => {
            const extractNameValidation = e.key.split('.');
            const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
            if (matchField(nameHtml, shortNameValidation)) {
                if (e.nested) {
                    e.nested = e.nested.map(fieldNested => {
                        if (matchFieldNested(nameHtml, fieldNested.key)) {
                            const rawValue = getRawValue(valueHtml, fieldNested.key, nestedNameData);
                            fieldNested = updateFieldValid(fieldNested, rawValue);
                        }
                        return fieldNested;
                    })
                } else {
                    const rawValue = getRawValue(valueHtml, null, null);
                    e = updateFieldValid(e, rawValue);
                }
            }
            return e;
        });
        this.setState({
            fields: fields
        })
    }

    setPlanDetailStateByProperty(nameData, nameHtml, valueHtml, nestedNameData = '') {
        this.setState(prevState => ({
            planDetail: {
                ...prevState.planDetail, [nameData]: valueHtml
            }
        }), () => this.checkValidation(nameHtml, valueHtml, nestedNameData));
    }

    onChangeHandle(nameHtml, valueHtml) {
        let { planDetail } = this.state;
        const { t } = this.props;
        let fields: FieldModel[] = [];
        let planPeriods: PlanPeriodsModel[] = [];
        switch (nameHtml) {
            case 'basicDetail[name]':
                this.setPlanDetailStateByProperty('name', nameHtml, valueHtml);
                break;
            case 'basicDetail[class]':
                this.setPlanDetailStateByProperty('class', nameHtml, { id: valueHtml, name: '' });
                break;
            case 'basicDetail[allowTransNonMember]':
                this.setPlanDetailStateByProperty('isAllMemberToNonMemberTransfer', nameHtml, !planDetail.isAllMemberToNonMemberTransfer);
                break;
            case 'availability[availableFrom]':
                let availableFrom = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    availableFrom = DisplayDateFormat(valueHtml, Route.GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00';
                    if (isNaN(Date.parse(availableFrom))) {
                        availableFrom = '';
                    }
                }
                this.setPlanDetailStateByProperty('availableFrom', nameHtml, availableFrom);
                /* Custom validation of date from & date to */
                fields = this.state.fields.map(e => {
                    const extractNameValidation = e.key.split('.');
                    const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
                    if (matchField(nameHtml, shortNameValidation)) {
                        if (availableFrom > planDetail.availableTo) {
                            e.customMsg = 'PAGE.PRODUCTS.PLANS.EDIT.ERR_DATE_FROM_LESS_THAN_DATE_TO';
                        } else {
                            e.customMsg = '';
                        }
                    }
                    if (matchField('availability[availableTo]', shortNameValidation)) {
                        e.customMsg = '';
                    }
                    return e;
                });
                this.setState({
                    fields: fields
                })
                break;
            case 'availability[availableTo]':
                let availableTo = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    availableTo = DisplayDateFormat(valueHtml, Route.GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00';
                    if (isNaN(Date.parse(availableTo))) {
                        availableTo = '';
                    }
                }
                this.setPlanDetailStateByProperty('availableTo', nameHtml, availableTo);
                /* Custom validation of date from & date to */
                fields = this.state.fields.map(e => {
                    const extractNameValidation = e.key.split('.');
                    const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
                    if (matchField(nameHtml, shortNameValidation)) {
                        if (planDetail.availableFrom > availableTo) {
                            e.customMsg = 'PAGE.PRODUCTS.PLANS.EDIT.ERR_DATE_TO_GREATER_THAN_DATE_FROM';
                        } else {
                            e.customMsg = '';
                        }
                    }
                    if (matchField('availability[availableFrom]', shortNameValidation)) {
                        e.customMsg = '';
                    }
                    return e;
                });
                this.setState({
                    fields: fields
                })
                break;
            case 'availability[availableSoldTelesale]':
                this.setPlanDetailStateByProperty('isAvailableForTeleSales', nameHtml, !planDetail.isAvailableForTeleSales);
                break;
            case 'availability[availableSoldClub]':
                this.setPlanDetailStateByProperty('isAvailableForClub', nameHtml, !planDetail.isAvailableForClub);
                break;
            case 'availability[clubGroups]':
                const clubGroups = valueHtml.map(e => ({ id: e }));
                this.setPlanDetailStateByProperty('clubGroups', nameHtml, clubGroups);
                observer.publish('plan.clubGroups.changed', true);
                break;
            case 'payment[comment]':
                this.setPlanDetailStateByProperty('comment', nameHtml, valueHtml);
                break;
            default:
                const extractNameHTML = interpret(nameHtml);
                if (extractNameHTML) {
                    if (extractNameHTML['name'] === 'planPeriod' &&
                        extractNameHTML['indices'] &&
                        extractNameHTML['indices'].length === 2) { // array of planPeriod field
                        const extractName = extractNameHTML['indices'][1];
                        const extractIndex = extractNameHTML['indices'][0];

                        if (planDetail.planPeriods) {
                            planPeriods = planDetail.planPeriods;
                        }
                        if (!planPeriods[extractIndex]) {
                            const packagesPeriods = this.state.packagesPeriods.find(e => e.id === extractIndex);
                            if (packagesPeriods) {
                                planPeriods[extractIndex] = PlanPeriodsModel.clone(packagesPeriods);
                            } else {
                                planPeriods[extractIndex] = new PlanPeriodsModel();
                                planPeriods[extractIndex].id = extractIndex;
                            }
                        }
                        if (extractName === 'monthDayType') {
                            planPeriods[extractIndex].unit.code = valueHtml;
                            this.setPlanDetailStateByProperty('planPeriods', nameHtml, planPeriods, 'unit');
                        }
                        if (extractName === 'monthDayValue') {
                            planPeriods[extractIndex].value = forceAbs(valueHtml);
                            this.setPlanDetailStateByProperty('planPeriods', nameHtml, planPeriods, 'value');
                        }
                        if (extractName === 'planPeriods') {
                            planPeriods[extractIndex].checked = valueHtml;
                            const fields = this.state.fields.map(f => {
                                if (f.key.indexOf('monthDayValue') > -1 && f.nested) {
                                    f.nested = f.nested.map(fn => {
                                        if (fn.key === extractIndex) {
                                            fn.isRequired = valueHtml;
                                        }
                                        return fn;
                                    })
                                }
                                return f;
                            });
                            this.setState({ fields: fields });
                            this.setPlanDetailStateByProperty('planPeriods', nameHtml, planPeriods, 'checked');
                        }

                    }
                }
                break;
        }
        this.setState({
            disabled: false
        });
        observer.publish('flagUnsavedChangeEvent', true);
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(PlanQuickClonePage)));
