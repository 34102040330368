import React from 'react';
import * as Interface from 'interfaces';
import * as DefaultConstants from 'constants/DefaultConstants';
import Loading from 'components/Loading';
import Swal from 'sweetalert2';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props {
    conmanService: Interface.PagePropsInterface['conmanService'];
    rightName: string;
    onNext: Function;
}

interface State {
    isLoading: boolean;
    subTenants: any;
    selectedSubTenantId: string;
}

class SelectSubTenant extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            isLoading: true,
            subTenants: [],
            selectedSubTenantId: ""
        }
    }

    componentDidMount(){
        const { conmanService, rightName }  = this.props;    
            
        conmanService
            .getIframeSubTenant(rightName)
            .then(res => this.initSubTenantData(res.data));          
    }

    initSubTenantData = (operatorSubTenants: any) => {

        if (operatorSubTenants.length == 1) {
            this.props.onNext(operatorSubTenants[0]);
        }
        else {
            operatorSubTenants.sort((t1, t2) => {
                var name1 = t1.name.toUpperCase();
                var name2 = t2.name.toUpperCase();
                if (name1 < name2) {
                    return -1;
                }
                if (name1 > name2) {
                    return 1;
                }
                return 0;
            });

            this.setState({
                isLoading: false,
                subTenants: operatorSubTenants
            });
        }
    }

    onSubTenantSelected = (subtenantId: string) => {
        this.setState({
            selectedSubTenantId: subtenantId
        })
    }

    handleNext = () => {
        const { t } = this.props;
        if (this.state.selectedSubTenantId === "") {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                type: 'error',
                html: t('COMMON.ERR_PLEASE_SELECT_SUB_TENANT'),
                confirmButtonText: t('BUTTON.CLOSE')
            });
        }
        else {
            const { subTenants, selectedSubTenantId } = this.state;
            let subtenant = subTenants.find(t => t.id == selectedSubTenantId);
            this.props.onNext(subtenant);
        }
    }

    render() {
        const { isLoading, subTenants, selectedSubTenantId } = this.state;
        const { t } = this.props;
        const tenantList = subTenants.map((t, index) => (
            <div key={index} className="col-6 col-md-4">
                <button className={`btn btn-default ${selectedSubTenantId == t.id ? "active" : ""}`}
                    onClick={() => this.onSubTenantSelected(t.id)}>
                    {t.name}
                </button>
            </div>
        ))

        return (
            tenantList.length < 2 ?
                <Loading />
                :
                <div className="main-center d-flex justify-content-center select-subtenant mt-3">
                    <section id="sub-tenant">
                        <div className="sub-tenant">
                            <div className="title-sub-tenant">
                                <h3>{t('COMMON.SELECT_SUB_TENANT')}</h3>
                            </div>
                            <div className="main-sub-tenant">
                                <div className="intro-sub-tenant">
                                    <p className="title">{t('COMMON.PLEASE_SELECT_SUB_TENANT')}</p>
                                </div>
                                <div className="box-btn-select">
                                    <div className="row">
                                        {isLoading ? <Loading /> : tenantList}
                                    </div>
                                </div>
                                <div className="box-btn-confirm">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <button className="btn btn-primary" onClick={this.handleNext}>
                                                {t('BUTTON.NEXT')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        )
    }
}

export default withTranslation()(SelectSubTenant);