import React from 'react';
import { FormInput, FormPhoneInput } from 'components/Form';
import { EmergencyContactModel, EmergencyContactFieldsModel } from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';


interface Props  {    
    errors: any;    
    emergencyContact: EmergencyContactModel;
    emergencyContactFieldsConfigs: EmergencyContactFieldsModel;    
    onInputChange: (key: string, value: string) => void;                
    onPhoneNumberChange: (key: string, isValid: boolean, nationalNumber: string, regionCode: string) => void;
}

const EmergencyContact = ({
    errors,    
    emergencyContact,
    emergencyContactFieldsConfigs,  
    onInputChange,   
    onPhoneNumberChange,
}: Props) => {
    let {
        contactName,
        contactNumber
    } = emergencyContact;

    let {
        contactNameConfig,
        contactNumberConfig
    } = emergencyContactFieldsConfigs

    const { t } = useTranslation();

    return (
        <div className="row">
            <div className="col-md-6">
                {contactNameConfig.isVisible &&      
                    <FormInput                            
                        id="contactName"
                        inputClass="form-control"
                        value={contactName}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMERGENCY_CONTACT_NAME')}                                                    
                        maxlength={contactNameConfig.maxLength}
                        require = {contactNameConfig.isRequired}
                        isValid={contactNameConfig.isRequired && contactName.length > 0}
                        validMsg={t(errors.contactName)}
                        onChange={(event) => onInputChange("contactName", event!.target.value)} />
                }  
            </div>
            <div className="col-md-6">                
                {contactNumberConfig.isVisible &&      
                    <>
                        <FormPhoneInput                        
                            inputClass="form-control"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMERGENCY_CONTACT_PHONE_NUMBER')}
                            require={contactNumberConfig.isRequired}
                            value={contactNumber.phoneNumber}
                            countryCode={contactNumber.countryCode}
                            onPhoneNumberChange={(phoneNum, regionCode, isValid) => onPhoneNumberChange('emergencyPhone', isValid, phoneNum, regionCode)}                            
                        />                        
                        {errors.emergencyPhone && <span className="phone-require text-danger input-error">{t(errors.emergencyPhone)}</span>}
                    </>           
                }
            </div>
        </div>     
    )
}

export default EmergencyContact;