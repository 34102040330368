import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import conman from './Conman';
import Auth from './Auth';
import NewMembership from './NewMembership'

export default (history, oidcReducer) => combineReducers({
    router: connectRouter(history),
    oidc: oidcReducer,
    auth: Auth,
    conman,
    newMembership: NewMembership
});
