export default class ChangePaymentDetailModel {
    memberId = '';
    primary = {
        paymentMethod: {
            id: '',
            name: ''
        },
        cardType: {
            id: '',
            name: ''
        },
        bank: {
            id: '',
            name: ''
        },
        accountName: '',
        cardNumber: '',
        issueNumber: '',
        nationalId: '',
        otherNationalId: '',
        expiryDate: '',
        excludeFromBilling: false,
        billingReference: '',
        lastUpdatedDate: '',
        cardSubType: '',
        issuingCountry: '',
        order: '',
        id: ''
    };
    secondary = {
        paymentMethod: {
            id: '',
            name: ''
        },
        cardType: '',
        bank: '',
        accountName: '',
        cardNumber: '',
        issueNumber: '',
        nationalId: '',
        otherNationalId: '',
        expiryDate: '',
        excludeFromBilling: false,
        billingReference: '',
        lastUpdatedDate: '',
        cardSubType: '',
        issuingCountry: '',
        order: '',
        id: ''
    };
}
