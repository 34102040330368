import * as types from 'constants/ActionTypes';

const newMembership = {
    selectedSubTenant: null,
    membershipSearchFilter: null,   // Step 1
    membershipConfiguration: null,  // Step 1
    selectedPackagePlan: null,      // Step 1
    selectedAddons: [],             // Step 2
    selectedPromotions: [],         // Step 2
    personalDetails: {},            // Step 3   
    paymentDetails: null,           // Step 4
    membershipReview: null,          // Step 5
    packagePlans: [],
    memberSummary: null        
};

const newMembershipReducer = (state = newMembership, action) => {
    switch (action.type) {
        case types.ADD_MEMBER_RESET_STATE:
            return newMembership;            
        case types.ADD_MEMBER_SET_SELECTED_SUBTENANT:
            return {
                ...state,
                selectedSubTenant: action.subtenant
            }
        case types.ADD_MEMBER_SET_SEARCH_FILTER:        
            return {
                ...state,
                membershipSearchFilter: action.membershipSearchFilter
            };
        case types.ADD_MEMBER_SET_CONFIGURATION:        
            return {
                ...state,
                membershipConfiguration: action.membershipConfiguration
            };
        case types.ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN:       
            return {
                ...state,
                selectedPackagePlan: action.packagePlan
            };
        case types.ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS:       
            return {
                ...state,                
                selectedPromotions: action.selectedPromotions,
                selectedAddons: action.selectedAddons
            };
        case types.ADD_MEMBER_SET_PERSONAL_DETAILS:
            return {
                ...state,
                personalDetails: {
                    ...state.personalDetails,
                    ...action.data
                }
            };
        case types.ADD_MEMBER_SET_PAYMENT_DETAILS:
            return {
                ...state,
                paymentDetails: action.paymentDetails
            };
        case types.SET_ALL_PACKAGE_PLAN:
            return {
                ...state,
                packagePlans: action.packagePlans
            };
        case types.SET_MEMBER_SUMMARY:
            return {
                ...state,
                memberSummary: action.memberSummary
            };
        default:
            return state;
    }
};
  
export default newMembershipReducer;