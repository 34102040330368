// Customizer const
export const SWITCH_LANGUAGE = 'switch-language';

// Conman
export const SET_OPERATOR = 'SET_OPERATOR';

// Auth const
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';

export const SET_TENANT_LOGO = 'SET_TENANT_LOGO';
export const SET_PROFILE_AUTH = 'SET_PROFILE_AUTH';

export const SET_USER_ROLES = 'SET_USER_ROLES';

//Add new member		
export const ADD_MEMBER_SET_SELECTED_SUBTENANT = 'SET_SELECTED_SUBTENANT';
export const ADD_MEMBER_RESET_STATE = "RESET_STATE";

// Step 1
export const ADD_MEMBER_SET_SEARCH_FILTER = 'SET_SEARCH_FILTER';
export const ADD_MEMBER_SET_CONFIGURATION = 'SET_CONFIGURATION';
export const ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN = 'SET_SELECTED_PACKAGE_PLAN';
export const ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS = 'SELECTED_PRODUCT_EXTRAS';
export const ADD_MEMBER_SET_PAYMENT_DETAILS = 'ADD_MEMBER_SET_PAYMENT_DETAILS';

//Step 3
export const ADD_MEMBER_SET_PERSONAL_DETAILS = 'ADD_MEMBER_SET_PERSONAL_DETAILS';
export const SET_ALL_PACKAGE_PLAN = 'SET_ALL_PACKAGE_PLAN';
export const SET_MEMBER_SUMMARY = 'SET_MEMBER_SUMMARY';