import React from 'react';
import { ViewDetailPlanInterface } from 'pages/Products/Plan/Template/interface';
import ReadonlyBasic from 'pages/Products/Plan/Template/ReadonlyBasic';
import ReadonlyOption from 'pages/Products/Plan/Template/ReadonlyOption';
import ReadonlyRelatedFee from 'pages/Products/Plan/Template/ReadonlyRelatedFee';
import ReadonlyPlanPeriod from 'pages/Products/Plan/Template/ReadonlyPlanPeriod';
import ReadonlyPriceAvailability from 'pages/Products/Plan/Template/ReadonlyPriceAvailability';

const template = (_this: ViewDetailPlanInterface) => {
    const { t } = _this.props;
    return (
        <section>
            <div className="plan-edit">
                <div className="title-content mb-0">{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.TITLE')} </div>
                <div className="box-btn-filter ml-auto d-flex mb-3">
                    <span className="plan-hint">{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.INSTRUCTION')}</span>
                </div>
                <div className="member">
                    <div className="member-main member-main--custom">
                        <div className="content">
                            <ReadonlyBasic 
                                data={_this.state.planDetail} 
                                {..._this.props} 
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <ReadonlyOption 
                                data={_this.state.planDetail} 
                                {..._this.props} 
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <ReadonlyRelatedFee 
                                data={_this.state.planDetail} 
                                {..._this.props} 
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <ReadonlyPlanPeriod 
                                data={_this.state.planDetail} 
                                packageId={_this.state.packageId} 
                                {..._this.props} 
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <ReadonlyPriceAvailability 
                                data={_this.state.planDetail} 
                                {..._this.props} 
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <div className="box-btn justify-content-start no-padding-left"> 
                                <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.BACK')} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
