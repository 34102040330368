import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import template from './template';
import ThisInteface from './interface';

class ProductPage extends React.Component<
ThisInteface['props'],
ThisInteface['state']
> {
    constructor(props: ThisInteface['props']) {
        super(props);
        this.state = {
            tableHeight: window.innerHeight - 300,
            version: 0
        };
    }

    componentDidMount() {
        window.onresize = this.resize;
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 300
        });
    };

    handleActionEvent = (actionMap: string, value: string) => {
        console.log(actionMap, value);
        this.setState(state => ({ version: state.version + 1 }));
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(ProductPage));
