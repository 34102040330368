import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { history } from 'reduxs/index';

import template from './template';
import ThisInterface from './interface';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isHasRight } from 'util/ControlUtils';
import * as RouteLoader from 'pages/RouteLoader';

class AddonPage extends React.Component<
ThisInterface['props'] & WithTranslation  & RouteComponentProps<{}>,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props']  & WithTranslation & RouteComponentProps<{}>) {
        super(props);
        const iframeSubtenantId = localStorage.iframeSubtenantId ? localStorage.iframeSubtenantId : '';

        this.state = {
            iframeLoaded: false,
            iframeToken: '',
            subTenantId: iframeSubtenantId
        }
        if (iframeSubtenantId) {
            this.getIframeToken(iframeSubtenantId);
        }
    }

    getIframeToken(subTenantId) {
        this.props.conmanService.getIframeToken(subTenantId).then(res => {
            this.setState({
                iframeToken: res.message
            })
        });
    }

    handleNext = (subTenant) => {
        this.setState({subTenantId: subTenant.id});
        localStorage.iframeSubtenantId = subTenant.id;
        this.getIframeToken(subTenant.id);
    }

    componentDidMount = () => {
        window.addEventListener("message", this.messageHandler, false);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data
        if (action == 'returnHeightData') {
            const obj: any = this.refs.iframe;
            if (obj) {
                obj.style.height = value + 'px';
            }
        } else if (action == 'redirectURL') {
            switch (value) {
                case '/Addon':
                    history.push(`/${RouteLoader.AddonListRoute.url}`);
                    break;
            
                default:
                    if (value.indexOf('Addon/Create') > -1) {
                        history.push(`/${RouteLoader.AddAddonRoute.url}`);
                    } else if (value.indexOf('Addon/Edit/') > -1) {
                        const slugs = value.split('/');
                        const id = slugs[slugs.length - 1];
                        history.push(`/${RouteLoader.EditAddonRoute.url.replace(':id', id)}`);
                    } else if (value.indexOf('Addon/EditSimple/') > -1) {
                        const slugs = value.split('/');
                        const id = slugs[slugs.length - 1];
                        history.push(`/${RouteLoader.EditSimpleAddonRoute.url.replace(':id', id)}`);
                    }
                    break;
            }
        }
    }

    resizeIframe = (obj) => {
        const isHasRightAddAddon = isHasRight(RouteLoader.AddAddonRoute.rightName);
        const isHasRightEditAddon = isHasRight(RouteLoader.EditAddonRoute.rightName);
        const isHasRightEditSimpleAddon = isHasRight(RouteLoader.EditSimpleAddonRoute.rightName);
        this.setState({iframeLoaded: true});
        if (isHasRightAddAddon) {
            obj.contentWindow.postMessage({
                action: 'isHasRightAddAddon'
            }, '*');
        }
        if (isHasRightEditAddon) {
            obj.contentWindow.postMessage({
                action: 'isHasRightEditAddon'
            }, '*');
        }
        if (isHasRightEditSimpleAddon) {
            obj.contentWindow.postMessage({
                action: 'isHasRightEditSimpleAddon'
            }, '*');
        }
        obj.contentWindow.postMessage({
            action: 'getHeightData'
        }, '*');
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(AddonPage)));
