import React from 'react';
import ThisInterface  from './interface';
import observer from 'util/Observer';
import PlanModel, { PackagePeriodsModel } from 'models/Plan';

import { getNameOrUndefined } from 'components/FormV2';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    packagesPeriods: PackagePeriodsModel[];
    isLoading: boolean;
}
interface Props {
    data: PlanModel;
    packageId: string;
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            packagesPeriods: [],
            isLoading: true
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.packageId !== this.props.packageId) {
            this.getPackagesPeriods();
        }
    }
    

    componentDidMount() {
        this.getPackagesPeriods();
    }

    
    getPackagesPeriods() {
        const { packageId, subTenantId, rightName } = this.props;
        if (packageId !== '') {
            return this.props.planService.getPackagesPeriods(packageId, subTenantId, rightName).then((res) => {
                const packagesPeriods: PackagePeriodsModel[] = res.data;
                this.setState({
                    packagesPeriods: packagesPeriods,
                    isLoading: false
                });
                observer.publish('plan.packagesPeriods.setData', packagesPeriods);
            }, () => {
                this.setState({
                    packagesPeriods: [],
                    isLoading: false
                });
                observer.publish('plan.packagesPeriods.setData', []);
            });
        }
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { data, t } = this.props;

        let planPeriods: any[] = [];

        this.state.packagesPeriods.forEach(row => {
            let planRow = data.planPeriods[row.id];
            if (planRow) {
                planRow.name = row.name;
                planPeriods.push(planRow);
            }
        });

        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.PLAN_PERIOD')} <span className="item-count">({t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.COUNT_ITEMS', {count: planPeriods.length})})</span></button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    <div className="table-list">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.PERIOD')}</th>
                                        <th>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.UNITS')}</th>
                                        <th>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.DURATION')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {planPeriods.map((e, key) => 
                                        <tr key={key}>
                                            <td>{e.name}</td>
                                            <td>{getNameOrUndefined(e.unit)}</td>
                                            <td>{e.value}</td>
                                        </tr>
                                    )}
                                    {planPeriods.length === 0 && <tr><td className="text-center" colSpan={3}>{t('COMMON.NO_DATA')}</td></tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;