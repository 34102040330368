import React from 'react';
import _ from 'lodash';
import CollapseButton from 'components/CollapseButton';
import CustomTable from 'components/Table';
import { DisplayDateFormat, CommaFormatted, getFutureDateYearWithMonth } from 'util/ControlUtils';
import { t } from 'util/I18nMessages';
import ThisInterface from './interface';

const paymentSummaryHeader = (t) => {
    return [
        {
            key: 'startDate',
            title: t('PAGE.REMOVE_CHANGE_MBS.VIEW.DATE')
        },
        {
            key: 'typeName',
            title: t('PAGE.REMOVE_CHANGE_MBS.VIEW.TYPE'),
            className: 'type-name'
        },
        {
            key: 'method',
            title: t('PAGE.REMOVE_CHANGE_MBS.VIEW.METHOD')
        },
        {
            key: 'status',
            title: t('PAGE.REMOVE_CHANGE_MBS.VIEW.STATUS'),
            className: 'status-text'
        },
        {
            key: 'amount',
            title: t('PAGE.REMOVE_CHANGE_MBS.VIEW.AMOUNT'),
            className: 'amount-number'
        }
    ]
}

const Info = ({ label, text }) => {
    return (
        <div className="row">
            <div className="col-md-3">{t(label)}</div>
            <div className="col-md-9">{text}</div>
        </div>
    );
};

const removeMembershipInfo = (changeMembershipEvent, dateFormat) => {
    const { planName } = changeMembershipEvent;
    return (
        <div className="future-info">
            <div className="description">
                {t('PAGE.REMOVE_CHANGE_MBS.VIEW.FUTURE_INFO_DESCR')}
            </div>
            <Info
                label="PAGE.REMOVE_CHANGE_MBS.VIEW.DATE_CHANGE"
                text={DisplayDateFormat(new Date(), dateFormat)}
            />
            <Info label="PAGE.REMOVE_CHANGE_MBS.VIEW.FUTURE_PACKAGE" text={'hello'} />
            <Info label="PAGE.REMOVE_CHANGE_MBS.VIEW.FUTURE_PLAN" text={planName} />
        </div>
    );
};

const renderTableContent = (_this: ThisInterface) => {
    const { paymentRemoveChangeMbs } = _this.state;
    const { dateFormat, t } = _this.props;
    return !_.isEmpty(paymentRemoveChangeMbs) ? (
        paymentRemoveChangeMbs.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{DisplayDateFormat(item.effectiveDate, dateFormat)}</td>
                    <td>{item.planName}</td>
                    <td>{t('PAGE.REMOVE_CHANGE_MBS.VIEW.ON_BILLING_RUN')}</td>
                    <td>{t('PAGE.REMOVE_CHANGE_MBS.VIEW.PENDING')}</td>
                    <td className="amount-number">{CommaFormatted(item.price)}</td>
                </tr>
            );
        })
    ) : (
        <tr className="no-data text-center"><td colSpan={5}>{t('COMMON.NO_DATA')}</td></tr>
    );
};

const template = (_this: ThisInterface) => {
    const {
        openPaymentInfo,
        openRemoveInfo,
        paymentRemoveChangeMbs
    } = _this.state;
    const { memberData, t, dateFormat } = _this.props;
    const changeMembershipEvent = memberData.futureEvents.find(
        item => item.type === 'CH'
    );
	
    const currentDate = new Date();
    const firstDateOfCurrentMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-1`;

    const lastEffectiveDate = !_.isEmpty(paymentRemoveChangeMbs)
        ? paymentRemoveChangeMbs[0].effectiveDate
        : firstDateOfCurrentMonth;

    return (
        <div className="remove-change-membership">
            <CollapseButton
                title={t('PAGE.REMOVE_CHANGE_MBS.VIEW.REMOVE_CHANGE_MBS_TITLE')}
                isOpen={openRemoveInfo}
                maxHeight="1200px"
                openCollapsedContent={() => _this.handleOpenCollapseBtn('RemoveInfo')}
                content={removeMembershipInfo(changeMembershipEvent, dateFormat)}
            />
            <CollapseButton
                title={t('PAGE.REMOVE_CHANGE_MBS.VIEW.PAYMENT_SUMMARY_TITLE')}
                isOpen={openPaymentInfo}
                maxHeight="1200px"
                openCollapsedContent={() => _this.handleOpenCollapseBtn('PaymentInfo')}
                content={
                    <div className="future-info">
                        <div className="description">
                            {t('PAGE.FREEZE_MEMBERSHIP.MEMBER_PAYMENT_SUMMARY_DESCRIPTION', {
                                month: 6,
                                date: getFutureDateYearWithMonth(new Date(lastEffectiveDate), 5, false, false)
                            })}
                        </div>
                        <CustomTable
                            renderingCondition
                            headers={paymentSummaryHeader(t)}
                            tableContent={renderTableContent(_this)}
                            maxHeigth="auto"
                            className="remove-change-mbs-table"
                        />
                    </div>
                }
            />
            <ul className="list-btn-action">
                <li>
                    <button
                        className="btn btn-primary"
                        onClick={_this.handleRemoveChangeMbs}
                    >
                        {t('BUTTON.FINISH')}
                    </button>
                </li>
                <li>
                    <button className="btn btn-dark" onClick={_this.props.onCancel}>
                        {t('BUTTON.CANCEL')}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default template;
