import React from 'react';

const OneButton = () => {
    return (
        <button className="btn-one btn btn-primary" >
            Demo Button
        </button>
    )
}

export default OneButton;