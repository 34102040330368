import React from 'react';
import moment from 'moment';
import ListComponent from 'list-component';
import Loading from 'components/Loading';
import CollapseButton from 'components/CollapseButton';
import {
    PRESET_ENDPOINT_URL,
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ThisInterface from './interface';

import { handleSessionExpired, checkNullData } from 'util/ControlUtils';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const PackageDetail = ({
    packageDetail
}: {
    packageDetail: ThisInterface['state']['packageDetail'];
}) => {
    const {
        id,
        name,
        description,
        isTimeRestricted,
        effectiveDate,
        expirationDate,
        packageClass,
        tier,
        timeGroup
    } = packageDetail;

    const { t } = useTranslation()
    return id ? (
        <div className="row table-above grid-divider">
            <div className="col-md-5 table-above__content">
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.NAME')}
                    </div>
                    <div className="col-6">{name}</div>
                </div>
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.DESCRIPTION')}
                    </div>
                    <div className="col-6">{description}</div>
                </div>
                <div className="row d-flex justify-content-between">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.TIME_RESTRICTED')}
                    </div>
                    <div className="col-6">{`${isTimeRestricted ? 'True' : 'False'}`}</div>
                </div>
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.EFFECTIVE')}
                    </div>
                    <div className="col-6">
                        {moment(effectiveDate).format(GENERAL_DATE_FORMAT)}
                    </div>
                </div>
            </div>
            <div className="col-md-5 table-above__content pd-left-35">
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.PACKAGE_CLASS')}
                    </div>
                    <div className="col-6">{packageClass.name}</div>
                </div>
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.TIER')}
                    </div>
                    <div className="col-6">{tier.name}</div>
                </div>
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.TIME_GROUP')}
                    </div>
                    <div className="col-6">
                        {checkNullData(timeGroup, timeGroup ? timeGroup.description : '')}
                    </div>
                </div>
                <div className="row d-flex">
                    <div className="col-6 title">
                        {t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.EXPIRES')}
                    </div>
                    <div className="col-6">
                        {moment(expirationDate).format(GENERAL_DATE_FORMAT)}
                    </div>
                </div>
            </div>
        </div>
    ) : (
    <></>
    );
};

const renderCollapseContent = (_this: ThisInterface) => {
    const {
        auth: { user }
    } = _this.props;
    const { packageDetail } = _this.state;
    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT,
        packageId: packageDetail.id
    };

    const errorHandle = (err) => {
        if (err && err.status === 401) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleSessionExpired();
        }
    }

    const { subTenantId } = _this.state;

    return (
        <ListComponent
            oidcAuth={{ ...user, profile: { ...user.profile, subTenantId: subTenantId } }}
            subTenantId={subTenantId}
            version={_this.state.version}
            rightName="view_package_detail"     
            config={{
                presetUrl: PRESET_ENDPOINT_URL,
                timeFormat: GENERAL_DATE_FORMAT,
                langCode: i18n.language,
                tableHeight: `${_this.state.tableHeight}px`,
                tableWidth: '100%'
            }
            }
            variable={variableConfig}
            actionEvent={null}
            errorEvent={errorHandle}
        />
    );
};

const template = (_this: ThisInterface) => {
    const { packageDetail, isLoading, openDropdown } = _this.state;

    const { t } = _this.props;

    return (
        <div className="package-detail">
            <div className="title-content mb-2">
                {t('PAGE.PRODUCTS.PACKAGES.TITLE')}
            </div>
            <div className="box-btn-decentralize row py-2">
                <p className="col-md-5">
                    {t('PAGE.PRODUCTS.PACKAGES.INSTRUCTION')}
                </p>
            </div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="table-list">
                    <PackageDetail packageDetail={packageDetail} />
                    <hr />
                    <div className="main-content--custom">
                        <div className="member">
                            <div className="member-main member-main--custom">
                                <div className="content">
                                    <CollapseButton
                                        className="collapse-promotion"
                                        title={t('PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.PACKAGE_PERIODS')}
                                        content={renderCollapseContent(_this)}
                                        isOpen={openDropdown}
                                        maxHeight="100%"
                                        openCollapsedContent={_this.handleOpenDropdown}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default template;
