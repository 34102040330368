import { submitModal } from 'components/CommonModal';
import { forceDecimal } from 'components/FormV2';
import { API_GET_MEMBER_ARREARS, API_GET_TENDER_TYPES, API_MAKE_PAYMENT } from 'constants/Constants';
import { SWAL_COMMON_STYLE } from 'constants/DefaultConstants';
import Field from 'models/FieldConfig';
import { MakePaymentRoute } from 'pages/RouteLoader';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CommonService from 'services/common.service';
import Swal from 'sweetalert2';
import { allocatePaymentAmount, calculateTotalAmount } from './common';
import ThisInterface, { PayOptions, Steps } from './interface';
import template from './template';


class MakePayment extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    commonService: CommonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {
            isLoading: false,
            step: Steps.Info,
            remaining: 0,
            fees: [],
            disabledNext: false,
            payOption: PayOptions.Full,
            totalAmountCharged: 0,
            totalAmountOutstanding: 0,
            totalAmountPaid: 0,
            totalPaymentAmount: 0,
            fields: {
                paymentAmount: 0,
                tenderType: ''
            },
            validations: {
                paymentAmount: {
                    ...new Field(),
                    key: 'paymentAmount',
                    name: 'paymentAmount'
                }
            },
            tenderTypes: []
        };
        this.commonService = new CommonService();
    }

    componentDidMount() {
        this.fetchData();
    }
    
    fetchData = () => {
        this.setState({
            isLoading: true
        });
        Promise.all([
            this.getFees(),
            this.getTenderTypes()
        ])
            .then(this.mapData)
            .catch(err => {
                this.props.onCancel();
                throw err;
            });
    }

    mapData = (responses) => {
        const [feeResponse, tenderTypesResponse] = responses;
        const fees = feeResponse.fees.map(fee => ({ ...fee, paymentAmount: 0 }));
        const tenderTypes = tenderTypesResponse.data;

        this.setState({
            isLoading: false,
            remaining: feeResponse.remaining,
            fees,
            totalAmountCharged: calculateTotalAmount(fees, 'amountCharged'),
            totalAmountPaid: calculateTotalAmount(fees, 'amountPaid'),
            totalAmountOutstanding: calculateTotalAmount(fees, 'amountOutstanding'),
            tenderTypes,
            fields: {
                ...this.state.fields,
                tenderType: tenderTypes[0] ? tenderTypes[0].id : ''
            }
        }, () => {
            this.allocate(this.state.totalAmountOutstanding);
        });
    }

    getTenderTypes = () => {
        return this.commonService.fetchData(API_GET_TENDER_TYPES, {
            subTenantId: this.props.memberData.subTenantId,
            rightName: MakePaymentRoute.rightName
        });
    }

    getFees = () => {
        return this.commonService.fetchData(API_GET_MEMBER_ARREARS.replace('{id}', this.props.memberData.id), {
            subTenantId: this.props.memberData.subTenantId,
            rightName: MakePaymentRoute.rightName
        });
    };

    handleChangeOption = (option: PayOptions) => {
        let paymentAmount = option === PayOptions.Full
            ? this.state.totalAmountOutstanding
            : this.getValidPaymentAmountValue(this.state.fields.paymentAmount);
        
        this.setState({
            payOption: option,
            fields: {
                ...this.state.fields,
                paymentAmount: this.state.payOption === PayOptions.Full ? 0 : paymentAmount
            }
        }, () => {
            this.updateValidation();
            this.allocate(
                option === PayOptions.Full ? paymentAmount : undefined
            );
        });
    }

    getValidPaymentAmountValue = (value) => {
        let updatedValue = Number(forceDecimal(value)) || 0;
        if (this.state.payOption === PayOptions.CustomAmount) {
            const totalInArrears = this.state.totalAmountOutstanding;
            updatedValue = updatedValue > totalInArrears ? totalInArrears : updatedValue;
        }
        return updatedValue;
    }

    validatePaymentAmount = (value: string) => {
        if(this.state.payOption === PayOptions.CustomAmount) {
            let numberizedValue = Number(forceDecimal(value)) || 0;
            if(numberizedValue > this.state.totalAmountOutstanding) {
                return {
                    isDirty: true,
                    customMsg: this.props.t('PAGE.MAKE_PAYMENT.OVERPAYMENTS_NOT_ALLOWED')
                };
            }
        }
        return {
            isDirty: false,
            customMsg: ''
        };
    }

    handleChangeField = (key: string, value: string) => {
        let updatedValue: string = value;
        let validations = Object.assign({}, this.state.validations);
        switch (key) {
            case 'paymentAmount':
                updatedValue = value.replace(/[^0-9.]/g, '');
                if(updatedValue.startsWith('0')) updatedValue = updatedValue.substr(1);
                validations = {
                    ...validations,
                    paymentAmount: {
                        ...validations.paymentAmount,
                        ...this.validatePaymentAmount(updatedValue)
                    }
                };
                break;
            default:
                break;
        }
        this.setState({
            fields: {
                ...this.state.fields,
                [key]: updatedValue
            },
            validations
        }, this.updateValidation);
    }

    updateValidation = () => {
        let disabledNext = false;
        const paymentAmount = this.state.fields.paymentAmount ? Number(forceDecimal(this.state.fields.paymentAmount)) || 0 : 0;
        if(this.state.payOption !== PayOptions.Full) {
            if(paymentAmount <= 0) {
                disabledNext = true;
            } else if(this.state.payOption === PayOptions.CustomAmount && paymentAmount > this.state.totalAmountOutstanding) {
                disabledNext = true;
            }
        }
        this.setState({
            disabledNext
        });
    }

    allocate = (paymentAmount?: number) => {
        const validPaymentAmountValue = paymentAmount || this.getValidPaymentAmountValue(this.state.fields.paymentAmount);
        this.setState({
            fees: allocatePaymentAmount(this.state.fees, validPaymentAmountValue),
            totalPaymentAmount: validPaymentAmountValue,
            fields: {
                ...this.state.fields,
                paymentAmount: validPaymentAmountValue
            }
        });
    }

    sendMakePaymentRequest = () => {
        return this.commonService.putData(API_MAKE_PAYMENT.replace('{id}', this.props.memberData.id), {
            tenderTypeId: this.state.fields.tenderType,
            amount: this.state.totalPaymentAmount
        }, {
            subTenantId: this.props.memberData.subTenantId,
            rightName: MakePaymentRoute.rightName
        });
    }

    moveToStep = (step: Steps) => {
        if(step === Steps.Summary) {
            this.allocate();
        }
        this.setState({
            step
        });
    }
    
    submit = () => {
        const {t} = this.props;
        submitModal(t('PAGE.MAKE_PAYMENT.CONFIRM_MSG'), () => {
            this.sendMakePaymentRequest().then(() => {
                Swal.fire({
                    ...SWAL_COMMON_STYLE,
                    type: 'success',
                    html: t('PAGE.MAKE_PAYMENT.SUCCESS'),
                    confirmButtonText: t('BUTTON.CLOSE'),
                }).then(() => this.props.onCancel());
            });
        });
    }

    render() {
        return template(this);
    }
}

export default withTranslation()(MakePayment);
