import React from 'react';
import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';
import ListComponent from 'list-component';
import {
    PRESET_ENDPOINT_URL,
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ThisInterface from './interface';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

const template = (_this: ThisInterface) => {
    const {
        auth: { user }
    } = _this.props;
    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT
    };

    const errorHandle = (err) => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    }
    const { t } = _this.props;

    return (
        <React.Fragment>
            <div className="look-up">
                <p className="title-content">
                    {t('PAGE.SYSTEM.LOOKUPS.TITLE')}
                </p>
                <div className="add-new-section">
                    <div className="box-btn-filter ml-auto d-flex">
                        <span>
                            {t('PAGE.SYSTEM.LOOKUPS.INSTRUCTION')}
                        </span>
                        <div className="lookup-action">
                            {isHasRight(RouteLoader.AddLookUpRoute.rightName) && (
                                <button
                                    className="btn btn-height-sm btn-add-new no-after no-margin-right"
                                    onClick={_this.handleMoveToAddLookup}
                                >
                                    {`+ ${t('PAGE.SYSTEM.LOOKUPS.BUTTON.ADD_LOOKUP')}`}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ListComponent
                oidcAuth={user}
                version={_this.state.version}
                rightName="view_lookup_list"
                config={{
                    presetUrl: PRESET_ENDPOINT_URL,
                    timeFormat: GENERAL_DATE_FORMAT,
                    langCode: i18n.language,
                    tableHeight: `${_this.state.tableHeight}px`,
                    tableWidth: '100%'
                }
                }
                variable={variableConfig}
                actionEvent={_this.handleActionEvent}
                errorEvent={errorHandle}
            />
        </React.Fragment>
    );
};

export default template;
