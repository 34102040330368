import React from 'react';
import { EditPlanAvailibilityInterface } from 'pages/Products/Plan/Template/interface';
import AvailabilityByClub from 'pages/Products/Plan/Template/AvailabilityByClub';

const template = (_this: EditPlanAvailibilityInterface) => {
    const { t } = _this.props;
    return (
        <section>
            <div className="plan-edit plan-edit-availability">
                {_this.state.step === 1 ?
                    <div className="title-content mb-3">{t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.EDIT_PLAN_AVAILABILITY')} </div>
                    :
                    <div className="title-content mb-3">{t('PAGE.PRODUCTS.PLANS.EDIT_AVAILABILITY.REVIEW_PLAN_AVAILABILITY')} </div>
                }
                <div className="member">
                    <div className="member-main member-main--custom">
                        <div className="content">
                            <AvailabilityByClub
                                step={_this.state.step}
                                data={_this.state.planDetail}
                                dataDefault={_this.state.planDetailDefault}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            {_this.state.step === 1 ?
                                <div className="box-btn justify-content-end no-padding-left">
                                    <button disabled={_this.state.disabled} className="btn btn-primary" onClick={() => _this.selectStep(2)}>{t('BUTTON.NEXT')} </button>
                                    <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.CANCEL')} </button>
                                </div>
                                :
                                <div className="box-btn justify-content-end no-padding-left">
                                    <button disabled={_this.state.disabled} className="btn btn-secondary" onClick={() => _this.selectStep(1)}>{t('BUTTON.PREVIOUS')} </button>
                                    <button disabled={_this.state.disabled} className="btn btn-primary" onClick={() => _this.onSubmit()}>{t('BUTTON.SAVE')} </button>
                                    <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.CANCEL')} </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
