import React from 'react';
import ListComponent from 'list-component';
import SelectSubTenant from 'components/SelectSubTenant';
import {
    PRESET_ENDPOINT_URL,
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import ThisInterface from './interface';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

const template = (_this: ThisInterface) => {
    const {
        auth: { user }
    } = _this.props;
    const { subTenantId, tableHeight, version } = _this.state;

    const variableConfig = {
        api: BASE_API_URL_DEVELOPMENT
    };

    const errorHandle = (err) => {
        if (err && err.status === 401) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleSessionExpired();
        }
    }

    const { t } = _this.props;

    return (
        <div className="package-detail">
            <div className="list-page plan-list-view-page">
                <div className="list-page-title">
                    <p className="title-content title-content-list">
                        {t('PAGE.PRODUCTS.PACKAGES.TITLE')}
                    </p>
                </div>
                <div className="box-btn-filter ml-auto d-flex">
                    <span className="plan-hint">
                        {t('PAGE.PRODUCTS.PACKAGES.INSTRUCTION')}
                    </span>
                </div>
            </div>
            {!subTenantId ? (
                <SelectSubTenant 
                    rightName="view_package_list"
                    conmanService={_this.conmanService}
                    onNext={_this.handleNext}
                />
            ) : (
                <ListComponent
                    oidcAuth={user}
                    version={version}
                    subTenantId={subTenantId}
                    rightName="view_package_list"     
                    config={{
                        presetUrl: PRESET_ENDPOINT_URL,
                        timeFormat: GENERAL_DATE_FORMAT,
                        langCode: i18n.language,
                        tableHeight: `${tableHeight}px`,
                        tableWidth: '100%'
                    }
                    }
                    variable={variableConfig}
                    actionEvent={_this.handleActionEvent}
                    errorEvent={errorHandle}
                />
            )}
            
            
        </div>
    );
};

export default template;
