import React from 'react';
import { connect } from 'react-redux';
import ThisInterface from './interface';
import template from './template';
import store from 'reduxs/index';
import { resetState, setSelectedSubTenant, setPackagePlanSearchFilter, setSelectedPackagePlan, setConfiguration, setPaymentDetails, setPersonalDetails, setMemberSummary } from 'reduxs/actions/NewMembership';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import MemberSummaryModel from 'models/MemberSummary';
import MembershipConfiguration from 'models/MembershipConfiguration';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import PaymentDetails from 'models/PaymentDetails';
import { PersonalDetailsModel, ContactDetailsModel, AddressDetailsModel, ContactedDetailsModel, EmergencyContactModel, OtherFieldsModel } from 'models/PersonalDetails';
import { DisplayDateFormat } from 'util/ControlUtils';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';

class AddNewMemberPage extends React.Component<
ThisInterface['props'] & RouteComponentProps<{id: string}> & WithTranslation,
ThisInterface['state']
> {
    unlisten;

    constructor(props: ThisInterface['props'] & RouteComponentProps<{id: string}> & WithTranslation) {
        super(props);

        const {  match } = this.props;
        const isEdit = !!(match && match.params && match.params.id);
        this.state = {
            data: null,
            isLoading: isEdit ? true : false,
            dateFormat: '',
            isEdit: isEdit,
            id: isEdit ? match.params.id : '',
            oldMembership: null,
            membershipNumber: ''
        }
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(() => {
            store.dispatch(resetState());
        });
        const { isEdit, id } = this.state;
        if (isEdit) {
            this.getInitData(id);
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getInitData = (memberId) => {
        const { membershipService, userService, location } = this.props;

        const { state } = location;
            
        if (state && state.subTenantId) {
            Promise.all([
                membershipService.getMemberById(
                    state.subTenantId,
                    memberId,
                    Route.ChangeMembershipRoute.rightName
                ),
                userService.getSystemConfigs(
                    state.subTenantId,
                    Route.ChangeMembershipRoute.rightName
                ),
                membershipService.getSubTenants(
                    Route.ChangeMembershipRoute.rightName
                )
            ])
                .then(([res1, res2, res3]) => {
                    const systemConfigs = res2;
                    if (systemConfigs) {
                        const dateFormat = systemConfigs.find(e => e.key === 'date.format');
                        if (dateFormat) {
                            this.setState({
                                dateFormat: dateFormat.value
                            });
                        }
                    }

                    if (res1 && res3) {
                        const currentSubtennant = res3.data.find(t => t.id === res1.subTenantId);

                        const membershipSearchFilter: MembershipSearchFilter = {
                            salePersonId: res1.salesPerson ? res1.salesPerson.id : '',
                            salePersonName: res1.salesPerson ? res1.salesPerson.name : '',
                            applicationTypeId: res1.applicationTypeId, 
                            reciprocalAccessId: "",
                            minimumContractId: "",
                            clubId: res1.club ? res1.club.id : '',
                            clubName: res1.club ? res1.club.name : '',
                            accessId: "",
                            paymentMethodId: "",
                            promotionCode: res1.promotionCode,
                            startDate: new Date(),
                            dateOfBirth: new Date(res1.dateOfBirth),
                            operator: null,
                            subTenantId: res1.subTenantId,
                            selectedPackagePlanId: res1.planId
                        }

                        const membershipConfiguration = new MembershipConfiguration();

                        const paymentDetails: PaymentDetails = {
                            primaryPaymentMethod:
                                {
                                    id: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.method ? res1.payment.primary.method.id : '',
                                    name: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.method ? res1.payment.primary.method.name : ''
                                },
                            primaryPaymentMethodId: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.method ? res1.payment.primary.method.id : '',
                            secondaryPaymentMethod: this.hasPaymentSecondaryMethod(res1) && res1.payment.secondary.method ? res1.payment.secondary.method.name : '',
                            cardTypeId: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.cardType ? res1.payment.primary.cardType.id : '',
                            cardType: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.cardType ? {
                                value: res1.payment.primary.cardType.id,
                                label: res1.payment.primary.cardType.name
                            } : '',
                            issuingBankId: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.bank ? res1.payment.primary.bank.id : '',
                            issuingBank:
                                {
                                    value: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.bank ? res1.payment.primary.bank.id : '',
                                    label: this.hasPaymentPrimaryMethod(res1) && res1.payment.primary.bank ? res1.payment.primary.bank.name : ''
                                },
                            accountName: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.accountName : '',
                            expiryDate: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.expiryDate : '',
                            creditCardNumber: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.cardNumber : '',
                            cardNumber: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.cardNumber : '',
                            issueNumber: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.issueNumber : '',
                            nationalIdNumber: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.nationalId : '',
                            otherNationalIdNumber: this.hasPaymentPrimaryMethod(res1) ? res1.payment.primary.otherNationalId : ''
                        }

                        const personalDetails: PersonalDetailsModel = {
                            title: {
                                id: res1.title ? res1.title.id : '',
                                name: res1.title ? res1.title.name : '',
                                value: res1.title ? res1.title.id : '',
                                label: res1.title ? res1.title.name : '',
                                key: "",
                                code: ""
                            },
                            gender: {
                                id: res1.gender ? res1.gender.id : '',
                                name: res1.gender ? res1.gender.name : '',
                                value: res1.gender ? res1.gender.id : '',
                                label: res1.gender ? res1.gender.name : '',
                                key: "",
                                code: ""
                            },
                            firstName: res1.firstName,
                            lastName: res1.lastName,
                            nationalIdNumber: res1.nationalId,
                            otherNationalIdNumber:res1.otherNationalId,
                            dateOfBirth: res1.dateOfBirth,
                            preferedLanguage: {
                                id: res1.languagePreference ? res1.languagePreference.id : '',
                                name: res1.languagePreference ? res1.languagePreference.name : '',
                                value: res1.languagePreference ? res1.languagePreference.id : '',
                                label: res1.languagePreference ? res1.languagePreference.name : '',
                                key: "",
                                code: ""
                            },
                            isParentalConsentProvided: res1.isParentConsentProvided
                        }
                        const contactDetails: ContactDetailsModel = {
                            mobilePhone: {
                                isValid: true,
                                phoneNumber: res1.mobilePhone,
                                countryCode: res1.mobilePhoneRegionCode
                            },
                            homePhone: {
                                isValid: true,
                                phoneNumber: res1.homePhone,
                                countryCode: res1.homePhoneRegionCode
                            },
                            workPhone: {
                                isValid: true,
                                phoneNumber: res1.workingPhone,
                                countryCode: res1.workingPhoneRegionCode
                            },
                            email: res1.email,
                            isEmailValid: true,
                            overrideEmailOption: {
                                id: res1.overrideEmail ? res1.overrideEmail.id : '',
                                name: res1.overrideEmail ? res1.overrideEmail.name : '',
                                value: res1.overrideEmail ? res1.overrideEmail.id : '',
                                label: res1.overrideEmail ? res1.overrideEmail.name : '',
                                key: "",
                                code: ""
                            },
                            mobilePhoneNoLongerUse: res1.isMobilePhoneNoLongerInUse,
                            homePhoneNoLongerUse: res1.isHomePhoneNoLongerInUse,
                            workPhoneNoLongerUse: res1.isWorkingPhoneNoLongerInUse,
                            emailNoLongerUse: res1.isEmailNoLongerInUse
                        };
                        const addressDetails: AddressDetailsModel = {
                            addressNumber: res1.addressNumber,
                            addressLine1: res1.line1,
                            addressLine2: res1.line2,
                            city: res1.city,
                            state: res1.state,
                            postCode: res1.postCode,
                            country: {
                                id: res1.country ? res1.country.id : '',
                                name: res1.country ? res1.country.name : '',
                                value: res1.country ? res1.country.id : '',
                                label: res1.country ? res1.country.name : ''
                            },
                            goneAway: res1.isGoneAway
                        };
                        const contactedDetails: ContactedDetailsModel = {
                            applicationRef: res1.applicationRef,
                            memberHappyToBeContact: res1.isHappyToBeContacted,
                            thirdPartyContact: res1.isAllowContactByThirdParty,
                            preferredContactMethod: res1.preferredContactMethod,
                            marketingSource: {
                                id: res1.source ? res1.source.id : '',
                                name: res1.source ? res1.source.name : '',
                                value: res1.source ? res1.source.id : '',
                                label: res1.source ? res1.source.name : '',
                                key: "",
                                code: ""
                            }
                        };
                        const emergencyContact: EmergencyContactModel = {
                            contactName: res1.emergencyContactName,
                            contactNumber: {
                                isValid: true,
                                phoneNumber: res1.emergencyContactPhoneNumber,
                                countryCode: res1.emergencyContactPhoneNumberRegionCode
                            },
                        };
                        const otherFields: OtherFieldsModel = {
                            companyName: res1.companyName,
                            occupation: res1.occupation,
                            referredByMemberName: res1.referredByName,
                            referredByMembershipNumber: res1.referredBymembershipNumber,
                            otherCommissionNote: res1.otherCommisisionNote,
                            generalNote: res1.generalNote,
                            aiaVitalityCategory: {
                                id: res1.aiaVitalityCategory ? res1.aiaVitalityCategory.id : '',
                                name: res1.aiaVitalityCategory ? res1.aiaVitalityCategory.name : '',
                                value: res1.aiaVitalityCategory ? res1.aiaVitalityCategory.id : '',
                                label: res1.aiaVitalityCategory ? res1.aiaVitalityCategory.name : '',
                                key: "",
                                code: ""
                            },
                            recurringCardType: {
                                id: res1.recurringCardType ? res1.recurringCardType.id : '',
                                name: res1.recurringCardType ? res1.recurringCardType.name : '',
                                value: res1.recurringCardType ? res1.recurringCardType.id : '',
                                label: res1.recurringCardType ? res1.recurringCardType.name : '',
                                key: "",
                                code: ""
                            }
                        };
                        const otherDetails = {
                            contactDetails,
                            addressDetails,
                            contactedDetails,
                            emergencyContact,
                            healthQuestions: [
                                {
                                    id: "8",
                                    name: "Are all questions in the medical questionnaire complete?",
                                    isLastQuestion: true
                                }
                            ],
                            otherFields
                        }

                        store.dispatch(setPersonalDetails({
                            ...personalDetails,
                            ...otherDetails
                        }));
                        
                        store.dispatch(setConfiguration(membershipConfiguration));
                        store.dispatch(setSelectedPackagePlan(res1.plan));
                        store.dispatch(setPackagePlanSearchFilter(membershipSearchFilter));
                        store.dispatch(setPaymentDetails(paymentDetails));
                        store.dispatch(setSelectedSubTenant(currentSubtennant));
                        store.dispatch(setMemberSummary(res1));
                        
                        this.setState({ data: res1, oldMembership: JSON.parse(JSON.stringify(this.props.newMembership)), membershipNumber: res1.membershipNumber, isLoading: false });
                    }
                })
                .catch(() => {
                    this.setState({
                        data: new MemberSummaryModel(),
                        isLoading: false
                    });
                });
        } else {
            this.props.history.push('/');
        }
    };

    hasPaymentPrimaryMethod (data) {
        return data && data.payment && data.payment.primary;
    }

    hasPaymentSecondaryMethod (data) {
        return data && data.payment && data.payment.secondary;
    }

    handleCancel = () => {
        const { t } = this.props;
        const { isEdit, id, data } = this.state;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('COMMON.CANCEL_NOTIFICATION'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.YES'),
            cancelButtonText: t('BUTTON.NO')
        }).then(res => {
            if (res.value) {
                if (!isEdit) {
                    this.props.history.push('/');
                } else {
                    this.props.history.push({
                        pathname: `/memberships/members/${id}/view`,
                        state: { subTenantId: data.subTenantId }
                    });
                }
            }
        });
    };

    handleCreateMember = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('MSG.ARE_YOU_SURE_TO_FINISH'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.YES'),
            cancelButtonText: t('BUTTON.NO')
        }).then(res => {
            if (res.value) {
                this.handleSave();
            }
        });
    };

    handleSave = () => {
        const {
            newMembership: {
                membershipSearchFilter,
                selectedPackagePlan,
                selectedAddons,
                personalDetails,
                paymentDetails
            },
            newMembership: {
                personalDetails: {
                    contactDetails,
                    addressDetails,
                    contactedDetails,
                    emergencyContact,
                    otherFields
                }
            },
            membershipService,
            history,
            t
        } = this.props;
        const { membershipNumber } = this.state;
        let preferredContactMethod = contactedDetails.preferredContactMethod.map(
            pc => pc.id
        );

        let data = {
            addons: selectedAddons.reduce((acc, cur) => [...acc, cur], []),
            membershipNumber: membershipNumber,
            applicationTypeId: membershipSearchFilter.applicationTypeId
                ? membershipSearchFilter.applicationTypeId
                : null,
            salePersonId: membershipSearchFilter.salePersonId
                ? membershipSearchFilter.salePersonId
                : null,
            packageId: selectedPackagePlan.plan.package.id
                ? selectedPackagePlan.plan.package.id
                : null,
            planId: selectedPackagePlan.plan.id ? selectedPackagePlan.plan.id : null,
            titleId: personalDetails.title.id ? personalDetails.title.id : null,
            clubId: membershipSearchFilter.clubId
                ? membershipSearchFilter.clubId
                : null,
            firstName: personalDetails.firstName ? personalDetails.firstName : null,
            lastName: personalDetails.lastName ? personalDetails.lastName : null,
            nationalId: personalDetails.nationalIdNumber
                ? personalDetails.nationalIdNumber
                : null,
            otherNationalId: personalDetails.otherNationalIdNumber
                ? personalDetails.otherNationalIdNumber
                : null,
            dateOfBirth: personalDetails.dateOfBirth
                ? DisplayDateFormat(personalDetails.dateOfBirth, GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00'
                : null,
            isParentalConsentProvided: personalDetails.isParentalConsentProvided,
            isTaxExcempt: false,
            joinedDate: new Date().toISOString(),
            startedDate: membershipSearchFilter.startDate
                ? membershipSearchFilter.startDate.toISOString()
                : null,
            languagePreferenceId: 'en-US',
            mobilePhone: contactDetails.mobilePhone.phoneNumber
                ? contactDetails.mobilePhone.phoneNumber
                : null,
            mobilePhoneRegionCode: contactDetails.mobilePhone.countryCode
                ? contactDetails.mobilePhone.countryCode
                : null,
            homePhone: contactDetails.homePhone.phoneNumber
                ? contactDetails.homePhone.phoneNumber
                : null,
            homePhoneRegionCode: contactDetails.homePhone.countryCode
                ? contactDetails.homePhone.countryCode
                : null,
            workingPhone: contactDetails.workPhone.phoneNumber
                ? contactDetails.workPhone.phoneNumber
                : null,
            workingPhoneRegionCode: contactDetails.workPhone.countryCode
                ? contactDetails.workPhone.countryCode
                : null,
            email: contactDetails.email ? contactDetails.email : null,
            addressNumber: addressDetails.addressNumber
                ? addressDetails.addressNumber
                : null,
            line1: addressDetails.addressLine1 ? addressDetails.addressLine1 : null,
            line2: addressDetails.addressLine2 ? addressDetails.addressLine2 : null,
            city: addressDetails.city ? addressDetails.city : null,
            state: addressDetails.state ? addressDetails.state : null,
            postCode: addressDetails.postCode ? addressDetails.postCode : null,
            countryId: addressDetails.country.id ? addressDetails.country.id : null,
            membershipApplicationRef: contactedDetails.applicationRef
                ? contactedDetails.applicationRef
                : null,
            isHappyToBeContacted: contactedDetails.memberHappyToBeContact,
            isAllowContactByThirdParty: contactedDetails.thirdPartyContact,
            preferredContactMethod: contactedDetails.preferredContactMethod
                ? preferredContactMethod
                : null,
            sourceId: contactedDetails.marketingSource.id
                ? contactedDetails.marketingSource.id
                : null,
            goneAway: addressDetails.isGoneAway,
            emergencyContactName: emergencyContact.contactName
                ? emergencyContact.contactName
                : null,
            emergencyContactPhoneNumber: emergencyContact.contactNumber.phoneNumber
                ? emergencyContact.contactNumber.phoneNumber
                : null,
            emergencyContactPhoneNumberRegionCode: emergencyContact.contactNumber
                .countryCode
                ? emergencyContact.contactNumber.countryCode
                : null,
            companyname: otherFields.companyName ? otherFields.companyName : null,
            occupation: otherFields.occupation ? otherFields.occupation : null,
            referredByName: otherFields.referredByMemberName
                ? otherFields.referredByMemberName
                : null,
            referredByMembershipNumber: otherFields.referredByMembershipNumber
                ? otherFields.referredByMembershipNumber
                : null,
            otherCommisisionNote: otherFields.otherCommissionNote
                ? otherFields.otherCommissionNote
                : null,
            generalNote: otherFields.generalNote ? otherFields.generalNote : null,
            aIAVitalityCategoryId: otherFields.aiaVitalityCategory.id
                ? otherFields.aiaVitalityCategory.id
                : null,
            recurringCardTypeId: otherFields.recurringCardType.id
                ? otherFields.recurringCardType.id
                : null,
            overrideEmailId: contactDetails.overrideEmailOption.id
                ? contactDetails.overrideEmailOption.id
                : null,
            preferredLanguageId: personalDetails.preferedLanguage.id
                ? personalDetails.preferedLanguage.id
                : null,
            genderId: personalDetails.gender.id ? personalDetails.gender.id : null,
            payment: {
                primary: {
                    methodId: paymentDetails.primaryPaymentMethodId
                        ? paymentDetails.primaryPaymentMethodId
                        : null,
                    cardTypeId: paymentDetails.cardTypeId
                        ? paymentDetails.cardTypeId
                        : null,
                    bankId: paymentDetails.issuingBankId
                        ? paymentDetails.issuingBankId
                        : null,
                    accountName: paymentDetails.accountName
                        ? paymentDetails.accountName
                        : null,
                    cardNumber: paymentDetails.cardNumber
                        ? paymentDetails.cardNumber
                        : paymentDetails.creditCardNumber,
                    expiryDate: paymentDetails.expiryDate
                        ? paymentDetails.expiryDate
                        : null,
                    issueNumber: paymentDetails.issueNumber
                        ? paymentDetails.issueNumber
                        : null,
                    nationalId: paymentDetails.nationalIdNumber
                        ? paymentDetails.nationalIdNumber
                        : personalDetails.nationalIdNumber
                            ? personalDetails.nationalIdNumber
                            : null,
                    otherNationalId: paymentDetails.otherNationalIdNumber
                        ? paymentDetails.otherNationalIdNumber
                        : personalDetails.otherNationalIdNumber
                            ? personalDetails.otherNationalIdNumber
                            : null
                },
                secondary: {
                    methodId: '2ED7E57C-A2A7-45CA-C144-08D731B565D2'
                }
            }
        };
        const { isEdit, id } = this.state;
        if (!isEdit) {
            const rightName = Route.AddNewMemberRoute.rightName;
            membershipService
                .postMember(membershipSearchFilter.subTenantId, data, rightName)
                .then(res => {
                    observer.publish('flagUnsavedChangeEvent', false);
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('MSG.MEMBERSHIPS.CREATE.SAVE_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    }).then(() => {
                        history.push({
                            pathname: `/memberships/members/${res.id}/view`,
                            state: { subTenantId: membershipSearchFilter.subTenantId }
                        });
                        store.dispatch(resetState());
                    });
                });
        } else {
            const rightName = Route.ChangeMembershipRoute.rightName;
            membershipService
                .putMember(id, membershipSearchFilter.subTenantId, data, rightName)
                .then(res => {
                    observer.publish('flagUnsavedChangeEvent', false);
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('MSG.MEMBERSHIPS.CREATE.SAVE_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    }).then(() => {
                        history.push({
                            pathname: `/memberships/members/${res.id}/view`,
                            state: { subTenantId: membershipSearchFilter.subTenantId }
                        });
                        store.dispatch(resetState());
                    });
                });
        }
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ newMembership }) => {
    return {
        newMembership
    };
};

export default connect(mapStateToProps)(withTranslation()(AddNewMemberPage));
