import PaymentMethod from "./PaymenMethod";

export default class PaymentDetails {
    primaryPaymentMethod = new PaymentMethod();
    primaryPaymentMethodId = '';
    secondaryPaymentMethod = '';
    cardTypeId = '';
    cardType: any;
    issuingBankId = '';
    issuingBank: any;
    accountName = "";
    expiryDate = "";    
    creditCardNumber = "";
    cardNumber="";
    issueNumber = "";
    nationalIdNumber = "";
    otherNationalIdNumber = "";    
}