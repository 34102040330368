import React from 'react';

import ThisInterface from './interface';
import { renderNewFreeze } from './common';

const template = (_this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <div id="new-freeze">
            <p>{t('PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.ENTER_INFORMATION')}</p>
            {renderNewFreeze(_this)}
        </div>
    );
};
export default template;
