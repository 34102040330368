import React from 'react';
import { DisplayDateFormat } from 'util/ControlUtils';
import { useTranslation } from 'react-i18next';

function formatAmount(amount){
    let formattedAmount = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(amount);    
    let decimalSeparator = formattedAmount.charAt(formattedAmount.length - 3);
    return formattedAmount.split(decimalSeparator);
}

interface PackagePlanItemProps {
    packagePlanItem: any;
    selectedPackagePlanId: string;
    membershipConfiguration: any;
    onSelected: (packagePlanId: string) => void;
}

const PackagePlanItem: React.FunctionComponent<PackagePlanItemProps> = ({
    packagePlanItem,
    selectedPackagePlanId,
    membershipConfiguration,
    onSelected
}) => {
    let {
        name: packageName,
        packageTier: { name: pacpackageTier },
        timeGroup: { name: accessType }
    } = packagePlanItem.package;

    let {
        id: planId,
        name: planName,
        minimumContract: { value: contractDuration, unit: { name: contractUnit } },
        payment: { paymentType, payNow, regular }
    } = packagePlanItem;
    const isLumpSum = paymentType === "lumpsum";
    const paynowAmount = formatAmount(payNow ? payNow.value : 0);
    const regularAmount =  formatAmount(regular ? regular.value : 0);
    const regularBillingDate = !isLumpSum && regular && regular.firstBilling;
    const className = selectedPackagePlanId == planId ? 'row-content d-flex active' : 'row-content d-flex';
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className="col-content">
                <p className="title">{packageName}</p>
                <ul>
                    <li>{pacpackageTier}</li>
                    <li>{accessType}</li>
                    {isLumpSum && !regular ? "" :
                        <li>
                            {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.REGULAR_BILLING')}: {DisplayDateFormat(regularBillingDate, membershipConfiguration.newMemberTimeFormat)}
                        </li>}
                </ul>
            </div>
            <div className="col-content">
                <p className="title">{planName}</p>
                <ul>
                    <li>{contractDuration} {contractUnit}</li>
                    <li>{isLumpSum ? "Lump sum payment" : "Continuous payment"}</li>
                </ul>
            </div>
            <div className="col-content pay">
                <ul>
                    <li>
                        <div className="pay-now">
                            <p>{t('PAGE.MEMBERSHIPS.CREATE.STEP_1.PAY_NOW')}</p>
                            <p className="number-pay-now">
                                {paynowAmount[0]}.
                                <sup>{paynowAmount[1]}</sup>
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="regular">
                            <p>{t('PAGE.MEMBERSHIPS.CREATE.STEP_1.REGULAR')}
                                <span className="number-regular">
                                    {regularAmount[0]}.
                                    <sup>{regularAmount[1]}</sup>
                                </span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="col-content select">
                <button className="btn btn-primary text-uppercase" onClick={() => onSelected(planId)}>
                    {t('BUTTON.SELECT')}
                </button>
            </div>
        </div>
    );
};

export default PackagePlanItem;