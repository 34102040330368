export class FieldModel {
    key = '';
    name = '';
    isRequired = false;
    isVisible = true;
    regex = '';
    maxLength = 5000;
    isDirty? = false;
    isValid? = false;
    isValidType?: 'required' | 'regex' | 'none' = 'none';
    customMsg? = '';
    nested?: FieldModel[];
    static clone = (obj: FieldModel) => {
        return {
            key: obj.key,
            name: obj.name,
            isRequired: obj.isRequired,
            isVisible: obj.isVisible,
            regex: obj.regex,
            maxLength: obj.maxLength,
            isDirty: obj.isDirty,
            isValid: obj.isValid,
            isValidType: obj.isValidType,
            customMsg: obj.customMsg,
        }
    }
}

export default FieldModel;