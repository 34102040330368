import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Language from 'models/Language';
import * as Interface from 'interfaces';
import ListComponent from 'list-component';
import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';
import i18n from 'i18next';
import { withTranslation, WithTranslation } from 'react-i18next';
import { handleSessionExpired } from 'util/ControlUtils';

import {
    BASE_API_URL_DEVELOPMENT,
    PRESET_ENDPOINT_URL,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';

import * as Route from 'pages/RouteLoader';

interface Props extends RouteComponentProps {
    locale?: Language;
    auth: Interface.StateToPropInterface['oidc'];
    history: any;
    t?: any;
}

interface State {
    tableHeight: number;
    version: number;
}

class MemberList extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            tableHeight: window.innerHeight - 320,
            version: 0
        };
    }

    componentDidMount() {
        window.onresize = this.resize;
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 320
        });
    };

    actionCallback = (action: string, id: string, data) => {
        const { history } = this.props;
        switch (action) {
            case 'view':
                history.push(`/memberships/members/${id}/view`, {
                    subTenantId: data.subTenant.id
                });
                break;
            case 'edit':
                break;
            default:
        }
        this.setState(state => ({ version: state.version + 1 }));
    };

    errorHandle = err => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    };

    render() {
        const {
            auth: { user },
            history
        } = this.props;
        const variableConfig = {
            api: BASE_API_URL_DEVELOPMENT
        };
        const rightName = Route.MemberListRoute.rightName;
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="member-list">
                    <p className="title-content">
                        {t('PAGE.MEMBERSHIPS.MEMBERS.TITLE')}
                    </p>
                    <div className="add-new-section">
                        <div className="box-btn-filter ml-auto d-flex">
                            <span>
                                {t('PAGE.MEMBERSHIPS.MEMBERS.INSTRUCTION')}
                            </span>
                            {isHasRight(RouteLoader.AddNewMemberRoute.rightName) && (
                                <button
                                    onClick={() => history.push('/memberships/create')}
                                    className="btn btn-height-sm btn-add-new"
                                >
                                    {t('PAGE.MEMBERSHIPS.MEMBERS.BUTTON.ADD_MEMBER')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <ListComponent
                    oidcAuth={user}
                    version={this.state.version}
                    rightName={rightName}
                    config={{
                        presetUrl: PRESET_ENDPOINT_URL,
                        timeFormat: GENERAL_DATE_FORMAT,
                        langCode: i18n.language,
                        tableHeight: `${this.state.tableHeight}px`,
                        tableWidth: '100%'
                    }}
                    variable={variableConfig}
                    actionEvent={this.actionCallback}
                    errorEvent={this.errorHandle}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(MemberList)));
