import Loading from 'components/Loading';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface TableProps {
    headers: {
        title: string;
        key: number | string;
        className?: string;
        icon?: string;
    }[];
    renderingCondition: boolean;
    tableContent: React.ReactNode;
    maxHeigth?: string;
    className?: string;
    tfoot?: React.ReactNode;
}

const CustomTable: React.FunctionComponent<TableProps> = ({
    headers,
    renderingCondition,
    tableContent,
    maxHeigth = '300px',
    className = '',
    tfoot,
    ...rest
}) => {
    function updateTableWidth() {
        const dataTable = document.getElementById('data-table');
        if (dataTable) {
            if (dataTable.childNodes[1]) {
                dataTable.childNodes[1]['style'].height = maxHeigth;
            }
        }
    }

    useEffect(() => {
        updateTableWidth();
    }, [maxHeigth]);

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {renderingCondition ? (
                <table
                    id="data-table"
                    {...rest}
                    className={`table table-base ${className}`}
                >
                    <thead>
                        <tr>
                            {headers &&
                                headers.map(item => {
                                    return (
                                        <th key={item.key} className={item.className}>
                                            {item.icon && (
                                                <i className={item.icon} />
                                            )}
                                            {t(item.title)}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody className="tbody-height">{tableContent}</tbody>
                    {tfoot && <tfoot>{tfoot}</tfoot>}
                </table>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
};

export default CustomTable;
