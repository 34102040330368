import React from 'react';
import NumberFormat from 'react-number-format';
import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';

const NumberCurrency = ((props: FormItemInterface) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        data,
        attribute,
        className = 'input-group mb-3',
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event.floatValue);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={className + ' ' + errorClass}>
                <NumberFormat 
                    {...attribute}
                    maxLength={fieldValidation.maxLength}
                    value={data as number} 
                    thousandSeparator={true} 
                    onValueChange={onChange}
                    name={fieldName}
                    className="form-control"
                    id={htmlFor}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});

export default NumberCurrency;