import React from 'react';
import * as Route from 'pages/RouteLoader';
import {
    BASE_API_URL_DEVELOPMENT,
    PRESET_ENDPOINT_URL,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import i18n from 'i18next';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Language from 'models/Language';
import * as Interface from 'interfaces';
import ListComponent from 'list-component';

interface Props extends RouteComponentProps {
    locale?: Language;
    auth: Interface.StateToPropInterface['oidc'];
    history: any;
    t?: any;
}

class ListComponentDemo extends React.Component<Props & WithTranslation, {}> {

    actionCallback = (action: string, id: string, data) => {
    };

    errorHandle = err => {
    };

    render() {
        const {
            auth: { user },
            history
        } = this.props;
        const variableConfig = {
            api: BASE_API_URL_DEVELOPMENT
        };
        const rightName = Route.MemberListRoute.rightName;
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="list-component-upgrade-css">
                    <div className="member-list">
                        <p className="title-content">
                            {t('PAGE.MEMBERSHIPS.MEMBERS.TITLE')}
                        </p>
                        <div className="add-new-section">
                            <div className="box-btn-filter ml-auto d-flex">
                                <span>
                                    {t('PAGE.MEMBERSHIPS.MEMBERS.INSTRUCTION')}
                                </span>
                                <button
                                    onClick={() => history.push('/memberships/create')}
                                    className="btn btn-height-sm btn-add-new"
                                >
                                    {t('PAGE.MEMBERSHIPS.MEMBERS.BUTTON.ADD_MEMBER')}
									
                                </button>
                            </div>
                        </div>
                    </div>

                    <ListComponent
                        oidcAuth={user}
                        version={1}
                        rightName={rightName}
                        config={{
                            presetUrl: PRESET_ENDPOINT_URL,
                            timeFormat: GENERAL_DATE_FORMAT,
                            langCode: i18n.language,
                            tableHeight: '100%',
                            tableWidth: '100%'
                        }}
                        variable={variableConfig}
                        actionEvent={this.actionCallback}
                        errorEvent={this.errorHandle}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(ListComponentDemo)));