import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import AdvanceSearchPanel from 'components/AdvanceSearchPanel';

import { API_USERS_FILTER, API_SEARCH_USERS } from 'constants/Constants';
import ThisInterface from './interface';

const templateSelectUser = (
    _this: ThisInterface
) => {
    const { selectedUsers, userFilterObject, tenantRoleEnabled } = _this.state;
    const { userProfile, t } = _this.props;

    const rows =
    selectedUsers.length == 0 ? (
        <TableRow>
            <TableCell colSpan={4} />
        </TableRow>
    ) : (
        selectedUsers.map((row, index) => (
            <TableRow key={index}>
                <TableCell colSpan={3}>{row.name}</TableCell>
                <TableCell>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => _this.onUserRemoved(row.id)}
                    >
                        {t('BUTTON.DELETE')}
                    </Link>
                </TableCell>
            </TableRow>
        ))
    );

    return (
        <Fragment>
            <AdvanceSearchPanel
                hideTag
                useSubTenantId={false}
                firstSelectApi={
                    tenantRoleEnabled && userProfile.tenantRoles.length > 0
                        ? API_SEARCH_USERS
                        : API_USERS_FILTER
                }
                filterFirstSelect={JSON.stringify(userFilterObject)}
                selectedSuggestions={selectedUsers}
                onSuggestionAdded={suggestion =>
                    _this.handleAddRemoveSingleSuggestion(suggestion, 'selectedUsers')
                }
                onSuggestionRemoved={() => {}}
                titleFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.USER')}
                placeholderFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ADD_USER')}
                tooltipFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.USER_INSTRUCTION')}
                method={'post'}
                rightName={_this.state.rightName}
            />

            <Table className="user-list-table">
                <TableHead className="table-header">
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Typography variant="subtitle1">
                                {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.USER')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle1">
                                {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ACTION')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="table-body">{rows}</TableBody>
            </Table>
        </Fragment>
    );
};

export default templateSelectUser;
