import React from 'react';
import _ from 'lodash';
import FormItem from 'components/FormV2';
import { getFutureDateYearWithMonth, CommaFormatted } from 'util/ControlUtils';
import { GENERAL_NA } from 'constants/Constants';

import ThisInterface from './interface';

export const Field = ({ label, content, t, notToShow = false }) => {
    return notToShow ? null : (
        <div className="row my-3">
            <div className="col-md-3">{t(label)}</div>
            <div className="col-md-4">{content}</div>
        </div>
    )
};

const newFreeze = (_this: ThisInterface) => {
    const { t } = _this.props;
    const {
        startDate,
        duration,
        reason,
        isOverrideFreezeNoticePeriod,
        isAllowFreezeBackDate,
        isAllowFutureFreezeStartDate,
        startDateRange,
        freezeDurationRange,
        freezePrice,
        isPlanAllowFreezeToday,
        reasons
    } = _this.state;

    const commonProps = {
        type: 'checkbox',
        fields: [],
        onChangeHandle: _this.handleChange
    };

    const validDate = startDate === '' ? new Date() : new Date(startDate);

    return [
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_START_DATE',
            content: (
                <FormItem
                    className={
                        isAllowFutureFreezeStartDate || isAllowFreezeBackDate
                            ? 'input-group'
                            : ''
                    }
                    options={startDateRange}
                    type={
                        isAllowFutureFreezeStartDate || isAllowFreezeBackDate
                            ? 'calendar'
                            : 'select'
                    }
                    onChangeHandle={_this.handleChange}
                    htmlFor="startDate"
                    fieldName="startDate"
                    data={startDate}
                    datePickerData={startDate === '' ? new Date() : new Date(startDate)}
                    fields={[]}
                    attribute={{
                        isClearable: false,
                        isAllowBackDated: isAllowFreezeBackDate,
                        isAllowFutureDate: isAllowFutureFreezeStartDate
                    }}
                />
            )
        },
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.PLAN_ALLOWS_FREEZE_TODAY',
            content: isPlanAllowFreezeToday ? t('BUTTON.YES') : t('BUTTON.NO')
        },
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_END_DATE',
            content: getFutureDateYearWithMonth(validDate, duration)
        },
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_DURATION',
            content: (
                <div className="row">
                    <div className="col-md-4">
                        <FormItem
                            type="select"
                            onChangeHandle={_this.handleChange}
                            options={freezeDurationRange}
                            data={duration}
                            htmlFor="duration"
                            fieldName="duration"
                            fields={[]}
                            selectAllLabel=""
                            attribute={{
                                isClearable: false
                            }}
                        />
                    </div>
                    <div className="col-md-8">
                        {freezeDurationRange.length > 1
                            ? `${t(
                                'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_DURATION_DESCRIPTION'
                            )} ${freezeDurationRange.length}`
                            : `${t(
                                'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_DURATION_DESCRIPTION_1'
                            )} ${freezeDurationRange[0] ? freezeDurationRange[0].value : t(GENERAL_NA)}`}
                    </div>
                </div>
            )
        },
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_REASON',
            content: (
                <FormItem
                    type="select"
                    options={reasons}
                    onChangeHandle={_this.handleChange}
                    data={reason}
                    htmlFor="reason"
                    fieldName="reason"
                    fields={[]}
                    attribute={{
                        isClearable: false
                    }}
                />
            ),
            notToShow: _.isEmpty(reasons)
        },
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.FREEZE_FEE',
            content: CommaFormatted(freezePrice)
        },
        {
            label: '',
            content: (
                <React.Fragment>
                    <FormItem
                        {...commonProps}
                        htmlFor="isOverrideFreezeNoticePeriod"
                        fieldName="isOverrideFreezeNoticePeriod"
                        fieldLabel={t(
                            'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.OVERRIDE_FREEZE_NOTICE_PERIOD'
                        )}
                        attribute={{
                            checked: isOverrideFreezeNoticePeriod
                        }}
                    />
                    <FormItem
                        {...commonProps}
                        htmlFor="isAllowFreezeBackDate"
                        fieldName="isAllowFreezeBackDate"
                        fieldLabel={t(
                            'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.ALLOW_FREEZE_TOBE_BACKDATED'
                        )}
                        attribute={{
                            checked: isAllowFreezeBackDate
                        }}
                    />
                    <FormItem
                        {...commonProps}
                        htmlFor="isAllowFutureFreezeStartDate"
                        fieldName="isAllowFutureFreezeStartDate"
                        fieldLabel={t(
                            'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.ALLOW_ANY_FUTURE_FREEZE_START_DATE'
                        )}
                        attribute={{
                            checked: isAllowFutureFreezeStartDate
                        }}
                    />
                </React.Fragment>
            )
        }
    ];
};

export const renderNewFreeze = (_this: ThisInterface) => {
    return newFreeze(_this).map((item, index) => {
        return <Field key={index} {...item} t={_this.props.t} />;
    });
};
