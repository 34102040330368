import React from 'react';
import _ from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router';
import observer from 'util/Observer';
import commonSevice from 'services/common.service';
import membershipService from 'services/membership.service';
import * as API from 'constants/Constants';
import MemberPersonalDetail from 'models/MemberPersonalDetails';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import ThisInterface from './interface';
import { Template } from './template';
import { createFields } from './common';

class ViewPersonalDetail extends React.Component<
ThisInterface['props'] & WithTranslation & RouteComponentProps,
ThisInterface['state']
> {
    commonService: commonSevice;
    membershipService: membershipService;

    constructor(props: ThisInterface['props'] & WithTranslation & RouteComponentProps) {
        super(props);
        const {
            memberData: { email }
        } = props;
        this.state = {
            data: new MemberPersonalDetail(),
            fields: null,
            isLoading: true
        };
        this.commonService = new commonSevice();
        this.membershipService = new membershipService();
    }

    componentDidMount() {
        this.getInitData();
    }

    componentWillUnmount() {
        observer.unsubscribe('changePrimaryPayment');
        observer.unsubscribe('communicationEmailChanged');
    }

    getInitData = () => {
        const { subTenantId, id } = this.props.memberData;
        Promise.all([
            this.membershipService.getMemberById(
                subTenantId,
                id,
                Route.MemberSummaryRoute.rightName
            ),
            this.commonService.fetchData(
                API.API_MBS_GET_FIELDS_ADD_MEMBER.replace('{0}', '1000'),
                {
                    rightName: Route.MemberSummaryRoute.rightName,
                    subTenantId
                }
            )
        ])
            .then(([res1, res2]) => {
                if (res2) {
                    this.setFields(res2.data);
                }
                if (res1) this.setState({ data: res1, isLoading: false });
            })
            .catch(() => {
                this.setState({
                    data: new MemberPersonalDetail(),
                    isLoading: false
                });
            });
    };

    setFields = data => {
        const fields = {};
        createFields('addMember', data).forEach(item => {
            Object.assign(fields, item);
        });

        this.setState({
            fields
        });
    };

    cancel = (e: React.MouseEvent) => {
        e.preventDefault();
        this.props.onCancel();
    };

    finish = (e: React.MouseEvent) => {
        e.preventDefault();
    };

    render = () => <Template {...this} />;
}

export default withRouter((withTranslation()(ViewPersonalDetail)));
