import React from 'react';
import ThisInterface from './interface';
import PlanModel, { UnitModel, PackagePeriodsModel } from 'models/Plan';

import Loading from 'components/Loading';
import OptionModel from 'models/Option';
import observer from 'util/Observer';
import { getNameOrUndefined } from 'components/FormV2';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
    packagesPeriods: PackagePeriodsModel[];
    units: OptionModel[];
}
interface Props {
    data: PlanModel;
    packageId: string;
    packagePeriodDefaultId: string;
    parentPage: 'quickClone' | 'quickEdit';
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true,
            packagesPeriods: [],
            units: []
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.packageId !== this.props.packageId) {
            this.getPackagesPeriods();
        }
    }

    componentDidMount() {
        const { subTenantId, rightName } = this.props;
        const getUnits = this.props.planService.getUnits(subTenantId, rightName).then((res) => {
            const units: UnitModel[] = res.data;
            this.setState({
                units: units.map(e => ({ value: e.code, label: e.name }))
            }, () => {
                observer.publish('plan.units.setData', this.state.units);
            });
        });
        const packagesPeriods = this.getPackagesPeriods();
        Promise.all([
            getUnits,
            packagesPeriods
        ]);
    }

    getPackagesPeriods() {
        const { packageId, subTenantId, rightName } = this.props;
        if (packageId !== '') {
            return this.props.planService.getPackagesPeriods(packageId, subTenantId, rightName).then((res) => {
                const packagesPeriods: PackagePeriodsModel[] = res.data;
                this.setState({
                    packagesPeriods: packagesPeriods,
                    isLoading: false
                });
                observer.publish('plan.packagesPeriods.setData', packagesPeriods);
            }, () => {
                this.setState({
                    packagesPeriods: [],
                    isLoading: false
                });
                observer.publish('plan.packagesPeriods.setData', []);
            });
        }
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { data, packagePeriodDefaultId, parentPage, t } = this.props;
        let col3Rows: any[] = [];

        this.state.packagesPeriods.forEach(row => {
            if (row.id !== packagePeriodDefaultId) {
                const planRow = data.planPeriods[row.id];
                if (planRow && planRow.checked || !planRow) {
                    col3Rows.push({
                        fieldLabel: row.name,
                        data: row.value + ' ' + row.unit.name
                    });
                } else {
                    col3Rows.push({
                        fieldLabel: planRow.name,
                        data: planRow.value + ' ' + planRow.unit.name
                    });
                }
            }
        });

        const col1Rows = [
            parentPage === 'quickEdit' ? {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_EDIT.PLAN_NAME',
                data: data.name
            } : undefined,
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_EDIT.DESCRIPTION',
                data: data.description
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_EDIT.PACKAGE',
                data: getNameOrUndefined(data.package)
            },
            parentPage === 'quickClone' ? {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.PLAN_TYPE',
                data: '-'
            } : undefined,
            parentPage === 'quickClone' ? {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.PAYMENT_METHOD',
                data: getNameOrUndefined(data.paymentMethod)
            } : undefined,
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.CORPORATE_PLAN',
                data: data.isCorporatePlan ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.SUBSIDY_TYPE',
                data: getNameOrUndefined(data.corporateSubsidy)
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.AVAILABLE_TO_BE_SOLD_VIA_WEB',
                data: data.isAvailableForWeb ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.REQUIRES_PERMISSION_TO_SELL_RESTRICTED_PLAN',
                data: data.isRequiresPermissionSellRestrictedItem ? t('BUTTON.YES') : t('BUTTON.NO')
            },
        ];

        const col2Rows = [
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.SWEEP_ARREARS_ONTO_BILLING',
                data: data.sweepArrearOntoBilling ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.ALLOW_MEMBERSHIP_TO_BE_FROZEN',
                data: data.isAllowFreezeMembership ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.IGNORE_THE_NO_FREEZE_PERIOD_MEMBERSHIPS_CAN_BE_FROZEN_IMMEDIATELY',
                data: data.isIgnoreNoFreeze ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.ALLOW_CHANGE_HOME_CLUB',
                data: data.isAllowChangeHomeClub ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.USE_LAST_PERIOD_DUES',
                data: data.isUseLastPeriodDues ? t('BUTTON.YES') : t('BUTTON.NO')
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.FIXED_TERM_TYPE',
                data: getNameOrUndefined(data.fixTermType)
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.FIXED_TERM_LIMIT',
                data: data.fixTermTypeLimit
            },
            {
                fieldLabel: 'PAGE.PRODUCTS.PLANS.QUICK_CLONE.EXTRA_CLUBS',
                data: data.isAllowExtraClub ? t('PAGE.PRODUCTS.PLANS.QUICK_CLONE.1_EXTRA_CLUB_ALLOWED') : t('PAGE.PRODUCTS.PLANS.QUICK_CLONE.DEFAULT_NO_EXTRA_CLUBS')
            },
        ];


        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.QUICK_CLONE.THESE_SETTINGS_CANNOT_BE_CHANGED')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            {col1Rows.map((field, key) =>
                                field && <div key={key} className="row mb-4">
                                    <div className="col-6">{t(field.fieldLabel)}</div>
                                    <div className="col-6 text-primary">{field.data}</div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {col2Rows.map((field, key) =>
                                field && <div key={key} className="row mb-4">
                                    <div className="col-6">{t(field.fieldLabel)}</div>
                                    <div className="col-6 text-primary">{field.data}</div>
                                </div>
                            )}
                        </div>
                        <div className="col-lg-4">
                            {this.state.isLoading ?
                                <Loading />
                                :
                                <>
                                    {col3Rows.map((field, key) =>
                                        field && <div key={key} className="row mb-4">
                                            <div className="col-6">{field.fieldLabel}</div>
                                            <div className="col-6 text-primary">{field.data}</div>
                                        </div>
                                    )}
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Template;