import React from 'react';
import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';

const ToogleGroup = React.memo((props: FormItemInterface) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        data,
        attribute,
        className = 'input-group mb-3',
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event.target.checked);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';
    const toogleLabel = attribute ? attribute.toogleLabel : null;
    if (attribute) {
        delete attribute.toogleLabel;
    }
    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className="form-group switch-container">
                <div className={className + ' ' + errorClass}>
                    <label className="switch">
                        <input
                            {...attribute}
                            type="checkbox"
                            maxLength={fieldValidation.maxLength}
                            checked={!!data}
                            onChange={onChange}
                            name={fieldName}
                            id={htmlFor}
                        />
                        <span className="slider round" />
                    </label>
                    <label htmlFor={htmlFor} className="label-select-switch">
                        {toogleLabel}
                    </label>

                    {renderErrorHtml(
                        isRequired,
                        isValidType,
                        isDirty,
                        fieldLabel,
                        customMsg,
                        htmlLabel
                    )}
                </div>
            </div>
        </React.Fragment>
    );
});
ToogleGroup.displayName = 'ToogleGroup';

export default ToogleGroup;
