import React from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';

import * as RouteLoader from 'pages/RouteLoader';
import * as Route from 'pages/RouteLoader';
import * as Utils from 'util/ControlUtils';
import ThisInterface from './interface';
import {
    descriptionList1,
    descriptionList2,
    descriptionList3,
    packagePeriod1,
    packagePeriod2,
    Content,
    PackageItem
} from './common';
import { Descriptions, SummarySection } from '../common';

const isAllowedFreeze = memberData => {
    const hasClaim = !!Utils.isHasRight(
        Route.ViewRequestFreezeMemberShip.rightName
    );

    return memberData.isAllowFreeze && hasClaim;
};

const isAllowedChangeMembership = memberData => {
    const hasClaim = !!Utils.isHasRight(Route.ChangeMembershipRoute.rightName);

    return (
        memberData.paymentStatus &&
		memberData.paymentStatus.code === 'OK' &&
		hasClaim
    );
};

const isAllowCancelMembership = (isAllowCancel: boolean) => {
    return isAllowCancel && !!Utils.isHasRight(RouteLoader.CancelMembershipRoute.rightName);
};

const template = (_this: ThisInterface) => {
    const { t, memberData, dateFormat, handleChangeSection, match } = _this.props;
    const { subTenantId, futureEvents, events, isAllowCancel, addons } = memberData;

    const actions = [
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.RENEW_MEMBERSHIP'
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.RENEW_COPR_MEMBERSHIP'
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.FREEZE_MEMBERSHIP',
            isVisible: isAllowedFreeze(memberData) && Utils.isNotHasFutureEvent(futureEvents, events),
            onClick: () =>
                handleChangeSection('freeze-membership', 'membership-detail')
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.REQUEST_CANCELLATION',
            isVisible: isAllowCancelMembership(isAllowCancel) && Utils.isNotHasFutureEvent(futureEvents, events),
            onClick: () =>
                handleChangeSection('cancel-membership', 'membership-detail'),

        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.BUTTON.CHANGE_MEMBERSHIP',
            isVisible: isAllowedChangeMembership(memberData) && Utils.isNotHasFutureEvent(futureEvents, events),
            to: {
                pathname: `/memberships/members/${match.params['id']}/edit`,
                state: { subTenantId: subTenantId, previousSection: 'membership-detail' }
            }
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.CHANGE_PRICE_CHANGE_STATUS'
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.MEMBER_TO_NONE_MEMBERTRANSFER'
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.MOVE_TO_CORP_MEMBERSHIP'
        }
    ];

    const actions2 = [
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.REDEEM_VOUCHER',
            onClick: () => {}
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.AFTER_MCP',
            to: ''
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.FIRST_CLUB_BENEFIT'
        },
        {
            text: 'PAGE.MEMBERSHIP_DETAIL.VIEW.BUTTON.REINSTATE_MEMBERSHIP'
        }
    ];

    return (
        <div className="member-ship-detail">
            <SummarySection
                t={t}
                title={'PAGE.MEMBERSHIPS.VIEW.MEMBERSHIP'}
                actions={actions}
            >
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <Descriptions
                            descriptionList={descriptionList1(memberData, dateFormat)}
                            t={t}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Descriptions
                            descriptionList={descriptionList2(memberData, t)}
                            t={t}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Descriptions
                            descriptionList={descriptionList3(memberData, dateFormat, t)}
                            t={t}
                        />
                    </div>
                </div>
            </SummarySection>
            <hr />
            <Content
                t={t}
                title="PAGE.MEMBERSHIP_DETAIL.MEMBER_RPOMOTION"
                element={
                    <div className="row promotion-addon-wrapper">
                        <div className="col-md-10 promotion-addon">
                            {t('PAGE.MEMBERSHIP_DETAIL.NO_PROMOTION_ADDON', {
                                key: t('PAGE.MEMBERSHIP_DETAIL.PROMOTIONS')
                            })}
                        </div>
                    </div>
                }
                actions={[{ text: 'PAGE.MEMBERSHIP_DETAIL.CHANGE_PROMOTION' }]}
            />
            <Content
                t={t}
                title="PAGE.MEMBERSHIP_DETAIL.MEMBER_ADDON"
                element={
                    <>
                    {addons.length === 0 && <div className="row promotion-addon-wrapper">
                        <div className="col-md-10 promotion-addon">
                            {t('PAGE.MEMBERSHIP_DETAIL.NO_PROMOTION_ADDON', {
                                key: t('PAGE.MEMBERSHIP_DETAIL.ADDONS')
                            })}
                        </div>
                    </div>}
                    {addons.length > 0 && <div className="row package-period-wrapper">
                        <div className="col-md-3">
                            {addons.map((e, key) => {
                                const price = e.totalPrice.toString();
                                const prices = price.indexOf('.') > 0 ? price.split('.') : [price, '00'];
                                return <div key={key} className="package-detail">
                                    <div className="data-label">{e.name}</div>
                                    <div className="data-text">{prices[0]}.{prices[1].padEnd(2, '0')}</div>
                                </div>})}
                        </div>
                    </div>}
                    </>
                }
                actions={[{ text: 'PAGE.MEMBERSHIP_DETAIL.ADD_MEMBER_ADDON' }]}
            />
            <Content
                t={t}
                title="PAGE.PRODUCTS.PACKAGES.VIEW_DETAIL.PACKAGE_PERIODS"
                className="no-border"
                element={
                    <div className="row package-period-wrapper">
                        <div className="col-md-6">
                            {packagePeriod1(memberData.plan, t).map((item, index) => {
                                return <PackageItem key={index} t={t} {...item} />;
                            })}
                        </div>
                        <div className="col-md-6">
                            {packagePeriod2(memberData.plan, t).map((item, index) => {
                                return <PackageItem key={index} t={t} {...item} />;
                            })}
                        </div>
                    </div>
                }
            />
            <div className="content-header">
                <ul className="list-action">
                    {!_.isEmpty(actions2) && actions2.map((item, index) => {
                        return (
                            <span key={index}>
                                <li onClick={item.onClick} className="list-action__items">
                                    {item.to ? (
                                        <NavLink to={item.to}>
                                            {t(item.text ? item.text : '')}
                                        </NavLink>
                                    ) : (
                                        <span>{t(item.text ? item.text : '')}</span>
                                    )}
                                </li>
                            </span>
                        );
                    })}
                </ul>
            </div>
            <ul className="list-btn-action">
                <li>
                    <button className="btn btn-primary">{t('BUTTON.ADD_NOTE')}</button>
                </li>
                <li>
                    <button className="btn btn-primary">{t('BUTTON.ADD_MESSAGE')}</button>
                </li>
                <li>
                    <button className="btn btn-dark" onClick={() => handleChangeSection('')}>{t('BUTTON.BACK')}</button>
                </li>
            </ul>
        </div>
    );
};

export default template;
