import React from 'react';
import { t } from 'util/I18nMessages';
import TableWithPaginationSortFilter, { TableColumn } from 'components/TableV2';
import ThisInterface from './interface';

// TODO: simulate API
const datas = [
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
    ['John Doe', 'Vetting passed', 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.', '17/10/2018'],
    ['System', 'P1 - P1 - Pending', 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. ', '28/11/2018'],
];

// TODO: simulate API
const pagination = {
    totalItemsCount: 20,
    totalPages: 2,
    pageIndex: 0,
    pageSize: 10
};

// TODO: simulate API
const operatorsOption = [
    { 'value': '1', 'label': 'All' },
    { 'value': '2', 'label': 'System' }
];

const filtersField = [
    { "key": "filter.operator", "isRequired": false, "isVisible": true, "regex": "", "maxLength": 50, "id": "", "name": "" },
    { "key": "filter.messageText", "isRequired": false, "isVisible": true, "regex": "", "maxLength": 50, "id": "", "name": "" },
];
const filtersForm = [
    {
        props: {
            type: 'select',
            htmlFor: 'filter[operator]',
            fieldName: 'filter[operator]',
            className: 'custom-select-filter',
            options: operatorsOption
        },
        filter: {
            queryKey: "operator",
            operation: "eq",
            queryType: "guid"
        },
        label: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.OPERATOR'
    },
    {
        props: {
            type: 'text',
            htmlFor: 'filter[messageText]',
            fieldName: 'filter[messageText]',
            className: 'custom-input-filter',
        },
        filter: {
            queryKey: "messageText",
            operation: "contains",
            queryType: "text"
        },
        label: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.MESSAGE_TEXT'
    },
]

const memberNotesTable = {
    columns: [
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.OPERATOR', sortKey: 'operator', sortable: false },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.SUBJECT', sortKey: 'subject', sortable: false },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.MESSAGE_TEXT', sortKey: 'messageText', sortable: false },
        { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.CREATED', sortKey: 'created', sortable: true },
    ] as TableColumn[],
    datas: datas,
    pagination: pagination,
    filtersField: filtersField,
    filtersForm: filtersForm
    
}

const template = (_this: ThisInterface) => {

    return (
        <div className="member-ship-detail member-contact-detail">
            <div className="section-summary ">
                <div className="summary-header ">
                    <p>{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT')}</p>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <span className="fs-i note-info">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.NO_MESSAGE')}</span>
                    </div>
                </div>
            </div>

            <hr />

            <div className="content-wrapper ">
                <div className="content-header">
                    <p className="head-title">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.EMAILS')}</p>
                </div>
                <div className="row promotion-addon-wrapper">
                    <div className="col-md-10 promotion-addon">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.NO_EMAIL')}</div>
                </div>
            </div>

            <div className="content-wrapper ">
                <div className="content-header">
                    <p className="head-title">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.LETTERS')}</p>
                </div>
                <div className="row promotion-addon-wrapper">
                    <div className="col-md-10 promotion-addon">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.NO_LETTER')}</div>
                </div>
            </div>

            <div className="content-wrapper ">
                <div className="content-header">
                    <p className="head-title">{t('PAGE.MEMBERSHIPS.VIEW.MEMBER_CONTACT.MEMBER_NOTES')}</p>
                </div>
                <TableWithPaginationSortFilter
                    columns={memberNotesTable.columns} 
                    datas={memberNotesTable.datas}
                    pagination={memberNotesTable.pagination}
                    filtersForm={memberNotesTable.filtersForm}
                    filtersField={memberNotesTable.filtersField}
                    isLoading={false}
                    onPaginationChanged={(pageIndex, pageSize) => console.log(pageIndex, pageSize)}
                    onPageSortChanged={(sorts) => console.log(sorts)}
                    onFilterChanged={(queryString) => console.log(queryString)}
                />
            </div>

            <ul className="list-btn-action">
                <li>
                    <button className="btn btn-secondary">{t('BUTTON.ADD_NOTE')}</button>
                </li>
                <li>
                    <button className="btn btn-primary">{t('BUTTON.ADD_MESSAGE')}</button>
                </li>
                <li>
                    <button className="btn btn-dark" onClick={_this.props.onCancel}>
                        {t('BUTTON.BACK')}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default template;
