import React from 'react';
import * as Utils from 'util/ControlUtils';
import FormButton from 'components/Membership/FormButton';
import { MsbClubPlan, MsbFee } from 'models/PackagePlan';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import AddOn from 'models/AddOn';

interface ProductSummaryProps {
    productSummary?: any;
    membershipSearchFilter: any;
    membershipConfiguration?: any;
    promotions?: any;
    addons?: any;
    handlePrevious?: (event) => void;
    handleNext?: (event) => void;
    handleCancel?: (event) => void;
    handleFinish?: (event) => void;
}

const TooltipInfoTable = ({ title, data, addOns }: { title: string; data: MsbFee[]; addOns?: AddOn[]}) => {
    const { t } = useTranslation();
    return <table className="tooltip-info-table">
        <thead>
            <tr>
                <th><b>{title}<br />{t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.DESCRIPTION')}</b></th>
                <th><b>{t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.AMOUNT')}</b></th>
            </tr>
        </thead>
        <tbody>
            {data.map((e, key) =>
                <tr key={key}>
                    <td>{e.typeName}</td>
                    <td>{Utils.formatMoney(e.amount, 2)}</td>
                </tr>)}
            {addOns && addOns.map((e, key) =>
                <tr key={key}>
                    <td>{e.name}</td>
                    <td>{Utils.formatMoney(e.totalPrice, 2)}</td>
                </tr>)}
        </tbody>
    </table>
}

const ProductSummary: React.FunctionComponent<ProductSummaryProps> = ({
    productSummary,
    membershipSearchFilter,
    membershipConfiguration,
    handlePrevious,
    handleNext,
    handleCancel,
    handleFinish,
    addons
}) => {
    const { t } = useTranslation();
    const plan: MsbClubPlan = productSummary.plan;
    const payNow = Utils.formatMoney((plan.payment.payNow.value + addons.reduce((acc, cur) => acc + cur.totalPrice, 0)), 2).toString();
    const payNows = payNow.indexOf('.') > 0 ? payNow.split('.') : [payNow, '00'];
    const payNowsDetail = plan.payment.payNow.fees;
    const payNowsTooltip = <TooltipInfoTable title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PAY_FEE_BREAKDOWN')} data={payNowsDetail} addOns={addons} />;

    const firstBilling = plan.payment.firstBilling
        ? plan.payment.firstBilling.amount.toString()
        : '0';

    const firstBillings =
    firstBilling.indexOf('.') > 0
        ? firstBilling.split('.')
        : [firstBilling, '00'];

    const firstBillingsDetail = plan.payment.firstBilling
        ? plan.payment.firstBilling.fees
        : [];

    const firstBillingsTooltip = (
        <TooltipInfoTable
            title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.FIRST_FEE_BREAKDOWN')}
            data={firstBillingsDetail}
        />
    );

    const nextBilling = plan.payment.nextBilling
        ? plan.payment.nextBilling.amount.toString()
        : '0';

    const nextBillings =
		nextBilling.indexOf('.') > 0 ? nextBilling.split('.') : [nextBilling, '00'];

    const nextBillingsDetail = plan.payment.nextBilling
        ? plan.payment.nextBilling.fees
        : [];

    const nextBillingsTooltip = (
        <TooltipInfoTable
            title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NEXT_FEE_BREAKDOWN')}
            data={nextBillingsDetail}
        />
    );

    const startDate =
    plan.payment &&
    plan.payment.payNow &&
    plan.payment.payNow.fees &&
    plan.payment.payNow.fees.length
        ? plan.payment.payNow.fees[0].startDate
        : membershipSearchFilter.startDate;

    return (
        <div className="aside-summary">
            <div className="product-summary">
                <p className="title-summary">
                    {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PRODUCT_SUMMARY')}
                </p>
                <div className="pay-now">
                    <p className="title-pay">
                        {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PAY_NOW')}
                    </p>
                    <p className="number-pay-now">
                        {payNows[0]}.<sup>{payNows[1].padEnd(2, '0')}
                            <Tooltip title={payNowsTooltip} placement="top-start" className="btn-tooltip">
                                <IconButton aria-label="info">
                                    <InfoIcon
                                        fontSize="small"
                                        className="infoIcon icon-detail-no-boder"
                                    />
                                </IconButton>
                            </Tooltip>
                        </sup>
                    </p>
                </div>
                <div className="section-billing">
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.FIRST_BILLING')}:
                        </span>
                        <span className="dots" />
                        <span className="first-billing last-span">
                            <b>
                                {firstBillings[0]}.{firstBillings[1]}
                            </b>
                            <Tooltip
                                title={firstBillingsTooltip}
                                placement="top-start"
                                className="btn-tooltip"
                            >
                                <IconButton aria-label="info">
                                    <InfoIcon
                                        fontSize="small"
                                        className="infoIcon icon-detail-no-boder"
                                    />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NEXT_BILLING')}:
                        </span>
                        <span className="dots" />
                        <span className="next-billing last-span">
                            <b>
                                {nextBillings[0]}.{nextBillings[1]}
                            </b>
                            <Tooltip
                                title={nextBillingsTooltip}
                                placement="top-start"
                                className="btn-tooltip"
                            >
                                <IconButton aria-label="info">
                                    <InfoIcon
                                        fontSize="small"
                                        className="infoIcon icon-detail-no-boder"
                                    />
                                </IconButton>
                            </Tooltip>
                        </span>
                    </div>
                </div>
                <div className="section-info">
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.START')}
                        </span>
                        <span className="dots" />
                        <span className="last-span">
                            {Utils.DisplayDateFormat(
                                startDate,
                                membershipConfiguration.newMemberTimeFormat
                            )}
                        </span>
                    </div>
                    {plan.payment.firstBilling && plan.payment.firstBilling.startDate && (
                        <div className="row-span">
                            <span className="first-span">
                                {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.FIRST_BILLING')}
                            </span>
                            <span className="dots" />
                            <span className="last-span">
                                {Utils.DisplayDateFormat(
                                    plan.payment.firstBilling.startDate,
                                    membershipConfiguration.newMemberTimeFormat
                                )}
                            </span>
                        </div>
                    )}
                    {plan.payment.nextBilling && plan.payment.nextBilling.startDate && (
                        <div className="row-span">
                            <span className="first-span">
                                {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NEXT_BILLING')}
                            </span>
                            <span className="dots" />
                            <span className="last-span">
                                {Utils.DisplayDateFormat(
                                    plan.payment.nextBilling.startDate,
                                    membershipConfiguration.newMemberTimeFormat
                                )}
                            </span>
                        </div>
                    )}
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.TIER')}
                        </span>
                        <span className="dots" />
                        <span className="last-span">{plan.package.packageTier.name}</span>
                    </div>
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.MCP')}
                        </span>
                        <span className="dots" />
                        <span>
                            {plan.minimumContract.value} {plan.minimumContract.unit.name}
                        </span>
                    </div>
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.ACCESS')}
                        </span>
                        <span className="dots" />
                        <span className="last-span">{plan.package.timeGroup.name}</span>
                    </div>
                    <div className="row-span">
                        <span className="first-span">
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PLAN')}
                        </span>
                        <span className="dots" />
                        <span className="last-span">{plan.name}</span>
                    </div>
                </div>
                <div className="section-add-ons">
                    <p className="title-content">{t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.ADDONS')}</p>
                    {addons.map((e, key) => {
                        const price = e.totalPrice.toString();
                        const prices = price.indexOf('.') > 0 ? price.split('.') : [price, '00'];
                        return <div key={key} className="row-span">
                            <span className="first-span">{e.name}</span>
                            <span className="dots" />
                            <span className="last-span"><b>{prices[0]}.{prices[1].padEnd(2, '0')}</b></span>
                        </div>
                    })}
                    {addons.length === 0 && <div className="row-span">
                        <span>{t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NO_ADDONS_SELECTED')}</span>
                    </div>}
                </div>
                <div className="section-promotions">
                    <p className="title-content">
                        {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.PROMOTIONS')}
                    </p>
                    <div className="row-span">
                        <span>
                            {t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.NO_PROMOTIONS_SELECTED')}
                        </span>
                    </div>
                </div>
            </div>
            <div className="sticky-aside">
                <FormButton
                    membershipConfiguration={membershipConfiguration}
                    isOnProductSummary={true}
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                    handleCancel={handleCancel}
                    handleFinish={handleFinish}
                />
            </div>
        </div>
    );
};

export default ProductSummary;
