export default class PackagePlan {
    plan: any;
}

export class MsbIdName {
    id = '';
    name = '';
}

export class MsbClubPackage extends MsbIdName {
    packageTier = new MsbIdName();
    packageClass = new MsbIdName();
    timeGroup = new MsbIdName();
}

export class MsbMinimumContract extends MsbIdName {
    value = '';
    unit = new MsbIdName();
}

export class MsbFee {
    startDate = "";
    typeName = "";
    method = "";
    status = "";
    amount = 0;
}

export class MsbPay {
    value = 0;
    currencyCode = '';
    fees: MsbFee[] = [];
}

export class MsbBilling {
    startDate = '';
    amount = 0;
    fees: MsbFee[] = [];
}

export class MsbPayment {
    paymentType = '';
    payNow = new MsbPay();
    regular = new MsbPay();
    firstBilling = new MsbBilling();
    nextBilling = new MsbBilling();
}
export class MsbClubPlan extends MsbIdName {
    package = new MsbClubPackage();
    planClass = new MsbIdName();
    minimumContract = new MsbMinimumContract();
    paymentMethod = new MsbIdName();
    payment = new MsbPayment();
    minimumContractValue = '';
}