import React from 'react';
import _ from 'lodash';
import CustomTable from 'components/Table';
import * as RouteLoader from 'pages/RouteLoader';
import * as Utils from 'util/ControlUtils';
import FormItem from 'components/FormV2';
import ThisInterface from './interface';
import { SummarySection, Descriptions, DropdownContent } from '../common';
import TableWithPaginationSortFilter, { TableColumn } from 'components/TableV2';

const memberAccount = (_this: ThisInterface, previousSection: string = '') => {
    const { memberData } = _this.props;
    const { memberAccountOpen ,advancedOpen } = _this.state;
    const { t } = _this.props;

    const actions = [
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.VIEW_BILLING_HISTORY'
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.RETURN_DEPOSIT'
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.POINT_MEMBER_DETAILS'
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.CHANGE_PAYMENT_DETAILS',
            isVisible: !!Utils.isHasRight(
                RouteLoader.ViewChangePaymentDetail.rightName
            ),
            onClick: () => _this.handleChangeSection('payment-detail', previousSection)
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.MAKE_PAYMENT',
            isVisible: !!Utils.isHasRight(
                RouteLoader.MakePaymentRoute.rightName
            ) && memberData.paymentStatus.code !== 'OK',
            onClick: () => _this.handleChangeSection('make-payment', previousSection)
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.ADVANCED',
            onClick: () => _this.handleOpenAction('advanced'),
            className: 'action',
            element: (
                <DropdownContent
                    t={t}
                    isVisible={advancedOpen}
                    actions={[
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.ACCOUNT_ADJUSTMENTS'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.CHANGE_BILLING_REFERENCE'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.CHANGE_PACKAGE_DATES'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.MEMBER_ACCOUNT_DETAIL_REPORTS'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.CHANGE_LEGACY_DETAILS'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.MEMBER_TAX_INVOICE'
                        }
                    ]}
                />
            )
        },
        {
            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.ACTION',
            onClick: () => _this.handleOpenAction('memberAccount'),
            className: 'action',
            element: (
                <DropdownContent
                    t={t}
                    isVisible={memberAccountOpen}
                    actions={[
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.VIEW_MEMBER_LEDGER'
                        },
                        {
                            text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.VIEW_DEBT_HISTORY'
                        }
                    ]}
                />
            )
        }
    ];

    const descriptionList1 = [
        {
            name: {
                text: 'PAGE.MEMBERSHIPS.VIEW.LAST_CHARGE'
            },
            value: {
                text: <span dangerouslySetInnerHTML={{__html: t('COMMON.MONEY_ON_DATE_WITH_BOLD', {money: '474,000.00', date: '01/10/2019'})}}></span>
            }
        }
    ];

    const descriptionList2 = [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.NEXT_CHARGE' },
            value: {
                text: <span dangerouslySetInnerHTML={{__html: t('COMMON.MONEY_ON_DATE_WITH_BOLD', {money: '474,000.00', date: '01/10/2020'})}}></span>
            }
        }
    ];

    const amount = memberData.currentBalance;
    const descriptionList3 = [
        {
            name: {
                text: 'PAGE.MEMBERSHIPS.VIEW.CURRENT_BALANCE',
                className: ''
            },
            value: {
                text: Utils.formatMoney(amount, 2),
                className: ''
            }
        }
    ];

    return (
        <div className="member-account-top">
            <SummarySection
                t={t}
                title={'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT'}
                actions={actions}
                ref={_this.memberAccountRef}
                changeSection={() => _this.handleChangeSection('account-detail')}
            >
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <Descriptions descriptionList={descriptionList1} t={t!} />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Descriptions descriptionList={descriptionList2} t={t!} />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Descriptions descriptionList={descriptionList3} t={t!} />
                    </div>
                </div>
            </SummarySection>
        </div>
    );
};

const template = (_this: ThisInterface) => {
    const { t } = _this.props;

    const data = [
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PRIMARY_METHOD',
            value: 'Credit Card'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.CARD_TYPE',
            value: 'Visa'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.CARD_SUB_TYPE',
            value: 'Visa'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.CARD_HOLDER_NAME',
            value: 'TestAcountName'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.CARD_NUMBER',
            value: '4000159*****0001'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.EXPIRY_DATE',
            value: '0330'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.ISSUING_BANK',
            value: ''
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.ISSUING_COUNTRY',
            value: 'IL'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.FUNDING_SOURCE',
            value: ''
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PSP_REFERENCE',
            value: ''
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.RECURRING_DETAILS_REFERENCE',
            value: '874384792794729'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.TOKEN_STATUS',
            value: 'Active'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.BILLING_REFERENCE',
            value: '0090993402'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PAYERS_NATIONAL_ID',
            value: '543535337675'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PAYERS_OLD_OTHER_NATIONAL_ID',
            value: '97932794937929'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LAST_UPDATED',
            value: '15/10/2018'
        },
        {
            key: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.SECONDARY',
            value: 'Cash'
        }
    ];

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        {data.map((item, key) => {
                            return (
                                <div className="row" key={key}>
                                    <div className="col-md-6 left-content">{t(item.key)}</div>
                                    <div className="col-md-6 text-right">{item.value}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    };
	
    // TODO: simulate API
    const pagination = {
        totalItemsCount: 20,
        totalPages: 2,
        pageIndex: 0,
        pageSize: 10
    };

    // TODO: simulate API
    const datasPaymentPass = [
        ['17/10/2018', 'Payment', '104.19', '104.19', '0.00', '0.00'],
    ];
    const paymentPassTable = {
        columns: [
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.DATE', sortKey: 'date', sortable: true },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.DESCRIPTION', sortKey: 'description', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PAID', sortKey: 'paid', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.CHARGED', sortKey: 'charged', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.OVERPAID', sortKey: 'overpaid', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.REFUNDED', sortKey: 'refunded', sortable: false },
        ] as TableColumn[],
        datas: datasPaymentPass,
        pagination: pagination,
        filtersField: [],
        filtersForm: []
    }

    // TODO: simulate API
    const datasFeeFuture = [
        ['17/10/2018', '190.00 Monthly', 'on going', 'N/A'],
    ];
    const feeFutureTable = {
        columns: [
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.FROM_DATE', sortKey: 'fromDate', sortable: true },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.AMOUNT_FREQUENCY', sortKey: 'amountFrequency', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.RECURRANCE', sortKey: 'recurrance', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.END_DATE', sortKey: 'endDate', sortable: true },
        ] as TableColumn[],
        datas: datasFeeFuture,
        pagination: pagination,
        filtersField: [],
        filtersForm: []
    }

    // TODO: simulate API
    const datasFeePast = [
        ['17/10/2018', 'Recurring Package Fee', '190.00', '190.00', '0.00', '0.00', '0.00'],
        ['17/10/2018', 'Recurring Package Fee', '190.00', '190.00', '0.00', '0.00', '0.00'],
        ['17/10/2018', 'Recurring Package Fee', '190.00', '190.00', '0.00', '0.00', '0.00'],
    ];
    const feePastTable = {
        columns: [
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.DATE', sortKey: 'date', sortable: true },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.SUBJECT', sortKey: 'subject', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.AMOUNT', sortKey: 'amount', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PAID', sortKey: 'paid', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WRITTEN_OFF', sortKey: 'writtenOff', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.REFUNDED', sortKey: 'refunded', sortable: false },
            { labelKey: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.REMAINING', sortKey: 'remaining', sortable: false },
        ] as TableColumn[],
        datas: datasFeePast,
        pagination: pagination,
        filtersField: [],
        filtersForm: []
    }
    return (
        <div className="member-account-detail">
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {memberAccount(_this as any, 'account-detail')}

            <div className="section-summary">
                <div className="summary-header">
                    <p className="text-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WHAT_FEES_IN_THE_PAST')}
                    </p>
                    <div className="show-dropdown">
                        <FormItem
                            type="select"
                            htmlFor='showItem'
                            fieldName='showItem'
                            fieldLabel={t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.BUTTON.SHOW')}
                            onChangeHandle={()=>{}}
                            attribute={{
                                isClearable: false 
                            }}
                            data={'all'}
                            options={[{ value: 'all', label: 'All' }]}
                            fields={[]}
                        />
                    </div>
                </div>
                <div>
                    <TableWithPaginationSortFilter
                        columns={feePastTable.columns} 
                        datas={feePastTable.datas}
                        pagination={feePastTable.pagination}
                        filtersForm={feePastTable.filtersForm}
                        filtersField={feePastTable.filtersField}
                        isLoading={false}
                        onPaginationChanged={(pageIndex, pageSize) => console.log(pageIndex, pageSize)}
                        onPageSortChanged={(sorts) => console.log(sorts)}
                        onFilterChanged={(queryString) => console.log(queryString)}
                    />
                </div>
            </div>
            <div className="section-summary">
                <div className="summary-header">
                    <p className="text-title mb-2">
                        {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WHAT_FEES_IN_THE_FUTURE')}
                    </p>
                </div>
                <div>
                    <TableWithPaginationSortFilter
                        columns={feeFutureTable.columns} 
                        datas={feeFutureTable.datas}
                        pagination={feeFutureTable.pagination}
                        filtersForm={feeFutureTable.filtersForm}
                        filtersField={feeFutureTable.filtersField}
                        isLoading={false}
                        onPaginationChanged={(pageIndex, pageSize) => console.log(pageIndex, pageSize)}
                        onPageSortChanged={(sorts) => console.log(sorts)}
                        onFilterChanged={(queryString) => console.log(queryString)}
                    />
                </div>
            </div>
            <div className="section-summary">
                {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.PROJECT_FOR_36_MONTHS', {
                    month: '36',
                    date: '05/03/2023'
                })}
            </div>
            <hr />
            <div className="section-summary">
                <div className="summary-header">
                    <p className="text-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WHAT_PAYMENTS_IN_THE_PAST')}
                    </p>
                    <div className="show-dropdown">
                        <FormItem
                            type="select"
                            htmlFor='showItem'
                            fieldName='showItem'
                            fieldLabel={t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.BUTTON.SHOW')}
                            onChangeHandle={()=>{}}
                            attribute={{
                                isClearable: false 
                            }}
                            data={'all'}
                            options={[{ value: 'all', label: 'All' }]}
                            fields={[]}
                        />
                    </div>
                </div>
                <div>
                    <TableWithPaginationSortFilter
                        columns={paymentPassTable.columns} 
                        datas={paymentPassTable.datas}
                        pagination={paymentPassTable.pagination}
                        filtersForm={paymentPassTable.filtersForm}
                        filtersField={paymentPassTable.filtersField}
                        isLoading={false}
                        onPaginationChanged={(pageIndex, pageSize) => console.log(pageIndex, pageSize)}
                        onPageSortChanged={(sorts) => console.log(sorts)}
                        onFilterChanged={(queryString) => console.log(queryString)}
                    />
                </div>
            </div>
            <div className="section-summary">
                {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.THIS_SECTION_ONLY_SHOWS')}
            </div>

            <hr />
            <div className="section-summary">
                <div className="summary-header">
                    <p className="text-title">
                        {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WHAT_IS_THE_CURRENT')}
                    </p>
                    <span className="list-action">
                        <span>
                            <li className="list-action__items">
                                <span>
                                    {t(
                                        'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.REMOVE'
                                    )}
                                </span>
                            </li>
                        </span>
                        <span>
                            <li className="list-action__items">
                                <span>
                                    {t(
                                        'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.LINK.CHECK_STATUS'
                                    )}
                                </span>
                            </li>
                        </span>
                    </span>
                </div>
                {renderContent()}
            </div>
            <hr />
            <div className="section-summary">
                <div className="summary-header">
                    <p className="text-title">
                        {t(
                            'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WHAT_IS_THE_CURRENT_PENDING_PAYMENT_METHOD'
                        )}
                    </p>
                </div>
                <span>
                    <b>{t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PAYMENT_DETAIL.LAST_UPDATED')}</b>
                </span>
            </div>
            <hr />
            <div className="section-summary">
                <div className="summary-header">
                    <p className="text-title">
                        {t(
                            'PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.WHAT_WAS_PREVIOUS_PRIMARY_PAYMENT_METHOD'
                        )}
                    </p>
                </div>
            </div>
			
            <div className="row-btn">
                <ul className="list-btn-action">
                    <li>
                        <button
                            className="btn btn-secondary"
                        >
                            {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.BUTTON.ADD_NOTE')}
                        </button>
                    </li>
                    <li>
                        <button
                            className="btn btn-primary"
                        >
                            {t('PAGE.MEMBERSHIPS.VIEW.MEMBER_ACCOUNT.BUTTON.ADD_MESSAGE')}
                        </button>
                    </li>
                    <li>
                        <button
                            className="btn btn-dark"
                            onClick={_this.props.onCancel}
                        >
                            {t('BUTTON.BACK')}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default template;
