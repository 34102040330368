/* eslint @typescript-eslint/no-explicit-any: 0 */
import React from 'react';
import _ from 'lodash';
import ConmanService from 'services/conman.service';
import FormSelect from 'components/Form/FormSelect';
import { SubTenantRoleModel, OptionModel } from 'models/Role';
import {withTranslation, WithTranslation} from 'react-i18next'
import { transformOptions, getSubTenantId } from 'util/ControlUtils';
import {
    ToolTipItem,
    TagList,
    updateSelectedSuggestions,
    getSortingString
} from './components/common';

interface Props extends WithTranslation {
    firstSelectApi: string;
    selectedSuggestions: any[];
    useSubTenantId?: boolean;
    secondSelectApi?: string;
    titleFirstSelect?: string;
    titleSecondSelect?: string;
    tooltipFirstSelect?: string;
    tooltipSecondSelect?: string;
    placeholderFirstSelect?: string;
    placeholderSecondSelect?: string;
    emptyTagMessage?: string;
    filterFirstSelect?: string;
    filterSecondSelect?: string;
    showSecondSelect?: boolean;
    hideTag?: boolean;
    subTenant?: {
        id: string;
        name: string;
    };
    rightName?: string;
    method?: 'post' | 'get';
    onSuggestionAdded: (suggestions: any[]) => void;
    onSuggestionRemoved: (suggestions: any[]) => void;
}

interface State {
    firstArray: OptionModel[];
    secondArray: OptionModel[];
    selectedFirstSelect: OptionModel | null;
    selectedSecondSelect: OptionModel | null;
    firstFilterValue: string | null;
    secondFilterValue: string | null;
    isDuplicate: boolean;
}

class AdvanceSearchPanel extends React.Component<Props, State> {
    conmanService: ConmanService;

    constructor(props: Props) {
        super(props);
        this.state = {
            firstArray: [],
            secondArray: [],
            selectedFirstSelect: null,
            selectedSecondSelect: null,
            firstFilterValue: '',
            secondFilterValue: '',
            isDuplicate: false
        };
        this.conmanService = new ConmanService();
    }

    static defaultProps = {
        useSubTenantId: true,
        secondSelectApi: '',
        titleFirstSelect: '',
        titleSecondSelect: '',
        tooltipFirstSelect: '',
        tooltipSecondSelect: '',
        placeholderFirstSelect: '',
        placeholderSecondSelect: '',
        emptyTagMessage: '',
        filterFirstSelect: '',
        filterSecondSelect: '',
        showSecondSelect: false,
        hideTag: false
    };

    componentDidMount() {
        const {
            firstSelectApi,
            filterFirstSelect,
            secondSelectApi,
            filterSecondSelect,
            showSecondSelect
        } = this.props;

        this.initSuggestion(firstSelectApi, filterFirstSelect!, 'firstArray', '');

        if (showSecondSelect && secondSelectApi && secondSelectApi !== '') {
            this.initSuggestion(
                secondSelectApi,
                filterSecondSelect!,
                'secondArray',
                ''
            );
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            firstSelectApi,
            filterFirstSelect,
            secondSelectApi,
            filterSecondSelect
        } = this.props;
        if (
            filterFirstSelect &&
      filterFirstSelect !== prevProps.filterFirstSelect
        ) {
            this.initSuggestion(firstSelectApi, filterFirstSelect!, 'firstArray', '');
        }
        if (
            secondSelectApi &&
      secondSelectApi !== '' &&
      filterSecondSelect &&
      filterSecondSelect !== prevProps.filterSecondSelect
        ) {
            this.initSuggestion(
                secondSelectApi,
                filterSecondSelect!,
                'secondArray',
                ''
            );
        }
    }

    filterListData = (
        data,
        selectedSuggestions,
        limit = Number.MAX_SAFE_INTEGER
    ) => {
        const dataConvert = data.map(e => {
            if (e.userName) {
                // Add missing name column when has userName column
                e.name = e.userName;
            }
            return e;
        });
        const { hideTag, showSecondSelect } = this.props;
        const listData = dataConvert;
        if (hideTag || !showSecondSelect) {
            selectedSuggestions.forEach(item => {
                const selected = listData.findIndex(el => el.id === item.id);
                if (selected !== -1) {
                    listData.splice(selected, 1);
                }
            });
            return listData;
        }
        return dataConvert.filter((e, key) => e && key < limit);
    };

    initSuggestion = (
        api: string,
        filterObject: string,
        stateKey: string,
        selectFilterValue: string
    ) => {
        const {
            selectedSuggestions,
            method = 'get',
            subTenant,
            rightName,
            useSubTenantId
        } = this.props;

        let fullyApi = api;
        let data = {};
        let limit = Number.MAX_SAFE_INTEGER;

        if (filterObject) {
            const filter = api.indexOf('?') > 0 ? '&filter=' : '?filter=';
            fullyApi = `${api}${filter}${filterObject.replace(
                '{value}',
                selectFilterValue
            )}${getSortingString(filterObject)}`;
            if (method === 'post') {
                // Apply for add user / add user group
                fullyApi = api;
                limit = 20;
                try {
                    let sortingString = '';
                    const filterObjParse = JSON.parse(filterObject);
                    if (filterObjParse && filterObjParse.and) {
                        filterObjParse.and.forEach(item => {
                            if (item.queryValue && item.queryValue.includes('{value}')) {
                                sortingString = `${item.queryKey}=asc`;
                            }
                        });

                        data = {
                            filter: filterObject.replace('{value}', selectFilterValue),
                            sort: sortingString
                        };
                    }
                } catch (e) {}
            }
        }
        const subTenantId =
      subTenant && subTenant.id ? subTenant.id : getSubTenantId();

        this.conmanService
            .getSuggestions(
                fullyApi,
                method,
                data,
                useSubTenantId ? subTenantId : '',
                rightName
            )
            .then(res => {
                const resCondition = res && res.data && !_.isEmpty(res.data);
                this.setState(prevState => {
                    return {
                        ...prevState,
                        [stateKey]: resCondition
                            ? transformOptions(
                                this.filterListData(res.data, selectedSuggestions, limit)
                            )
                            : []
                    };
                });
            })
            .catch(() => {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        [stateKey]: []
                    };
                });
            });
    };

    refetchData = (firstCondition, secondCondition) => {
        const {
            firstSelectApi,
            filterFirstSelect,
            filterSecondSelect,
            secondSelectApi,
            showSecondSelect
        } = this.props;

        this.initSuggestion(
            firstSelectApi,
            filterFirstSelect!,
            'firstArray',
            firstCondition === null ? '' : firstCondition
        );
        if (showSecondSelect && secondSelectApi && secondSelectApi !== '') {
            this.initSuggestion(
                secondSelectApi!,
                filterSecondSelect!,
                'secondArray',
                secondCondition === null ? '' : secondCondition
            );
        }
    };

    handleChangeValue = (value: OptionModel, key: string) => {
        const { hideTag, showSecondSelect, selectedSuggestions } = this.props;

        this.setState(
            prevState => {
                return {
                    ...prevState,
                    [key]: value
                };
            },
            () => {
                const {
                    selectedFirstSelect,
                    selectedSecondSelect,
                    firstArray
                } = this.state;

                const { isDuplicate } = updateSelectedSuggestions(
                    selectedSuggestions,
                    showSecondSelect!,
                    selectedFirstSelect!,
                    selectedSecondSelect!
                );

                if (hideTag || !showSecondSelect) {
                    if (selectedFirstSelect) {
                        this.handleAddSuggestion();
                        this.setState(
                            {
                                firstArray: firstArray.filter(
                                    item => item.value !== selectedFirstSelect.value
                                ),
                                selectedFirstSelect: null,
                                firstFilterValue: null,
                                secondFilterValue: null
                            },
                            () => {
                                const { firstFilterValue, secondFilterValue } = this.state;
                                this.refetchData(firstFilterValue, secondFilterValue);
                            }
                        );
                    }
                } else {
                    this.setState({
                        isDuplicate
                    });
                    this.refetchData(selectedFirstSelect, selectedSecondSelect);
                }
            }
        );
    };

    handleInputChange = (value: string, key: string) => {
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    [key]: value
                };
            },
            () => {
                const { firstFilterValue, secondFilterValue } = this.state;
                this.refetchData(firstFilterValue, secondFilterValue);
            }
        );
    };

    handleAddSuggestion = () => {
        const { selectedFirstSelect, selectedSecondSelect } = this.state;
        const {
            onSuggestionAdded,
            showSecondSelect,
            selectedSuggestions,
            hideTag
        } = this.props;
        const { suggestionList } = updateSelectedSuggestions(
            selectedSuggestions,
            showSecondSelect!,
            selectedFirstSelect!,
            selectedSecondSelect!
        );

        if (!hideTag || showSecondSelect) {
            this.setState({
                isDuplicate: true
            });
        }

        onSuggestionAdded(suggestionList);
    };

    handleDeleteSuggestion = (tagId: string) => {
        const {
            onSuggestionRemoved,
            selectedSuggestions,
            hideTag,
            showSecondSelect
        } = this.props;

        const suggestionlist = selectedSuggestions
            .map(item => {
                return SubTenantRoleModel.clone(item);
            })
            .filter(item => item.id !== tagId);

        let suggestionItem = selectedSuggestions.find(item => item.id === tagId);
        suggestionItem = {
            ...suggestionItem,
            firstKeyId: tagId,
            secondKeyId: '',
            label: suggestionItem.name,
            value: tagId
        };

        this.setState(
            {
                selectedFirstSelect: null,
                selectedSecondSelect: null
            },
            () => {
                onSuggestionRemoved(suggestionlist);
                if (hideTag || !showSecondSelect) {
                    this.setState(prevState => {
                        return {
                            firstArray: [suggestionItem].concat(prevState.firstArray)
                        };
                    });
                }
            }
        );
    };

    renderFirstSelect = () => {
        const {
            titleFirstSelect,
            tooltipFirstSelect,
            placeholderFirstSelect
        } = this.props;

        const { firstArray, selectedFirstSelect } = this.state;

        return (
            <div className="form-wrapper">
                <span className="title-first-select">{titleFirstSelect}</span>
                <FormSelect
                    className="form-select"
                    placeholder={placeholderFirstSelect}
                    options={firstArray}
                    value={selectedFirstSelect}
                    onChange={e => this.handleChangeValue(e, 'selectedFirstSelect')}
                    searchIcon
                    isClearable
                    onInputChange={(value: string) =>
                        this.handleInputChange(value, 'firstFilterValue')
                    }
                />
                <ToolTipItem tooltipText={tooltipFirstSelect!} />
            </div>
        );
    };

    render() {
        const {
            titleSecondSelect,
            tooltipSecondSelect,
            emptyTagMessage,
            placeholderSecondSelect,
            showSecondSelect,
            selectedSuggestions,
            hideTag,
            t
        } = this.props;

        const {
            secondArray,
            selectedFirstSelect,
            selectedSecondSelect,
            isDuplicate
        } = this.state;

        const disabledCondtion =
      (!showSecondSelect && selectedFirstSelect) ||
      (selectedFirstSelect && selectedSecondSelect);

        return hideTag ? (
            <div className="search-wrapper">{this.renderFirstSelect()}</div>
        ) : (
            <div className="ewh-autosuggest-container">
                <div className="autosugest-controller">
                    <div className="search-wrapper">
                        {this.renderFirstSelect()}
                        {showSecondSelect && (
                            <React.Fragment>
                                <div className="form-wrapper">
                                    <span>{titleSecondSelect}</span>
                                    <FormSelect
                                        isClearable
                                        className="form-select"
                                        placeholder={placeholderSecondSelect}
                                        options={secondArray}
                                        value={selectedSecondSelect}
                                        onChange={e =>
                                            this.handleChangeValue(e, 'selectedSecondSelect')
                                        }
                                        searchIcon
                                        onInputChange={(value: string) =>
                                            this.handleInputChange(value, 'secondFilterValue')
                                        }
                                    />
                                    <ToolTipItem tooltipText={tooltipSecondSelect!} />
                                </div>
                                <button
                                    disabled={!disabledCondtion || isDuplicate}
                                    className="btn btn-primary btn-add"
                                    onClick={() =>
                                        (disabledCondtion || !isDuplicate) &&
                    this.handleAddSuggestion()
                                    }
                                >
                                    {t('BUTTON.ADD')}
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <TagList
                    selectedSuggestions={selectedSuggestions}
                    emptyTagMessage={emptyTagMessage}
                    onTagDeleted={this.handleDeleteSuggestion}
                />
            </div>
        );
    }
}

export default withTranslation()(AdvanceSearchPanel);
