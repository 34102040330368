import React from 'react';
import ThisInterface  from './interface';
import PlanModel, { PackagePeriodsModel, PlanPeriodsModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import OptionModel from 'models/Option';
import observer from 'util/Observer';

import FormItem, { getIdOrUndefined } from 'components/FormV2';
import Loading from 'components/Loading';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
    plansPeriods: PlanPeriodsModel[];
    packagesPeriods: PackagePeriodsModel[];
    units: OptionModel[];
}
interface Props {
    onChangeHandle: Function;
    data: PlanModel;
    fields: FieldModel[];
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true,
            plansPeriods: [],
            packagesPeriods: [],
            units: []
        }
    }

    componentDidMount() {
        observer.subscribe('plan.units.setData', (data) => {
            if (data) {
                this.setState({
                    units: data
                });
            }
        });

        observer.subscribe('plan.packagesPeriods.setData', (data) => {
            if (data) {
                this.setState({
                    packagesPeriods: data,
                    isLoading: false
                });
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('plan.units.setData');
        observer.unsubscribe('plan.packagesPeriods.setData');
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { onChangeHandle, data, fields, t } = this.props;

        let planPeriodColumns: any[][] = [];
        this.state.packagesPeriods.forEach(row => {
            const planRow =  data.planPeriods[row.id];
            if (planRow && planRow.checked) {
                planPeriodColumns.push([
                    {
                        props: {
                            type: 'html',
                            html: <span>{planRow.name}</span>
                        },
                        className: 'mt-2 mr-2'
                    },
                    {
                        props: {
                            type: 'number',
                            data: planRow.value,
                            attribute: {
                                disabled: !planRow.checked,
                                min: 0
                            },
                            htmlFor: 'planPeriod[' + planRow.id + '][monthDayValue]',
                            fieldName: 'planPeriod[' + planRow.id + '][monthDayValue]'
                        },
                    },
                    {
                        props: {
                            type: 'select',
                            htmlFor: 'planPeriod[' + planRow.id + '][monthDayType]',
                            fieldName: 'planPeriod[' + planRow.id + '][monthDayType]',
                            attribute: {
                                disabled: !planRow.checked,
                                isClearable: false
                            },
                            data: getIdOrUndefined(planRow.unit),
                            selectAllLabel: '',
                            options: this.state.units
                        },
                    }
                ]);
            }
        });

        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.EDIT.PERIODS')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    {this.state.isLoading ?
                        <Loading />
                        :
                        <div className="row periods">
                            <div className="plan">
                                {planPeriodColumns.length === 0 && t('COMMON.NO_DATA')}
                                {planPeriodColumns.map((rows, rowKey) =>
                                    <div key={rowKey} className="period-item">
                                        <div className="d-flex mt-2">
                                            {rows.map((field, key) =>
                                                <div key={key} className={field.className}>
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={onChangeHandle}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>)}

                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default Template;