import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next'
import ThisInterface, { PropsInterface } from './interface';
import template from './template';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
class UserListPage extends React.Component<
PropsInterface & WithTranslation,
ThisInterface['state']
> {
    constructor(props: PropsInterface & WithTranslation) {
        super(props);
        this.state = {
            version: 0,
            tableHeight: window.innerHeight - 320
        };
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.setState({
            version: 1
        });
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 320
        });
    };

    callback = (action: string, id: string) => {
        const { history } = this.props;
        switch (action) {
            case 'edit':
                history.push(`/system/users/${id}/edit`);
                break;
            case 'delete':
                console.log(`Delete user ${id}`);
                break;
            default:
        }
        this.setState(state => ({ version: state.version + 1 }));
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};


export default withRouter(connect(mapStateToProps)(withTranslation()(UserListPage)));
