import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import commonService from 'services/common.service';
import * as Route from 'pages/RouteLoader';
import {
    getFutureDateYearWithMonth,
    DisplayDateFormat
} from 'util/ControlUtils';
import {
    API_GET_FREEZE,
    GENERAL_DATABASE_DATE_FORMAT
} from 'constants/Constants';

import template from './template';
import ThisInterface from './interface';

class FreezeMembershipSummary extends React.Component<
ThisInterface['props'] & RouteComponentProps,
ThisInterface['state']
> {
    commonService: commonService;
    constructor(props: ThisInterface['props'] & RouteComponentProps) {
        super(props);
        this.state = {
            freezeRequestSummary: [],
            isLoading: true
        };
        this.commonService = new commonService();
    }

    componentDidMount() {
        this.getFreezeRequestSummary();
    }

    getFreezeRequestSummary = () => {
        const {
            match: { params },
            requestFreeze,
            memberData: { subTenantId }
        } = this.props;
        if (params['id'] && requestFreeze) {
            const { startDate, duration } = requestFreeze;
            const data = {
                startDate: `${DisplayDateFormat(
                    startDate,
                    GENERAL_DATABASE_DATE_FORMAT
                )}T00:00:00Z`,
                endDate: getFutureDateYearWithMonth(
                    new Date(startDate),
                    duration,
                    true
                ),
                month: 6
            };
            this.commonService
                .postData(API_GET_FREEZE.replace('{id}', params['id']), data, {
                    rightName: Route.ViewRequestFreezeMemberShip.rightName,
                    subTenantId
                })
                .then(res => {
                    this.setState({
                        freezeRequestSummary: res ? res : [],
                        isLoading: false
                    });
                })
                .catch(() => {
                    this.setState({
                        isLoading: false
                    });
                });
        }
    };

    render() {
        return template(this);
    }
}

export default withRouter(FreezeMembershipSummary);
