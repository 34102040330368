import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import template from './template';
import ThisInterface from './interface';

class MemberAccoutDetail extends React.Component<ThisInterface['props'] & WithTranslation, ThisInterface['state']> {
    memberAccountRef;
    constructor(props: ThisInterface['props'] & WithTranslation) {
        super(props);
        this.state = {
            memberAccountOpen: false,
            advancedOpen: false
        };
        this.memberAccountRef = React.createRef();
    }

    handleChangeSection = (section: string, prevSection: string = '') => {
        this.props.handleChangeSection(section, prevSection)
    }
	
    handleOpenAction = (key: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                memberAccountOpen: false,
                advancedOpen: false,
                [`${key}Open`]: !prevState[`${key}Open`]
            };
        });
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(withRouter(MemberAccoutDetail))