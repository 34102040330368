import React from 'react';
import ThisInterface from './interface';
import SelectSubTenant from './SelectSubTenant';
import SelectPackagePlan from './SelectPackagePlan';
import SelectProductExtras from './SelectProductExtras';
import SelectPersonalDetails from './SelectPersonalDetails';
import SelectPaymentDetails from './SelectPaymentDetails';
import MemberSummary from './MemberSummary';
import Loading from 'components/Loading';
import ChangeSummary from './ChangeSummary';

const NewMembership = ({
    isEdit,
    membershipService,
    userService,
    handleCancel,
    handleCreateMember,
    oldMembership,
    // goToStep,
    newMembership: { membershipConfiguration } }) => {
    if (!membershipConfiguration || membershipConfiguration.stepIndex === 1) {
        return <SelectPackagePlan
            isEdit={isEdit}
            membershipService={membershipService}
            userService={userService}
            onCancel={handleCancel}
            // goToStep={(stepIndex) => goToStep(stepIndex)} 
        />
    }
    else {
        const { stepIndex, stepLatest } = membershipConfiguration;
        if (stepIndex === 2 && stepLatest > 1) {
            return <SelectProductExtras
                isEdit={isEdit}
                membershipService={membershipService}
                onCancel={handleCancel}
                // goToStep={(stepIndex) => goToStep(stepIndex)} 
            />
        }
        else if (stepIndex === 3 && stepLatest > 2 && !isEdit) {
            return <SelectPersonalDetails
                isEdit={isEdit}
                membershipService={membershipService}
                onCancel={handleCancel}
                // goToStep={(stepIndex) => goToStep(stepIndex)} 
            />
        }
        else if (stepIndex === 3 && stepLatest > 2 && isEdit) {
            return <SelectPaymentDetails
                isEdit={isEdit}
                membershipService={membershipService}
                onCancel={handleCancel}
                // goToStep={(stepIndex) => goToStep(stepIndex)} 
            />
        }
        else if (stepIndex === 4 && stepLatest > 3 && !isEdit) {
            return <SelectPaymentDetails
                isEdit={isEdit}
                membershipService={membershipService}
                onCancel={handleCancel}
                // goToStep={(stepIndex) => goToStep(stepIndex)} 
            />
        }
        else if (stepIndex === 4 && stepLatest > 3 && isEdit) {
            return <ChangeSummary
                isEdit={isEdit}
                membershipService={membershipService}
                onCancel={handleCancel}
                oldMembership={oldMembership}
                // goToStep={(stepIndex) => goToStep(stepIndex)} 
            />
        }
        else if (stepIndex === 5 && stepLatest > 4) {
            return <MemberSummary
                isEdit={isEdit}
                membershipService={membershipService}
                onCancel={handleCancel}
                onFinish={handleCreateMember}
                // goToStep={(stepIndex) => goToStep(stepIndex)} 
            />
        }
    }

    return null;
}

const template = (_this: ThisInterface) => {
    const {
        membershipService,
        userService,
        newMembership } = _this.props;
    const { isLoading, isEdit, oldMembership } = _this.state;
    return (
        !isLoading ? <div className="add-member-page">
            {
                newMembership.selectedSubTenant && newMembership.selectedSubTenant.id
                    ?
                    <NewMembership
                        isEdit={isEdit}
                        membershipService={membershipService}
                        userService={userService}
                        handleCancel={_this.handleCancel}
                        handleCreateMember={_this.handleCreateMember}
                        newMembership={newMembership}
                        oldMembership={oldMembership} />
                    :
                    <SelectSubTenant
                        isEdit={isEdit}
                        membershipService={membershipService}
                        onCancel={_this.handleCancel} />

            }
        </div> : <Loading />
    )
}

export default template;