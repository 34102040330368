import axios from 'axios';
import * as API from 'constants/Constants';
import CommonService from './common.service';

export default class FeeService {
    commonService: CommonService;

    constructor() {
        this.commonService = new CommonService();
    }

    createFee(data, rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        return axios
            .post(API.API_CREATE_FEE, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updateFee(data, id, rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        return axios
            .put(API.API_GET_UPDATE_FEE_DETAIL.replace('{1}', id), data, {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFeeDetail(id: string, rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        return axios
            .get(API.API_GET_UPDATE_FEE_DETAIL.replace('{1}', id), {
                headers
            })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getLedgerGroups(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_LEDGER_GROUP;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTaxGroups(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_TAX_GROUP;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPricingOptions(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_PRICING_OPTION;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getTenderRestrictions(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_TENNDER_RESTRICTION;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getStatuses(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_STATUS;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getItemTypes(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_ITEM_TYPE;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFeeTypes(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(true, rightName);
        const url = API.API_GET_FEE_TYPE;
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }
}
