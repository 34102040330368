import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import { MatchParams, AddEditTenderClassInterface } from 'pages/SystemPage/Tender/TenderClass/interface';
import { TenderClassModel } from 'models/Tender';
import { FieldModel } from 'models/Field';
import { transformOptions, getSubTenantId } from 'util/ControlUtils';
import { matchField, getRawValue, isValidOrNot, updateFieldValid, matchFieldNested } from 'components/FormV2';
import observer from 'util/Observer';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import * as Constants from 'constants/Constants';
import * as Route from 'pages/RouteLoader';

class AddTenderClassPage extends React.Component<
    AddEditTenderClassInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation,
    AddEditTenderClassInterface['state']
    > {
    constructor(props: AddEditTenderClassInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation) {
        super(props);
        const id = props.match.params['id'];
        const pathname = props.location.pathname;
        this.state = {
            id: id,
            isLoading: true,
            disabled: true,
            isEditMode: id && pathname.indexOf('edit') > -1 ? true : false,
            isViewMode: id && pathname.indexOf('view') > -1 ? true : false,
            tenderClassDetail: new TenderClassModel(),
            fields: [],
            subTenants: []
        }
    }

    componentDidMount() {
        const { isEditMode, isViewMode, id } = this.state;
        const rightName = isEditMode ? Route.EditTenderClassRoute.rightName :
            (isViewMode ? Route.ViewTenderClassRoute.rightName : Route.AddTenderClassRoute.rightName);

        // Get subTenantId & rightName
        const { location: { state } } = this.props;
        let subTenantId = getSubTenantId();
        if (state && state.subTenantId) {
            subTenantId = state.subTenantId;
        }
        const getTenderClassData = isEditMode || isViewMode ? this.props.conmanService.getTenderClassDetail(id, rightName, subTenantId).then((res) => {
            let tenderClassDetail: TenderClassModel = res;
            this.setState({
                tenderClassDetail: tenderClassDetail,
            });
        }, () => {
            this.props.history.push(Constants.ROUTE_TENDER_CLASS_LIST);
        }) : {};

        let fields = [
            {
                "key": "tenderClass.name",
                "isRequired": true,
                "isVisible": true,
                "regex": "^[a-zA-Z0-9-._ ]+$",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Name"
            },
            {
                "key": "tenderClass.code",
                "isRequired": true,
                "isVisible": true,
                "regex": "^[a-zA-Z0-9]+$",
                "maxLength": 2,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Tender Class Code"
            },
            {
                "key": "tenderClass.subTenant",
                "isRequired": true,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Sub-tenant"
            },
            {
                "key": "tenderClass.isAllowInMakePaymentWizard",
                "isRequired": false,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Allow in Make Payment Wizard"
            },
            {
                "key": "tenderClass.isAllowInRefundWizard",
                "isRequired": false,
                "isVisible": true,
                "regex": "",
                "maxLength": 50,
                "id": "00000000-0000-0000-0000-000000000000",
                "name": "Allow in Make Payment Wizard"
            }
        ];

        fields = fields.map(e => ({
            ...new FieldModel(), ...e,
            isValid: isEditMode ? true : false,
            isValidType: isEditMode ? 'none' : 'required'
        }));

        this.setState({
            fields: fields
        });

        const getSubTenants = this.props.conmanService.getTenderClassesSubTenants(rightName).then((res) => {
            if (res && res.data && res.data.length) {
                this.setState({
                    subTenants: transformOptions(res.data)
                });
            }
        });


        Promise.all([
            getTenderClassData,
            getSubTenants
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    onSubmit = () => {
        const isValid = this.checkValidations();
        const { isEditMode } = this.state;
        const { t } = this.props;

        if (isValid) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.CONFIRM_SAVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO'),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false
                    })
                    const { tenderClassDetail, id } = this.state;
                    Promise.all([
                        isEditMode
                            ? this.props.conmanService.updateTenderClass(TenderClassModel.buildData(tenderClassDetail), id, Route.EditTenderClassRoute.rightName)
                            : this.props.conmanService.createTenderClass(TenderClassModel.buildData(tenderClassDetail), Route.AddTenderClassRoute.rightName)
                    ]).then(() => {
                        observer.publish('flagUnsavedChangeEvent', false);
                        this.setState({
                            disabled: true
                        }, () => {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('MSG.SAVE_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => {
                                this.props.history.push(Constants.ROUTE_TENDER_CLASS_LIST);
                            })
                        })
                    });
                }
            });
        }
    }

    onCancel = () => {
        this.props.history.push(Constants.ROUTE_TENDER_CLASS_LIST);
    }

    checkValidations() {
        let isValid = true;
        const fields = this.state.fields.map(e => {
            e.isDirty = true;
            if (e.nested) {
                e.nested = e.nested.map(fieldNested => {
                    fieldNested.isDirty = true;
                    if (isValidOrNot(fieldNested.isValid, fieldNested.isRequired, fieldNested.regex, fieldNested.customMsg)) {
                        isValid = false;
                    }
                    return fieldNested;
                })
            }
            if (isValidOrNot(e.isValid, e.isRequired, e.regex, e.customMsg)) {
                isValid = false;
            }
            return e;
        });
        this.setState({
            fields: fields
        }, () => {
            if (!isValid) {
                const element: HTMLElement | null = document.querySelector('.error-holder input, .error-holder textarea, .error-holder select, .error-holder .icon-checked-ico:before, .error-holder .select__control');
                if (element) {
                    element.focus();
                }
            }
        });
        return isValid;
    }

    checkValidation(nameHtml, valueHtml, nestedNameData) {
        const fields = this.state.fields.map(e => {
            const extractNameValidation = e.key.split('.');
            const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
            if (matchField(nameHtml, shortNameValidation)) {
                if (e.nested) {
                    e.nested = e.nested.map(fieldNested => {
                        if (matchFieldNested(nameHtml, fieldNested.key)) {
                            const rawValue = getRawValue(valueHtml, fieldNested.key, nestedNameData);
                            fieldNested = updateFieldValid(fieldNested, rawValue);
                        }
                        return fieldNested;
                    })
                } else {
                    const rawValue = getRawValue(valueHtml, null, null);
                    e = updateFieldValid(e, rawValue);
                }
            }
            return e;
        });
        this.setState({
            fields: fields
        })
    }

    setTenderClassStateByProperty(nameData, nameHtml, valueHtml, nestedNameData = '') {
        this.setState(prevState => ({
            tenderClassDetail: {
                ...prevState.tenderClassDetail, [nameData]: valueHtml
            }
        }), () => this.checkValidation(nameHtml, valueHtml, nestedNameData));
    }

    onChangeHandle(nameHtml, valueHtml) {
        let { tenderClassDetail } = this.state;
        const { t } = this.props;
        let fields: FieldModel[] = [];
        switch (nameHtml) {
            case 'tenderClassDetail[name]':
                this.setTenderClassStateByProperty('name', nameHtml, valueHtml);
                break;
            case 'tenderClassDetail[code]':
                valueHtml = valueHtml.toUpperCase();
                this.setTenderClassStateByProperty('code', nameHtml, valueHtml);
                /* Custom validation of tender code */
                fields = this.state.fields.map(e => {
                    const extractNameValidation = e.key.split('.');
                    const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
                    if (matchField(nameHtml, shortNameValidation)) {
                        if (valueHtml.length !== 2) {
                            e.customMsg = { value: 'PAGE.SYSTEM.TENDER_CLASS.ERR_TENDER_CODE_MIN_LENGTH', key: { min: 2 } } as any;
                        } else {
                            e.customMsg = '';
                        }
                    }
                    return e;
                });
                this.setState({
                    fields: fields
                })
                break;
            case 'tenderClassDetail[subTenant]':
                this.setTenderClassStateByProperty('subTenant', nameHtml, { id: valueHtml, name: '' });
                break;
            case 'tenderClassDetail[isAllowInMakePaymentWizard]':
                this.setTenderClassStateByProperty('isAllowInMakePaymentWizard', nameHtml, !tenderClassDetail.isAllowInMakePaymentWizard);
                break;
            case 'tenderClassDetail[isAllowInRefundWizard]':
                this.setTenderClassStateByProperty('isAllowInRefundWizard', nameHtml, !tenderClassDetail.isAllowInRefundWizard);
                break;
            default:
                break;
        }
        this.setState({
            disabled: false
        });
        observer.publish('flagUnsavedChangeEvent', true);
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {

    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(AddTenderClassPage)));
