import React from 'react';
import { Input } from 'reactstrap';
import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';

const TextAreaGroup = React.memo((props: FormItemInterface) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        data,
        attribute,
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event.target.value);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={errorClass}>
                <Input
                    rows={4}
                    style={{ resize: 'none' }}
                    {...attribute}
                    value={data}
                    maxLength={fieldValidation.maxLength}
                    onChange={onChange}
                    name={fieldName}
                    id={htmlFor}
                    type="textarea"
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
TextAreaGroup.displayName = 'TextAreaGroup';

export default TextAreaGroup;
