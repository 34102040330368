import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import CollapseButton from 'components/CollapseButton';
import CustomTable from 'components/Table';
import ThisInterface from './interface';
import { Interface } from 'readline';


const ChangeMemberSummaryInformation = (_this: ThisInterface) => {
    const {t} = _this.props;

    let dataOldMembership = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CLUB'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_PLAN'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_FEE'),
            data: "Gold Gyme"
        }
    ]

    let dataNewMembership = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CLUB'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_PLAN'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_FEE'),
            data: "Gold Gyme"
        }
    ]

    return (
        <>
            <div className="select-group open">
                <p className="title">{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.NEW_MEMBERSHIP')}</p>
                {dataOldMembership.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
                <p className="title">{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.OLD_MEMBERSHIP')}</p>
                {dataNewMembership.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
                <p>{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SELECT_COMMUNICATION_OPTIONS')}</p>
                <form className="option-group-change-summary select-group open" action="#">
                    <div className="form-check"><label className="custom-radio">
                        <span>No Communication</span>
                        <input type="radio" name="radio" /><span className="checkmark-radio" />
                    </label></div>
                    <div className="form-check"><label className="custom-radio">
                        <span>Existing Email (doangluong@gmail.com)</span>
                        <input type="radio" name="radio" /><span className="checkmark-radio" />
                    </label></div>
                    <div className="form-check"><label className="custom-radio">
                        <span>New Email</span>
                       <input type="radio" name="radio" /><span className="checkmark-radio" />
                    </label></div>
                </form>
               <div className="form-email">
                    <form action="" className="form-group">
                            <label htmlFor="">Email Address</label>
                            <input className="form-control form-control" type="text" placeholder="Email Address"/>
                    </form>
               </div>
            </div>
        </>
    )
}

const NewMemberSummaryInformation = (_this: ThisInterface) => {
    const {t} = _this.props;
    let data = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_PLAN'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SALES_PERSON'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.JOIN_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.END_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_ELIGIBILITY_REQUIRED'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ELIGIBILITY_PROVIDED'),
            data: "Gold Gyme"
        }

    ]

    return (
        <>
            <div className="select-group open">
                <p className="title">Summary information about the membership</p>
                {data.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
            </div>
        </>
    )
}

const OldMemberSummaryInformation = (_this: ThisInterface) => {
    const {t} = _this.props;
    let data = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_PLAN'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SALES_PERSON'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.JOIN_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.END_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_ELIGIBILITY_REQUIRED'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ELIGIBILITY_PROVIDED'),
            data: "Gold Gyme"
        }

    ]

    return (
        <>
            <div className="select-group open">
                <p className="title">Summary information about the membership</p>
                {data.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
            </div>
        </>
    )
}

const PaymentMethodSummaryInformation = (_this: ThisInterface) => {
    const {t} = _this.props;
    let data = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PRIMARY_METHOD'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_SERVICE_PROVIDER'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARD_TYPE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARD_SUB_TYPE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARDHOLDER_NAME'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARD_NUMBER'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.EXPIRY_DATE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ISSUING_BANK'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ISSUING_COUNTRY'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.FUNDING_SOURCE'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYERS_NATIONAL_ID_NUMBER'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYER_OTHER_ID_PASSPORT_NO'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.LAST_UPDATED'),
            data: "Gold Gyme"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SECONDARY_METHOD'),
            data: "Gold Gyme"
        }
    ]

    return (
        <>
            <div className="select-group open">
                {data.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
            </div>
        </>
    )
}

const headers = [
    {
        key: 1,
        title: "title1"
    },
    {
        key: 2,
        title: "title2"
    },
    {
        key: 3,
        title: "title3"
    },

]

const renderTableContent = () => {
    return (
        <div>
            Table Here
        </div>
    )
}

const MemberPaymentSummaryInformation = (_this) => {
    return (
        <>
            <p>Table title</p>
            <div className="decentralize">
                <div className="decentralize-main">
                    <div className="table-responsive">
                        {/* <CustomTable
                            headers={headers}
                            tableContent={renderTableContent()}
                            renderingCondition
                        /> */}
                    </div>
                </div>
            </div>
        </>
    )
}


const template = (_this: ThisInterface) => {

    const {
        openDropdown
    } = _this.state;


    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    <p>Change Summary</p>
                </div>
                <div className="member-main">
                    <div>Breadcrumb</div>
                    <div className="content select-add-on personal change-summary">
                         <div className="main-payment select-add-on-main">
                            <CollapseButton
                                className="collapse-promotion"
                                title="Change Membership Summary Information"
                                content={ChangeMemberSummaryInformation(_this)}
                                isOpen={openDropdown['changeMemberSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('changeMemberSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title="New Membership Summary Information"
                                content={NewMemberSummaryInformation(_this)}
                                isOpen={openDropdown['newMemberSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('newMemberSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title="Old Membership Summary Information"
                                content={OldMemberSummaryInformation(_this)}
                                isOpen={openDropdown['oldMemberSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('oldMemberSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title="Payment Method Summary Information"
                                content={PaymentMethodSummaryInformation(_this)}
                                isOpen={openDropdown['paymentMethodSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('paymentMethodSummaryInformation')}
                            />
                             <CollapseButton
                                className="collapse-promotion"
                                title="Member Payment Summary Information"
                                content={MemberPaymentSummaryInformation(_this)}
                                isOpen={openDropdown['memberPaymentSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('memberPaymentSummaryInformation')}
                            />
                         </div>
                         <div>Product Summary</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default template;