import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Interface from 'interfaces';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import Tenant from 'models/Tenant';
import * as Route from 'pages/RouteLoader';

declare var AvatarCrop;
declare var img;

interface StateInterface {
    zoom: number;
    invisibleHolder: boolean;
    showDiscardBtn: boolean;
    boxHeight: number;
}

interface Props {
    subTenantId: string;
}

class ChangeLogo extends Component<Interface.PagePropsInterface & Props & WithTranslation, StateInterface> {
    dataImg = '';
    avatar;
    constructor(props) {
        super(props);
        AvatarCrop.DEFAULT_MODE = 'fill';
        this.state = {
            zoom: 0,
            invisibleHolder: true,
            showDiscardBtn: false,
            boxHeight: window.innerHeight - 500
        };
    }
    componentDidMount() {
        this.avatar = new AvatarCrop(document.querySelector('.holder'), {
            width: 298,
            height: 69.5,
            minZoom: 0,
            maxSize: DefaultConstants.MAX_FILESIZE_LOGO_UPLOAD * 1024 * 1024,
            message: ''
        });

        let link = document.getElementById('img-store');
        img = link!.firstElementChild;

        this.avatar.addEventListener('change', () => this.changingImage());
        this.avatar.addEventListener('clear', () => this.changingImage());
        this.avatar.addEventListener('load', () => this.selectedImage());
        this.avatar.addEventListener('errorMaxSize', () => this.showErrorMaxSize());

        observer.subscribe('windowResizeEvent', data => {
            if (data > 767) {
                this.avatar.config({
                    width: 298,
                    height: 69.5
                });
                this.avatar.render();
            }  else {
                this.avatar.config({
                    width: 226,
                    height: 52
                });
                this.avatar.render();
            }
            this.setState({
                boxHeight: window.innerHeight - 500
            });
        });
        observer.publish('windowResizeEvent', window.innerWidth);
    }

    componentWillUnmount() {
        observer.unsubscribe('windowResizeEvent');
    }

    openTabs(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName)!.style.display = "block";
        evt.currentTarget.className += " active";
    }

    showErrorMaxSize() {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.FILE_SIZE_INSTRUCTION', { filesize: DefaultConstants.MAX_FILESIZE_LOGO_UPLOAD }),
            type: 'error',
            confirmButtonText: 'OK'
        });
    }

    changeZoom(value) {
        this.setState({ zoom: value });
        if (this.avatar && this.avatar.data) {
            let canvasW = this.avatar.options.width,
                canvasH = this.avatar.options.height,
                image = this.avatar.data,
                imageW = image.width,
                imageH = image.height,
                ratio = 1;
            if (canvasH < canvasW) {
                if (imageH > imageW) {
                    ratio = 1;
                } else {
                    if (imageH / imageW > canvasH / canvasW) {
                        ratio = canvasH / imageH * imageW / canvasW;
                    } else {
                        ratio = 1;
                    }
                }
            } else {
                ratio = 1;
            }
            this.avatar.zoom(ratio + value / 10).render();
        }
    }

    zoomIn() {
        const zoom = this.state.zoom;
        if (zoom < 100) {
            this.changeZoom(zoom + 1);
        }
    }

    zoomOut() {
        const zoom = this.state.zoom;
        if (zoom > 0) {
            this.changeZoom(zoom - 1);
        }
    }

    selectedImage() {
        this.avatar.fit().render();
        this.setState({
            zoom: 0,
            invisibleHolder: false,
            showDiscardBtn: true
        });
    }

    changingImage() {
        observer.publish('flagUnsavedChangeEvent', true);
        let data = this.avatar.save();
        if (data) {
            img.src = data;
        } else {
            img.removeAttribute('src');
        }
    }

    resetAvatar() {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('MSG.CONFIG.APPEARANCE_SETTING.CONFIRM_RESTORE_LOGO_DEFAULTS'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.ACCEPT'),
            cancelButtonText: t('BUTTON.DECLINE'),
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                const rightName = Route.AppearanceSettingRoute.rightName;
                const { subTenantId } = this.props;
                this.props.conmanService.putUpdateLogo(rightName, subTenantId).then(res => {
                    this.cancelAvatar();
                    let tenant: Tenant = res;
                    observer.publish('loadLogoEvent', tenant);
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('MSG.CONFIG.APPEARANCE_SETTING.RESTORE_LOGO_DEFAULTS_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    })
                });
            }
        });
    }

    saveAvatar() {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('MSG.CONFIG.APPEARANCE_SETTING.CONFIRM_RESTORE_LOGO_DEFAULTS'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.ACCEPT'),
            cancelButtonText: t('BUTTON.DECLINE'),
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                })
                const rightName = Route.AppearanceSettingRoute.rightName;
                const { subTenantId } = this.props;
                this.props.conmanService.putAddLogo(img.src, rightName, subTenantId).then(res => {
                    let tenant: Tenant = res;
                    observer.publish('loadLogoEvent', tenant);
                    this.cancelAvatar();
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('MSG.CONFIG.APPEARANCE_SETTING.RESTORE_LOGO_DEFAULTS_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    })
                });
            }
        });
    }

    openHelpDialog() {
        const { t } = this.props;
        observer.publish('openDialogEvent', {
            content: <p>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.HELP_INSTRUCTION')}</p>,
        });
    }

    cancelAvatar() {
        this.avatar.clear();
        this.setState({
            zoom: 0,
            invisibleHolder: true,
            showDiscardBtn: false
        });
        observer.publish('flagUnsavedChangeEvent', false);
    }


    render() {
        return template(this);
    }
}

const mapStateToProps = ({ conman, oidc }: Interface.StateToPropInterface) => {
    return { conman, oidc };
};

export default connect(mapStateToProps)(withTranslation()(ChangeLogo));
