import React from 'react';

const ThreeButton = () => {
    return (
        <div className="three-button">
            <button className="btn-one btn btn-primary" >
				Primary
            </button>
            <button className="btn-one btn btn-secondary" >
				Dark
            </button>
            <button className="btn-one btn btn-dark" >
				Dark
            </button>
        </div>
    )
}

export default ThreeButton;