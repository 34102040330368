import React from 'react';
import { NavLink } from 'react-router-dom';
import { WithTranslation } from 'react-i18next';

import { SummarySectionProps, DescriptionsProps, ActionLinkProps } from './interface';
import { isUndefined } from 'util';

export const Descriptions: React.FC<DescriptionsProps> = ({
    descriptionList,
    t
}) => {
    let filteredDLs = descriptionList.filter(dl => dl);
    return (
        <React.Fragment>
            {filteredDLs.map((item, index) => (
                <dl key={index} className={item.className}>
                    <dt className={item.name.className}>{t(item.name.text)}:</dt>
                    <dd className={item.value.className}>{item.value.text}</dd>
                </dl>
            ))}
        </React.Fragment>
    );
};

export const DropdownContent = ({ actions, isVisible, t }) => {
    return (
        isVisible && (
            <ul className="show-box">
                {actions.map((item, index) => {
                    return (
                        <li key={index}>
                            {item.to ? (
                                <NavLink to={item.to}>
                                    {t(item.text ? item.text : '')}
                                </NavLink>
                            ) : (
                                <span onClick={item.onClick ? item.onClick : () => {}}>
                                    {t(item.text ? item.text : '')}
                                </span>
                            )}
                        </li>
                    );
                })}
            </ul>
        )
    );
};

interface Props {
    actions: ActionLinkProps[];
    t: WithTranslation['t'];
}

export class Actions extends React.Component<Props, {}> {
    dropdownRef;
    constructor(props: Props) {
        super(props);
        this.dropdownRef = React.createRef();
    }

    render() {
        const { actions, t } = this.props;
        const visibleActions = actions.filter(item => item.isVisible || isUndefined(item.isVisible)) || [];

        return visibleActions.length && <ul className="list-action">
            {visibleActions.map((link, index) => {
                return (
                    <span key={index} ref={this.dropdownRef}>
                        <li
                            onClick={link.onClick}
                            className={
                                link.className ? link.className : 'list-action__items'
                            }
                        >
                            {link.to ? (
                                <NavLink to={link.to}>
                                    {t(link.text ? link.text : '')}
                                </NavLink>
                            ) :
                                link.text && (
                                    <span>
                                        {t(link.text)}
                                    </span>
                                )}
                            {link.hasAction && (
                                <React.Fragment>
                                    <span className="button-action" />
                                    <div className="triangle" />
                                </React.Fragment>
                            )}
                        </li>
                        {link.element}
                    </span>
                );
            })}
        </ul>
    }
}

export class SummarySection extends React.PureComponent<
SummarySectionProps,
{}
> {
    dropdownRef;
    constructor(props: SummarySectionProps) {
        super(props);
        this.dropdownRef = React.createRef();
    }

    renderActions = (visibleActions, hasDropdownRef = true) => {
        const { t } = this.props;
        return visibleActions.length && <ul className="list-action">
            {visibleActions.map((link, index) => {
                return (
                    <span key={index} ref={hasDropdownRef && this.dropdownRef}>
                        <li
                            onClick={link.onClick}
                            className={
                                link.className ? link.className : 'list-action__items'
                            }
                        >
                            {link.to ? (
                                <NavLink to={link.to}>
                                    {t(link.text ? link.text : '_blank')}
                                </NavLink>
                            ) : (
                                <span>
                                    {t(link.text ? link.text : '_blank')}
                                </span>
                            )}
                        </li>
                        {link.element}
                    </span>
                );
            })}
        </ul>
    }

    render() {
        const { title, actions, changeSection, children, t, secondActions, customSectionClass, customHeaderClass } = this.props;
        let visibleActions = actions
            ? actions.filter(
                action => action.isVisible || isUndefined(action.isVisible)
            )
            : [];
        
        let visibleSecondActions = secondActions
            ? secondActions.filter(
                secondActions => secondActions.isVisible || isUndefined(secondActions.isVisible)
            )
            : [];
        
                  
        return (
            <div className={`section-summary ${customSectionClass || ''}`}>
                <div className={`summary-header ${customHeaderClass || ''}`}>
                    {
                        visibleSecondActions && visibleSecondActions.length
                            ? (
                                <React.Fragment>
                                    <div className="d-flex justify-content-between">
                                        <p onClick={changeSection}>{t(title)}</p>
                                        {
                                            this.renderActions(visibleActions, true)
                                        }
                                    </div>
                                    {
                                        this.renderActions(visibleSecondActions, false)
                                    }
                                </React.Fragment>
                            )
                            : <React.Fragment>
                                <p onClick={changeSection}>{t(title)}</p>
                                {
                                    this.renderActions(visibleActions)
                                }
                            </React.Fragment>
                    }
                </div>
                {children}
            </div>
        );
    }
}
