import _ from 'lodash';
import { FieldConfig } from 'models/PersonalDetails';

export const getFieldConfig = (fieldConfiguration: any, fieldKey: string) => {
    const titleConfig: FieldConfig = fieldConfiguration.find(fc => fc.key == fieldKey);
    return titleConfig || new FieldConfig();
}

export const filterValuableFieldsOnly = (fieldConfigs) => {
    const defaultFieldConfig = JSON.stringify(new FieldConfig());
    const fieldConfigsProperties = Object.keys(fieldConfigs);    
    const valuableProps = fieldConfigsProperties
        .filter(propName => JSON.stringify(fieldConfigs[propName]) !== defaultFieldConfig);
    return _.pick(fieldConfigs, valuableProps);
}


export const isFieldRequired = (field: FieldConfig) => {
    return field.isVisible && field.isRequired;
}

