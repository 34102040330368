import React from 'react';
import ThisInterface  from './interface';
import PlanModel from 'models/Plan';
import Loading from 'components/Loading';
import {
    BASE_API_URL_DEVELOPMENT
} from 'constants/Constants';

import ListComponent from 'list-component';
import Language from 'models/Language';
import * as Route from 'constants/Constants';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;

    version: number;
    tableHeight: number;
}
interface Props {
    data: PlanModel;
    locale?: Language;
    auth: any;
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true,

            tableHeight: 0,
            version: 0
        }
    }

    componentDidMount() {
        const { user } = this.props.auth;
        this.setState({
            tableHeight: 350,
            version: 1
        })
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    errorHandle = (err) => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    }

    render() {
        const { data, auth, subTenantId, t } = this.props;
        const variableConfig = {
            api: BASE_API_URL_DEVELOPMENT,
            id: data.id
        };


        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.VIEW_DETAIL.PRICE_AND_AVAILABILITY_BY_CLUB')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>  
                    {this.state.version > 0 && data.id !== '' ?
                        <ListComponent
                            oidcAuth={{...auth.user, profile: {...auth.user.profile, subTenantId: subTenantId}}}
                            version={this.state.version}
                            rightName="view_plan_detail"     
                            config={{
                                presetUrl: Route.PRESET_ENDPOINT_URL,
                                timeFormat: Route.GENERAL_DATE_FORMAT,
                                langCode: i18n.language,
                                tableHeight: `${this.state.tableHeight}px`,
                                tableWidth: '100%'
                            }
                            }
                            variable={variableConfig}
                            actionEvent={null}
                            errorEvent={this.errorHandle}
                        />
                        : <Loading />}
                </div>
            </div>
        );
    }
}
export default Template;