export const DEFAULT_SUB_TENANT_ID = '35337AA7-C8D1-462F-8D45-856025D11AA7';
export const SWAL_COMMON_STYLE = {
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-dark'
    },
    buttonsStyling: false,
    focusConfirm: false
};
export const MAX_FILESIZE_LOGO_UPLOAD = 2; 
