import React from 'react';
import _ from 'lodash';

import PersonalDetails from 'components/Membership/PersonalDetails';
import ContactDetails from 'components/Membership/ContactDetails';
import AddressDetails from 'components/Membership/AddressDetails';
import ContactedDetails from 'components/Membership/ContactedDetails';
import EmergencyContact from 'components/Membership/EmergencyContact';
import HealthQuestion from 'components/Membership/HealthQuestion';
import OtherFields from 'components/Membership/OtherFields';
import { isFieldRequired } from 'util/FieldConfiguration';
import { isInputMatchRegex } from 'util/ControlUtils';
import { t } from 'util/I18nMessages';
import ThisInterface from './interface';

export const renderCollapseContents = (_this: ThisInterface) => {
    const {
        onInputChange,
        onCheckboxChange,
        onSelectOptionChange,
        onPhoneNumberChange
    } = _this;

    const {
        dateFormat,
        canChangeFirstName,
        fieldConfigs: {
            personalDetailsFieldConfigs,
            contactDetailsFieldConfigs,
            addressDetailsFieldConfigs,
            contactedDetailsFieldConfigs,
            emergencyContactFieldsConfigs,
            otherFieldsFieldsConfigs
        }
    } = _this.props;

    const {
        errors,
        personalDetails,
        contactDetails,
        addressDetails,
        contactedDetails,
        emergencyContact,
        healthQuestions,
        otherFields,
        titles,
        genders,
        languages,
        overrideEmailOptions,
        countries,
        marketingSources,
        contactMethods,
        questions,
        aiaVitalityCategories,
        recurringCardTypes
    } = _this.state;

    const datePickerFormat = dateFormat.toUpperCase();

    return [
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.PERSONAL_DETAILS',
            content: <PersonalDetails 
                isEditMode
                canChangeFirstName={canChangeFirstName}
                errors={errors}
                titles={titles}
                genders={genders}
                languages={languages}
                personalDetails={personalDetails}
                personalDetailsFieldConfigs={personalDetailsFieldConfigs}
                onInputChange={onInputChange}
                onSelectOptionChange={onSelectOptionChange}
                onCheckboxChange={onCheckboxChange}
                membershipConfiguration={{newMemberDOBFormat: datePickerFormat}}
            />,
            key: 'personalDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.CONTACT_DETAILS',
            content: <ContactDetails 
                isEditMode={true}
                errors={errors}
                overrideEmailOptions={overrideEmailOptions}
                contactDetails={contactDetails}
                contactDetailsFieldConfigs={contactDetailsFieldConfigs}
                onInputChange={onInputChange}
                onCheckboxChange={onCheckboxChange}
                onSelectOptionChange={onSelectOptionChange}
                onPhoneNumberChange={onPhoneNumberChange}
            />,
            key: 'contactDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.ADDRESS_DETAILS',
            content: < AddressDetails 
                errors={errors}
                countries={countries}
                addressDetails={addressDetails}
                addressDetailsFieldConfigs={addressDetailsFieldConfigs}
                onInputChange={onInputChange}
                onSelectOptionChange={onSelectOptionChange}
                onCheckboxChange={onCheckboxChange}
            />,
            key: 'addressDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.CONTACTED_DETAILS',
            content: <ContactedDetails
                errors={errors}
                marketingSources={marketingSources}
                contactMethods={contactMethods}
                contactedDetails={contactedDetails}
                contactedDetailsFieldConfigs={contactedDetailsFieldConfigs}
                onInputChange={onInputChange}
                onCheckboxChange={onCheckboxChange}
                onSelectOptionChange={onSelectOptionChange}
            />,

            key: 'contactedDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT',
            content: <EmergencyContact 
                errors={errors}
                emergencyContact={emergencyContact}
                emergencyContactFieldsConfigs={emergencyContactFieldsConfigs}
                onInputChange={onInputChange}
                onPhoneNumberChange={onPhoneNumberChange}
            />,
            key: 'emergencyContact'
        },
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.HEALTH_QUESTIONNAIRE',
            content: <HealthQuestion 
                errors={errors}
                questions={questions}
                healthQuestions={healthQuestions}
                onCheckboxChange={onCheckboxChange}
            />,
            key: 'healthQuestion'
        },
        {
            title: 'PAGE.MEMBERSHIPS.EDIT.CHANGE_PERSONAL_DETAILS.OTHER_FIELDS',
            content: <OtherFields 
                errors={errors}
                aiaVitalityCategories={aiaVitalityCategories}
                recurringCardTypes={recurringCardTypes}
                otherFields={otherFields}
                otherFieldsFieldsConfigs={otherFieldsFieldsConfigs}
                onInputChange={onInputChange}
                onSelectOptionChange={onSelectOptionChange}
            />,
            key: 'otherFields'
        }
    ];
};

export const fieldErrorMessage = (fieldConfig, key: string, value) => {
    let isMatchRegex = true;
    let errorMessage = '';
    let fieldKey = key;
    if (key.includes('IdNumber')) {
        fieldKey = key.replace('Number', '').trim();
    }

    isMatchRegex = isInputMatchRegex(
        _.get(fieldConfig, `${fieldKey}Config.regex`),
        value
    );
    if (isFieldRequired(_.get(fieldConfig, `${fieldKey}Config`)) && !value) {
        errorMessage = t('COMMON.FIELD_REQUIRED');
    }

    return !isMatchRegex ? false : errorMessage;
};

export const getErrorIfAny = (
    fieldConfig,
    dataDetail,
    configKey: string,
    detailKey: string,
    error = {}
) => {
    let errorList = error;

    if (
        isFieldRequired(_.get(fieldConfig, `${configKey}Config`)) &&
    !_.get(dataDetail, detailKey)
    ) {
        errorList = {
            ...error,
            [configKey]: t('COMMON.FIELD_REQUIRED')
        };
        return errorList;
    }
    return errorList;
};

export const getContactMethodErrorIfAny = (
    contactDetails,
    validKey: string,
    emptyKey: string,
    contactMethod: string
) => {
    let isProvided = false;
    let errorMessage = 's';
    isProvided =
    _.get(contactDetails, validKey) && _.get(contactDetails, emptyKey) !== '';
    if (!isProvided) {
        errorMessage = t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD.REQUIRED');
    }
    return {
        isContactMethodProvided: isProvided,
        errorMessage
    };
};
