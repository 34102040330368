import React from 'react';
import Loading from 'components/Loading';
import * as Interface from 'interfaces';

import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

interface State {
    isLoading: boolean;
}

class HomePage extends React.Component<Interface.PagePropsInterface & WithTranslation, State> {
    constructor(props: Interface.PagePropsInterface & WithTranslation) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    static getDerivedStateFromProps(nextProps: Interface.PagePropsInterface & WithTranslation) {
        const { conman } = nextProps;
        if (conman && conman.operator) {
            return {
                isLoading: false
            }
        }
        return null;
    }
    
    componentDidUpdate(prevProps: Interface.PagePropsInterface & WithTranslation) {
        const { conman } = this.props;
        if (conman && !conman.operator && conman !== prevProps.conman) {
            setTimeout(() => {
                this.setLoading(false);
            }, 20000);
        }
    }

    setLoading = (isLoading: boolean) => {
        this.setState({
            isLoading
        });
    }

    render() {
        // i18n: sample in component
        const { conman, t } = this.props;
        const { isLoading } = this.state;

        return (
            <div className="app-wrapper main-page">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div>
                        {conman && conman.operator ? (
                            <h2 className="heading">
                                {t('PAGE.HOME.WELCOME', {name: conman.operator.fullName})}
                            </h2>
                        ) : (
                            <></>
                        )}
                        <p className="body-text padding-top15">
                            {t('PAGE.HOME.INSTRUCTION_LINE_1')}
                        </p>
                        <p className="body-text">
                            {t('PAGE.HOME.INSTRUCTION_LINE_2')}
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ oidc, conman }: Interface.StateToPropInterface) => {
    return { oidc, conman };
};

const mapDispatchToProps = () => ({});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(HomePage))
