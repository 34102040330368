import CommonService from './common.service';
import axios from 'axios';
import * as API from 'constants/Constants';
import * as Route from 'pages/RouteLoader';
import { getTenantId } from 'util/ControlUtils';

export default class MembershipService {
    commonService: CommonService;

    constructor() {
        this.commonService = new CommonService();
    }

    getAddonsForAddMembership(subTenantId, clubId, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_GET_ADDON_OF_MSB.replace('{0}', clubId);
        const tenantId = getTenantId();
        return axios
            .get(url.replace('{0}', tenantId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSubTenants(rightName) {
        const headers = this.commonService.getHeaderWithoutSubTenant(false, rightName);
        const url = API.API_MBS_GET_SUBTENANT;
        const tenantId = getTenantId();
        return axios
            .get(url.replace('{0}', tenantId), { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getClubs(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        const url = API.API_MBS_GET_CLUBS.replace('{0}', `${1000}`);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getApplicationTypes(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        const url = API.API_MBS_GET_APPLICATION_TYPES.replace('{0}', `${1000}`);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getSalePeople(subTenantId, clubId, rightName) {
        const headers = {
            ...this.commonService.getHeader(false, rightName),
            'x-clubid': clubId
        }
        headers["x-subtenant-id"] = subTenantId;
        const url = API.API_MBS_GET_SALE_PEOPLE.replace('{0}', `${1000}`);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getPackagePlans(subTenantId, packagePlanId, startDate, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        let queryString = '';
        if (rightName === Route.ChangeMembershipRoute.rightName) {
            queryString = '&changeMembership=true';
        }
        const url = API.API_MBS_GET_PACKAGE_PLANS;
        return axios
            .get(url.replace('{0}', packagePlanId).replace('{1}', `${1000}`).replace('{2}', `${startDate}`) + queryString, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getFieldsConfiguration(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        const url = API.API_MBS_GET_FIELDS_ADD_MEMBER.replace('{0}', `${1000}`);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getMemberTitles(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_MEMBER_TITLES, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getMemberGenders(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_MEMBER_GENDERS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getMemberLanguagePreferences(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_MEMBER_LANGUAGE_PREFERENCES, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getOverrideEmailOptions(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_OVERRIDE_EMAIL_OPTIONS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getCountries(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_COUNTRIES, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getContactMethods(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_CONTACT_METHODS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getMarketingSources(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        const url = API.API_MBS_GET_MARKETING_SOURCE.replace('{0}', `${1000}`);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getAIAVitalityCategories(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_AIA_VITALITY_CATEGORY, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getRecurringCardTypes(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_RECURRING_CARD_TYPE, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getDuplicateMembers(subTenantId, lastName, birthDate, postCode, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;

        const url = API.API_MBS_GET_DUPLICATE_MEMBERS
            .replace("{0}", "1000")
            .replace("{1}", lastName)
            .replace("{2}", birthDate)
            .replace("{3}", postCode);

        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }


    getBanks(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_MEMBER_BANKS, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getCardTypes(subTenantId, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .get(API.API_MBS_GET_MEMBER_CARD_TYPES, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    postMember(subTenantId, data, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .post(API.API_MBS_POST_MEMBER, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    putMember(id, subTenantId, data, rightName) {
        const headers = this.commonService.getHeader(false, rightName);
        headers["x-subtenant-id"] = subTenantId;
        return axios
            .put(API.API_MBS_PUT_MEMBER.replace('{id}', id), data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    getMemberById(subTenantId, id, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const url = API.API_MBS_GET_MEMBER_BY_ID.replace('{id}', id);
        return axios
            .get(url, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    changePersonalDetails(subTenantId, memberId, data, rightName) {
        const headers = this.commonService.getHeaderWithSubtenantAndRightName(subTenantId, rightName, false);
        const api = API.API_MBS_CHANGE_PERSONAL_DETAILS.replace('{id}', memberId);

        return axios
            .put(api, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err));
    }

    updatePaymentDetails(memberId, subTenantId, data) {
        const headers = this.commonService.getHeaderWithSubtenant(subTenantId);
        const url = API.API_MBS_GET_PAYMENT_DETAIL.replace('{id}', memberId);

        return axios
            .put(url, data, { headers })
            .then(res => res.data)
            .catch(err => this.commonService.handleErrorHttp(err))
    }
}