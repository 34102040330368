import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

class CurrentI18n extends React.Component<WithTranslation, {}> {
    static instance: CurrentI18n;

    componentWillMount() {
        if (!CurrentI18n.instance)
            CurrentI18n.instance = this;
    }

    render() {
        return false;
    }
}

export default withTranslation()(CurrentI18n);

export function translate() {
    return CurrentI18n.instance && CurrentI18n.instance.props.t;
}

export function t(key, data?) {
    return translate() && translate()(key, data);
}
