import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color'; // Reference from casesandberg.github.io/react-color/#examples
import 'react-rangeslider/lib/index.css';
import ThisInterface from './interface';
import { t } from 'util/I18nMessages';

const template = (_this: ThisInterface) => {
    const {
        displayColorPicker,
        defaultTheme,
        previousTheme,
        recommendationThemes,
        primaryColor,
        secondaryColor,
        historyThemes,
        hasThemeChange
    } = _this.state;

    const { t } = _this.props;

    const styles = reactCSS({
        default: {
            primaryColor: {
                cursor: 'pointer',
                background: `rgba(${primaryColor.r}, ${primaryColor.g}, ${
                    primaryColor.b
                }, ${primaryColor.a})`
            },
            secondaryColor: {
                background: `rgba(${secondaryColor.r}, ${secondaryColor.g}, ${
                    secondaryColor.b
                }, ${secondaryColor.a})`
            }
        }
    });
    return (
        <>
            <p className="title">
                {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.INSTRUCTION')}
            </p>
            <div className="pickerColor">
                <div className="pickerColor__change">
                    <div
                        style={styles.primaryColor}
                        className="change-color"
                        onClick={() => _this.handleOpenColorPicker('primary')}
                    >
                        <span className="icon-edit-ico" />
                    </div>
                    <div
                        style={styles.secondaryColor}
                        className="change-color"
                        onClick={() => _this.handleOpenColorPicker('secondary')}
                    >
                        <span className="icon-edit-ico" />
                    </div>
                </div>
                {displayColorPicker === 'primary' ? (
                    <div className="popover">
                        <div
                            className="cover"
                            onClick={() => _this.handleOpenColorPicker('')}
                        />
                        <SketchPicker
                            color={primaryColor}
                            onChange={color => _this.handlePrimaryColorChange(color)}
                        />
                    </div>
                ) : null}
                {displayColorPicker === 'secondary' ? (
                    <div className="popover secondary">
                        <div
                            className="cover"
                            onClick={() => _this.handleOpenColorPicker('')}
                        />
                        <SketchPicker
                            color={secondaryColor}
                            onChange={color => _this.handleSecondaryColorChange(color)}
                        />
                    </div>
                ) : null}
                <div className="pickerColor__text">
                    <p>
                        {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.SELECT_COLOR_INSTRUCTION_1')}
                    </p>
                    <p>
                        {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.SELECT_COLOR_INSTRUCTION_2')}
                    </p>
                    <p>
                        {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.SELECT_COLOR_INSTRUCTION_3')}
                    </p>
                </div>
            </div>
            <div className="section-combo-color">
                <p className="title-content">
                    {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.SUGGESTED_COLOURS')}
                </p>
                <div className="exColor">
                    {defaultTheme && (
                        <div className="exColor__default exColor__box active">
                            <p>
                                {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.DEFAULT_COLOUR')}
                            </p>
                            <div
                                className="combo-color"
                                onClick={() =>
                                    _this.handleChangeTheme(
                                        defaultTheme.primaryColor,
                                        defaultTheme.secondaryColor
                                    )
                                }
                            >
                                <div style={{ backgroundColor: defaultTheme.primaryColor }} />
                                <div style={{ backgroundColor: defaultTheme.secondaryColor }} />
                            </div>
                        </div>
                    )}
                    {recommendationThemes && recommendationThemes.length > 0 && (
                        <ThemeList
                            items={recommendationThemes}
                            isRecommendation={true}
                            _this={_this}
                        />
                    )}
                </div>
            </div>
            <div className="section-combo-color">
                <p className="title-content">
                    {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.CUSTOMIZED_COLOURS')}
                </p>
                <div className="exColor">
                    <div className="exColor__default exColor__box active">
                        <p>
                            {t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.PREVIOUS_COLOUR')}
                        </p>
                        {previousTheme ? (
                            <div
                                className="combo-color"
                                onClick={() =>
                                    _this.handleChangeTheme(
                                        previousTheme.primaryColor,
                                        previousTheme.secondaryColor
                                    )
                                }
                            >
                                <div style={{ backgroundColor: previousTheme.primaryColor }} />
                                <div
                                    style={{ backgroundColor: previousTheme.secondaryColor }}
                                />
                            </div>
                        ) : (
                            <div className="combo-color">
                                <div />
                                <div />
                            </div>
                        )}
                    </div>
                    {historyThemes && historyThemes.length > 0 && (
                        <ThemeList items={historyThemes} _this={_this} />
                    )}
                </div>
            </div>
            <div className="saveColor">
                <button onClick={_this.resetTheme} className="btn btn-dark">
                    {t('BUTTON.RESTORE_DEFAULTS')}
                </button>
                <button
                    disabled={!hasThemeChange}
                    onClick={_this.saveTheme}
                    className="btn btn-primary"
                >
                    {t('BUTTON.SAVE')}
                </button>
            </div>
        </>
    );
};

const ThemeList = ({ _this, isRecommendation = false, items }) => {
    return (
    <>
      {items &&
        items.map(item => {
            return (
                <ThemeItem
                    key={Math.random()}
                    _this={_this}
                    isRecommendation={isRecommendation}
                    index={items.indexOf(item) + 1}
                    {...item}
                />
            );
        })}
    </>
    );
};

const ThemeItem = ({
    _this,
    isRecommendation,
    index,
    primaryColor,
    secondaryColor,
}) => {
    return (
        <>
            <div
                className="exColor__box"
                onClick={() => _this.handleChangeTheme(primaryColor, secondaryColor)}
            >
                <p>
                    {isRecommendation
                        ? t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.COLOUR_COMBO_VALUE')
                        : t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_COLOR.CUSTOMIZED_COLOURS')}{' '}
                    {index}
                </p>
                <div className="combo-color">
                    <div style={{ backgroundColor: primaryColor }} />
                    <div style={{ backgroundColor: secondaryColor }} />
                </div>
            </div>
        </>
    );
};

export default template;
