import { Component } from 'react';
import { connect } from 'react-redux';

import * as Interface from 'interfaces';

import template from './template';
import * as Route from 'pages/RouteLoader';
import _ from 'lodash';
import { OptionsModel } from 'pages/SystemPage/AddFieldsConfiguration/interface';
import Tenant from 'models/Tenant';
import observer from 'util/Observer';
import { getSubTenantId } from 'util/ControlUtils';

interface State {
    isLoading: boolean;
    countries: OptionsModel[];
    selectedCountries: OptionsModel | null;
    subTenantId: string;
}

class AppearanceSettingPage extends Component<Interface.PagePropsInterface, State> {

    constructor(props: Interface.PagePropsInterface) {
        super(props);

        this.state = {
            isLoading: true,
            countries: [],
            selectedCountries: null,
            subTenantId: ''
        };
    }
    componentDidMount() {
        const rightName = Route.AppearanceSettingRoute.rightName;
        this.props.conmanService.getSubTenantConfig(rightName).then(res => {
            if (res && res.data && !_.isEmpty(res.data)) {
                const countries = this.transformOptions(res.data);
                const selectedCountries = countries.find(e => e.value === getSubTenantId());
                this.setState({
                    countries: countries,
                    selectedCountries: selectedCountries ? selectedCountries : countries[0],
                    subTenantId: selectedCountries ? selectedCountries.value : countries[0].value
                });
            }
            this.setState({
                isLoading: false
            });
        })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    }

    componentWillUnmount() {
        this.loadLogo(getSubTenantId());
    }

    loadLogo = (subTenantId: string) => {
        const rightName = Route.AppearanceSettingRoute.rightName;
        this.props.conmanService
            .getSubTenant(subTenantId, rightName)
            .then(data => {
                const tenant: Tenant = data;
                observer.publish('loadLogoEvent', tenant);
            });
    }

    handleChangeValue = (value: OptionsModel, key: string) => {
        this.setState(
            prevState => {
                return {
                    ...prevState,
                    [key]: value,
                    subTenantId: value.value
                };
            }, () =>{
                this.loadLogo(this.state.subTenantId);
            }
        );
    };

    transformOptions = (data: { id: string; name: string }[]) => {
        return data.map(item => {
            return {
                value: item.id,
                label: item.name,
                ...item
            };
        });
    };

    openTabs(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-pane");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("nav-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName)!.style.display = "block";
        evt.currentTarget.className += " active";
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ conman, oidc }: Interface.StateToPropInterface) => {
    return { conman, oidc };
};

export default connect(mapStateToProps)(AppearanceSettingPage);
