import React from 'react';
import _ from 'lodash';
import { TFunction } from 'i18next';
import Loading from 'components/Loading';
import { DisplayDateFormat, CommaFormatted } from 'util/ControlUtils';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import CustomTable from 'components/Table';
import { ThisInterface } from './interface';
import moment from 'moment';

const paymentSummaryHeaders = [
    {
        key: 'startDate',
        title: 'Date'
    },
    {
        key: 'typeName',
        title: 'Type',
        className: 'type-name'
    },
    {
        key: 'method',
        title: 'Method'
    },
    {
        key: 'status',
        title: 'Status',
        className: 'status-text'
    },
    {
        key: 'amount',
        title: 'Amount',
        className: 'amount-number'
    }
];

const ButtonItem = ({
    type = 'primary',
    disabled = false,
    title,
    onClick = () => { }
}) => {
    return (
        <li>
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const Field = ({ label, value }) => {
    return (
        <div className="row">
            <div className="col-6">
                <p>{label}</p>
            </div>
            <div className="col-6">{value}</div>
        </div>
    );
};

const summaryFreezeDetails = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { freezeDetails } = _this.state;

    if (!freezeDetails) return null;

    const fields = [{
        label: t('PAGE.REMOVE_FREEZE.LABEL.START_DATE'),
        value: DisplayDateFormat(freezeDetails.startDate, GENERAL_DATE_FORMAT)
    }, {
        label: t('PAGE.REMOVE_FREEZE.LABEL.END_DATE'),
        value: DisplayDateFormat(freezeDetails.endDate, GENERAL_DATE_FORMAT)
    }];

    return (
        <div className="section-content col-8">
            <p>{t('PAGE.REMOVE_FREEZE.SUMMARY_INFORMATION_ABOUT_FREEZE_BEING_REMOVED')}</p>
            <div className="freeze-details-container">
                {fields.map((field, index) => (
                    <Field key={`freeze_details_field_${index}`} {...field} />
                ))}
            </div>
        </div>
    );
};

const renderTableContent = (paymentSummary: ThisInterface['state']['paymentSummary'], t: TFunction) => {
    return _.isEmpty(paymentSummary) ?
        (
            <tr className="no-data text-center"><td colSpan={5}>{t('COMMON.NO_DATA')}</td></tr>
        )
        : paymentSummary.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{DisplayDateFormat(item.startDate, GENERAL_DATE_FORMAT)}</td>
                    <td>{item.typeName}</td>
                    <td>{item.method}</td>
                    <td>{item.status}</td>
                    <td>{CommaFormatted(item.amount)}</td>
                </tr>
            );
        });
};

const renderPaymentSummaryTable = (_this: ThisInterface) => {
    const { paymentSummary, freezeDetails } = _this.state;
    const { t } = _this.props;
    if (!freezeDetails) return null;

    const tillDate = moment(
        freezeDetails.startDate
    ).add(5, 'months').startOf('month').format(GENERAL_DATE_FORMAT);

    return (
        <React.Fragment>
            <div className="col-12">
                <p>
                    {t(
                        'PAGE.FREEZE_MEMBERSHIP.MEMBER_PAYMENT_SUMMARY_DESCRIPTION',
                        { month: 6, date: tillDate }
                    )}
                </p>
            </div>
            <CustomTable
                renderingCondition
                headers={paymentSummaryHeaders}
                tableContent={renderTableContent(paymentSummary, _this.props.t)}
                maxHeigth="auto"
                className="freeze-payment-summary-table"
            />
        </React.Fragment>
    );
};

export const renderActionButtons = (_this: ThisInterface) => {
    const { t } = _this.props;
    return (
        <div className="col-12 mt-4">
            <ul className="list-btn-action">
                <ButtonItem
                    onClick={_this.removeFreeze}
                    title={t('BUTTON.FINISH')}
                />
                <ButtonItem
                    onClick={_this.props.onCancel}
                    title={t('BUTTON.CANCEL')}
                    type="dark"
                />
            </ul>
        </div>
    );
};

export const template = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { isLoading, freezeDetails } = _this.state;
    return isLoading ?
        <Loading />
        : (
            <div className="remove-freeze">
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-title mb-3">
                            {t('PAGE.FREEZE_MEMBERSHIP.REMOVE_EXISTING_FREEZE')}
                        </h3>
                    </div>
                </div>
                <div className="row">
                    {freezeDetails && summaryFreezeDetails(_this)}
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-title mb-3">
                            {t('PAGE.REMOVE_FREEZE.MEMBER_PAYMENT_INFORMATION')}
                        </h3>
                    </div>
                </div>
                <div className="row">
                    {renderPaymentSummaryTable(_this)}
                </div>
                <div className="row">
                    {renderActionButtons(_this)}
                </div>
            </div>
        );
};