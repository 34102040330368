import React, { useState, useEffect, Fragment } from 'react';
import Loading from 'components/Loading';
import { t } from 'util/I18nMessages';
import PaginationModel from 'models/Pagination';
import Pagination from 'components/Pagination';
import Select from 'react-select';
import FormItem, { getIdOrUndefined } from 'components/FormV2';

export interface TableColumn {
    labelKey: string;
    sortKey: string;
    sortable: boolean;
    className?: string;
}

interface TableProps {
    columns: TableColumn[];
    datas: any[];
    pagination: PaginationModel;
    isLoading: boolean;
    filtersField: any[];
    filtersForm: {
        props: any;
        filter: any;
        label: string;
    }[];

    onPaginationChanged: (pageIndex, pageSize) => void;
    onPageSortChanged: (sorts) => void;
    onFilterChanged: (queryString) => void;
}

const rowsPerPageOptions = [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 }
];

const TableWithPaginationSortFilter: React.FunctionComponent<TableProps> = ({
    columns,
    datas,
    pagination,
    filtersForm,
    filtersField,
    isLoading,
    onPaginationChanged,
    onPageSortChanged,
    onFilterChanged
}) => {

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [formData, setFormData] = useState({});
    const [sorts, setSorts] = useState([] as { sortKey: string; direction: string; order: number }[]);

    useEffect(() => {
        setPageSize(pagination.pageSize);
    }, [pagination.pageSize]);

    const changePageIndex = (pageIndex) => {
        setPageIndex(pageIndex);
        onPaginationChanged(pageIndex, pageSize);
    }

    const changePageSize = (pageSize) => {
        setPageSize(pageSize); 
        setPageIndex(1);
        onPaginationChanged(pageIndex, pageSize);
    }

    const hasSort = (sortKey, direction) => {
        const currentSort = sorts.find(e => {
            if (e) {
                return e.sortKey === sortKey && e.direction === direction;
            }
            return false;
        });
        return currentSort;
    }

    const changeSort = (order, sortKey, direction) => {
        const currentSort = hasSort(sortKey, direction);
        if (currentSort) {
            const newSorts = sorts.filter(e => e.sortKey !== sortKey);
            const newSortsWithOrder = newSorts.sort((a,b) => a.order > b.order ? 1 : -1);
            setSorts(newSortsWithOrder);
            onPageSortChanged(newSortsWithOrder.map(e => `${e.sortKey}=${e.direction}`).join(';'));
        } else {
            const removeSorts = sorts.filter(e => e.sortKey !== sortKey);
            const newSorts = [...removeSorts, { order: order, sortKey: sortKey, direction: direction }];
            const newSortsWithOrder = newSorts.sort((a,b) => a.order > b.order ? 1 : -1);
            setSorts(newSortsWithOrder);
            onPageSortChanged(newSortsWithOrder.map(e => `${e.sortKey}=${e.direction}`).join(';'));
        }
    }

    const setStateByProperty = (nameData, valueHtml) => {
        setFormData({...formData, [nameData]: valueHtml});
    }

    const onChangeHandle = (nameData, valueHtml, type) => {
        switch (type) {
            case 'text':
                setStateByProperty(nameData, valueHtml);
                break;
            case 'select':
                setStateByProperty(nameData, { id: valueHtml, name: '' });
                break;
            default:
                break;
        }
    }

    const getValue = (field) => {
        switch (field.props.type) {
            case 'text':
                return formData[field.filter.queryKey] ? formData[field.filter.queryKey] : '';
            case 'select':
                return formData[field.filter.queryKey] ? getIdOrUndefined(formData[field.filter.queryKey]) : '';
        }

        return undefined;
    }

    const submitFilter = () => {
        const queryArr = filtersForm.reduce((acc, cur) => [
            ...acc, 
            getValue(cur) ? {...cur.filter, queryValue: getValue(cur)} : undefined
        ], [] as { queryKey: string; operation: string; queryType: string; queryValue: string }[]);
        onFilterChanged(JSON.stringify( { and: queryArr.filter(e => e)} ));
    }

    const clearFilter = () => {
        setFormData({});
        onFilterChanged('');
    }

    return (
        <div className="row table-wrapper">
            <div className="table-list w-100">
                {filtersForm.length > 0 && <div className="table-filter">
                    <div className="left-filter">
                        {filtersForm.map((field, key) => {
                            const props = {...field.props, data: getValue(field)};
                            return <Fragment key={key}>
                                <span className="filter-input-label">{t(field.label)}</span>
                                <FormItem
                                    {...props}
                                    onChangeHandle={(name, value) => onChangeHandle(field.filter.queryKey, value, field.props.type)}
                                    fields={filtersField}
                                />
                            </Fragment>
                        }
                        )}
                        <div>
                            <button className="btn btn-primary" onClick={() => submitFilter()}><i className="icon icon-filter"></i></button>
                            <button className="btn btn-dark" onClick={() => clearFilter()}><i className="icon icon-delete"></i></button>
                        </div>
                    </div>
                </div>}
                <div className="table-responsive">
                    <table id="data-table" className="table">
                        <thead>
                            <tr>
                                {columns.map((e, key) =>
                                    <th key={key} className={e.className}>
                                        <span>{t(e.labelKey)}</span>
                                        {e.sortable && 
                                            <div className="section-filter">
                                                <span className="sort-control">
                                                    <span onClick={() => changeSort(key, e.sortKey, 'asc')}>
                                                        <i className={`icon icon-arr-sort icon-asc-sort ${hasSort(e.sortKey, 'asc') ? 'active' : ''}`}></i>
                                                    </span>
                                                    <span onClick={() => changeSort(key, e.sortKey, 'desc')}>
                                                        <i className={`icon icon-arr-sort icon-desc-sort ${hasSort(e.sortKey, 'desc') ? 'active' : ''}`}></i>
                                                    </span>
                                                </span>
                                            </div>}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="tbody-height">
                            {isLoading && <tr><td className="text-center" colSpan={columns.length}><Loading /></td></tr>}
                            {datas.map((row, key) =>
                                <tr key={key}>
                                    {row.map((e, rowKey) =>
                                        <td key={rowKey}>{e}</td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="table-paging">
                    <span className="title-paging">
                        {t('LIST.ROWS_PER_PAGE')}
                    </span>
                    <div className="form-group">
                        <div className="custom-select-saas">
                            <Select
                                onChange={event => changePageSize(event.value) }
                                className="rows-per-page"
                                classNamePrefix="select"
                                menuPlacement="top"
                                value={rowsPerPageOptions.find(
                                    e => e.value === pageSize
                                )}
                                isClearable={false}
                                isSearchable={false}
                                name="rowsPerPage"
                                options={rowsPerPageOptions}
                            />
                        </div>
                    </div>
                    <Pagination
                        onPageChange={(page) => changePageIndex(page)}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={pagination.totalItemsCount}
                        activePage={pageIndex}
                    />
                    <div className="paging-detail">
                        <span className="page-display">
                            {(pageIndex-1) * pageSize + 1} -{' '}
                            {Math.min(
                                pageIndex * pageSize,
                                pagination.totalItemsCount
                            )}
                        </span>
                        <span> {t('LIST.OF')} </span>
                        <span className="page-total">{pagination.totalItemsCount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableWithPaginationSortFilter;
