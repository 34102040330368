import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { Accordion } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Fab from '@material-ui/core/Fab';

import { FormInput, FormSelect } from 'components/Form';
import Loading from 'components/Loading';
import { transformOptions } from 'util/ControlUtils';

import { RightItem, FeatureItem, ReSourceItem } from './components';
import ThisInterface from './interface';

const loadSubTenant = (_this: ThisInterface) => {
    const className = _this.state.selectSubTenant ? ' active' : '';

    const subTenantList = _this.state.selectSubTenant
        ? _this.state.subTenantList.map((item, index: number) => {
            return (
                <li key={index}>
                    <div className="form-group switch-container">
                        <label className="switch">
                            <input
                                type="checkbox"
                                name={item.name}
                                value={item.id}
                                checked={item.active}
                                onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                    _this.handleSelectSubTenant(e!, index)
                                }
                            />
                            <span className="slider round" />
                        </label>
                        <span className="label-select-switch">{item.name}</span>
                    </div>
                </li>
            );
        })
        : null;

    return (
        <div className={`sub-tenant-content${className}`}>
            <Fab
                size="small"
                color="primary"
                className="expand-collapse-button"
                onClick={_this.onCollapsed}
            >
                {_this.state.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </Fab>
            <Collapse in={_this.state.isExpanded} className="expand-collapse-panel">
                <ul className="sub-tenant-list">{subTenantList}</ul>
            </Collapse>
        </div>
    );
};

const renderRightList = (_this: ThisInterface, rights) => {
    if (rights && rights.length > 0) {
        return rights.map(right => {
            return (
                <RightItem
                    key={right.id}
                    right={right}
                    handleSelectRights={_this.handleSelectRights}
                />
            );
        });
    }
    return <></>;
};

const renderResourceList = (_this: ThisInterface, resources, indexGroup) => {
    if (resources && resources.length > 0) {
        return resources.map((source, indexResource) => {
            return (
                <ReSourceItem
                    key={indexResource}
                    source={source}
                    indexGroup={indexGroup}
                    indexResource={indexResource}
                    handleSelectAllResource={(indexGr: number, indexRe: number) =>
                        _this.handleSelectDeselectAllResource(indexGr, indexRe, true)
                    }
                    handleDeselectAllResource={(indexGr: number, indexRe: number) =>
                        _this.handleSelectDeselectAllResource(indexGr, indexRe, false)
                    }
                    listRight={renderRightList(_this, source.rights)}
                />
            );
        });
    }
    return <></>;
};

const renderFeatureList = (_this: ThisInterface, featuresList) => {
    if (featuresList && featuresList.length > 0) {
        return featuresList.map((feature, indexGroup) => {
            return (
                <FeatureItem
                    key={indexGroup}
                    indexGroup={indexGroup}
                    titleGroup={<h4>{feature.name}</h4>}
                    resouceList={renderResourceList(_this, feature.resources, indexGroup)}
                    handleSelectAllGroup={(index: number) =>
                        _this.handleSelectDeselectAllGroup(index, true)
                    }
                    handleDeselectAllGroup={(index: number) =>
                        _this.handleSelectDeselectAllGroup(index, false)
                    }
                />
            );
        });
    }
    return <></>;
};

const loadFeatureList = (_this: ThisInterface) => {
    const { featuresList, isLoadingFeature } = _this.state;
    const { t } = _this.props;
    return (
        <div className="feature-list">
            {isLoadingFeature ? (
                <Loading />
            ) : (
                <dl>
                    <dt>{t('PAGE.SYSTEM.ROLES.ADD_EDIT.FEATURES')}</dt>
                    <dd>
                        <Accordion>{renderFeatureList(_this, featuresList)}</Accordion>
                    </dd>
                </dl>
            )}
        </div>
    );
};

const template = (_this: ThisInterface) => {
    const {
        roleName,
        tenant,
        type,
        roleTypes,
        isValidSubTenant,
        isDirtyRoleName,
        isEditMode,
        disabled,
        isLoadingType
    } = _this.state;

    const { t } = _this.props;

    const roleTypeList = transformOptions(roleTypes);

    const errMsg = !isValidSubTenant ? (
        <span className="text-danger input-error">
            {t('PAGE.SYSTEM.ROLES.ADD_EDIT.THE_ROLE_HAS_TO_ASSIGNED_TO_AT_LEAST_ONE_COUNTRY')}
        </span>
    ) : null;

    return (
        <section className="add-role system-page">
            <p className="title-content">
                {isEditMode
                    ? t('PAGE.SYSTEM.ROLES.ADD_EDIT.TITLE_EDIT')
                    : t('PAGE.SYSTEM.ROLES.ADD_EDIT.TITLE_ADD') }
            </p>
            <div className="form-add-role">
                <dl className="form-table clearfix">
                    <dt>
                        {t('PAGE.SYSTEM.ROLES.ADD_EDIT.ROLE_NAME')}{' '}
                        <span className="text-primary bold">&nbsp;*</span>
                    </dt>
                    <dd>
                        <FormInput
                            id="role-name"
                            inputClass="form-control"
                            placeholder={t('PAGE.SYSTEM.ROLES.ADD_EDIT.ROLE_NAME')}
                            value={roleName}
                            maxlength={50}
                            isDirty={isDirtyRoleName}
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                _this.handleChangeText(e!, 'roleName')
                            }
                            errorMsg={t(
                                'COMMON.NOT_EMPTY',
                                { key: t('PAGE.SYSTEM.ROLES.ADD_EDIT.ROLE_NAME') }
                            )}
                            require
                        />
                        <Tooltip
                            title={t('PAGE.SYSTEM.ROLES.ADD_EDIT.ROLE_NAME_INSTRUCTION')}
                            placement="top-start"
                        >
                            <IconButton aria-label="info" className="btn-tooltip">
                                <InfoIcon className="infoIcon" />
                            </IconButton>
                        </Tooltip>
                    </dd>
                </dl>
                <dl className="form-table clearfix">
                    <dt>{t('PAGE.SYSTEM.ROLES.ADD_EDIT.TENANT')}</dt>
                    <dd>
                        <FormInput
                            id="tenant"
                            inputClass="form-control"
                            placeholder={t('PAGE.SYSTEM.ROLES.ADD_EDIT.TENANT')}
                            value={tenant}
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                                _this.handleChangeText(e!, 'tenant')
                            }
                            disabled
                        />
                    </dd>
                </dl>

                <dl className="form-table clearfix">
                    <dt>{t('PAGE.SYSTEM.ROLES.ADD_EDIT.TYPE')}</dt>
                    <dd>
                        <FormSelect
                            options={roleTypeList}
                            value={roleTypeList.find(item => item.value === type)}
                            onChange={_this.handleChangeType}
                            isLoading={isLoadingType}
                        />
                    </dd>
                </dl>

                <dl className="form-table clearfix">
                    <dt>{t('PAGE.SYSTEM.ROLES.ADD_EDIT.SUB_TENANT')}</dt>
                    <dd>
                        <div className="form-group">
                            <label className="switch">
                                <input
                                    className="form-control"
                                    name="subTenant"
                                    type="checkbox"
                                    checked={_this.state.selectSubTenant}
                                    onChange={_this.handleChangeSubTenant}
                                />
                                <span className="slider round" />
                            </label>
                            <div>{errMsg}</div>
                        </div>
                    </dd>
                </dl>
                {loadSubTenant(_this)}
                {loadFeatureList(_this)}
                <div className="row-btn">
                    <button
                        className="btn btn-primary"
                        onClick={_this.handleSaveRole}
                        disabled={disabled}
                    >
                        {t('BUTTON.SAVE')}
                    </button>
                    <button className="btn btn-dark" onClick={_this.handleCancel}>
                        {t('BUTTON.CANCEL')}
                    </button>
                </div>
            </div>
        </section>
    );
};

export default template;
