import React from 'react';

interface Props {
    title: string;
    isOpen: boolean;
    isLink?: boolean;
    content?: React.ReactNode;
    description?: string;
    className?: string;
    maxHeight?: string;
    openCollapsedContent?: () => void;
    openLink?: () => void;
}

const CollapseButton: React.FunctionComponent<Props> = ({
    title,
    description = '',
    isOpen,
    isLink,
    className,
    maxHeight = '200px',
    openCollapsedContent,
    openLink,
    content
}) => {
    const activeContent = isOpen ? 'active' : '';
    const activeMaxHeight = isOpen ? maxHeight : '';
    return (
        <div className={`${className} collapse-saas`}>
            <button
                className={`collapsible ${activeContent}`}
                onClick={openCollapsedContent}
            >
                {isLink ? <a onClick={openLink}>{title}</a> : title}
            </button>
            <div className={`content ${activeContent}`} style={{ maxHeight: activeMaxHeight }}>
                <div className="info-content">
                    {description && (
                        <p>
                            <i>{description}</i>
                        </p>
                    )}
                    <div className="section-down">{content}</div>
                </div>
            </div>
        </div>
    );
};

CollapseButton.displayName = 'CollapseButton';

export default CollapseButton;
