import React from 'react';
import { Input } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { th, zhCN, enUS } from 'date-fns/locale';
import moment from 'moment';

import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';

registerLocale('en-US', enUS);
registerLocale('th', th);
registerLocale('zh-cn', zhCN);

interface Props extends FormItemInterface {
    locale?: string;
    selected: Date | undefined;
    isClearable?: boolean;
}
const CalendarGroup = React.memo((props: Props) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        className = 'input-group mb-3',
        locale = 'en-US',
        attribute,
        selected,
        onChangeHandle,
        htmlLabel,
        isClearable = false
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event);
    };

    const isAllowBackDated = attribute && attribute.isAllowBackDated;
    const isAllowFutureDate = attribute && attribute.isAllowFutureDate;
    const minDate = attribute && attribute.minDate;
    const maxDate = attribute && attribute.maxDate;
    const allowedDates = attribute && attribute.allowedDates;

    function allowDate(date) {
        if(allowedDates) {
            return allowedDates.find(d => moment(date).isSame(d));
        }
        if (isAllowBackDated && isAllowFutureDate) {
            return true;
        }
        if (isAllowFutureDate) {
            return moment() < date;
        }
        if (isAllowBackDated) {
            return moment() > date;
        }
        if(minDate) {
            return moment(date).isSameOrAfter(moment(minDate));
        }
        if(maxDate) {
            return moment(date).isSameOrBefore(moment(maxDate));
        }
        return true;
    }

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    let _calendar;
    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={`${className} ${errorClass} form-date-picker`}>
                <DatePicker
                    dateFormat={GENERAL_DATE_FORMAT.toLowerCase().replace('mm', 'MM')}
                    popperPlacement={'bottom'}
                    {...attribute}
                    ref={c => (_calendar = c)}
                    id={fieldLabel}
                    locale={locale}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable={isClearable}
                    placeholderText={
                        attribute && attribute.dateFormat && attribute.dateFormat !== ''
                            ? attribute.dateFormat.toLowerCase()
                            : GENERAL_DATE_FORMAT.toLowerCase()
                    }
                    selected={selected ? selected : null}
                    onChange={onChange}
                    customInput={
                        <Input
                            maxLength={fieldValidation.maxLength}
                            id={htmlFor}
                            name={fieldName}
                            calendar={_calendar}
                        />
                    }
                    className="calendar-fluid"
                    popperModifiers={{
                        offset: {
                            enabled: true,
                            offset: '1px, 1px'
                        },
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                            boundariesElement: 'viewport'
                        }
                    }}
                    filterDate={date => {
                        return allowDate(date);
                    }}
                />
                <button
                    className="icon-calendar-ico icon--calendar"
                    onClick={() => _calendar.setOpen(true)}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
CalendarGroup.displayName = 'CalendarGroup';

export default CalendarGroup;
