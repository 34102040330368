import * as React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'react-router-redux';
import userManager from 'util/IdentityOidc';
import * as DefaultConstants from 'constants/DefaultConstants';
import { setRequiredChangePassword } from 'util/ControlUtils';

import observer from 'util/Observer';
import axios from 'axios';
import store from 'reduxs/index';
import { setProfileAuth } from 'reduxs/actions/Auth';

interface Props {
    dispatch: Function;
}

interface UserInterface {
    access_token: string;
    expires_at: number;
    id_token: string;
    profile: {
        amr: string[];
        auth_time: number;
        idp: string;
        name: string;
        sid: string;
        sub: string;
        tenantId: string;
        username: string;
    };
    refresh_token: string;
    scope: string;
    session_state: string;
    state: { path: string };
    token_type: string;
    scopes: string[];
    expired?: boolean;
    expires_in?: number;
}

class CallbackPage extends React.Component<Props, {}> {
    successCallback = (user: UserInterface) => {
        const { dispatch } = this.props;
        // get the user's previous location, passed during signinRedirect()
        const redirectPath = user.state.path;

        userManager.getUser().then(user => {
            if (user && !user.expired) {

                store.dispatch(setProfileAuth(user));

                // Force user to change password at first time login
                setRequiredChangePassword(user.profile);

                observer.publish('oidcEvent', true);

                dispatch(push(redirectPath));
            }
        });
    };

    errorCallback = () => {
        const { dispatch } = this.props;
        dispatch(push('/'));
    };

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={this.successCallback}
                errorCallback={this.errorCallback}
            >
                <div />
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackPage);
