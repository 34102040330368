import React from 'react';
import ThisInterface from './interface';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import creditImage from 'assets/images/card-payment.png';
import debitImage from 'assets/images/debit-payment.png';
import { FormSelect, FormInput, FormCheckbox } from 'components/Form';
import * as Utils from 'util/ControlUtils';

const SecondaryPaymentMethod = ({
    paymentDetails,
    handleChange,
    t
}) => {
    const { secondaryPaymentMethod } = paymentDetails;
    return (
        secondaryPaymentMethod &&
        <FormCheckbox
            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CASH')}
            checked={secondaryPaymentMethod.toLowerCase() === 'cash'}
            onChange={() => { }}
            disabled
        />
    )
}

/** TODO: Implement this later **/
// const PayerDetails = () => {
//     return (
//         <div className="payer-detail hidden">
//           <p className="payer-title text-uppercase">payer details</p>
//           <p className="payer-info">If National ID details are not entered the Members National ID details will be used</p>
//           <div className="row">
//             <div className="col-xl-6">
//               <div className="form-group"><label>Payer NRIC Number</label>
//                 <div className="input-group"><input className="form-control" type="text" placeholder="Payer NRIC Number" name="cardname" /></div>
//               </div>
//             </div>
//             <div className="col-xl-6">
//               <div className="form-group"><label>Payer Passport Number</label>
//                 <div className="input-group"><input className="form-control" type="text" placeholder="Payer Passport Number" name="cardname" /></div>
//               </div>
//             </div>
//           </div>
//         </div>
//     );
// }

const NationalId = ({
    paymentDetails,
    fieldConfigs,
    errors,
    handleInputChange, 
    t }) => {
    const { nationalIdNumber, otherNationalIdNumber } = paymentDetails;
    return (
        <div className="national">
            <p className="national-title text-uppercase">{t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_DETAILS')}</p>
            <p className="national-info">{t('PAGE.MEMBERSHIPS.CREATE.STEP_4.IF_NATIONAL_ID_DETAILS_ARE_NOT_ENTERED_THE_MEMBERS_NATIONAL_ID_DETAILS_WILL_BE_USED')}</p>
            <div className="row">
                <div className="col-xl-6">
                    <FormInput
                        id="national-id-number"
                        name="national-id-number"
                        inputClass="form-control"
                        value={nationalIdNumber}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_NUMBER')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.NATIONAL_ID_NUMBER')}
                        maxlength={12}
                        onChange={(event) => handleInputChange("nationalIdNumber", event!.target.value)}
                        errorMsg={errors.nationalIdNumber}
                    />
                </div>
                <div className="col-xl-6">
                    <FormInput
                        id="other-national-id-number"
                        name="other-national-id-number"
                        inputClass="form-control"
                        value={otherNationalIdNumber}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.OLD_OTHER_NATIONAL_ID')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.OLD_OTHER_NATIONAL_ID')}
                        maxlength={15}
                        onChange={(event) => handleInputChange("otherNationalIdNumber", event!.target.value)}
                        errorMsg={errors.otherNationalIdNumber}
                    />
                </div>
            </div>
        </div>
    );
}

const FrontOfCard = ({
    name,
    cardTypeName,
    cardNumber,
    cardValidType,
    expiryDate,
    nameOnCard }) => {
    return (
        name && name.toLowerCase().includes('credit') ?
            <div className="card-image">
                <img src={creditImage} alt="" />
                <h4 className="name-payment">{cardTypeName}</h4>
                <h2 className="card-number">{cardNumber}</h2>
                <div className="section-exp">
                    <div className="exp-title"><span>{cardValidType}</span><span>thru</span></div>
                    <h4 className="exp-date">{expiryDate}</h4>
                </div>
                <h3 className="acc-name">{nameOnCard}</h3>
            </div>
            :
            <div className="debit-card-image">
                <img src={debitImage} alt="" />
            </div>
    )
}

const Other = ({
    paymentDetails,
    primaryPaymentMethods,
    errors,
    handleChange,
    handleInputChange, t }) => {
    const {
        primaryPaymentMethod: { name } } = paymentDetails;
    return (
        <>
            <div className="front-card">
                <FrontOfCard
                    name={name}
                    cardTypeName={''}
                    cardNumber={''}
                    cardValidType={''}
                    expiryDate={''}
                    nameOnCard={''}
                />
                <div className="info-card">
                    <FormSelect
                        id="primary-payment-method"
                        name="primary-payment-method"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD')}
                        disabled={primaryPaymentMethods.length === 0}
                        require
                        onChange={event => handleChange('primaryPaymentMethodId', event)}
                        value={Utils.getProperty(
                            paymentDetails,
                            'primaryPaymentMethodId',
                            primaryPaymentMethods
                        )}
                        options={primaryPaymentMethods}
                        errorMsg={errors.primaryPaymentMethodId}
                    />
                </div>
            </div>
        </>
    );
}

const DebitCard = ({
    paymentDetails,
    primaryPaymentMethods,
    issuingBanks,
    errors,
    handleChange,
    handleInputChange, 
    t }) => {
    const { accountName, cardNumber, primaryPaymentMethod: { name } } = paymentDetails;
    return (
        <>
            <div className="front-card">
                <FrontOfCard
                    name={name}
                    cardTypeName={'debit card'}
                    cardNumber={'1234 5678 9012 9999'}
                    cardValidType={'valid'}
                    expiryDate={'12/23'}
                    nameOnCard={'Name Surname'}
                />
                <div className="info-card">
                    <FormSelect
                        id="primary-payment-method"
                        name="primary-payment-method"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD')}
                        disabled={primaryPaymentMethods.length === 0}
                        require
                        onChange={event => handleChange('primaryPaymentMethodId', event)}
                        value={Utils.getProperty(
                            paymentDetails,
                            'primaryPaymentMethodId',
                            primaryPaymentMethods
                        )}
                        options={primaryPaymentMethods}
                        errorMsg={t(errors.primaryPaymentMethodId)}
                    />
                    <FormSelect
                        id="issuing-bank"
                        name="issuing-bank"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK')}
                        disabled={issuingBanks.length === 0}
                        require
                        onChange={event => handleChange('issuingBankId', event)}
                        value={Utils.getProperty(
                            paymentDetails,
                            'issuingBankId',
                            issuingBanks
                        )}
                        options={issuingBanks}
                        errorMsg={t(errors.issuingBankId)}
                    />
                    <FormInput
                        id="account-name"
                        name="account-name"
                        inputClass="form-control"
                        value={accountName}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME')}
                        maxlength={100}
                        require
                        onChange={(event) => handleInputChange("accountName", event!.target.value)}
                        errorMsg={t(errors.accountName)}
                    />
                    <FormInput
                        id="card-number"
                        name="card-number"
                        inputClass="form-control"
                        value={cardNumber}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.BANK_ACCOUNT_NUMBER')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.BANK_ACCOUNT_NUMBER')}
                        require
                        onChange={(event) => handleInputChange("cardNumber", event!.target.value)}
                        errorMsg={t(errors.cardNumber)}
                    />
                </div>
            </div>
            {/* <BehindCard /> TODO: Implement this later */}
        </>
    );
}

/** TODO: Implement this later **/
// const BehindCard = () => {
//     return (
//         <div className="behind-card hidden">
//             <div className="form-group"><label>Card Holder Name</label>
//                 <div className="input-group"><input className="form-control" type="text" placeholder="Card Holder Name" name="cardname" /></div>
//             </div>
//             <div className="detail-card">
//                 <div className="form-group">
//                     <label>Card Number</label>
//                     <div className="input-group"><input className="form-control" type="text" placeholder="Card Number" name="cardname" /></div>
//                 </div>
//                 <div className="form-group">
//                     <label>Expiry Date</label>
//                     <div className="input-group"><input className="form-control" type="date" /></div>
//                 </div>
//                 <div className="form-group">
//                     <label>CVC/CCV</label>
//                     <div className="input-group"><input className="form-control" type="number" placeholder="CVC/CCV" name="cardname" /></div>
//                 </div>
//             </div>
//         </div>
//     )
// }

const CreditCard = ({
    paymentDetails,
    primaryPaymentMethods,
    cardTypes,
    issuingBanks,
    errors,
    handleChange,
    handleInputChange,
    t }) => {
    const {
        accountName,
        creditCardNumber,
        expiryDate,
        issueNumber,
        primaryPaymentMethod: { name } } = paymentDetails;
    return (
        <>
            <div className="front-card">
                <FrontOfCard
                    name={name}
                    cardTypeName={'credit card'}
                    cardNumber={'1234 5678 9012 1111'}
                    cardValidType={'valid'}
                    expiryDate={'12/23'}
                    nameOnCard={'Name Surname'}
                />
                <div className="info-card">
                    <FormSelect
                        id="primary-payment-method"
                        name="primary-payment-method"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD')}
                        disabled={primaryPaymentMethods.length === 0}
                        require
                        onChange={event => handleChange('primaryPaymentMethodId', event)}
                        value={Utils.getProperty(
                            paymentDetails,
                            'primaryPaymentMethodId',
                            primaryPaymentMethods
                        )}
                        options={primaryPaymentMethods}
                        errorMsg={errors.primaryPaymentMethodId}
                    />
                    <FormSelect
                        id="card-type"
                        name="card-type"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CARD_TYPE')}
                        disabled={cardTypes.length === 0}
                        require
                        onChange={event => handleChange('cardTypeId', event)}
                        value={Utils.getProperty(
                            paymentDetails,
                            'cardTypeId',
                            cardTypes
                        )}
                        options={cardTypes}
                        errorMsg={t(errors.cardTypeId)}
                    />
                    <FormSelect
                        id="issuing-bank"
                        name="issuing-bank"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUING_BANK')}
                        disabled={issuingBanks.length === 0}
                        require
                        onChange={event => handleChange('issuingBankId', event)}
                        value={Utils.getProperty(
                            paymentDetails,
                            'issuingBankId',
                            issuingBanks
                        )}
                        options={issuingBanks}
                        errorMsg={t(errors.issuingBankId)}
                    />
                    <FormInput
                        id="account-name"
                        name="account-name"
                        inputClass="form-control"
                        value={accountName}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ACCOUNT_NAME')}
                        maxlength={100}
                        require
                        onChange={(event) => handleInputChange("accountName", event!.target.value)}
                        errorMsg={t(errors.accountName)}
                        validMsg={t(errors.accountName)}
                    />
                    <FormInput
                        id="credit-card-number"
                        name="credit-card-number"
                        inputClass="form-control"
                        value={creditCardNumber}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CREDIT_CARD_NUMBER')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.CREDIT_CARD_NUMBER')}
                        require
                        onChange={(event) => handleInputChange("creditCardNumber", event!.target.value)}
                        errorMsg={t(errors.creditCardNumber)}
                        validMsg={t(errors.creditCardNumber)}
                    />
                    <FormInput
                        id="expiry-date"
                        name="expiry-date"
                        inputClass="form-control"
                        value={expiryDate}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.EXPIRY_DATE')}
                        placeholder={"MM/yy"}
                        maxlength={5}
                        require
                        onChange={(event) => handleInputChange("expiryDate", event!.target.value)}
                        errorMsg={t(errors.expiryDate)}
                        validMsg={t(errors.expiryDate)}
                    />
                    <FormInput
                        id="issue-number"
                        name="issue-number"
                        inputClass="form-control"
                        value={issueNumber}
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUE_NUMBER')}
                        placeholder={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.ISSUE_NUMBER')}
                        maxlength={2}
                        onChange={(event) => handleInputChange("issueNumber", event!.target.value)}
                        errorMsg={errors.issueNumber}
                    />
                </div>
            </div>
            {/* <BehindCard /> TODO: Implement this later */}
            {/* <div className="box-btn"><button className="btn btn-primary">{formatMessage({ id: 'ADD_MEMBER_PAGE.VERIFY_CARD' })}</button></div> */}
        </>
    );
}

const PrimaryPaymentMethod = ({
    paymentDetails,
    primaryPaymentMethods,
    cardTypes,
    issuingBanks,
    errors,
    handleChange,
    handleInputChange,
    t
}) => {
    const { primaryPaymentMethod: { name } } = paymentDetails;
    return (
        name && (name.toLowerCase().includes('credit') || name.toLowerCase().includes('debit')) ?
            name && name.toLowerCase().includes('credit') ?
                <CreditCard
                    paymentDetails={paymentDetails}
                    primaryPaymentMethods={primaryPaymentMethods}
                    cardTypes={cardTypes}
                    issuingBanks={issuingBanks}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange} 
                    t={t} />
                :
                <DebitCard
                    paymentDetails={paymentDetails}
                    primaryPaymentMethods={primaryPaymentMethods}
                    issuingBanks={issuingBanks}
                    errors={errors}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange} 
                    t={t} />
            : <Other
                paymentDetails={paymentDetails}
                primaryPaymentMethods={primaryPaymentMethods}
                errors={errors}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                t={t}
            />
    );
}

const template = (_this: ThisInterface) => {
    const {
        membershipSearchFilter,
        membershipConfiguration,
        selectedPackagePlan,
        selectedAddons } = _this.props.newMembership;

    const {
        openDropdown,
        paymentDetails,
        primaryPaymentMethods,
        cardTypes,
        issuingBanks,
        fieldConfigs,
        errors } = _this.state;

    const { isEdit, t } = _this.props;

    const {
        handleOpenDropdown,
        handleChange,
        handleInputChange } = _this;

    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    {!isEdit && <p>{t('PAGE.MEMBERSHIPS.CREATE.ADD_NEW_MEMBER')}</p>}
                    {isEdit && <p>{t('PAGE.MEMBERSHIPS.EDIT.CHANGE_MEMBERSHIP')}</p>}
                </div>
                <div className="member-main">
                    <Breadcrumb
                        isEdit={isEdit}
                        membershipConfiguration={membershipConfiguration}
                        goToStep={(stepIndex) => _this.goToStep(stepIndex)}
                    />
                    <div className="content select-add-on">
                        <div className="main-payment select-add-on-main">
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.PRIMARY_PAYMENT_METHOD')}
                                content={PrimaryPaymentMethod({
                                    paymentDetails,
                                    primaryPaymentMethods,
                                    cardTypes,
                                    issuingBanks,
                                    errors,
                                    handleChange,
                                    handleInputChange,
                                    t
                                })}
                                isOpen={openDropdown['primary']}
                                maxHeight="1000px"
                                openCollapsedContent={() => handleOpenDropdown('primary')}
                            />
                            <NationalId
                                paymentDetails={paymentDetails}
                                fieldConfigs={fieldConfigs}
                                errors={errors}
                                handleInputChange={handleInputChange} 
                                t={t} />
                            {/* <PayerDetails /> TODO: Implement this later */}
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.STEP_4.SECONDARY_PAYMENT_METHOD')}
                                content={SecondaryPaymentMethod({
                                    paymentDetails,
                                    handleChange,
                                    t
                                })}
                                isOpen={openDropdown['secondary']}
                                openCollapsedContent={() => handleOpenDropdown('secondary')}
                            />
                        </div>
                        <ProductSummary
                            productSummary={selectedPackagePlan}
                            membershipSearchFilter={membershipSearchFilter}
                            membershipConfiguration={membershipConfiguration}
                            addons={selectedAddons}
                            handlePrevious={() => _this.handlePrevious()}
                            handleNext={() => _this.handleNext()}
                            handleCancel={_this.props.onCancel}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default template;

