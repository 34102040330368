import React from 'react';
import { withRouter } from 'react-router-dom';

import * as RouteLoader from 'pages/RouteLoader';
import commonService from 'services/common.service';
import FreezeModel, { FreezeMember } from 'models/Freeze';
import OptionModel from 'models/Option';
import observer from 'util/Observer';
import { DisplayDateFormat } from 'util/ControlUtils';
import { API_GET_FREEZE } from 'constants/Constants';

import template from './template';
import ThisInterface from './interface';

class RequestFreezeMembership extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    commonService: commonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        let freezeData = new FreezeModel(),
            freezeMember = new FreezeMember();
        props.freezeData && (freezeData = props.freezeData);
        props.freezeMember && (freezeMember = props.freezeMember);

        this.state = {
            ...freezeMember,
            ...freezeData
        };
        this.commonService = new commonService();
    }

    componentDidMount() {
        this.getDateRange(this.state.isOverrideFreezeNoticePeriod);
    }

    getRequestFreeze = () => {
        const {
            startDate,
            duration,
            reason,
            freezePrice,
            isPlanAllowFreezeToday,
            reasons,
            isOverrideFreezeNoticePeriod,
            isAllowFreezeBackDate,
            isAllowFutureFreezeStartDate,
            startDateRange
        } = this.state;
        observer.publish('requestFreeze', {
            startDate,
            duration,
            reason: reasons.find(item => item.value === reason),
            freezePrice,
            isPlanAllowFreezeToday,
            isOverrideFreezeNoticePeriod,
            isAllowFreezeBackDate,
            isAllowFutureFreezeStartDate,
            startDateRange
        });
    };
	
    getDateRange = (isOverrideFreezeNoticePeriod: boolean, onChange: boolean = false) => {
        const { dateFormat, match: { params }, memberData, isModify } = this.props;
        const { startDate } = this.state;
        this.commonService.fetchData(
            `${API_GET_FREEZE.replace('{id}', params['id'])}?isOverride=${isOverrideFreezeNoticePeriod}`,
            {
                rightName: RouteLoader.ViewRequestFreezeMemberShip.rightName,
                subTenantId: memberData.subTenantId
            }
        ).then(res => {
            let startDateRange: OptionModel[] = [];
            if (res) {
                startDateRange = res.startDateRange.map(item => ({
                    value: item,
                    label: DisplayDateFormat(item, dateFormat)
                }));
                this.setState({
                    startDateRange,
                    startDate: isModify && !onChange ? startDate : startDateRange[0].value
                }, () => {
                    this.getRequestFreeze();
                });
            }
        });
    }

    handleChange = (fieldName: string, value) => {
        const {freezeData } = this.props;
        if (freezeData) {
            this.setState(
                prevState => {
                    switch (fieldName) {
                        case 'isOverrideFreezeNoticePeriod':
                            this.getDateRange(value, true);
                        default:
                            return {
                                ...prevState,
                                [fieldName]: value
                            };
                    }
                },
                () => {
                    const {
                        isAllowFutureFreezeStartDate,
                        isAllowFreezeBackDate,
                        startDateRange
                    } = this.state;
                    const containField =
						fieldName === 'isAllowFutureFreezeStartDate' ||
						fieldName === 'isAllowFreezeBackDate';
                    const isAllow = isAllowFutureFreezeStartDate || isAllowFreezeBackDate;
                    if (containField) {
                        this.setState(
                            {
                                startDate: isAllow ? `${new Date()}` : startDateRange[0] ? startDateRange[0].value : null
                            },
                            () => this.getRequestFreeze()
                        );
                    } else {
                        this.getRequestFreeze();
                    }
                }
            );
        }
    };

    render() {
        return template(this);
    }
}

export default withRouter(RequestFreezeMembership);
