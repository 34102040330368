export class Right {
    id = '';
    name = '';
    normalizeName = '';
    priority = 0;
    active = false;
}

export class Resource {
    id = '';
    name = '';
    rights = [new Right];
}

export default class Feature {
    id = '';
    name = '';
    resources =  [new Resource()];
    static clone = (obj: Feature) => {
        return {
            id: obj.id,
            name: obj.name,
            resources: obj.resources
        }
    }
}