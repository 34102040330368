import React from 'react';
import { CommaFormatted, DisplayDateFormat, NumberMoneyFormat } from 'util/ControlUtils';
import debitImg from 'assets/images/debit-denied.svg';

import { Descriptions } from '../common';
import ThisInterface from '../interface';

const DebtSummary = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { dateFormat, memberData } = _this.state;
	
    const arrearAmount = CommaFormatted(memberData.arrearsValue);

    const descriptionList2 = [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.MEMBER_DUES', className: 'debt-chase' },
            value: { text: '$474,000.00', className: 'col-4 debt-value' },
            className: 'row'
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.CONTRACT_NOTICE_PERIOD', className: 'debt-chase' },
            value: { text: '1 Month', className: 'col-4 debt-value' },
            className: 'row'
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.LAST_ATTENDED', className: 'debt-chase' },
            value: { text: '-', className: 'col-4 debt-value' },
            className: 'row'
        }
    ];
	
    const descriptionList3 = [
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.LAST_BANK_REJECT_TYPE_MF', className: 'original-state' },
            value: { text: '2', className: 'col-1' },
            className: 'row'
        },
        {
            name: { text: 'PAGE.MEMBERSHIPS.VIEW.LAST_BANK_REJECT_TYPE_DESCRIPTION' },
            value: { text: 'Suspended' },
            className: 'last-bank-reject'
        }
    ];

    return (
        <div className="debt-summary">
            <div className="debt-title">{t('PAGE.MEMBERSHIPS.VIEW.DEBT_SUMMARY')}</div>
            <div className="debt-wrapper">
                <div className="content-wrapper money-wrapper">
                    <div className="money-value row">
                        <div className="col-md-6">
                            <p className="title-value">
                                {t('PAGE.MEMBERSHIPS.VIEW.ARREAR_VALUE')}
                            </p>
                            <div className="value color-red">
                                {arrearAmount >= 0
                                    ? `${arrearAmount}`
                                    : (
                                        <NumberMoneyFormat
                                            amount={arrearAmount.toString().replace('-', '$')}
                                            operator="-"
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <p className="title-value">
                                {t('PAGE.MEMBERSHIPS.VIEW.REMAINING_CCV')}
                            </p>
                            <div className="value color-green">
                                <NumberMoneyFormat
                                    amount="$121212"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content-wrapper debt-content">
                        <p className="title">{t('PAGE.MEMBERSHIPS.VIEW.DEBT_STAGE')}</p>
                        <div className="debt-state color-red">04 - out of debt chase</div>
                        <Descriptions descriptionList={descriptionList2} t={t!} />
                    </div>
                </div>
                <div className="content-wrapper">
                    <div className="debit-content">
                        <div className="image-wrapper">
                            <div className="image-content">
                                <img src={debitImg} alt="debit" />
                            </div>
                        </div>
                        <div className="text-wrapper">
                            <div className="text-content">
                                <p className="text-value">
                                    {t('PAGE.MEMBERSHIPS.VIEW.ORIGINAL_REJECT_REASON')}:
                                </p>
                                <div className="value">Transaction Returned (MBB)</div>
                            </div>
                            <div className="text-content">
                                <p className="text-value">
                                    {t('PAGE.MEMBERSHIPS.VIEW.LAST_REJECT_DATE')}:
                                </p>
                                <div className="value">{DisplayDateFormat('2019-01-10', dateFormat)}</div>
                            </div>
                        </div>
                    </div>
                    <Descriptions descriptionList={descriptionList3} t={t!} />
                </div>
            </div>
            <div className="button-wrapper">
                <button className="btn btn-primary" onClick={() => _this.handleChangeSection('make-payment')} >
                    {t('PAGE.MEMBERSHIPS.VIEW.BUTTON.MAKE_PAYMENT')}
                </button>
                <button className="btn btn-view-debt" >
                    {t('PAGE.MEMBERSHIPS.VIEW.BUTTON.VIEW_DEBT_LIST')}
                </button>
            </div>
        </div>
    )
}

export default DebtSummary
