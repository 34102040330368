import React from 'react';
import { getPager } from 'util/ControlUtils';

interface PaginationProps {
    activePage: number;
    itemsCountPerPage: number;
    totalItemsCount: number;
    onPageChange: (page: number) => void;
}

const PaginationHolder: React.SFC<PaginationProps> = ({
    activePage,
    onPageChange,
    itemsCountPerPage,
    totalItemsCount
}) => {
    function handleChangePage(page: number) {
        onPageChange(page);
    }

    function changePrevNextPage(key: string, totalPages: number) {
        const currentPage = key === 'next' ? activePage + 1 : activePage - 1;
        if (currentPage >= 1 && currentPage <= totalPages) {
            onPageChange(currentPage);
        }
    }

    function changeFirstLastPage(key: string, totalPages: number) {
        const currentPage = key === 'first' ? 1 : totalPages;
        onPageChange(currentPage);
    }

    function renderPage() {
        const { pages, totalPages } = getPager(
            totalItemsCount,
            activePage,
            itemsCountPerPage
        );

        return (
            <>
                <li
                    className={`page-item ${activePage === 1 ? 'disabled' : ''}`}
                    onClick={() => changeFirstLastPage('first', totalPages)}
                >
                    <a className="page-link" role="button" tabIndex={0}>
                        <span className="icon icon-double-arrow-left-icon" />
                    </a>
                </li>
                <li
                    className={`page-item ${activePage <= 1 ? 'disabled' : ''}`}
                    onClick={() =>
                        activePage <= 1 ? {} : changePrevNextPage('prev', totalPages)
                    }
                >
                    <a className="page-link" role="button" tabIndex={0}>
                        <span className="icon icon-arrow-left-icon" />
                    </a>
                </li>
                {pages.map(item => {
                    return (
                        <li
                            className={`page-item ${activePage === item ? 'active' : ''}`}
                            key={item}
                            onClick={() => handleChangePage(item)}
                        >
                            <a className="page-link" role="button" tabIndex={0}>
                                {item}
                            </a>
                        </li>
                    );
                })}
                <li
                    className={`page-item ${activePage >= totalPages ? 'disabled' : ''}`}
                    onClick={() =>
                        activePage >= totalPages
                            ? {}
                            : changePrevNextPage('next', totalPages)
                    }
                >
                    <a className="page-link" role="button" tabIndex={0}>
                        <span className="icon icon-arrow-right-icon" />
                    </a>
                </li>
                <li
                    className={`page-item ${activePage === totalPages ? 'disabled' : ''}`}
                    onClick={() => changeFirstLastPage('last', totalPages)}
                >
                    <a className="page-link" role="button" tabIndex={0}>
                        <span className="icon icon-double-arrow-right-icon" />
                    </a>
                </li>
            </>
        );
    }

    return <ul className="pagination pagination-sm">{renderPage()}</ul>;
};

export default PaginationHolder;
