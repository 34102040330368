import React from 'react';
import ThisInterface from './interface';
import PlanModel, { PackageModel, ClassModel, PaymentMethodModel, FixTermTypeModel, CorporateSubsidyModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import OptionModel from 'models/Option';

import FormItem, { getIdOrUndefined } from 'components/FormV2';
import Loading from 'components/Loading';

interface State {
    isExpand: boolean;
    isExpanded: boolean;
    isLoading: boolean;
    packages: OptionModel[];
    classes: OptionModel[];
    paymentsMethod: OptionModel[];
    terms: OptionModel[];
    subsidies: OptionModel[];
}
interface Props {
    onChangeHandle: Function;
    data: PlanModel;
    fields: FieldModel[];
    parentPage: 'quickClone' | 'quickEdit';
    t?: any;
}

class Template extends React.PureComponent<Props & ThisInterface['props'], State> {
    constructor(props: Props & ThisInterface['props']) {
        super(props);
        this.state = {
            isExpand: true,
            isExpanded: true,
            isLoading: true,
            packages: [],
            classes: [],
            paymentsMethod: [],
            terms: [],
            subsidies: []
        }
    }

    componentDidMount() {
        const { subTenantId, rightName } = this.props;
        const getPackages = this.props.planService.getPackages(subTenantId, rightName).then((res) => {
            const packages: PackageModel[] = res.data;
            this.setState({
                packages: packages.map(e => ({ value: e.id, label: e.name }))
            });
        });
        const getClasses = this.props.planService.getClasses(subTenantId, rightName).then((res) => {
            const classes: ClassModel[] = res.data;
            this.setState({
                classes: classes.map(e => ({ value: e.id, label: e.name }))
            });
        });
        const getPaymentsMethod = this.props.planService.getPaymentsMethod(subTenantId, rightName).then((res) => {
            const paymentsMethod: PaymentMethodModel[] = res.data;
            this.setState({
                paymentsMethod: paymentsMethod.map(e => ({ value: e.id, label: e.name }))
            });
        });
        const getTerms = this.props.planService.getTerms(subTenantId, rightName).then((res) => {
            const terms: FixTermTypeModel[] = res.data;
            this.setState({
                terms: terms.map(e => ({ value: e.id, label: e.name }))
            });
        });
        const getSubsidies = this.props.planService.getSubsidies(subTenantId, rightName).then((res) => {
            const subsidies: CorporateSubsidyModel[] = res.data;
            this.setState({
                subsidies: subsidies.map(e => ({ value: e.id, label: e.name }))
            });
        });
        Promise.all([
            getPackages,
            getClasses,
            getPaymentsMethod,
            getTerms,
            getSubsidies
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    toogleExpand() {
        this.setState(state => ({ isExpand: !state.isExpand }), () => {
            setInterval(() => this.setState({ isExpanded: this.state.isExpand }), 500);
        });
    }

    render() {
        const { onChangeHandle, data, fields, parentPage, t } = this.props;
        const basicColumns = [
            parentPage === 'quickClone' ? {
                props: {
                    type: 'text',
                    htmlFor: 'basicDetail[name]',
                    fieldName: 'basicDetail[name]',
                    data: data.name,
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.QUICK_CLONE.NAME')
                },
            } : undefined,
            {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[class]',
                    fieldName: 'basicDetail[class]',
                    data: getIdOrUndefined(data.class),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.QUICK_CLONE.PLAN_CLASS'),
                    options: this.state.classes
                },
            },
            parentPage === 'quickEdit' ? {
                props: {
                    type: 'select',
                    htmlFor: 'basicDetail[paymentMethod]',
                    fieldName: 'basicDetail[paymentMethod]',
                    data: getIdOrUndefined(data.paymentMethod),
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.QUICK_EDIT.PAYMENT_METHOD_CONFIGURATION'),
                    options: this.state.paymentsMethod
                },
            } : undefined,
            {
                props: {
                    type: 'checkbox',
                    htmlFor: 'basicDetail[allowTransNonMember]',
                    fieldName: 'basicDetail[allowTransNonMember]',
                    data: data.isAllMemberToNonMemberTransfer ? 1 : 0,
                    attribute: {
                        checked: data.isAllMemberToNonMemberTransfer
                    },
                    fieldLabel: t('PAGE.PRODUCTS.PLANS.QUICK_EDIT.ALLOW_MEMBER_TO_NON_MEMBER_TRANSFER'),
                },
                className: "form-check"
            },
        ];

        return (
            <div className="collapse-promotion collapse-saas"><button className={this.state.isExpand ? 'collapsible active' : 'collapsible'} onClick={() => this.toogleExpand()}>{t('PAGE.PRODUCTS.PLANS.QUICK_EDIT.BASIC_DETAILS')}</button>
                <div className={this.state.isExpanded ? 'content open' : 'content'} style={{ maxHeight: (this.state.isExpand ? '10000px' : '0') }}>
                    {this.state.isLoading ?
                        <Loading />
                        :
                        <div className="row">
                            <div className="col-lg-4">
                                <h4 className="title">{t('PAGE.PRODUCTS.PLANS.QUICK_EDIT.BASIC')}</h4>
                                {basicColumns.map((field, key) =>
                                    field && <div key={key} className="mt-3">
                                        <FormItem
                                            {...field.props}
                                            onChangeHandle={onChangeHandle}
                                            fields={fields}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default Template;