import React from 'react';
import { connect } from 'react-redux';
import ThisInterface from './interface';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import store from 'reduxs/index';
import template from './template';
import { withTranslation, WithTranslation } from 'react-i18next';
import AddOn, { AddOnClass } from 'models/AddOn';
import * as Routes from 'pages/RouteLoader';

class SelectProductExtras extends React.Component<ThisInterface["props"]  & WithTranslation, ThisInterface["state"]> {
    constructor(props: ThisInterface["props"]  & WithTranslation) {
        super(props);

        this.state = {
            openDropdown: {
                promotion: false,
                specialPromotion: false,
                addOns: true
            },
            isLoading: {
                promotion: false,
                specialPromotion: false,
                addOns: false
            },
            addOns: [],
            selectedAddOns: [],
            errors: []
        }
    }

    componentDidMount() {
        const { newMembership } = this.props; //TODO: Reload product extras from Redux
        const legacyId = newMembership.membershipSearchFilter.legacyId;
        const subTenantId = newMembership.selectedSubTenant.id;

        const rightName = Routes.AddNewMemberRoute.rightName;
        // this.props.membershipService.getAddonsForAddMembership(subTenantId, legacyId, rightName).then(res => {
        //     this.setState(prevState => ({
        //         isLoading: {
        //             ...prevState.isLoading,
        //             addOns: false
        //         },
        //         addOns: this.getAddOnsFromAddOnClasses(res.data),
        //         selectedAddOns: newMembership.selectedAddons
        //     }));
        // });
    }

    getAddOnsFromAddOnClasses(addOnClasses: AddOnClass[]) {
        return (addOnClasses.reduce((acc, cur) => [...acc, ...cur.addons], [] as AddOn[]) as AddOn[]).filter((elem, pos, arr) => arr.findIndex(i => i.id === elem.id) === pos);
    }

    handleChangeAddOn = (addOn: AddOn, event) => {
        if (event.target.checked) {
            this.setState({
                selectedAddOns: [addOn]
            }, () => {
                store.dispatch(MembershipActions.setProductExtras([], this.state.selectedAddOns));
            });
        } else {
            this.setState(prevState => ({
                selectedAddOns: prevState.selectedAddOns.filter(e => e.id !== addOn.id)
            }), () => {
                store.dispatch(MembershipActions.setProductExtras([], this.state.selectedAddOns));
            });
        }
    }

    handlePrevious() {
        const { membershipConfiguration } = this.props.newMembership;
        membershipConfiguration.stepIndex = 1;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
    }

    handleNext() {
        const { membershipConfiguration } = this.props.newMembership;
        if (membershipConfiguration.stepLatest < 3) {
            membershipConfiguration.stepLatest = 3;
        }
        membershipConfiguration.stepIndex = 3;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        // store.dispatch(MembershipActions.setProductExtras(null, null)); //TODO: Store product extras to Redux
    }

    handleOpenDropdown = (key) => {
        const { openDropdown } = this.state;
        openDropdown[key] = !this.state.openDropdown[key];
        this.setState({
            openDropdown
        })
    };

    goToStep(stepIndex) {
        const { membershipConfiguration } = this.props.newMembership;
        membershipConfiguration.stepIndex = stepIndex;
        store.dispatch<any>(MembershipActions.setConfiguration(membershipConfiguration));
    }

    render() {
        return template(this);
    }
}

const mapStateToProps: any = ({ newMembership }) => {
    return { newMembership };
};

export default connect(
    mapStateToProps
)(withTranslation()(SelectProductExtras));
