class Child {
    id = '';
    key = '';
    name = '';
    regEx = '';
    maxLength = 50;
}

export default class Field {
    id = '';
    key = '';
    name = '';
    isRequired = false;
    isVisible = true;
    isValidType: 'required' | 'regex' | 'none' | undefined = 'none';
    regEx: string = '';
    regex: string = '';
    isDirty: boolean = false;
    maxLength: number = 5000;
    customMsg: string = '';
    children: Child[] = [new Child()];
}
