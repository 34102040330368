/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { GENERAL_DATABASE_DATE_FORMAT, GENERAL_DATE_FORMAT, GENERAL_NA, BASE_IFRAME_URL_DEVELOPMENT } from 'constants/Constants';
import { PhoneNumberUtil } from 'google-libphonenumber';
import _ from 'lodash';
import SubTenantModel from 'models/SubTennant';
import moment from 'moment';
import store from 'reduxs/index';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';
import { t } from 'util/I18nMessages';

export function getTenantId() {
    const reduxState = store.getState();
    const { auth: { user: { profile } } } = reduxState;
    if (profile) {
        return profile.tenantId;
    }
    return '';
}

export function getSubTenantId() {
    const reduxState = store.getState();
    const { auth: { user: { profile } } } = reduxState;
    if (profile) {
        return profile.subTenantId;
    }
    return '';
}

export function getToken() {
    const reduxState = store.getState();
    const { auth: { user: { access_token } } } = reduxState;
    return access_token;
}

export function getIframeEndpoint() {
    const reduxState = store.getState();
    const { auth: { user: { profile } } } = reduxState;
    if (profile) {
        try {
            return JSON.parse(profile.subTenants);
        } catch (error) {
            return [];
        }
    }
    return [];
}

export function getAllRights() {
    let claims: string[] = [];
    try {
        const allClaims = JSON.parse(localStorage.allClaims);
        for (let key in allClaims.tenant) {
            claims.push(key);
        }
        for (let key in allClaims.subTenant) {
            claims.push(key);
        }
    } catch (e) { }
    return claims;
}

export function handleSessionExpired() {
    observer.publish('flagUnsavedChangeEvent', false);
    Swal.fire({
        ...DefaultConstants.SWAL_COMMON_STYLE,
        text: t('COMMON.EXPIRED_SESSION'),
        type: 'warning',
        confirmButtonText: t('BUTTON.CLOSE')
    }).then(() => {
        observer.publish('forceLogoutEvent');
    });
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function randomPassword() {
    const chars = 'ABCDEFGHIJKLMNOP';
    const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    const speacialChars = '!@#$%^&*()-+<>';
    const number = '1234567890';
    let password = '';
    for (let i = 0; i < 2; i++) {
        const j = Math.floor(Math.random() * chars.length);
        const k = Math.floor(Math.random() * speacialChars.length);
        const l = Math.floor(Math.random() * lowerChars.length);
        const m = Math.floor(Math.random() * number.length);
        password +=
            chars.charAt(j) +
            speacialChars.charAt(k) +
            lowerChars.charAt(l) +
            number.charAt(m);
    }
    return password;
}

export function transformOptions(data: { id: string; name: string }[]) {
    return data.map(item => {
        return {
            value: item.id,
            label: item.name,
            ...item
        };
    });
}

export function isValidPassword(password: string) {
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    return (
        format.test(password) && /[a-z]/.test(password) && /[A-Z]/.test(password)
    );
}

export function IsValidEmail(emailAddress) {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        emailAddress
    );
}

// Eg: XXX-XXX-XXXX
// Eg: XXX.XXX.XXXX
// Eg: XXX XXX XXXX
export function IsValidPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.split(' ').join('');
    return /^\d{10}$/.test(phoneNumber);
}

export function IsValidPhoneNumberWithCountryCode(phoneNumber) {
    return /^\+?[0-9]*$/.test(phoneNumber);
}

export function chunk(arr, chunkSize) {
    var R: any[] = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
    return R;
}

export function interpret(str) {
    let idx = 0;
    while (str[idx + 1] != '[') {
        idx++;
    }
    let name = str.substr(0, idx + 1);

    let indices: any[] = [];
    while (str[idx + 1] == '[') {
        idx++;
        let startIdx = idx;
        while (str[idx + 1] != ']') {
            idx++;
        }
        indices.push(str.substr(startIdx + 1, idx - startIdx));
        idx++;
    }

    return { name, indices };
}

export function removeDuplicatedObj(arr) {
    return arr.reduce((unique, o) => {
        if (!unique.some(obj => obj.label === o.label && obj.value === o.value)) {
            unique.push(o);
        }
        return unique;
    }, []);
}

// Eg. +XX-XXXX-XXXX
// Eg. +XX.XXXX.XXXX
// Eg. +XX XXXX XXXX
export function IsValidSignPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.split(' ').join('');
    return /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(phoneNumber);
}

// Eg. MM/YY
export function IsValidCreditCardExpiryDate(creditCardNumber) {
    return /^\(?([0-9]{2})\)?[/]?([0-9]{2})$/.test(creditCardNumber);
}

// Eg. 38980
// Eg. 83900-8789
export function IsValidPostCode(postCode) {
    return /^\d{5}$|^\d{5}-\d{4}$/.test(postCode);
}

// Eg. XXX
export function IsValidCvcOrCvv(cvcOrCvv) {
    return /^[0-9]{3}$/.test(cvcOrCvv);
}

// Eg. Visa 	4111111111111111
// Eg. MasterCard 	5500000000000004
// Eg. American Express 	340000000000009
export function IsValidCreditCardNumber(cardNumber, cardType) {
    cardNumber = cardNumber.split(' ').join('');
    const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    const amexpRegEx = /^(?:3[47][0-9]{13})$/;
    let isValid = false;

    if (
        (cardType === 'Visa' || cardType === 'Visa Debit') &&
        visaRegEx.test(cardNumber)
    ) {
        isValid = true;
    } else if (
        (cardType === 'MasterCard' || cardType === 'Master Debit') &&
        mastercardRegEx.test(cardNumber)
    ) {
        isValid = true;
    } else if (cardType === 'AMEX' && amexpRegEx.test(cardNumber)) {
        isValid = true;
    }

    return isValid;
}

// Eg. 2345643.00 will return 2,345,643.00
export function CommaFormatted(amount) {
    return amount ? amount.toLocaleString() : 0;
}

export function GetFullName(firstName, lastName) {
    return `${firstName} ${lastName}`;
}

export function DisplayCardNumber(cardNumber) {
    return cardNumber !== ''
        ? `****${cardNumber.substring(cardNumber.length - 4, cardNumber.length)}`
        : '';
}

export function displayBankNumber(bankNumber: string) {
    return bankNumber !== ''
        ? `****${bankNumber.substring(bankNumber.length - 5, bankNumber.length)}`
        : '';
}

export function ConvertDateFormatForDatePicker(dateFormat) {
    switch (dateFormat) {
        case 'dd/mm/yyyy':
            return 'dd/MM/yyyy';
        case 'mm/dd/yyyy':
            return 'MM/dd/yyyy';
        default:
            return dateFormat;
    }
}

export function ConvertDateForSelectedDatePicker(date) {
    return moment(date).toDate();
}

export function DisplayDateFormat(date, dateFormat) {
    return moment(date).format(dateFormat.toUpperCase());
}

export function CompareTwoDates(newDate, oldDate, dateFormat) {
    return (
        DisplayDateFormat(newDate, dateFormat) ===
        DisplayDateFormat(oldDate, dateFormat)
    );
}

export function IsValidDateOfBirth(dateOfBirth) {
    return moment(dateOfBirth).isSameOrBefore(new Date(), 'day');
}

export function IsValidStartDate(startDate) {
    return moment(startDate).isSameOrAfter(new Date(), 'day');
}

export function ConvertDateFormat(date) {
    return moment(date)
        .utc()
        .toDate()
        .toISOString();
}

export function IsValidFormatDate(dateString, dateFormat) {
    if (dateString.length !== dateFormat.length) {
        return false;
    }
    return DisplayDateFormat(dateString, dateFormat).length === dateFormat.length;
}

export function IsValidDate(date, dateFormat) {
    var dateString = DisplayDateFormat(date, dateFormat);
    return dateString.length === dateFormat.length;
}

export function DisplayNationalIdNumber(nationalId) {
    return nationalId !== ''
        ? `${nationalId.substring(0, 4)}******${nationalId.substring(
            nationalId.length - 3,
            nationalId.length
        )}`
        : '';
}

export function CapitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function IsMatchWithSystemConfig(value, pattern) {
    const regex = RegExp(pattern);
    return regex.test(value);
}

export function IsEndDateAfterStartDate(startDate, endDate) {
    return moment(endDate)
        .utc()
        .isAfter(moment(startDate).utc());
}

export function GenerateNumericRandom() {
    const min = 1;
    const max = 100;
    return min + Math.random() * (max - min);
}

export function getPager(
    totalItems: number,
    currentPage: number = 1,
    itemPerPage: number = 10
) {
    const totalPages = Math.ceil(totalItems / itemPerPage);
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 3;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    const pages = [...Array(endPage + 1 - startPage).keys()].map(
        i => startPage + i
    );

    return {
        currentPage,
        startPage,
        endPage,
        pages,
        totalPages
    };
}

// Reference from css-tricks.com/css-variables-calc-rgb-enforcing-high-contrast-colors
export function enforcingHighContrastColors(red, green, blue) {
    return (red * 299 + green * 587 + blue * 144 - 180000) * -1000;
}

export function convertSingleCode(colorCode) {
    let hexCode = colorCode.toString(16);
    return hexCode.length == 1 ? '0' + hexCode : hexCode;
}

// Reference from tutorialscapital.com/react-native-convert-rgb-color-code-hexadecimal-code-tutorial-scratch/
export function rgbToHex(red, green, blue) {
    return (
        '#' +
        convertSingleCode(red) +
        convertSingleCode(green) +
        convertSingleCode(blue)
    );
}

// Reference from sitepoint.com/javascript-generate-lighter-darker-color
export function ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = '#',
        c,
        i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

// Contain at least 8 characters
export function isMinimumCharacterCount(password) {
    return password.length > 7;
}

// Contain at least 1 uppercase character (A-Z)
export function IsMinimumUpperCaseLetter(password) {
    return /[A-Z]/.test(password);
}

// Contain at least 1 lowercase character (a-z)
export function IsMinimumLowerCaseLetter(password) {
    return /[a-z]/.test(password);
}

// Contain at least 1 digit character
export function IsMinimumDigitCharacterCount(password) {
    return /[0-9]/.test(password);
}

// Contain at least one special character
export function IsMinimumSpecialCharacter(password) {
    return /[.!@#\$%\^&\*]/.test(password);
}

export function setRequiredChangePassword(userProfile) {
    const { amr, isRequiredChangePassword } = userProfile;

    if (amr[0] !== 'external' && isRequiredChangePassword === '1') {
        localStorage.isRequiredChangePassword = isRequiredChangePassword;
    } else if (
        localStorage.isRequiredChangePassword &&
        localStorage.isRequiredChangePassword === '1'
    ) {
        localStorage.isRequiredChangePassword = '0';
    }
}

export function getDialCode(phoneNumber: string, countryCode: string) {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil
            .parseAndKeepRawInput(phoneNumber, countryCode)
            .getCountryCode()!
            .toString();
    } catch {
        return '';
    }
}

export function isValidNumber(phone: string, countryCode: string) {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        return phoneUtil.isValidNumber(
            phoneUtil.parseAndKeepRawInput(phone, countryCode)
        );
    } catch {
        return false;
    }
}

export function checkInvalidEmailField(email: string) {
    if (email) {
        return IsValidEmail(email);
    }
    return true;
}

export function isGroupNameValid(groupName: string) {
    if (groupName) {
        return /^[A-Za-z\s-]+$/.test(groupName);
    }
    return false;
}

export function isInputMatchRegex(regex: string, inputText: string) {
    if (regex && inputText) {
        return new RegExp(regex).test(inputText);
    }
    return true;
}

// Get object to display on Dropdown list
export function getProperty(model, key, list) {
    return model && model[key] ? list.find(e => e.value === model[key]) : list[0];
}

// Valid characters are "a-z", "A-Z" and space, and max length is 100 characters
export function IsValidAccountName(accountName) {
    return /^[a-zA-Z ]{1,100}$/.test(accountName);
}

// Valid characters are 0-9 and space
export function IsValidCardNumber(cardNumber) {
    return /^[\d ]*$/.test(cardNumber);
}

// Valid characters are 0-9 and space
export function IsValidIssueNumber(issueNumber) {
    return /^[\d]{1,2}$/.test(issueNumber);
}

// Valid characters are 0-9, and max length is 12 characters
export function IsValidNationalIdNumber(nationalIdNumber) {
    return /^[\d]{1,12}$/.test(nationalIdNumber);
}

export function IsValidOtherNationalIdNumber(nationalIdNumber) {
    return /^[\d]{1,15}$/.test(nationalIdNumber);
}

export function filterUserAndUserGroup(
    tenantId: string,
    selectedTenantRoles: any[] = [],
    selectedSubTenantRoles: any[] = []
) {
    let tenantFilter = [
        {
            operation: 'eq',
            queryType: 'guid',
            queryKey: 'tenantId',
            queryValue: tenantId
        }
    ];

    let subTenantHasRoles = selectedSubTenantRoles.filter(
        s => s.roles.length > 0
    );

    let subTenantFilter: any[] = [];
    subTenantHasRoles.length > 0 &&
        subTenantHasRoles.forEach(s => {
            s.roles.forEach(r => {
                subTenantFilter.push({
                    operation: 'eq',
                    queryType: 'guid',
                    queryKey: 'ClubGroupId',
                    queryValue: r.secondKeyId ? r.secondKeyId : r.clubGroup.id
                });
            });
        });

    let filter =
        selectedTenantRoles.length > 0 && subTenantHasRoles.length > 0
            ? [...tenantFilter, ...subTenantFilter]
            : selectedTenantRoles.length > 0
                ? [...tenantFilter]
                : subTenantHasRoles.length > 0
                    ? [...subTenantFilter]
                    : [];

    return filter;
}

export function getAffectSubTenants(
    roleSubTenants: SubTenantModel[],
    userSubTenants: SubTenantModel[]
) {
    const subTenantRole = roleSubTenants.map((item, index) => ({
        ...item,
        index
    })),
        subTenantUser = userSubTenants.map((item, index) => ({ ...item, index }));
    const sumupArray = subTenantUser
        .concat(subTenantRole)
        .sort((a, b) => (a.index < b.index ? -1 : 1)),
        theSameSubTenants: SubTenantModel[] = [],
        notSameSubTenants: SubTenantModel[] = [];

    for (let i = 0; i < sumupArray.length - 1; i++) {
        if (_.isEqual(sumupArray[i + 1], sumupArray[i])) {
            theSameSubTenants.push(sumupArray[i]);
        }
    }
    if (
        roleSubTenants.length > 0 &&
        theSameSubTenants.length > 0 &&
        !_.isEqual(roleSubTenants, userSubTenants) &&
        userSubTenants.length <= roleSubTenants.length
    ) {
        roleSubTenants.forEach(item => {
            theSameSubTenants.forEach(sub => {
                if (!_.isEqual(item, sub)) {
                    notSameSubTenants.push(item);
                }
            });
        });
    }

    return _.uniq(notSameSubTenants.map(item => item.name));
}

export function formatCreditDebitCard(cardNumber: string) {
    const value = cardNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = value.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts: string[] = [];

    for (let i = 0; i < match.length; i += 4) {
        parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
        return parts.join(' ');
    }
    return cardNumber;
}

export function formatExpiryDate(date: string) {
    const value = date.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = value.match(/\d{2,4}/g);
    const match = (matches && matches[0]) || '';

    const parts: string[] = [];
    for (let i = 0; i < match.length; i += 2) {
        parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
        let dates = parts;
        if (parts[0] && parseInt(parts[0], 10) > 12) {
            dates[0] = '12';
        }
        return dates.join('/');
    }
    return date;
}

export function checkCreditValidYear(date: string) {
    const dates = date.split('/');
    const currentYear = parseInt(
        new Date()
            .getFullYear()
            .toString()
            .substr(-2),
        10
    );
    if (dates[1] && parseInt(dates[1], 10) > currentYear) {
        return true;
    }
    return false;
}

export function checkValidBankAccount(bankNumber: string) {
    if (bankNumber) {
        const value = bankNumber.replace(/[^0-9]/g, '');
        return value.length === 10;
    }
    return false;
}

export function checkValidCardNumber(cardNumber: string) {
    const match = cardNumber.match(/\d{4,16}/g);
    if (match && match[3] && match[3].length === 4) {
        return true;
    }
    return false;
}

export function checkNullData(notNullData, data = '') {
    if (notNullData && notNullData !== '') {
        return data !== '' ? data : notNullData;
    }
    return GENERAL_NA;
}

export function getFutureDateYearWithMonth(
    date: Date,
    month: number,
    toISOString = false,
    lastDateOfMonth = true
) {
    const lastDate = lastDateOfMonth ? date.getDate() - 1 : date.getDate();
    const futureDate = date.setMonth(date.getMonth() + month, lastDate);
    return toISOString
        ? `${DisplayDateFormat(futureDate, GENERAL_DATABASE_DATE_FORMAT)}T00:00:00Z`
        : DisplayDateFormat(futureDate, GENERAL_DATE_FORMAT);
}

export function formatMoney(number, decPlaces) {
    return (number).toFixed(decPlaces).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
}


export function isHasRight(rightName: string) {
    return getAllRights().find(key => key === rightName);
}


export function getIframeAPIURL(apiUrl, subTenantId, app = '') {
    const domainIFrameURL = `${window.location.protocol}//iframe.${
        window.location.hostname
        }${window.location.port ? `:${window.location.port}` : ''}`;
    if (domainIFrameURL.indexOf('localhost') === -1) {
        const endpoints = getIframeEndpoint();
        const endpoint = endpoints.find(e => e.SubTenantId === subTenantId);
        if (endpoint) {
            return apiUrl.replace(BASE_IFRAME_URL_DEVELOPMENT, endpoint.MFEndpoint + app);
        } else {
            return apiUrl;
        }
    }
    return apiUrl;
}

export function isNationalIdBlank(nationalId: string, baseNationalId: string) {
    if (!nationalId && !baseNationalId) return true;
    return false;
}

export function isLettersOnly(value: string) {
    return value.match(/^[a-zA-Z\s]*$/);
}

export function isEmptyString(value: string) {
    return value.length === 0;
}

export function toLocalDate(date: any) {
    return moment(date).local();
}

export function isNotHasFutureEvent(futureEvents, events) {
    if (
        (futureEvents && !_.isEmpty(futureEvents)) ||
        (events && !_.isEmpty(events))
    ) {
        return futureEvents.length === 0 && events.length === 0;
    }
    return true;
}

export function dateInMCP(startedDate: string, unitName: string, value: number) {
    switch (unitName) {
        case 'Months':
            return getFutureDateYearWithMonth(new Date(startedDate), value, true, true);
        case 'Days':
            const date = new Date(startedDate);
            const lastDate = date.getDate() + value;
            const futureDate = date.setMonth(date.getMonth(), lastDate);
            return `${DisplayDateFormat(futureDate, GENERAL_DATABASE_DATE_FORMAT)}T00:00:00Z`;
        default:
            return '';
    }
}

export function isInMCPandHasNoticePeriod(minimumMembership, cancellationNotice, startedDate) {
    if (minimumMembership && cancellationNotice) {
        const valueFromBoth = `${minimumMembership.unitName}_${cancellationNotice.unitName}`.toLowerCase();
        const currentTime = new Date().getTime();
        switch (valueFromBoth) {
            case 'months_months': {
                const lastDateMCP = getFutureDateYearWithMonth(new Date(startedDate), minimumMembership.value, true, true);
                const date = new Date(lastDateMCP);
                const lastDateAfterNoticePeriod = new Date(DisplayDateFormat(date.setMonth(date.getMonth() - cancellationNotice.value, date.getDate()), GENERAL_DATABASE_DATE_FORMAT)).getTime();
                return currentTime > lastDateAfterNoticePeriod && currentTime < new Date(lastDateMCP).getTime();
            }
            // TODO: later for 11 cases
            // case 'months_days': {
            //     const lastDateMCP = new Date(getFutureDateYearWithMonth(new Date(startedDate), minimumMembership.value, true, true)).getTime();
            //     const date = new Date(lastDateMCP);
            //     const lastDateAfterNoticePeriod = new Date(DisplayDateFormat(date.setMonth(date.getMonth(), date.getDate() - cancellationNotice.value), GENERAL_DATABASE_DATE_FORMAT)).getTime();
            //     return currentTime >= lastDateAfterNoticePeriod && currentTime <= new Date(lastDateMCP).getTime();
            // }
            default:
                return false;
        }
    }
}

export function hiddenString(string, displayLength) {
    if (string) {
        return '*'.repeat(string.length - displayLength) + string.substring(string.length - displayLength, string.length);
    }
}

export const NumberMoneyFormat = ({ amount, operator = '' }) => {
    const splitedAmount = amount.split('.');
    if (splitedAmount[1]) {
        return (
            <p className="number-pay-now">
                {`${operator}${splitedAmount[0]}`}.
                <sup>{splitedAmount[1]}</sup>
            </p>
        )
    }
    return (
        <p className="number-pay-now">
            {`${operator}${splitedAmount[0]}`}.
            <sup>00</sup>
        </p>
    )
}

export function parseParamFromUrl(url: string) {
    return url.split('&').map(item => {
        const [key, value] = item.split('=');
        return {
            key,
            value
        };
    });
}

export function getParamFromUrl(url: string, key: string) {
    const paramUrl = decodeURIComponent(url).split('?');
    const redirectUrl = paramUrl.find(item => item.indexOf(`${key}=`) > -1);
    const params = redirectUrl && parseParamFromUrl(redirectUrl);

    return params && params.find(item => item.key === key);
}