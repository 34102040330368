import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { FormInput, FormSelect } from 'components/Form';
import Loading from 'components/Loading';
import SubTenantRolePanel from 'components/SubTenantRolePanel/SubTenantRolePanel';
import AdvanceSearchPanel from 'components/AdvanceSearchPanel';

import * as API from 'constants/Constants';

import templateSelectUser from './template.select.users';
import ThisInterface from './interface';
import { getTenantId } from 'util/ControlUtils';

const filterRoleByTenantAndName = {
    and: [
        {
            operation: 'eq',
            queryType: 'boolean',
            queryKey: 'SubTenantId == null',
            queryValue: 'true'
        },
        {
            operation: 'contains',
            queryType: 'text',
            queryKey: 'role.name',
            queryValue: '{value}'
        }
    ]
};

const template = (_this: ThisInterface) => {
    if (_this.state.isLoading) return <Loading />;

    const {
        groupName,
        tenantName,
        tenantRoleEnabled,
        selectedTenantRoles,
        selectedSubTenantRoles,
        selectedSubTenantId,
        isGroupNameValid,
        isSelectedRolesValid,
        disabled
    } = _this.state;

    const { userProfile, subTenants, subTenantRoleEnabled, t } = _this.props;

    return (
        <section className="add-usergroup system-page">
            <p className="title-content">
                {_this.state.groupId == ''
                    ? t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.TITLE_ADD')
                    : t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.TITLE_EDIT')}
            </p>
            <div className="form-add-usergroup">
                <dl className="form-table clearfix">
                    <dt className="font-weight-bold">
                        {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.GROUP_NAME')}
                        <span className="text-primary bold">&nbsp;*</span>
                    </dt>
                    <dd>
                        <FormInput
                            id="group-name"
                            value={groupName}
                            maxlength={50}
                            isDirty={!isGroupNameValid}
                            onChange={e => _this.onGroupNameChange(e)}
                            errorMsg={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.GROUP_NAME_REQUIRED')}
                            require
                        />

                        <Tooltip
                            title={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.GROUP_NAME_INSTRUCTION')}
                            placement="top-start"
                        >
                            <IconButton aria-label="info" className="btn-tooltip">
                                <InfoIcon className="infoIcon" />
                            </IconButton>
                        </Tooltip>
                    </dd>
                </dl>

                <dl className="form-table clearfix">
                    <dt className="font-weight-bold">
                        {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.TENANT')}
                    </dt>
                    <dd>
                        <FormInput value={tenantName || 'EWH'} disabled />
                    </dd>
                </dl>

                <dl className="form-table clearfix">
                    <dt className="font-weight-bold">
                        {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.SUB_TENANT')}
                    </dt>
                    <dd>
                        <FormSelect
                            options={subTenants}
                            value={subTenants.find(
                                item => item.value === selectedSubTenantId
                            )}
                            onChange={_this.onSubTenantSelectedChanged}
                        />
                    </dd>
                </dl>

                <div className="form-group">
                    <span className="font-weight-bold">
                        {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ROLE')}
                    </span>

                    {!isSelectedRolesValid && (
                        <div>
                            <span className="text-danger input-error">
                                {t('MSG.SYSTEM.USERS.ADD_USER.ROLE_REQUIRED')}
                            </span>
                            <br />
                        </div>
                    )}
                </div>

                {userProfile.tenantRoles.length > 0 && (
                    <div className="tenant-roles">
                        <div className="form-group switch-container">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={tenantRoleEnabled}
                                    onChange={e => _this.onTenantRoleSwichChanged(e)}
                                />
                                <span className="slider round" />
                            </label>
                            <span className="label-select-switch">
                                {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.TENANT_LEVEL')}
                            </span>
                        </div>
                        {tenantRoleEnabled && (
                            <AdvanceSearchPanel
                                titleFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ROLES')}
                                placeholderFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ADD_ROLE')}
                                tooltipFirstSelect={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.ROLE_INSTRUCTION')}
                                emptyTagMessage={t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.NO_ROLE_HAS_BEEN_CHOSEN_YET')}
                                firstSelectApi={API.API_SEARCH_ROLES}
                                filterFirstSelect={JSON.stringify(filterRoleByTenantAndName)}
                                selectedSuggestions={selectedTenantRoles}
                                onSuggestionAdded={(suggestion) => _this.handleAddRemoveSingleSuggestion(suggestion, 'selectedTenantRoles')}
                                onSuggestionRemoved={(suggestion) => _this.handleAddRemoveSingleSuggestion(suggestion, 'selectedTenantRoles')}
                                rightName={_this.state.rightName}
                            />
                        )}
                    </div>
                )}

                <div className="sub-teanant-roles">
                    <div className="form-group switch-container">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={subTenantRoleEnabled}
                                onChange={e => _this.onSubTenantRoleSwichChanged(e)}
                            />
                            <span className="slider round" />
                        </label>
                        <span className="label-select-switch">
                            {t('PAGE.SYSTEM.USER_GROUP.ADD_EDIT.SUB_TENANT_LEVEL')}
                        </span>
                    </div>

                    {subTenantRoleEnabled && (
                        <SubTenantRolePanel
                            parent={'userGroup'}
                            searchRoleApi={API.API_SEARCH_ROLES}
                            searchClubGroupApi={API.API_SEARCH_CLUBGROUP.replace(
                                '{1}',
                                getTenantId()
                            )}
                            selectedSubTenantRoles={selectedSubTenantRoles}
                            onRoleAdded={(subId, roles) =>
                                _this.onSubTenantRole(subId, roles, 'add')
                            }
                            onRoleDeleted={(subId, roles) =>
                                _this.onSubTenantRole(subId, roles, 'delete')
                            }
                            onRoleDisabled={subId =>
                                _this.onSubTenantRole(subId, [], 'disabled')
                            }
                        />
                    )}
                </div>
                {templateSelectUser(_this)}
                <div className="row no-gutters justify-content-end">
                    <div className="row-btn">
                        <button
                            className="btn btn-primary"
                            onClick={_this.handleSubmit}
                            disabled={disabled}
                        >
                            {t('BUTTON.SAVE')}
                        </button>

                        <button className="btn btn-dark" onClick={_this.handleCancel}>
                            {t('BUTTON.CANCEL')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
