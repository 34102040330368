import React from 'react';
import _ from 'lodash';
import FormItem from 'components/FormV2';
import CustomTable from 'components/Table';
import Pagination from 'components/Pagination';
import Loading from 'components/Loading';
import {
    GENERAL_DATABASE_DATE_FORMAT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import { DisplayDateFormat } from 'util/ControlUtils';
import ThisInterface, { Field } from './interface';

const itemPerPages = 10;

const allPriceHeader = [
    {
        key: 'subTenant',
        title: 'PAGE.PRODUCTS.ITEMS.COLUMN.SUBTENANT'
    },
    {
        key: 'clubGroup',
        title: 'PAGE.PRODUCTS.ITEMS.COLUMN.CLUBGROUP'
    },
    {
        key: 'unitPrice',
        title: 'PAGE.PRODUCTS.ITEMS.COLUMN.UNIT_PRICE'
    },
    {
        key: 'effectiveDate',
        title: 'PAGE.PRODUCTS.ITEMS.COLUMN.EFFECTIVE_DATE'
    },
    {
        key: 'expirationDate',
        title: 'PAGE.PRODUCTS.ITEMS.COLUMN.EXPIRATION_DATE'
    },
    {
        key: 'prority',
        title: 'PAGE.PRODUCTS.ITEMS.COLUMN.PRIORITY',
        className: 'priority'
    },
    {
        key: 'action',
        title: '',
        className: 'action',
        icon: 'icon icon-dots-horizontally'
    }
];

const ButtonItem = ({
    type = 'primary',
    disabled = false,
    title,
    onClick = () => { }
}) => {
    return (
        <li className="group-btn">
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const renderTableContent = (_this: ThisInterface) => {
    const { priceList, currentPage } = _this.state;
    const { t } = _this.props;
    const arrayList: Field[] = [];

    if (!_.isEmpty(priceList)) {
        for (
            let i = (currentPage - 1) * itemPerPages;
            i < itemPerPages * currentPage;
            i++
        ) {
            arrayList.push(priceList[i]);
        }

        return arrayList
            .filter(item => item)
            .map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{item.subTenant.label}</td>
                        <td>{item.clubGroup.label}</td>
                        <td>{Number(item.priceAmount.toFixed(2)).toLocaleString()}</td>
                        <td>
                            {DisplayDateFormat(item.priceEffectiveDate, GENERAL_DATE_FORMAT)}
                        </td>
                        <td>
                            {DisplayDateFormat(item.priceExpireDate, GENERAL_DATE_FORMAT)}
                        </td>
                        <td className="priority">{item.priority}</td>
                        <td className="action">
                            <button className="btn btn-default" onClick={() => _this.handleDeletePrice(index)} >
                                {t('BUTTON.DELETE')}
                            </button>
                        </td>
                    </tr>
                );
            });
    }

    return (
        <tr className="no-data text-center">
            <td colSpan={5}>{t('COMMON.NO_DATA')}</td>
        </tr>
    );
};

const template = (_this: ThisInterface) => {
    const {
        feeCode,
        feeName,
        field: {
            subTenant,
            clubGroup,
            priceAmount,
            priority,
            priceEffectiveDate,
            priceExpireDate
        },
        formField,
        subTenants,
        clubGroups,
        priorities,
        priceList,
        currentPage,
        isLoading,
        isEditMode
    } = _this.state;
    const { t } = _this.props;

    const feeColumns = [
        {
            label: 'PAGE.PRODUCTS.ITEMS.FEE.ITEM_CODE',
            value: feeCode
        },
        {
            label: 'PAGE.PRODUCTS.ITEMS.FEE.NAME',
            value: feeName
        }
    ];

    const className = 'col-12 col-md-6 mt-1';
    const currentDate = new Date(
        DisplayDateFormat(new Date(), GENERAL_DATABASE_DATE_FORMAT)
    );
    const yesterdate = new Date(currentDate.setDate(currentDate.getDate() - 1));
    const effectiveDate = new Date(
        DisplayDateFormat(priceEffectiveDate, GENERAL_DATABASE_DATE_FORMAT)
    );

    const sellingItem = [
        {
            child: FormItem,
            className,
            props: {
                type: 'select',
                htmlFor: 'subTenant',
                fieldName: 'subTenant',
                data: subTenant,
                attribute: {
                    disabled: isEditMode,
                    isClearable: false
                },
                fieldLabel: 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.SUBTENANT',
                options: subTenants
            }
        },
        {
            child: null,
            className
        },
        {
            child: FormItem,
            className,
            props: {
                type: 'select',
                htmlFor: 'clubGroup',
                fieldName: 'clubGroup',
                data: clubGroup,
                attribute: {
                    disabled: subTenant === 'all',
                    isClearable: false
                },
                fieldLabel: 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.CLUBGROUP',
                options: clubGroups
            }
        },
        {
            child: FormItem,
            className,
            props: {
                type: 'currency-number',
                htmlFor: 'priceAmount',
                fieldName: 'priceAmount',
                data: Number(priceAmount.toFixed(2)).toLocaleString(),
                fieldLabel: 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.PRICE_AMOUNT'
            }
        },
        {
            child: FormItem,
            className,
            props: {
                type: 'select',
                htmlFor: 'priority',
                fieldName: 'priority',
                data: priority,
                fieldLabel: 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.PRIORITY',
                options: priorities,
                attribute: {
                    isClearable: false
                }
            }
        },
        {
            child: FormItem,
            className: 'col-6 col-md-3 mt-1',
            props: {
                type: 'calendar',
                htmlFor: 'priceEffectiveDate',
                fieldName: 'priceEffectiveDate',
                data: priceEffectiveDate,
                datePickerData: new Date(priceEffectiveDate),
                fieldLabel: 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EFFECTIVE_DATE',
                attribute: {
                    minDate: yesterdate
                }
            }
        },
        {
            child: FormItem,
            className: 'col-6 col-md-3 mt-1',
            props: {
                type: 'calendar',
                htmlFor: 'priceExpireDate',
                fieldName: 'priceExpireDate',
                data: priceExpireDate,
                datePickerData: new Date(priceExpireDate),
                fieldLabel: 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EXPIRATION_DATE',
                attribute: {
                    minDate: new Date(effectiveDate.setDate(effectiveDate.getDate() - 1))
                },
                fields: [formField.priceExpireDate]
            }
        }
    ];

    return (
        <section>
            <div className="add-edit-selling-prices">
                <div className="title-content mb-3">
                    {t('PAGE.PRODUCTS.ITEMS.SELLING_PRICE.ADD_SELLING_PRICE')}
                </div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="member">
                        <div className="member-main member-main--custom">
                            <div className="content">
                                <div className="collapse-promotion collapse-saas">
                                    <div className="collapsible">
                                        {t('PAGE.PRODUCTS.ITEMS.FEE.FEE_ITEM')}
                                    </div>
                                    <div className="content open">
                                        {t('PAGE.PRODUCTS.ITEMS.FEE.FEE_ITEM_HINT')}
                                        <div className="row w-100">
                                            {feeColumns.map((field, key) => (
                                                <div key={key} className="col-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-4 col-lg-3">
                                                            {t(field.label)}
                                                        </div>
                                                        <div className="col-md-4 col-lg-3">{field.value}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="collapsible">
                                        {t(
                                            'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.ADD_ITEM_SELLING_PRICE'
                                        )}
                                    </div>
                                    <div className="content open">
                                        <div className="row selling-price-1">
                                            {sellingItem.map((field, key) => (
                                                <div key={key} className={field.className}>
                                                    {field.child && (
                                                        <field.child
                                                            {...field.props}
                                                            fieldLabel={t(field.props.fieldLabel)}
                                                            onChangeHandle={
                                                                (fieldName, value) =>
                                                                    fieldName === 'subTenant' && isEditMode
                                                                        ? null
                                                                        : _this.onChangeHandle(fieldName, value)
                                                            }
                                                            fields={
                                                                field.props.fields
                                                                    ? field.props.fields
                                                                    : []
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {!isEditMode && (
                                    <React.Fragment>
                                        <div className="group-btn d-flex justify-content-start pl-0">
                                            <button
                                                className="btn btn-primary"
                                                onClick={_this.handleAddPrice}
                                                disabled={_.isEmpty(clubGroup) || _.isEmpty(priorities)}
                                            >
                                                {t('PAGE.PRODUCTS.ITEMS.SELLING_PRICE.BUTTON.ADD_PRICE')}
                                            </button>
                                        </div>
                                        <div className="collapse-promotion collapse-saas">
                                            <div className="collapsible">
                                                {t('PAGE.PRODUCTS.ITEMS.SELLING_PRICE.ALL_PRICES')}
                                            </div>
                                            <div className="content open">
                                                <CustomTable
                                                    renderingCondition
                                                    headers={allPriceHeader}
                                                    tableContent={renderTableContent(_this)}
                                                    maxHeigth="auto"
                                                    className="selling-price-table"
                                                    tfoot={
                                                        !_.isEmpty(priceList) && (
                                                            <tr>
                                                                <td>
                                                                    <Pagination
                                                                        itemsCountPerPage={itemPerPages}
                                                                        totalItemsCount={priceList.length}
                                                                        activePage={currentPage}
                                                                        onPageChange={_this.handleChangePage}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                <ul className="list-btn-action">
                                    <ButtonItem
                                        title={t('BUTTON.SAVE')}
                                        onClick={() => !(isEditMode ? !isEditMode : _.isEmpty(priceList)) && _this.handleSave()}
                                        disabled={isEditMode ? !isEditMode : _.isEmpty(priceList)}
                                    />
                                    <ButtonItem
                                        title={t('BUTTON.CANCEL')}
                                        type="dark"
                                        onClick={_this.handleCancel}
                                    />
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default template;
