import React from 'react';
import { connect } from 'react-redux';
import * as Interface from 'interfaces';
import Loading from 'components/Loading';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import template from './template';
import ThisInterface from './interface';
import OperatorPagination from 'models/OperatorPagination';
import observer from 'util/Observer';
import { withTranslation, WithTranslation } from 'react-i18next';

class ImportMemberPage extends React.Component<
Interface.PagePropsInterface & WithTranslation,
ThisInterface['state']
> {
    inputFile: HTMLInputElement | null = null;
    constructor(props: Interface.PagePropsInterface & WithTranslation) {
        super(props);
        this.state = {
            fileName: '',
            fileSize: 0,
            isCollapse: true,
            file: null,
            errorMessage: '',
            isLoading: false,
            currentPage: 1,
            operatorPagination: new OperatorPagination(),
            operatorList: []
        };
    }

    handleOpenFile = () => {
        if (this.inputFile) {
            this.inputFile.click();
        }
    };

    handleSelectFile = () => {
        const { inputFile } = this;
        if (inputFile!.files && inputFile!.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    fileName: inputFile!.files![0].name,
                    file: inputFile!.files![0],
                    fileSize: inputFile!.files![0].size
                });
            };
            reader.readAsDataURL(inputFile!.files[0]);
            observer.publish('flagUnsavedChangeEvent', true);
        }
    };

    handleOpenCollapseBtn = () => {
        this.setState(prevState => {
            return {
                isCollapse: !prevState.isCollapse
            };
        });
    };

    setErrorMessage = (msg: string) => {
        this.setState({
            errorMessage: msg
        });
    };

    handleUploadFile = () => {
        const { fileName, fileSize } = this.state;
        const { t } = this.props;
        if (fileName === '') {
            this.setErrorMessage('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.PLEASE_SELECT_A_FILE_TO_IMPORT');
            return;
        }
        if (fileName.split('.')[1] !== 'csv') {
            this.setErrorMessage('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.NOT_FILE_TYPE');
        } else {
            if (fileSize < 60) {
                this.setErrorMessage('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.EMPTY_FIELD');
                return;
            }
            if (fileSize > 5248021) {
                this.setErrorMessage('PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.FILE_SIZE');
                return;
            } else {
                this.setState(
                    {
                        errorMessage: '',
                        isLoading: true,
                        currentPage: 1
                    },
                    () => {
                        this.props.conmanService.importOperator(this.state.file!).then(
                            res => {
                                this.setState(
                                    {
                                        isLoading: false
                                    },
                                    () => {
                                        if (res.message === 'success') {
                                            this.setState({
                                                operatorPagination: res,
                                                errorMessage: ''
                                            });
                                            if (res.metadata.TotalItemSuccess > 0 
                                                    && res.data.length === 0) {
                                                Swal.fire({
                                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                                    type: 'success',
                                                    html: 'PAGE.CONFIG.SUPPORT_TOOLS.BULK_IMPORT_ACCOUNT.SUCCESS',
                                                    confirmButtonText: 'OK'
                                                });
                                                this.setState({
                                                    operatorList: []
                                                });
                                                observer.publish('flagUnsavedChangeEvent', false);
                                            } else {
                                                this.setState({
                                                    operatorList: res.data
                                                });
                                            }
                                        } else {
                                            this.setState({
                                                errorMessage: t(res.data ),
                                                operatorList: []
                                            });
                                        }
                                    }
                                );
                            },
                            () => {
                                this.setState({
                                    isLoading: false
                                });
                            }
                        );
                    }
                );
            }
        }
    };

    handleChangePage = (pageIndex: number) => {
        this.props.conmanService
            .getImportOperatorPagination(pageIndex)
            .then(res => {
                this.setState({
                    operatorPagination: res,
                    operatorList: res.data,
                    currentPage: pageIndex
                });
            });
    };

    render() {
        const { conman } = this.props;
        return conman!.operator ? template(this) : <Loading />;
    }
}

const mapStateToProps = ({
    conman
}: {
    conman: Interface.PagePropsInterface['conman'];
}) => {
    return {
        conman
    };
};

export default connect(mapStateToProps)(withTranslation()(ImportMemberPage));
