import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import { MatchParams, EditPlanAvailibilityInterface } from 'pages/Products/Plan/Template/interface';
import { PlanAvailabilityModel } from 'models/Plan';
import { FieldModel } from 'models/Field';
import * as Route from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import { DisplayDateFormat, getSubTenantId } from 'util/ControlUtils';
import observer from 'util/Observer';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import {withTranslation, WithTranslation} from 'react-i18next'
import { matchField, matchFieldNested, getRawValue, isValidOrNot, updateFieldValid } from 'components/FormV2';

class PlanEditAvailability extends React.Component<
EditPlanAvailibilityInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation,
EditPlanAvailibilityInterface['state']
> {
    constructor(props: EditPlanAvailibilityInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation) {
        super(props);
        
        // Get subTenantId & rightName
        const { location: { state } } = props;
        let subTenantId = getSubTenantId();
        if (state && state.subTenantId) {
            subTenantId = state.subTenantId;
        }
        const rightName = RouteLoader.PlanEditAvailabilityRoute.rightName;

        this.state = {
            planDetail: new PlanAvailabilityModel(),
            planDetailDefault: new PlanAvailabilityModel(),
            step: 1,
            fields: [],
            isLoading: true,
            disabled: true,
            subTenantId: subTenantId,
            rightName: rightName
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const { subTenantId, rightName } = this.state;
        const getPlanData = this.props.planService.getPlanAvailabilityData(id, subTenantId, rightName).then((res) => {
            let planDetail: PlanAvailabilityModel = res;
            planDetail.id = id;
            this.setState({
                planDetail: planDetail,
                planDetailDefault: PlanAvailabilityModel.clone(planDetail)
            });
            observer.publish('plan.clubGroups.changed', true);
        }, () => {
            this.props.history.push(Route.ROUTE_PLAN_LIST);
        });

        const getFields = this.props.planService.getFields(subTenantId, rightName).then((res) => {
            let fields: FieldModel[] = res.data;
            fields = fields.map(e => ({
                ...new FieldModel(), ...e,
                isValid: true,
                isValidType: 'none'
            }));
            this.setState({
                fields: fields
            });
        });


        Promise.all([
            getPlanData,
            getFields
        ]).then(() => {
            this.setState({ isLoading: false });
        });
    }

    selectStep(step) {
        const isValid = this.checkValidations();
        if (isValid) {
            this.setState({ step: step });
        }
    }

    onCancel() {
        this.props.history.push(Route.ROUTE_PLAN_LIST);
    }

    onSubmit() {
        const { subTenantId, rightName } = this.state;
        const { t } = this.props;
        const isValid = this.checkValidations();
        if (isValid) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('MSG.PRODUCTS.PLAN.CONFIRM_SAVE_PLAN'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO'),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                        showConfirmButton: false,
                        allowOutsideClick: false
                    })
                    const { planDetail } = this.state;
                    this.props.planService.putPlanAvailabilityData(planDetail.id, PlanAvailabilityModel.buildPutData(planDetail, subTenantId, rightName)).then(() => {
                        observer.publish('flagUnsavedChangeEvent', false);
                        this.setState({
                            disabled: true
                        }, () => {
                            Swal.fire({
                                ...DefaultConstants.SWAL_COMMON_STYLE,
                                type: 'success',
                                html: t('MSG.SAVE_SUCCESS'),
                                confirmButtonText: t('BUTTON.CLOSE')
                            }).then(() => {
                                this.props.history.push(Route.ROUTE_PLAN_LIST);
                            })
                        });
                    });
                }
            });
        }
    }

    checkValidations() {
        let isValid = true;
        const fields = this.state.fields.map(e => {
            e.isDirty = true;
            if (e.nested) {
                e.nested = e.nested.map(fieldNested => {
                    fieldNested.isDirty = true;
                    if (isValidOrNot(fieldNested.isValid, fieldNested.isRequired, fieldNested.regex, fieldNested.customMsg)) {
                        isValid = false;
                    }
                    return fieldNested;
                })
            }
            if (isValidOrNot(e.isValid, e.isRequired, e.regex, e.customMsg)) {
                isValid = false;
            }
            return e;
        });
        this.setState({
            fields: fields
        }, () => {
            if (!isValid) {
                const element: HTMLElement | null = document.querySelector('.error-holder input, .error-holder textarea, .error-holder select, .error-holder .icon-checked-ico:before, .error-holder .select__control');
                if (element) {
                    element.focus();
                }
            }
        });
        return isValid;
    }

    checkValidation(nameHtml, valueHtml, nestedNameData) {
        const fields = this.state.fields.map(e => {
            const extractNameValidation = e.key.split('.');
            const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
            if (matchField(nameHtml, shortNameValidation)) {
                if (e.nested) {
                    e.nested = e.nested.map(fieldNested => {
                        if (matchFieldNested(nameHtml, fieldNested.key)) {
                            const rawValue = getRawValue(valueHtml, fieldNested.key, nestedNameData);
                            fieldNested = updateFieldValid(fieldNested, rawValue);
                        }
                        return fieldNested;
                    })
                } else {
                    const rawValue = getRawValue(valueHtml, null, null);
                    e = updateFieldValid(e, rawValue);
                }
            }
            return e;
        });
        this.setState({
            fields: fields
        })
    }

    setPlanDetailStateByProperty(nameData, nameHtml, valueHtml, nestedNameData = '') {
        this.setState(prevState => ({
            planDetail: {
                ...prevState.planDetail, [nameData]: valueHtml
            }
        }), () => this.checkValidation(nameHtml, valueHtml, nestedNameData));
    }

    onChangeHandle(nameHtml, valueHtml) {
        let { planDetail } = this.state;
        const { t } = this.props;
        let fields: FieldModel[] = [];
        switch (nameHtml) {
            case 'availability[availableFrom]':
                let availableFrom = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    availableFrom = DisplayDateFormat(valueHtml, Route.GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00';
                    if (isNaN(Date.parse(availableFrom))) {
                        availableFrom = '';
                    }
                }
                this.setPlanDetailStateByProperty('availableFrom', nameHtml, availableFrom);
                /* Custom validation of date from & date to */
                fields = this.state.fields.map(e => {
                    const extractNameValidation = e.key.split('.');
                    const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
                    if (matchField(nameHtml, shortNameValidation)) {
                        if (availableFrom > planDetail.availableTo) {
                            e.customMsg = 'PAGE.PRODUCTS.PLANS.EDIT.ERR_DATE_FROM_LESS_THAN_DATE_TO';
                        } else {
                            e.customMsg = '';
                        }
                    }
                    if (matchField('availability[availableTo]', shortNameValidation)) {
                        e.customMsg = '';
                    }
                    return e;
                });
                this.setState({
                    fields: fields
                })
                break;
            case 'availability[availableTo]':
                let availableTo = '';
                if (!isNaN(Date.parse(valueHtml))) {
                    availableTo = DisplayDateFormat(valueHtml, Route.GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00';
                    if (isNaN(Date.parse(availableTo))) {
                        availableTo = '';
                    }
                }
                this.setPlanDetailStateByProperty('availableTo', nameHtml, availableTo);
                /* Custom validation of date from & date to */
                fields = this.state.fields.map(e => {
                    const extractNameValidation = e.key.split('.');
                    const shortNameValidation = extractNameValidation[extractNameValidation.length - 1];
                    if (matchField(nameHtml, shortNameValidation)) {
                        if (planDetail.availableFrom > availableTo) {
                            e.customMsg = 'PAGE.PRODUCTS.PLANS.EDIT.ERR_DATE_TO_GREATER_THAN_DATE_FROM';
                        } else {
                            e.customMsg = '';
                        }
                    }
                    if (matchField('availability[availableFrom]', shortNameValidation)) {
                        e.customMsg = '';
                    }
                    return e;
                });
                this.setState({
                    fields: fields
                })
                break;
            case 'availability[requirePermissionToSell]':
                this.setPlanDetailStateByProperty('isRequiresPermissionSellRestrictedItem', nameHtml, !planDetail.isRequiresPermissionSellRestrictedItem);
                break;
            case 'availability[availableSoldTelesale]':
                this.setPlanDetailStateByProperty('isAvailableForTeleSales', nameHtml, !planDetail.isAvailableForTeleSales);
                break;
            case 'availability[availableSoldWeb]':
                this.setPlanDetailStateByProperty('isAvailableForWeb', nameHtml, !planDetail.isAvailableForWeb);
                break;
            case 'availability[availableSoldClub]':
                this.setPlanDetailStateByProperty('isAvailableForClub', nameHtml, !planDetail.isAvailableForClub);
                break;
            case 'availability[clubGroups]':
                const clubGroups = valueHtml.map(e => ({ id: e }));
                this.setPlanDetailStateByProperty('clubGroups', nameHtml, clubGroups);
                observer.publish('plan.clubGroups.changed', true);
                break;
            default:
                break;
        }
        this.setState({
            disabled: false
        });
        observer.publish('flagUnsavedChangeEvent', true);
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(PlanEditAvailability)));
