//HTML content only
import HTMLPage from 'demo';
import React from 'react';
import ChangePasswordPage from './ChangePasswordPage';
import ResetPasswordPage from './ConfigurationManagement/ResetPassword';
import ImportMemberPage from './ConfigurationManagement/SupportTools/BulkImportAccount';
import AppearanceSettingPage from './ConfigurationManagement/SystemConfig/AppearanceSetting';
import EditProfilePage from './EditProfilePage';
import AddNewMemberPage from './MembershipManagement/AddNewMemberPage';
import ChangePersonalDetailsPage from './MembershipManagement/ChangePersonalDetailsPage';
import MemberListPage from './MembershipManagement/MemberListPage';
import MemberSummaryPage from './MembershipManagement/MemberSummaryPage';
import ProductPage from './Products/Items';
import PackagePage from './Products/Package';
import PackageDetailPage from './Products/Package/PackageDetail';
import PlanPage from './Products/Plan';
import PlanClonePage from './Products/Plan/PlanClone';
import PlanEditPage from './Products/Plan/PlanEdit';
import PlanEditAvailabilityPage from './Products/Plan/PlanEditAvailability';
import PlanQuickClonePage from './Products/Plan/PlanQuickClone';
import PlanQuickEditPage from './Products/Plan/PlanQuickEdit';
import PlanViewDetailPage from './Products/Plan/PlanViewDetail';
import FeeListPage from './Products/Items/Fee';
import DetailFeePage from './Products/MFItems/FeeDetail';
import MFItemsPage from './Products/MFItems';
import AddFieldsConfigPage from './SystemPage/AddFieldsConfiguration';
import LookUpsPage from './SystemPage/Lookups';
import AddUpdateLookUpPage from './SystemPage/Lookups/AddUpdateLookup';
import AddRolePage from './SystemPage/Roles/AddRole';
import RoleListPage from './SystemPage/Roles/RoleList';
import ServiceVersionPage from './SystemPage/ServiceVersion';
import TenderClassListPage from './SystemPage/Tender/TenderClass';
import AddEditTenderClassPage from './SystemPage/Tender/TenderClass/AddEditTenderClass';
import TenderTypeListPage from './SystemPage/Tender/TenderType';
import AddEditTenderTypePage from './SystemPage/Tender/TenderType/AddEditTenderType';
import AddUserGroupPage from './SystemPage/UserGroup/AddUserGroup';
import UserGroupListPage from './SystemPage/UserGroup/UserGroupList';
import AddUserPage from './SystemPage/Users/AddUser';
import UserListPage from './SystemPage/Users/UserList';
import SellingPricePage from './Products/Items/SellingPrice';

import AddonListPage from './Products/CMAddon';
import AddEditAddonPage from './Products/CMAddon/AddEditAddon';

import mfAddEditFee from './Products/MFItems/AddEditFee';
import CMClubsPage from './ConfigurationManagement/CMClubs';
import cmAddEditClub from './ConfigurationManagement/CMClubs/AddEditClub';
import MFPromotionPage from './Products/MFPromotion';
import mfAddEditPromotion from './Products/MFPromotion/AddEditPromotion';
import EditPromotionAvailability from './Products/MFPromotion/EditAvailability';

import ClubListPage from './Clubs/MFClubList';
import ClubDetailPage from './Clubs/MFClubDetail';
import mfClubFeatures from './Clubs/MFClubDetailFeatures';


const EmptyContent = () => {
    return <></>;
};

export const HTMLPageRoute = {
    url: 'demo/html',
    Component: HTMLPage,
    rightName: ''
};

export const HTMLPageRouteId = {
    url: 'demo/html/:name',
    Component: HTMLPage,
    rightName: ''
};

export const AddonListRoute = {
    url: 'products/addons',
    Component: AddonListPage,
    rightName: 'view_addon_list'
};
export const AddAddonRoute = {
    url: 'products/addons/create',
    Component: AddEditAddonPage,
    rightName: 'add_addon'
};
export const EditAddonRoute = {
    url: 'products/addons/:id/edit',
    Component: AddEditAddonPage,
    rightName: 'edit-addon'
};
export const EditSimpleAddonRoute = {
    url: 'products/addons/:id/edit-simple',
    Component: AddEditAddonPage,
    rightName: 'quick-edit-addon'
};

export const EditProfileRoute = {
    url: 'edit-profile',
    Component: EditProfilePage,
    rightName: 'edit_profile'
};
export const ImportMemberRoute = {
    url: 'config/import-member',
    Component: ImportMemberPage,
    rightName: 'bulk_import_azure_id'
};
export const AppearanceSettingRoute = {
    url: 'config/appearance-setting',
    Component: AppearanceSettingPage,
    rightName: 'change_appearance_setting'
};
export const ResetPasswordRoute = {
    url: 'config/reset-password',
    Component: ResetPasswordPage,
    rightName: 'reset_password'
};
export const ChangePasswordRoute = {
    url: 'config/change-password',
    Component: ChangePasswordPage,
    rightName: ''
};
export const RoleListRoute = {
    url: 'system/roles',
    Component: RoleListPage,
    rightName: 'view_role_list'
};
export const AddRoleRoute = {
    url: 'system/roles/create',
    Component: AddRolePage,
    rightName: 'add_role'
};
export const EditRoleRoute = {
    url: 'system/roles/:id/edit',
    Component: AddRolePage,
    rightName: 'edit_role'
};
export const UserListRoute = {
    url: 'system/users',
    Component: UserListPage,
    rightName: 'view_user_list'
};
export const AddUserRoute = {
    url: 'system/users/create',
    Component: AddUserPage,
    rightName: 'add_user'
};
export const EditUserRoute = {
    url: 'system/users/:id/edit',
    Component: AddUserPage,
    rightName: 'edit_user'
};
export const UserGroupListRoute = {
    url: 'system/user-group',
    Component: UserGroupListPage,
    rightName: 'view_user_group_list'
};
export const AddUserGroupRoute = {
    url: 'system/user-group/create',
    Component: AddUserGroupPage,
    rightName: 'add_user_group'
};
export const EditUserGroupRoute = {
    url: 'system/user-group/:id/edit',
    Component: AddUserGroupPage,
    rightName: 'edit_user_group'
};
export const ProductRoute = {
    url: 'products/items',
    Component: ProductPage,
    rightName: 'view_product'
};
export const PlanRoute = {
    url: 'products/plans',
    Component: PlanPage,
    rightName: 'view_plan_list'
};
export const PlanViewDetailRoute = {
    url: 'products/plans/:id/view-detail',
    Component: PlanViewDetailPage,
    rightName: 'view_plan_detail'
};
export const PlanQuickEditRoute = {
    url: 'products/plans/:id/quick-edit',
    Component: PlanQuickEditPage,
    rightName: 'quick_edit_plan'
};
export const PlanEditRoute = {
    url: 'products/plans/:id/edit',
    Component: PlanEditPage,
    rightName: 'edit_plan'
};
export const PlanEditAvailabilityRoute = {
    url: 'products/plans/:id/edit-availability',
    Component: PlanEditAvailabilityPage,
    rightName: 'edit_plan_availability'
};
export const PlanQuickCloneRoute = {
    url: 'products/plans/:id/quick-clone',
    Component: PlanQuickClonePage,
    rightName: 'quick_clone_plan'
};
export const PlanCloneRoute = {
    url: 'products/plans/:id/clone',
    Component: PlanClonePage,
    rightName: 'clone_plan'
};
export const AddNewMemberRoute = {
    url: 'memberships/create',
    Component: AddNewMemberPage,
    rightName: 'add_member'
};
export const ChangeMembershipRoute = {
    url: 'memberships/members/:id/edit',
    Component: AddNewMemberPage,
    rightName: 'change_membership'
};
export const MemberListRoute = {
    url: 'memberships/members',
    Component: MemberListPage,
    rightName: 'view_member_list'
};
export const MemberSummaryRoute = {
    url: 'memberships/members/:id/view',
    Component: MemberSummaryPage,
    rightName: 'view_member_detail'
};
export const ChangePersonalDetailsRoute = {
    url: 'memberships/members/:id/change-personal-details',
    Component: ChangePersonalDetailsPage,
    rightName: 'edit_member'
};

export const PackageRoute = {
    url: 'products/packages',
    Component: PackagePage,
    rightName: 'view_package_list'
};
export const PackageDetailRoute = {
    url: 'products/packages/:id/view-detail',
    Component: PackageDetailPage,
    rightName: 'view_package_detail'
};

export const ServiceVersionRoute = {
    url: 'system/build-version',
    Component: ServiceVersionPage,
    rightName: 'view_system_version'
};
export const AddFieldsConfigRoute = {
    url: 'system/fields-config',
    Component: AddFieldsConfigPage,
    rightName: 'edit_feature_configuration'
};

export const LookUpsRoute = {
    url: 'system/lookups',
    Component: LookUpsPage,
    rightName: 'view_lookup_list'
};
export const AddLookUpRoute = {
    url: 'system/lookups/create',
    Component: AddUpdateLookUpPage,
    rightName: 'add_lookup'
};
export const EditLookUpRoute = {
    url: 'system/lookups/:id/edit',
    Component: AddUpdateLookUpPage,
    rightName: 'edit_lookup'
};

export const TenderClassRoute = {
    url: 'system/tender-class',
    Component: TenderClassListPage,
    rightName: 'view_tender_class_list'
};
export const AddTenderClassRoute = {
    url: 'system/tender-class/create',
    Component: AddEditTenderClassPage,
    rightName: 'add_tender_class'
};
export const EditTenderClassRoute = {
    url: 'system/tender-class/:id/edit',
    Component: AddEditTenderClassPage,
    rightName: 'edit_tender_class'
};
export const ViewTenderClassRoute = {
    url: 'system/tender-class/:id/view',
    Component: AddEditTenderClassPage,
    rightName: 'view_tender_class_detail'
};


export const FeeRoute = {
    url: 'products/fee',
    Component: FeeListPage,
    rightName: 'view_fee_list'
};

// Comment for MF Items
export const ItemRoute = {
    url: 'product/items',
    Component: MFItemsPage,
    rightName: 'view_item_list'
};

export const AddFeeRoute = {
    url: 'products/fee/create',
    Component: mfAddEditFee('AddFeeRoute', 'PAGE.PRODUCTS.ITEMS.FEE.ADD_NEW_ITEM'),
    rightName: 'add_fee_item'
};
export const EditFeeRoute = {
    url: 'products/fee/:id/edit',
    Component: mfAddEditFee('EditFeeRoute', 'PAGE.PRODUCTS.ITEMS.FEE.EDIT_ITEM'),
    rightName: 'edit_fee_item'
};
export const ViewFeeRoute = {
    url: 'products/fee/:id/view',
    Component: DetailFeePage,
    rightName: 'view_fee_item_detail'
};

export const PromotionRoute = {
    url: 'product/promotions',
    Component: MFPromotionPage,
    rightName: 'view_promotion_list'
};

export const AddPromotionRoute = {
    url: 'product/promotions/create',
    Component: mfAddEditPromotion('AddPromotionRoute', 'PAGE.PRODUCTS.PROMOTIONS.ADD_EDIT'),
    rightName: 'add_promotion'
};

export const EditPromotionRoute = {
    url: 'product/promotions/:id/edit',
    Component: mfAddEditPromotion('EditPromotionRoute', 'PAGE.PRODUCTS.PROMOTIONS.ADD_EDIT'),
    rightName: 'edit_promotion'
};

export const EditPromotionAvailabilityRoute = {
    url: 'product/promotions/:id/editPromotionAvailability',
    Component: EditPromotionAvailability,
    rightName: 'change_availability'
}

export const TenderTypesRoute = {
    url: 'system/tender-type',
    Component: TenderTypeListPage,
    rightName: 'view_tender_type_list'
};
export const AddTenderTypeRoute = {
    url: 'system/tender-type/create',
    Component: AddEditTenderTypePage,
    rightName: 'add_tender_type'
};
export const EditTenderTypeRoute = {
    url: 'system/tender-type/:id/edit',
    Component: AddEditTenderTypePage,
    rightName: 'edit_tender_type'
};
export const ViewTenderTypeRoute = {
    url: 'system/tender-type/:id/view',
    Component: AddEditTenderTypePage,
    rightName: 'view_tender_type_detail'
};

export const ViewChangePaymentDetail = {
    url: '',
    Component: EmptyContent,
    rightName: 'change_payment_detail'
};

export const ViewRequestFreezeMemberShip = {
    url: '',
    Component: EmptyContent,
    rightName: 'request_freeze_membership'
};

export const MakePaymentRoute = {
    url: '',
    Component: EmptyContent,
    rightName: 'make_payment'
};

export const ViewModifyFreezeMemberShip = {
    url: '',
    Component: EmptyContent,
    rightName: 'modify_freeze_membership'
};

export const ViewRemoveFreezeMemberShip = {
    url: '',
    Component: EmptyContent,
    rightName: 'remove_freeze_membership'
};

export const UnfreezeMembershipRoute = {
    url: '',
    Component: EmptyContent,
    rightName: 'unfreeze_membership'
};

export const CancelMembershipRoute = {
    url: '',
    Component: EmptyContent,
    rightName: 'cancel_membership'
};

export const RemoveChangeMembershipRoute = {
    url: '',
    Component: EmptyContent,
    rightName: 'remove_change_membership'
};

export const RemoveCancelMembershipRoute = {
    url: '',
    Component: EmptyContent,
    rightName: 'remove_cancel_membership'
};

export const AddSellingRoute = {
    url: 'products/fee/:id/selling/create',
    Component: SellingPricePage,
    rightName: 'add_selling_price'
};

export const EditSellingPrice = {
    url: 'products/fee/:id/selling/edit/:sellingPriceId',
    Component: SellingPricePage,
    rightName: 'edit_selling_price'
};

export const CMClubsPageRoute = {
    url: 'config/clubs',
    Component: CMClubsPage,
    rightName: 'configuration_club_list'
};

export const CMAddClubPageRoute = {
    url: 'config/clubs/create',
    Component: cmAddEditClub('CMAddClubPageRoute', 'PAGE.CONMAN.CLUBS.ADD_NEW_ITEM'),
    rightName: 'configuration_add_club'
};

export const CMEditClubPageRoute = {
    url: 'config/clubs/:id/edit',
    Component: cmAddEditClub('CMEditClubPageRoute', 'PAGE.CONMAN.CLUBS.EDIT_ITEM'),
    rightName: 'configuration_edit_club'
};

export const ClubBankingRoute = {
    url: 'clubs/:id/club-banking',
    Component: mfClubFeatures('ClubBankingRoute', 'PAGE.MF.CLUBS.CLUB.BANKING'),
    rightName: 'club_banking'
};

export const ClubListPageRoute = {
    url: 'clubs/list',
    Component: ClubListPage,
    rightName: 'view_club_list'
};

export const ClubDetailPageRoute = {
    url: 'clubs/detail/:id/view',
    Component: ClubDetailPage,
    rightName: 'view_club_detail' 
};

export const ClubOutStandingShift = {
    url: 'clubs/:id/club-outstanding-shift',
    Component: mfClubFeatures('ClubOutStandingShift', 'PAGE.MF.CLUBS.CLUB.OUSTANDING_SHIFT'),
    rightName: 'view_club_outstanding_shift'
}

export const ClubCloseShift = {
    url: 'clubs/:id/club-close-shift',
    Component: mfClubFeatures('ClubCloseShift', 'PAGE.MF.CLUBS.CLUB.CLOSE_SHIFT'),
    rightName: 'view_club_close_shift'
}

const RouteLoader = [
    // HTML content
    HTMLPageRoute,
    HTMLPageRouteId,

    // Iframe content
    AddonListRoute,
    AddAddonRoute,
    EditAddonRoute,
    EditSimpleAddonRoute,
    PromotionRoute,
    AddPromotionRoute,
    EditPromotionRoute,
    EditPromotionAvailabilityRoute,

    // main contents
    EditProfileRoute,
    ImportMemberRoute,
    AppearanceSettingRoute,
    ResetPasswordRoute,
    ChangePasswordRoute,
    RoleListRoute,
    AddRoleRoute,
    EditRoleRoute,
    UserListRoute,
    AddUserRoute,
    EditUserRoute,
    UserGroupListRoute,
    AddUserGroupRoute,
    EditUserGroupRoute,
    ProductRoute,
    PlanRoute,
    PlanViewDetailRoute,
    PlanQuickEditRoute,
    PlanEditRoute,
    PlanEditAvailabilityRoute,
    PlanQuickCloneRoute,
    PlanCloneRoute,
    AddNewMemberRoute,
    ChangeMembershipRoute,
    MemberListRoute,
    MemberSummaryRoute,
    ChangePersonalDetailsRoute,
    PackageRoute,
    PackageDetailRoute,
    ServiceVersionRoute,
    AddFieldsConfigRoute,
    LookUpsRoute,
    AddLookUpRoute,
    EditLookUpRoute,
    TenderClassRoute,
    AddTenderClassRoute,
    EditTenderClassRoute,
    ViewTenderClassRoute,
    ItemRoute,
    AddFeeRoute,
    EditFeeRoute,
    ViewFeeRoute,
    TenderTypesRoute,
    AddTenderTypeRoute,
    EditTenderTypeRoute,
    ViewTenderTypeRoute,
    ViewChangePaymentDetail,
    ViewRequestFreezeMemberShip,
    MakePaymentRoute,
    ViewModifyFreezeMemberShip,
    ViewRemoveFreezeMemberShip,
    UnfreezeMembershipRoute,
    CancelMembershipRoute,
    RemoveChangeMembershipRoute,
    RemoveCancelMembershipRoute,
    AddSellingRoute,
    EditSellingPrice,
    CMClubsPageRoute,
    CMAddClubPageRoute,
    CMEditClubPageRoute,
    ClubBankingRoute,
    ClubListPageRoute,
    ClubDetailPageRoute,
    ClubOutStandingShift,
    ClubCloseShift
];

export default RouteLoader;
