import React from 'react';
import ThisInterface from './interface';
import CollapseButton from 'components/CollapseButton';
import { renderCollapseContents } from './common';

const template = (_this: ThisInterface) => {
    const { openDropdown } = _this.state;
    const { t } = _this.props;
    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    <p>
                        {t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.CHANGE_PERSON_DETAILS')}
                    </p>
                </div>
                <div className="member-main">
                    <div className="content select-add-on personal change-personal-detail-wrapper">
                        <div className="main-payment select-add-on-main">
                            {renderCollapseContents(_this).map(item => {
                                return (
                                    <CollapseButton
                                        key={item.key}
                                        className="collapse-promotion"
                                        title={t(item.title)}
                                        content={item.content}
                                        isOpen={openDropdown[item.key]}
                                        maxHeight="1200px"
                                        openCollapsedContent={() =>
                                            _this.handleOpenDropdown(item.key)
                                        }
                                    />
                                );
                            })}

                            <div className="row">
                                <div className="col-md-4 offset-md-8">
                                    <div className="box-btn">
                                        <button
                                            className="btn btn-dark"
                                            onClick={() => _this.props.handleCancel()}
                                        >
                                            {t('BUTTON.CANCEL')}
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => _this.props.handlePrevious()}
                                        >
                                            {t('BUTTON.PREVIOUS')}
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => _this.props.handleFinish()}
                                        >
                                            {t('BUTTON.FINISH')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
