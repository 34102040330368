export default class MembershipSearchFilter {
    salePersonId = '';
    salePersonName = '';
    applicationTypeId = '';
    reciprocalAccessId = '';
    minimumContractId = '';
    clubId = '';
    clubName = '';
    accessId = '';
    paymentMethodId = '';
    promotionCode = '';
    startDate = new Date();
    dateOfBirth: Date | null = null;
    operator = null;
    subTenantId = '';
    selectedPackagePlanId = '';
    legacyId?;
}