import React from 'react';
import { QuickEditPlanInterface } from 'pages/Products/Plan/Template/interface';
import AvailabilityShorten from 'pages/Products/Plan/Template/AvailabilityShorten';
import BasicDetailsShorten from 'pages/Products/Plan/Template/BasicDetailsShorten';
import ReadonlyInformations from 'pages/Products/Plan/Template/ReadonlyInformations';
import PeriodsShorten from 'pages/Products/Plan/Template/PeriodsShorten';
import Payments from 'pages/Products/Plan/Template/Payments';

const template = (_this: QuickEditPlanInterface) => {
    const { t } = _this.props;
    return (
        <section>
            <div className="plan-edit">
                <div className="title-content mb-3">{t('PAGE.PRODUCTS.PLANS.QUICK_EDIT.TITLE')} </div>
                <div className="member">
                    <div className="member-main member-main--custom">
                        <div className="content">
                            <ReadonlyInformations
                                data={_this.state.planDetail}
                                packageId={_this.state.packageId}
                                packagePeriodDefaultId={_this.state.packagePeriodDefaultId}
                                parentPage={'quickEdit'}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <BasicDetailsShorten
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                parentPage={'quickEdit'}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <AvailabilityShorten
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <PeriodsShorten
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <Payments
                                hideComments={false}
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <div className="box-btn justify-content-start no-padding-left">
                                <button disabled={_this.state.disabled} className="btn btn-primary" onClick={() => _this.onSubmit()}>{t('BUTTON.SAVE')} </button>
                                <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.CANCEL')} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
