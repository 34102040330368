import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import ThisInterface from './interface';
import store from 'reduxs/index';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import observer from 'util/Observer';
import { connect } from 'react-redux';
import { MsbClubPlan, MsbFee } from 'models/PackagePlan';
import moment from 'moment';
import { GENERAL_DATABASE_DATE_FORMAT } from 'constants/Constants';
import { dateInMCP } from 'util/ControlUtils';

class ChangeSummary extends React.Component<ThisInterface["props"] & WithTranslation, ThisInterface["state"]> {
    constructor(props: ThisInterface["props"] & WithTranslation) {
        super(props)

        this.state = {
            openDropdown: {
                changeMemberSummaryInformation: true,
                newMemberSummaryInformation: true,
                oldMemberSummaryInformation: true,
                paymentMethodSummaryInformation: true,
                memberPaymentSummaryInformation: true,
            },
            minContractEndDateOld: '',
            minContractEndDateNew: '',
            errors: [],
            hasChanged: false,
            memberData: null,
            fees: []
        }
    }
    componentDidMount() {
        this.getMinContractEndDateNew();
        this.getMinContractEndDateOld();
        this.getProjectedPayments();
        observer.subscribe('communicationEmailChanged', data => {
            if (data) {
                const { personalDetails: { contactDetails } } = this.props.newMembership;
                store.dispatch(MembershipActions.setPersonalDetails({
                    ...this.props.newMembership.personalDetails,
                    contactDetails: {
                        ...contactDetails,
                        email: data.email
                    }
                }))
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('communicationEmailChanged');
    }

    getProjectedPayments = () => {
        const {
            newMembership: {
                membershipSearchFilter,
                selectedPackagePlan,
                selectedSubTenant
            }
        } = this.props;

        if (
            membershipSearchFilter &&
            selectedPackagePlan &&
            selectedSubTenant &&
            selectedPackagePlan.plan
        ) {
            const packagePlan: MsbClubPlan = selectedPackagePlan.plan;
            if (packagePlan &&
                packagePlan.payment &&
                packagePlan.payment.payNow &&
                packagePlan.payment.payNow.fees &&
                packagePlan.payment.payNow.fees.length) {
                const payNowFees: MsbFee[] = packagePlan.payment.payNow.fees;
                const hasNextMonthDue = payNowFees.find(e => e.typeName.indexOf('Next Month') > -1);
                let regularFees: MsbFee[] = [];
                if (packagePlan.payment.regular &&
                    packagePlan.payment.regular.fees &&
                    packagePlan.payment.regular.fees.length) {
                    const sourceRegularFees = JSON.parse(JSON.stringify(packagePlan.payment.regular.fees));
                    const regularDate = sourceRegularFees[0].startDate;
                    for (let i = 1; i < (hasNextMonthDue ? 5 : 6); i++) {
                        sourceRegularFees.forEach(e => {
                            e.startDate = moment(regularDate).add(i-1, 'months').format(GENERAL_DATABASE_DATE_FORMAT + 'T00:00:00Z');
                            regularFees.push({...e});
                        });
                    }
                }

                this.setState({
                    fees: [
                        ...payNowFees,
                        ...regularFees,
                    ]
                });
            }
        }
    };

    handleOpenDropdown = (key) => {
        const { openDropdown } = this.state;
        openDropdown[key] = !this.state.openDropdown[key];
        this.setState({
            openDropdown
        })
    };

    handlePrevious() {
        const { membershipConfiguration, membershipConfiguration: { stepLatest } } = this.props.newMembership;
        if (stepLatest > 4 && !this.isValidationSuccess()) {
            return;
        }
        this.storeDataToRedux();
        membershipConfiguration.stepIndex = 3;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
    }

    handleNext() {
        if (this.isValidationSuccess()) {
            const { membershipConfiguration } = this.props.newMembership;
            if (membershipConfiguration.stepLatest < 5) {
                membershipConfiguration.stepLatest = 5;
            }
            membershipConfiguration.stepIndex = 5;
            store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
            this.storeDataToRedux();
        }
    }

    storeDataToRedux = () => {
    }

    isValidationSuccess() {
        let errors: any = [];

        return Object.keys(errors).length === 0;
    }


    handleSaveAndLeave(stepIndex, isSave = false) {
        const { membershipConfiguration } = this.props.newMembership;
        if (isSave) {
            this.storeDataToRedux();
        }
        membershipConfiguration.stepIndex = stepIndex;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        observer.publish('closeDialogEvent', true)
    }

    goToStep(stepIndex) {
        const { hasChanged } = this.state;
        const { t } = this.props; 
        const { membershipConfiguration, membershipConfiguration: { stepLatest } } = this.props.newMembership;
        if (hasChanged && stepLatest > 4) {
            if (!this.isValidationSuccess()) return;
            const content = (
                <React.Fragment>
                    <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page"></div>
                    <p className="content-center add-member-page">{t('MSG.UNSAVED_CHANGES')}</p>
                    <div className="modalSaas__row--btn">
                        <button
                            className="btn btn-primary text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex, true)}
                        >
                            {t('BUTTON.SAVE_AND_LEAVE')}
                        </button>
                        <button
                            className="btn btn-dark text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex)}
                        >
                            {t('BUTTON.LEAVE_AND_NOT_SAVE')}
                        </button>
                    </div>
                </React.Fragment>
            );
            observer.publish('openDialogEvent', {
                content: content
            });
        }
        else {
            this.storeDataToRedux();
            membershipConfiguration.stepIndex = stepIndex;
            store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        }
    }

    getMinContractEndDateNew = () => {
        const {
            newMembership: { selectedPackagePlan, membershipSearchFilter }
        } = this.props;
        let day = dateInMCP(membershipSearchFilter.startDate, selectedPackagePlan.minimumContract.minimumContractUnitName, selectedPackagePlan.minimumContract.minimumContractValue);
        
        this.setState({
            minContractEndDateNew: day
        });
    };

    getMinContractEndDateOld = () => {
        const {
            oldMembership: { selectedPackagePlan, memberSummary }
        } = this.props;
        let day = dateInMCP(memberSummary.startedDate, selectedPackagePlan.minimumContract.minimumContractUnitName, selectedPackagePlan.minimumContract.minimumContractValue);
        this.setState({
            minContractEndDateOld: day
        });
    };

    render() {
        return template(this);
    }
}

const mapStateToProps: any = ({ newMembership }) => {
    return { newMembership };
};

export default connect(
    mapStateToProps
)(withTranslation()(ChangeSummary));