import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Route from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';

import {
    BASE_API_URL_DEVELOPMENT,
    GENERAL_DATE_FORMAT
} from 'constants/Constants';
import * as Constant from 'constants/Constants';

import Language from 'models/Language';
import ListComponent from 'list-component';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

interface StateInterface {
    version: number;
    tableHeight: number;
}

interface PropsInterface extends RouteComponentProps<{}> {
    locale?: Language;
    auth: any;
}

class TenderTypeListPage extends React.Component<
PropsInterface & WithTranslation,
StateInterface
> {
    constructor(props) {
        super(props);
        this.state = {
            tableHeight: 0,
            version: 0
        };
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.setState({
            tableHeight: window.innerHeight - 320,
            version: 1
        });
    }
    actionCallback = (action, id) => {
        this.setState(state => ({ version: state.version + 1 }));
        if (action === 'edit') {
            this.props.history.push(
                Constant.ROUTE_TENDER_TYPE_EDIT.replace('{0}', id)
            );
        }
        if (action === 'view') {
            this.props.history.push(
                Constant.ROUTE_TENDER_TYPE_VIEW.replace('{0}', id)
            );
        }
    };

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 320
        });
    };

    errorHandle = err => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    };

    render() {
        const { auth, t } = this.props;
        const variableConfig = {
            api: BASE_API_URL_DEVELOPMENT
        };

        return this.state.version > 0 ? (
      <>
        <div className="list-page plan-list-view-page">
            <div className="list-page-title">
                <p className="title-content title-content-list">
                    {t('PAGE.SYSTEM.TENDER_TYPE.TITLE')}
                </p>
                <div className="add-new-section">
                    <div className="box-btn-filter ml-auto d-flex">
                        <span>
                            {t('PAGE.SYSTEM.TENDER_TYPE.INSTRUCTION')}
                        </span>
                        {isHasRight(RouteLoader.AddTenderTypeRoute.rightName) && (
                            <button
                                onClick={() =>
                                    this.props.history.push(Constant.ROUTE_TENDER_TYPE_ADD)
                                }
                                className="btn btn-height-sm btn-add-new"
                            >
                                {t('PAGE.SYSTEM.TENDER_TYPE.BUTTON.ADD_TENDER_TYPE')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
        <ListComponent
            oidcAuth={auth.user}
            version={this.state.version}
            rightName="view_tender_type_list"
            config={{
                presetUrl: Route.PRESET_ENDPOINT_URL,
                timeFormat: GENERAL_DATE_FORMAT,
                langCode: i18n.language,
                tableHeight: `${this.state.tableHeight}px`,
                tableWidth: '100%'
            }}
            variable={variableConfig}
            actionEvent={this.actionCallback}
            errorEvent={this.errorHandle}
        />
      </>
        ) : null;
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(TenderTypeListPage)));
