import React from 'react';

interface State {
    cardHeight: number;
}

function withWrapper(Component, isScrolling = false) {
    class Wrapper extends React.Component<{}, State> {
        constructor(props) {
            super(props);
            this.state = {
                cardHeight: window.innerHeight - 140
            };
        }

        componentDidMount() {
            if (!isScrolling) {
                window.onresize = this.onResize;
            }
        }

        onResize = () => {
            this.setState({
                cardHeight: window.innerHeight - 140
            });
        };

        render() {
            const { cardHeight } = this.state;
            return (
                <div
                    className="table-list member-summary-table-list"
                    style={{ height: isScrolling ? 'auto' : cardHeight }}
                >
                    <Component {...this.props} />
                </div>
            );
        }
    }

    return Wrapper;
}

export default withWrapper;
