import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import { MatchParams, ViewDetailPlanInterface } from 'pages/Products/Plan/Template/interface';
import PlanModel, { PlanPeriodsModel } from 'models/Plan';
import * as Route from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import observer from 'util/Observer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getSubTenantId } from 'util/ControlUtils';

class PlanViewDetailPage extends React.Component<
ViewDetailPlanInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation,
ViewDetailPlanInterface['state']
> {
    constructor(props: ViewDetailPlanInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation) {
        super(props);
        
        // Get subTenantId & rightName
        const { location: { state } } = props;
        let subTenantId = getSubTenantId();
        if (state && state.subTenantId) {
            subTenantId = state.subTenantId;
        }
        const rightName = RouteLoader.PlanViewDetailRoute.rightName;

        this.state = {
            packageId: '',
            planDetail: new PlanModel(),
            packagesPeriods: [],
            isLoading: true,
            subTenantId: subTenantId,
            rightName: rightName
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const { subTenantId, rightName } = this.state;
        const getPlanData = this.props.planService.getPlanData(id, subTenantId, rightName).then((res) => {
            let planDetail: PlanModel = res;
            let planPeriods: PlanPeriodsModel[] = [];
            planDetail.planPeriods.forEach(e => {
                planPeriods[e.id] = PlanPeriodsModel.clone(e);
                planPeriods[e.id].checked = true;
            });
            planDetail.id = id;
            planDetail.planPeriods = planPeriods;
            this.setState({
                planDetail: planDetail,
                packageId: planDetail.package ? planDetail.package.id : ''
            });
            observer.publish('plan.clubGroups.changed', true);
        }, () => {
            this.props.history.push(Route.ROUTE_PLAN_LIST);
        });

        Promise.all([
            getPlanData
        ]).then(() => {
            this.setState({ isLoading: false });
        });


        observer.subscribe('plan.packagesPeriods.setData', (data) => {
            if (data) {
                this.setState({packagesPeriods: data});
            }
        });
    }

    componentWillUnmount() {
        observer.unsubscribe('plan.packagesPeriods.setData');
    }

    onCancel() {
        this.props.history.push(Route.ROUTE_PLAN_LIST);
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(PlanViewDetailPage)));
