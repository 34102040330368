import React from 'react';
import Option from 'models/Option';

import TextBoxGroup from './TextBoxGroup';
import NumberBoxGroup from './NumberBoxGroup';
import TextAreaGroup from './TextAreaGroup';
import SelectBoxGroup from './SelectBoxGroup';
import SelectBoxAsyncGroup from './SelectBoxAsyncGroup';
import CheckBoxGroup from './CheckBoxGroup';
import CalendarGroup from './CalendarGroup';
import MultiSelectBoxGroup from './MultiSelectBoxGroup';
import MultiSelectBoxAsyncGroup from './MultiSelectBoxAsyncGroup';
import NumberCurrency from './NumberCurrency';
import { FieldModel } from 'models/Field';
import { interpret } from 'util/ControlUtils';
import ToogleGroup from './ToogleGroup';
import CurrencyBoxGroup from './CurrencyBoxGroup';
import RadioGroup from './RadioGroup';
import { t } from 'util/I18nMessages';

export interface AttributeInterface {
    disabled?: boolean;
    checked?: boolean;
    min?: number;
    rows?: number;
    style?: React.CSSProperties;
    popperPlacement?: string;
    dateFormat?: string;
    toogleLabel?: string;
    isClearable?: boolean;
    isAllowBackDated?: boolean;
    isAllowFutureDate?: boolean;
    minDate?: Date;
    maxDate?: Date;
    allowedDates?: (Date | string)[];
}

export interface AdvanceOptions {
    apiEndpoint: string;
    buildOptions: (objects: any) => Option[];
    buildFilterObject?: (keyword: string) => string;
    pageSize?: number;
    subTenantId: string;
    rightName: string;
}

export interface FormItemInterface {
    htmlFor?: string;
    fieldLabel?: string;
    htmlLabel?: string;
    fieldName?: string;
    attribute?: AttributeInterface;
    className?: string;
    data?: string | string[] | number;
    fieldValidation?: FieldModel;
    onChangeHandle: Function;
    placeholder?: string;
}

interface Props extends FormItemInterface {
    type: string;
    fields: FieldModel[];
    options?: Option[];
    advanceOptions?: AdvanceOptions;
    selectAllLabel?: string;
    html?: JSX.Element[] | JSX.Element;
    datePickerData?: Date;
    multiSelectData?: Option[];
    locale?: string;
}

export const isValidOrNot = (isValid, isRequired, regex, customMsg) => {
    return (
        (!isValid && (isRequired || (regex && regex !== ''))) ||
    (customMsg && customMsg !== '')
    );
};

export const renderErrorHtml = (
    isRequired,
    isValidType,
    isDirty,
    fieldLabel,
    customMsg,
    htmlLabel = ''
) => {
    return customMsg && customMsg !== '' ? (
        isDirty && <div className="error">{customMsg.key ? t(customMsg.value, {...customMsg.key}) : t(customMsg)}</div>
    ) : (
        <React.Fragment>
            {isRequired && isValidType === 'required' && isDirty && (
                <div className="error">
                    {t('COMMON.ERR_IS_REQUIRED', {value: htmlLabel === '' ? fieldLabel : htmlLabel })}
                </div>
            )}
            {isValidType === 'regex' && isDirty && (
                <div className="error">
                    {t('COMMON.ERR_NOT_VAILD', {value: htmlLabel === '' ? fieldLabel : htmlLabel })}
                </div>
            )}
        </React.Fragment>
    );
};

export const matchField = (nameHtml, shortNameValidation) => {
    if (nameHtml && nameHtml.indexOf('[') > -1 && nameHtml.indexOf(']') > -1) {
        const extractNameHtml = interpret(nameHtml);
        if (extractNameHtml && extractNameHtml['indices']) {
            const shortNameHtml =
        extractNameHtml['indices'][extractNameHtml['indices'].length - 1];
            return shortNameValidation === shortNameHtml;
        }
    }
    return nameHtml === shortNameValidation;
};

export const matchFieldNested = (nameHtml, keyNested) => {
    if (nameHtml && nameHtml.indexOf('[') > -1 && nameHtml.indexOf(']') > -1) {
        const extractNameHtml = interpret(nameHtml);
        if (
            extractNameHtml &&
      extractNameHtml['indices'] &&
      extractNameHtml['indices'].length >= 2
        ) {
            const keyHtml =
        extractNameHtml['indices'][extractNameHtml['indices'].length - 2];
            return keyHtml === keyNested;
        }
    }
    return nameHtml === keyNested;
};

export const getRawValue = (value, fieldIndexData, fieldNameData) => {
    if (value.hasOwnProperty('id')) {
        return value['id'];
    } else if (value.hasOwnProperty('code')) {
        return value['code'];
    } else if (fieldIndexData && fieldNameData) {
        if (value[fieldIndexData][fieldNameData].hasOwnProperty('id')) {
            return value[fieldIndexData][fieldNameData]['id'];
        } else if (value[fieldIndexData][fieldNameData].hasOwnProperty('code')) {
            return value[fieldIndexData][fieldNameData]['code'];
        } else {
            return value[fieldIndexData][fieldNameData];
        }
    }
    return value;
};

export const hasValue = rawValue => {
    if ((rawValue || rawValue === 0) && rawValue !== '') {
        return true;
    }
    return false;
};

export const getFieldValidation = (fields, fieldName) => {
    const fieldValidationRoot = fields.find(e => {
        const extractNameValidation = e.key.split('.');
        const shortNameValidation =
      extractNameValidation[extractNameValidation.length - 1];

        if (matchField(fieldName, shortNameValidation)) {
            return true;
        }
        return false;
    });

    if (fieldValidationRoot && fieldValidationRoot.nested) {
        return fieldValidationRoot.nested.find(fieldNested => {
            if (matchFieldNested(fieldName, fieldNested.key)) {
                return true;
            }
            return false;
        });
    } else {
        return fieldValidationRoot;
    }
};

export const updateFieldValid = (field, rawValue) => {
    field.isValid = true;
    field.isValidType = 'none';
    if (field.isRequired && !hasValue(rawValue)) {
        field.isValid = false;
        field.isValidType = 'required';
    } else if (field.regex && field.regex.trim()) {
        try {
            const regex = new RegExp(field.regex);
            if (!regex.test(rawValue)) {
                field.isValid = false;
                field.isValidType = 'regex';
            }
        } catch (ex) {}
    }
    return field;
};

export const forceDecimal = (value: number | string, decimal = 2) => {
    return parseFloat(
        String(Math.round(parseFloat(String(value)) * 100) / 100)
    ).toFixed(decimal);
};

export const forceInt = value => {
    return value ? parseInt(String(value), 10) : 0;
};

export const forceFloat = value => {
    return value ? parseFloat(String(value)) : 0;
};

export const forceAbs = value => {
    return value < 0 ? Math.abs(value) : value;
};

export const getIdOrUndefined = value => {
    return value ? value.id : undefined;
};

export const getCodeOrUndefined = value => {
    return value ? value.code : undefined;
};

export const getNameOrUndefined = value => {
    return value ? value.name : undefined;
};

export const getIdOrNull = value => {
    return value && value.id !== '' ? value.id : null;
};

export const getCodeOrNull = value => {
    return value && value.code !== '' ? value.code : null;
};

export const formatDateAddZ = value => {
    return value.replace('Z', '') + 'Z';
};

const FormItem = React.memo((props: Props) => {
    const {
        html,
        htmlFor,
        fieldLabel,
        htmlLabel,
        fieldName,
        className,
        attribute,
        selectAllLabel = `-- ${t('COMMON.PLEASE_SELECT')} --`,
        type,
        data,
        datePickerData,
        multiSelectData,
        locale = 'en-US',
        fields = [],
        options,
        advanceOptions,
        onChangeHandle
    } = props;

    const formItemProps: FormItemInterface = {
        htmlFor,
        htmlLabel,
        fieldName,
        fieldLabel,
        fieldValidation: getFieldValidation(fields, fieldName),
        className,
        attribute,
        onChangeHandle,
        data
    };

    switch (type) {
        case 'toogle':
            return <ToogleGroup {...formItemProps} />;
        case 'text':
            return <TextBoxGroup {...formItemProps} />;
        case 'number':
            return <NumberBoxGroup {...formItemProps} />;
        case 'currency':
            return <CurrencyBoxGroup {...formItemProps} />;
        case 'currency-number':
            return <NumberCurrency {...formItemProps} />
        case 'textarea':
            return <TextAreaGroup {...formItemProps} />;
        case 'simple-select':
            return (
                <SelectBoxGroup
                    {...formItemProps}
                    selectAllLabel={selectAllLabel}
                    options={options}
                />
            );
        case 'select':
            return (
                <SelectBoxAsyncGroup
                    {...formItemProps}
                    selectAllLabel={selectAllLabel}
                    options={options}
                    advanceOptions={advanceOptions}
                />
            );
        case 'checkbox':
            return <CheckBoxGroup {...formItemProps} />;
        case 'calendar':
            return (
                <CalendarGroup
                    {...formItemProps}
                    locale={locale}
                    selected={datePickerData ? datePickerData : undefined}
                />
            );
        case 'simple-multi-select':
            return (
                <MultiSelectBoxGroup
                    {...formItemProps}
                    selectAllLabel={selectAllLabel}
                    options={options}
                    multiSelectData={multiSelectData}
                />
            );
        case 'multi-select':
            return (
                <MultiSelectBoxAsyncGroup
                    {...formItemProps}
                    selectAllLabel={selectAllLabel}
                    options={options}
                    multiSelectData={multiSelectData}
                    advanceOptions={advanceOptions}
                />
            );
        case 'radio':
            return <RadioGroup {...formItemProps} />;
        case 'html':
            return <>{html}</>;

        default:
            return <></>;
    }
});
FormItem.displayName = 'FormItem';

export default FormItem;
