import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import * as Interface from 'interfaces';

interface FormInputProps extends Interface.InputInterface {
    isValid?: boolean;
    validMsg?: string;
    maxlength?: number;
    minlength?: number;
    toolTipMsg?: string;
    isDirty?: boolean;
    min?: string;
    max?: string;
    classLongErrorMsg?: string;
}

const FormInput: React.SFC<FormInputProps> = ({
    label,
    id,
    name,
    type = 'text',
    placeholder,
    maxlength,
    minlength,
    value,
    onChange = () => {},
    className = '',
    require,
    errorMsg,
    classLongErrorMsg = '',
    toolTipMsg = '',
    inputClass = '',
    labelClass,
    disabled,
    onClick,
    isValid = true,
    validMsg,
    isDirty,
    min,
    max
}) => {
	
    const [error, setError] = useState(false);

    useEffect(() => {
        if (isDirty === undefined) {
            !errorMsg ? setError(false) : setError(true);
        } else {
            value || !isDirty ? setError(false) : setError(true);
        }
    });

    function handleChangeValue(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        onChange(e);
        if ((value === '' && isDirty) || errorMsg) {
            setError(true);
        } else {
            setError(false);
        }
    }

    function handleEnterInput(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    return (
        <form
            className={`${className} position-relative form-group`}
            onKeyDown={handleEnterInput}
        >
            {label && label !== '' ? (
                <label className={labelClass}>
                    {label}
                    {require && <span className="text-primary bold">&nbsp;*</span>}
                </label>
            ) : null}
            <Tooltip title={toolTipMsg}>
                <input
                    type={type}
                    name={name}
                    id={id}
                    min={min}
                    max={max}
                    value={value}
                    placeholder={placeholder}
                    maxLength={maxlength}
                    minLength={minlength}
                    onChange={handleChangeValue}
                    onClick={onClick}
                    className={`${inputClass} form-control`}
                    disabled={disabled}
                    autoComplete='off'
                />
            </Tooltip>
            <span className={`text-danger input-error ${classLongErrorMsg}`}>
                {error && errorMsg ? errorMsg : ''}
            </span>
            <span className={`text-danger input-error ${classLongErrorMsg}`}>
                {!isValid && validMsg ? validMsg : ''}
            </span>
        </form>
    );
};

export default FormInput;
