import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/FormV2';
import { DisplayDateFormat, dateInMCP, isInMCPandHasNoticePeriod } from 'util/ControlUtils';
import { transformOptions } from 'util/ControlUtils';
import { ThisInterface } from './interface';

const Field: React.FC<{
    label: string;
    fieldItem: React.ReactNode;
}> = (props) => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <div className="col-4">
                <p>{t(props.label)}</p>
            </div>
            <div className="col-8">
                {props.fieldItem}
            </div>
        </React.Fragment>
    );
};

function getFields(_this: ThisInterface): {
    label: string;
    fieldItem: React.ReactNode;
}[] {
    const {
        state: {
            fields: {
                requestDate,
                cancellationDate,
                reasonId
            },
            allowedCancellationDates,
            cancellationReasons
        }
    } = _this;
	
    const allowedCancellationDatesOption = allowedCancellationDates.map(item => {
        return {
            label: DisplayDateFormat(item.cancellationDate, _this.props.dateFormat),
            value: item.cancellationDate
        }
    })

    return [
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.REQUESTED_DATE',
            fieldItem: <FormItem
                type="calendar"
                fieldName="requestDate"
                fields={[]}
                datePickerData={requestDate}
                onChangeHandle={_this.handleFieldChanged}
                attribute={{
                    minDate: moment().subtract(5, 'days').toDate(),
                    isAllowBackDated: true
                }}
            />
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.CANCELLATION_DATE',
            fieldItem: <FormItem
                type="select"
                fieldName="cancellationDate"
                fields={[]}
                data={cancellationDate}
                onChangeHandle={_this.handleFieldChanged}
                options={allowedCancellationDatesOption}
                attribute={{
                    isClearable: false
                }}
            />
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.CANCELLATION_REASON',
            fieldItem: <FormItem
                type="select"
                fieldName="reasonId"
                fields={[]}
                data={reasonId}
                options={transformOptions(cancellationReasons)}
                onChangeHandle={_this.handleFieldChanged}
                attribute={{
                    isClearable: false
                }}
                selectAllLabel=""
            />
        }
    ];
};

function getOptions(_this: ThisInterface): {
    label: string;
    fieldName: string;
    checked: boolean;
}[] {
    const {
        state: {
            fields
        }
    } = _this;

    return [
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.OPTIONS.OVERRIDE_CANCELLATION_NOTICE_PERIOD',
            fieldName: 'overrideCancellationNoticePeriod',
            checked: fields.overrideCancellationNoticePeriod
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.OPTIONS.OVERRIDE_CANCEL_DURING_MINIMUM_CONTRACT_PERIOD',
            fieldName: "overrideMinimumContractPeriod",
            checked: fields.overrideMinimumContractPeriod
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.OPTIONS.OVERRIDE_CANCEL_AS_IF_IN_MONEY_BACK_GUARANTEE_PERIOD',
            fieldName: 'overrideMoneyBackPeriod',
            checked: fields.overrideMoneyBackPeriod
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.OPTIONS.OVERRIDE_CANCEL_AT_END_OF_BILLING_PERIOD',
            fieldName: 'overrideEndBillingPeriod',
            checked: fields.overrideEndBillingPeriod
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.OPTIONS.CANCEL_REQUESTED_BY_MEMBER',
            fieldName: 'memberRequested',
            checked: fields.memberRequested
        }
    ];
}

export const CancelMembershipForm: React.FC<ThisInterface> = (_this: ThisInterface) => {
    const {
        props: { t, memberData, dateFormat }
    } = _this;
	
    const {
        withinMCP,
        plan: {
            period: {
                cancellationNotice,
                minimumMembership
            }
        },
        startedDate
    } = memberData;

    const fields = getFields(_this);
    const options = getOptions(_this);
	
    const datePeriod = withinMCP
        ? dateInMCP(startedDate, minimumMembership.unitName, minimumMembership.value)
        : dateInMCP(moment().toString(), cancellationNotice.unitName, cancellationNotice.value);

    return (
        <React.Fragment>
            <div className="row col important-note">
                <h4>{t('PAGE.CANCEL_MEMBERSHIP.IMPORTANT_NOTIFICATION')}:&nbsp;
                    <ul>
                        <li>
                            {t(withinMCP
                                ? 'PAGE.CANCEL_MEMBERSHIP.IMPORTANT_NOTIFICATION_MESSAGE_INSIDE'
                                : 'PAGE.CANCEL_MEMBERSHIP.IMPORTANT_NOTIFICATION_MESSAGE_OUTSIDE', {
                                date: DisplayDateFormat(datePeriod, dateFormat)
                            })}
                        </li>
                        {isInMCPandHasNoticePeriod(minimumMembership, cancellationNotice, startedDate) && (
                            <li>
                                {t('PAGE.CANCEL_MEMBERSHIP.IMPORTANT_NOTIFICATION_MESSAGE_OUTSIDE', {
                                    date: DisplayDateFormat(dateInMCP(moment().toString(), cancellationNotice.unitName, cancellationNotice.value), dateFormat)
                                })}
                            </li>
                        )}
                        
                    </ul>
                </h4>
            </div>
            <div className="row cancel-membership-form">
                <div className="col-12">
                    <p>{t('PAGE.CANCEL_MEMBERSHIP.CANCEL_MEMBER_DETAILS_SECTION_TITLE')}</p>
                </div>
                {fields.map((field, index) => <Field key={index} {...field} />)}
                {options.map((option, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="col-md-4" />
                            <div className="col-md-8">
                                <FormItem
                                    type="checkbox"
                                    htmlFor={option.fieldName}
                                    fieldName={option.fieldName}
                                    fieldLabel={t(option.label)}
                                    onChangeHandle={_this.handleFieldChanged}
                                    attribute={{
                                        checked: option.checked
                                    }}
                                    fields={[]}
                                />
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </React.Fragment>
    );
};