import React from 'react';
import {
    Route,
    Switch,
    withRouter,
    RouteComponentProps
} from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'components/SideNav/index';
import Footer from 'components/Footer';
import Error404 from 'components/Error404';
import CustomModal from 'components/Modal';
import ApiContext from 'util/ApiContext';
import observer from 'util/Observer';
import HomePage from './HomePage';
import RouteLoader from './RouteLoader';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import { getAllRights } from 'util/ControlUtils';

interface Props extends RouteComponentProps<{}> {
    drawerType: string;
    navigationStyle: string;
    horizontalNavPosition: string;
    isFullConnected: boolean;
    auth: any;
}

interface State {
    openDialog: boolean;
    messageDialog: string;
    titleDialog: string;
    modalContent: React.ReactNode;
    classHolder: string;
    somethingChanged: boolean;
    forceChangeRoute: boolean;
}

class AppRoute extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            openDialog: false,
            messageDialog: '',
            titleDialog: '',
            modalContent: '',
            classHolder: '',
            somethingChanged: false,
            forceChangeRoute: false
        };
    }
    componentDidMount() {
        observer.subscribe('flagUnsavedChangeEvent', data => {
            this.setState({ somethingChanged: data });
        });

        observer.subscribe('openDialogEvent', (data: Record<string, string>) => {
            if (data) {
                this.setState({
                    messageDialog: data.message,
                    openDialog: true,
                    titleDialog: data.title,
                    modalContent: data.content,
                    classHolder: data.classHolder
                });
            }
        });

        observer.subscribe('closeDialogEvent', (data: boolean) => {
            if (data) {
                this.setState({
                    openDialog: false
                });
            }
        });

        this.props.history.block(e => {
            const currentLocale = this.props.location.pathname;
            const currentState = this.props.location.state;
            const nextLocale = e.pathname;
            const { t } = this.props;

            if (localStorage.isRequiredChangePassword === '1') {
                // A notification appears in case user accidently navigates out of change password page
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    text: t('PAGE.CHANGE_FORGOT_PASSWORD.FIRST_TIME_LOGIN_CHANGE_PASSWORD'),
                    type: 'warning',
                    confirmButtonText: t('COMMON.OK')
                });
                return false;
            }
            if (
                currentLocale !== nextLocale &&
                this.state.somethingChanged &&
                !this.state.forceChangeRoute
            ) {
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    text: t('MSG.UNSAVED_CHANGES'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('BUTTON.STAY_ON_THIS_PAGE'),
                    cancelButtonText: t('BUTTON.LEAVE_THIS_PAGE')
                }).then(result => {
                    if (
                        result.hasOwnProperty('dismiss') &&
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        this.setState({ forceChangeRoute: true }, () =>{
                            this.props.history.push(nextLocale, currentState);
                        });
                    }
                });
                return false;
            } else if (currentLocale !== nextLocale) {
                this.setState({ forceChangeRoute: false, somethingChanged: false });
            }
        });

        window.onbeforeunload = () => {
            const { t } = this.props;
            if (this.state.somethingChanged) {
                return t('COMMON.CHANGE_NOT_BE_SAVED');
            } else {
                return null;
            }
        };
    }

    componentWillUnmount() {
        observer.unsubscribe('flagUnsavedChangeEvent');
        observer.unsubscribe('openDialogEvent');
        observer.unsubscribe('closeDialogEvent');
    }

    closeDialog = () => {
        this.setState({
            openDialog: false
        });
    };
    
    checkClaim(rightName, claims) {
        if (rightName === '') return true;
        let hasRight = false;
        const existRight = claims.find(c => c === rightName);
        if (existRight && existRight.length > 0) {
            hasRight = true;
        }
        return hasRight;
    }

    render() {
        const { match } = this.props;
        const {
            openDialog,
            messageDialog,
            modalContent,
            classHolder,
            titleDialog
        } = this.state;

        const claims = getAllRights();

        return (
            <React.Fragment>
                <Sidebar />
                <div className="page-container">
                    <Header />
                    <main className="main-content">
                        <Switch>
                            <Route
                                path={`${match.url}`}
                                exact
                                render={props => (
                                    <ApiContext.Consumer>
                                        {svrs => <HomePage {...props} {...svrs} />}
                                    </ApiContext.Consumer>
                                )}
                            />
                            {RouteLoader.map(({ url, Component, rightName }) => {
                                return this.checkClaim(rightName, claims) ? (
                                    <Route
                                        key={url}
                                        path={`${match.url}${url}`}
                                        exact={true}
                                        render={props => (
                                            <ApiContext.Consumer>
                                                {svrs => <Component {...props} {...svrs} />}
                                            </ApiContext.Consumer>
                                        )}
                                    />
                                ) : null;
                            })}
                            {this.props.isFullConnected && <Route component={Error404} />}
                        </Switch>
                        <CustomModal
                            classHolder={classHolder}
                            isOpen={openDialog}
                            title={titleDialog}
                            closeModal={this.closeDialog}
                            content={
                                <React.Fragment>
                                    <p>{messageDialog}</p>
                                    {modalContent}
                                </React.Fragment>
                            }
                        />
                    </main>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
};
export default withRouter(connect(mapStateToProps)(withTranslation()(AppRoute)));
