import React from 'react';
import ThisInterface from './interface';
import Breadcrumb from 'components/Membership/Breadcrumb';
import ProductSummary from 'components/Membership/ProductSummary';
import CollapseButton from 'components/CollapseButton';
import creditImage from 'assets/images/card-payment.png';
import debitImage from 'assets/images/debit-payment.png';
import * as Utils from 'util/ControlUtils';
import CustomTable from 'components/Table';
import MemberArrea from 'models/MemberArrea';
import moment from 'moment';
import AddOn from 'models/AddOn';

function formatAmount(amount) {
    let formattedAmount = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
    let decimalSeparator = formattedAmount.charAt(formattedAmount.length - 3);
    return formattedAmount.split(decimalSeparator);
}


const MemberSummary = _this => {
    const { fieldConfigs } = _this.state;
    const {
        membershipSearchFilter,
        membershipConfiguration,
        selectedPackagePlan,
        personalDetails,
        selectedAddons
    } = _this.props.newMembership;

    const { t } = _this.props;

    let payNow = Utils.formatMoney((selectedPackagePlan.plan.payment.payNow.value + selectedAddons.reduce((acc, cur) => acc + cur.totalPrice, 0)), 2).toString();
    payNow =
    payNow.indexOf('.') > 0 ? payNow.split('.') : [payNow, '00'];

    let data = [
        {
            isVisible: fieldConfigs.titleConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.TITLE'),
            data: personalDetails.title.name
                ? personalDetails.title.name
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.firstNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.FIRST_NAME'),
            data: personalDetails.firstName
                ? personalDetails.firstName
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.lastNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.LAST_NAME'),
            data: personalDetails.lastName ? personalDetails.lastName : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.genderConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENDER'),
            data: personalDetails.gender.name
                ? personalDetails.gender.name
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.nationalConfig.isVisible,
            fieldLabel: fieldConfigs.nationalIdConfig.name,
            data: personalDetails.nationalIdNumber
                ? personalDetails.nationalIdNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.nationalConfig.isVisible,
            fieldLabel: fieldConfigs.otherNationalIdConfig.name,
            data: personalDetails.otherNationalIdNumber
                ? personalDetails.otherNationalIdNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.dateOfBirthConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE_OF_BIRTH'),
            data: personalDetails.dateOfBirth
                ? Utils.DisplayDateFormat(
                    personalDetails.dateOfBirth,
                    membershipConfiguration.newMemberTimeFormat
                )
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.preferedLanguageConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PREFERRED_LANGUAGE'),
            data: personalDetails.preferedLanguage.name
                ? personalDetails.preferedLanguage.name
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.CLUB'),
            data: membershipSearchFilter.clubName
                ? membershipSearchFilter.clubName
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SALE_PERSON'),
            data: membershipSearchFilter.salePersonName
                ? membershipSearchFilter.salePersonName
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.TIER'),
            data: selectedPackagePlan.plan.package.packageTier.name
                ? selectedPackagePlan.plan.package.packageTier.name
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE'),
            data: selectedPackagePlan.plan.package.name
                ? selectedPackagePlan.plan.package.name
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE_PLAN'),
            data: selectedPackagePlan.plan.name
                ? selectedPackagePlan.plan.name
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.START_DATE'),
            data: membershipSearchFilter.startDate
                ? Utils.DisplayDateFormat(
                    membershipSearchFilter.startDate,
                    membershipConfiguration.newMemberTimeFormat
                )
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.TOTAL_INITIAL_FEE'),
            data: selectedPackagePlan.plan.payment.payNow ? (
        <>
          {payNow[0]}.{payNow[1]}
        </>
            ) : (
                t('COMMON.NA')
            )
        }
    ];

    return (
    <>
      <div className="select-group open">
          <p>
              {t(
                  'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP_AND_THEIR_MEMBERSHIP'
              )}
          </p>
          {data.map(
              (field, key) =>
                  field.isVisible && (
                      <div key={key} className="row pb-2">
                          <div className="col-6 title">{field.fieldLabel}</div>
                          <div className="col-6">{field.data}</div>
                      </div>
                  )
          )}
      </div>
    </>
    );
};

const PersonalDetails = _this => {
    const { fieldConfigs } = _this.state;
    const {
        membershipConfiguration,
        personalDetails
    } = _this.props.newMembership;

    const { t } = _this.props;

    let data = [
        {
            isVisible: fieldConfigs.titleConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.TITLE'),
            data: personalDetails.title.name
                ? personalDetails.title.name
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.firstNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.FIRST_NAME'),
            data: personalDetails.firstName
                ? personalDetails.firstName
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.lastNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.LAST_NAME'),
            data: personalDetails.lastName ? personalDetails.lastName : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.genderConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENDER'),
            data: personalDetails.gender.name
                ? personalDetails.gender.name
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.nationalConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HKID_NO'),
            data: personalDetails.nationalIdNumber
                ? personalDetails.nationalIdNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.nationalConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.Passport_NO'),
            data: personalDetails.otherNationalIdNumber
                ? personalDetails.otherNationalIdNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HOME_TELEPHONE'),
            data: personalDetails.contactDetails.homePhone.phoneNumber
                ? personalDetails.contactDetails.homePhone.phoneNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MOBILE_TELEPHONE'),
            data: personalDetails.contactDetails.mobilePhone.phoneNumber
                ? personalDetails.contactDetails.mobilePhone.phoneNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.WORK_TELEPHONE'),
            data: personalDetails.contactDetails.workPhone.phoneNumber
                ? personalDetails.contactDetails.workPhone.phoneNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.dateOfBirthConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE_OF_BIRTH'),
            data: personalDetails.dateOfBirth
                ? Utils.DisplayDateFormat(
                    personalDetails.dateOfBirth,
                    membershipConfiguration.newMemberTimeFormat
                )
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.emailConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.EMAIL'),
            data: personalDetails.contactDetails.email
                ? personalDetails.contactDetails.email
                : personalDetails.contactDetails.overrideEmailOption.name
                    ? personalDetails.contactDetails.overrideEmailOption.name
                    : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.line1Config.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.HOME_ADDRESS'),
            data: personalDetails.addressDetails.addressLine1
                ? `${personalDetails.addressDetails.addressNumber} - ${
                    personalDetails.addressDetails.addressLine1
                } - ${personalDetails.addressDetails.city} - ${
                    personalDetails.addressDetails.state
                } - ${personalDetails.addressDetails.country.name}`
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.line2Config.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.WORK_ADDRESS'),
            data: personalDetails.addressDetails.addressLine2
                ? `${personalDetails.addressDetails.addressNumber} - ${
                    personalDetails.addressDetails.addressLine2
                } - ${personalDetails.addressDetails.city} - ${
                    personalDetails.addressDetails.state
                } - ${personalDetails.addressDetails.country.name}`
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MARKETING_DETAILS'),
            data: t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.applicationRefConfig.isVisible,
            fieldLabel: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.MEMBERSHIP_APPLICATION_REF'
            ),
            data: personalDetails.contactedDetails.applicationRef
                ? personalDetails.contactedDetails.applicationRef
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.emergencyNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.EMERGENCY_CONTACT_NAME'),
            data: personalDetails.emergencyContact.contactName
                ? personalDetails.emergencyContact.contactName
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.emergencyPhoneConfig.isVisible,
            fieldLabel: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.EMERGENCY_CONTACT_PHONE_NUMBER'
            ),
            data: personalDetails.emergencyContact.contactNumber
                ? personalDetails.emergencyContact.contactNumber.phoneNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.preferredContactMethodsConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PREFERRED_CONTACT_METHODS'),
            data: personalDetails.contactedDetails.preferredContactMethod
        },
        {
            isVisible: fieldConfigs.sourceConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SOURCE'),
            data: personalDetails.contactedDetails.marketingSource
                ? personalDetails.contactedDetails.marketingSource.name
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.marketingConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MARKETING_CONSENT'),
            data: personalDetails.contactedDetails.memberHappyToBeContact
                ? t('BUTTON.YES')
                : t('BUTTON.NO')
        },
        {
            isVisible: fieldConfigs.thirdPartyConfig.isVisible,
            fieldLabel: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.THIRD_PARTY_MARKETING_CONSENT'
            ),
            data: personalDetails.contactedDetails.thirdPartyContact
                ? t('BUTTON.YES')
                : t('BUTTON.NO')
        },
        {
            isVisible: fieldConfigs.companyNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.COMPANY_NAME'),
            data: personalDetails.otherFields.companyName
                ? personalDetails.otherFields.companyName
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.occupationConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.OCCUPATION'),
            data: personalDetails.otherFields.occupation
                ? personalDetails.otherFields.occupation
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.referredByNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.REFERRED_BY_NAME'),
            data: personalDetails.otherFields.referredByMemberName
                ? personalDetails.otherFields.referredByMemberName
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.referredByMembershipConfig.isVisible,
            fieldLabel: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.REFERRED_BY_MEMBERSHIP_NUMBER'
            ),
            data: personalDetails.otherFields.referredByMembershipNumber
                ? personalDetails.otherFields.referredByMembershipNumber
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.otherCommissonNoteConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.OTHER_COMMISSION_NOTE'),
            data: personalDetails.otherFields.otherCommissionNote
                ? personalDetails.otherFields.otherCommissionNote
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.generalNoteConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.GENERAL_NOTE'),
            data: personalDetails.otherFields.generalNote
                ? personalDetails.otherFields.generalNote
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.aiaVitalityConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.AIA_VITALITY_CATEGORY'),
            data: personalDetails.otherFields.aiaVitalityCategory.name
                ? personalDetails.otherFields.aiaVitalityCategory.name
                : t('COMMON.NA')
        },
        {
            isVisible: fieldConfigs.recurringCardTypeConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.RECURRING_CARD_TYPE'),
            data: personalDetails.otherFields.recurringCardType.name
                ? personalDetails.otherFields.recurringCardType.name
                : t('COMMON.NA')
        }
    ];

    const renderSubContent = data => {
        return (
            <div className="row">
                {data.map((field, key) => (
                    <div className="col-md-6" key={key}>
                        <div className="form-check">
                            <input type="checkbox" checked disabled />
                            <label className="icon-checked-ico">
                                {' '}
                                <span>{field.name}</span>
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
    <>
      <div className="select-group open">
          <p>
              {t(
                  'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP_AND_THEIR_MEMBERSHIP'
              )}
          </p>
          {data.map(
              (field, key) =>
                  field.isVisible && (
                      <div
                          key={key}
                          className={`row ${Array.isArray(field.data) ? 'pb-4' : 'pb-2'}`}
                      >
                          <div className="col-6 title">{field.fieldLabel}</div>
                          <div className="col-6">
                              {Array.isArray(field.data)
                                  ? renderSubContent(field.data)
                                  : field.data}
                          </div>
                      </div>
                  )
          )}
          <div className="edit-custom-btn">
              <button
                  className="btn btn-primary"
                  onClick={() => _this.handleEdit(3)}
              >
                  {t('BUTTON.EDIT')}
              </button>
          </div>
      </div>
    </>
    );
};

const HealthQuestion = _this => {
    const { personalDetails } = _this.props.newMembership;

    const { t } = _this.props;

    return (
    <>
      <div className="select-group open">
          {personalDetails.healthQuestions &&
          personalDetails.healthQuestions.map((question, index) => (
              <div className="row pb-2" key={index}>
                  <div className="col-8 d-flex justify-content-between">
                      <p className="pr-4">{t(question.name)}</p>
                      <form action="#">
                          <div className="form-check">
                              <input id="test7" type="checkbox" checked disabled />
                              <label className="icon-checked-ico"> </label>
                          </div>
                      </form>
                  </div>
              </div>
          ))}

          <div className="edit-custom-btn">
              <button
                  className="btn btn-primary"
                  onClick={() => _this.handleEdit(3)}
              >
                  {t('BUTTON.EDIT')}
              </button>
          </div>
      </div>
    </>
    );
};

const MembershipDetails = _this => {
    const { minContractEndDate } = _this.state;
    const {
        membershipSearchFilter,
        membershipConfiguration,
        selectedPackagePlan,
        selectedAddons
    } = _this.props.newMembership;

    const { t } = _this.props;
    let data = [
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE'),
            data: selectedPackagePlan.plan.package.name
                ? selectedPackagePlan.plan.package.name
                : t('COMMON.NA')
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE_PLAN'),
            data: selectedPackagePlan.plan.name
                ? selectedPackagePlan.plan.name
                : t('COMMON.NA')
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SALE_PERSON'),
            data: membershipSearchFilter.salePersonName
                ? membershipSearchFilter.salePersonName
                : t('COMMON.NA')
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.JOIN_DATE'),
            data: Utils.DisplayDateFormat(
                new Date(),
                membershipConfiguration.newMemberTimeFormat
            )
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.START_DATE'),
            data: membershipSearchFilter.startDate
                ? Utils.DisplayDateFormat(
                    membershipSearchFilter.startDate,
                    membershipConfiguration.newMemberTimeFormat
                )
                : t('COMMON.NA')
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.END_DATE'),
            data: t('COMMON.NA')
        },
        {
            fieldLabel: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.MIN_CONTRACT_PERIOD_END_DATE'
            ),
            data: membershipSearchFilter.startDate
                ? Utils.DisplayDateFormat(
                    minContractEndDate,
                    membershipConfiguration.newMemberTimeFormat
                )
                : t('COMMON.NA')
        },
        {
            fieldLabel: t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE_ELIGIBILITY_REQUIRED'
            ),
            data: t('BUTTON.NO')
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.ELIGIBILITY_PROVIDED'),
            data: t('BUTTON.NO')
        },
        {
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.MEMBER_ADD-ONS'),
            data: selectedAddons.length ? selectedAddons.reduce((acc, cur) => [...acc, cur.name], []).join(', ') : t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.THIS_MEMBER_CURRENTLY_HAS_NO_ADD-ON'
            )
        }
    ];

    return (
    <>
      <div className="select-group open">
          <p>
              {t(
                  'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP'
              )}
          </p>
          {data.map(
              (field, key) =>
                  field && (
                      <div key={key} className="row pb-2">
                          <div className="col-6 title">{field.fieldLabel}</div>
                          <div className="col-6">{field.data}</div>
                      </div>
                  )
          )}
          <div className="edit-custom-btn">
              <button
                  className="btn btn-primary"
                  onClick={() => _this.handleEdit(1)}
              >
                  {t('BUTTON.EDIT')}
              </button>
          </div>
      </div>
    </>
    );
};

const MemberPaymentDetails = _this => {
    const { fieldConfigs } = _this.state;
    const { personalDetails, paymentDetails } = _this.props.newMembership;

    const { t } = _this.props;

    let cardNumber = paymentDetails.primaryPaymentMethod.name
        .toLowerCase()
        .includes('credit')
        ? paymentDetails.creditCardNumber
        : paymentDetails.cardNumber;
    let firstNumbers = cardNumber.substring(0, 6);
    let lastNumbers = cardNumber.substring(
        cardNumber.length - 4,
        cardNumber.length
    );

    let data = [
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.PRIMARY_PAYMENT_METHOD'),
            data: paymentDetails.primaryPaymentMethod
                ? paymentDetails.primaryPaymentMethod.name
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.ACCOUNT_NAME'),
            data: paymentDetails.primaryPaymentMethod
                ? paymentDetails.accountName
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.FULL_ACCOUNT_NUMBER'),
            data: `${firstNumbers}****${lastNumbers}`
        },
        {
            isVisible: paymentDetails.primaryPaymentMethod.name
                .toLowerCase()
                .includes('credit')
                ? true
                : false,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.CARD_TYPE'),
            data: paymentDetails.cardType && paymentDetails.cardType.label
                ? paymentDetails.cardType.label
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.ISSUING_BANK'),
            data: paymentDetails.issuingBank.label
                ? paymentDetails.issuingBank.label
                : t('COMMON.NA')
        },
        {
            isVisible: paymentDetails.primaryPaymentMethod.name
                .toLowerCase()
                .includes('credit')
                ? true
                : false,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.EXPIRY_DATE'),
            data: paymentDetails.expiryDate
                ? paymentDetails.expiryDate
                : t('COMMON.NA')
        },
        {
            isVisible: paymentDetails.primaryPaymentMethod.name
                .toLowerCase()
                .includes('credit')
                ? true
                : false,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.ISSUE_NUMBER'),
            data: paymentDetails.issueNumber
                ? paymentDetails.issueNumber
                : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: fieldConfigs.nationalIdConfig.name,
            data: paymentDetails.nationalIdNumber
                ? paymentDetails.nationalIdNumber
                : personalDetails.nationalIdNumber
                    ? personalDetails.nationalIdNumber
                    : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: fieldConfigs.otherNationalIdConfig.name,
            data: paymentDetails.otherNationalIdNumber
                ? paymentDetails.otherNationalIdNumber
                : personalDetails.otherNationalIdNumber
                    ? personalDetails.otherNationalIdNumber
                    : t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.LAST_UPDATED'),
            data: t('COMMON.NA')
        },
        {
            isVisible: true,
            fieldLabel: t('PAGE.MEMBERSHIPS.CREATE.STEP_5.SECONDARY_PAYMENT_METHOD'),
            data: paymentDetails.secondaryPaymentMethod
                ? paymentDetails.secondaryPaymentMethod
                : t('COMMON.NA')
        }
    ];

    return (
    <>
      <div className="select-group open">
          <p>
              {t(
                  'PAGE.MEMBERSHIPS.CREATE.STEP_5.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP'
              )}
          </p>
          <div className="front-card">
              {paymentDetails.primaryPaymentMethod.name
                  .toLowerCase()
                  .includes('credit') ? (
                      <div className="card-image">
                          <img src={creditImage} alt="" />
                          <h4 className="name-payment">credit card</h4>
                          <h2 className="card-number">1234 5678 9012 1111</h2>
                          <div className="section-exp">
                              <div className="exp-title">
                                  <span>valid</span>
                                  <span>thru</span>
                              </div>
                              <h4 className="exp-date">12/23</h4>
                          </div>
                          <h3 className="acc-name">Name Surname</h3>
                      </div>
                  ) : (
                      <div className="debit-card-image">
                          <img src={debitImage} alt="" />
                      </div>
                  )}

              <div className="info-card-auto">
                  {data.map(
                      (field, key) =>
                          field.isVisible && (
                              <div key={key} className="row pb-2">
                                  <div className="col-6 title">{field.fieldLabel}</div>
                                  <div className="col-6">{field.data}</div>
                              </div>
                          )
                  )}
              </div>
          </div>
          <div className="edit-custom-btn">
              <button
                  className="btn btn-primary"
                  onClick={() => _this.handleEdit(4)}
              >
                  {t('BUTTON.EDIT')}
              </button>
          </div>
      </div>
    </>
    );
};

const headers = [
    {
        key: 1,
        title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.DATE'
    },
    {
        key: 2,
        title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.TYPE'
    },
    {
        key: 3,
        title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.METHOD'
    },
    {
        key: 4,
        title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.STATUS'
    },
    {
        key: 5,
        title: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.AMOUNT'
    }
];

const renderTableContent = (data, addOns, newMemberTimeFormat) => {
    let addOnDate = moment(new Date());
    let addOnMethod = '-';
    let addOnStatus = '-';
    data.map((item: MemberArrea, index) => {
        let startDate = moment(item.startDate);
        if (index === 0) {
            addOnDate = startDate;
            addOnMethod = item.method;
            addOnStatus = item.status;
        }
    });
    return <>
        {addOns && addOns.map((e: AddOn, key) => {
            const price = e.totalPrice.toString();
            const prices = price.indexOf('.') > 0 ? price.split('.') : [price, '00'];
            return <tr key={key}>
                <td className="td-left">{addOnDate.format(newMemberTimeFormat.toUpperCase())}</td>
                <td className="td-left">{e.name}</td>
                <td className="td-left">{addOnMethod}</td>
                <td className="td-left">{addOnStatus}</td>
                <td className="td-left">
                    <p className="amount">
                        {prices[0]}.<sup>{prices[1].padEnd(2, '0')}</sup>
                    </p>
                </td>
            </tr>
        })}
        {data.map((item: MemberArrea, index) => {
            let startDate = moment(item.startDate);
            if (index === 0) {
                addOnDate = startDate;
                addOnMethod = item.method;
                addOnStatus = item.status;
            }
            let amount = formatAmount(item ? item.amount : 0);

            return (
                <tr key={index}>
                    <td className="td-left">{startDate.format(newMemberTimeFormat.toUpperCase())}</td>
                    <td className="td-left">{item.typeName}</td>
                    <td className="td-left">{item.method}</td>
                    <td className="td-left">{item.status}</td>
                    <td className="td-left">
                        <p className="amount">
                            {amount[0]}.<sup>{amount[1]}</sup>
                        </p>
                    </td>
                </tr>
            );
        })}
    </>;
};

const MemberPayments = _this => {
    const { t, newMembership: {
        membershipConfiguration,
        selectedAddons
    } } = _this.props;
    if (_this.state.fees && _this.state.fees.length) {
        const tillDate = moment(
            _this.state.fees[0].startDate
        ).add(5, 'months').startOf('month');
        return (
      <>
        <p>
            {t(
                'PAGE.MEMBERSHIPS.CREATE.STEP_5.PAYMENTS_PROJECT_FOR_6_MONTHS_UNTIL_VALUE',
                { value: tillDate.format(membershipConfiguration.newMemberTimeFormat.toUpperCase()) }
            )}
        </p>
        <div className="decentralize">
            <div className="decentralize-main">
                <div className="table-responsive">
                    <CustomTable
                        headers={headers}
                        tableContent={renderTableContent(_this.state.fees, selectedAddons, membershipConfiguration.newMemberTimeFormat)}
                        renderingCondition
                    />
                </div>
            </div>
        </div>
      </>
        );
    }
};

const template = (_this: ThisInterface) => {
    const {
        membershipSearchFilter,
        membershipConfiguration,
        selectedPackagePlan,
        selectedAddons
    } = _this.props.newMembership;

    const { openDropdown } = _this.state;
    const { t, isEdit } = _this.props;

    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    {!isEdit && <p>{t('PAGE.MEMBERSHIPS.CREATE.ADD_NEW_MEMBER')}</p>}
                    {isEdit && <p>{t('PAGE.MEMBERSHIPS.EDIT.CHANGE_MEMBERSHIP')}</p>}
                </div>
                <div className="member-main">
                    <Breadcrumb
                        isEdit={isEdit}
                        membershipConfiguration={membershipConfiguration}
                        goToStep={stepIndex => _this.goToStep(stepIndex)}
                    />
                    <div className="content select-add-on personal">
                        <div className="main-payment select-add-on-main">
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.SUMMARY.MEMBER_SUMMARY')}
                                content={MemberSummary(_this)}
                                isOpen={openDropdown['memberSummary']}
                                maxHeight="1200px"
                                openCollapsedContent={() =>
                                    _this.handleOpenDropdown('memberSummary')
                                }
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.PERSONAL_DETAILS')}
                                content={PersonalDetails(_this)}
                                isOpen={openDropdown['personalDetails']}
                                maxHeight="1200px"
                                openCollapsedContent={() =>
                                    _this.handleOpenDropdown('personalDetails')
                                }
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.HEALTH_QUESTIONNAIRE')}
                                content={HealthQuestion(_this)}
                                isOpen={openDropdown['healthQuestion']}
                                maxHeight="1200px"
                                openCollapsedContent={() =>
                                    _this.handleOpenDropdown('healthQuestion')
                                }
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.MEMBERSHIP_DETAILS')}
                                content={MembershipDetails(_this)}
                                isOpen={openDropdown['membershipDetails']}
                                maxHeight="1200px"
                                openCollapsedContent={() =>
                                    _this.handleOpenDropdown('membershipDetails')
                                }
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.MEMBERSHIP_PAYMENT_DETAIL')}
                                content={MemberPaymentDetails(_this)}
                                isOpen={openDropdown['memberPaymentDetails']}
                                maxHeight="1200px"
                                openCollapsedContent={() =>
                                    _this.handleOpenDropdown('memberPaymentDetails')
                                }
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t('PAGE.MEMBERSHIPS.CREATE.MEMBERSHIP_PAYMENTS')}
                                content={MemberPayments(_this)}
                                isOpen={openDropdown['memberPayments']}
                                maxHeight="1200px"
                                openCollapsedContent={() =>
                                    _this.handleOpenDropdown('memberPayments')
                                }
                            />
                        </div>
                        <ProductSummary
                            productSummary={selectedPackagePlan}
                            membershipSearchFilter={membershipSearchFilter}
                            membershipConfiguration={membershipConfiguration}
                            addons={selectedAddons}
                            handlePrevious={() => _this.handlePrevious()}
                            handleCancel={_this.props.onCancel}
                            handleFinish={_this.props.onFinish}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;