import React from 'react';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { RouteComponentProps } from 'react-router';

import SubTenantModel from 'models/SubTennant';
import FeatureRight from 'models/Right';
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';
import * as API from 'constants/Constants';
import * as Route from 'pages/RouteLoader';
import { getAffectSubTenants } from 'util/ControlUtils';

import ThisInterface from './interface';
import template from './template';
import { withTranslation, WithTranslation } from 'react-i18next';

const filterQueryClubGroup = {
    and: [
        {
            queryKey: 'subTenantId',
            operation: 'in',
            queryType: 'guid',
            queryValue: '00000000-0000-0000-0000-000000000000'
        },
        {
            queryKey: 'name',
            operation: 'contains',
            queryType: 'text',
            queryValue: '{value}'
        }
    ]
};

class AddRolePage extends React.Component<
ThisInterface['props'] & WithTranslation & RouteComponentProps,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation & RouteComponentProps) {
        super(props);
        const { match } = props;
        this.state = {
            roleName: '',
            tenant: 'EWS',
            type: '',
            selectSubTenant: false,
            isValidSubTenant: true,
            isExpanded: true,
            isDirtyRoleName: false,
            checkedSubTenant: [],
            rights: [],
            roleTypes: [],
            subTenantList: [],
            featuresList: [],
            rightList: [],
            selectAllRight: false,
            apiSearch: API.API_SEARCH_CLUBGROUP,
            filterQuery: filterQueryClubGroup,
            roleId: match.params['id'] || '',
            isEditMode: match.params['id'] && match.params['id'] !== '',
            disabled: true,
            isLoadingFeature: true,
            isLoadingType: true,
            affectSubTenants: []
        };
    }

    componentDidMount() {
        this.getRoleTypes();
    }

    filterClubGroup = (list: string[]) => {
        const filter = {
            and: [
                {
                    queryKey: 'subTenantId',
                    operation: 'in',
                    queryType: 'guid',
                    queryValue: list.join()
                },
                {
                    queryKey: 'name',
                    operation: 'contains',
                    queryType: 'text',
                    queryValue: '{value}'
                }
            ]
        };

        this.setState({
            filterQuery: filter
        });
    };

    getRoleTypes = () => {
        const { conmanService } = this.props;
        const { isEditMode } = this.state;
        const rightName = isEditMode
            ? Route.EditRoleRoute.rightName
            : Route.AddRoleRoute.rightName;
        Promise.all([
            conmanService.getRoleTypes(),
            conmanService.getSubTenants(rightName)
        ])
            .then(([res1, res2]) => {
                const res1Condition = res1 && !_.isEmpty(res1.data);
                const subTenantList = res2 && !_.isEmpty(res2.data) ? res2.data : [];

                this.setState(
                    {
                        roleTypes: res1Condition ? res1.data : []
                    },
                    () => {
                        if (!isEditMode) {
                            this.initCreateMode(subTenantList, res1.data[0].id);
                        } else {
                            this.initEditMode(subTenantList);
                        }
                    }
                );
            })
            .catch(() => {
                this.setState({
                    roleTypes: [],
                    type: '',
                    subTenantList: [],
                    isLoadingFeature: false,
                    isLoadingType: false
                });
            });
    };

    //Process data
    initCreateMode = (subTenantList: SubTenantModel[], type: string) => {
        this.setState(
            {
                subTenantList,
                type
            },
            () => {
                if (this.state.type) {
                    this.setState({
                        isLoadingType: false
                    });
                    this.getResourceList(this.state.type);
                }
            }
        );
    };

    initEditMode = (subtenants: SubTenantModel[]) => {
        const { roleId } = this.state;
        const { conmanService } = this.props;

        // Get subTenantId & rightName
        const rightName = Route.EditRoleRoute.rightName;

        conmanService.getRoleById(roleId, rightName).then(res => {
            this.mapDataToState(subtenants, res);
        });
    };

    mapDataToState = (subTenants: SubTenantModel[], role) => {
        const roleSubTenants = !_.isEmpty(role.type.subTenants)
            ? role.type.subTenants.map(item => ({ id: item.id, name: item.name }))
            : [];
        const userSubTenants = subTenants.map(item => ({
            id: item.id,
            name: item.name
        }));
        this.setState(
            {
                roleName: role.name,
                type: role.type.id,
                subTenantList: subTenants,
                rights: role.type.rights,
                selectSubTenant: role.type.subTenants.length > 0,
                isLoadingType: false,
                affectSubTenants: getAffectSubTenants(roleSubTenants, userSubTenants)
            },
            () => {
                this.handleRenderSubTenant(role.type.subTenants);
                this.getResourceList(role.type.id);
            }
        );
    };

    handleRenderSubTenant = data => {
        let subTenant = data.map(d => d.id);
        let subTenantList = this.state.subTenantList;

        subTenantList = subTenantList.map(st => {
            return {
                ...st,
                active: subTenant.some(s => s === st.id)
            };
        });

        this.setState(
            {
                checkedSubTenant: subTenant,
                subTenantList: subTenantList
            },
            () => {
                this.filterClubGroup(this.state.checkedSubTenant);
            }
        );
    };

    getResourceList = (id: string) => {
        this.props.conmanService
            .getResourceByType(id)
            .then(res => {
                const resCondition = res && !_.isEmpty(res.data);
                this.setState(
                    {
                        featuresList: resCondition ? res.data : [],
                        isLoadingFeature: false
                    },
                    () => {
                        this.handleRenderRights(this.state.featuresList);
                    }
                );
            })
            .catch(() => {
                this.setState({
                    featuresList: [],
                    isLoadingFeature: false
                });
            });
    };

    handleRenderRights = data => {
        const rightList: string[] = [];
        const { rights } = this.state;
        const featuresList = data;
        const results = featuresList.map(feature => ({
            ...feature,
            resources: feature.resources.map(resource => ({
                ...resource,
                rights: resource.rights.map(right => {
                    rightList.push(right.normalizeName);
                    return {
                        ...right,
                        active: rights.some(r => r === right.normalizeName)
                    };
                })
            }))
        }));

        this.setState({
            featuresList: results,
            rightList,
            selectAllRight: rights.length === rightList.length
        });
    };

    onCollapsed = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    };

    handleSelectDeselectAllGroup = (indexGroup: number, isActive: boolean) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const { featuresList, rights } = this.state;
        const rightsSelected: string[] = [];

        for (let [index1, resource] of featuresList[
            indexGroup
        ].resources.entries()) {
            for (let [index2] of resource.rights.entries()) {
                featuresList[indexGroup].resources[index1].rights[
                    index2
                ].active = isActive;
                rightsSelected.push(
                    featuresList[indexGroup].resources[index1].rights[index2]
                        .normalizeName
                );
                this.setState({
                    featuresList
                });
            }
        }

        const newRights = isActive
            ? _.difference(rightsSelected, rights)
            : _.difference(rights, rightsSelected);
        this.setState(prevState => {
            return {
                rights: isActive ? prevState.rights.concat(newRights) : newRights,
                disabled: false
            };
        });

        const group = document.querySelectorAll(`.group-${indexGroup}`);
        const target = group[0].children;

        if (!target[1].classList.contains('active')) {
            const buttonTarget = target[1].children;
            const element: HTMLElement = buttonTarget[0] as HTMLElement;
            element.click();
        }
    };

    handleSelectDeselectAllResource = (
        indexGroup: number,
        indexResource: number,
        isActive: boolean
    ) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const { featuresList, rights } = this.state;
        const rightsSelected: string[] = [];

        for (let [index] of featuresList[indexGroup].resources[
            indexResource
        ].rights.entries()) {
            featuresList[indexGroup].resources[indexResource].rights[
                index
            ].active = isActive;
            rightsSelected.push(
                featuresList[indexGroup].resources[indexResource].rights[index]
                    .normalizeName
            );
            this.setState({
                featuresList
            });
        }

        const newRights = isActive
            ? _.difference(rightsSelected, rights)
            : _.difference(rights, rightsSelected);
        this.setState(prevState => {
            return {
                rights: isActive ? prevState.rights.concat(newRights) : newRights,
                disabled: false
            };
        });
    };

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = e.target.value.replace(/[^a-zA-Z- ]/g, '');
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(prevState => {
            return {
                ...prevState,
                isDirtyRoleName: value === '',
                disabled: false,
                [key]: value
            };
        });
    };

    handleChangeType = (value: { value: string }) => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(
            {
                type: value.value,
                rights: [],
                selectAllRight: false,
                disabled: false,
                isLoadingFeature: true
            },
            () => {
                this.getResourceList(this.state.type);
                document
                    .querySelectorAll<HTMLInputElement>('.feature-checkbox')
                    .forEach(el => (el.checked = false));
            }
        );
    };

    handleChangeSubTenant = () => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(
            {
                selectSubTenant: !this.state.selectSubTenant,
                checkedSubTenant: [],
                filterQuery: filterQueryClubGroup,
                disabled: false
            },
            () => {
                const { selectSubTenant, checkedSubTenant } = this.state;
                const subTenantList = this.state.subTenantList.map(subTenant => ({
                    ...subTenant,
                    active: false
                }));
                this.setState({
                    subTenantList,
                    isValidSubTenant: selectSubTenant
                        ? checkedSubTenant.length !== 0
                        : true
                });
            }
        );
    };

    handleSelectSubTenant = (e: React.ChangeEvent<HTMLInputElement>, i) => {
        const { subTenantList } = this.state;

        subTenantList[i].active = !subTenantList[i].active;

        const options = this.state.checkedSubTenant;

        if (e.target.checked) {
            options.push(e.target.value);
        } else {
            const index = options.indexOf(e.target.value);
            options.splice(index, 1);
        }
        this.setState({
            checkedSubTenant: options,
            subTenantList,
            disabled: false,
            isValidSubTenant: options.length !== 0
        });
        this.filterClubGroup(options);
    };

    handleSelectRights = (e: React.ChangeEvent<HTMLInputElement>, value) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const featuresList = this.state.featuresList.map(item =>
            FeatureRight.clone(item)
        );

        featuresList.forEach((feature, index) => {
            feature.resources.forEach((resource, index1) => {
                const id = resource.rights.findIndex(e => {
                    return e.id === value;
                });
                const right = resource.rights.find(e => e.id === value);
                if (id > -1 && right) {
                    featuresList[index].resources[index1].rights[
                        id
                    ].active = !featuresList[index].resources[index1].rights[id].active;
                    if (
                        right.priority < 10 &&
						!right.normalizeName.includes('add') &&
						!right.normalizeName.includes('delete')
                    ) {
                        featuresList[index].resources[index1].rights.forEach(viewRight => {
                            if (viewRight.priority >= 10 &&
								featuresList[index].resources[index1].rights[id].active
                            ) {
                                viewRight.active =
                  					featuresList[index].resources[index1].rights[id].active;
                            } 
                        });
                    } else if (
                        right.priority >= 10 &&
						!featuresList[index].resources[index1].rights[id].active
                    ) {
                        featuresList[index].resources[index1].rights.forEach(editRight => {
                            if (
                                editRight.priority < 10 &&
								!editRight.normalizeName.includes('add') &&
								!editRight.normalizeName.includes('delete')
                            ) {
                                editRight.active = false;
                            }
                        });
                    }
                }
            });
        });
        const finalRight: string[] = [];
        featuresList.forEach(feature => {
            feature.resources.forEach(resource => {
                resource.rights.forEach(right => {
                    if (right.active) {
                        finalRight.push(right.normalizeName)
                    }
                });
            });
        });

        this.setState(
            {
                rights: finalRight,
                featuresList,
                disabled: false
            },
            () => {
                const { rightList, rights } = this.state;
                this.setState({
                    selectAllRight: rightList.length === rights.length
                });
            }
        );
    };

    checkingReadyToSave = () => {
        const {
            roleName,
            type,
            rights,
            checkedSubTenant,
            isValidSubTenant
        } = this.state;

        if (!roleName) {
            this.setState({
                isDirtyRoleName: true
            });
        }

        if (roleName && isValidSubTenant) {
            return {
                roleName,
                type,
                checkedSubTenant,
                rights
            };
        }
        return false;
    };

    createOrUpdateRole = data => {
        const { conmanService, history, t } = this.props;
        const { roleId, isEditMode } = this.state;
        Promise.all([
            isEditMode
                ? conmanService.updateRole(roleId, data)
                : conmanService.createRole(data)
        ]).then(([]) => {
            observer.publish('flagUnsavedChangeEvent', false);
            this.setState(
                {
                    disabled: true
                },
                () => {
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('PAGE.SYSTEM.ROLES.ADD_EDIT.SAVE_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    }).then(() => history.push('/system/roles'));
                }
            );
        });
    };

    handleSaveRole = () => {
        const submitData = this.checkingReadyToSave();
        const { affectSubTenants, isEditMode } = this.state;
        const { t } = this.props;
        const effectiveString = !_.isEmpty(affectSubTenants)
            ? affectSubTenants.join(', ')
            : '';
        if (submitData) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t(
                    
                    isEditMode && effectiveString !== ''
                        ? 'PAGE.SYSTEM.ROLES.ADD_EDIT.CONFIRM_SAVE_WITH_EFFECTIVE'
                        : 'PAGE.SYSTEM.ROLES.ADD_EDIT.CONFIRM_SAVE_ROLE'
                    ,
                    { key: effectiveString }
                ),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(res => {
                if (res.value) {
                    Swal.fire({
                        html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                        showConfirmButton: false
                    });

                    const { roleName, type, checkedSubTenant, rights } = submitData;

                    const data = {
                        Name: roleName,
                        RoleTypeId: type,
                        SubTenants: checkedSubTenant.map(item => ({ SubTenantId: item })),
                        Rights: rights
                    };
                    this.createOrUpdateRole(data);
                }
            });
        }
    };

    handleCancel = () => {
        const { history } = this.props;
        history.push('/system/roles');
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(AddRolePage);
