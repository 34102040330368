import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as Interface from 'interfaces';
import embededWrapper from 'hocs/Embeded';
import Loading from 'components/Loading';
import { getIframeAPIURL, parseParamFromUrl } from 'util/ControlUtils';
import {
    IFRAME_CLUB_BANKING,
    IFRAME_OUTSTANDING_SHIFT,
    IFRAME_CLOSE_SHIFT
} from 'constants/Constants';

interface Props extends RouteComponentProps {
    auth: Interface.StateToPropInterface['oidc'];
    iframeLoaded: boolean;
    iframeToken: string;
    subTenantId: string;
    handleNext: (subtenant) => void;
    resizeIframe: (obj) => void;
}

interface State {
    contentType: string;
}

const embeded = (route, title = '', description = '') => {

    class MFClubDetailFeatures extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            const { location: { pathname } } = props;
            let contentType = '';
            if (pathname.includes('club-banking')) {
                contentType = 'clubBanking';
            } else if (pathname.includes('club-outstanding-shift')) {
                contentType = 'outstandingShift';
            } else if (pathname.includes('club-close-shift')) {
                contentType = 'closeShift';
            }
	
            this.state = {
                contentType
            };
        }
		
	
        componentDidMount = () => {
            window.addEventListener('message', this.messageHandler);
        }
		
        componentWillUnmount() {
            window.removeEventListener('message', this.messageHandler);
        }
	
        messageHandler = (event) => {
            const { action, value } = event.data;
            const { match: { params } } = this.props;
			
            switch (action) {
                case 'returnHeightData': 
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const obj: any = this.refs.iframe;
                    if (obj) {
                        obj.style.height = value + 'px';
                    }
                    break;
                case 'redirectURL':
                    if (value.includes('ClubId')) {
                        const paramUrl = decodeURIComponent(value).split('?');
                        const redirectUrl = paramUrl.find(club => club.indexOf('ClubId=') > -1);
                        const params = redirectUrl && parseParamFromUrl(redirectUrl);
                        const clubIdObj = params && params.find(club => club.key === 'ClubId');
						
                        if (value.includes('ClubBankingSession.aspx')) {
                            clubIdObj && this.props.history.push(`/clubs/${clubIdObj.value}/club-banking`);
                        }
                        if (value.includes('Clubs/Shifts.aspx')) {
                            clubIdObj && this.props.history.push(`/clubs/${clubIdObj.value}/club-outstanding-shift`);
                        }
                        if (value.includes('Clubs/ClosedShifts.aspx')) {
                            clubIdObj && this.props.history.push(`/clubs/${clubIdObj.value}/club-close-shift`);
                        }
                    }
                    if (value.includes('Clubs/Club.aspx')) {
                        this.props.history.push(`/clubs/detail/${params['id']}/view`)
                    }
                    break;
                default:
                    break;
            }
        }
		
        getDynamicIframeURL = () => {
            const { contentType } = this.state;
            switch (contentType) {
                case 'clubBanking':
                    return IFRAME_CLUB_BANKING;
                case 'outstandingShift':
                    return IFRAME_OUTSTANDING_SHIFT;
                case 'closeShift':
                    return IFRAME_CLOSE_SHIFT;
                default:
                    return '';
            };
        } 
		
        render() {
            const {
                iframeLoaded,
                iframeToken,
                subTenantId,
                match: { params }
            } = this.props;
	
            return (
                <div className="member mt-3">
                    <div className="member-main member-main--custom" style={{padding: 0}}>
                        {!iframeLoaded && <Loading />}
                        {iframeToken && (
                            <iframe
                                src={`${getIframeAPIURL(this.getDynamicIframeURL().replace('{0}', params['id']), subTenantId, '/MembershipManagement')}&token=${iframeToken}`}
                                ref='iframe'
                                onLoad={() => this.props.resizeIframe(this.refs.iframe)}
                                className="iframe"
                                frameBorder="0"
                            />
                        )}
                    </div>
                </div>
            )
        }
    }

    return embededWrapper(MFClubDetailFeatures, route, title, description);
}

export default embeded;
