import React from 'react';

const FourButton = () => {
    return (
        <div className="four-button">
            <div className="two-button">
                <button className="btn-one btn btn-primary" >
					Primary
                </button>
                <button className="btn-one btn btn-secondary" >
					Secondary 
                </button>
            </div>
            <div className="two-button">
                <button className="btn-one btn btn-secondary" >
					Dark
                </button>
                <button className="btn-one btn btn-dark" >
					Dark
                </button>
            </div>
        </div>
    )
}

export default FourButton;