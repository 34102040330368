import * as React from 'react';
import { connect } from 'react-redux';
import userManager from 'util/IdentityOidc';

class SilentPage extends React.Component<{}, {}> {

    render() {
        userManager.signinSilentCallback();
        return null;
    }
}

export default connect()(SilentPage);
