import React from 'react'
import _ from 'lodash';
import moment from 'moment';
import { LookupModel } from 'models/PersonalDetails';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import { t } from 'util/I18nMessages';

import { 
    PersonalDetails,
    ContactDetails,
    AddressDetails,
    ContactedDetails,
    EmergencyContact,
    HealthQuestion,
    OtherFields
} from './components';
import ThisInterface from './interface';

export const getStringValue = (obj, key: string) => {
    let value = _.get(obj, key);
    return value || t('COMMON.NA');
};

export const getBooleanValue = (obj, key: string) => {
    let value = _.get(obj, key);

    return value
        ? t('BUTTON.YES')
        : t('BUTTON.NO');
};

export const getDateValue = (obj, key: string, format: string) => {
    let value = _.get(obj, key);

    if (!value || !moment(value).isValid)
        return t('COMMON.NA');

    return moment(value).format(
        format ? format.toUpperCase() : GENERAL_DATE_FORMAT
    );
};

export const getEmailValue = (
    obj,
    emailKey: string,
    overrideEmailKey: string,
    noLongerUseKey: string
): string[] => {
    let email = _.get(obj, emailKey);
    if (!email) {
        const overrideEmail =
      _.get(obj, overrideEmailKey) || t('COMMON.NA');
        return [overrideEmail];
    }

    const noUse = _.get(obj, noLongerUseKey);
    return noUse
        ? [
            email,
            t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.NO_LONGER_IN_USE')
        ]
        : [email];
};

export const getPhoneValue = (
    obj,
    phoneKey: string,
    noLongerUseKey: string
) => {
    const phone = _.get(obj, phoneKey);

    if (!phone) return [t('COMMON.NA')];

    const noUse = _.get(obj, noLongerUseKey);
    return noUse
        ? [
            phone,
            t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.NO_LONGER_IN_USE')
        ]
        : [phone];
};

export const getPreferContactsMethods = (obj, key: string) => {
    const contactMethods: LookupModel[] = _.get(obj, key);

    if (!contactMethods || contactMethods.length === 0)
        return [t('COMMON.NA')];

    return contactMethods
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(cm => cm.name);
};

export const renderCollapseContents = (_this: ThisInterface) => {
    const {
        dateFormat,
        fieldConfigs,
        originalPersonalDetails,
        newPersonalDetails
    } = _this.props;
	
    return [
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.PERSONAL_DETAILS',
            content: <PersonalDetails 
                dateFormat={dateFormat}
                fieldConfigs={fieldConfigs.personalDetailsFieldConfigs}
                originalDetails={originalPersonalDetails.personalDetails}
                newDetails={newPersonalDetails.personalDetails}
            />,
            key: 'personalDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.CONTACT_DETAILS',
            content: <ContactDetails
                fieldConfigs={fieldConfigs.contactDetailsFieldConfigs}
                originalDetails={originalPersonalDetails.contactDetails}
                newDetails={newPersonalDetails.contactDetails}
            />,
            key: 'contactDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.ADDRESS_DETAILS',
            content: <AddressDetails
                fieldConfigs={fieldConfigs.addressDetailsFieldConfigs}
                originalDetails={originalPersonalDetails.addressDetails}
                newDetails={newPersonalDetails.addressDetails}
            />,
            key: 'addressDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.CONTACTED_DETAILS',
            content: <ContactedDetails
                fieldConfigs={fieldConfigs.contactedDetailsFieldConfigs}
                originalDetails={originalPersonalDetails.contactedDetails}
                newDetails={newPersonalDetails.contactedDetails}
            />,
            key: 'contactedDetails'
        },
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT',
            content: <EmergencyContact
                fieldConfigs={fieldConfigs.emergencyContactFieldsConfigs}
                originalDetails={originalPersonalDetails.emergencyContact}
                newDetails={newPersonalDetails.emergencyContact}
            />,
            key: 'emergencyContact'
        },
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.HEALTH_QUESTIONNAIRE',
            content: <HealthQuestion                                   
                healthQuestions={newPersonalDetails.healthQuestions}                                   
            />,
            key: 'healthQuestion'
        },
        {
            title: 'PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.OTHER_FIELDS',
            content: <OtherFields
                fieldConfigs={fieldConfigs.otherFieldsFieldsConfigs}
                originalDetails={originalPersonalDetails.otherFields}
                newDetails={newPersonalDetails.otherFields}
            />,
            key: 'otherFields'
        },
    ]
}
