import React from 'react';
import { getIframeAPIURL } from 'util/ControlUtils';
import { IFRAME_ADD_PROMOTION, IFRAME_EDIT_PROMOTION } from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import embededWrapper from 'hocs/Embeded';
import Loading from 'components/Loading';
import ThisInterface from './interface';

const embeded= (route, title= '', description = '') => {
    class AddEditPromotion extends React.Component<ThisInterface['props'], {}> {
        componentDidMount = () => {
            window.addEventListener('message', this.messageHandler);
        }
		
        componentWillUnmount() {
            window.removeEventListener('message', this.messageHandler);
        }
    
        messageHandler = (event) => {
            const { action, value } = event.data;
    
            switch (action) {
                case 'returnHeightData': 
                    const obj: any = this.refs.iframe;
                    if (obj) {
                        obj.style.height = value + 'px';
                    }
                    break;
                case 'redirectURL':
                    if(value.replace(/%2f/g, '/').includes('/Products/Promotions/Promotions.aspx') || value.includes('MEMBER_UPDATE_CANCELLED_FIELD')) {
                        this.props.history.push(`/${RouteLoader.PromotionRoute.url}`);
                        break;
                    }
            }
        }
        
        render() {
            const { iframeLoaded, iframeToken, subTenantId } = this.props;
            const {match: {params}} = this.props;
            const isEditMode = !!params['id'];
            const editUrl = params['id'] ? IFRAME_EDIT_PROMOTION.replace('{0}', params['id']) : '';
    
            return (
                <>
                    <div className="member mt-3">
                        <div className="member-main member-main--custom">
                            <div className="content">
                                {!iframeLoaded && <Loading />}
                                {iframeToken && !isEditMode && (
                                    <iframe
                                        src={`${getIframeAPIURL(IFRAME_ADD_PROMOTION, subTenantId, '/MembershipManagement')}&token=${iframeToken}`}
                                        ref='iframe'
                                        onLoad={() => this.props.resizeIframe(this.refs.iframe)}
                                        className="iframe"
                                        frameBorder="0"
                                    />
                                )}
                                {iframeToken && isEditMode && (
                                    <iframe
                                        src={`${getIframeAPIURL(editUrl, subTenantId, '/MembershipManagement')}&token=${iframeToken}`}
                                        ref='iframe'
                                        onLoad={() => this.props.resizeIframe(this.refs.iframe)}
                                        className="iframe"
                                        frameBorder="0"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </>
               
            )
        }
    }
    return embededWrapper(AddEditPromotion, route, title, description);
}

export default embeded;