import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import SelectSubTenant from 'components/SelectSubTenant';
import ConmanService from 'services/conman.service';
import * as Interface from 'interfaces';
import * as Route from 'pages/RouteLoader';
import { handleSessionExpired } from 'util/ControlUtils';


interface Props extends RouteComponentProps, WithTranslation {
    auth: Interface.StateToPropInterface['oidc'];
}

interface State {
    iframeLoaded: boolean;
    iframeToken: string;
    forceHiddenIframe: boolean;
    subTenantId: string;
    subTenantName: string;
    id?: string;
}

const embededWrapper = (Component, routePage, title = '', description = '') => {
    class EmbededWrapper extends React.Component<Props, State> {
        conmanService: ConmanService
        handleTimeout: number;
        constructor(props: Props) {
            super(props);
            this.state = {
                iframeLoaded: false,
                iframeToken: '',
                forceHiddenIframe: false,
                subTenantId: localStorage.iframeSubtenantId
                    ? localStorage.iframeSubtenantId
                    : '',
                subTenantName: localStorage.iframeSubtenantName
                    ? localStorage.iframeSubtenantName
                    : '',
                id: props.match.params['id']
            };
            this.conmanService = new ConmanService();
            this.handleTimeout = 0;
        }

        componentDidMount() {
            const { subTenantId } = this.state;
            if (subTenantId) {
                this.getIframeToken(subTenantId);
            }
            //for redirect to login embedded page
            const { t } = this.props;
            window.addEventListener('message', (event) => {
                const { action, value } = event.data;
				
                if (action === 'redirectURL') {
                    if (value.includes('SignOn.aspx')) {
                        this.setState({forceHiddenIframe: true});
                    	handleSessionExpired();
                    } else {
                        Swal.close();
                        clearTimeout(this.handleTimeout);
                    }
                }
                if (action === 'handleLoading') {
                    if (value) {
                        this.handleTimeout = setTimeout(() => {
                            Swal.fire({
                                html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                                showConfirmButton: false,
                                allowOutsideClick: false
                            });
                        }, 500);
                    } else {
                        Swal.close();
                        clearTimeout(this.handleTimeout);
                    }
                }
            });
        }

        componentDidUpdate({}, prevState: State) {
            const { subTenantId } = this.state;
            if (subTenantId && subTenantId !== prevState.subTenantId) {
                this.getIframeToken(subTenantId);
            }
        }

        getIframeToken = (subTenantId: string) => {
            this.conmanService.getIframeToken(subTenantId)
                .then(res => {
                    this.setState({
                        iframeToken: res.message
                    });
                });
        };

        handleNext = subTenant => {
            this.setState({ 
                subTenantId: subTenant.id,
                subTenantName: subTenant.name,
            });
            localStorage.iframeSubtenantId = subTenant.id;
            localStorage.iframeSubtenantName = subTenant.name;
            this.getIframeToken(subTenant.id);
        };
		
        resizeIframe = (obj, callback = () => { }) => {
            this.setState({
                iframeLoaded: true
            }, () => {
                obj.contentWindow.postMessage({
                    action: 'getHeightData'
                }, '*');
                callback();
            });
        }

        render() {
            const { subTenantId, forceHiddenIframe, subTenantName } = this.state;
            
            const { t } = this.props; 
    
            return (
				<>
					<div className="items-detail">
					    <div className="list-page plan-list-view-page">
					        <div className="list-page-title">
					            <p className="title-content title-content-list">
					                {subTenantId === "" ? t(title) : t(title) + "-" + subTenantName}
					            </p>
					        </div>
					        <div className="box-btn-filter ml-auto d-flex">
					            <span className="plan-hint">
					                {t(description)}
					            </span>
					        </div>
					    </div>
					</div>
					{!subTenantId ? (
					    <SelectSubTenant 
					        rightName={Route[routePage].rightName}
					        conmanService={this.conmanService}
					        onNext={this.handleNext}
					    />
					) : (
					    !forceHiddenIframe && <Component
					        {...this.props}
					        {...this.state}
					        handleNext={this.handleNext}
					        resizeIframe={this.resizeIframe}
					    />
					)}
				</>
            );
        }
    }

    return withRouter(withTranslation()(EmbededWrapper));
};

export default embededWrapper;
