/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import _ from 'lodash';
import {
    PersonalDetailFieldsModel,
    PersonalDetailsModel,
    ContactDetailsFieldsModel,
    ContactDetailsModel,
    AddressDetailsFieldsModel,
    AddressDetailsModel,
    ContactedDetailsFieldsModel,
    ContactedDetailsModel,
    EmergencyContactFieldsModel,
    EmergencyContactModel,
    OtherFieldsFieldsModel,
    OtherFieldsModel,
    HealthQuestionModel
} from 'models/PersonalDetails';
import {
    getStringValue,
    getBooleanValue,
    getDateValue,
    getEmailValue,
    getPhoneValue,
    getPreferContactsMethods
} from './common';
import { useTranslation } from 'react-i18next';

interface RowData {
    isVisible: boolean;
    fieldLabel: string;
    oData: any;
    nData: any;
}

const renderRows = (data: RowData[]) => {
    const { t } = useTranslation();
    return data.map(
        (field, key) =>
            field.isVisible && (
                <div key={key} className="row pb-2">
                    <div className="col-4 title">{t(field.fieldLabel)}</div>
                    {!_.isArray(field.oData) ? (
                        <React.Fragment>
                            <div className="col-4 multi-line">{field.oData}</div>
                            <div
                                className={`col-4 multi-line ${
                                    field.nData === field.oData ? '' : 'data-has-changed'
                                }`}
                            >
                                {field.nData}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="col-4 multi-line">
                                {field.oData.map((data, index) => (
                                    <span key={index}>{data}</span>
                                ))}
                            </div>
                            <div
                                className={`col-4 multi-line ${
                                    _.xor(field.nData, field.oData).length === 0
                                        ? ''
                                        : 'data-has-changed'
                                }`}
                            >
                                {field.nData.map((data, index) => (
                                    <span key={index}>{data}</span>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )
    );
};

export const PersonalDetails =  ({
    dateFormat,
    fieldConfigs,
    originalDetails,
    newDetails
}: {
    dateFormat: string;
    fieldConfigs: PersonalDetailFieldsModel;
    originalDetails: PersonalDetailsModel;
    newDetails: PersonalDetailsModel;
}) => {
    const { t } = useTranslation();
    const data = [
        {
            isVisible: fieldConfigs.titleConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.TITLE'),
            oData: getStringValue(originalDetails, 'title.name'),
            nData: getStringValue(newDetails, 'title.name')
        },
        {
            isVisible: fieldConfigs.genderConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.GENDER'),
            oData: getStringValue(originalDetails, 'gender.name'),
            nData: getStringValue(newDetails, 'gender.name')
        },
        {
            isVisible: fieldConfigs.firstNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.FIRST_NAME'),
            oData: getStringValue(originalDetails, 'firstName'),
            nData: getStringValue(newDetails, 'firstName')
        },
        {
            isVisible: fieldConfigs.lastNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.LAST_NAME'),
            oData: getStringValue(originalDetails, 'lastName'),
            nData: getStringValue(newDetails, 'lastName')
        },
        {
            isVisible: fieldConfigs.nationalConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.NATIONAL_ID_NUMBER'),
            oData: getStringValue(originalDetails, 'nationalIdNumber'),
            nData: getStringValue(newDetails, 'nationalIdNumber')
        },
        {
            isVisible: fieldConfigs.nationalConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.OLD_OTHER_NATIONAL_ID'),
            oData: getStringValue(originalDetails, 'otherNationalIdNumber'),
            nData: getStringValue(newDetails, 'otherNationalIdNumber')
        },
        {
            isVisible: fieldConfigs.dateOfBirthConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.DATE_OF_BIRTH'),
            oData: getDateValue(originalDetails, 'dateOfBirth', dateFormat),
            nData: getDateValue(newDetails, 'dateOfBirth', dateFormat)
        },
        {
            isVisible: fieldConfigs.preferedLanguageConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.MEMBER_LANGUAGE_PREFERENCE'),
            oData: getStringValue(originalDetails, 'preferedLanguage.name'),
            nData: getStringValue(newDetails, 'preferedLanguage.name')
        }
    ];

    return <div className="select-group open">{renderRows(data)}</div>;
};

export const ContactDetails = ({
    fieldConfigs,
    originalDetails,
    newDetails
}: {
    fieldConfigs: ContactDetailsFieldsModel;
    originalDetails: ContactDetailsModel;
    newDetails: ContactDetailsModel;
}) => {
    const { t } = useTranslation();
    const data = [
        {
            isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.MOBILE_TELEPHONE'),
            oData: getPhoneValue(
                originalDetails,
                'mobilePhone.phoneNumber',
                'mobilePhoneNoLongerUse'
            ),
            nData: getPhoneValue(
                newDetails,
                'mobilePhone.phoneNumber',
                'mobilePhoneNoLongerUse'
            )
        },
        {
            isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.HOME_TELEPHONE'),
            oData: getPhoneValue(
                originalDetails,
                'homePhone.phoneNumber',
                'homePhoneNoLongerUse'
            ),
            nData: getPhoneValue(
                newDetails,
                'homePhone.phoneNumber',
                'homePhoneNoLongerUse'
            )
        },
        {
            isVisible: fieldConfigs.telephoneNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.WORK_TELEPHONE'),
            oData: getPhoneValue(
                originalDetails,
                'workPhone.phoneNumber',
                'workPhoneNoLongerUse'
            ),
            nData: getPhoneValue(
                newDetails,
                'workPhone.phoneNumber',
                'workPhoneNoLongerUse'
            )
        },
        {
            isVisible: fieldConfigs.emailConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMAIL_ADDRESS'),
            oData: getEmailValue(
                originalDetails,
                'email',
                'overrideEmailOption.name',
                'emailNoLongerUse'
            ),
            nData: getEmailValue(
                newDetails,
                'email',
                'overrideEmailOption.name',
                'emailNoLongerUse'
            )
        }
    ];

    return <div className="select-group open">{renderRows(data)}</div>;
};

export const AddressDetails = ({
    fieldConfigs,
    originalDetails,
    newDetails
}: {
    fieldConfigs: AddressDetailsFieldsModel;
    originalDetails: AddressDetailsModel;
    newDetails: AddressDetailsModel;
}) => {
    const { t } = useTranslation()
    const data = [
        {
            isVisible: fieldConfigs.addressNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.NUMBER_NAME'),
            oData: getStringValue(originalDetails, 'addressNumber'),
            nData: getStringValue(newDetails, 'addressNumber')
        },
        {
            isVisible: fieldConfigs.addressLine1Config.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.LINE_1'),
            oData: getStringValue(originalDetails, 'addressLine1'),
            nData: getStringValue(newDetails, 'addressLine1')
        },
        {
            isVisible: fieldConfigs.addressLine2Config.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.LINE_2'),
            oData: getStringValue(originalDetails, 'addressLine2'),
            nData: getStringValue(newDetails, 'addressLine2')
        },
        {
            isVisible: fieldConfigs.cityConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.CITY_TOWN'),
            oData: getStringValue(originalDetails, 'city'),
            nData: getStringValue(newDetails, 'city')
        },
        {
            isVisible: fieldConfigs.stateConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.STATE_COUNTY'),
            oData: getStringValue(originalDetails, 'state'),
            nData: getStringValue(newDetails, 'state')
        },
        {
            isVisible: fieldConfigs.postCodeConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.POSTCODE'),
            oData: getStringValue(originalDetails, 'postCode'),
            nData: getStringValue(newDetails, 'postCode')
        },
        {
            isVisible: fieldConfigs.countryConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.COUNTRY'),
            oData: getStringValue(originalDetails, 'country.name'),
            nData: getStringValue(newDetails, 'country.name')
        },
        {
            isVisible: fieldConfigs.goneAwayConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.GONE_AWAY'),
            oData: getBooleanValue(originalDetails, 'goneAway'),
            nData: getBooleanValue(newDetails, 'goneAway')
        }
    ];

    return <div className="select-group open">{renderRows(data)}</div>;
};

export const ContactedDetails = ({
    fieldConfigs,
    originalDetails,
    newDetails
}: {
    fieldConfigs: ContactedDetailsFieldsModel;
    originalDetails: ContactedDetailsModel;
    newDetails: ContactedDetailsModel;
}) => {
    const { t } = useTranslation();
    const data = [
        {
            isVisible: fieldConfigs.applicationRefConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.MEMBERSHIP_APPLICATION_REF'),
            oData: getStringValue(originalDetails, 'applicationRef'),
            nData: getStringValue(newDetails, 'applicationRef')
        },
        {
            isVisible: fieldConfigs.memberHappyToBeContactConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.MARKETING_CONSENT'),
            oData: getBooleanValue(originalDetails, 'memberHappyToBeContact'),
            nData: getBooleanValue(newDetails, 'memberHappyToBeContact')
        },
        {
            isVisible: fieldConfigs.thirdPartyContactConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.THIRD_PARTY_MARKETING_CONSENT'),
            oData: getBooleanValue(originalDetails, 'thirdPartyContact'),
            nData: getBooleanValue(newDetails, 'thirdPartyContact')
        },
        {
            isVisible: fieldConfigs.preferredContactMethodConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.PREFERRED_CONTACT_METHODS'),
            oData: getPreferContactsMethods(
                originalDetails,
                'preferredContactMethod'
            ),
            nData: getPreferContactsMethods(newDetails, 'preferredContactMethod')
        },
        {
            isVisible: fieldConfigs.marketingSourceConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.SOURCE'),
            oData: getStringValue(originalDetails, 'marketingSource.name'),
            nData: getStringValue(newDetails, 'marketingSource.name')
        }
    ];

    return <div className="select-group open">{renderRows(data)}</div>;
};

export const EmergencyContact = ({
    fieldConfigs,
    originalDetails,
    newDetails
}: {
    fieldConfigs: EmergencyContactFieldsModel;
    originalDetails: EmergencyContactModel;
    newDetails: EmergencyContactModel;
}) => {
    const { t } = useTranslation();
    const data = [
        {
            isVisible: fieldConfigs.contactNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_NAME'),
            oData: getStringValue(originalDetails, 'contactName'),
            nData: getStringValue(newDetails, 'contactName')
        },
        {
            isVisible: fieldConfigs.contactNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.EMERGENCY_CONTACT_PHONE_NUMBER'),
            oData: getStringValue(originalDetails, 'contactNumber.phoneNumber'),
            nData: getStringValue(newDetails, 'contactNumber.phoneNumber')
        }
    ];

    return <div className="select-group open">{renderRows(data)}</div>;
};

export const HealthQuestion = ({
    healthQuestions
}: {
    healthQuestions: HealthQuestionModel[];
}) => {
    const { t } = useTranslation();
    return (
        <div className="select-group open">
            {healthQuestions &&
        healthQuestions.map((question, index) => (
            <div className="row pb-2" key={index}>
                <div className="col-4 title">{question.name}</div>
                <div className="col-4">{t('BUTTON.NO')}</div>
                <div className="col-4 title">
                    {t('BUTTON.YES')}
                </div>
            </div>
        ))}
        </div>
    );
};

export const OtherFields = ({
    fieldConfigs,
    originalDetails,
    newDetails
}: {
    fieldConfigs: OtherFieldsFieldsModel;
    originalDetails: OtherFieldsModel;
    newDetails: OtherFieldsModel;
}) => {
    const { t } = useTranslation()
    const data = [
        {
            isVisible: fieldConfigs.companyNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.COMPANY_NAME'),
            oData: getStringValue(originalDetails, 'companyName'),
            nData: getStringValue(newDetails, 'companyName')
        },
        {
            isVisible: fieldConfigs.occupationConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.OCCUPATION'),
            oData: getStringValue(originalDetails, 'occupation'),
            nData: getStringValue(newDetails, 'occupation')
        },
        {
            isVisible: fieldConfigs.referredByMemberNameConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.REFERRED_BY_NAME'),
            oData: getStringValue(originalDetails, 'referredByMemberName'),
            nData: getStringValue(newDetails, 'referredByMemberName')
        },
        {
            isVisible: fieldConfigs.referredByMembershipNumberConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.REFERRED_BY_MEMBERSHIP_NUMBER'),
            oData: getStringValue(originalDetails, 'referredByMembershipNumber'),
            nData: getStringValue(newDetails, 'referredByMembershipNumber')
        },
        {
            isVisible: fieldConfigs.otherCommissionNoteConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.OTHER_COMMISSION_NOTE'),
            oData: getStringValue(originalDetails, 'otherCommissionNote'),
            nData: getStringValue(newDetails, 'otherCommissionNote')
        },
        {
            isVisible: fieldConfigs.generalNoteConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.GENERAL_NOTE'),
            oData: getStringValue(originalDetails, 'generalNote'),
            nData: getStringValue(newDetails, 'generalNote')
        },
        {
            isVisible: fieldConfigs.aiaVitalityCategoryConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.AIA_VITALITY_CATEGORY'),
            oData: getStringValue(originalDetails, 'aiaVitalityCategory.name'),
            nData: getStringValue(newDetails, 'aiaVitalityCategory.name')
        },
        {
            isVisible: fieldConfigs.recurringCardTypeConfig.isVisible,
            fieldLabel: t('PAGE.MEMBERSHIPS.VIEW.CHANGE_PERSONAL_DETAILS.RECURRING_CARD_TYPE'),
            oData: getStringValue(originalDetails, 'recurringCardType.name'),
            nData: getStringValue(newDetails, 'recurringCardType.name')
        }
    ];

    return <div className="select-group open">{renderRows(data)}</div>;
};
