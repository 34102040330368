import React from 'react';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';
import * as DefaultConstants from 'constants/DefaultConstants';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import { t } from 'util/I18nMessages';
import uuidv4 from 'uuid/v4';
import OptionModel from 'models/Option';
import LookUpModel, { LookUpValue } from 'models/LookUp';
import { transformOptions, getTenantId, getSubTenantId } from 'util/ControlUtils';
import * as Route from 'pages/RouteLoader';
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import ThisInterface from './interface';

const formatText = (
    key: string,
    value: string,
    ignoreValidate: boolean = false
) => {
    if (ignoreValidate) return value;

    if (key === 'reasonCode' || key === 'code') {
        return value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
    }
    return value.replace(/[^a-zA-Z0-9- ]/g, '');
};

const convertDataToLookUpUX = (lookUpValueList: LookUpValue[]) => {
    if (_.isEmpty(lookUpValueList)) {
        return [];
    }
    return lookUpValueList.map(item => {
        return {
            ...LookUpModel.clone(item),
            code: {
                value: item.code.value,
                errorMsg: item.code.value
                    ? ''
                    : t(
                        'COMMON_KEY_FIELD_REQUIRED',
                        { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.REASON_CODE') }
                    )
            },
            sortIndex: {
                value: item.sortIndex.value,
                errorMsg:
                    item.sortIndex.value && item.sortIndex.value >= 0
                        ? ''
                        : t(
                            'COMMON_KEY_FIELD_REQUIRED',
                            { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.SORT_INDEX') }
                        )
            },
            name: {
                value: item.name.value,
                errorMsg: item.name.value
                    ? ''
                    : t(
                        'COMMON_KEY_FIELD_REQUIRED',
                        { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.NAME') }
                    )
            }
        };
    });
};

const lookUpValues = (lookUpValueList: LookUpValue[]) => {
    if (!_.isEmpty(lookUpValueList)) {
        const valueSortIndex = convertDataToLookUpUX(lookUpValueList).map(
            item => !_.isNaN(item.sortIndex.value) && item.sortIndex.value
        );
        const duplicateSortIndex = valueSortIndex.filter(
            (item, index) => item && valueSortIndex.indexOf(item) !== index
        );

        return !_.isEmpty(duplicateSortIndex)
            ? convertDataToLookUpUX(lookUpValueList).map(item => {
                if (duplicateSortIndex.includes(item.sortIndex.value)) {
                    return {
                        ...item,
                        sortIndex: {
                            ...item.sortIndex,
                            errorMsg: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.DUPLICATE_SORT_INDEX')
                        }
                    };
                }
                return item;
            })
            : convertDataToLookUpUX(lookUpValueList);
    }
    return [];
};

const renderLookUpValues = valueList => {
    return !_.isEmpty(valueList)
        ? valueList.map(item => ({
            code: {
                value: item.code,
                errorMsg: ''
            },
            sortIndex: {
                value: item.sortIndex,
                errorMsg: ''
            },
            name: {
                value: item.name,
                errorMsg: ''
            },
            status: item.status,
            id: uuidv4()
        }))
        : [];
};

const convertDataToSave = (lookUpValueList: LookUpValue[]) => {
    if (_.isEmpty(lookUpValueList)) {
        return [];
    }
    return lookUpValueList.map(item => {
        return {
            code: item.code.value,
            sortIndex: item.sortIndex.value,
            name: item.name.value,
            status: item.status
        };
    });
};

class AddUpdateLookup extends React.Component<
ThisInterface['props'] & WithTranslation & RouteComponentProps,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & WithTranslation & RouteComponentProps) {
        super(props);
        const innerHeight = window.innerHeight - 400;
        this.state = {
            isLoading: false,
            disabled: true,
            subTenants: [],
            reasonType: '',
            reasonCode: '',
            selectedSubtenant: null,
            selectedStatus: null,
            reasonTypeDirty: false,
            reasonCodeDirty: false,
            selectedSubtenantDirty: false,
            selectedStatusDirty: false,
            lookUpValueList: [],
            isEditMode: props.match.params['id'] ? true : false,
            listHeight: innerHeight
        };
    }

    componentDidMount() {
        this.initSubTenant();
        this.state.isEditMode && this.getLookUpDetail();
        window.onresize = this.onResize;
    }

    onResize = () => {
        const innerHeight = window.innerHeight - 400;
        this.setState({
            listHeight: innerHeight
        });
    };

    initSubTenant = () => {
        const { conmanService, location: { state } } = this.props;
        const { isEditMode } = this.state;
        const rightName = isEditMode ? Route.EditLookUpRoute.rightName : Route.AddLookUpRoute.rightName;
        conmanService
            .getSubTenantLookUp(rightName, state ? state['subTenantId'] : getSubTenantId())
            .then(res => {
                if (res && res.data && !_.isEmpty(res.data)) {
                    this.setState({
                        subTenants: transformOptions(res.data)
                    });
                } else {
                    this.setState({
                        subTenants: []
                    });
                }
            })
            .catch(() => {
                this.setState({
                    subTenants: []
                });
            });
    };

    initState = () => {
        this.setState({
            isLoading: false,
            reasonType: '',
            reasonCode: '',
            selectedStatus: null,
            lookUpValueList: []
        });
    };

    getLookUpDetail = () => {
        const {
            conmanService,
            match: { params },
            location: { state }
        } = this.props;
        this.setState(
            {
                isLoading: true
            },
            () => {
                conmanService
                    .getLookUpDetail(params['id'], state ? state['subTenantId'] : '', Route.EditLookUpRoute.rightName)
                    .then(res => {
                        if (res) {
                            this.setState({
                                isLoading: false,
                                reasonType: res.reasonType,
                                reasonCode: res.reasonCode,
                                selectedSubtenant: {
                                    label: res.subTenant.name,
                                    value: res.subTenant.id
                                },
                                selectedStatus: res.status,
                                lookUpValueList: renderLookUpValues(res.values)
                            });
                        } else {
                            this.initState();
                        }
                    })
                    .catch(() => {
                        this.initState();
                    });
            }
        );
    };

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const { value } = e.target;
        this.setState(prevState => {
            return {
                ...prevState,
                [key]: formatText(key, value),
                [`${key}Dirty`]: value ? false : true,
                disabled: false
            };
        });
    };

    handleChangeSelect = (value: OptionModel, key: string) => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(prevState => {
            return {
                ...prevState,
                [key]: key === 'selectedStatus' ? value.value : value,
                [`${key}Dirty`]: false,
                disabled: false
            };
        });
    };

    setValue = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: string,
        ignoreValidate = false
    ) => {
        switch (key) {
            case 'status':
                return event['value'];
            case 'sortIndex':
                const value = parseInt(event.target.value, 10);
                return {
                    value: value > 999 ? 999 : value,
                    errorMsg: ''
                };
            default:
                return {
                    value: formatText(key, event.target.value, ignoreValidate),
                    errorMsg: ''
                };
        }
    };

    handleChangeLookUpValue = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: string,
        key: string,
        ignoreValidate = false
    ) => {
        observer.publish('flagUnsavedChangeEvent', true);
        const lookUpValueList: LookUpValue[] = this.state.lookUpValueList.map(
            item => LookUpModel.clone(item)
        );
        this.setState({
            lookUpValueList: lookUpValueList.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [key]: this.setValue(e, key, ignoreValidate)
                    };
                }
                return item;
            }),
            disabled: false
        });
    };

    handleAddRow = () => {
        observer.publish('flagUnsavedChangeEvent', true);
        const lookUpValueList = this.state.lookUpValueList.map(item =>
            LookUpModel.clone(item)
        );
        const lookUpValue = new LookUpValue();
        this.setState({
            lookUpValueList: lookUpValueList.concat({
                ...lookUpValue,
                id: uuidv4(),
                sortIndex: {
                    errorMsg: '',
                    value: lookUpValueList.length + 1
                },
                isNew: true
            }),
            disabled: false
        });
    };

    saveLookUp = () => {
        const {
            conmanService,
            match: { params },
            history,
            t
        } = this.props;
        const {
            isEditMode,
            reasonType,
            reasonCode,
            selectedSubtenant,
            selectedStatus,
            lookUpValueList
        } = this.state;
        const valueList = lookUpValues(lookUpValueList);
        const valueCode = convertDataToLookUpUX(lookUpValueList).map(
            item => item.code.value !== '' && item.code.value
        );
        const duplicateValues = valueCode.filter(
            (item, index) => item && valueCode.indexOf(item) !== index
        );

        this.setState({
            reasonTypeDirty: !reasonType,
            reasonCodeDirty: !reasonCode,
            selectedSubtenantDirty: !selectedSubtenant,
            selectedStatusDirty: selectedStatus === null,
            lookUpValueList: valueList
        });
        const isCodeError = valueList.some(item => item.code.errorMsg);
        const isSortIndexError = valueList.some(item => item.sortIndex.errorMsg);
        const isNameError = valueList.some(item => item.name.errorMsg);
        const valueListError = isCodeError || isSortIndexError || isNameError;
        if (!_.isEmpty(duplicateValues)) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                type: 'warning',
                html: t(
                    'PAGE.SYSTEM.LOOKUPS.ADD_EDIT.DUPLICATE_REASON_CODE',
                    { key: duplicateValues.join(', ') }
                ),
                confirmButtonText: t('BUTTON.CLOSE')
            });
        }
        if (
            reasonType &&
            reasonCode &&
            selectedSubtenant &&
            selectedStatus !== null &&
            !valueListError &&
            _.isEmpty(duplicateValues)
        ) {
            const data = {
                reasonType,
                reasonCode,
                status: selectedStatus,
                subTenantId: selectedSubtenant.value,
                tenantId: getTenantId(),
                values: convertDataToSave(valueList)
            };
            observer.publish('flagUnsavedChangeEvent', false);
            Swal.fire({
                html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                showConfirmButton: false
            });
            const rightName = isEditMode ? Route.EditLookUpRoute.rightName : Route.AddLookUpRoute.rightName;
            Promise.all([
                isEditMode
                    ? conmanService.updatelookUp(data, params['id'], rightName)
                    : conmanService.createLookUp(data, rightName)
            ])
                .then(res => {
                    if (res) {
                        this.setState(
                            {
                                disabled: true
                            },
                            () => {
                                Swal.fire({
                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                    type: 'success',
                                    html: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.SAVE_SUCCESSFUL'),
                                    confirmButtonText: t('BUTTON.CLOSE')
                                }).then(() => {
                                    history.push('/system/lookups');
                                });
                            }
                        );
                    }
                })
                .catch(err => {
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        text: err.message
                            ? t(err.message)
                            : t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.SAVE_FAIL'),
                        type: 'error',
                        confirmButtonText: t('BUTTON.CLOSE')
                    });
                });
        }
    };

    handleSaveLookUp = () => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            text: t('MSG.CONFIRM_SAVE'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: t('BUTTON.YES'),
            cancelButtonText: t('BUTTON.NO')
        }).then(res => {
            if (res.value) {
                this.saveLookUp();
            }
        });
    };

    handleCancelLookUp = () => {
        this.props.history.push('/system/lookups');
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(AddUpdateLookup);
