import React from 'react';
import FormItem, { getIdOrUndefined } from 'components/FormV2';
import Loading from 'components/Loading';
import { AddEditTenderClassInterface } from 'pages/SystemPage/Tender/TenderClass/interface'

const template = (_this: AddEditTenderClassInterface) => {
    const {
        tenderClassDetail,
        fields,
        isEditMode,
        isViewMode,
        subTenants,
        isLoading } = _this.state;
	
    const { t } = _this.props;

    const tenderClassColumns = [
        {
            props: {
                type: 'text',
                htmlFor: 'tenderClassDetail[name]',
                fieldName: 'tenderClassDetail[name]',
                data: tenderClassDetail.name,
                attribute: {
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_CLASS.COLUMN.NAME')
            }
        },
        {
            props: {
                type: 'text',
                htmlFor: 'tenderClassDetail[code]',
                fieldName: 'tenderClassDetail[code]',
                data: tenderClassDetail.code,
                attribute: {
                    disabled: isEditMode || isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_CLASS.COLUMN.TENDER_CLASS_CODE')
            }
        },
        {
            props: {
                type: 'select',
                htmlFor: 'tenderClassDetail[subTenant]',
                fieldName: 'tenderClassDetail[subTenant]',
                data: getIdOrUndefined(tenderClassDetail.subTenant),
                attribute: {
                    disabled: isEditMode || isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_CLASS.COLUMN.SUB_TENANT'),
                options: subTenants
            }
        }
    ];

    const checkBoxColumns = [
        {
            props: {
                type: 'checkbox',
                htmlFor: 'tenderClassDetail[isAllowInMakePaymentWizard]',
                fieldName: 'tenderClassDetail[isAllowInMakePaymentWizard]',
                data: tenderClassDetail.isAllowInMakePaymentWizard ? 1 : 0,
                attribute: {
                    checked: tenderClassDetail.isAllowInMakePaymentWizard,
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_CLASS.ADD_EDIT_VIEW.ALLOW_IN_MAKE_PAYMENT_WIZARD'),
            },
            className: "form-check"
        },
        {
            props: {
                type: 'checkbox',
                htmlFor: 'tenderClassDetail[isAllowInRefundWizard]',
                fieldName: 'tenderClassDetail[isAllowInRefundWizard]',
                data: tenderClassDetail.isAllowInRefundWizard ? 1 : 0,
                attribute: {
                    checked: tenderClassDetail.isAllowInRefundWizard,
                    disabled: isViewMode
                },
                fieldLabel: t('PAGE.SYSTEM.TENDER_CLASS.ADD_EDIT_VIEW.ALLOW_IN_REFUND_WIZARD'),
            },
            className: "form-check"
        }
    ]
    return (
        <>
                <section>
                    <div className="add-edit-tender-class">
                        <div className="title-content mb-3">
                            {!isViewMode && !isEditMode ? t('PAGE.SYSTEM.TENDER_CLASS.ADD_EDIT_VIEW.TITLE_ADD') : null}
                            {isEditMode ? t('PAGE.SYSTEM.TENDER_CLASS.ADD_EDIT_VIEW.TITLE_EDIT') : null}
                            {isViewMode ? t('PAGE.SYSTEM.TENDER_CLASS.ADD_EDIT_VIEW.TITLE_VIEW') : null}
                        </div>
                        <div className="member">
                            <div className="member-main member-main--custom">
                                {isLoading ? <Loading /> :
                                    <div className="content">
                                        <div className="row">
                                            {tenderClassColumns.map((field, key) =>
                                                <div key={key} className="col-md-4 mt-3">
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            {checkBoxColumns.map((field, key) =>
                                                <div key={key} className="col-md-12 mt-3">
                                                    <FormItem
                                                        {...field.props}
                                                        onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                                        fields={fields}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="group-btn">
                                            {!isViewMode && <button disabled={_this.state.disabled} className="btn btn-primary" onClick={() => _this.onSubmit()}>{t('BUTTON.SAVE')} </button>}
                                            <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.CANCEL')} </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
};

export default template;
