import React from 'react';
import OneButton from '../buttons/1-button';
import TwoButton from '../buttons/2-buttons';
import TreeButton from '../buttons/3-buttons';
import FourButton from '../buttons/4-buttons';

const DemoButton = () => {
    return (
        <div className="table-list">
            <div>
                <div>1 button</div>
                <OneButton />
            </div>
            <div>
                <div>2 buttons</div>
                <TwoButton />
            </div>
            <div>
                <div>3 buttons</div>
                <TreeButton />
            </div>
            <div>
                <div>4 buttons</div>
                <FourButton />
            </div>
        </div>
    )
}

export default DemoButton;