import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import { submitModal, errorModal } from 'components/CommonModal';
import { DisplayDateFormat, transformOptions, getSubTenantId } from 'util/ControlUtils';
import observer from 'util/Observer';
import {
    GENERAL_DATABASE_DATE_FORMAT,
    API_GET_UPDATE_FEE_DETAIL,
    API_GET_SELLING_PRICES_SUBTENANTS,
    API_GET_SELLING_PRICES_CLUBGROUPS,
    API_GET_SELLING_PRICES_PRIORITIS,
    API_SAVE_SELLING_PRICES,
    API_ACTION_ON_SELLING_PRICE_ITEM
} from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import * as DefaultConstants from 'constants/DefaultConstants';
import CommonService from 'services/common.service';
import FieldModel from 'models/Field';

import template from './template';
import ThisInterface, { Field } from './interface';

class SellingPricePage extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    commonService: CommonService;
    constructor(props: ThisInterface['props']) {
        super(props);
        const {
            match: { params }
        } = props;
        const initDate = `${DisplayDateFormat(
            new Date(),
            GENERAL_DATABASE_DATE_FORMAT
        )}T00:00:00Z`;
        this.state = {
            feeCode: 0,
            feeName: '',
            field: {
                subTenant: '',
                clubGroup: '',
                priceAmount: 0,
                priority: '',
                priceEffectiveDate: initDate,
                priceExpireDate: initDate
            },
            formField: {
                priceExpireDate: {
                    ...new FieldModel(),
                    key: 'priceExpireDate'
                }
            },
            subTenants: [{ value: 'all', label: 'All' }],
            clubGroups: [],
            priorities: [],
            priceList: [],
            currentPage: 1,
            isLoading: true,
            isEditMode: !!params['sellingPriceId']
        };
        this.commonService = new CommonService();
    }

    componentDidMount() {
        const { location: { state }, history } = this.props;
        const { isEditMode } = this.state;
        if (isEditMode) {
            if (!state) {
                history.push('/');
            }
        }
        this.getInitData();
    }
    getInitData = () => {
        const {
            location: { state },
            match: { params }
        } = this.props;
        const { isEditMode } = this.state;
        const rightName = isEditMode
            ? RouteLoader.EditSellingPrice.rightName
            : RouteLoader.AddSellingRoute.rightName;
        const subTenantId =
			isEditMode && state && state.subTenantId
			    ? state.subTenantId
			    : getSubTenantId();

        const headers = {
            rightName,
            subTenantId
        };
        Promise.all([
            this.commonService.fetchData(API_GET_UPDATE_FEE_DETAIL.replace('{1}', params['id']), {
                rightName: RouteLoader.ViewFeeRoute.rightName,
                subTenantId: getSubTenantId()
            }),
            this.commonService.fetchData(API_GET_SELLING_PRICES_SUBTENANTS, headers),
            this.commonService.fetchData(API_GET_SELLING_PRICES_PRIORITIS, headers),
            isEditMode &&
        this.commonService.fetchData(
            API_ACTION_ON_SELLING_PRICE_ITEM.replace(
                '{id}',
                params['id']
            ).replace('{sellingpriceid}', params['sellingPriceId']),
            headers
        )
        ])
            .then(([resFeeDetail, resSubTenant, resPriority, resSellingDetail]) => {
                if (resFeeDetail && !_.isEmpty(resSubTenant.data) && !_.isEmpty(resPriority.data)) {
                    this.setState(
                        prevState => {
                            return {
                                feeCode: resFeeDetail.code,
                                feeName: resFeeDetail.name,
                                subTenants: prevState.subTenants.concat(
                                    transformOptions(resSubTenant.data)
                                ),
                                priorities: resPriority.data.map(item => ({
                                    value: parseInt(item.code, 10),
                                    label: item.name
                                }))
                            };
                        },
                        () => {
                            this.setStateCallBack(resSellingDetail);
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    setStateCallBack = resSellingDetail => {
        if (resSellingDetail) {
            this.setState(
                {
                    field: {
                        subTenant: resSellingDetail.subTenantId,
                        priority: resSellingDetail.priority,
                        clubGroup: resSellingDetail.clubGroupId,
                        priceAmount: resSellingDetail.price,
                        priceEffectiveDate: resSellingDetail.effectiveDate,
                        priceExpireDate: resSellingDetail.expirationDate
                    },
                    isLoading: false
                },
                () => this.getClubGroupsBySubTentant()
            );
        } else {
            this.setState(
                prevState => {
                    return {
                        field: {
                            ...prevState.field,
                            subTenant: prevState.subTenants[0].value,
                            priority: prevState.priorities[0].value
                        },
                        isLoading: false
                    };
                },
                () => this.getClubGroupsBySubTentant()
            );
        }
    };

    getClubGroupsBySubTentant = () => {
        const {
            field: { subTenant },
            isEditMode
        } = this.state;
        const rightName = isEditMode
            ? RouteLoader.EditSellingPrice.rightName
            : RouteLoader.AddSellingRoute.rightName;
        this.commonService
            .fetchData(API_GET_SELLING_PRICES_CLUBGROUPS, {
                rightName,
                subTenantId: subTenant
            })
            .then(res => {
                if (!_.isEmpty(res.data)) {
                    this.setState(
                        {
                            clubGroups: transformOptions(res.data)
                        },
                        () => {
                            if (!isEditMode) {
                                this.setState(prevState => {
                                    return {
                                        field: {
                                            ...prevState.field,
                                            clubGroup: prevState.clubGroups[0].value
                                        }
                                    };
                                });
                            }
                        }
                    );
                }
            });
    };

    validateEffectiveDateExpiredDate = (key: string, errorMsg: string) => {
        this.setState(prevState => {
            return {
                formField: {
                    ...prevState.formField,
                    [key]: {
                        ...prevState.formField[key],
                        isDirty: errorMsg !== '' ? true : false,
                        customMsg: errorMsg
                    }
                }
            };
        });
    };

    showDuplicateError = (msg: string) => {
        errorModal(msg);
    };

    onChangeHandle = (name: string, value) => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(
            preState => {
                switch (name) {
                    case 'priceAmount':
                        return {
                            field: {
                                ...preState.field,
                                priceAmount: value ? value : 0
                            }
                        };
                    case 'priceEffectiveDate':
                    case 'priceExpireDate':
                        return {
                            field: {
                                ...preState.field,
                                [name]: `${DisplayDateFormat(
                                    value,
                                    GENERAL_DATABASE_DATE_FORMAT
                                )}T00:00:00Z`
                            }
                        };
                    default:
                        return {
                            field: {
                                ...preState.field,
                                [name]: value
                            }
                        };
                }
            },
            () => {
                const {
                    field: { priceEffectiveDate, priceExpireDate }
                } = this.state;
                const currentTime = new Date().getTime();
                const effectiveTime = priceEffectiveDate
                    ? new Date(priceEffectiveDate).getTime()
                    : currentTime;
                const expiredTime = priceExpireDate
                    ? new Date(priceExpireDate).getTime()
                    : currentTime;
                switch (name) {
                    case 'priceEffectiveDate':
                        if (effectiveTime >= expiredTime) {
                            this.setState(prevState => {
                                return {
                                    field: {
                                        ...prevState.field,
                                        priceExpireDate: priceEffectiveDate
                                    }
                                };
                            });
                            this.validateEffectiveDateExpiredDate('priceExpireDate', '');
                        }
                        break;
                    case 'priceExpireDate':
                        this.validateEffectiveDateExpiredDate(
                            'priceExpireDate',
                            effectiveTime <= expiredTime
                                ? ''
                                : 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EXPIRATION_DATE_ERROR'
                        );
                        break;
                    case 'subTenant':
                        this.getClubGroupsBySubTentant();
                    default:
                        break;
                }
            }
        );
    };

    equalCondition = (priceItem: Field) => {
        const {
            field: { priceEffectiveDate, priceExpireDate }
        } = this.state;
        return (
            priceItem.priceEffectiveDate === priceEffectiveDate ||
			priceItem.priceExpireDate === priceExpireDate ||
			(priceItem.priceEffectiveDate === priceExpireDate ||
			priceItem.priceExpireDate === priceEffectiveDate)
        );
    };

    greaterLessThanCondition = (priceItem: Field) => {
        const {
            field: { priceEffectiveDate, priceExpireDate }
        } = this.state;
        const effectiveTime = new Date(priceEffectiveDate).getTime();
        const expiredTime = new Date(priceExpireDate).getTime();
        const listEffectiveTime = new Date(priceItem.priceEffectiveDate).getTime();
        const listExpiredTime = new Date(priceItem.priceExpireDate).getTime();
        return (
            (listEffectiveTime < effectiveTime && listExpiredTime > effectiveTime) ||
			(listEffectiveTime < expiredTime && listExpiredTime > expiredTime) ||
			(listEffectiveTime > effectiveTime && listEffectiveTime < expiredTime)
        );
    };

    hasEqualAndGreaterLessThanCondition = (priceItem: Field) => {
        const {
            field: { priceEffectiveDate, priceExpireDate }
        } = this.state;
        const effectiveTime = new Date(priceEffectiveDate).getTime();
        const expiredTime = new Date(priceExpireDate).getTime();
        const listEffectiveTime = new Date(priceItem.priceEffectiveDate).getTime();
        const listExpiredTime = new Date(priceItem.priceExpireDate).getTime();

        return (
            (priceItem.priceEffectiveDate === priceEffectiveDate &&
				(effectiveTime > listEffectiveTime || effectiveTime < listEffectiveTime)) ||
			(priceItem.priceExpireDate === priceExpireDate &&
				(expiredTime > listExpiredTime || expiredTime < listExpiredTime)) ||
			(priceItem.priceExpireDate === priceEffectiveDate &&
				(expiredTime > listEffectiveTime || expiredTime < listEffectiveTime)) ||
			(priceItem.priceEffectiveDate === priceExpireDate &&
				(effectiveTime > listExpiredTime || effectiveTime < listExpiredTime))
        );
    };

    handleChangePage = (currentPage: number) => {
        this.setState({
            currentPage
        });
    };

    handleAddPrice = () => {
        const {
            field: {
                subTenant,
                clubGroup,
                priority,
                priceAmount,
                priceEffectiveDate,
                priceExpireDate
            },
            subTenants,
            clubGroups,
            priceList
        } = this.state;
        const {
            match: { params }
        } = this.props;
        observer.publish('flagUnsavedChangeEvent', true);

        let list: Field[] = [];
        let errorMsg = '';
        const effectiveTime = new Date(priceEffectiveDate).getTime();
        const expiredTime = new Date(priceExpireDate).getTime();

        const filteredSubTenants = subTenants
            .map(item => item)
            .filter(item => item.value !== 'all');

        if (effectiveTime > expiredTime) {
            this.validateEffectiveDateExpiredDate(
                'priceExpireDate',
                'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EXPIRATION_DATE_ERROR'
            );
            return;
        }

        if (subTenant === 'all') {
            if (_.isEmpty(priceList)) {
                this.setState({
                    priceList: priceList.concat(
                        filteredSubTenants.map(item => {
                            return {
                                subTenant: subTenants.find(el => el.value === item.value)!,
                                clubGroup: clubGroups.find(el => el.value === clubGroup)!,
                                priority,
                                priceAmount,
                                priceEffectiveDate,
                                priceExpireDate,
                                feeId: params['id']
                            };
                        })
                    )
                });
            } else {
                list = priceList.map(item => item);
                let allowToAdd = false;
                list.forEach(item => {
                    if (
                        filteredSubTenants.some(
                            sub => item.subTenant.value === sub.value
                        ) &&
						item.priority === priority &&
						item.clubGroup.value === clubGroup &&
						item.priceAmount === priceAmount &&
						(
						    this.greaterLessThanCondition(item) ||
							this.hasEqualAndGreaterLessThanCondition(item) ||
							this.equalCondition(item)
						)
                    ) {
                        allowToAdd = false;
                        errorMsg = 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EXIST_PRICE';
                    } else {
                        allowToAdd = true;
                    }
                });
                if (allowToAdd && !errorMsg) {
                    list = priceList.map(item => item);
                    filteredSubTenants.forEach(sTenant => {
                        list.unshift({
                            ...this.state.field,
                            subTenant: sTenant,
                            clubGroup: clubGroups.find(club => clubGroup === club.value)!,
                            feeId: params['id']
                        });
                    });
                    if (!_.isEmpty(list)) {
                        this.setState({
                            priceList: _.uniqWith(list, _.isEqual)
                        });
                    }
                } else {
                    this.showDuplicateError(errorMsg);
                }
            }
        } else {
            list = [];
            if (
                priceList.some(
                    item =>
                        item.subTenant.value === subTenant &&
						item.priority === priority &&
						item.clubGroup.value === clubGroup &&
						item.priceAmount === priceAmount &&
						this.equalCondition(item)
                )
            ) {
                errorMsg = 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EXIST_PRICE';
            }
            priceList.forEach(item => {
                if (
                    item.subTenant.value === subTenant &&
					item.priority === priority &&
					item.clubGroup.value === clubGroup &&
					item.priceAmount === priceAmount &&
					this.greaterLessThanCondition(item)
                ) {
                    errorMsg = 'PAGE.PRODUCTS.ITEMS.SELLING_PRICE.EXIST_PRICE';
                } else {
                    list = [
                        {
                            subTenant: subTenants.find(el => el.value === subTenant)!,
                            clubGroup: clubGroups.find(el => el.value === clubGroup)!,
                            priority,
                            priceAmount,
                            priceEffectiveDate,
                            priceExpireDate,
                            feeId: params['id']
                        }
                    ];
                }
            });

            if (errorMsg) {
                this.showDuplicateError(errorMsg);
                return;
            }
            this.setState({
                priceList: _.isEmpty(priceList)
                    ? [
                        {
                            subTenant: subTenants.find(el => el.value === subTenant)!,
                            clubGroup: clubGroups.find(el => el.value === clubGroup)!,
                            priority,
                            priceAmount,
                            priceEffectiveDate,
                            priceExpireDate,
                            feeId: params['id']
                        }
                    ].concat(priceList)
                    : list.concat(priceList)
            });
        }
    };

    handleDeletePrice = (index: number) => {
        const { priceList } = this.state;
        const list = priceList.map(item => item);
        list.splice(index, 1);
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState({
            priceList: list
        });
    };

    handleSave = () => {
        const headers = {
            rightName: RouteLoader.AddSellingRoute.rightName,
            subTenantId: getSubTenantId()
        };
        const {
            t,
            history,
            match: { params }
        } = this.props;
        const { priceList, isEditMode, field } = this.state;

        const data = priceList.map(item => {
            return {
                subtenantId: item.subTenant.value,
                clubGroupId: item.clubGroup.value,
                feeId: item.feeId,
                priority: parseInt(item.priority),
                price: item.priceAmount,
                effectiveDate: item.priceEffectiveDate,
                expirationDate: item.priceExpireDate
            };
        });
        const editData = {
            id: params['sellingPriceId'],
            subtenantId: field.subTenant,
            clubGroupId: field.clubGroup,
            feeId: params['id'],
            priority: field.priority,
            price: field.priceAmount,
            effectiveDate: field.priceEffectiveDate,
            expirationDate: field.priceExpireDate
        };
        const rightName = isEditMode
            ? RouteLoader.EditSellingPrice.rightName
            : RouteLoader.AddSellingRoute.rightName;

        submitModal('PAGE.PRODUCTS.ITEMS.SELLING_PRICE.CONFIRM_SAVE', () => {
            Promise.all([
                isEditMode
                    ? this.commonService.putData(
                        API_ACTION_ON_SELLING_PRICE_ITEM.replace(
                            '{id}',
                            params['id']
                        ).replace('{sellingpriceid}', params['sellingPriceId']),
                        editData,
                        {
                            rightName,
                            subTenantId: editData.subtenantId
                        }
                    )
                    : this.commonService.postData(
                        API_SAVE_SELLING_PRICES.replace('{id}', params['id']),
                        data,
                        headers
                    )
            ]).then(([res]) => {
                if (res) {
                    if (res) {
                        observer.publish('flagUnsavedChangeEvent', false);
                        Swal.fire({
                            ...DefaultConstants.SWAL_COMMON_STYLE,
                            type: 'success',
                            text: t('PAGE.PRODUCTS.ITEMS.SELLING_PRICE.SAVE_SUCCESS'),
                            showCancelButton: false,
                            confirmButtonText: t('BUTTON.CLOSE')
                        }).then(() => {
                            history.push(`/products/fee/${params['id']}/view`);
                        });
                    }
                }
            });
        });
    };

    handleCancel = () => {
        const {
            history,
            match: { params }
        } = this.props;
        history.push(`/products/fee/${params['id']}/view`);
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(withRouter(SellingPricePage));
