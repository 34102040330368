import React from 'react';
import UserAvatar from 'assets/images/user_avatar.png';
import moment from 'moment';
import * as Utils from 'util/ControlUtils';

import ThisInterface from '../interface';

const renderPaymentStatusClass = (status: string) => {
    switch (status) {
        case 'OK':
            return 'positive';
        case 'AR': 
            return 'negative';
        case 'AP':
            return 'neutral';
        default:
            return '';
    }
}

const renderMemberStatusClass = (status: string) => {
    switch (status) {
        case 'OK':
            return 'positive';
        case 'JN': 
            return 'neutral';
        case 'SS':
            return 'neutral';
        case 'CA':
            return 'negative';
        case 'DC':
            return 'negative';
        case 'EX':
            return 'negative';
        case 'FZ':
            return 'negative';
        case 'GR':
            return 'negative';
        default:
            return '';
    }
}

const generalInfo = (_this: ThisInterface) => {
    const { memberData, dateFormat } = _this.state;
    const { t } = _this.props;

    let joinedDate = memberData.joinedDate
        ? moment(new Date(memberData.joinedDate))
        : moment(new Date());
    let currentDate = moment(new Date());

    let years = currentDate.diff(joinedDate, 'years');
    let months = currentDate.diff(joinedDate, 'months');
    let days = currentDate.diff(joinedDate, 'days');
    const remainMonths = months - years * 12;
	
    let nationalId = memberData.nationalId
		&& memberData.nationalId.length > 4
        ? Utils.hiddenString(memberData.nationalId, 4)
        : memberData.nationalId;

    let otherNationalId = memberData.otherNationalId
		&& memberData.otherNationalId.length > 4
        ? Utils.hiddenString(memberData.otherNationalId, 4)
        : memberData.otherNationalId;
	

    return (
        <div className="row">
            <div className="info-acc col-md-8">
                <div className="box-avatar">
                    <div className="edit-avartar">
                        <img className="img-acc" src={UserAvatar} alt="user-avatar" />
                    </div>
                </div>
                <div className="info-detail">
                    <p>
                        <b className="large-size">
                            {memberData.title && `${memberData.title.name} `}
                            {memberData.firstName && `${memberData.firstName} `}
                            {memberData.lastName && `${memberData.lastName} `}
                            {memberData.applicationRef
                                ? `(${memberData.applicationRef})`
                                : ''}
                        </b>
                    </p>
                    <p><b>{t('PAGE.MEMBERSHIPS.VIEW.DOB')}</b>{memberData.dateOfBirth &&
              				moment(memberData.dateOfBirth).format('DD-MMM')}</p>
                    <p>
                        <b>{t!('PAGE.MEMBERSHIPS.VIEW.MEMBER_SINCE')}:&nbsp;</b>
                        {Utils.DisplayDateFormat(memberData.joinedDate, dateFormat)} &nbsp;
                        <span>
                            {years > 0 && remainMonths > 0 ? (
                                <span>
                                    {'( '}
                                    <span dangerouslySetInnerHTML={{__html: t(years > 1 ? 'PAGE.MEMBERSHIPS.VIEW.VALUE_YEARS' : 'PAGE.MEMBERSHIPS.VIEW.VALUE_YEAR', { value: `<span class="color-text">${years}</span>` })}}></span>
                                    {', '}
                                    <span dangerouslySetInnerHTML={{__html: t(remainMonths > 1 ? 'PAGE.MEMBERSHIPS.VIEW.VALUE_MONTHS' : 'PAGE.MEMBERSHIPS.VIEW.VALUE_MONTH', { value: `<span class="color-text">${remainMonths}</span>` })}}></span>
                                    {' )'}

                                </span>
                            ) : (
                                <span>
                                    {'('}
                                    <span dangerouslySetInnerHTML={{__html: t(days > 1 ? 'PAGE.MEMBERSHIPS.VIEW.VALUE_DAYS' : 'PAGE.MEMBERSHIPS.VIEW.VALUE_DAY', { value: `<span class="color-text">${days}</span>` })}}></span>
                                    {')'}
                                </span>
                            )}
                        </span>
                    </p>
                    <p>
                        <b>{t!('PAGE.MEMBERSHIPS.VIEW.ID_NUMBER')}: &nbsp;</b>
                        {nationalId
                            ? nationalId
                            : otherNationalId
                                ? otherNationalId
                                : t!('COMMON.NA')}
                    </p>
                </div>
            </div>
            <div className="general-content col-md-4">
                <p>
                    <b className="large-size">{memberData.club && memberData.club.name}</b>
                </p>
                <p className={`membership-stt ${renderMemberStatusClass(memberData.memberStatus.code)}`}>
                    {`${t!('PAGE.MEMBERSHIPS.VIEW.MEMBERSHIP_STATUS')}: `}
                    <span className={renderMemberStatusClass(memberData.memberStatus.code)}>
                        {memberData.memberStatus ? memberData.memberStatus.name : t!('COMMON.OK')}
                    </span>
                </p>
                <p className={`payment-stt ${renderPaymentStatusClass(memberData.paymentStatus.code)}`}>
                    {`${t!('PAGE.MEMBERSHIPS.VIEW.PAYMENT_STATUS')}: `}
                    <span className={renderPaymentStatusClass(memberData.paymentStatus.code)}> 
                        {memberData.paymentStatus ? memberData.paymentStatus.name : 'Pending'}
                    </span>
                </p>
                <p className="last-swipe-date-stt smile">
                    {`${t!('PAGE.MEMBERSHIPS.VIEW.LAST_SWIPE_DATE')}: `}
                    <span className="smile">08/03/2019</span>
                </p>
            </div>
        </div>
    );
};

export default generalInfo;
