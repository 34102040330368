import React from 'react';
import { HealthQuestionModel } from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';

class QuestionModel {
    id = '';
    name = '';
}

interface Props {    
    errors: any;
    questions: any;
    healthQuestions: HealthQuestionModel[];    
    onCheckboxChange: (key: string, checked: boolean, value: any) => void; 
}

const HealthQuestion: React.FunctionComponent<Props> = ({
    errors,   
    questions,  
    healthQuestions,        
    onCheckboxChange    
}) => {
    
    let lastQuestion = questions[questions.length -1] || new QuestionModel();
    let isLastQuestionChecked = healthQuestions.some(aq => aq.id === lastQuestion.id );
    let questionRows;
    const { t } = useTranslation();
    
    if(questions.length > 1){
        let questionItems = questions.filter(q => q.id !== lastQuestion.id).map((question, index) => {
            let isChecked = healthQuestions.some(aq => aq.id === question.id );
            return (            
                <div key={index} className="col-md-6">
                    <div className="form-group">
                        <div className="form-check">
                            <input  id={`q-${index}`} type="checkbox" 
                                checked={isChecked} 
                                onChange={(event) => onCheckboxChange("answeredQuestion", event.target.checked, question)} />
                            <label htmlFor={`q-${index}`} className="icon-checked-ico"> <span>{t(question.name)}</span> </label>
                        </div>
                    </div>
                </div>
            )
        });
   
        //populate question to row with 2 items/row
        let numOfItem = 2;
        questionRows = questionItems.map((item, index) => {  
            return index % numOfItem !== 0 ? null : <div key={index} className="row">{questionItems.slice(index, index + numOfItem)}</div>;
        }).filter(row => { return row; });
    }    

    return (        
        <>
            {questionRows}
            {lastQuestion.id &&
                <div className="row">
                    <div className="col">
                        <div className="form-group pt-3 bd-t-gray2">
                            <div className="form-check">
                                <input  id="q-all" type="checkbox" 
                                    checked={isLastQuestionChecked}
                                    onChange={(event) => onCheckboxChange("lastQuestion", event.target.checked, lastQuestion)}/>
                                <label htmlFor="q-all" className="icon-checked-ico"> <span>{t(lastQuestion.name)}</span></label><span className="text-primary bold">&nbsp;*</span>
                            </div>      
                            {errors.lastQuestion && <span className="text-danger input-error">{t(errors.lastQuestion)}</span>}                      
                        </div>
                        
                    </div>
                </div>
            }
        </>
    )
}

export default HealthQuestion;