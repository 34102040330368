import React from 'react';
import Breadcrumb from 'components/Membership/Breadcrumb';
import CollapseButton from 'components/CollapseButton';
import CustomTable from 'components/Table';
import * as Utils from 'util/ControlUtils';
import ProductSummary from 'components/Membership/ProductSummary';
import ThisInterface from './interface';
import EmailSummary from 'pages/MembershipManagement/MemberSummaryPage/EmailSummary';
import MemberArrea from 'models/MemberArrea';
import moment from 'moment';

function formatAmount(amount) {
    let formattedAmount = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
    let decimalSeparator = formattedAmount.charAt(formattedAmount.length - 3);
    return formattedAmount.split(decimalSeparator);
}


const ChangeMemberSummaryInformation = (_this: ThisInterface) => {
    const { minContractEndDateOld, minContractEndDateNew } = _this.state;
    const { t } = _this.props;
    const { 
        personalDetails : { contactDetails }
    } = _this.props.oldMembership;

    const membershipSearchFilterOld = _this.props.oldMembership.membershipSearchFilter;
    const memberSummaryOld = _this.props.oldMembership.memberSummary;

    const membershipSearchFilterNew = _this.props.newMembership.membershipSearchFilter;
    const membershipConfigurationNew = _this.props.newMembership.membershipConfiguration;

    const packagePlanIdNew = _this.props.newMembership.membershipSearchFilter ? _this.props.newMembership.membershipSearchFilter.selectedPackagePlanId : '';
    const packagePlanNew = _this.props.newMembership.packagePlans.find(e => e.id === packagePlanIdNew);

    let dataOldMembership = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CLUB'),
            data: membershipSearchFilterOld.clubName ? membershipSearchFilterOld.clubName : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: memberSummaryOld && memberSummaryOld.package ? memberSummaryOld.package.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_PLAN'),
            data:  memberSummaryOld && memberSummaryOld.plan ? memberSummaryOld.plan.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: memberSummaryOld.startedDate ? Utils.DisplayDateFormat(
                memberSummaryOld.startedDate,
                membershipConfigurationNew.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: memberSummaryOld.startedDate ? Utils.DisplayDateFormat(minContractEndDateOld, membershipConfigurationNew.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_FEE'),
            data: "999.000.00"
        }
    ]

    let dataNewMembership = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CLUB'),
            data: membershipSearchFilterNew.clubName ? membershipSearchFilterNew.clubName : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: packagePlanNew && packagePlanNew.package ? packagePlanNew.package.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_PLAN'),
            data: packagePlanNew ? packagePlanNew.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: membershipSearchFilterNew.startDate ? Utils.DisplayDateFormat(
                membershipSearchFilterNew.startDate,
                membershipConfigurationNew.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: membershipSearchFilterNew.startDate ? Utils.DisplayDateFormat(minContractEndDateNew, membershipConfigurationNew.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_FEE'),
            data: "999.000.00"
        }
    ]

    return (
        <>
            <div className="select-group open">
                <p className="title">{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.OLD_MEMBERSHIP')}</p>
                {dataOldMembership.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
                <p className="title pt-3">{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.NEW_MEMBERSHIP')}</p>
                {dataNewMembership.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
                <div className="section-email">
                    <EmailSummary 
                        memberData={
                            { email: contactDetails && contactDetails.email ? contactDetails.email : null } as any
                        }
                    />
                </div>
            </div>
        </>
    )
}

const NewMemberSummaryInformation = (_this: ThisInterface) => {
    const { minContractEndDateNew } = _this.state;

    const {t} = _this.props;

    const { membershipSearchFilter,
        membershipConfiguration } = _this.props.newMembership;
    
    const packagePlanIdNew = _this.props.newMembership.membershipSearchFilter ? _this.props.newMembership.membershipSearchFilter.selectedPackagePlanId : '';
    const packagePlanNew = _this.props.newMembership.packagePlans.find(e => e.id === packagePlanIdNew);
    
    let data = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: packagePlanNew && packagePlanNew.package ? packagePlanNew.package.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_PLAN'),
            data:  packagePlanNew ? packagePlanNew.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SALES_PERSON'),
            data: membershipSearchFilter.salePersonName ?  membershipSearchFilter.salePersonName : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.JOIN_DATE'),
            data: membershipSearchFilter.startDate ? Utils.DisplayDateFormat(
                membershipSearchFilter.startDate,
                membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: membershipSearchFilter.startDate ? Utils.DisplayDateFormat(
                membershipSearchFilter.startDate,
                membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.END_DATE'),
            data: minContractEndDateNew ? Utils.DisplayDateFormat(minContractEndDateNew, membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: minContractEndDateNew ? Utils.DisplayDateFormat(minContractEndDateNew, membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_ELIGIBILITY_REQUIRED'),
            data: t!('BUTTON.NO')
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ELIGIBILITY_PROVIDED'),
            data: t!('BUTTON.NO')
        }

    ]

    return (
        <>
            <div className="select-group open">
                <p className="title">{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP')}</p>
                {data.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
            </div>
        </>
    )
}

const OldMemberSummaryInformation = (_this: ThisInterface) => {
    const {minContractEndDateOld } = _this.state;
    const {t} = _this.props;
    const { membershipSearchFilter } = _this.props.oldMembership;

    const { 
        membershipConfiguration,
    } = _this.props.newMembership;

    const memberSummaryOld = _this.props.oldMembership.memberSummary;

    let data = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE'),
            data: memberSummaryOld && memberSummaryOld.package ? memberSummaryOld.package.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_PLAN'),
            data:  memberSummaryOld && memberSummaryOld.plan ? memberSummaryOld.plan.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SALES_PERSON'),
            data: membershipSearchFilter.salePersonName ?  membershipSearchFilter.salePersonName : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.JOIN_DATE'),
            data: Utils.DisplayDateFormat(
                memberSummaryOld.joinedDate,
                membershipConfiguration.newMemberTimeFormat
            )
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.START_DATE'),
            data: memberSummaryOld.startedDate ? Utils.DisplayDateFormat(
                memberSummaryOld.startedDate,
                membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.END_DATE'),
            data: memberSummaryOld.endDate ? Utils.DisplayDateFormat(
                memberSummaryOld.endDate,
                membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MINIMUM_CONTRACT_PERIOD_END_DATE'),
            data: minContractEndDateOld ? Utils.DisplayDateFormat(minContractEndDateOld, membershipConfiguration.newMemberTimeFormat
            ) : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_ELIGIBILITY_REQUIRED'),
            data: t!('BUTTON.NO')
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ELIGIBILITY_PROVIDED'),
            data:  t!('BUTTON.NO')
        }

    ]

    return (
        <>
            <div className="select-group open">
                <p className="title">{t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SUMMARY_INFORMATION_ABOUT_THE_MEMBERSHIP')}</p>
                {data.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
            </div>
        </>
    )
}

const PaymentMethodSummaryInformation = (_this: ThisInterface) => {
    const {t} = _this.props;
    const { paymentDetails } = _this.props.newMembership;
    const { 
        membershipConfiguration,
    } = _this.props.newMembership;
    const memberSummaryOld = _this.props.oldMembership.memberSummary;

    let data = [
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PRIMARY_METHOD'),
            data: paymentDetails.primaryPaymentMethod ? paymentDetails.primaryPaymentMethod.name : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_SERVICE_PROVIDER'),
            data: "-"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARD_TYPE'),
            data: paymentDetails.cardType ?  paymentDetails.cardType.label : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARD_SUB_TYPE'),
            data: "-"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARDHOLDER_NAME'),
            data: paymentDetails.accountName
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CARD_NUMBER'),
            data: paymentDetails.creditCardNumber
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.EXPIRY_DATE'),
            data: paymentDetails.expiryDate
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ISSUING_BANK'),
            data: paymentDetails.issuingBank ? paymentDetails.issuingBank.label : null
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.ISSUING_COUNTRY'),
            data: "-"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.FUNDING_SOURCE'),
            data: "-"
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYERS_NATIONAL_ID_NUMBER'),
            data: paymentDetails.nationalIdNumber
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYER_OTHER_ID_PASSPORT_NO'),
            data: paymentDetails.otherNationalIdNumber
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.LAST_UPDATED'),
            data: Utils.DisplayDateFormat(
                memberSummaryOld.payment && memberSummaryOld.payment.primary && memberSummaryOld.payment.primary.updatedUtc ? new Date(memberSummaryOld.payment.primary.updatedUtc) : new Date(),
                membershipConfiguration.newMemberTimeFormat
            )
        },
        {
            isVisible: true,
            fieldLabel: t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.SECONDARY_METHOD'),
            data: paymentDetails.secondaryPaymentMethod
        }
    ]

    return (
        <>
            <div className="select-group open">
                {data.map((field, key) =>
                    field.isVisible && <div key={key} className="row pb-2">
                        <div className="col-4">{field.fieldLabel}</div>
                        <div className="col-6">{field.data}</div>
                    </div>
                )}
            </div>
        </>
    )
}

const headers = [
    {
        key: 1,
        title: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.DATE'
    },
    {
        key: 2,
        title: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.TYPE'
    },
    {
        key: 3,
        title: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.METHOD'
    },
    {
        key: 4,
        title: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.STATUS'
    },
    {
        key: 5,
        title: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.AMOUNT'
    }
]

const renderTableContent = (data, newMemberTimeFormat) => {
    return data.map((item: MemberArrea, index) => {
        let startDate = moment(item.startDate);
        let amount = formatAmount(item ? item.amount : 0);

        return (
            <tr key={index}>
                <td className="td-left">{startDate.format(newMemberTimeFormat.toUpperCase())}</td>
                <td className="td-left">{item.typeName}</td>
                <td className="td-left">{item.method}</td>
                <td className="td-left">{item.status}</td>
                <td className="td-left">
                    <p className="amount">
                        {amount[0]}.<sup>{amount[1]}</sup>
                    </p>
                </td>
            </tr>
        );
    });
};

const MemberPaymentSummaryInformation = _this => {
    const { t, newMembership: {
        membershipConfiguration
    } } = _this.props;
    
    if (_this.state.fees && _this.state.fees.length) {
        const tillDate = moment(
            _this.state.fees[0].startDate
        ).add(5, 'months').startOf('month');
        return (
      <>
        <p>
            {t(
                'PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENTS_PROJECT_FOR_6_MONTHS_UNTIL_VALUE',
                { value: tillDate.format(membershipConfiguration.newMemberTimeFormat.toUpperCase()) }
            )}
        </p>
        <div className="decentralize">
            <div className="decentralize-main">
                <div className="table-responsive">
                    <CustomTable
                        headers={headers}
                        tableContent={renderTableContent(_this.state.fees, membershipConfiguration.newMemberTimeFormat)}
                        renderingCondition
                    />
                </div>
            </div>
        </div>
      </>
        );
    }
};


const template = (_this: ThisInterface) => {

    const {
        openDropdown,
    } = _this.state;

    const { isEdit, t } = _this.props;

    const {
        membershipSearchFilter,
        membershipConfiguration,
        selectedPackagePlan } = _this.props.newMembership;



    return (
        <section id="member">
            <div className="member member-paging">
                <div className="title-content">
                    {!isEdit && <p>{t!('PAGE.MEMBERSHIPS.CREATE.ADD_NEW_MEMBER')}</p>}
                    {isEdit && <p>{t!('PAGE.MEMBERSHIPS.EDIT.CHANGE_MEMBERSHIP')}</p>}
                </div>
                <div className="member-main">
                    <Breadcrumb
                        isEdit={isEdit}
                        membershipConfiguration={membershipConfiguration}
                        goToStep={(stepIndex) => _this.goToStep(stepIndex)} />
                    <div className="content select-add-on personal change-summary">
                        <div className="main-payment select-add-on-main">
                            <CollapseButton
                                className="collapse-promotion"
                                title={t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.CHANGE_MEMBERSHIP_SUMMARY_INFORMATION')}
                                content={ChangeMemberSummaryInformation(_this)}
                                isOpen={openDropdown['changeMemberSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('changeMemberSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.NEW_MEMBERSHIP_SUMMARY_INFORMATION')}
                                content={NewMemberSummaryInformation(_this)}
                                isOpen={openDropdown['newMemberSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('newMemberSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.OLD_MEMBERSHIP_SUMMARY_INFORMATION')}
                                content={OldMemberSummaryInformation(_this)}
                                isOpen={openDropdown['oldMemberSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('oldMemberSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.PAYMENT_METHOD_SUMMARY_INFORMATION')}
                                content={PaymentMethodSummaryInformation(_this)}
                                isOpen={openDropdown['paymentMethodSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('paymentMethodSummaryInformation')}
                            />
                            <CollapseButton
                                className="collapse-promotion"
                                title={t!('PAGE.MEMBERSHIPS.EDIT.STEP_4.MEMBER_PAYMENT_SUMMARY_INFOR')}
                                content={MemberPaymentSummaryInformation(_this)}
                                isOpen={openDropdown['memberPaymentSummaryInformation']}
                                maxHeight="1200px"
                                openCollapsedContent={()=> _this.handleOpenDropdown('memberPaymentSummaryInformation')}
                            />
                        </div>
                        <ProductSummary
                            productSummary={selectedPackagePlan}
                            membershipSearchFilter={membershipSearchFilter}
                            membershipConfiguration={membershipConfiguration}
                            handlePrevious={() => _this.handlePrevious()}
                            handleNext={() => _this.handleNext()}
                            handleCancel={_this.props.onCancel}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default template;