import React from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { withTranslation, WithTranslation } from 'react-i18next';
import { checkInvalidEmailField, transformOptions } from 'util/ControlUtils';
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';

import template from './template';
import ThisInterface, { OptionModel } from './interface';
import * as Route from 'pages/RouteLoader';

class EditProfile extends React.Component<
ThisInterface['props'] & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props']) {
        super(props);
        const { conman } = props;

        this.state = {
            email: conman!.operator.email,
            mobilePhoneNumber: conman!.operator.mobilePhoneNumber,
            mobilePhoneRegionCode: conman!.operator.mobilePhoneRegionCode,
            firstName: conman!.operator.firstName,
            lastName: conman!.operator.lastName,
            selectedSubtentant: {
                value: '',
                label: ''
            },
            isValidPhoneNumber: true,
            disabled: true,
            subTenants: [],
            firstNameDirty: false,
            lastNameDirty: false,
            isChanging: false
        };
    }

    componentDidMount() {
        const { conmanService, conman } = this.props;
        const rightName = Route.EditProfileRoute.rightName;
        conmanService
            .getSubTenants(rightName)
            .then(res => {
                if (res.data && !_.isEmpty(res.data)) {
                    this.setState({
                        subTenants: transformOptions(res.data),
                        selectedSubtentant: conman!.operator.subTenantId
                            ? transformOptions(res.data).find(
                                item => item.id === conman!.operator.subTenantId
                            )!
                            : null
                    });
                }
            })
            .catch(() => {
                this.setState({
                    subTenants: [],
                    selectedSubtentant: {
                        value: '',
                        label: ''
                    }
                });
            });
    }

    checkingReadyToSave = () => {
        const {
            firstName,
            lastName,
            email,
            mobilePhoneNumber,
            mobilePhoneRegionCode,
            isValidPhoneNumber
        } = this.state;
        this.setState({
            firstNameDirty: !firstName,
            lastNameDirty: !lastName
        });
        const fullName = firstName && lastName;
        const validPhoneAndMail =
            isValidPhoneNumber && checkInvalidEmailField(email);

        if (
            fullName &&
            validPhoneAndMail
        ) {
            return {
                firstName,
                lastName,
                email,
                mobilePhoneNumber,
                mobilePhoneRegionCode
            };
        }
        return false;
    };

    saveProfile = submitData => {
        const { userService, conman, t } = this.props;
        userService
            .updateUserProfile(conman!.operator.id, submitData)
            .then(data => {
                this.setState({
                    disabled: true,
                    isChanging: false
                });
                if (data) {
                    const fullName = `${data.firstName} ${data.lastName}`;
                    observer.publish('loaduserProfile', {
                        ...conman!.operator,
                        ...data,
                        fullName
                    });
                    observer.publish('flagUnsavedChangeEvent', false);
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        type: 'success',
                        html: t('PAGE.UPDATE_PROFILE.SAVE_SUCCESS'),
                        confirmButtonText: t('BUTTON.CLOSE')
                    });
                }
            })
            .catch(() => {
                this.setState({
                    disabled: false,
                    isChanging: true
                });
            });
    };

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const { value } = e.target;
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(prevState => {
            return {
                ...prevState,
                disabled: false,
                [key]: value,
                [`${key}Dirty`]: false,
                isChanging: true
            };
        });
    };

    handleChangePhoneNumber = (
        nationalNumber: string,
        regionCode: string,
        isValid: boolean,
        key: string
    ) => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState(prevState => {
            return {
                ...prevState,
                [key]: nationalNumber,
                mobilePhoneRegionCode: regionCode,
                isValidPhoneNumber: isValid,
                disabled: false,
                isChanging: true
            };
        });
    };

    handleChangeNationality = (value: OptionModel) => {
        observer.publish('flagUnsavedChangeEvent', true);
        this.setState({
            selectedSubtentant: value,
            disabled: false,
            isChanging: true
        });
    };

    handleCancel = () => {
        const { conman, history, t } = this.props;
        if (this.state.isChanging) {
            if (conman) {
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    text: t('COMMON.CANCEL_NOTIFICATION'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: t('BUTTON.YES'),
                    cancelButtonText: t('BUTTON.NO')
                }).then(res => {
                    if (res.value) {
                        observer.publish('flagUnsavedChangeEvent', false);
                        this.setState({
                            email: conman.operator.email,
                            mobilePhoneNumber: conman!.operator.mobilePhoneNumber,
                            mobilePhoneRegionCode: conman!.operator.mobilePhoneRegionCode,
                            firstName: conman.operator.firstName,
                            lastName: conman.operator.lastName,
                            selectedSubtentant: conman!.operator.subTenantId
                                ? this.state.subTenants.find(
                                    item => item.value === conman!.operator.subTenantId
                                )!
                                : null,
                            disabled: true,
                            isChanging: false
                        });
                    }
                });
            }
        } else {
            history.push('/');
        }
    };

    handleUpdateProfile = () => {
        const { t } = this.props;
        const submitData = this.checkingReadyToSave();
        if (submitData) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('PAGE.UPDATE_PROFILE.CONFIRM_SAVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO')
            }).then(res => {
                if (res.value) {
                    Swal.fire({
                        html: `${t(
                            'PROCESSING'
                        )}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                        showConfirmButton: false
                    });
                    this.saveProfile(submitData);
                }
            });
        }
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(EditProfile);
