import React from 'react';

interface Props {
    title: string;
    listData: { id: string; name: string }[];
}

const Roles: React.FunctionComponent<Props> = ({ title, listData }) => {
    return (
        <div className="list">
            <h3 className="title">{title}</h3>
            <ul className="item-holder">
                {listData.map(item => {
                    return (
                        <li key={item.id} className="item">
                            {item.name}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

Roles.displayName = 'Roles';

export default Roles;
