import Swal from 'sweetalert2';

import * as DefaultConstants from 'constants/DefaultConstants';
import { t } from 'util/I18nMessages';

export const submitModal = (confirmSubmitMsg: string, submitCallback = () => {}) => {
    Swal.fire({
        ...DefaultConstants.SWAL_COMMON_STYLE,
        type: 'warning',
        text: t(confirmSubmitMsg),
        showCancelButton: true,
        confirmButtonText: t('BUTTON.YES'),
        cancelButtonText: t('BUTTON.NO')
    }).then(res => {
        if (res.value) {
            Swal.fire({
                html: `${t('COMMON.PROCESSING')}...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`,
                showConfirmButton: false
            });
            submitCallback();
        }
    });
};

export const confirmModal = (confirmMsg: string, confirmCallBack = () => {}) => {
    Swal.fire({
        ...DefaultConstants.SWAL_COMMON_STYLE,
        type: 'warning',
        text: t(confirmMsg),
        showCancelButton: true,
        confirmButtonText: t('BUTTON.YES'),
        cancelButtonText: t('BUTTON.NO')
    }).then(res => {
        if (res.value) {
            confirmCallBack();
        }
    });
}

export const errorModal = (errorMsg: string) => {
    Swal.fire({
        ...DefaultConstants.SWAL_COMMON_STYLE,
        text: t(errorMsg),
        type: 'error',
        confirmButtonText: t('BUTTON.CLOSE')
    });
};
