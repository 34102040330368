import React from 'react';

const Loading: React.SFC<{}> = () => {
    return (
        <div className="loading-dot-holder">
            <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loading;
