import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import { getParamFromUrl, isHasRight } from 'util/ControlUtils';
import * as Route from 'pages/RouteLoader';
import { MatchParams, ThisInterface } from './interface';
import template from './template';

class MFDetailFeePage extends React.Component<
ThisInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & RouteComponentProps<MatchParams> & WithTranslation) {
        super(props);
        this.state = {
            iframeLoaded: false,
            iframeToken: '',
            subTenantId: '',
            id: props.match.params.id
        }
    }

    getIframeToken(subTenantId) {
        this.props.conmanService.getIframeToken(subTenantId).then(res => {
            this.setState({
                iframeToken: res.message
            })
        });
    }

    componentDidMount() {
        window.addEventListener("message", this.messageHandler, false);
        const iframeSubtenantId = localStorage.iframeSubtenantId ? localStorage.iframeSubtenantId : '';
        if (iframeSubtenantId) {
            this.setState({subTenantId: iframeSubtenantId});
            this.getIframeToken(iframeSubtenantId);
        }
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data;

        switch (action) {
            case 'returnHeightData': 
                const obj: any = this.refs.iframe;
                if (obj) {
                    obj.style.height = value + 'px';
                }
                break;
            case 'redirectURL':
                if(value.includes('/Products/Items/Items.aspx')) {
                    this.props.history.push(`/${Route.ItemRoute.url}`);
                } else if(value.includes('/Products/Plans/Plans.aspx')){
                    this.props.history.push(`/${Route.PlanRoute.url}`);
                } else if(value.includes('/Products/Items/Wizards/EditItem.aspx')){
                    const item = getParamFromUrl(value, 'ItemId');
                    item && this.props.history.push(`/${Route.EditFeeRoute.url.replace(':id', item.value)}`);
                }
                break;
        }
    }

    onCancel = () => {
        this.props.history.push(`/${Route.ItemRoute.url}`);
    }

    handleNext = () => {
    }

    resizeIframe = (obj) => {
        this.setState({iframeLoaded: true});
        obj.contentWindow.postMessage({
            action: 'getHeightData'
        }, '*');

        const hasRightViewPlans = isHasRight(Route.PlanRoute.rightName);
        const hasRightEditFee = isHasRight(Route.EditFeeRoute.rightName);

        hasRightViewPlans && obj.contentWindow.postMessage({
            action: 'hasRightViewPlans'
        }, '*');

        hasRightEditFee && obj.contentWindow.postMessage({
            action: 'hasRightEditFee'
        }, '*');
    }

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(MFDetailFeePage)));
