import React from 'react';
import { Input } from 'reactstrap';
import { FieldModel } from 'models/Field';
import { FormItemInterface, isValidOrNot, renderErrorHtml } from './index';
import OptionModel from 'models/Option';

interface Props extends FormItemInterface {
    selectAllLabel: string | undefined;
    options: OptionModel[] | undefined;
}

const SelectBoxGroup = React.memo((props: Props) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        selectAllLabel,
        className = 'select-custom',
        data,
        attribute,
        options = [],
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event.target.value);
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';
    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={className + ' ' + errorClass}>
                <span className="icon-arrow-down-icon"> </span>
                <Input
                    {...attribute}
                    value={data}
                    onChange={onChange}
                    className="select-filter"
                    name={fieldName}
                    id={htmlFor}
                    type="select"
                >
                    {selectAllLabel && <option value="">{selectAllLabel}</option>}
                    {options.map((row, key) => (
                        <option key={key} value={row.value}>
                            {row.label}
                        </option>
                    ))}
                </Input>
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
SelectBoxGroup.displayName = 'SelectBoxGroup';

export default SelectBoxGroup;
