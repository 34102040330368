import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as Constants from 'constants/Constants';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en-US',

        // i18n.changeLanguage('en-US');
        backend: {
            loadPath: `${Constants.LANGUAGE_RESOURCE_URL}/{{lng}}.json`
        },

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        debug: false,
        keySeparator: false,

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            wait: true
        }
    });

export default i18n;