import React, { useState, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import * as Interface from 'interfaces';
import {withTranslation, WithTranslation} from 'react-i18next'

interface OptionModel {
    label: string;
    value: React.ReactNode;
}

interface SelectProps extends Interface.InputInterface {
    options: OptionModel[];
    isDisabled?: boolean;
    defaultValue?: string | number;
    isClearable: boolean;
    searchIcon?: boolean;
    isDirty?: boolean;
    isTyping?: boolean;
    onInputChange?: (value: string) => void;
    isLoading?: boolean;
}

const primaryColor = '#00b7bc';

const customStyles = (searchIcon = false) => {
    const padding = searchIcon
        ? {
            paddingLeft: 24
        }
        : {
            padding: 0
        };

    return {
        valueContainer: (provided: Record<string, {}>) => ({
            ...provided,
            ...padding
        }),
        option: (
            provided: Record<string, {}>,
            { isSelected }: { isSelected?: boolean }
        ) => {
            return {
                ...provided,
                backgroundColor: isSelected ? primaryColor : provided.backgroundColor
            };
        },
        control: (
            provided: Record<string, {}>,
            { isFocused }: { isFocused?: boolean }
        ) => ({
            ...provided,
            boxShadow: 'none',
            borderColor: isFocused ? primaryColor : provided.borderColor,
            padding: '2px 10px',
            minHeight: '34px',
            '&:hover': { borderColor: 'primaryColor' }
        })
    };
};

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!children && <SearchIcon className="search-icon" />}
                {children}
            </components.ValueContainer>
        )
    );
};

const Select: React.FunctionComponent<SelectProps & WithTranslation> = props => {
    const [error, setError] = useState(false);
    const [menuIsOpen, setMenuOpen] = useState({});

    useEffect(() => {
        if (props.isDirty === undefined) {
            !props.errorMsg ? setError(false) : setError(true);
        } else {
            props.value || !props.isDirty ? setError(false) : setError(true);
        }
        setMenuOpen(
            props.isDisabled
                ? {
                    menuIsOpen: false
                }
                : {}
        );
    }, [props.isDisabled, props.isDirty, props.value, props.errorMsg]);

    function handleChangeValue(value: OptionModel) {
        props.onChange!(value);
        if ((value === null && props.isDirty) || props.errorMsg) {
            setError(true);
        } else {
            setError(false);
        }
    }

    function handleInputChange(value: string) {
        props.onInputChange!(value);
    }

    const classNameError = props.errorMsg ? 'error' : '';
    const inputClass = props.inputClass ? props.inputClass : '';

    const onInputChange =
    props.onInputChange !== null ? { onInputChange: handleInputChange } : {};
    return (
        <>
            <ReactSelect
                isClearable={props.isClearable}
                styles={customStyles(props.searchIcon)}
                classNamePrefix="select"
                components={props.searchIcon ? { ValueContainer } : {}}
                className={`${classNameError} ${inputClass}`}
                onChange={handleChangeValue}
                isLoading={props.isLoading}
                noOptionsMessage={()=> props.t('COMMON.NO_OPTIONS')}
                {...menuIsOpen}
                {...onInputChange}
                {...props}
            />
            {error && props.errorMsg && (
                <span className="text-danger input-error">{props.errorMsg}</span>
            )}
        </>
    );
};

Select.displayName = 'Select';

export default withTranslation()(Select);
