import React from 'react';
import PackagePlanItem from './PackagePlanItem';
import Loading from 'components/Loading';

interface PackagePlanListProps {
    isLoading: boolean;
    packagePlans: any;
    selectedPackagePlanId: string;
    membershipConfiguration: any;
    onPackagePlanSelected: (packagePlanId: string) => void;
}

const PackagePlanList: React.FunctionComponent<PackagePlanListProps> = ({
    isLoading,
    packagePlans,
    selectedPackagePlanId,
    membershipConfiguration,
    onPackagePlanSelected
}) => {
    return (
        <div className="content select-package-plan">
            {isLoading ? <Loading /> :
                packagePlans && packagePlans.map((packagePlanItem, index) =>
                    <PackagePlanItem key={index}
                        packagePlanItem={packagePlanItem}
                        selectedPackagePlanId={selectedPackagePlanId}
                        membershipConfiguration={membershipConfiguration}
                        onSelected={onPackagePlanSelected} />
                )
            }
        </div>
    );
};

export default PackagePlanList;