import React from 'react';
import { useTranslation } from 'react-i18next';

interface FormButtonProps {
    membershipConfiguration?;
    isOnProductSummary?: boolean;
    handlePrevious?;
    handleNext?;
    handleCancel?;
    handleFinish?;
}

const FormButton: React.SFC<FormButtonProps> = ({
    membershipConfiguration,
    isOnProductSummary = false,
    handlePrevious,
    handleNext,
    handleCancel,
    handleFinish
}) => {
    const { t } = useTranslation();
    return (
        isOnProductSummary ?
            <>
                <div className="box-btn">
                    <button className={`btn btn-secondary btn--aside ${handlePrevious ? '' : 'hidden'}`} onClick={() => handlePrevious()}>{t('BUTTON.PREVIOUS')}</button>
                    {membershipConfiguration.stepIndex == 5
                        ?
                        <button className={`btn btn-primary btn--aside`} onClick={() => handleFinish()}>{t('BUTTON.FINISH')}</button>
                        :
                        <button className={`btn btn-primary btn--aside ${handleNext ? '' : 'hidden'}`} onClick={() => handleNext()}>{t('BUTTON.NEXT')}</button>
                    }
                </div>
                <div className="box-btn box-btn-mt0">
                    <button className={`btn btn-dark btn--aside-block ${handleCancel ? '' : 'hidden'}`} onClick={() => handleCancel()}>{t('BUTTON.CANCEL')}</button>
                </div>
            </> :
            <div className="box-btn">
                <button className={`btn btn-secondary ${handlePrevious ? '' : 'hidden'}`} onClick={() => handlePrevious()}>{t('BUTTON.PREVIOUS')}</button>
                <button className={`btn btn-primary ${handleNext ? '' : 'hidden'}`} onClick={() => handleNext()}>{t('BUTTON.NEXT')}</button>
                <button className={`btn btn-dark ${handleCancel ? '' : 'hidden'}`} onClick={() => handleCancel()}>{t('BUTTON.CANCEL')}</button>
            </div>
    );
};

export default FormButton;