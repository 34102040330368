import React from 'react';
import { ThisInterface } from './interface';
import * as Constants from 'constants/Constants';
import Loading from 'components/Loading';
import { getIframeAPIURL } from 'util/ControlUtils';

const template = (_this: ThisInterface) => {
    const { t } = _this.props;
    const { iframeToken, subTenantId, iframeLoaded, id } = _this.state;
    const iframeDetail = Constants.IFRAME_VIEW_FEE_DETAIL.replace('{0}', id || '');
    return (
        <>
            <div className="package-detail">
                <div className="list-page plan-list-view-page">
                    <div className="list-page-title">
                        <p className="title-content title-content-list">
                            {t('PAGE.PRODUCTS.ITEMS.FEE.ITEM_DETAIL')}
                        </p>
                    </div>
                    <div className="box-btn-filter ml-auto d-flex">
                        <span className="plan-hint">
                            {t('PAGE.PRODUCTS.ITEMS.FEE.ITEM_DETAIL.INSTRUCTION')}
                        </span>
                    </div>
                </div>
            </div>
            <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && <iframe src={`${getIframeAPIURL(iframeDetail, subTenantId, '/MembershipManagement')}&token=${iframeToken}`} ref='iframe' onLoad={() => _this.resizeIframe(_this.refs.iframe)} className="iframe" frameBorder="0"></iframe>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default template;
