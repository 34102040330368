import React from 'react';
import embededWrapper from 'hocs/Embeded';
import Loading from 'components/Loading';

import { IFRAME_CLUB_DETAIL } from 'constants/Constants';
import { getIframeAPIURL, getParamFromUrl } from 'util/ControlUtils';
import * as RouteLoader from 'pages/RouteLoader';
import ThisInterface from './interface';

interface State {
    id: string;
}
class ClubDetail extends React.Component<ThisInterface['props'], State> {
    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {
            id: props.match.params['id']
        }
    }

    componentDidMount = () => {
        window.addEventListener('message', this.messageHandler);
    }
	
    componentWillUnmount() {
        window.removeEventListener('message', this.messageHandler);
    }

    messageHandler = (event) => {
        const { action, value } = event.data;
        
        switch (action) {
            case 'returnHeightData': 
                const obj: any = this.refs.iframe;
                if (obj) {
                    obj.style.height = value + 'px';
                }
                break;
            case 'redirectURL':
                if(value.includes('/Clubs/Clubs.aspx')) {
                    this.props.history.push(`/${RouteLoader.ClubListPageRoute.url}`);
                }
                if (value.includes('ClubId')) {
                    const club = getParamFromUrl(value, 'ClubId');
					
                    if (value.includes('ClubBankingSession.aspx')) {
                        club && this.props.history.push(`/clubs/${club.value}/club-banking`);
                    }
                    if (value.includes('Clubs/Shifts.aspx')) {
                        club && this.props.history.push(`/clubs/${club.value}/club-outstanding-shift`);
                    }
                    if (value.includes('Clubs/ClosedShifts.aspx')) {
                        club && this.props.history.push(`/clubs/${club.value}/club-close-shift`);
                    }
                }
                break;
            default:
                break;
        }
    }
	
    render() {
        const { iframeLoaded, iframeToken, subTenantId } = this.props;
        const { id } = this.state;

        return (
            <div className="member mt-3">
                <div className="member-main member-main--custom">
                    <div className="content">
                        {!iframeLoaded && <Loading />}
                        {iframeToken && (
                            <iframe
                                src={`${getIframeAPIURL(IFRAME_CLUB_DETAIL.replace('{0}', id), subTenantId, '/MembershipManagement')}&token=${iframeToken}`}
                                ref='iframe'
                                onLoad={() => this.props.resizeIframe(this.refs.iframe)}
                                className="iframe"
                                frameBorder="0"
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default embededWrapper(ClubDetail, 'ClubDetailPageRoute', 'PAGE.MF.CLUBS.CLUB.TITLE', 'PAGE.MF.CLUBS.CLUB.DESCRIPTION');