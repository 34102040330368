import React from 'react';
import ReactPasswordStrength from 'react-password-strength';
import Loading from 'components/Loading';

import ThisInterface from './interface';

const template = (_this: ThisInterface) => {
    const {
        newPassword,
        confirmNewPassword,
        newPasswordShow,
        confirmNewPasswordShow,
        newPasswordErrors,
        message,
        confirmNewPasswordError,
        isLoading
    } = _this.state;
    const { t } = _this.props;
    const listMsgKey = Object.keys(message);

    return (
        <>
            <div className="loginForm__desc">
                <h3>{t('PAGE.CHANGE_FORGOT_PASSWORD.CREATE_NEW_PASSWORD')}</h3>
            </div>
            <form>
                <div className="input-group input-pass input-newpass">
                    <ReactPasswordStrength
                        className="form-control react-password-strength-input"
                        minLength={8}
                        minScore={2}
                        tooShortWord={t('PAGE.CHANGE_FORGOT_PASSWORD.WEAK')}
                        scoreWords={[
                            t('PAGE.CHANGE_FORGOT_PASSWORD.WEAK'),
                            t('PAGE.CHANGE_FORGOT_PASSWORD.WEAK'),
                            t('PAGE.CHANGE_FORGOT_PASSWORD.MEDIUM'),
                            t('PAGE.CHANGE_FORGOT_PASSWORD.MEDIUM'),
                            t('PAGE.CHANGE_FORGOT_PASSWORD.STRONG')
                        ]}
                        changeCallback={_this.handleChangeNewPassword}
                        inputProps={{
                            name: 'newPassword',
                            type: `${newPasswordShow ? 'text' : 'password'}`,
                            autoComplete: 'off',
                            className: 'new-password form-control',
                            placeholder: t('PAGE.CHANGE_FORGOT_PASSWORD.NEW_PASSWORD'),
                            value: newPassword
                        }}
                    />
                    <span
                        className={`icon ${
                            newPasswordShow ? 'icon-hidden' : 'icon-visible-ico'
                        }`}
                        onClick={() => _this.showPassword('newPassword')}
                    />
                </div>
            </form>
            <form>
                <div className="input-group input-pass">
                    <input
                        className="inPassword form-control"
                        type={confirmNewPasswordShow ? 'text' : 'password'}
                        placeholder={t('PAGE.CHANGE_FORGOT_PASSWORD.CONFIRM_NEW_PASSWORD')}
                        value={confirmNewPassword}
                        onChange={_this.handleChangeConfirmPassword}
                    />
                    <span
                        className={`icon ${
                            confirmNewPassword ? 'icon-hidden' : 'icon-visible-ico'
                        }`}
                        onClick={() => _this.showPassword('confirmNewPassword')}
                    />
                </div>
                <span className="text-danger input-error">
                    {confirmNewPasswordError
                        ? t('PAGE.FORGOT_PASSWORD.NEW_PASSWORD_NOT_MATCH_CONFIRM')
                        : ''}
                </span>
            </form>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <button className="btn btn-white" onClick={_this.submitNewPassword}>
                        {t('PAGE.CHANGE_FORGOT_PASSWORD.CHANGE_PASSWORD')}
                    </button>
                    <div className="info-change-pass">
                        <p>{t('PAGE.CHANGE_FORGOT_PASSWORD.PASSWORD_MUST_CONTAIN')}</p>
                        <div className="list-check-pass">
                            <ul>
                                {listMsgKey.map((item, index) => {
                                    return (
                                        <li
                                            key={item}
                                            className={`${index % 2 === 0 ? 'mg-r-20' : ''} ${
                                                newPasswordErrors[item] ? 'active' : ''
                                            }`}
                                        >
                                            {message[item]}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default template;
