import React from 'react';

class Footer extends React.PureComponent<{}, {}> {
    constructor(props: {}) {
        super(props);
    }

    render() {
        return (
            <footer>
                <h3 className="text-right">Circuit - Powering Membership</h3>
            </footer>
        );
    }
}
export default Footer;
