import React from 'react';
import ApiContext from 'util/ApiContext';
import UserProfile from './UserProfile';
import Loading from 'components/Loading';
import Profile from './Profile';
import ThisInterface from './interface';

const template = (_this: ThisInterface) => {
    const {
        conman,
        userService,
        oidc: { user }
    } = _this.props;

    return (
        <section className="edit-profile">
            {conman && conman.operator ? (
                <div className="profile-content">
                    <UserProfile
                        operator={conman.operator}
                        userService={userService}
                        user={user}
                    />
                    <div className="edit-acc tabs">
                        <div className="tab-content">
                            <ApiContext.Consumer>
                                {svrs => (
                                    <Profile
                                        {...svrs}
                                        history={_this.props['history']}
                                        isShow
                                        conman={conman}
                                    />
                                )}
                            </ApiContext.Consumer>
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </section>
    );
};

export default template;
