import React from 'react';
import Pagination from 'components/Pagination';
import Loading from 'components/Loading';
import CustomTable from 'components/Table';
import errNotFound from 'assets/images/err-edit-pass.svg';
import { Input } from 'reactstrap';

const headers = [
    {
        key: 1,
        title: 'COMMON.NO.',
        className: 'acc-stt'
    },
    {
        key: 2,
        title: 'PAGE.RESET_PASSWORD.ACCOUNT_NAME',
        className: 'acc-name rounded-top-left'
    },
    {
        key: 3,
        title: 'PAGE.RESET_PASSWORD.FULL_NAME',
        className: 'acc-fname rounded-top-left hidden-mb'
    },
    {
        key: 4,
        title: '_blank',
        className: 'acc-reset'
    }
];

const renderItem = _this => {
    const { t } = _this.props;
    return _this.state.data.operators.map((item, index) => 
        <tr key={index}>
            <td>{index + 1}</td>
            <td className="rounded-top-left"><span className="wraptext" title={item.username}>{ item.username }</span></td>
            <td className="rounded-top-left hidden-mb">{ item.fullName }</td>
            <td className="rounded-top-left reset-icon"  onClick={() => _this.resetPassword(item)}>
                <span className="icon-reset-password-ico"></span>
                <span className="text-bl">{t('PAGE.SYSTEM.ROLES.ADD_EDIT.RESET_PASSWORD')}</span>
            </td>
        </tr>
    )
}

const template = _this => {
    const { t } = _this.props;
    const { pagination } = _this.state.data;
    return (
        <section id="reset-password">
            <div className="reset-password">
                <div className="title-content">
                    <p>{t('PAGE.SYSTEM.ROLES.ADD_EDIT.RESET_PASSWORD')}</p>
                </div>
                <div className="reset-password-main">
                    <p className="rs-title">{t('PAGE.RESET_PASSWORD.DESCRIPTION')}</p>
                    <div className="rs-search">
                        <form onSubmit={(e) => _this.submitSearchByKey(e)}>
                            <div className="input-group input-search">
                                <Input onChange={(e) => _this.searchKeywordChange(e)} className="form-control" type="text" placeholder={t('PAGE.RESET_PASSWORD.SEARCH_BY_USERNAME')} />
                            </div>
                        </form><button onClick={() => _this.searchButtonPress()} className="btn btn-primary">{t('BUTTON.SEARCH')}</button>
                    </div>
                    <div className="rs-content">
                        {_this.state.isLoading ? <Loading /> : 
                            (_this.state.data.totalCount > 0 ? 
                                <div className="table-content">
                                    <div className="list-page">
                                        <span className="font-weight-bold">{t('PAGE.RESET_PASSWORD.YOUR_RESULT')}</span>
                                        <Pagination
                                            onPageChange={_this.handleChangePage}
                                            itemsCountPerPage={pagination.pageSize}
                                            totalItemsCount={pagination.totalItems}
                                            activePage={_this.state.page}
                                        />
                                    </div>
                                    <div className="table-edit-pass table-responsive">
                                        <CustomTable
                                            headers={headers}
                                            tableContent={renderItem(_this)}
                                            renderingCondition={!_this.state.isLoadingPagination}
                                        />
                                    </div>
                                </div>
                                : 
                                <div className="err-box">
                                    <div className="err-text">
                                        <h2 className="text-center">{t('COMMON.OOPS')}</h2>
                                        <h3 className="text-center">{t('PAGE.RESET_PASSWORD.NOT_FOUND')}</h3>
                                    </div>
                                    <div className="err-img text-center"><img src={errNotFound} alt="IMG" /></div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
