export class DefaultModel {
    id = '';
    name = '';
    value = '';
    label = '';
}

export class ContactMethodModel extends DefaultModel {   
    key = '';
}

export class LookupModel extends DefaultModel{
    key = '';
    code = '';    
}

export class PhoneNumberModel {
    isValid = false;
    countryCode = '';
    phoneNumber = '';
}

export class FieldConfig {
    key = "";
    name = "";
    isRequired = false;
    isVisible = true;
    regex = "";
    maxLength = 255;
}

export class PersonalDetailsModel {
    title = new LookupModel();
    gender = new LookupModel();
    firstName = '';
    lastName = '';
    nationalIdNumber = '';
    otherNationalIdNumber = '';
    dateOfBirth = '';
    preferedLanguage = new LookupModel();
    isParentalConsentProvided = false;
}

export class PersonalDetailFieldsModel {
    titleConfig = new FieldConfig();
    genderConfig = new FieldConfig();
    firstNameConfig = new FieldConfig();
    lastNameConfig = new FieldConfig();
    dateOfBirthConfig = new FieldConfig();
    nationalConfig = new FieldConfig();
    nationalIdConfig = new FieldConfig();
    otherNationalIdConfig = new FieldConfig();
    preferedLanguageConfig = new FieldConfig();
    parentalConsentConfig = new FieldConfig();
}

export class ContactDetailsModel {
    mobilePhone = new PhoneNumberModel();
    homePhone = new PhoneNumberModel();
    workPhone = new PhoneNumberModel();
    email = '';
    isEmailValid = true;
    overrideEmailOption = new LookupModel();
    mobilePhoneNoLongerUse? = false;
    homePhoneNoLongerUse? = false;
    workPhoneNoLongerUse? = false;
    emailNoLongerUse? = false;
}

export class ContactDetailsFieldsModel {
    telephoneNumberConfig = new FieldConfig();
    mobilePhoneConfig = new FieldConfig();
    homePhoneConfig = new FieldConfig();
    workPhoneConfig = new FieldConfig();
    emailConfig = new FieldConfig();
    overrideEmailConfig = new FieldConfig();
}

export class AddressDetailsModel {
    addressNumber = '';
    addressLine1 = '';
    addressLine2 = '';
    city = '';
    state = '';
    postCode = '';
    country = new DefaultModel();    
    goneAway = false;
}

export class AddressDetailsFieldsModel {
    addressNumberConfig = new FieldConfig();
    addressLine1Config = new FieldConfig();
    addressLine2Config = new FieldConfig();
    cityConfig = new FieldConfig();
    stateConfig = new FieldConfig();
    countryConfig = new FieldConfig();
    postCodeConfig = new FieldConfig();
    goneAwayConfig = new FieldConfig();
}

export class ContactedDetailsModel {
    applicationRef = '';
    memberHappyToBeContact = false;        
    thirdPartyContact = false;
    preferredContactMethod: ContactMethodModel[] = [];
    marketingSource = new LookupModel();
}

export class ContactedDetailsFieldsModel{
    applicationRefConfig = new FieldConfig();
    memberHappyToBeContactConfig = new FieldConfig();
    thirdPartyContactConfig = new FieldConfig();
    preferredContactMethodConfig = new FieldConfig();
    postConfig = new FieldConfig();
    homePhoneConfig = new FieldConfig();
    mobileConfig = new FieldConfig();
    workPhoneConfig = new FieldConfig();
    emailConfig = new FieldConfig();
    smsConfig = new FieldConfig();
    marketingSourceConfig = new FieldConfig();
}

export class EmergencyContactModel {
    contactName = '';
    contactNumber = new PhoneNumberModel()
}
export class EmergencyContactFieldsModel {
    contactNameConfig = new FieldConfig();
    contactNumberConfig = new FieldConfig();
}

export class HealthQuestionModel {    
    id = '';
    name = '';
    isLastQuestion = false;
}

export class OtherFieldsModel {    
    companyName = '';
    occupation = '';
    referredByMemberName = '';
    referredByMembershipNumber = '';
    otherCommissionNote = '';
    generalNote = '';
    aiaVitalityCategory = new LookupModel();
    recurringCardType = new LookupModel();
}

export class OtherFieldsFieldsModel {
    companyNameConfig = new FieldConfig();
    occupationConfig = new FieldConfig();
    referredByMemberNameConfig = new FieldConfig();
    referredByMembershipNumberConfig = new FieldConfig();
    otherCommissionNoteConfig = new FieldConfig();
    generalNoteConfig = new FieldConfig();
    aiaVitalityCategoryConfig = new FieldConfig();
    recurringCardTypeConfig = new FieldConfig();
}