import React from 'react';
import { 
    ContactMethodModel, 
    ContactedDetailsModel, 
    ContactedDetailsFieldsModel     
} from 'models/PersonalDetails';
import { FormSelect, FormInput } from 'components/Form';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { useTranslation } from 'react-i18next';

const getLanguageKey = (configKey) => {
    switch (configKey) {
        case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_POST:   
            return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.POST';            
        case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_HOME_TELEPHONE:   
            return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE';
        case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_MOBILE_TELEPHONE:   
            return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE';
        case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_WORK_TELEPHONE:   
            return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE';
        case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_EMAIL:   
            return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL';
        case FieldKeys.ADDMEMBER_PREFERRED_CONTACT_METHOD_SMS:   
            return 'PAGE.MEMBERSHIPS.CREATE.STEP_3.SMS';    
        default:
            return 'DEFAULT_FALLBACK'
    }
}

interface Props {    
    errors: any;
    marketingSources: any;
    contactMethods: ContactMethodModel[];    
    contactedDetails: ContactedDetailsModel;
    contactedDetailsFieldConfigs: ContactedDetailsFieldsModel;    
    onInputChange: (key: string, value: string) => void;        
    onCheckboxChange: (key: string, checked: boolean, value?: any) => void;
    onSelectOptionChange: (key: string, value: string) => void;    
}

const ContactedDetails = ({
    errors,    
    marketingSources,
    contactMethods,    
    contactedDetails,
    contactedDetailsFieldConfigs,
    onInputChange,     
    onCheckboxChange,
    onSelectOptionChange,
}: Props ) => {
    let {
        applicationRef,
        marketingSource,
        memberHappyToBeContact,        
        thirdPartyContact,
        preferredContactMethod,        
    } = contactedDetails
    let {
        applicationRefConfig,
        memberHappyToBeContactConfig,
        thirdPartyContactConfig,
        preferredContactMethodConfig,        
        marketingSourceConfig,
    } = contactedDetailsFieldConfigs

    const { t } = useTranslation();
               
    let contactMethodItems = contactMethods.map((cm, index) => {        
        let languageKey = getLanguageKey(cm.key); 

        return (
            (preferredContactMethodConfig.isVisible) &&
            <div key={index} className="col-lg-4 col-md-6">
                <div className="form-check">
                    <input id={`cm-${index}`} type="checkbox" 
                        checked={preferredContactMethod.some(pref => pref.id === cm.id)} 
                        onChange={(event) => onCheckboxChange(cm["key"], event.target.checked, cm)} />
                    <label htmlFor={`cm-${index}`} className="icon-checked-ico"> 
                        <span>{ t(languageKey, cm.name) } </span> 
                    </label>
                </div>
            </div>
        )
    });
    //populate contact method to row with items/row
    var contactMethodRows = contactMethodItems.map((item, index) => {  
        return index % 3 !== 0 ? null : <div key={index} className="row pb-3 pl-3">{contactMethodItems.slice(index, index + 3)}</div>;
    }).filter(row => { return row; });
    
    return (        
        <>
            <div className="row">
                <div className="col-md-6">
                    {applicationRefConfig.isVisible &&                  
                        <FormInput                            
                            id="applicationRef"
                            inputClass="form-control"                                                                          
                            value={applicationRef}        
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBERSHIP_APPLICATION_REF')}       
                            maxlength={applicationRefConfig.maxLength}
                            require = {applicationRefConfig.isRequired}                                
                            isValid={applicationRefConfig.isRequired && applicationRef.length > 0}                                                                                        
                            validMsg={t(errors.applicationRef)}
                            onChange={(event) => onInputChange("applicationRef", event!.target.value)} />                                  
                    }                  
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {memberHappyToBeContactConfig.isVisible &&
                        <div className="form-check">
                            <div>
                                <input  id="memberHappyToBeContact" type="checkbox" className="myCheckedbox"
                                    checked={memberHappyToBeContact}
                                    onChange={(event) => onCheckboxChange("memberHappyToBeContact", event.target.checked)} />
                                
                                <label className="icon-checked-ico" htmlFor="memberHappyToBeContact">
                                    <span>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBER_HAPPY_TO_BE_CONTACTED')}</span>
                                </label>
                                {memberHappyToBeContactConfig.isRequired && <span className="text-primary bold">&nbsp;*</span> }
                            </div>
                            {errors.memberHappyToBeContact && <span className="text-danger input-error">{t(errors.memberHappyToBeContact)}</span>}   
                        </div>
                    }
                    {preferredContactMethodConfig.isVisible &&
                        <div className="box-nation active mt-3 mb-3">
                            <p>
                                {t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PREFER_CONTACT_METHOD')} 
                                {preferredContactMethodConfig.isRequired && <span className="text-primary bold">&nbsp;*</span> }
                                &nbsp;&nbsp;
                                <label className="text-danger input-error">{t(errors.preferredContactMethod)}</label>
                            </p>
                            
                            {contactMethodRows}   
                            
                        </div>
                    }
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-6">                
                    {thirdPartyContactConfig.isVisible &&      
                        <div className="form-check form-group">
                            <div>
                                <input  id="thirdPartyContact" type="checkbox" 
                                    checked={thirdPartyContact}  
                                    onChange={(event) => onCheckboxChange("thirdPartyContact", event.target.checked)} />
                                <label className="icon-checked-ico" htmlFor="thirdPartyContact">
                                    <span>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MEMBER_IS_HAPPY_TO_BE_CONTACTED_BY_A_THIRD_PARTY')}</span>
                                    {thirdPartyContactConfig.isRequired && <span className="text-primary bold">&nbsp;*</span> }
                                </label>
                            </div>
                            {errors.thirdPartyContact && <span className="text-danger input-error">{t(errors.thirdPartyContact)}</span>}
                        </div>                       
                    }                     
                </div>
            </div>
            
            <div className="row marketing-source">               
                <div className="col-md-6">
                    {marketingSourceConfig.isVisible && 
                        <FormSelect
                            id="source"
                            name="source"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.SOURCE')}
                            disabled={marketingSources.length === 0}
                            require={marketingSourceConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("marketingSource", marketingSources.find(marketingSource => marketingSource.value === event.value))}
                            value={marketingSource}
                            options={marketingSources}
                            errorMsg={t(errors.marketingSource)}
                        />
                    }    
                </div>
            </div>
        </>
    )
}

export default ContactedDetails;
