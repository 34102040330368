import React from 'react';
import { Input } from 'reactstrap';
import { FieldModel } from 'models/Field';
import {
    FormItemInterface,
    isValidOrNot,
    renderErrorHtml,
    forceDecimal
} from './index';

const CurrencyBoxGroup = React.memo((props: FormItemInterface) => {
    const {
        htmlFor,
        fieldLabel,
        fieldName,
        fieldValidation = new FieldModel(),
        data,
        attribute,
        className = 'input-group mb-3',
        onChangeHandle,
        htmlLabel
    } = props;

    const onChange = event => {
        onChangeHandle(fieldName, event.target.value);
    };

    const onBlur = event => {
        if (event.target.value !== '') {
            const value = forceDecimal(event.target.value);
            onChangeHandle(fieldName, value);
        } else {
            onChangeHandle(fieldName, '0.00');
        }
    };

    const onFocus = event => {
        if (event.target.value === '0.00') {
            onChangeHandle(fieldName, '');
        }
    };

    const {
        isValidType,
        isDirty,
        isValid,
        isVisible,
        isRequired,
        regex,
        customMsg
    } = fieldValidation;
    const errorClass =
    isDirty && isValidOrNot(isValid, isRequired, regex, customMsg)
        ? 'error-holder'
        : '';

    const value = data === 0 ? '0.00' : data;

    return !isVisible ? null : (
        <React.Fragment>
            {fieldLabel && (
                <label htmlFor={htmlFor}>
                    {fieldLabel} {isRequired && <span className="required">*</span>}
                </label>
            )}
            <div className={className + ' ' + errorClass}>
                <Input
                    {...attribute}
                    maxLength={fieldValidation.maxLength}
                    value={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    name={fieldName}
                    id={htmlFor}
                    type="number"
                    onKeyDown={e => e.keyCode === 69 && e.preventDefault()}
                />
                {renderErrorHtml(
                    isRequired,
                    isValidType,
                    isDirty,
                    fieldLabel,
                    customMsg,
                    htmlLabel
                )}
            </div>
        </React.Fragment>
    );
});
CurrencyBoxGroup.displayName = 'CurrencyBoxGroup';

export default CurrencyBoxGroup;
