import React from 'react';
import { useTranslation } from 'react-i18next';

interface BreadcrumbProps {
    membershipConfiguration;
    goToStep;
    isEdit;
}

const Breadcrumb: React.SFC<BreadcrumbProps> = ({
    membershipConfiguration,
    goToStep,
    isEdit
}) => {
    const { stepIndex, stepLatest } = membershipConfiguration;

    const { t } = useTranslation();

    return (
        <div className="sticky-breadcrum">
            <div className="paging-steps d-flex">
                <a
                    className={`step active ${
                        stepLatest > 0 && stepIndex > 1 ? 'go-to-step' : ''
                    }`}
                    onClick={() => stepLatest > 0 && stepIndex !== 1 && goToStep(1)}
                >
                    <div className="number-step">
                        <span>01</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.PACKAGE_PLAN')}
                    </p>
                </a>
                <div
                    className={`section-dots ${
                        stepLatest > 1 || stepIndex === 2 ? 'dots-primary' : ''
                    }`}
                >
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
                <a
                    className={`step ${
                        stepLatest > 1 || stepIndex === 2
                            ? `active ${stepIndex !== 2 ? 'go-to-step' : ''}`
                            : ''
                    }`}
                    onClick={() => stepLatest > 1 && stepIndex !== 2 && goToStep(2)}
                >
                    <div className="number-step">
                        <span>02</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.PROMOTION_ADD_ON')}
                    </p>
                </a>
                <div
                    className={`section-dots ${
                        stepLatest > 2 || stepIndex === 3 ? 'dots-primary' : ''
                    }`}
                >
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
                {!isEdit && <a
                    className={`step ${
                        stepLatest > 2 || stepIndex === 3
                            ? `active ${stepIndex !== 3 ? 'go-to-step' : ''}`
                            : ''
                    }`}
                    onClick={() => stepLatest > 2 && stepIndex !== 3 && goToStep(3)}
                >
                    <div className="number-step">
                        <span>03</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.PERSONAL_DETAILS')}
                    </p>
                </a>}
                {isEdit && <a
                    className={`step ${
                        stepLatest > 2 || stepIndex === 3
                            ? `active ${stepIndex !== 3 ? 'go-to-step' : ''}`
                            : ''
                    }`}
                    onClick={() => stepLatest > 2 && stepIndex !== 3 && goToStep(3)}
                >
                    <div className="number-step">
                        <span>03</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.PAYMENT_DETAILS')}
                    </p>
                </a>}
                <div
                    className={`section-dots ${
                        stepLatest > 3 || stepIndex === 4 ? 'dots-primary' : ''
                    }`}
                >
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
                {!isEdit && <a
                    className={`step ${
                        stepLatest > 3 || stepIndex === 4
                            ? `active ${stepIndex !== 4 ? 'go-to-step' : ''}`
                            : ''
                    }`}
                    onClick={() => stepLatest > 3 && stepIndex !== 4 && goToStep(4)}
                >
                    <div className="number-step">
                        <span>04</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.PAYMENT_DETAILS')}
                    </p>
                </a>}
                {isEdit && <a
                    className={`step ${
                        stepLatest > 3 || stepIndex === 4
                            ? `active ${stepIndex !== 4 ? 'go-to-step' : ''}`
                            : ''
                    }`}
                    onClick={() => stepLatest > 3 && stepIndex !== 4 && goToStep(4)}
                >
                    <div className="number-step">
                        <span>04</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.CHANGE_SUMMARY')}
                    </p>
                </a>}
                <div
                    className={`section-dots ${
                        stepLatest > 4 || stepIndex === 5 ? 'dots-primary' : ''
                    }`}
                >
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
                <a
                    className={`step ${
                        stepLatest > 4 || stepIndex === 5
                            ? `active ${stepIndex !== 5 ? 'go-to-step' : ''}`
                            : ''
                    }`}
                    onClick={() => stepLatest > 4 && stepIndex !== 1 && goToStep(5)}
                >
                    <div className="number-step">
                        <span>05</span>
                    </div>
                    <p className="text-step">
                        {t('PAGE.MEMBERSHIPS.BREADCRUMB.MEMBERS_SUMMARY')}
                    </p>
                </a>
            </div>
        </div>
    );
};

export default Breadcrumb;
