import React from 'react';
import Slider from 'react-rangeslider';
import * as DefaultConstants from 'constants/DefaultConstants';
import 'react-rangeslider/lib/index.css';
import { t } from 'util/I18nMessages';

const template = _this => {
    const { boxHeight } = _this.state;
    const style = {
        height: `${boxHeight}px`
    };
    return (
        <>
            <p className="title"><span className="text">{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.INSTRUCTION')}</span><span className="icon-help-ico float-right" onClick={() => _this.openHelpDialog()}>{t('COMMON.HELP')}</span></p>

            <div className="up-logo system-config" style={style}>
                <div className="box-img">
                    <a id="img-store">
                        <img src="" />
                    </a>
                    <div className={_this.state.invisibleHolder ? "holder invisible-holder" : "holder"}></div>
                    <span className="upload-title icon-image-ico">{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.UPLOAD_IMAGE_HERE')}</span>
                    <div className="change-img">
                        <button onClick={() => _this.zoomOut()} className="btn btn-zoom-in icon-zoom-in-ico"></button>
                        <button onClick={() => _this.zoomIn()} className="btn btn-zoom-out icon-zoom-out-ico"></button>
                        <div className="uou-rangeslider">
                            <Slider
                                min={0}
                                max={100}
                                step={1}
                                value={_this.state.zoom}
                                tooltip={false}
                                onChange={(e) => _this.changeZoom(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <p>{t('PAGE.CONFIG.APPEARANCE_SETTING.CHANGE_LOGO.UPLOAD_LOGO_INSTRUCTION', {filesize: DefaultConstants.MAX_FILESIZE_LOGO_UPLOAD})}</p>
            <div className="saveColor">
                <button onClick={() => _this.resetAvatar()} className="btn btn-dark">{t('BUTTON.RESTORE_DEFAULTS')}</button>
                {_this.state.showDiscardBtn ? <button onClick={() => _this.cancelAvatar()} className="btn btn-secondary">{t('BUTTON.DISCARD')}</button> : null}
                <button disabled={!_this.state.showDiscardBtn} onClick={() => _this.saveAvatar()} className="btn btn-primary">{t('BUTTON.SAVE')}</button>
            </div>
        </>
    );
};

export default template;
