import { Component } from 'react';
import { connect } from 'react-redux';
import * as Interface from 'interfaces';
import * as Utils from 'util/ControlUtils';
import store from 'reduxs/index';
import { userSignOut } from 'reduxs/actions/Auth';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';
import observer from 'util/Observer';
import * as DefaultConstants from 'constants/DefaultConstants';
import { withTranslation, WithTranslation } from 'react-i18next';
import template from './template';
import ThisInterface from './interface';
class ChangePasswordPage extends Component<Interface.PagePropsInterface & RouteComponentProps & WithTranslation, ThisInterface['state']> {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            currentPassword: '',
            currentPasswordType: 'password',
            newPassword: '',
            newPasswordType: 'password',
            confirmNewPassword: '',
            confirmNewPasswordType: 'password',
            messages: [],
            newPasswordValid: false,
            newPasswordErrors: [],
            errors: [],
            isRequiredChangeSuccess: false,
            isOnFocusNewPassword: false
        };
    }

    componentDidMount() {
        let messages: any = [];
        const { t } = this.props;

        // messages = {...messages, notSameLoginName: "Not be the same as the user’s login name."}; // It should not be the same as the user’s login name.
        messages = { ...messages, minimumCharacterCount: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_8_CHARACTERS') }; // It should be at least eight characters long.
        // It should include uppercase and lowercase letters, numbers, special characters or punctuation marks.
        messages = { ...messages, minimumUpperCaseLetter: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_UPPERCASE') };
        messages = { ...messages, minimumLowerCaseLetter: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_LOWER') };
        messages = { ...messages, minimumDigitCharacterCount: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_DIGIT') };
        messages = { ...messages, minimumSpecialCharacter: t('PAGE.CHANGE_FORGOT_PASSWORD.AT_LEAST_1_SPECIAL_OR_PUNCTUATION') };

        this.setState({ messages });
        this.clearAllData();
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps && nextProps.oidc.user && nextProps.oidc.user.profile) {
            return {
                username: nextProps.oidc.user.profile.username
            }
        }
        return null;
    }

    validatePassword(password) {
        let newPasswordErrors: any = this.state.newPasswordErrors;
        let newPasswordValid = false;

        // newPasswordErrors.notSameLoginName = username !== password;
        newPasswordErrors.minimumCharacterCount = !Utils.isMinimumCharacterCount(password);
        newPasswordErrors.minimumUpperCaseLetter = !Utils.IsMinimumUpperCaseLetter(password);
        newPasswordErrors.minimumLowerCaseLetter = !Utils.IsMinimumLowerCaseLetter(password);
        newPasswordErrors.minimumDigitCharacterCount = !Utils.IsMinimumDigitCharacterCount(password);
        newPasswordErrors.minimumSpecialCharacter = !Utils.IsMinimumSpecialCharacter(password);

        if (password !== ''
            && !newPasswordErrors.minimumCharacterCount
            && !newPasswordErrors.minimumUpperCaseLetter
            && !newPasswordErrors.minimumLowerCaseLetter
            && !newPasswordErrors.minimumDigitCharacterCount
            && !newPasswordErrors.minimumSpecialCharacter) {
            newPasswordValid = true;
        }

        this.setState({ newPasswordErrors, newPasswordValid });
    }

    handleNewPassword(event) {
        const newPassword = event.password;
        this.setState({ newPassword });
        this.validatePassword(newPassword);
        if (!localStorage.isRequiredChangePassword || localStorage.isRequiredChangePassword === "0") {
            observer.publish('flagUnsavedChangeEvent', true);
        }
    }

    handleSave(event) {
        let errors: any = [];
        const { t } = this.props;

        if (this.state.currentPassword === '') {
            errors.currentPassword = "OPERATOR_CHANGE_PASSWORD.CURRENT_PASSWORD_REQUIRED";
        }

        if (this.state.newPassword === '') {
            errors.newPassword = "OPERATOR_CHANGE_PASSWORD.NEW_PASSWORD_REQUIRED";
        }
        else if (this.state.newPassword === this.state.currentPassword) {
            errors.newPassword = "OPERATOR_CHANGE_PASSWORD.NEW_PASSWORD_IS_MATCH_CURRENT_PASSWORD";
        }
        else if (!Utils.isMinimumCharacterCount(this.state.newPassword)
            || !Utils.IsMinimumUpperCaseLetter(this.state.newPassword)
            || !Utils.IsMinimumLowerCaseLetter(this.state.newPassword)
            || !Utils.IsMinimumDigitCharacterCount(this.state.newPassword)
            || !Utils.IsMinimumSpecialCharacter(this.state.newPassword)) {
            errors.newPassword = "OPERATOR_CHANGE_PASSWORD.NEW_PASSWORD_VALIDATION_FAILED";
        }

        if (this.state.newPassword.indexOf(this.state.username) > -1) {
            errors.newPassword = "OPERATOR_CHANGE_PASSWORD.NEW_PASSWORD_MUST_NOT_CONTAINS_USERNAME";
        }

        if (this.state.confirmNewPassword === '') {
            errors.confirmNewPassword = "OPERATOR_CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_REQUIRED";
        }
        else if (this.state.confirmNewPassword !== this.state.newPassword) {
            errors.confirmNewPassword = "OPERATOR_CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD_NOT_MATCH_NEW_PASSWORD";
        }

        if (Object.keys(errors).length > 0) {
            event.preventDefault();
            window.scrollTo(0, 0);
        }
        else if (this.state.username) {
            Swal.fire({
                ...DefaultConstants.SWAL_COMMON_STYLE,
                text: t('PAGE.CHANGE_FORGOT_PASSWORD.CONFIRM_CHANGE_PASSWORD'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: t('BUTTON.YES'),
                cancelButtonText: t('BUTTON.NO'),
            }).then((result) => {
                if (result.value) {
                    Swal.fire({
                        html: t('COMMON.PROCESSING') + '...<br/><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>',
                        showConfirmButton: false,
                    });
                    const { conman } = this.props;
                    this.props.conmanService!.changeNewPassword(conman!.operator.id, this.state)
                        .then(res => {
                            if (res.isSuccess === true) {
                                this.clearAllData();
                                Swal.fire({
                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                    type: 'success',
                                    html: t('PAGE.CHANGE_FORGOT_PASSWORD.CHANGE_PASSWORD_SUCCESS'),
                                    confirmButtonText: t('BUTTON.CLOSE')
                                }).then(() =>
                                    observer.publish('flagUnsavedChangeEvent', false)
                                ).then(() => {
                                    localStorage.isRequiredChangePassword = "0";
                                    this.setState({ isRequiredChangeSuccess: true });
                                });
                            }
                            else if (localStorage.isRequiredChangePassword && localStorage.isRequiredChangePassword === "1") {
                                Swal.fire({
                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                    type: 'warning',
                                    html: t('PAGE.LOGIN.LOGIN_SUMMARY_ERROR'),
                                    confirmButtonText: t('BUTTON.OK')
                                });
                            }
                            else if (res.isSuccess === false) {
                                errors.currentPassword = res.message;
                                this.setState({ errors });
                                Swal.fire({
                                    ...DefaultConstants.SWAL_COMMON_STYLE,
                                    type: 'warning',
                                    html: t('PAGE.CHANGE_FORGOT_PASSWORD.CHANGE_PASSWORD_FAILED'),
                                    confirmButtonText: t('BUTTON.CLOSE')
                                });
                            }
                        });
                }
            });
        }

        this.setState({ errors });
    }

    handleFocusNewPassword() {
        this.setState({
            isOnFocusNewPassword: !this.state.isOnFocusNewPassword
        })
    }

    handleInputChange(key, event) {
        const { value } = event.target;

        this.setState(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        });
        if (!localStorage.isRequiredChangePassword || localStorage.isRequiredChangePassword === "0") {
            if (event.target.value !== '') {
                observer.publish('flagUnsavedChangeEvent', true);
            }
            else if (this.state.currentPassword === ''
                && this.state.newPassword === ''
                && this.state.confirmNewPassword === '') {
                observer.publish('flagUnsavedChangeEvent', false);
            }
        }
    }

    handleReset(ReactPasswordStrength) {
        ReactPasswordStrength.clear();
        this.clearAllData();
    }

    handleCancel() {
        this.props.history.push('/edit-profile');
    }

    clearAllData() {
        let newPasswordErrors: any = [];

        newPasswordErrors = { ...newPasswordErrors, notSameLoginName: true };
        newPasswordErrors = { ...newPasswordErrors, minimumCharacterCount: true };
        newPasswordErrors = { ...newPasswordErrors, minimumUpperCaseLetter: true };
        newPasswordErrors = { ...newPasswordErrors, minimumLowerCaseLetter: true };
        newPasswordErrors = { ...newPasswordErrors, minimumDigitCharacterCount: true };
        newPasswordErrors = { ...newPasswordErrors, minimumSpecialCharacter: true };

        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errors: [],
            newPasswordErrors
        });
    }

    showHideCurrentPassword(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            currentPasswordType: this.state.currentPasswordType === 'text' ? 'password' : 'text'
        })
    }

    showHideNewPassword(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            newPasswordType: this.state.newPasswordType === 'text' ? 'password' : 'text'
        })
    }

    showHideConfirmNewPassword(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            confirmNewPasswordType: this.state.confirmNewPasswordType === 'text' ? 'password' : 'text'
        })
    }

    render() {
        if (this.state.isRequiredChangeSuccess && localStorage.isRequiredChangePassword) {
            store.dispatch<any>(userSignOut()); /* eslint @typescript-eslint/no-explicit-any: 0 */
            this.props.history.push('/');
        }

        return template(this);
    }
}

const mapStateToProps = ({ conman, oidc }: Interface.StateToPropInterface) => {
    return { conman, oidc };
};

export default withTranslation()(connect(mapStateToProps)(ChangePasswordPage));
