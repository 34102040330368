import React from 'react';
import { FormSelect, FormInput } from 'components/Form';
import { AddressDetailsModel, AddressDetailsFieldsModel } from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';

interface Props {
    errors: any;        
    countries: any;
    addressDetails: AddressDetailsModel;  
    addressDetailsFieldConfigs: AddressDetailsFieldsModel;
    onInputChange: (key: string, value: string) => void;    
    onSelectOptionChange: (key: string, value: any) => void;    
    onCheckboxChange: (key: string, value: boolean) => void;
}

const AddressDetails = ({
    errors,    
    countries,
    addressDetails,
    addressDetailsFieldConfigs,
    onInputChange,
    onSelectOptionChange,
    onCheckboxChange,

}: Props ) => {   
    let {
        addressNumber,
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postCode,
        goneAway
    } = addressDetails;
    let {
        addressNumberConfig,        
        addressLine1Config,
        addressLine2Config,
        cityConfig,
        stateConfig,
        countryConfig,
        postCodeConfig,
        goneAwayConfig        
    } = addressDetailsFieldConfigs;              
    const { t } = useTranslation();
    return(
        <div className="personal-group">
            <div className="row">
                <div className="col-md-6">
                    {addressNumberConfig.isVisible &&      
                            <FormInput                            
                                id="addressNumber"
                                inputClass="form-control"
                                value={addressNumber}
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NUMBER_NAME')}                                                    
                                maxlength={addressNumberConfig.maxLength}
                                require={addressNumberConfig.isRequired}
                                isValid={addressNumberConfig.isRequired && addressNumber.length > 0}
                                validMsg={t(errors.addressNumber)}
                                onChange={(event) => onInputChange("addressNumber", event!.target.value)} />
                    }                        
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {addressLine1Config.isVisible &&      
                            <FormInput                            
                                id="addressLine1"
                                inputClass="form-control"                                                                          
                                value={addressLine1}        
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.LINE_1')}         
                                maxlength={addressLine1Config.maxLength}
                                require={addressLine1Config.isRequired}                                
                                isValid={addressLine1Config.isRequired && addressLine1.length > 0}                                                                                        
                                validMsg={t(errors.addressLine1)}
                                onChange={(event) => onInputChange("addressLine1", event!.target.value)} />
                    }  
                </div>
                <div className="col-md-6">
                    {addressLine2Config.isVisible &&      
                            <FormInput                            
                                id="addressLine2"
                                inputClass="form-control"                                
                                value={addressLine2}
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.LINE_2')}                                                    
                                maxlength={addressLine2Config.maxLength}
                                require={addressLine2Config.isRequired}   
                                isValid={addressLine2Config.isRequired && addressLine2.length > 0}
                                validMsg={t(errors.addressLine2)}
                                onChange={(event) => onInputChange("addressLine2", event!.target.value)} />
                    } 
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {cityConfig.isVisible &&      
                            <FormInput                            
                                id="city"
                                inputClass="form-control"                                
                                value={city}
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.CITY_TOWN')}                                                    
                                maxlength={cityConfig.maxLength}
                                require={cityConfig.isRequired}                               
                                isValid={cityConfig.isRequired && city.length > 0}                        
                                validMsg={t(errors.city)}
                                onChange={(event) => onInputChange("city", event!.target.value)} />
                    } 
                </div>
                <div className="col-md-6">                       
                    {stateConfig.isVisible &&
                            <FormInput                            
                                id="state"
                                inputClass="form-control"                                
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.STATE_COUNTY')}                                                    
                                maxlength={stateConfig.maxLength}
                                require={stateConfig.isRequired}        
                                value={state}
                                isValid={stateConfig.isRequired && state.length > 0}                        
                                validMsg={t(errors.state)}
                                onChange={(event) => onInputChange("state", event!.target.value)} />                       
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {postCodeConfig.isVisible &&      
                            <FormInput                            
                                id="postCode"
                                inputClass="form-control"                                
                                value={postCode}
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.POSTCODE')}                                                    
                                maxlength={postCodeConfig.maxLength}
                                require={postCodeConfig.isRequired} 
                                isValid={postCodeConfig.isRequired && postCode.length > 0}                        
                                validMsg={t(errors.postCode)}
                                onChange={(event) => onInputChange("postCode", event!.target.value)} />
                    } 
                </div>
                <div className="col-md-6 gr-gone-away">                       
                    {countryConfig.isVisible &&
                            <FormSelect
                                id="country"
                                name="country"
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.COUNTRY')}
                                disabled={countries.length === 0}
                                require={countryConfig.isRequired}
                                onChange={(event) => onSelectOptionChange("country", countries.find(country => country.value === event.value))}
                                value={country}
                                options={countries}
                                errorMsg={t(errors.country)}
                            />
                    }
                        
                    {goneAwayConfig.isVisible &&
                        <div className="form-check form-group">
                            <div>
                                <input id="isGoneAway" type="checkbox" checked={goneAway}  onChange={(event) => onCheckboxChange("goneAway", event.target.checked)} />
                                <label className="icon-checked-ico" htmlFor="isGoneAway"> <span>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.GONE_AWAY')}</span></label>
                                {goneAwayConfig.isRequired && <span className="text-primary bold">&nbsp;*</span> }
                            </div>
                            {errors.goneAway && <span className="text-danger input-error">{t(errors.goneAway)}</span>}
                        </div>
                    }
                </div>
            </div>
        </div>
    )    
}

export default AddressDetails;