export const DATE_FORMAT_LIST = [
    {
        format: 'dd/mm/yyyy',
        maskedInput: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    },
    {
        format: 'dd-mm-yyyy',
        maskedInput: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    },
    {
        format: 'mm/dd/yyyy',
        maskedInput: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
    },
    {
        format: 'mm-dd-yyyy',
        maskedInput: [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    },
    {
        format: 'yyyy/dd/mm',
        maskedInput: [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]
    },
    {
        format: 'yyyy-dd-mm',
        maskedInput: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
    },
    {
        format: 'yyyy/mm/dd',
        maskedInput: [/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]
    },
    {
        format: 'yyyy-mm-dd',
        maskedInput: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
    }
]