export default class Role {
    id = '';
    name = '';
    type = {
        id: '',
        name: ''
    };
}

export interface OptionModel {
    value: string;
    label: string;
}

export class SubTenantRoleModel {
    name = '';
    id = '';
    firstKeyId = '';
    secondKeyId = '';
    static clone = (obj: SubTenantRoleModel) => {
        return {
            name: obj.name,
            id: obj.id,
            firstKeyId: obj.firstKeyId,
            secondKeyId: obj.secondKeyId
        };
    };
}
