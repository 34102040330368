import React from 'react';
import { FormSelect, FormDatePicker } from 'components/Form';
import { useTranslation } from 'react-i18next';

interface FormInfoProps {
    dateOfBirthConfig;
    membershipSearchFilter;
    membershipConfiguration;
    errors;
    clubs;
    applicationTypes;
    salePeople;
    toggleFilter;
    filterButtonRef;
    handleChange;
    handleInputChange;
    handleDateChange;
}

const FormInfo: React.SFC<FormInfoProps> = ({
    dateOfBirthConfig,
    membershipSearchFilter,
    membershipConfiguration,
    errors,
    clubs,
    applicationTypes,
    salePeople,
    toggleFilter,
    filterButtonRef,
    handleChange,
    handleInputChange,
    handleDateChange
}) => {
    function memberValue(membershipSearchFilter, key, arrList) {
        return membershipSearchFilter[key]
            ? arrList.find(e => e.value === membershipSearchFilter[key])
            : arrList[0];
    }
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="list-page">
                <p className="title-content room-name text-uppercase select-add-on-title--custom">
                    {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.SELECT_PACKAGE_PLAN')}
                </p>
                <div className="list-page-content ml-auto d-flex">
                    <div ref={filterButtonRef} className="box-btn-filter d-flex">
                        <button
                            className="btn btn-primary btn-height-sm btn-filter not-after"
                            onClick={event => toggleFilter(event)}
                        >
                            <i className="icon icon-filter" />
                            <span>{t('PAGE.MEMBERSHIPS.CREATE.FILTER')}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="section-select">
                <FormSelect
                    id="club"
                    name="club"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CLUB')}
                    disabled={clubs.length === 0}
                    require
                    onChange={event => handleChange('clubId', event)}
                    value={memberValue(membershipSearchFilter, 'clubId', clubs)}
                    options={clubs}
                    errorMsg={errors.clubId}
                />
                <FormSelect
                    id="salePerson"
                    name="salePerson"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.SALE_PERSON')}
                    disabled={salePeople.length === 0}
                    require
                    onChange={event => handleChange('salePersonId', event)}
                    value={memberValue(
                        membershipSearchFilter,
                        'salePersonId',
                        salePeople
                    )}
                    options={salePeople}
                    errorMsg={errors.salePersonId}
                />
                <FormSelect
                    id="applicationType"
                    name="applicationType"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.APPLICATION_TYPE')}
                    disabled={applicationTypes.length === 0}
                    require
                    onChange={event => handleChange('applicationTypeId', event)}
                    value={memberValue(
                        membershipSearchFilter,
                        'applicationTypeId',
                        applicationTypes
                    )}
                    options={applicationTypes}
                    errorMsg={errors.applicationTypeId}
                />
                {dateOfBirthConfig.isVisible &&
                    <FormDatePicker
                        id="date-of-birth"
                        name="date-of-birth"
                        label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH')}
                        selectKey="dateOfBirth"
                        className="datepicker-wrapper"
                        maxDate={new Date()}
                        required={dateOfBirthConfig.isRequired}
                        dateFormat={membershipConfiguration.newMemberDOBFormat}
                        selectedDate={membershipSearchFilter.dateOfBirth}
                        onChange={value => handleDateChange(value, 'dateOfBirth')}
                        errorMsg={t(errors.dateOfBirth)}
                    />
                }
                <div className="form-group">
                    <label>
                        {t('PAGE.MEMBERSHIPS.CREATE.STEP_1.PROMOTION_CODE')}
                    </label>
                    <div className="input-group">
                        <input
                            className="form-control"
                            type="text"
                            name="code"
                            onChange={event => handleInputChange('promotionCode', event)}
                        />
                        <button className="btn btn-primary btn--apply">{t('BUTTON.APPLY')}</button>
                    </div>
                </div>
                <FormDatePicker
                    id="start-date"
                    name="start-date"
                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE')}
                    selectKey="startDate"
                    className="datepicker-wrapper"
                    minDate={new Date()}
                    required
                    dateFormat={membershipConfiguration.newMemberTimeFormat}
                    selectedDate={membershipSearchFilter.startDate}
                    onChange={value => handleDateChange(value, 'startDate')}
                    errorMsg={errors.startDate}
                />
            </div>
        </React.Fragment>
    );
};

export default FormInfo;
