
import FieldModel from 'models/FieldConfig';
import * as Interface from 'interfaces';
import ChangePaymentDetailModel from 'models/ChangePaymentDetail';
import MemberSummaryModel from 'models/MemberSummary';

export enum Steps {
    Form = 0,
    Review = 1
}

export enum PaymentMethods {
    Credit = 'Credit Card',
    Debit = 'Direct Debit'
}

export enum EmailDetail {
    existEmail = 'existEmail',
    newEmail = 'newEmail',
    noEmail = 'noEmail'
}

export default interface ThisInterface {
    props: {
        memberData: MemberSummaryModel;
        membershipService: Interface.PagePropsInterface['membershipService'];
        onCancel: () => void;
        t?: any;
    };
    state: {
        paymentDetails: ChangePaymentDetailModel;
        basePaymentDetails: ChangePaymentDetailModel;
        planPaymentMethods: { id: string; name: string }[];
        step: Steps;
        banks: { id: string; name: string }[];
        fields: FieldModel[];
        isLoading: boolean;
        communicationEmail: {
            email: string;
            emailError: boolean;
        };
        disabledNext: boolean;
        disabledFinish: boolean;
        validations: {
            [key: string]: FieldModel;
        };
        isShowCreditCardModify: boolean;
    };
    review: () => void;
    viewForm: () => void;
    finish: () => void;
    handleFieldChanged: (key: string, value: any) => void;
    handlePaymentMethodChanged: (methodId: string) => void;
    handleChangeToogle: () => void;
};