import React from 'react';
import { connect } from 'react-redux';
import template from './template';
import ThisInterface from './interface';
import store from 'reduxs/index';
import * as MembershipActions from 'reduxs/actions/NewMembership';
import * as Utils from 'util/ControlUtils';
import MembershipSearchFilter from 'models/MembershipSearchFilter';
import MembershipConfiguration from 'models/MembershipConfiguration';
import PackagePlan from 'models/PackagePlan';
import OptionModel from 'models/Option';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FieldConfig } from 'models/PersonalDetails';
import * as FieldKeys from 'util/FieldConfiguration/Keys';
import { getFieldConfig } from 'util/FieldConfiguration';
import observer from 'util/Observer';
import _ from 'lodash';
import {
    GENERAL_DATE_FORMAT,
    GENERAL_DATABASE_DATE_FORMAT
} from 'constants/Constants';
import * as Route from 'pages/RouteLoader';
import { DisplayDateFormat } from 'util/ControlUtils';


class SelectPackagePlan extends React.Component<ThisInterface["props"]  & WithTranslation , ThisInterface["state"]> {
    filterRef;
    filterButtonRef;
    constructor(props: ThisInterface["props"] & WithTranslation) {
        super(props);

        this.state = {
            dateOfBirthConfig: new FieldConfig(),
            membershipSearchFilter: new MembershipSearchFilter(),
            membershipConfiguration: new MembershipConfiguration(),
            selectedPackagePlan: new PackagePlan(),
            clubs: [],
            allPackagePlans: [],
            packagePlans: [],
            salePeople: [],
            applicationTypes: [],
            reciprocalAccesses: [],
            minimumContracts: [],
            accesses: [],
            paymentMethods: [],
            isShowFilter: false,
            isLoading: false,
            errors: []
        }
        this.filterRef = React.createRef();
        this.filterButtonRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutSide);
        observer.publish('flagUnsavedChangeEvent', true);
        window.scrollTo(0, 0);
        const { newMembership } = this.props;
        let {
            membershipSearchFilter,
            membershipConfiguration,
            selectedPackagePlan } = this.state;
        if (newMembership && newMembership.selectedSubTenant) {
            membershipSearchFilter.subTenantId = newMembership.selectedSubTenant.id;
            if (newMembership && newMembership.selectedPackagePlan) {
                membershipSearchFilter = newMembership.membershipSearchFilter;
                membershipConfiguration = newMembership.membershipConfiguration;
                selectedPackagePlan = newMembership.selectedPackagePlan;
            }
            else {
                membershipConfiguration.locale = 'en-US';
                membershipConfiguration.newMemberDOBFormat = GENERAL_DATE_FORMAT;
                membershipConfiguration.newMemberTimeFormat = GENERAL_DATE_FORMAT;
            }
            this.setState({
                membershipSearchFilter,
                membershipConfiguration,
                selectedPackagePlan
            }, () => {
                this.getInitData();
            })
        }
    }

    handleClickOutSide = e => {
        if (
            this.filterRef &&
            this.filterRef.current &&
            !this.filterRef.current.contains(e.target) &&
            this.filterButtonRef &&
            this.filterButtonRef.current &&
            !this.filterButtonRef.current.contains(e.target)
        ) {
            this.setState({
                isShowFilter: false
            });
        }
    };

    getInitData() {
        const _this = this;
        const {
            membershipService,
            userService,
            isEdit } = _this.props;
        const {
            membershipConfiguration,
            membershipSearchFilter,
            membershipSearchFilter: { subTenantId } } = _this.state;


        let rightName = Route.AddNewMemberRoute.rightName;
        if (isEdit) {
            rightName = Route.ChangeMembershipRoute.rightName;
        }

        _this.setState({ isLoading: true })

        Promise.all([
            userService.getSystemConfigs(subTenantId, rightName),
            membershipService.getClubs(subTenantId, rightName),
            membershipService.getApplicationTypes(subTenantId, rightName),
            membershipService.getFieldsConfiguration(subTenantId, rightName)
        ]).then(([res1, res2, res3, res4]) => {
            const systemConfigs: any = res1;
            if (systemConfigs) {
                const dobFormat = systemConfigs.find(e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DOB_FORMAT);
                if (dobFormat) {
                    membershipConfiguration.newMemberDOBFormat = dobFormat.value;
                } else {
                    membershipConfiguration.newMemberDOBFormat = GENERAL_DATE_FORMAT;
                }
                const dateFormat = systemConfigs.find(e => e.key === FieldKeys.ADDMEMBER_ADD_NEW_DATE_FORMAT);
                if (dateFormat) {
                    membershipConfiguration.newMemberTimeFormat = dateFormat.value;
                } else {
                    membershipConfiguration.newMemberTimeFormat = GENERAL_DATE_FORMAT;
                }
            }
            const clubs: OptionModel[] = res2.data.map(e => ({ value: e.id, label: e.name, legacyId: e.legacyId }));
            if (clubs.length > 0 && clubs[0].value) {
                if (!membershipSearchFilter.clubId) {
                    membershipSearchFilter.legacyId = clubs[0].legacyId;
                    membershipSearchFilter.clubId = clubs[0].value;
                    membershipSearchFilter.clubName = clubs[0].label;
                }
                const startDate = DisplayDateFormat(membershipSearchFilter.startDate, GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00Z';
                if (!membershipSearchFilter.startDate) {
                    this.setState({
                        isLoading: false,
                        packagePlans: []
                    });
                    return;
                }
                Promise.all([
                    membershipService.getSalePeople(subTenantId, membershipSearchFilter.clubId, rightName),
                    membershipService.getPackagePlans(subTenantId, membershipSearchFilter.clubId, startDate, rightName)
                ]).then(([res1, res2]) => {

                    let salePeople: OptionModel[] = res1.data.map(e => ({ value: e.id, label: e.name }));
                    let allPackagePlans = res2.data || [];
                    allPackagePlans = allPackagePlans.map(obj => ({
                        ...obj,
                        minimumContractValue: `${obj.minimumContract.value} ${obj.minimumContract.unit.name}`
                    }));

                    if (!membershipSearchFilter.salePersonId && salePeople.length > 0) {
                        membershipSearchFilter.salePersonId = salePeople[0].value;
                        membershipSearchFilter.salePersonName = salePeople[0].label;
                    }
                    store.dispatch(MembershipActions.setAllPackagePlans(allPackagePlans));

                    this.setState({
                        salePeople,
                        membershipSearchFilter,
                        allPackagePlans,
                        packagePlans: allPackagePlans,
                        isLoading: false
                    }, () => {
                        if (_this.props.newMembership.selectedPackagePlan) {
                            _this.searchPackagePlan();
                        } else {
                            this.onCountFilter()
                        }
                    })
                }).catch(() => {
                    this.setState({
                        isLoading: false,
                        packagePlans: []
                    });
                });
            }

            const applicationTypes: OptionModel[] = res3.data.map(e => ({ value: e.id, label: e.name }));
            const applicationType = applicationTypes.find(e => e.label.toLowerCase() === 'paper contract');
            if (!membershipSearchFilter.applicationTypeId && applicationType) {
                membershipSearchFilter.applicationTypeId = applicationType.value;
            }

            const dateOfBirthConfig = getFieldConfig(res4.data, FieldKeys.ADDMEMBER_BIRTH_DATE_STEP_1);

            _this.setState({
                dateOfBirthConfig,
                clubs,
                applicationTypes,
                membershipSearchFilter,
                membershipConfiguration
            });
        }).catch(() => {
            _this.setState({
                isLoading: false,
                dateOfBirthConfig: new FieldConfig(),
                clubs: [],
                applicationTypes: [],
                membershipSearchFilter: new MembershipSearchFilter(),
                membershipConfiguration: new MembershipConfiguration(),
            });
        });
    }

    searchPackagePlan = () => {
        const { membershipSearchFilter: { selectedPackagePlanId } } = this.state;
        if (selectedPackagePlanId) {
            this.handleFilterPackagePlan();
        }
    }

    handleFilterPackagePlan = () => {
        const { allPackagePlans, membershipSearchFilter } = this.state;
        let result = allPackagePlans.filter(e => {
            return e.package.packageTier.name.indexOf(membershipSearchFilter.reciprocalAccessId) == 0
                && e.minimumContractValue.indexOf(membershipSearchFilter.minimumContractId) == 0
                && e.package.timeGroup.name.indexOf(membershipSearchFilter.accessId) == 0
                && e.paymentMethod.name.indexOf(membershipSearchFilter.paymentMethodId) == 0
        });

        this.setState({
            packagePlans: result
        }, () => { if (this.state.packagePlans.length > 0) this.onCountFilter(); })
    }

    handleClearFilter = () => {
        const { allPackagePlans, membershipSearchFilter } = this.state;
        membershipSearchFilter.reciprocalAccessId = '';
        membershipSearchFilter.minimumContractId = '';
        membershipSearchFilter.accessId = '';
        membershipSearchFilter.paymentMethodId = '';

        this.setState({
            membershipSearchFilter,
            packagePlans: allPackagePlans
        }, () => this.onCountFilter())

    }

    toggleFilter = (e) => {
        e.preventDefault();
        this.setState({
            isShowFilter: !this.state.isShowFilter
        });
    }

    handleChange = (key, event) => {
        if (!event.value) return;
        const { membershipService, isEdit } = this.props;
        const {
            clubs,
            salePeople } = this.state;

        let rightName = Route.AddNewMemberRoute.rightName;
        if (isEdit) {
            rightName = Route.ChangeMembershipRoute.rightName;
        }
        let membershipSearchFilter = this.cloneMembershipSearchFilter(this.state.membershipSearchFilter);
        let newValue = event.value;
        if (key.includes('clubId')) {
            this.setState({ isLoading: true });
            const startDate = DisplayDateFormat(membershipSearchFilter.startDate, GENERAL_DATABASE_DATE_FORMAT) + 'T00:00:00Z';
            Promise.all([
                membershipService.getSalePeople(membershipSearchFilter.subTenantId, newValue, rightName),
                membershipService.getPackagePlans(membershipSearchFilter.subTenantId, newValue, startDate, rightName)
            ]).then(([res1, res2]) => {
                let salePersonList: OptionModel[] = res1.data.map(e => ({ value: e.id, label: e.name }));
                let clubList = clubs.filter(cl => cl.value === newValue);
                membershipSearchFilter.clubName = clubList.length > 0 ? clubList[0].label : '';
                if (salePersonList.length > 0) {
                    membershipSearchFilter.salePersonId = salePersonList[0] ? salePersonList[0].value : '';
                    membershipSearchFilter.salePersonName = salePersonList[0] ? salePersonList[0].label : '';
                }
                membershipSearchFilter.reciprocalAccessId = '';
                membershipSearchFilter.minimumContractId = '';
                membershipSearchFilter.accessId = '';
                membershipSearchFilter.paymentMethodId = '';

                let allPackagePlans = res2.data;
                allPackagePlans = allPackagePlans.map(obj => ({
                    ...obj,
                    minimumContractValue: `${obj.minimumContract.value} ${obj.minimumContract.unit.name}`
                }));
                store.dispatch(MembershipActions.setAllPackagePlans(allPackagePlans));
                this.setState({
                    salePeople,
                    membershipSearchFilter,
                    allPackagePlans,
                    packagePlans: allPackagePlans,
                    isLoading: false
                }, () => this.onCountFilter())
            });
        }
        if (key.includes('salePersonId')) {
            let salePersonName = salePeople.filter(s => s.value === newValue);
            membershipSearchFilter.salePersonId = salePersonName.length > 0 ? salePersonName[0].value : '';
            membershipSearchFilter.salePersonName = salePersonName.length > 0 ? salePersonName[0].label : '';
        }
        membershipSearchFilter[key] = newValue;
        this.setState({
            membershipSearchFilter
        });
    }

    cloneMembershipSearchFilter(membershipSearchFilterObj) {
        let membershipSearchFilter = new MembershipSearchFilter();
        membershipSearchFilter.salePersonId = membershipSearchFilterObj.salePersonId;
        membershipSearchFilter.salePersonName = membershipSearchFilterObj.salePersonName;
        membershipSearchFilter.applicationTypeId = membershipSearchFilterObj.applicationTypeId;
        membershipSearchFilter.reciprocalAccessId = membershipSearchFilterObj.reciprocalAccessId;
        membershipSearchFilter.minimumContractId = membershipSearchFilterObj.minimumContractId;
        membershipSearchFilter.clubId = membershipSearchFilterObj.clubId;
        membershipSearchFilter.clubName = membershipSearchFilterObj.clubName;
        membershipSearchFilter.legacyId = membershipSearchFilterObj.legacyId;
        membershipSearchFilter.accessId = membershipSearchFilterObj.accessId;
        membershipSearchFilter.paymentMethodId = membershipSearchFilterObj.paymentMethodId;
        membershipSearchFilter.promotionCode = membershipSearchFilterObj.promotionCode;
        membershipSearchFilter.startDate = membershipSearchFilterObj.startDate;
        membershipSearchFilter.dateOfBirth = membershipSearchFilterObj.dateOfBirth;
        membershipSearchFilter.operator = membershipSearchFilterObj.operator;
        membershipSearchFilter.subTenantId = membershipSearchFilterObj.subTenantId;
        membershipSearchFilter.selectedPackagePlanId = membershipSearchFilterObj.selectedPackagePlanId;
        return membershipSearchFilter;
    }

    handleFilterChange = (key, event) => {
        let membershipSearchFilter = this.cloneMembershipSearchFilter(this.state.membershipSearchFilter);
        membershipSearchFilter[key] = event.target.value;
        this.setState({
            membershipSearchFilter
        });
    }

    handleInputChange = (key, event) => {
        let membershipSearchFilter = this.cloneMembershipSearchFilter(this.state.membershipSearchFilter);
        membershipSearchFilter[key] = event.target.value;
        this.setState({
            membershipSearchFilter
        });
    }

    handleDateChange = (date, name) => {
        let membershipSearchFilter = this.cloneMembershipSearchFilter(this.state.membershipSearchFilter);
        switch (name) {
            case 'dateOfBirth':
                membershipSearchFilter[name] = date ? new Date(Utils.DisplayDateFormat(date, GENERAL_DATABASE_DATE_FORMAT)) : null;
                break;
            default:
                membershipSearchFilter[name] = date;
                break;
        }

        this.setState({ membershipSearchFilter }, () => {
            if (name === 'startDate') {
                this.getInitData();
            }
        });
    }

    isReadyToNextStep = () => {
        let errors: any = [];
        const { t } = this.props;
        const {
            dateOfBirthConfig,
            membershipSearchFilter: {
                clubId,
                salePersonId,
                applicationTypeId,
                dateOfBirth,
                startDate,

            },
            membershipConfiguration: {
                newMemberDOBFormat,
                newMemberTimeFormat } } = this.state;

        if (!clubId) {
            errors = { ...errors, clubId: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.CLUB_REQUIRED') };
        }

        if (!salePersonId) {
            errors = { ...errors, salePersonId: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.SALE_PERSON_REQUIRED') };
        }

        if (!applicationTypeId) {
            errors = { ...errors, applicationTypeId: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.APPLICATION_TYPE_REQUIRED') };
        }

        if (dateOfBirthConfig.isVisible) {
            if (dateOfBirthConfig.isRequired && !dateOfBirth) {
                errors = { ...errors, dateOfBirth: 'PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_REQUIRED' };
            }
            else if (dateOfBirth && !Utils.IsValidDate(dateOfBirth, newMemberDOBFormat)) {
                errors = { ...errors, dateOfBirth: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_INVALID')};
            }
            else if (dateOfBirth && !Utils.IsValidDateOfBirth(dateOfBirth)) {
                errors = { ...errors, dateOfBirth: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.DATE_OF_BIRTH_CAN_NOT_BE_FUTURE_DATE') };
            }
        }

        if (!startDate) {
            errors = { ...errors, startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_REQUIRED') };
        }
        else if (!Utils.IsValidDate(startDate, newMemberTimeFormat)) {
            errors = { ...errors, startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_INVALID') };
        }
        else if (!Utils.IsValidStartDate(startDate)) {
            errors = { ...errors, startDate: t('PAGE.MEMBERSHIPS.CREATE.STEP_1.START_DATE_CAN_NOT_BE_PAST_DATE') };
        }

        this.setState({
            errors
        });

        return Object.keys(errors).length === 0;
    }

    onPackagePlanSelected = (packagePlanId: string) => {
        if (this.isReadyToNextStep()) {
            const {
                packagePlans,
                selectedPackagePlan,
                membershipSearchFilter,
                membershipConfiguration
            } = this.state;

            let packagePlan = packagePlans.find(pl => pl.id == packagePlanId);
            selectedPackagePlan.plan = packagePlan;
            store.dispatch(MembershipActions.setSelectedPackagePlan(selectedPackagePlan));

            membershipSearchFilter.selectedPackagePlanId = packagePlanId;
            if (membershipConfiguration.stepLatest < 2) {
                membershipConfiguration.stepLatest = 2;
            }
            membershipConfiguration.stepIndex = 2;
            store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
            store.dispatch(MembershipActions.setPackagePlanSearchFilter(membershipSearchFilter));
        }
    }

    onCountFilter = () => {
        const { packagePlans } = this.state;
        const reciprocalAccesses: any = Object.values(packagePlans.reduce((r, e) => {
            let key = `${e.package.packageTier.name}`;
            if (!r[key]) r[key] = { name: e.package.packageTier.name, count: 1 }
            else r[key].count += 1;
            return r;
        }, {}));

        const minimumContracts: any = Object.values(packagePlans.reduce((r, e) => {
            let key = `${e.minimumContractValue}`;
            if (!r[key]) r[key] = { name: e.minimumContractValue, value: Number(e.minimumContractValue.match(/\d+/)![0]), count: 1 }
            else r[key].count += 1;
            return r;
        }, {}));

        const accesses: any = Object.values(packagePlans.reduce((r, e) => {
            let key = `${e.package.timeGroup.name}`;
            if (!r[key]) r[key] = { name: e.package.timeGroup.name, count: 1 }
            else r[key].count += 1;
            return r;
        }, {}));

        const paymentMethods: any = Object.values(packagePlans.reduce((r, e) => {
            let key = `${e.paymentMethod.name}`;
            if (!r[key]) r[key] = { name: e.paymentMethod.name, count: 1 }
            else r[key].count += 1;
            return r;
        }, {}));

        // Sort minimumContracts by ascending
        let contractsByDay = minimumContracts.filter(el => el && el.name && el.name.toLowerCase().indexOf('day') > -1);
        let contractsByMonth = minimumContracts.filter(el => el && el.name && el.name.toLowerCase().indexOf('month') > -1);
        let contractsByYear = minimumContracts.filter(el => el && el.name && el.name.toLowerCase().indexOf('year') > -1);
        let sortByDay = _.sortBy(contractsByDay, 'value');
        let sortByMonth = _.sortBy(contractsByMonth, 'value');
        let sortByYear = _.sortBy(contractsByYear, 'value');
        let newContracts = [...sortByDay, ...sortByMonth, ...sortByYear];

        this.setState({
            reciprocalAccesses,
            minimumContracts: newContracts,
            accesses,
            paymentMethods
        })
    }

    storeDataToRedux = () => {
        const { membershipSearchFilter } = this.props.newMembership;
        membershipSearchFilter.salePersonId = this.state.membershipSearchFilter.salePersonId;
        membershipSearchFilter.salePersonName = this.state.membershipSearchFilter.salePersonName;
        membershipSearchFilter.applicationTypeId = this.state.membershipSearchFilter.applicationTypeId;
        membershipSearchFilter.startDate = this.state.membershipSearchFilter.startDate;
        membershipSearchFilter.dateOfBirth = this.state.membershipSearchFilter.dateOfBirth;
        store.dispatch(MembershipActions.setPackagePlanSearchFilter(membershipSearchFilter));
    }

    hasChangeControlValue() {
        const propMembershipSearchFilter = this.props.newMembership.membershipSearchFilter;
        const stateMembershipSearchFilter = this.state.membershipSearchFilter;
        if (stateMembershipSearchFilter.applicationTypeId !== propMembershipSearchFilter.applicationTypeId
            || stateMembershipSearchFilter.dateOfBirth !== propMembershipSearchFilter.dateOfBirth
            || stateMembershipSearchFilter.startDate !== propMembershipSearchFilter.startDate
            || (stateMembershipSearchFilter.salePersonId !== propMembershipSearchFilter.salePersonId
                && stateMembershipSearchFilter.clubId === propMembershipSearchFilter.clubId)) {
            return true;
        }
        return false;
    }

    handleSaveAndLeave(stepIndex, isSave = false) {
        const { membershipConfiguration } = this.props.newMembership;
        if (isSave) {
            this.storeDataToRedux();
        }
        membershipConfiguration.stepIndex = stepIndex;
        store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        observer.publish('closeDialogEvent', true);
    }

    goToStep(stepIndex) {
        const {
            membershipConfiguration,
            membershipConfiguration: { stepLatest } } = this.props.newMembership;
        const { t } = this.props;
        if (stepLatest > 1 && this.hasChangeControlValue()) {
            if (!this.isReadyToNextStep()) return;
            const content = (
                <React.Fragment>
                    <div className="swal2-icon swal2-warning swal2-animate-warning-icon add-member-page"></div>
                    <p className="content-center add-member-page">{t('MSG.UNSAVED_CHANGES')}</p>
                    <div className="modalSaas__row--btn">
                        <button
                            className="btn btn-primary text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex, true)}
                        >
                            {t('BUTTON.SAVE_AND_LEAVE')}
                        </button>
                        <button
                            className="btn btn-dark text-uppercase"
                            onClick={() => this.handleSaveAndLeave(stepIndex)}
                        >
                            {t('BUTTON.LEAVE_AND_NOT_SAVE')}
                        </button>
                    </div>
                </React.Fragment>
            );
            observer.publish('openDialogEvent', {
                content: content
            });
        }
        else {
            membershipConfiguration.stepIndex = stepIndex;
            store.dispatch(MembershipActions.setConfiguration(membershipConfiguration));
        }
    }

    render() {
        return template(this);
    }
}

const mapStateToProps: any = ({ newMembership, conman }) => {
    return { newMembership, conman };
};

export default connect(
    mapStateToProps
)(withTranslation()(SelectPackagePlan));
