import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PackageModel from 'models/Package';
import * as Route from 'pages/RouteLoader';
import template from './template';
import ThisInterface from './interface';
import { getSubTenantId } from 'util/ControlUtils';

class PackageDetailPage extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    constructor(props: ThisInterface['props']) {
        super(props);

        // Get subTenantId & rightName
        const { location: { state } } = props;
        let subTenantId = getSubTenantId();
        if (state && state.subTenantId) {
            subTenantId = state.subTenantId;
        }
        const rightName = Route.PackageDetailRoute.rightName;

        this.state = {
            packageDetail: new PackageModel(),
            isLoading: true,
            openDropdown: true,
            tableHeight: window.innerHeight - 400,
            version: 0,
            subTenantId,
            rightName: rightName

        };
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props;
        const { id } = params as { id: string };
        if (id) {
            this.getPackageDetail(id);
        }
        window.onresize = this.resize;
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 400
        });
    };

    getPackageDetail = (id: string) => {
        const { subTenantId, rightName } = this.state;
        const { conmanService } = this.props;
        conmanService
            .getPackageDetail(id, subTenantId, rightName)
            .then(data => {
                this.setState({
                    packageDetail: data,
                    isLoading: false
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    handleOpenDropdown = () => {
        this.setState(prevState => {
            return {
                openDropdown: !prevState.openDropdown
            };
        });
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(PackageDetailPage)));
