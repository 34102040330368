import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Constant from 'constants/Constants';
import * as RouteLoader from 'pages/RouteLoader';
import { isHasRight } from 'util/ControlUtils';
import Language from 'models/Language';
import ListComponent from 'list-component';

import { handleSessionExpired } from 'util/ControlUtils';
import i18n from 'i18next';

interface StateInterface {
    version: number;
    tableHeight: number;
}

interface PropsInterface extends RouteComponentProps<{}> {
    locale?: Language;
    auth: any;
}

class FeeListPage extends React.Component<
PropsInterface & WithTranslation,
StateInterface
> {
    constructor(props) {
        super(props);
        this.state = {
            tableHeight: 0,
            version: 0
        };
    }

    componentDidMount() {
        const { user } = this.props.auth;
        window.onresize = this.resize;
        this.setState({
            tableHeight: window.innerHeight - 340,
            version: 1
        });
    }
    actionCallback = (action, id, item) => {
        this.setState(state => ({ version: state.version + 1 }));
        if (action === 'edit') {
            this.props.history.push(
                `/${RouteLoader.EditFeeRoute.url.replace(':id', id)}`
            );
        }
        if (action === 'view') {
            this.props.history.push(
                `/${RouteLoader.ViewFeeRoute.url.replace(':id', id)}`
            );
        }
    };

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 340
        });
    };

    errorHandle = err => {
        if (err && err.status === 401) {
            handleSessionExpired();
        }
    };

    render() {
        const { auth, t } = this.props;
        const variableConfig = {
            api: Constant.BASE_API_URL_DEVELOPMENT
        };

        return this.state.version > 0 ? (
      <>
        <div className="list-page plan-list-view-page">
            <div className="list-page-title">
                <p className="title-content title-content-list">
                    {t('PAGE.PRODUCTS.ITEMS.TITLE')}
                </p>
                <div className="box-btn-filter ml-auto d-flex">
                    <span>
                        {t('PAGE.PRODUCTS.ITEMS.INSTRUCTION')}
                    </span>
                </div>
                <div className="add-new-section">
                    {isHasRight(RouteLoader.AddFeeRoute.rightName) && (
                        <button
                            onClick={() =>
                                this.props.history.push(`/${RouteLoader.AddFeeRoute.url}`)
                            }
                            className="btn btn-height-sm btn-add-new"
                        >
                            {t('PAGE.PRODUCTS.ITEMS.BUTTON.ADD_FEE')}
                        </button>
                    )}
                </div>
            </div>
        </div>
        <ListComponent
            oidcAuth={auth.user}
            version={this.state.version}
            rightName={RouteLoader.FeeRoute.rightName}
            config={{
                presetUrl: Constant.PRESET_ENDPOINT_URL,
                timeFormat: Constant.GENERAL_DATE_FORMAT,
                langCode: i18n.language,
                tableHeight: `${this.state.tableHeight}px`,
                tableWidth: '100%'
            }}
            variable={variableConfig}
            actionEvent={this.actionCallback}
            errorEvent={this.errorHandle}
        />
      </>
        ) : null;
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(FeeListPage)));
