import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import template from './template';
import ThisInterface from './interface';

class ServiceVersionPage extends React.Component<
ThisInterface['props'] & RouteComponentProps<{}>,
ThisInterface['state']
> {
    constructor(props: ThisInterface['props'] & RouteComponentProps<{}>) {
        super(props);
        this.state = {
            tableHeight: window.innerHeight - 260,
            version: 0
        }
    }

    componentDidMount() {
        window.onresize = this.resize;
    }

    resize = () => {
        this.setState({
            tableHeight: window.innerHeight - 260
        });
    };

    render() {
        return template(this);
    }
}

const mapStateToProps = ({ auth }) => {
    
    return {
        auth
    };
};

export default withRouter(connect(mapStateToProps)(ServiceVersionPage));
