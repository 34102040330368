import React from 'react';
import _ from 'lodash';
import { FormSelect, FormInput, FormCheckbox } from 'components/Form';
import Loading from 'components/Loading';
import CustomTable from 'components/Table';
import circleXIcon from 'assets/images/circle-x.svg';
import ThisInterface from './interface';

const headers = [
    {
        key: 1,
        title: ''
    },
    {
        key: 2,
        title: 'PAGE.SYSTEM.FIELDS_CONFIG.DISPLAY'
    },
    {
        key: 3,
        title: 'PAGE.SYSTEM.FIELDS_CONFIG.REQUIRED'
    },
    {
        key: 4,
        title: 'PAGE.SYSTEM.FIELDS_CONFIG.REGEX',
        className: 'align-center'
    },
    {
        key: 5,
        title: 'PAGE.SYSTEM.FIELDS_CONFIG.MAX_LENGTH',
        className: 'align-center'
    },
    {
        key: 6,
        title: ''
    }
];

const isDisabled = (isVisible: boolean) => {
    if (isVisible !== undefined) {
        return !isVisible;
    }
    return false;
};

const SingleField = ({
    field,
    isHighGround = false,
    onChangeValue,
    parent = { id: '', isVisible: false },
    onRemoveField
}) => {
    return (
        <tr>
            <td className={isHighGround ? 'bold-text' : 'pd-left-30'}>
                {field.name}
            </td>
            <td>
                {field.isVisible !== undefined && (
                    <FormCheckbox
                        checked={field.isVisible}
                        onChange={e => onChangeValue(e, field.id, 'isVisible')}
                    />
                )}
            </td>
            <td>
                {field.isRequired !== undefined && (
                    <FormCheckbox
                        disabled={isDisabled(field.isVisible)}
                        checked={field.isRequired}
                        onChange={e => onChangeValue(e, field.id, 'isRequired')}
                    />
                )}
            </td>
            <td>
                {field.regEx !== null && field.regEx !== undefined && (
                    <FormInput
                        disabled={
                            parent.id
                                ? isDisabled(parent.isVisible)
                                : isDisabled(field.isVisible)
                        }
                        value={field.regEx}
                        onChange={e =>
                            onChangeValue(
                                e,
                                !isHighGround ? parent.id : field.id,
                                'regEx',
                                field.id
                            )
                        }
                    />
                )}
            </td>
            <td>
                {field.maxLength !== null && field.maxLength !== undefined && (
                    <FormInput
                        type="number"
                        disabled={
                            parent.id
                                ? isDisabled(parent.isVisible)
                                : isDisabled(field.isVisible)
                        }
                        value={field.maxLength}
                        onChange={e =>
                            onChangeValue(
                                e,
                                !isHighGround ? parent.id : field.id,
                                'maxLength',
                                field.id
                            )
                        }
                    />
                )}
            </td>
            <td>
                {isHighGround && (
                    <div className="delete-icon" onClick={() => onRemoveField(field.id)}>
                        <img src={circleXIcon} alt="" />
                    </div>
                )}
            </td>
        </tr>
    );
};

const renderTableContent = (_this: ThisInterface) => {
    const { editFields } = _this.state;
    return editFields.map(item => {
        if (item.children) {
            return (
                <React.Fragment key={item.id}>
                    <SingleField
                        field={item}
                        isHighGround
                        onChangeValue={_this.handleChangeFieldValue}
                        onRemoveField={_this.handleRemoveField}
                    />
                    {item.children.map(child => {
                        return (
                            <SingleField
                                key={child.id}
                                field={child}
                                onChangeValue={_this.handleChangeFieldValue}
                                parent={item}
                                onRemoveField={null}
                            />
                        );
                    })}
                </React.Fragment>
            );
        }
        return (
            <SingleField
                isHighGround
                key={item.id}
                field={item}
                onChangeValue={_this.handleChangeFieldValue}
                onRemoveField={_this.handleRemoveField}
            />
        );
    });
};

const FieldSelect = ({
    options,
    selectedValue,
    onChange,
    label,
    disabled = false
}) => {
    return (
        <React.Fragment>
            <label className="title">{label}</label>
            <FormSelect
                disabled={disabled}
                className="select-form"
                options={options}
                value={selectedValue}
                onChange={onChange}
                isClearable
            />
        </React.Fragment>
    );
};

const template = (_this: ThisInterface) => {
    const {
        countries,
        features,
        fields,
        isLoading,
        isLoadingFields,
        disabledButton,
        selectedCountries,
        selectedFeature,
        selectedField,
        editFields,
        tableHeight
    } = _this.state;

    const { t } = _this.props;
    const diabledFeatures = selectedCountries === null;
    const isDisabled = diabledFeatures || selectedFeature === null;
    const isShowTable = !isDisabled && !_.isEmpty(editFields);

    return isLoading ? (
        <Loading />
    ) : (
        <div className="member-config-page">
            <p className="title-content title-content-list">
                {t('PAGE.SYSTEM.FIELDS_CONFIG.TITLE')}
            </p>
            <div className="box-btn-filter ml-auto d-flex mb-3">
                <span>
                    {t('PAGE.SYSTEM.FIELDS_CONFIG.INSTRUCTION')}
                </span>
            </div>
            <div className="member-config-page-content">
                <div className="form-wrapper">
                    <FieldSelect
                        options={countries}
                        selectedValue={selectedCountries}
                        onChange={e => _this.handleChangeValue(e, 'selectedCountries')}
                        label={t('PAGE.SYSTEM.FIELDS_CONFIG.COUNTRY')}
                    />
                </div>
                <div className="form-wrapper">
                    <FieldSelect
                        disabled={diabledFeatures}
                        options={features}
                        selectedValue={!diabledFeatures ? selectedFeature : null}
                        onChange={e =>
                            !diabledFeatures && _this.handleChangeValue(e, 'selectedFeature')
                        }
                        label={t('PAGE.SYSTEM.FIELDS_CONFIG.FEATURE')}
                    />
                </div>
                <div className="form-wrapper">
                    <FieldSelect
                        disabled={isDisabled}
                        options={fields}
                        selectedValue={selectedField}
                        onChange={e =>
                            !isDisabled && _this.handleChangeValue(e, 'selectedField')
                        }
                        label={t('PAGE.SYSTEM.FIELDS_CONFIG.ADD_FIELD')}
                    />
                    <button
                        disabled={isDisabled || selectedField === null}
                        onClick={() =>
                            (!isDisabled || selectedField) && _this.handleAddField()
                        }
                        className="btn btn-primary btn-add"
                    >
                        {t('BUTTON.ADD')}
                    </button>
                </div>
                <p className="description">
                    {t('PAGE.SYSTEM.FIELDS_CONFIG.FIELDS_INSTRUCTION')}
                </p>
                {isLoadingFields ? (
                    <Loading />
                ) : (
                    isShowTable && (
                        <div className="table-responsive">
                            <CustomTable
                                headers={headers}
                                tableContent={renderTableContent(_this)}
                                renderingCondition
                                maxHeigth={`${tableHeight}px`}
                            />
                        </div>
                    )
                )}
                <div className="button-holder">
                    <button
                        disabled={isDisabled || disabledButton}
                        onClick={() =>
                            (!isDisabled || !disabledButton) && _this.handleSaveConfig()
                        }
                        className="btn btn-primary"
                    >
                        {t('BUTTON.SAVE')}
                    </button>
                    <button
                        onClick={_this.handleCancel}
                        className="btn btn-dark"
                    >
                        {t('BUTTON.CANCEL')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default template;
