import { forceInt, getIdOrNull, formatDateAddZ } from 'components/FormV2';
import { getTenantId } from 'util/ControlUtils';

export class SubTenantModel {
    id = '';
    name = '';
}
export class PackageModel {
    id = '';
    name = '';
}
export class ClassModel {
    id = '';
    name = '';
    code = '';
}
export class PaymentMethodModel {
    id = '';
    name = '';
}
export class FixTermTypeModel {
    id = '';
    name = '';
}
export class CorporateSubsidyModel {
    id = '';
    name = '';
    code = '';
}
export class RestrictionModel {
    isNewMembership = false;
    isRenewMembership = false;
    isChangeMembership = true;
    isRejoinMembership = true;
}
export class UnitModel {
    code = '';
    name = '';
    static clone = (obj: UnitModel) => {
        return {
            code: obj.code,
            name: obj.name
        }
    }
}
export class PlanPeriodsModel {
    id = '';
    name = '';
    value = 0;
    unit = new UnitModel();
    checked? = false;
    static clone = (obj: PlanPeriodsModel | PackagePeriodsModel) => {
        return {
            id: obj.id,
            name: obj.name,
            value: obj.value,
            checked: obj.checked,
            unit: UnitModel.clone(obj.unit)
        }
    }
}
export class PackagePeriodsModel {
    id = '';
    name = '';
    value = 0;
    unit = new UnitModel();
    checked? = false;
    static clone = (obj: PlanPeriodsModel | PackagePeriodsModel) => {
        return {
            id: obj.id,
            name: obj.name,
            value: obj.value,
            checked: obj.checked,
            unit: UnitModel.clone(obj.unit)
        }
    }
}
export class ClubGroupsModel {
    id = '';
    name = '';
    static clone = (obj: ClubGroupsModel) => {
        return {
            id: obj.id,
            name: obj.name
        }
    }
}
export class ClubsModel {
    id = '';
    name = '';
}
export class CommentModel {
    id = '';
    operatorId = '';
    comment = '';
    fullName = '';
    dateTime = '';
}
export class CollectionMethodModel {
    id = '';
    name = '';
}
export class PriceModel {
    clubGroup = new ClubGroupsModel();
    effectiveDate = '';
    expirationDate = '';
    priority = 0;
    value = 0;
}
export class PaymentModel {
    code = '';
    name = '';
    collectionMethod = new CollectionMethodModel();
    id = '';
    prices: PriceModel[] = [];
}

export class PlanAvailabilityModel {
    id = '';
    availableFrom = '';
    availableTo = '';
    isRequiresPermissionSellRestrictedItem = false;
    isAvailableForTeleSales = false;
    isAvailableForWeb = false;
    isAvailableForClub = false;
    clubGroups: ClubGroupsModel[] = [];
    static clone = (obj: PlanAvailabilityModel) => {
        return {
            id: obj.id,
            availableFrom: obj.availableFrom,
            availableTo: obj.availableTo,
            isRequiresPermissionSellRestrictedItem: obj.isRequiresPermissionSellRestrictedItem,
            isAvailableForTeleSales: obj.isAvailableForTeleSales,
            isAvailableForWeb: obj.isAvailableForWeb,
            isAvailableForClub: obj.isAvailableForClub,
            clubGroups: obj.clubGroups.map(e => ClubGroupsModel.clone(e))
        }
    }
    static buildPutData = (obj: PlanAvailabilityModel, subTenantId, rightName) => {
        return {
            availableFrom: formatDateAddZ(obj.availableFrom),
            availableTo: formatDateAddZ(obj.availableTo),
            isRequiresPermissionSellRestrictedItem: obj.isRequiresPermissionSellRestrictedItem,
            isAvailableForTeleSales: obj.isAvailableForTeleSales,
            isAvailableForWeb: obj.isAvailableForWeb,
            isAvailableForClub: obj.isAvailableForClub,
            clubGroups: obj.clubGroups.map(e => e.id),
            tenantId: getTenantId(),
            subTenantId: subTenantId,
            rightName: rightName
        }
    }
}

export default class PlanModel {
    id = '';
    name = '';
    description = '';
    rejectionCountThresholdOverride = 3;
    sweepArrearOntoBilling = true;
    isIgnoreNoFreeze = false;
    isAllowChangeHomeClub = true;
    isAllMemberToNonMemberTransfer = true;
    isUseLastPeriodDues = false;
    isAllowFreezeMembership = false;
    isAllowExtraClub = false;
    fixTermTypeLimit = 0;
    isCorporatePlan = true;
    effectiveDate = '';
    expirationDate = '';
    availableFrom = '';
    availableTo = '';
    isRequiresPermissionSellRestrictedItem = false;
    isAvailableForTeleSales = false;
    isAvailableForWeb = false;
    isAvailableForClub = false;
    subTenant = new SubTenantModel();
    package = new PackageModel();
    class = new ClassModel();
    payments: PaymentModel[] = [];
    paymentMethod = new PaymentMethodModel();
    fixTermType = new FixTermTypeModel();
    corporateSubsidy = new CorporateSubsidyModel();
    restriction = new RestrictionModel();
    planPeriods: PlanPeriodsModel[] = [];
    clubGroups: ClubGroupsModel[] = [];
    comments: CommentModel[] = [];
    comment? = '';
    static buildPutData = (obj: PlanModel, planPeriods: PlanPeriodsModel[], subTenantId, rightName) => {
        return {
            name: obj.name,
            description: obj.description,
            packageId: getIdOrNull(obj.package),
            classId: getIdOrNull(obj.class),
            paymentMethodId: getIdOrNull(obj.paymentMethod),
            rejectionCountThresholdOverride: forceInt(obj.rejectionCountThresholdOverride),
            isSweepArrearOntoBilling: obj.sweepArrearOntoBilling,
            isIgnoreNoFreeze: obj.isIgnoreNoFreeze,
            isAllowChangeHomeClub: obj.isAllowChangeHomeClub,
            isAllMemberToNonMemberTransfer: obj.isAllMemberToNonMemberTransfer,
            isUseLastPeriodDues: obj.isUseLastPeriodDues,
            isAllowFreezeMembership: obj.isAllowFreezeMembership,
            fixTermTypeId: getIdOrNull(obj.fixTermType),
            fixTermTypeLimit: forceInt(obj.fixTermTypeLimit),
            isCorporatePlan: obj.isCorporatePlan,
            corporateSubsidyId: getIdOrNull(obj.corporateSubsidy),
            availableFrom: formatDateAddZ(obj.availableFrom),
            availableTo: formatDateAddZ(obj.availableTo),
            isRequiresPermissionSellRestrictedItem: obj.isRequiresPermissionSellRestrictedItem,
            isAvailableForTeleSales: obj.isAvailableForTeleSales,
            isAvailableForWeb: obj.isAvailableForWeb,
            isAvailableForClub: obj.isAvailableForClub,
            isAllowExtraClub: obj.isAllowExtraClub,
            clubGroups: obj.clubGroups.map(e => e.id),
            planPeriods: planPeriods.map(e => ({
                id: e.id,
                value: forceInt(e.value),
                unitId: getIdOrNull(e.unit),
                offsets: []
            })),
            isRestrictNewMembership: obj.restriction.isNewMembership,
            isRenewMembership: obj.restriction.isRenewMembership,
            isRestrictChangeMembership: obj.restriction.isChangeMembership,
            isRestrictRejoinMembership: obj.restriction.isRejoinMembership,
            fees: obj.payments.map(e => e.id),
            comment: obj.comment,
            tenantId: getTenantId(),
            subTenantId: subTenantId,
            rightName: rightName
        }
    }
    static buildQuickClonePostData = (obj: PlanModel, planPeriods: PlanPeriodsModel[], subTenantId, rightName) => {
        return {
            name: obj.name,
            classId: getIdOrNull(obj.class),
            availableFrom: formatDateAddZ(obj.availableFrom),
            availableTo: formatDateAddZ(obj.availableTo),
            isAvailableForTeleSales: obj.isAvailableForTeleSales,
            isAvailableForClub: obj.isAvailableForClub,
            isAllowExtraClub: obj.isAllowExtraClub,
            clubGroups: obj.clubGroups.map(e => e.id),
            planPeriods: planPeriods.map(e => ({
                id: e.id,
                value: forceInt(e.value),
                unitId: getIdOrNull(e.unit),
                offsets: []
            })),
            comment: obj.comment,
            tenantId: getTenantId(),
            subTenantId: subTenantId,
            rightName: rightName
        }
    }
    static buildQuickEditPutData = (obj: PlanModel, planPeriods: PlanPeriodsModel[], subTenantId, rightName) => {
        return {
            classId: getIdOrNull(obj.class),
            paymentMethodId: getIdOrNull(obj.paymentMethod),
            availableFrom: formatDateAddZ(obj.availableFrom),
            availableTo: formatDateAddZ(obj.availableTo),
            isAvailableForTeleSales: obj.isAvailableForTeleSales,
            isAvailableForClub: obj.isAvailableForClub,
            isAllMemberToNonMemberTransfer: obj.isAllMemberToNonMemberTransfer,
            clubGroups: obj.clubGroups.map(e => e.id),
            planPeriods: planPeriods.map(e => ({
                id: e.id,
                value: forceInt(e.value),
                unitId: getIdOrNull(e.unit),
                offsets: []
            })),
            comment: obj.comment,
            tenantId: getTenantId(),
            subTenantId: subTenantId,
            rightName: rightName
        }
    }
}