import React from 'react';
import ThisInterface, { CpdSteps } from './interface';
import PersonalDetails from './Steps/PersonalDetails';
import MemberSummary from './Steps/MemberSummary';
import Loading from 'components/Loading';

const template = (_this: ThisInterface) => {
    const { handleCancel, handlePrevious, handleNext, handleFinish } = _this;
    const { membershipService } = _this.props;
    const {
        activeStep,
        dateFormat,
        fieldConfigs,
        originalPersonalDetails,
        newPersonalDetails
    } = _this.state;

    return (
        <div className="change-personal-details-page">
            {!newPersonalDetails.subTenantId ? (
                <Loading />
            ) : activeStep === CpdSteps.PERSONAL_DETAILS ? (
                <PersonalDetails
                    membershipService={membershipService}
                    dataSource={newPersonalDetails}
                    fieldConfigs={fieldConfigs}
                    dateFormat={dateFormat}
                    canChangeFirstName={_this.checkClaim('change_member_first_name')}
                    handleCancel={handleCancel}
                    handleNext={handleNext}
                />
            ) : (
                <MemberSummary
                    dateFormat={dateFormat}
                    fieldConfigs={fieldConfigs}
                    originalPersonalDetails={originalPersonalDetails}
                    newPersonalDetails={newPersonalDetails}
                    handleCancel={handleCancel}
                    handlePrevious={handlePrevious}
                    handleFinish={handleFinish}
                />
            )}
        </div>
    );
};

export default template;
