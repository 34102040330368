import React from 'react';
import editProfile from 'assets/images/edit-profile.png';

export default class MemberSummary extends React.Component<any> {
    render() {
        return (
            <React.Fragment>
                <div className="membersummary-page-header">
                    <div className="info-summary">
                        <div className="box-avatar">
                            <div className="edit-avartar">
                                <img src={editProfile} alt="profile"/>
                                <span className="icon-edit-ico" />
                            </div>
                        </div>
                        <div className="detail-summary">
                            <p><b>MR DOAN-TH LUONG_TEST_CHANGE_PERSONAL_DETAIL</b></p>
                            <p>Male</p>
                            <p><b>Member since:</b> 11/13/2019(6 Day(s))</p>
                            <p><b>ID Number:</b> 123456789</p>
                        </div>
                    </div>
                    <div className="capital-center">
                        <p><b>AIA CAPITAL CENTER</b></p>
                        <p className="membership-stt">Membership Status: <span className="ok">OK</span></p>
                        <p className="payment-stt">Payment Status: <span className="pending">Pending</span></p>
                    </div>
                </div>
                <div className="membersummary-list">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="debt-summary">
                                <div className="debt-summnary-header header">
                                    <p className="title">DEBT SUMMARY</p>
   
                                    <ul>
                                        <li>
                                            <a href="#">View Debit List</a>
                                        </li>
                                        <li>
                                            <a href="#">Action</a>
                                        </li>
                                    </ul>

                                </div>
                                <div className="debt-summnary-list">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <dl>
                                                <dt className="red-color">Debt Stage:</dt>
                                                <dd className="red-color">P2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Debt Start Date:</dt>
                                                <dd>20/07/2019</dd>
                                            </dl>
                                            <dl>
                                                <dt>Contract Start Date:</dt>
                                                <dd>30/07/2019</dd>
                                            </dl>
                                            <dl>
                                                <dt>Date of MCP:</dt>
                                                <dd>31/07/2020</dd>
                                            </dl>
                                            <dl>
                                                <dt>Within MCP?:</dt>
                                                <dd>Yes</dd>
                                            </dl>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <dl>
                                                <dt className="red-color">Remaining CCV:</dt>
                                                <dd className="red-color">4.266.000.000</dd>
                                            </dl>
                                            <dl>
                                                <dt>Member Dues:</dt>
                                                <dd>474.000.00</dd>
                                            </dl>
                                            <dl>
                                                <dt>Contract Notice Period:</dt>
                                                <dd>1 Month</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Attended:</dt>
                                                <dd>-</dd>
                                            </dl>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <dl>
                                                <dt className="red-color">Arear Value:</dt>
                                                <dd>-524.000.00</dd>
                                            </dl>
                                            <dl>
                                                <dt>Original Reject Reason:</dt>
                                                <dd>n/a</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Reject Date:</dt>
                                                <dd>Last Reject Date</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Bank Reject Type MF:</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Last Bank Reject Type:</dt>
                                                <dd>Invalid payment</dd>
                                            </dl>
                                            <dl>
                                                <dt>Description:</dt>
                                                <dd>method data</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="membership">
                                <div className="member-account-header header">
                                    <p className="title">MEMBER ACCOUNT</p>
                                    <ul>
                                        <li>
                                            <a href="#">Request Payment</a>
                                        </li>
                                        <li>
                                            <a href="#">Changes Payment Details</a>
                                        </li>
                                        <li>
                                            <a href="#">Make Payment</a>
                                        </li>
                                        <li>
                                            <a href="#">Action</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="member-account-list">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Last charge:</dt>
                                                <dd>474.000.00 on 01/10/2019</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Next charge:</dt>
                                                <dd>474.000.00 on 01/11/2019</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt className="red-color">Cureent balance:</dt>
                                                <dd className="red-color">-524.000.00</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="member-account">
                                <div className="membership-header header">
                                    <p className="title">MEMBERSHIP</p>
                                    <ul>
                                        <li>
                                            <a href="#">Freeze Membership</a>
                                        </li>
                                        <li>
                                            <a href="#">Request Cancellation</a>
                                        </li>
                                        <li>
                                            <a href="#">Change Membership</a>
                                        </li>
                                        <li>
                                            <a href="#">Action</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="member-account-list">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Tier:</dt>
                                                <dd>Home</dd>
                                            </dl>
                                            <dl>
                                                <dt>Access:</dt>
                                                <dd>Full Access</dd>
                                            </dl>
                                            <dl>
                                                <dt>Start Date:</dt>
                                                <dd>11/13/2019</dd>
                                            </dl>
                                            <dl>
                                                <dt>Length Of:</dt>
                                                <dd>3 Months (Until 02/12/2020)</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Sales Person:</dt>
                                                <dd>Sale by NashTech Global in AIA Capital Center</dd>
                                            </dl>
                                            <dl>
                                                <dt>Package:</dt>
                                                <dd>Home</dd>
                                            </dl>
                                            <dl>
                                                <dt>Plan:</dt>
                                                <dd>Doan-Plan-One</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>End Date:</dt>
                                                <dd>02/13/2020</dd>
                                            </dl>
                                            <dl>
                                                <dt>Within MBG?:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Within MCP?:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Remaining:</dt>
                                                <dd></dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="personal-detail">
                                <div className="personal-detail-header header">
                                    <p className="title">PERSONAL DETAILS</p>
                                    <ul>
                                        <li>
                                            <a href="#">Change Personal Details</a>
                                        </li>
                                        <li>
                                            <a href="#">Action</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="personal-detail-list">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Address:</dt>
                                                <dd>N/A</dd>
                                            </dl>
                                            <dl>
                                                <dt>Email:</dt>
                                                <dd>N/A</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Health Questionnaire:</dt>
                                                <dd>OK</dd>
                                            </dl>
                                            <dl>
                                                <dt>Preferred Language:</dt>
                                                <dd>English</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="activity">
                                <div className="activity-header header">
                                    <p className="title">ACTIVITY</p>
                                    <ul>
                                        <li>
                                            <a href="#">Create Booking</a>
                                        </li>
                                        <li>
                                            <a href="#">Action</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="activity-list">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Member Card Has Been Assigned:</dt>
                                                <dd>2</dd>
                                            </dl>
                                            <dl>
                                                <dt>Visists:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Online Access:</dt>
                                                <dd>Not Registered</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="contact">
                                <div className="contact-header header">
                                    <p className="title">CONTACT</p>
                                    <ul>
                                        <li>
                                            <a href="#">Add Note</a>
                                        </li>
                                        <li>
                                            <a href="#">Add Message</a>
                                        </li>
                                        <li>
                                            <a href="#">Action</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-list">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Notes:</dt>
                                                <dd>Test</dd>
                                            </dl>
                                            <dl>
                                                <dt>Messeges:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Email:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Letters:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                        <div className="col-md-4">
                                            <dl>
                                                <dt>Documents:</dt>
                                                <dd>No</dd>
                                            </dl>
                                            <dl>
                                                <dt>Interactions:</dt>
                                                <dd>No</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row-btn">
                    <button className="btn btn-primary">member document</button>
                    <button className="btn btn-primary btn-long-text">member to nonmember transer</button>
                    <button className="btn btn-primary btn-long-text">move to corporate membership</button>
                    <button className="btn btn-dark">back to members</button>
                </div>
            </React.Fragment>
        )
    }
}