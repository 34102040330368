import React from 'react';
import { EditPlanInterface } from 'pages/Products/Plan/Template/interface';
import Availability from 'pages/Products/Plan/Template/Availability';
import BasicDetails from 'pages/Products/Plan/Template/BasicDetails';
import Payments from 'pages/Products/Plan/Template/Payments';
import Periods from 'pages/Products/Plan/Template/Periods';
import PlanRestrictions from 'pages/Products/Plan/Template/PlanRestrictions';

const template = (_this: EditPlanInterface) => {
    const { t } = _this.props;
    return (
        <section>
            <div className="plan-edit">
                <div className="title-content mb-3">{t('PAGE.PRODUCTS.PLANS.EDIT.TITLE')} </div>
                <div className="member">
                    <div className="member-main member-main--custom">
                        <div className="content">
                            <BasicDetails
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <Availability
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <Periods
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                packageId={_this.state.packageId}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <PlanRestrictions
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <Payments
                                hideComments={false}
                                data={_this.state.planDetail}
                                fields={_this.state.fields}
                                onChangeHandle={(name, value) => _this.onChangeHandle(name, value)}
                                {..._this.props}
                                subTenantId={_this.state.subTenantId}
                                rightName={_this.state.rightName}
                            />
                            <div className="box-btn justify-content-start no-padding-left">
                                <button disabled={_this.state.disabled} className="btn btn-primary" onClick={() => _this.onSubmit()}>{t('BUTTON.SAVE')} </button>
                                <button className="btn btn-dark" onClick={() => _this.onCancel()}>{t('BUTTON.CANCEL')} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default template;
