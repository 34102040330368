import Swal from 'sweetalert2';
import axios from 'axios';
import * as DefaultConstants from 'constants/DefaultConstants';
import { t } from 'util/I18nMessages';
import { getTenantId, getSubTenantId, getToken, handleSessionExpired } from 'util/ControlUtils';
class Headers {
    rightName: string = '';
    subTenantId?: string = '';
    hasCache?: boolean = true;
}


export default class CommonService {

    getHeaderFormData() {
        const xTenantId = getTenantId() ? { 'x-tenant-id': getTenantId() } : {};
        return {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            accept: 'application/json',
            ...xTenantId,
            Authorization: `Bearer ${getToken()}`
        };
    }

    getHeaderTextData() {
        const xTenantId = getTenantId() ? { 'x-tenant-id': getTenantId() } : {};
        return {
            'Content-Type': 'text/plain',
            accept: 'application/json',
            ...xTenantId,
            Authorization: `Bearer ${getToken()}`
        };
    }

    getHeader(hasCache: boolean = true, rightName: string = '') {
        const xTenantId = getTenantId() ? { 'x-tenant-id': getTenantId() } : {};
        const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
        const xRightName = rightName !== '' ? { 'x-right-name': rightName } : {};
        return {
            'Content-Type': 'application/json;charset=UTF-8',
            accept: 'application/json',
            ...xTenantId,
            ...xRightName,
            Authorization: `Bearer ${getToken()}`,
            ...cacheControl
        };
    }

    getHeaderWithoutSubTenant(hasCache: boolean = true, rightName: string = '') {
        const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
        const xRightName = rightName !== '' ? { 'x-right-name': rightName } : {};
        return {
            'Content-Type': 'application/json;charset=UTF-8',
            accept: 'application/json',
            'x-tenant-id': getTenantId(),
            ...xRightName,
            Authorization: `Bearer ${getToken()}`,
            ...cacheControl
        };
    }

    getHeaderWithSubtenant(subTenantId: string) {
        return {
            'Content-Type': 'application/json;charset=UTF-8',
            accept: 'application/json',
            'x-tenant-id': getTenantId(),
            'x-subtenant-id': subTenantId,
            Authorization: `Bearer ${getToken()}`,
            'cache-control': 'no-cache'
        };
    }

    getHeaderWithSubtenantAndRightName(
        subTenantId: string = '',
        rightName: string,
        hasCache: boolean = true
    ) {
        const cacheControl = !hasCache ? { 'cache-control': 'no-cache' } : {};
        return {
            'Content-Type': 'application/json;charset=UTF-8',
            accept: 'application/json',
            'x-tenant-id': getTenantId(),
            'x-subtenant-id': subTenantId ? subTenantId : getSubTenantId(),
            'x-right-name': rightName,
            Authorization: `Bearer ${getToken()}`,
            ...cacheControl
        };
    }

    commonHeader(headers: Headers) {
        const { subTenantId, rightName, hasCache } = headers;
        const getHeaders = subTenantId
            ? this.getHeaderWithSubtenantAndRightName(
                subTenantId,
                rightName,
                hasCache
            )
            : this.getHeader(hasCache, rightName);
        return getHeaders;
    }

    fetchData(api: string, headers: Headers) {
        return axios
            .get(api, { headers: this.commonHeader(headers) })
            .then(res => res.data)
            .catch(err => this.handleErrorHttp(err));
    }

    postData(api: string, data, headers: Headers) {
        return axios
            .post(api, data, { headers: this.commonHeader(headers) })
            .then(res => res.data)
            .catch(err => this.handleErrorHttp(err));
    }

    putData(api: string, data, headers: Headers) {
        return axios
            .put(api, data, { headers: this.commonHeader(headers) })
            .then(res => res.data)
            .catch(err => this.handleErrorHttp(err));
    }

    deleteData(api: string, headers: Headers) {
        return axios
            .delete(api, { headers: this.commonHeader(headers) })
            .then(res => res.data)
            .catch(err => this.handleErrorHttp(err));
    }

    handleErrorHttp(err) {
        const errorResponse = err.response ? err.response.data : err.response;
        if (JSON.stringify(err) !== '{}') {
            if (errorResponse) {
                Swal.fire({
                    ...DefaultConstants.SWAL_COMMON_STYLE,
                    title: errorResponse.title
                        ? t(errorResponse.title)
                        : '',
                    text: errorResponse.message
                        ? t(errorResponse.message)
                        : t('MSG.NETWORK_ERROR'),
                    type: 'error',
                    confirmButtonText: t('BUTTON.CLOSE')
                });
            } else if (!errorResponse && err) {
                if (err.response && err.response.status === 401) {
                    handleSessionExpired();
                } else {
                    Swal.fire({
                        ...DefaultConstants.SWAL_COMMON_STYLE,
                        text: t('MSG.NETWORK_ERROR'),
                        type: 'warning',
                        confirmButtonText: t('BUTTON.CLOSE')
                    });
                }
            }
        }
        throw errorResponse;
    }

    isFieldError(state, key, markAsDirty = false) {
        if (
            Object.prototype.hasOwnProperty.call(state, key) &&
            Object.prototype.hasOwnProperty.call(state[key], 'validate')
        ) {
            if (!Object.prototype.hasOwnProperty.call(state[key].validate, 'dirty')) {
                state[key].validate.dirty = false;
            }
            if (markAsDirty) {
                state[key].validate.dirty = true;
            }
            if (state[key].validate.dirty) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        state[key].validate,
                        'required'
                    ) &&
                    state[key].value === ''
                ) {
                    return 'required';
                }
            }
            return false;
        }
        return false;
    }

    delete(api: string, headers: any) {
        return axios
            .delete(api, { headers })
            .then(res => res.data)
            .catch(err => this.handleErrorHttp(err));
    }
}
