import React from 'react';
import { FormInput, FormSelect } from 'components/Form';
import Loading from 'components/Loading';
import _ from 'lodash';
import ThisInterface from './interface';

const statusList = [
    {
        value: true,
        label: 'Active'
    },
    {
        value: false,
        label: 'Inactive'
    }
];

const renderLooUpList = (_this: ThisInterface) => {
    const { lookUpValueList, isEditMode } = _this.state;
    const { t } = _this.props;
    if (!_.isEmpty(lookUpValueList)) {
        return lookUpValueList.map(item => {
            return (
                <div key={item.id} className="lookup-control">
                    <FormInput
                        disabled={!item.isNew && isEditMode}
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.REASON_CODE')}
                        value={item.code.value}
                        minlength={2}
                        maxlength={2}
                        errorMsg={item.code.errorMsg}
                        onChange={e =>
                            !(!item.isNew && isEditMode)
                                ? _this.handleChangeLookUpValue(e, item.id, 'code')
                                : null
                        }
                    />
                    <FormInput
                        min="0"
                        max="999"
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.SORT_INDEX')}
                        type="number"
                        value={item.sortIndex.value}
                        errorMsg={item.sortIndex.errorMsg}
                        onChange={e =>
                            _this.handleChangeLookUpValue(e, item.id, 'sortIndex')
                        }
                    />
                    <FormInput
                        maxlength={50}
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.NAME')}
                        value={item.name.value}
                        errorMsg={item.name.errorMsg}
                        onChange={e =>
                            _this.handleChangeLookUpValue(e, item.id, 'name', true)
                        }
                    />
                    <FormSelect
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.STATUS')}
                        value={statusList.find(opt => opt.value === item.status)}
                        options={statusList}
                        onChange={value =>
                            _this.handleChangeLookUpValue(value, item.id, 'status')
                        }
                    />
                </div>
            );
        });
    }
    return <></>;
};

const template = (_this: ThisInterface) => {
    const {
        listHeight,
        isLoading,
        subTenants,
        reasonType,
        reasonCode,
        selectedSubtenant,
        selectedStatus,
        reasonTypeDirty,
        reasonCodeDirty,
        selectedSubtenantDirty,
        selectedStatusDirty,
        isEditMode,
        disabled
    } = _this.state;

    const { t } = _this.props;

    return (
        <section className="add-lookup">
            <p className="title-content">
                {isEditMode
                    ? t(
                        'PAGE.SYSTEM.LOOKUPS.EDIT_LOOK_UP',
                        { lookupType: reasonType }
                    )
                    : t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.TITLE_ADD')}
            </p>
            <div className="lookup-main">
                <div className="lookup-control">
                    <FormInput
                        require
                        maxlength={50}
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.REASON_TYPE')}
                        labelClass="root-lookup"
                        value={reasonType}
                        onChange={e => _this.handleChangeText(e, 'reasonType')}
                        errorMsg={t(
                            'COMMON_KEY_FIELD_REQUIRED',
                            { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.REASON_TYPE') }
                        )}
                        isDirty={reasonTypeDirty}
                    />
                    <FormInput
                        require
                        disabled={isEditMode}
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.REASON_CODE')}
                        labelClass="root-lookup"
                        value={reasonCode}
                        minlength={2}
                        maxlength={2}
                        onChange={e => !isEditMode && _this.handleChangeText(e, 'reasonCode')}
                        errorMsg={t(
                            'COMMON_KEY_FIELD_REQUIRED',
                            { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.REASON_CODE') }
                        )}
                        isDirty={reasonCodeDirty}
                    />
                    <FormSelect
                        require
                        disabled={isEditMode}
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.SUB_TENANT')}
                        labelClass="root-lookup"
                        value={selectedSubtenant}
                        options={subTenants}
                        onChange={value =>
                            !isEditMode && _this.handleChangeSelect(value, 'selectedSubtenant')
                        }
                        errorMsg={t(
                            ('COMMON_KEY_FIELD_REQUIRED'),
                            { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.SUB_TENANT') }
                        )}
                        isDirty={selectedSubtenantDirty}
                    />
                    <FormSelect
                        require
                        label={t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.STATUS')}
                        labelClass="root-lookup"
                        value={statusList.find(item => item.value === selectedStatus)}
                        options={statusList}
                        onChange={value => _this.handleChangeSelect(value, 'selectedStatus')}
                        errorMsg={t(
                            'COMMON_KEY_FIELD_REQUIRED',
                            { key: t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.STATUS') }
                        )}
                        isDirty={selectedStatusDirty}
                    />
                </div>
                <div className="lookup-list-item">
                    <div className="box-btn-filter ml-auto d-flex">
                        <div className="lookup-action">
                            <button
                                className="btn btn-primary no-after no-margin-right"
                                onClick={_this.handleAddRow}
                            >
                                {`+ ${t('PAGE.SYSTEM.LOOKUPS.ADD_EDIT.ADD_VALUE')}`}
                            </button>
                        </div>
                    </div>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div
                            style={{ height: listHeight }}
                            id="look-up-control-list"
                            className="look-up-control-list"
                        >
                            {renderLooUpList(_this)}
                        </div>
                    )}
                </div>
                <div className="btn-holder">
                    <button
                        className="btn btn-primary"
                        onClick={_this.handleSaveLookUp}
                        disabled={disabled}
                    >
                        {t('BUTTON.SAVE')}
                    </button>
                    <button className="btn btn btn-dark" onClick={_this.handleCancelLookUp}>
                        {t('BUTTON.CANCEL')}
                    </button>
                </div>
            </div>
        </section>
    );
};

export default template;
