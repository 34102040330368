import React from 'react';
import Loading from 'components/Loading';
import { ThisInterface, Steps } from './interface';
import { CancelMembershipForm } from './CancelMembershipForm';
import { CancelMembershipSummary } from './CancelMembershipSummary';

const ButtonItem = ({
    type = 'primary',
    disabled = false,
    title,
    onClick = () => { }
}) => {
    return (
        <li>
            <button
                onClick={onClick}
                className={`btn btn-${type} btn-w100`}
                disabled={disabled}
            >
                {title}
            </button>
        </li>
    );
};

const renderStep = (_this: ThisInterface) => {
    const {
        state: {
            step
        }
    } = _this;
    
    switch (step) {
        case Steps.Form:
            return (
                <CancelMembershipForm {..._this} />
            );
        case Steps.Summary:
            return (
                <CancelMembershipSummary {..._this} />
            );
        default:
            return null;
    }
};

const renderActions = (_this: ThisInterface) => {
    const {
        props: {
            t
        },
        state: {
            step,
            disableNext,
            communicationEmail: { disabledFinish },
            isRemoveCancel,
        }
    } = _this;
    
    const nextActionBtn = () => {
        switch (step) {
            case Steps.Form:
                return (
                    <ButtonItem
                        title={t('BUTTON.NEXT')}
                        disabled={disableNext}
                        onClick={() => _this.moveToStep(Steps.Summary)}
                    />
                );
            case Steps.Summary:
                return (
                    <ButtonItem
                        title={t('BUTTON.FINISH')}
                        onClick={() => _this.handleFinish()}
                        disabled={disabledFinish}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="col-12 mt-4">
            <ul className="list-btn-action">
                {step !== Steps.Form && !isRemoveCancel && (
                    <ButtonItem
                        onClick={() => _this.moveToStep(Steps.Form)}
                        title={t('BUTTON.PREVIOUS')}
                    />
                )}
                {nextActionBtn()}
                <ButtonItem
                    onClick={_this.handleCancel}
                    title={t('BUTTON.CANCEL')}
                    type="dark"
                />
            </ul>
        </div>
    );
};

export const template = (_this: ThisInterface) => {
    const {
        props: {
            t
        },
        state: {
            isRemoveCancel
        }
    } = _this;
    return _this.state.isLoading ? <Loading /> : (
        <div className="cancel-membership">
            <div className="row col">
                <h3 className="text-title">{isRemoveCancel ? t('PAGE.REMOVE_CANCEL_MEMBERSHIP.TITLE') : t('PAGE.CANCEL_MEMBERSHIP.TITLE')}</h3>
            </div>
            {renderStep(_this)}
            {renderActions(_this)}
        </div>
    );
};