import * as types from 'constants/ActionTypes';
import Operator from 'models/Operator';
import { UserProfile } from 'hocs/AddUserAndUserGroup';

interface ConmanInterface {
    operator?: Operator;
    logoUri?: string;
    userRoles?: {
        tenantRoles: UserProfile['tenantRoles'];
        userRoles: UserProfile['userRoles'];
    };
}

const conman = (state: ConmanInterface = {}, action) => {
    switch (action.type) {
        case types.SET_OPERATOR:
            state.operator = action.operator;
            return Object.assign({}, state);

        case types.SET_TENANT_LOGO:
            state.logoUri = action.logoUri;
            return Object.assign({}, state);
        case types.SET_USER_ROLES:
            return {
                ...state,
                userRoles: action.userRoles
            }
        default:
            return state;
    }
};

export default conman;