import React from 'react';
import { FormSelect, FormInput, FormPhoneInput, FormCheckbox } from 'components/Form';
import { LookupModel, ContactDetailsModel, ContactDetailsFieldsModel } from 'models/PersonalDetails';
import { useTranslation } from 'react-i18next';

interface Props {   
    isEditMode?: boolean; 
    errors: any;
    overrideEmailOptions: LookupModel[];
    contactDetails: ContactDetailsModel;
    contactDetailsFieldConfigs: ContactDetailsFieldsModel;
    onInputChange: (key: string, value: string) => void;
    onSelectOptionChange: (key: string, value: any) => void;
    onCheckboxChange?: (key: string, value: boolean) => void;
    onPhoneNumberChange: (key: string, isValid: boolean, phoneNumber: string, dialCode: string) => void;
}

const ContactDetails = ({
    isEditMode,
    errors,
    overrideEmailOptions,
    contactDetails,
    contactDetailsFieldConfigs,
    onInputChange,    
    onSelectOptionChange,
    onCheckboxChange,
    onPhoneNumberChange,
}: Props) => {
    const {
        mobilePhone,
        homePhone,
        workPhone,
        email,        
        overrideEmailOption,
        mobilePhoneNoLongerUse,
        homePhoneNoLongerUse,
        workPhoneNoLongerUse,
        emailNoLongerUse,
    } = contactDetails;

    const {
        telephoneNumberConfig,
        emailConfig,
        overrideEmailConfig
    } = contactDetailsFieldConfigs;

    const { t } = useTranslation();
    
    return (
        <div className="personal-group">
            <p className="title">{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.PLEASE_PROVIDE_AT_LEAST_ONE_TELEPHONE_NUMBER')}</p>
            <div className="row">
                {telephoneNumberConfig.isVisible &&
                    <>
                        <div className="col-md-6">              
                            <FormPhoneInput
                                inputClass="form-control"
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.MOBILE_TELEPHONE')}
                                require={telephoneNumberConfig.isRequired}
                                value={mobilePhone.phoneNumber}
                                countryCode={mobilePhone.countryCode}
                                errorMsg=""
                                onPhoneNumberChange={(phoneNum, regionCode, isValid) => onPhoneNumberChange('mobilePhone', isValid, phoneNum, regionCode)}                               
                            />     
                            
                            {isEditMode && 
                                <FormCheckbox
                                    className='noLongerInUse'
                                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NO_LONGER_IN_USE')}                               
                                    checked={mobilePhoneNoLongerUse}
                                    onChange={(event) => onCheckboxChange!("mobilePhoneNoLongerUse", event.target.checked)}
                                />    
                            }                                      
                            
                            {errors.mobilePhone && <span className="phone-require text-danger input-error">{t(errors.mobilePhone)}</span>}
                        </div>
                        <div className="col-md-6">
                            <FormPhoneInput
                                inputClass="form-control"
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.HOME_TELEPHONE')}
                                value={homePhone.phoneNumber}
                                countryCode={homePhone.countryCode}
                                errorMsg=""
                                onPhoneNumberChange={(phoneNum, regionCode, isValid) => onPhoneNumberChange('homePhone', isValid, phoneNum, regionCode)}                               
                            />

                            {isEditMode && 
                                <FormCheckbox
                                    className='noLongerInUse'
                                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NO_LONGER_IN_USE')}                               
                                    checked={homePhoneNoLongerUse}
                                    onChange={(event) => onCheckboxChange!("homePhoneNoLongerUse", event.target.checked)}
                                />    
                            }

                            {errors.homePhone && <span className="phone-require text-danger input-error">{t(errors.homePhone)}</span>}                            
                        </div>
                    </>
                }
            </div>
            <div className="row">                
                {telephoneNumberConfig.isVisible &&
                    <div className="col-md-6">
                        <FormPhoneInput
                            className="pt-3"
                            inputClass="form-control "
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.WORK_TELEPHONE')}
                            value={workPhone.phoneNumber}
                            countryCode={workPhone.countryCode}
                            errorMsg=""
                            onPhoneNumberChange={(phoneNum, regionCode, isValid) => onPhoneNumberChange('workPhone', isValid, phoneNum, regionCode)}/>                                                                        
                        
                        {isEditMode && 
                            <FormCheckbox
                                className='noLongerInUse'
                                label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NO_LONGER_IN_USE')}                               
                                checked={workPhoneNoLongerUse}
                                onChange={(event) => onCheckboxChange!("workPhoneNoLongerUse", event.target.checked)}
                            />    
                        }

                        {errors.workPhone && <span className="phone-require text-danger input-error">{t(errors.workPhone)}</span>}                        
                    </div>
                }

                {emailConfig.isVisible && overrideEmailOption.code === "EM" &&
                    <div className="col-md-6">
                        <div className="form-group pt-3">
                            <label>{t('PAGE.MEMBERSHIPS.CREATE.STEP_3.EMAIL_ADDRESS')}</label>{emailConfig.isRequired && <span className="text-primary bold">&nbsp;*</span> }                    
                            <FormInput
                                id="email"
                                inputClass="form-control"
                                maxlength={emailConfig.maxLength}
                                require={emailConfig.isRequired}                            
                                value={email}
                                validMsg={errors.errorMessage}
                                onChange={(event) => onInputChange("email", event!.target.value)}
                            />
                            
                            {isEditMode && 
                                <FormCheckbox
                                    className='noLongerInUse'
                                    label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.NO_LONGER_IN_USE')}                               
                                    checked={emailNoLongerUse}
                                    onChange={(event) => onCheckboxChange!("emailNoLongerUse", event.target.checked)}
                                />    
                            }

                            {errors.email && <span className="phone-require text-danger input-error">{t(errors.email)}</span>}
                        </div>                   
                    </div>
                }
            </div>
            <div className="row">
                {overrideEmailConfig.isVisible &&
                    <div className="col-md-6">
                        <FormSelect
                            id="override-email"
                            name="override-email"
                            className="pt-3"
                            label={t('PAGE.MEMBERSHIPS.CREATE.STEP_3.OVERRIDE_EMAIL')}
                            disabled={overrideEmailOptions.length === 0}
                            require={overrideEmailConfig.isRequired}
                            onChange={(event) => onSelectOptionChange("overrideEmailOption", overrideEmailOptions.find(overrideEmailOption => overrideEmailOption.value === event.value))}
                            value={overrideEmailOption}
                            options={overrideEmailOptions}
                            errorMsg={errors.preferedLanguage}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default ContactDetails;