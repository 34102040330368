export default class Operator {
    userName = '';
    fullName = '';
    title = '';
    phoneNumber = '';
    subTenantId = '';
    subTenantName = '';
    tenantId = '';
    email = '';
    id = '';
    avatarUri = '';
    database = '';
    enableConman = false;
    firstName = '';
    lastName = '';
    level = 0;
    nationality = '';
    operatorId = '';
    role = '';
    roles = [];
    groups = [];
    subTenants = [];
    tenantName = '';
    mobilePhoneNumber = '';
    mobilePhoneRegionCode = '';
    right: {
        subTenant: any;
        tenant: any;
    } = {
        subTenant: {},
        tenant: {}
    };
    userRoles: {
        id: string;
        name: string;
        subTenantId: string;
        subTenantName: string;
        tenantId: string;
        tenantName: string;
    }[] = [];
}
