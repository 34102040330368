import * as types from 'constants/ActionTypes'
import PackagePlan from 'models/PackagePlan';
import PaymentDetails from 'models/PaymentDetails';

export function resetState(){
    return {
        type: types.ADD_MEMBER_RESET_STATE
    }
}
export function setSelectedSubTenant(subtenant: any) {
    return { 
        type: types.ADD_MEMBER_SET_SELECTED_SUBTENANT, 
        subtenant 
    };
}

export function setPackagePlanSearchFilter(membershipSearchFilter) {
    return { type: types.ADD_MEMBER_SET_SEARCH_FILTER, membershipSearchFilter };
}

export function setConfiguration(membershipConfiguration) {
    return { type: types.ADD_MEMBER_SET_CONFIGURATION, membershipConfiguration };
}

export function setProductExtras(selectedPromotions, selectedAddons) {
    return { type: types.ADD_MEMBER_SET_SELECTED_PRODUCT_EXTRAS, selectedPromotions, selectedAddons };
}

export function setSelectedPackagePlan(packagePlan: PackagePlan) {
    return { type: types.ADD_MEMBER_SET_SELECTED_PACKAGE_PLAN, packagePlan };
}

export function setPersonalDetails(personalDetails) {
    return { 
        type: types.ADD_MEMBER_SET_PERSONAL_DETAILS, 
        data: personalDetails 
    };
}

export function setAllPackagePlans(packagePlans) {
    return { 
        type: types.SET_ALL_PACKAGE_PLAN, 
        packagePlans 
    };
}

export function setMemberSummary(memberSummary) {
    return { 
        type: types.SET_MEMBER_SUMMARY, 
        memberSummary 
    };
}

export function setPaymentDetails(paymentDetails: PaymentDetails) {
    return { type: types.ADD_MEMBER_SET_PAYMENT_DETAILS, paymentDetails };
}