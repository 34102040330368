import React from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { DisplayDateFormat, dateInMCP } from 'util/ControlUtils';
import CustomTable from 'components/Table';
import FormItem from 'components/FormV2';
import { ThisInterface } from './interface';
import EmailSummary from '../EmailSummary';

const SummaryField: React.FC<{
    label: string;
    value;
}> = props => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className="col-6">
                <p>{t(props.label)}</p>
            </div>
            <div className="col-6">{props.value}</div>
        </React.Fragment>
    );
};

const getCancelSummaryFields = (_this: ThisInterface) => {
    const {
        state: { fields, cancellationReasons },
        props: { dateFormat }
    } = _this;

    const reason = cancellationReasons.find(item => item.id === fields.reasonId);

    return [
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.REQUESTED_DATE',
            value: DisplayDateFormat(fields.requestDate, dateFormat)
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.CANCELLATION_DATE',
            value: DisplayDateFormat(fields.cancellationDate, dateFormat)
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.CANCELLATION_REASON',
            value: reason ? reason.name : ''
        },
        {
            label: 'PAGE.CANCEL_MEMBERSHIP.LABELS.TOTAL_REFUND_AMOUNT',
            value: 1000 // TODO: integrate from API later
        }
    ];
};

const getMemberSummaryFields = (_this: ThisInterface) => {
    const {
        props: { memberData, dateFormat }
    } = _this;
    const {
        plan: {
            period: {
                minimumMembership: { unitName, value }
            }
        },
        startedDate
    } = memberData;
    const col1 = [
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.PACKAGE',
            value: memberData.package.name
        },
        {
            label: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.PACKAGE_PLAN',
            value: memberData.plan.name
        },
        {
            label: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.SALES_PERSON',
            value: memberData.salesPerson.name
        },
        {
            label: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.JOIN_DATE',
            value: DisplayDateFormat(memberData.joinedDate, dateFormat)
        },
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.START_DATE',
            value: DisplayDateFormat(memberData.startedDate, dateFormat)
        }
    ];
    const col2 = [
        {
            label: 'PAGE.MEMBERSHIPS.VIEW.END_DATE',
            value: DisplayDateFormat(memberData.endDate, dateFormat)
        },
        {
            label: 'PAGE.MEMBERSHIPS.CREATE.STEP_5.MIN_CONTRACT_PERIOD_END_DATE',
            value: DisplayDateFormat(
                dateInMCP(startedDate, unitName, value),
                dateFormat
            )
        },
        {
            label: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.PACKAGE_ELIGIBILITY_REQUIRED',
            value: 'No'
        },
        {
            label: 'PAGE.MEMBERSHIPS.EDIT.STEP_4.ELIGIBILITY_PROVIDED',
            value: 'No'
        }
    ];
    return [col1, col2];
};

const CancelSummarySection: React.FC<ThisInterface> = _this => {
    const {
        props: { t }
    } = _this;

    const summaryFields = getCancelSummaryFields(_this);

    return (
        <React.Fragment>
            <div className="row col">
                <p className="section-title">
                    {t('PAGE.CANCEL_MEMBERSHIP.SECTION_TITLE.CANCEL_SUMMARY_INFORMATION')}
                </p>
            </div>
            <div className="row cancel-summary-section">
                {summaryFields.map((field, index) => (
                    <SummaryField key={index} {...field} />
                ))}
            </div>
        </React.Fragment>
    );
};

const MemberSummarySection: React.FC<ThisInterface> = _this => {
    const {
        props: { t }
    } = _this;

    const [col1, col2] = getMemberSummaryFields(_this);

    return (
        <React.Fragment>
            <div className="row col">
                <p className="section-title">
                    {t('PAGE.CANCEL_MEMBERSHIP.SECTION_TITLE.MEMBER_SUMMARY_INFORMATION')}
                </p>
            </div>
            <div className="row">
                <div className="row col-6">
                    {col1.map((field, index) => (
                        <SummaryField key={index} {...field} />
                    ))}
                </div>
                <div className="row col-6">
                    {col2.map((field, index) => (
                        <SummaryField key={index} {...field} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

const PaymentSummarySection: React.FC<ThisInterface> = _this => {
    const {
        props: { t, dateFormat, memberData },
        state: { monthsOfPaymentsProjected, payments }
    } = _this;

    const headers = [
        {
            key: 'date',
            title: 'PAGE.CANCEL_MEMBERSHIP.PAYMENT_SUMMARY.COLUMNS.DATE'
        },
        {
            key: 'type',
            title: 'PAGE.CANCEL_MEMBERSHIP.PAYMENT_SUMMARY.COLUMNS.TYPE'
        },
        {
            key: 'method',
            title: 'PAGE.CANCEL_MEMBERSHIP.PAYMENT_SUMMARY.COLUMNS.METHOD'
        },
        {
            key: 'status',
            title: 'PAGE.CANCEL_MEMBERSHIP.PAYMENT_SUMMARY.COLUMNS.STATUS'
        },
        {
            key: 'amount',
            title: 'PAGE.CANCEL_MEMBERSHIP.PAYMENT_SUMMARY.COLUMNS.AMOUNT'
        }
    ];

    const renderTableContent = () => {
        return _.isEmpty(payments) ? (
            <tr className="no-data text-center">
                <td colSpan={5}>{t('COMMON.NO_DATA')}</td>
            </tr>
        ) : (
            payments.map((payment, index) => (
                <tr key={index}>
                    <td>{DisplayDateFormat(payment.date, dateFormat)}</td>
                    <td>{payment.type}</td>
                    <td>{payment.method}</td>
                    <td>{payment.status}</td>
                    <td>{payment.amount.toFixed(2)}</td>
                </tr>
            ))
        );
    };

    const currentDate = new Date();

    return (
        <React.Fragment>
            <div className="row col">
                <p className="section-title">
                    {t(
                        'PAGE.CANCEL_MEMBERSHIP.SECTION_TITLE.MEMBER_PAYMENT_SUMMARY_INFORMATION'
                    )}
                </p>
            </div>
            <div className="row col">
                <p>
                    {t(
                        'PAGE.MEMBERSHIPS.VIEW.PAYMENTS_PROJECT_FOR_X_MONTHS_UNTIL_VALUE',
                        {
                            months: monthsOfPaymentsProjected,
                            value: DisplayDateFormat(
                                currentDate.setMonth(
                                    currentDate.getMonth() +
									(currentDate.getDate() === memberData.billingRunDay
									    ? monthsOfPaymentsProjected - 1
									    : monthsOfPaymentsProjected),
                                    memberData.billingRunDay
                                ),
                                dateFormat
                            )
                        }
                    )}
                </p>
            </div>
            <div className="row">
                <CustomTable
                    renderingCondition
                    headers={headers}
                    tableContent={renderTableContent()}
                    maxHeigth="auto"
                    className="cancel-membership-payment-summary-table"
                />
            </div>
        </React.Fragment>
    );
};

export const CancelMembershipSummary: React.FC<ThisInterface> = _this => {
    const {
        props: { memberData, t },
        state: {
            isRemoveCancel,
            fields: { saveCancelCodeId },
            saveCancelCodes
        },
        handleCommunicationEmailChanged
    } = _this;

    const saveCancelCodeOptions = saveCancelCodes.map(item => {
        return {
            label: item.name,
            value: item.id
        };
    });

    return (
        <React.Fragment>
            {isRemoveCancel && saveCancelCodeId && (
                <div className="row cancel-code">
                    <div className="col-sm-4 col-lg-3 cancel-code__title">
                        {t('PAGE.REMOVE_CANCEL_MEMBERSHIP.SAVE_CANCEL_CODE')}
                    </div>
                    <div className="col-sm-6 col-xl-4">
                        <FormItem
                            type="select"
                            fieldName="saveCancelCodeId"
                            fields={[]}
                            data={saveCancelCodeId}
                            onChangeHandle={_this.handleFieldChanged}
                            options={saveCancelCodeOptions}
                            attribute={{
                                isClearable: false
                            }}
                        />
                    </div>
                </div>
            )}
            <CancelSummarySection {..._this} />
            <EmailSummary
                memberData={memberData}
                handleChange={handleCommunicationEmailChanged}
            />
            <MemberSummarySection {..._this} />
            <PaymentSummarySection {..._this} />
        </React.Fragment>
    );
};
