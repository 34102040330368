import OptionModel from 'models/Option';

export class ReasonModel {
    key = '';
    code = '';
    id = '';
    name = '';
}

export class FreezeRequestModel {
    startDate = '';
    typeName = '';
    method = '';
    status = '';
    amount = 0;
}

export class FreezeMember {
    startDate = '';
    duration = 0;
    reason = '';
    isOverrideFreezeNoticePeriod = false;
    isAllowFreezeBackDate = false;
    isAllowFutureFreezeStartDate = false;
    isPlanAllowFreezeToday = false;
    freezePrice = 0
}

export default class FreezeModal {
    startDateRange: OptionModel[] = [];
    freezeDurationRange: OptionModel[] = [];
    freezePrice: number = 0;
    expirationDate: string = '2019-12-31T00:00:00';
    isPlanAllowFreezeToday: boolean = false;
    reasons: OptionModel[] = [];
    freezeNoticeEndDate: string = '';
}

export interface FreezeDetails {
    id: string;
    startDate: string;
    endDate: string;
    isPlanAllowFreezeToday: boolean;
    duration: number;
    freezeReason: {
        code: string;
        name: string;
    };
    freezeValue: number;
    isOverrideFreezeNoticePeriod: boolean;
    isAllowFreezeBackDate: boolean;
    isAllowFutureFreezeStartDate: boolean;
    freezeNoticeEmail?: string;
}
