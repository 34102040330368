import React from 'react';
import { FormCheckbox } from 'components/Form';
import { AccordionItem } from 'react-light-accordion';
import { useTranslation } from 'react-i18next';

export const RightItem = ({ right, handleSelectRights }) => {
    return (
        <li>
            <FormCheckbox
                label={right.name}
                value={right.normalizeName}
                checked={right.active}
                onChange={(e?: React.ChangeEvent<HTMLInputElement>) =>
                    handleSelectRights(e!, right.id)
                }
            />
        </li>
    );
};

export const ReSourceItem = ({
    source,
    indexResource,
    indexGroup,
    handleSelectAllResource,
    handleDeselectAllResource,
    listRight
}) => {
    const { t } = useTranslation();
    return (
        <div className="group-feature">
            <dl className="clearfix">
                <dt>
                    {source.name}
                    <ul className="resource-list clearfix">
                        <li>
                            <a
                                onClick={() =>
                                    handleSelectAllResource(indexGroup, indexResource)
                                }
                            >
                                {t('BUTTON.SELECT_ALL')}
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={() =>
                                    handleDeselectAllResource(indexGroup, indexResource)
                                }
                            >
                                {t('BUTTON.DESELECT_ALL')}
                            </a>
                        </li>
                    </ul>
                </dt>
                <dd>
                    <ul className="right-list">{listRight}</ul>
                </dd>
            </dl>
        </div>
    );
};

export const FeatureItem = ({
    indexGroup,
    titleGroup,
    resouceList,
    handleSelectAllGroup,
    handleDeselectAllGroup
}) => {
    const { t } = useTranslation();
    return (
        <div className={`accordion-group group-${indexGroup}`}>
            <ul className="group-list clearfix">
                <li>
                    <a onClick={() => handleSelectAllGroup(indexGroup)}>
                        {t('BUTTON.SELECT_ALL')}
                    </a>
                </li>
                <li>
                    <a onClick={() => handleDeselectAllGroup(indexGroup)}>
                        {t('BUTTON.DESELECT_ALL')}
                    </a>
                </li>
            </ul>
            <AccordionItem title={titleGroup}>
                <div className="group-panel">{resouceList}</div>
            </AccordionItem>
        </div>
    );
};
