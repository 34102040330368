/* eslint-disable */
import { Fee } from 'models/Fee';
import Field from 'models/FieldConfig';
import MemberDetail from 'models/MemberSummary';
import { WithTranslation } from 'react-i18next';

export class FeeView extends Fee {
    paymentAmount = 0;
}

interface Props extends WithTranslation {
    memberData: MemberDetail;
    onCancel: () => void;
}

export enum Steps {
    Info = 0,
    Summary = 1
}

export enum PayOptions {
    Full = 0,
    OtherAmount = 1,
    CustomAmount = 2
}

export default interface ThisInterface {
    props: Props;
    state: {
        isLoading: boolean;
        step: Steps;
        fees: FeeView[];
        remaining: number;
        disabledNext: boolean;
        payOption: PayOptions;
        totalAmountCharged: number;
        totalAmountPaid: number;
        totalAmountOutstanding: number;
        totalPaymentAmount: number;
        fields: {
            paymentAmount: number;
            tenderType: string;
        },
        validations: {
            paymentAmount: Field;
        },
        tenderTypes: {
            id: string;
            name: string;
        }[]
    };
    allocate: () => void;
    moveToStep: (step: Steps) => void;
    handleChangeOption: (option: PayOptions) => void;
    handleChangeField: (key: string, value: string) => void;
    submit: () => void;
}