import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { DATE_FORMAT_LIST as DateFormatList } from 'constants/DateFormat';
import { th, zhCN } from 'date-fns/locale';
import {
    FormGroup,
    Label,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup
} from 'reactstrap';

registerLocale('th', th);
registerLocale('zh-cn', zhCN);

interface DatePickerProps {
    // required props
    id: string;
    name: string;
    dateFormat: string;
    selectedDate: Date | null;
    errorMsg?: string | React.ReactNode | null;
    onChange: (value: string, key: string) => void;
    onDateClick?: (e: React.MouseEvent, value: string) => void;
    // optional
    locale?: string;
    label?: string | React.ReactNode | null;
    minDate?: Date;
    maxDate?: Date;
    required?: boolean;
    onClickOutside?: () => void;
    onBlur?: (event: React.MouseEvent) => void;
    icon?: string;
    hasIcon?: boolean;
    selectKey?: string;
    className?: string;
}

const FormDatePicker: React.SFC<DatePickerProps> = ({
    locale,
    label,
    id,
    name,
    dateFormat,
    minDate,
    maxDate,
    required,
    onChange,
    onDateClick,
    onClickOutside,
    onBlur,
    selectedDate,
    errorMsg,
    className
}) => {
    dateFormat = dateFormat.replace(/D/g, 'd').replace(/m/g, 'M').replace(/Y/g, 'y');
    let maskedInput: any[] = [];
    let resultFilter = DateFormatList.filter(d => d.format === dateFormat.toLowerCase());
    if (resultFilter.length > 0) {
        maskedInput = [...resultFilter[0].maskedInput];
    } else {
        maskedInput = [...DateFormatList[0].maskedInput];
    }

    return (
        <FormGroup className={className}>
            {label && <Label for={id}>{label}</Label>}
            {required && <span className="text-primary bold">&nbsp;*</span>}
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <span className="icons icon-calendar-ico"></span>
                    </InputGroupText>
                </InputGroupAddon>
                {dateFormat &&
                    <DatePicker
                        // locale={locale}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        isClearable
                        placeholderText={dateFormat}
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={selectedDate}
                        dateFormat={dateFormat}
                        required={required}
                        onClickOutside={onClickOutside}
                        onBlur={onBlur}
                        onChange={onChange}
                        onFocus={onDateClick}
                        customInput={
                            <MaskedInput
                                mask={maskedInput}
                                placeholderChar={'\u2000'}
                            />
                        }
                        // className={`flex-fill ${errorMsg && 'error'}`}
                        className="form-control btlr-0 bblr-0"
                        popperPlacement="top-start"
                        popperModifiers={{
                            offset: {
                                enabled: true,
                                offset: '1px, 1px'
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                                boundariesElement: 'viewport'
                            }
                        }}
                        disabledKeyboardNavigation={true}
                    />}
            </InputGroup>
            {errorMsg && (
                <span className="text-danger input-error">{errorMsg}</span>
            )}
        </FormGroup>
    );
};

export default FormDatePicker;
