import React from 'react';
import template from './template';
import ThisInterface from './interface';
import { withTranslation, WithTranslation } from 'react-i18next';

class MemberSummary extends React.Component<ThisInterface["props"] & WithTranslation, ThisInterface["state"]> {
    constructor(props: ThisInterface["props"] & WithTranslation) {
        super(props);

        this.state = {
            openDropdown: {
                personalDetails: true,
                contactDetails: false,
                addressDetails: false,
                contactedDetails: false,
                emergencyContact: false,
                healthQuestion: false,
                otherFields: false
            }  
        }
    }

    handleOpenDropdown = (key: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                openDropdown: {
                    ...prevState.openDropdown[key],
                    [key]: !prevState.openDropdown[key]
                }
            };
        });
    };

    render() {
        return template(this);
    }
}

export default withTranslation()(MemberSummary);