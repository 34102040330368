import CollapseButton from 'components/CollapseButton';
import CustomTable from 'components/Table';
import { GENERAL_DATE_FORMAT } from 'constants/Constants';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommaFormatted, DisplayDateFormat, toLocalDate } from 'util/ControlUtils';
import EmailSummary from '../../EmailSummary';
import { CollapseBarInterface, ThisInterface } from './interface';

class CollapseBar extends React.Component<CollapseBarInterface['props'], CollapseBarInterface['state']> {
    constructor(props: CollapseBarInterface['props']) {
        super(props);
        this.state = {
            isExpanded: true
        };
    }

    toggleExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }
    
    render() {
        return (
            <CollapseButton
                title={this.props.title}
                isOpen={this.state.isExpanded}
                content={this.props.children}
                openCollapsedContent={this.toggleExpand}
                className="section-title-bar"
                maxHeight="1000px"
            />            
        );
    }
}

const SummaryField: React.FC<{
    label: string;
    value: string;
}> = ({label, value}) => {
    return (
        <React.Fragment>
            <div className="col-6">
                <p>{label}</p>
            </div>
            <div className="col-6">
                <p>{value}</p>
            </div>
        </React.Fragment>
    );
};

const renderSection = (title: string, children: React.ReactNode) => {
    return (
        <CollapseBar
            title={title}
        >
            {children}
        </CollapseBar>
    );
};

export const UnfreezeSummaryInfo: React.FC<ThisInterface> = (_this: ThisInterface) => {
    const {
        props: {
            t,
            memberData
        },
        state: {
            unfreezeDetails
        },
        handleCommunicationEmailChanged
    } = _this;

    const displayDate = (date: any) => {
        return DisplayDateFormat(toLocalDate(date), GENERAL_DATE_FORMAT);
    };

    const renderSectionContent = () => {
        return (
            <React.Fragment>
                <div className="row unfreeze-summary-info">
                    <div className="col-12">
                        <p>{t('PAGE.UNFREEZE_MEMBERSHIP.UNFREEZE_SUMMARY_SUBTITLE')}</p>
                    </div>
                    <SummaryField
                        label={t('PAGE.UNFREEZE_MEMBERSHIP.SUMMARY.FREEZE_START_DATE')}
                        value={displayDate(unfreezeDetails.startedDateUtc)}
                    />
                    <SummaryField
                        label={t('PAGE.UNFREEZE_MEMBERSHIP.SUMMARY.FREEZE_END_DATE')}
                        value={displayDate(unfreezeDetails.endDateUtc)}
                    />
                    <SummaryField
                        label={t('PAGE.UNFREEZE_MEMBERSHIP.SUMMARY.NEW_FREEZE_END_DATE')}
                        value={displayDate(unfreezeDetails.newEndDateUtc)}
                    />
                    <SummaryField
                        label={t('PAGE.UNFREEZE_MEMBERSHIP.SUMMARY.PRO_RATA_FEE')}
                        value={CommaFormatted(unfreezeDetails.proRataFee)}
                    />
                </div>
                <EmailSummary memberData={memberData} handleChange={handleCommunicationEmailChanged} />
            </React.Fragment>
        );
    };

    return renderSection(t('PAGE.UNFREEZE_MEMBERSHIP.SUMMARY_INFORMATION_TITLE'), renderSectionContent());
};

const paymentSummaryHeaders = [
    {
        key: 'startDate',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_DATE'
    },
    {
        key: 'typeName',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_TYPE'
    },
    {
        key: 'method',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_METHOD'
    },
    {
        key: 'status',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_STATUS'
    },
    {
        key: 'amount',
        title: 'PAGE.MEMBERSHIPS.VIEW.FREEZE_MEMBERSHIP.COLUMN_AMOUNT'
    }
];

const renderTableContent = (fees: ThisInterface['state']['unfreezeDetails']['fees']) => {
    const {t} = useTranslation();

    return _.isEmpty(fees) ?
        (
            <tr className="no-data text-center"><td colSpan={5}>{t('COMMON.NO_DATA')}</td></tr>
        )
        : fees.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{DisplayDateFormat(item.startDate, GENERAL_DATE_FORMAT)}</td>
                    <td>{item.typeName}</td>
                    <td>{item.method}</td>
                    <td>{item.status}</td>
                    <td>{CommaFormatted(item.amount)}</td>
                </tr>
            );
        });
};

const renderPaymentSummaryTable = (_this: ThisInterface) => {
    const { state: { unfreezeDetails } } = _this;
    const { t } = _this.props;

    return (
        <React.Fragment>
            <div className="col-12">
                <p>
                    {t(
                        'PAGE.UNFREEZE_MEMBERSHIP.MEMBER_PAYMENT_SUMMARY_DESCRIPTION',
                        { month: 6, date: DisplayDateFormat(moment().local().add(6, 'months'), GENERAL_DATE_FORMAT) }
                    )}
                </p>
            </div>
            <CustomTable
                renderingCondition
                headers={paymentSummaryHeaders}
                tableContent={renderTableContent(unfreezeDetails.fees)}
                maxHeigth="auto"
                className="unfreeze-payment-summary-table"
            />
        </React.Fragment>
    );
};

export const PaymentSummaryInfo: React.FC<ThisInterface> = (_this: ThisInterface) => {
    const {
        props: {
            t
        }
    } = _this;

    const renderSectionContent = () => {
        return (
            <div className="row">
                {renderPaymentSummaryTable(_this)}
            </div>
        );
    };

    return renderSection(t('PAGE.UNFREEZE_MEMBERSHIP.PAYMENT_SUMMARY_TITLE'), renderSectionContent());
};

export const UnfreezeSummary = (_this: ThisInterface) => {
    return (
        <React.Fragment>
            <UnfreezeSummaryInfo {..._this} />
            <PaymentSummaryInfo {..._this} />
        </React.Fragment>
    );
};