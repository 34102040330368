import React from 'react';
import Swal from 'sweetalert2';
import * as DefaultConstants from 'constants/DefaultConstants';

import ThisInterface from './interface';
import template from './template';

interface SwalInterface {
    type: 'error' | 'warning' | 'success' | 'info' | 'question' | undefined;
}

class ForgotPassword extends React.Component<
ThisInterface['props'],
ThisInterface['state']
> {
    constructor(props: ThisInterface['props']) {
        super(props);
        this.state = {
            username: '',
            emailOrPhone: '',
            usernameError: '',
            emailOrPhoneError: '',
            isLoading: false,
            isResend: false
        };
    }

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const { value } = e.target;
        this.setState(prevState => {
            return {
                ...prevState,
                [key]: value,
                [`${key}Error`]: ''
            };
        });
    };

    showPopUp = (msgId: string = '', type: SwalInterface['type'] = 'warning') => {
        const { t } = this.props;
        Swal.fire({
            ...DefaultConstants.SWAL_COMMON_STYLE,
            type,
            html: t!(msgId),
            confirmButtonText: t!('BUTTON.CLOSE')
        });
    };

    unsetLoading = (callback: () => void) => {
        this.setState(
            {
                isLoading: false
            },
            callback
        );
    };

    resetPassword = () => {
        const { username, emailOrPhone } = this.state;
        const { conmanService } = this.props;

        this.setState({
            usernameError: username ? '' : 'PAGE.FORGOT_PASSWORD.PLEASE_ENTER',
            emailOrPhoneError: emailOrPhone ? '' : 'PAGE.FORGOT_PASSWORD.PLEASE_ENTER'
        });
        if (username && emailOrPhone) {
            this.setState({
                isLoading: true,
                usernameError: '',
                emailOrPhoneError: ''
            });
            conmanService
                .forgotPassword(username, emailOrPhone)
                .then(res => {
                    if (res) {
                        this.unsetLoading(() => {
                            if (res.data.statusCode === 200) {
                                this.setState({
                                    isResend: true
                                });
                            } else {
                                this.showPopUp(res.data.message);
                            }
                        });
                    }
                })
                .catch(err => {
                    this.unsetLoading(() => this.showPopUp(err));
                });
        }
    };

    render() {
        return template(this);
    }
}

export default ForgotPassword;
